import {
  D1Data,
  axis,
  correction,
  eye,
  optimisationLevel,
  orderType,
} from "../interfaces";
import { Engine } from "../engine";
import { Troubleshoot } from "../worksheet/troubleshoot";
import { EbcWorksheet } from "../worksheet/worksheet";

export class D1 {
  D4: string;
  D5: string;
  D6: string;
  D7: string;
  D8: string;
  K9: orderType;
  H10: eye;
  H12: number;
  K12: number;
  H14: number;
  K14: number;
  H15: number;
  K15: number;
  H16: axis;
  K16: axis;
  H17: number;
  K17: number;
  H19: correction;
  K19: correction;
  H22: number;
  K22: number;
  H23: number;
  J23 = (ebcWorksheet: EbcWorksheet, troubleshoot: any, troubleshoot2: any) =>
    ebcWorksheet.E30(troubleshoot, troubleshoot2);
  K23: number;
  M23 = (ebcWorksheet: EbcWorksheet, troubleshoot: any, troubleshoot2: any) =>
    ebcWorksheet.E89(troubleshoot, troubleshoot2);
  H25: number;
  K25: number;
  H26 = (
    ebcWorksheet: EbcWorksheet,
    odEngine: Engine,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) => ebcWorksheet.H37(odEngine, troubleshoot, troubleshoot2, ebcWorksheet);
  K26 = (
    ebcWorksheet: EbcWorksheet,
    osEngine: Engine,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) => ebcWorksheet.H96(osEngine, ebcWorksheet, troubleshoot, troubleshoot2);
  H28: number;
  J28 = (
    ebcWorksheet: EbcWorksheet,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) => ebcWorksheet.F45(troubleshoot, troubleshoot2);
  K28: number;
  M28 = (
    ebcWorksheet: EbcWorksheet,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) => ebcWorksheet.F104(troubleshoot, troubleshoot2);
  H31: number;
  K31: number;
  H32: number;
  K32: number;
  H33: number;
  K33: number;
  H34 = (
    odEngine: Engine,
    ebcWorksheet: EbcWorksheet,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) =>
    (odEngine.AB26(ebcWorksheet, troubleshoot, troubleshoot2) -
      odEngine.J26(ebcWorksheet, troubleshoot, troubleshoot2)) *
    1000;
  K34 = (
    osEngine: Engine,
    ebcWorksheet: EbcWorksheet,
    troubleshoot: Troubleshoot,
    troubleshoot2: Troubleshoot
  ) =>
    (osEngine.AB26(ebcWorksheet, troubleshoot, troubleshoot2) -
      osEngine.J26(ebcWorksheet, troubleshoot, troubleshoot2)) *
    1000;
  H35: boolean;
  K35: boolean;
  H36: optimisationLevel | "";
  K36: optimisationLevel | "";
  H37: boolean;
  K37: boolean;
  constructor(data: D1Data) {
    this.D4 = data.clinicName;
    this.D5 = data.clinicAddress;
    this.D6 = data.doctorName;
    this.D7 = data.patientName;
    this.D8 = data.patientReference;
    this.K9 = data.OrderType;
    this.H10 = data.question1;
    this.H12 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question2?.right || 0 : 0;
    this.K12 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question2?.left || 0 : 0;
    this.H14 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question3?.right?.sphere || 0 : 0;
    this.K14 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question3?.left?.sphere || 0 : 0;
    this.H15 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question3?.right?.cylinder || 0 : 0;
    this.K15 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question3?.left?.cylinder || 0 : 0;
    this.H16 =
    (this.H10 === eye.both || this.H10 !== eye.left) ? data.question3?.right?.axis || axis.WTR : axis.WTR;
    this.K16 =
    (this.H10 === eye.both || this.H10 !== eye.right) ? data.question3?.left?.axis || axis.WTR : axis.WTR;
    this.H17 = -this.H14;
    this.K17 = -this.K14;
    this.H19 =
    (this.H10 === eye.both || this.H10 !== eye.left)
        ? data.question4?.right || correction.miopiaControl
        : correction.miopiaControl;

    this.K19 =
    (this.H10 === eye.both || this.H10 !== eye.right)
        ? data.question4?.left || correction.miopiaControl
        : correction.miopiaControl;

    this.H22 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question5?.right || 0 : 0;
    this.K22 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question5?.left || 0 : 0;
    this.H23 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question6?.right || 0 : 0;
    this.K23 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question6?.left || 0 : 0;
    this.H25 = (this.H10 === eye.both || this.H10 !== eye.left) ? data.question7?.right || 0 : 0;
    this.K25 = (this.H10 === eye.both || this.H10 !== eye.right) ? data.question7?.left || 0 : 0;
    this.H28 =
    (this.H10 === eye.both || this.H10 !== eye.left)
        ? data.question8?.a
          ? data.question8?.a.right || 0
          : 0
        : 0;
    this.K28 =
    (this.H10 === eye.both || this.H10 !== eye.right)
        ? data.question8?.a
          ? data.question8?.a?.left || 0
          : 0
        : 0;
    this.H31 =
    (this.H10 === eye.both || this.H10 !== eye.left)
        ? data.question8?.b
          ? data.question8?.b?.right || 0
          : 0
        : 0;
    this.K31 =
    (this.H10 === eye.both || this.H10 !== eye.right)
        ? data.question8?.b
          ? data.question8?.b?.left || 0
          : 0
        : 0;

    this.H33 = this.H15;

    this.K33 = this.K15;

    this.H32 = data.question3?.right?.axis == axis.WTR ? -this.H33 : this.H33;

    this.K32 = data.question3?.left?.axis == axis.WTR ? -this.K33 : this.K33;

    this.H35 = this.H28 > 25 || this.H31 > 1.3 ? true : false;

    this.K35 = this.K28 > 25 || this.K31 > 1.3 ? true : false;

    this.H36 =
    (this.H10 === eye.both || this.H10 !== eye.left)
        ? data.question9?.right || optimisationLevel.optimised
        : "";

    this.K36 =
    (this.H10 === eye.both || this.H10 !== eye.right)
        ? data.question9?.left || optimisationLevel.optimised
        : "";

    this.K37 = (this.H10 === eye.both || this.H10 == eye.left )? data.question10?.left || false : false;

    this.H37 = (this.H10 === eye.both || this.H10 == eye.right) ? data.question10?.right || false : false;
  }
}
