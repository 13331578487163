import { GetDoctorInfoQuery } from "@app/generated/graphql"
import { User } from "@app/shared/models/shared.model";

export enum ActionType {
  getFranchiseClinicList = '[Doctor] get franchise clinic list',
  getFranchiseClinicListSuccess = '[Doctor] get user list success',
  getRegularClinicList = '[Doctor] get regular clinic list',
  getRegularClinicListSuccess = '[Doctor] get regular clinic list success',
  onboardDoctor = '[Doctor] Onboard doctor',
  onboardDoctorSuccess = '[Doctor] Onboard doctor success',
  getDoctorFormData = '[Doctor] get doctor form data',
  getDoctorFormDataSuccess = '[Doctor] get doctor form data success',
  saveClinicDetailsOfDoctors = '[Doctor] Update clinic details',
  saveClinicDetailsOfDoctorsSuccess = '[Doctor] Update clinic details success',
  getDoctorTableList = '[Doctors] Get doctors table list',
  getDoctorTableListSuccess = '[Doctors] Get doctors table list success',
  getDoctorTableListFailure = '[Doctors] Get doctors table list failure',
  getTotalTableItemsCount = '[Doctors] Get total table list count',
  getTotalTableItemsCountSuccess = '[Doctors] Get total table list count success',
}

export const initialState: State = {
  franchiseClinicList: null,
  regularClinicList: null,
  doctorDetails: { user_role_mapping: [] },
  doctors: [],
  onboardingRoleId: '',
  doctorTableList: null,
  totalTableListCount: 0,
}

export interface State {
  franchiseClinicList: User[] | null,
  regularClinicList: User[] | null,
  doctorDetails: GetDoctorInfoQuery,
  doctors: [];
  onboardingRoleId: string;
  doctorTableList: DoctorListData[] | null;
  totalTableListCount: number;
}

export interface DoctorListData {
  id: string;
  name: string;
  email: string;
  contact_number: string;
  is_active: string;
  created_at: string;
  role_mapping_id: string;
  role_id: string;
  country_code?: string;
}
