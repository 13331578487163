import { createAction } from '@ngrx/store';

import { ActionType } from '../models/exclusive-distributors.model';
import {
    GetDesignerListQuery,
    GetDesignerListQueryVariables,
    GetExclusiveDistributorListBasedOnRoleQuery,
    GetExclusiveDistributorListBasedOnRoleQueryVariables,
    GetTotalTableItemCountForExclusiveDistributorsQueryVariables,
    OnboardExclusiveDistributorMutationVariables,
} from '@app/generated/graphql';

export const onBoardDistributors = createAction(
    ActionType.onBoardDistributors,
    (data: OnboardExclusiveDistributorMutationVariables) => ({ data })
);

export const getDesignersList = createAction(ActionType.getDesignersList, (payload: GetDesignerListQueryVariables) => ({
    payload,
}));

export const getDesignersListSuccess = createAction(
    ActionType.getDesignersListSuccess,
    (designers: GetDesignerListQuery) => ({ designers })
);

export const getExclusiveDistributorsListBasedOnRole = createAction(
    ActionType.getExclusiveDistributorsListBasedOnRole,
    (params: GetExclusiveDistributorListBasedOnRoleQueryVariables) => ({ params })
);

export const getExclusiveDistributorsListBasedOnRoleSuccess = createAction(
    ActionType.getExclusiveDistributorsListBasedOnRoleSuccess,
    (payload: GetExclusiveDistributorListBasedOnRoleQuery) => ({ payload })
);

export const getTotalCountOfTableList = createAction(
    ActionType.getTotalTableItemsCount,
    (countListParams: GetTotalTableItemCountForExclusiveDistributorsQueryVariables) => ({ countListParams })
);

export const getTotalTableItemsCountSuccess = createAction(
    ActionType.getTotalTableItemsCountSuccess,
    (tableItemCount: number) => ({ tableItemCount })
);
