import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription, takeWhile } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Roles } from '@app/core/util/common';
import { orderStatusDateModal } from '@app/shared/models/shared.model';
import { CredentialsService } from '@app/core/services/credentials.service';
import { selectOrderDetailsById } from '@app/shared/selectors/shared.selector';
import {
    Manufacturer_Status_Enum,
    Order_Status_Enum,
    UpdateOrderStatusMutationVariables,
} from '@app/generated/graphql';
import { getOrderDetailsByOrderId, getOrderDetailsByOrderIdSuccess, updateOrderDetails } from '@app/shared/actions/shared.action';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@app/core/services/common.service';

@Component({
    selector: 'app-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent implements OnChanges, OnInit, OnDestroy {
    currentUserId = '';
    currentUserRole = '';
    previousStatusId = 0;
    isOrderCancelled = false;
    hideCancelButton: boolean = false;
    orderStatusDetails: any = [];
    isAlive = true;
    orderStatusObject: orderStatusDateModal = {
        submittedDate: '',
        orderedDate: '',
        shippedDate: '',
        outForDeliveryDate: '',
        deliveredDate: '',
        cancelledDate: '',
    };
    orderStatusDetailsSubs$ = new Subscription();

    orderStatusList = [
        { id: 1, label: 'Designed', active: true, show: false, created_at: '' },
        { id: 2, label: 'Ordered', active: true, show: false, created_at: '' },
        { id: 3, label: 'Shipped to country', active: true, show: false, created_at: '' },
        { id: 4, label: 'Out for Delivery', active: true, show: false, created_at: '' },
        { id: 5, label: 'Delivered', active: true, show: false, created_at: '' },
    ];
    writtenConfirmation = '';

    @Input() order_status = '';
    @Input() previous_status = '';
    @Input() orderId = '';
    @Input() orderIdForDisplay = '';
    @Input() created_at = '';
    @Input() manufacturerStatus = '';
    @ViewChild('confirmationDialogCmp') confirmationDialogCmp!: ConfirmationDialogComponent;

    constructor(
        private _store: Store,
        private _toast: ToastrService,
        private _credentialService: CredentialsService,
        private _commonService: CommonService
    ) {}

    ngOnChanges(): void {
        if (this.order_status) {
            this.updateStatusActive();
        }
        this._commonService.updateOrderStatusAsObs().pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
          if(value){
            this.order_status = value;
          }
        })
    }

    ngOnInit() {
        this.currentUserId = this._credentialService?.credentials?.userId || '';
        this.currentUserRole = this._credentialService?.credentials?.role || '';
        if (this.currentUserRole === Roles.CONTRACT_MANUFACTURER) {
            this.hideCancelButton = true;
        }
        if (this.order_status === Order_Status_Enum.Cancelled) {
            this.orderStatusList.push({ id: 6, label: 'Cancelled', active: true, show: false, created_at: '' });
            this.hideCancelButton = false;
        }

        this._store.dispatch(getOrderDetailsByOrderId({ order_id: this.orderId }));
        this.previousStatusId =
            this.orderStatusList.find((el) => el.label.toLocaleLowerCase() === this.previous_status)?.id || 0;
        this.fetchOrderStatusDetailsByOrderId();
    }

    // fetch order status details from state and mapping dates to respective statuses.
    fetchOrderStatusDetailsByOrderId = (): void => {
        this.orderStatusDetailsSubs$ = this._store.select(selectOrderDetailsById).subscribe((orderDetails) => {
            this.orderStatusDetails = [];
            this.orderStatusDetails = orderDetails?.order_status;
            this.isOrderCancelled = orderDetails?.order?.[0]?.order_status === Order_Status_Enum.Cancelled;
            if (this.orderStatusDetails != undefined) {
                this.orderStatusDetails?.forEach((order: any) => {
                    switch (order?.order_status) {
                        case Order_Status_Enum.Submitted:
                            this.orderStatusObject.submittedDate = order?.created_at;
                            break;
                        case Order_Status_Enum.Ordered:
                            this.orderStatusObject.orderedDate = order?.created_at;
                            break;
                        case Order_Status_Enum.Shipped:
                            this.orderStatusObject.shippedDate = order?.created_at;
                            break;
                        case Order_Status_Enum.OutForDelivery:
                            this.orderStatusObject.outForDeliveryDate = order?.created_at;
                            break;
                        case Order_Status_Enum.Delivered:
                            this.orderStatusObject.deliveredDate = order?.created_at;
                            break;
                        case Order_Status_Enum.Cancelled:
                            this.orderStatusObject.cancelledDate = order?.created_at;
                            break;
                    }
                    // Updates the shipped date from tracking table when contract manufacturer logged in
                    if (
                        this.currentUserRole === Roles.CONTRACT_MANUFACTURER &&
                        order?.manufacturer_order_status === Manufacturer_Status_Enum.Shipped
                    ) {
                        this.orderStatusObject.shippedDate = order?.manufacturer_created_at;
                    }
                });
            }
        });
    };

    // Updates the tracker according to the order status of the order_id.
    updateStatusActive = (): void => {
        const isManufactureEnumValue =
            this.order_status === Manufacturer_Status_Enum.Recieved ||
            this.order_status === Manufacturer_Status_Enum.Delayed ||
            this.order_status === Manufacturer_Status_Enum.Manufactured
                ? true
                : false;
        const orderStatusValue = isManufactureEnumValue ? this.order_status : this.order_status.toLocaleLowerCase();

        switch (orderStatusValue) {
            case Order_Status_Enum.Submitted:
                const index1: number =
                    this.orderStatusList.findIndex(
                        (orderStatus) => orderStatus.label.toLocaleLowerCase() === 'designed'
                    ) + 1;
                this.updateOrderTrackerBasedOnStatus(index1);
                break;
            case Manufacturer_Status_Enum.Recieved:
            case Manufacturer_Status_Enum.Delayed:
            case Manufacturer_Status_Enum.Manufactured:
            case Order_Status_Enum.Ordered:
                const index2: number =
                    this.orderStatusList.findIndex(
                        (orderStatus) => orderStatus.label.toLocaleLowerCase() === 'ordered'
                    ) + 1;
                this.updateOrderTrackerBasedOnStatus(index2);
                break;
            case Order_Status_Enum.Shipped:
                const index3: number =
                    this.orderStatusList.findIndex(
                        (orderStatus) => orderStatus.label.toLocaleLowerCase() === 'shipped to country'
                    ) + 1;
                this.updateOrderTrackerBasedOnStatus(index3);
                break;
            case Order_Status_Enum.OutForDelivery:
                const index4: number =
                    this.orderStatusList.findIndex(
                        (orderStatus) => orderStatus.label.toLocaleLowerCase() === 'out for delivery'
                    ) + 1;
                this.updateOrderTrackerBasedOnStatus(index4);
                break;
            case Order_Status_Enum.Delivered:
                const index5: number =
                    this.orderStatusList.findIndex(
                        (orderStatus) => orderStatus.label.toLocaleLowerCase() === 'delivered'
                    ) + 1;
                this.updateOrderTrackerBasedOnStatus(index5);
                break;
            case Order_Status_Enum.Cancelled:
                this.orderStatusList[0].created_at = this.orderStatusObject.cancelledDate || '--';
                break;
        }
    };

    updateOrderTrackerBasedOnStatus = (id: number): void => {
        this.orderStatusList.forEach((element) => {
            const status = element;
            if (status.id <= id) {
                status.active = true;
            } else {
                status.active = false;
            }
        });
    };

    onClickCancelOrder = (): void => {
        let orderDetailsParams: UpdateOrderStatusMutationVariables = {
            order_id: this.orderId,
            order_status: Order_Status_Enum.Cancelled,
            user_id: this.currentUserId,
        };
        this._store.dispatch(updateOrderDetails(orderDetailsParams));
        this.hideCancelButton = false;
        this.ngOnInit();
    };

    onCopyToClipboard = (): void => {
        this._toast.success('Order ID copied');
    };

        /**
     * Recieves event from modal component upon confirmation
     * Dispatches action to delete user
     */
    onConfirmationStatusUpdate = (): void => {
        if(this.writtenConfirmation?.toLocaleLowerCase() !== 'cancel'){
          this._toast.error('Please type CANCEL in the input');
          return;
        }
        this.writtenConfirmation = '';
        this.confirmationDialogCmp.onCancelConfirmDialogOnly();
        this.onClickCancelOrder();
    };

    openOrderCancelConfirmModal = ():void => {
      this.confirmationDialogCmp.openConfirmDialog();
    }

    checkManufacturerStatus = ():boolean => {
      return !(this.manufacturerStatus === Manufacturer_Status_Enum.Shipped && this.currentUserRole === Roles.CONTRACT_MANUFACTURER);
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        this.orderStatusObject = {
            submittedDate: '',
            orderedDate: '',
            shippedDate: '',
            outForDeliveryDate: '',
            deliveredDate: '',
            cancelledDate: '',
        };
        this.orderStatusDetailsSubs$.unsubscribe();
        this._store.dispatch(getOrderDetailsByOrderIdSuccess({ order_status: [], order: [] }))
    }
}
