export enum axis {
    WTR = 'WTR',
    ATR = 'ATR',
    Oblique = 'Oblique',
}

export enum correction {
    miopiaControl = 'Myopia control',
    adult = 'Adult',
    presbyopia = 'Presbyopia',
}

export enum optimisationLevel {
    optimised = 'Optimized',
    oneStepLoose = '1 step loose',
    twoStepLoose = '2 steps loose',
}

export enum orderType {
    new = 'New',
    amend = 'Amend',
    remake = 'Remake'
}

export enum eye {
    left = 'LEFT ONLY',
    right = 'RIGHT ONLY',
    both = 'RIGHT AND LEFT',
    empty = ''
}

export enum EyeSide {
    RIGHT = "Right",
    LEFT = "Left",
    EMPTY = ""
}

export interface DoctorSlipDetails {
    patientName: string;
    referenceName: string;
    contactNumber: string;
    practiceName: string;
    orderDate: string;
    side: {
        right: string;
        left: string;
    };
    materialAndColor: {
        right: string;
        left: string;
    };
    lensType: {
        right: string;
        left: string;
    };
    BV: {
        right: number;
        left: number;
    };
    diameter: {
        right: number;
        left: number;
    };
    ct: {
        right: number;
        left: number;
    };
    bc: {
        right: {
            value1: number;
            value2: number;
            width?: number;
        };
        left: {
            value1: number;
            value2: number;
            width?: number;
        };
    };
    bc_eccentricity: {
        right: string | number;
        left: string | number;
    };
    taz: {
        right: {
            value1: number | any;
            value2: null | any;
            width: number | any;
        };
        left: {
            value1: number | any;
            value2: null | any;
            width: number | any;
        };
    };
    tpz: {
        right: {
            value1: number | any;
            value2: null | any;
            width: number | any;
        };
        left: {
            value1: number | any;
            value2: null | any;
            width: number | any;
        };
    };
    orthoCorrectionReason: string;
    estimated_delivery: string;
    specialInstructions: string;
    lensManufacturer: string;
    targetEye: EyeSide
    lensColor: {
        right: string;
        left: string;
    };
}

export interface LabFormDetails {
    orderDate: string,
    patientName: string,
    doctorName: string,
    clinicName: string,
    patientReference: string;
    side: {
        right: string;
        left: string;
    };
    materialAndColor: {
        right: string;
        left: string;
    };
    lensType: {
        right: string;
        left: string;
    };
    BV: {
        right: number;
        left: number;
    };
    diameter: {
        right: number;
        left: number;
    };
    ct: {
        right: number;
        left: number;
    };
    foz: {
        right: number;
        left: number;
    };
    et: {
        right: number;
        left: number;
    };
    bc: {
        right: {
            value1: number;
            value2: number;
            width?: number;
        };
        left: {
            value1: number;
            value2: number;
            width?: number;
        };
    };
    bc_eccentricity: {
        right: number | string;
        left: number | string;
    };
    ic1: {
        right: {
            value1: number;
            value2: number;
            width: number;
        };
        left: {
            value1: number;
            value2: number;
            width: number;
        };
    };
    ic2: {
        right: {
            value1: number;
            value2: number;
            width: number;
        };
        left: {
            value1: number;
            value2: number;
            width: number;
        };
    };
    ic3: {
        right: {
            value1: number;
            value2: number;
            width: number;
        };
        left: {
            value1: number;
            value2: number;
            width: number;
        };
    };
    ic4: {
        right: {
            value1: number;
            value2: null;
            width: number;
        };
        left: {
            value1: number;
            value2: null;
            width: number;
        };
    };
    ic5: {
        right: {
            value1: number;
            value2: null;
            width: number;
        };
        left: {
            value1: number;
            value2: null;
            width: number;
        };
    };
    specialInstructions: string;
    lensManufacturer: string;
    lensColor: {
        right: string;
        left: string;
    };
}

export interface D1Data {
    clinicName: string;
    clinicAddress: string;
    patientName: string;
    patientReference: string;
    doctorName: string;
    OrderType: orderType;
    labName?: string;
    question1: eye; // eye selection
    question2: {
        // hvid value
        right?: number;
        left?: number;
    };
    question3: {
        // patient's refractive error
        right: {
            sphere: number; // range - between -20 and 5
            cylinder: number; // range - between -10 and 0
            axis: axis;
        };
        left: {
            sphere: number; // range - between -20 and 5
            cylinder: number; // range - between -10 and 0
            axis: axis;
        };
    };
    question4: {
        // correction would your patient like to achieve with Ortho-K?
        right?: correction;
        left?: correction;
    };
    question5: {
        // What is the Flat-K?
        right?: number;
        left?: number;
    };
    question6: {
        // sag height of cornea at stated chord diameter? (If applicable)
        right?: number;
        left?: number;
    };
    question7: {
        // average e value
        right?: number; // range - between -0.99 and 0.99
        left?: number; // range - between -0.99 and 0.99
    };
    question8: {
        a: {
            right?: number;
            left?: number;
        };
        b: {
            right?: number;
            left?: number;
        };
    };
    question9: {
        // Choose your edge lift profile
        right?: optimisationLevel;
        left?: optimisationLevel;
    };
    question10: {
        // Supercharge myopia control design ?
        right?: boolean;
        left?: boolean;
    };
    question11: {
        right: string;
        left: string;
    };
    question12: string;
    evalueHistory: {
        right: {
            evalue: number;
            calculatedValue: number;
        };
        left: {
            evalue: number;
            calculatedValue: number;
        };
    };
    lensColor: {
        right: string;
        left: string;
    }
}

export interface D1DataRightEye {
    clinicName: string;
    clinicAddress: string;
    patientName: string;
    patientReference: string;
    doctorName: string;
    OrderType: orderType;
    question1: eye; // eye selection
    question2: {
        // hvid value
        right?: number;
    };
    question3: {
        // patient's refractive error
        right?: {
            sphere: number; // range - between -20 and 5
            cylinder: number; // range - between -10 and 0
            axis: axis;
        };
    };
    question4: {
        // correction would your patient like to achieve with Ortho-K?
        right?: correction;
    };
    question5: {
        // What is the Flat-K?
        right?: number;
    };
    question6: {
        // sag height of cornea at stated chord diameter? (If applicable)
        right?: number;
    };
    question7: {
        // average e value
        right?: number; // range - between -0.99 and 0.99
    };
    question8: {
        a: {
            right?: number;
        };
        b: {
            right?: number;
        };
    };
    question9: {
        // Choose your edge lift profile
        right?: optimisationLevel;
    };
    question10: {
        // Supercharge myopia control design ?
        right?: boolean;
    };
    question11: {
        right?: string;
    };
    question12: string;
    evalueHistory?: {
        right: {
            evalue: number;
            calculatedValue: number;
        };
    };
    lensColor: {
        right: string;
    }
}

export interface D1DataLeftEye {
    clinicName: string;
    clinicAddress: string;
    patientName: string;
    patientReference: string;
    doctorName: string;
    OrderType: orderType;
    question1: eye; // eye selection
    question2: {
        // hvid value
        left?: number;
    };
    question3: {
        // patient's refractive error
        left?: {
            sphere: number; // range - between -20 and 5
            cylinder: number; // range - between -10 and 0
            axis: axis;
        };
    };
    question4: {
        // correction would your patient like to achieve with Ortho-K?
        left?: correction;
    };
    question5: {
        // What is the Flat-K?
        left?: number;
    };
    question6: {
        // sag height of cornea at stated chord diameter? (If applicable)
        left?: number;
    };
    question7: {
        // average e value
        left?: number; // range - between -0.99 and 0.99
    };
    question8: {
        a: {
            left?: number;
        };
        b: {
            left?: number;
        };
    };
    question9: {
        // Choose your edge lift profile
        left?: optimisationLevel;
    };
    question10: {
        // Supercharge myopia control design ?
        left?: boolean;
    };
    question11: {
        left?: string;
    };
    question12: string;
    evalueHistory?: {
        left: {
            evalue: number;
            calculatedValue: number;
        };
    };
    lensColor: {
        left: string;
    }
}

export interface AData {
    clinicName: string;
    patientName: string;
    patientReference: string;
    practitionerName: string;
    eyes: eye;
    question1: {
        right: number;
        left: number;
    };
    question2: {
        right: number;
        left: number;
    };
    question3: {
        right: number;
        left: number;
    };
    question4: {
        right: {
            1: boolean;
            2: boolean;
            3: boolean;
            4: boolean;
            5: boolean;
            6: boolean;
        };
        left: {
            1: boolean;
            2: boolean;
            3: boolean;
            4: boolean;
            5: boolean;
            6: boolean;
        };
    };
    question5: {
        right: {
            1: number;
            2: number;
            3: axis;
        };
        left: {
            1: number;
            2: number;
            3: axis;
        };
    };
    question6: {
        right: boolean;
        left: boolean;
    };
    question7: {
        right: boolean;
        left: boolean;
    };
    question8: {
        right: number;
        left: number;
    };
    question9: string;
    question10: {
      right: string;
      left: string;
    },
    lensColor: {
      right: string;
      left: string;
  }
}

export interface ADataRightEye {
    clinicName: string;
    patientName: string;
    patientReference: string;
    practitionerName: string;
    eyes: eye;
    question1: {
        right: number;
    };
    question2: {
        right: number;
    };
    question3: {
        right: number;
    };
    question4: {
        right: {
            1: boolean;
            2: boolean;
            3: boolean;
            4: boolean;
            5: boolean;
            6: boolean;
        };
    };
    question5: {
        right: {
            1: number;
            2: number;
            3: axis;
        };
    };
    question6: {
        right: boolean;
    };
    question7: {
        right: boolean;
    };
    question8: {
        right: number;
    };
    question9: string;
    question10: {
      left: string;
    }
}
export interface ADataLeftEye {
    clinicName: string;
    patientName: string;
    patientReference: string;
    practitionerName: string;
    eyes: eye;
    question1: {
        left: number;
    };
    question2: {
        left: number;
    };
    question3: {
        left: number;
    };
    question4: {
        left: {
            1: boolean;
            2: boolean;
            3: boolean;
            4: boolean;
            5: boolean;
            6: boolean;
        };
    };
    question5: {
        left: {
            1: number;
            2: number;
            3: axis;
        };
    };
    question6: {
        left: boolean;
    };
    question7: {
        left: boolean;
    };
    question8: {
        left: number;
    };
    question9: string;
    question10: {
      left: string;
    }
}
