import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-additional-info-clinic',
  templateUrl: './additional-info-clinic.component.html',
  styleUrls: ['./additional-info-clinic.component.scss']
})
export class AdditionalInfoClinicComponent {
  @Input('isRegularClinic') isRegularClinic = false;

  headings = ['Name', 'Reference No', 'Contact No'];
  data = [
    { name: 'Tony Stark', referenceNumber: 'RN240', contactNumber: '+65 12345678' },
    { name: 'Peter Parker', referenceNumber: 'RN241', contactNumber: '+65 12345678' },
    { name: 'Albeit', referenceNumber: 'RN242', contactNumber: '+65 12345678' },
    { name: 'Wanda', referenceNumber: 'RN243', contactNumber: '+65 12345678' },
    { name: 'Clint', referenceNumber: 'RN244', contactNumber: '+65 12345678' },
  ];
  existingPatientsHeading = ['Name', 'Reference No', 'Contact No'];
  existingPatientsData = [
    { name: 'Tony Stark', referenceNumber: 'RN240', contactNumber: '+65 12345678' },
    { name: 'Peter Parker', referenceNumber: 'RN241', contactNumber: '+65 12345678' },
    { name: 'Albeit', referenceNumber: 'RN242', contactNumber: '+65 12345678' },
    { name: 'Wanda', referenceNumber: 'RN243', contactNumber: '+65 12345678' },
    { name: 'Clint', referenceNumber: 'RN244', contactNumber: '+65 12345678' },
  ];
  doctorsHeading = ['Name', 'Contact number'];
  doctorData = [
    { name: 'ARS optics', contactNumber: '+65 12345678'},
    { name: 'Lenskart optics & lens', contactNumber: '+65 12345678'},
    { name: 'SN lens & optics', contactNumber: '+65 12345678'},
    { name: 'The straits eye center', contactNumber: '+65 12345678'},
    { name: 'Marina optics & lens', contactNumber: '+65 12345678'},
  ];
  orderHeading = ['Order ID', 'Patient name', 'Date', 'Status'];
  orderData = [
    { orderId: 'OI2345', patientName: 'Tony Stark', Date: '17 Jan 2023', Status: 'Pending' },
    { orderId: 'OI2346', patientName: 'Peter Parker', Date: '18 Jan 2023', Status: 'In production' },
    { orderId: 'OI2347', patientName: 'Albeit', Date: '19 Jan 2023', Status: 'Pending' },
    { orderId: 'OI2348', patientName: 'Wanda', Date: '20 Jan 2023', Status: 'Completed' },
    { orderId: 'OI2349', patientName: 'Clint', Date: '21 Jan 2023', Status: 'In production' },
  ];
}
