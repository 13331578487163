import { GetOnboardUserFormDataQuery, GetMoreDetailsOfUserQuery, GetRoleDetailsQuery } from '@app/generated/graphql';
import { Action, createReducer, on } from '@ngrx/store';

import {
    setLoadingSpinner,
    setErrorMessage,
    getRoleDetailsSuccess,
    getLoggedInUserEmailSuccess,
    storeUserPermissionsSuccess,
    getOnboardUserFormDataSuccess,
    getMoreDetailsOfUserSuccess,
    addItemToBreadCrumpUrlCollection,
    clearBreadCrumpUrlCollection,
    updateBreadCrumpRootUrl,
    updateBreadCrumpUrlCollection,
    updateSideNavBarStatus,
    resetOnboardedUserData,
    setOpaqueLoadingSpinner,
    getGrantedRoleListPerUserSuccess,
    updateModuleChange,
    getCountryCodeDetailsSuccess,
    updateFormMode,
} from '../actions/ui.actions';
import { initialState, UIState, UrlCollection, UserPermissionsData } from '../models/ui.model';
import { resetAppState } from '@app/actions';

const _uiReducer = createReducer(
    initialState,
    on(setLoadingSpinner, (state, action) => {
        return {
            ...state,
            showLoading: action.status,
        };
    }),
    on(setOpaqueLoadingSpinner, (state, action) => {
      return {
        ...state,
        showOpaqueLoader: action.status
      }
    }),
    on(setErrorMessage, (state, action) => {
        return {
            ...state,
            errorMessage: action.message,
        };
    }),
    on(getRoleDetailsSuccess, (state, action: { data: GetRoleDetailsQuery }) => {
        return {
            ...state,
            lookUp: {
                ...state.lookUp,
                userRoleId: action.data,
            },
        };
    }),
    on(getLoggedInUserEmailSuccess, (state, action) => {
        return {
            ...state,
            emailOfLoggedInUser: action.data,
        };
    }),
    on(storeUserPermissionsSuccess, (state, action: { permissions: UserPermissionsData}) => {
        return {
            ...state,
            lookUp: {
                ...state.lookUp,
                role_permissions: action.permissions,
            },
        };
    }),
    on(getOnboardUserFormDataSuccess, (state, action: { onboardFormData: GetOnboardUserFormDataQuery }) => {
        return {
            ...state,
            lookUp: {
                ...state.lookUp,
                onboardUserFormData: action.onboardFormData,
            },
        };
    }),
    on(getMoreDetailsOfUserSuccess, (state: UIState, action: { moreUserDetails: GetMoreDetailsOfUserQuery }) => {
        return {
            ...state,
            lookUp: {
                ...state.lookUp,
                userMoreDetails: action?.moreUserDetails,
            },
        };
    }),
    on(updateBreadCrumpUrlCollection, (state: UIState, action: { urlCollection: UrlCollection[]}) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          urlCollection: { ...state.lookUp.urlCollection, urlCollection: action.urlCollection}
        }
      }
    }),
    on(addItemToBreadCrumpUrlCollection, (state: UIState, action: { urlCollection: UrlCollection }) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          urlCollection: { ...state.lookUp.urlCollection, urlCollection: [...state.lookUp.urlCollection.urlCollection,action.urlCollection]}
        }
      }
    }),
    on(updateBreadCrumpRootUrl, (state: UIState, action: { rootUrl: string}) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          urlCollection: { ...state.lookUp.urlCollection, rootUrl: action.rootUrl}
        }
      }
    }),
    on(updateModuleChange, (state: UIState, {isModuleChanged}) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          urlCollection: { ...state.lookUp.urlCollection, isModuleChanged}
        }
      }
    }),
    on(clearBreadCrumpUrlCollection, (state: UIState) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          urlCollection: { ...state.lookUp.urlCollection, rootUrl: '', urlCollection: [], isModuleChanged: false}
        }
      }
    }),
    on(updateSideNavBarStatus, (state: UIState, action: { sideNavBarStatus: boolean}) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          hideSideAndNavBar: action.sideNavBarStatus
        }
      }
    }),
    on(resetOnboardedUserData, (state) => {
      return {
          ...state,
          lookUp: {
              ...state.lookUp,
              onboardUserFormData: {}
          },
      };
    }),
    on(getGrantedRoleListPerUserSuccess, (state: UIState, { grantedRoles }) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          grantedRoles
        }
      }
    }),
    on(getCountryCodeDetailsSuccess, (state: UIState, { countryCodeDetails }) => {
      return {
        ...state,
        lookUp: {
          ...state.lookUp,
          countryCodeDetails
        }
      }
    }),
    on(updateFormMode, (state: UIState, {formMode}) => {
      return {
        ...state,
        formMode
      }
    }),
    on(resetAppState, () => initialState)
);

export function uiReducer(state: UIState | undefined, action: Action) {
    return _uiReducer(state, action);
}
