import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";
import { ApolloQueryResult } from "@apollo/client/core";

import { GetClinicsByDoctorIdGQL, GetClinicsByDoctorIdQuery, GetClinicsByDoctorIdQueryVariables, GetDoctorsListBasedOnRoleGQL, GetDoctorsListBasedOnRoleQuery, GetDoctorsListBasedOnRoleQueryVariables, GetTotalTableItemCountForDoctorsGQL, GetTotalTableItemCountForDoctorsQuery, GetTotalTableItemCountForDoctorsQueryVariables, SaveClinicDetailsOfDoctorsGQL, SaveClinicDetailsOfDoctorsMutation, SaveClinicDetailsOfDoctorsMutationVariables } from "@app/generated/graphql";
import { MutationResult } from "apollo-angular";
import { User } from "@app/shared/models/shared.model";
import { DoctorListData } from "@app/doctors/models/doctors.model";
import { Roles } from "../util/common";

@Injectable({
    providedIn: 'root'
})

export class DoctorsService{

    constructor(
        private _getDoctorTableListForSA: GetDoctorsListBasedOnRoleGQL,
        private _getTableListTotalCount: GetTotalTableItemCountForDoctorsGQL,
        private _saveClinicDetailsOfDoctors: SaveClinicDetailsOfDoctorsGQL,
        private _getClinicsByDoctorId: GetClinicsByDoctorIdGQL,
    ){}

    getDoctorsTableListForSA = (payload: GetDoctorsListBasedOnRoleQueryVariables): Observable<ApolloQueryResult<GetDoctorsListBasedOnRoleQuery>> => {
        return this._getDoctorTableListForSA.watch(payload).valueChanges;
    }
    getTotalTableItemCount = (params: GetTotalTableItemCountForDoctorsQueryVariables): Observable<ApolloQueryResult<GetTotalTableItemCountForDoctorsQuery>> => {
        return this._getTableListTotalCount.watch(params).valueChanges;
    }
    saveClinicDetailsOfDoctors = (params: SaveClinicDetailsOfDoctorsMutationVariables): Observable<MutationResult<SaveClinicDetailsOfDoctorsMutation>> => {
        return this._saveClinicDetailsOfDoctors.mutate(params);
    }
    getClinicsByDoctorId = (params: GetClinicsByDoctorIdQueryVariables): Observable<User[]> => {
      return this._getClinicsByDoctorId.watch(params).valueChanges.pipe(
        map((data: ApolloQueryResult<GetClinicsByDoctorIdQuery>) => {
          const clinicList: User[] = this.transformResponseOfClinicsByDoctorId(data?.data);
          return clinicList;
        })
      );
    };

    transformResponseOfClinicsByDoctorId = (data: GetClinicsByDoctorIdQuery):User[]  => {
      const clinics = data?.clinic_doctor_mapping?.map((item) => {
        let clinic: User  = { id: item?.user?.user_role_mappings?.[0]?.user_id, name: item?.user?.user_role_mappings?.[0]?.name || ''};
        return clinic;
      })
      return clinics;
    }

    transformToDoctorListData = (queryData: GetDoctorsListBasedOnRoleQuery, role: string): DoctorListData[] => {
      let sourceData;

      switch (role) {
        case Roles.REGULAR_CLINIC:
          sourceData = queryData?.regularCllinic;
          break;
        case Roles.FRANCHISE_CLINIC:
          sourceData = queryData?.franchiseClinic;
          break;
        case Roles.SUPER_ADMIN:
          sourceData = queryData?.superAdmin?.map(admin => ({ userByDoctorId: { user_role_mappings: [admin] }}));
          break;
        case Roles.DESIGNER:
          sourceData = queryData?.designer?.map(designer => ({ userByDoctorId: { user_role_mappings: [designer] }}));
          break;
        case Roles.EXCLUSIVE_DISTRIBUTOR:
        case Roles.SELECTIVE_DISTRIBUTOR:
          sourceData = queryData?.distributor.map(distributor => ({ userByDoctorId: { user_role_mappings: [distributor] }}));
          break;
        default:
          return [];
      }

      const roleMappingIdSet = new Set();
      const uniqueDoctors = [];

      for (const item of sourceData) {
        const userMapping = item?.userByDoctorId?.user_role_mappings?.[0];
        if (roleMappingIdSet?.has(userMapping?.id)) {
          continue;
        }
        roleMappingIdSet?.add(userMapping?.id);

        uniqueDoctors?.push({
          id: userMapping?.user?.id,
          name: userMapping?.name || '',
          email: userMapping?.user?.email || '',
          country_code: userMapping?.user?.country_code || '',
          contact_number: userMapping?.user?.contact_number || '',
          is_active: '',
          created_at: userMapping?.user?.created_at || '',
          role_mapping_id: userMapping?.id,
          role_id: userMapping?.role_id || ''
        });
      }

      return uniqueDoctors;
    }

}
