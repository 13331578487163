import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';

/**
 * Use this directive to trigger click event outside native element where the
 * directive sits.
 * @example use it to close a dropdown when clicked out the scope.
 */
@Directive({
    selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
    // Send event when mouse is clicked outside of the native element.
    @Output() clickOutside = new EventEmitter<void>();

    documentClickSubscription: Subscription | undefined;

    constructor(
        private element: ElementRef, // Get native element in which the directive sits.
        @Inject(DOCUMENT) private document: Document // Get DOM document.
    ) {}

    ngAfterViewInit(): void {
        /**
         * Subscribe to the click event, and emit the event on trigger.
         * @var documentClickSubscription stores the subscription.
         * @param document represents the DOM.
         * @param event stores the element.
         */
        this.documentClickSubscription = fromEvent(this.document, 'click')
            .pipe(
                filter((event) => {
                    // Return element only if the clicked element is outside the native element.
                    return !this.isInside(event.target as HTMLElement);
                })
            )
            .subscribe(() => {
                // Here receives the not native element clicks and emits the click event.
                this.clickOutside.emit();
            });
    }

    ngOnDestroy(): void {
        this.documentClickSubscription?.unsubscribe();
    }

    /**
     * Check if the element is native element or its child elements.
     * @param elementToCheck represents the element where the click event triggered.
     * @returns true if the element is a native element or child elements.
     * @returns false if the element is an outside element.
     */
    isInside(elementToCheck: HTMLElement): boolean {
        return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
    }
}
