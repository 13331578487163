import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ActionButtonType, Roles } from '@app/core/util/common';
import { getDeletingUserSuperiorSuccess, getRoleSpecificUsers } from '@app/shared/actions/shared.action';
import { selectDeletingUserSuperiorId, selectRoleSpecificUsers } from '@app/shared/selectors/shared.selector';
import { GetRoleSpecificUsersQueryVariables } from '@app/generated/graphql';
import { DoctorsService } from '@app/core/services/doctors.service';
import { User } from '@app/shared/models/shared.model';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ContractedManufacturerService } from '@app/core/services/contracted-manufacturers.service';

@Component({
    selector: 'app-user-delete-confirm-modal',
    templateUrl: './user-delete-confirm-modal.component.html',
    styleUrls: ['./user-delete-confirm-modal.component.scss'],
})
export class UserDeleteConfirmModalComponent implements OnInit, OnDestroy {
    data!: any;
    userId = '';
    loggedInUserRole = '';
    removeDoctorFromClinicWithId = '';
    removeManufacturerFromDistributorWithId = '';
    event!: string;
    onDeleteAndAssignUserContinue = false;
    userList$ = this._store.select(selectRoleSpecificUsers);;
    isAlive = true;
    deletingUserSuperiorId = '';
    clinics: User[] | null = null;
    distributors: User[] | null = null;
    noClinicSelected = false;
    noDistributorSelected = false;

    @Output() deleteAndAssignUser = new EventEmitter<{ selectedUserId: string }>();
    @Output() deleteUser = new EventEmitter<boolean>();

    constructor(private _activeModal: NgbActiveModal, private _store: Store, private _doctorService: DoctorsService, private _credentialService: CredentialsService, private _contractedManufacturerService: ContractedManufacturerService) {}

    ngOnInit(): void {
        this.event = this.data?.event; // Get data which requires to render the delete model and delete and reassign user.
        this.loggedInUserRole = this._credentialService?.credentials?.role || '';
        // Get assignee user list which don't have a superior except super admin.
        if (this.data && this.data?.data?.role_id && this.data?.roleIdentifier === Roles.DESIGNER) {
            this.getAssigneeUserListWithOutSuperiorId(this.data?.roleIdentifier);
        }

        this._store
            .select(selectDeletingUserSuperiorId)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((deletingUserSuperiorId: string | null) => {
                if (deletingUserSuperiorId && this.data?.roleIdentifier !== Roles.DESIGNER && this.data?.roleIdentifier !== Roles.DOCTOR) {
                    this.deletingUserSuperiorId = deletingUserSuperiorId;
                    this.getAssigneeUserListWithSuperiorId(this.data?.roleIdentifier); // Ge assignee list who has a superior so that to load only list which is under the superior.
                }
            });
        if(this.data?.roleIdentifier === Roles.DOCTOR && this.data?.event === ActionButtonType.OpenUserDeleteOptionModal) {
          this.getClinicsByDoctorId();
        }
        if(this.data?.roleIdentifier === Roles.CONTRACT_MANUFACTURER && this.data?.event === ActionButtonType.OpenUserDeleteOptionModal) {
          this.getDistributorListByManufacturerId();
        }
        if(this.data?.roleIdentifier === Roles.DOCTOR){
          this.getAssigneeUserListWithOutSuperiorId(Roles.DOCTOR);
        }
        if(this.data?.roleIdentifier === Roles.CONTRACT_MANUFACTURER){
          this.getAssigneeUserListWithOutSuperiorId(Roles.CONTRACT_MANUFACTURER);
        }
    }

    closeModal = (): void => {
        this._activeModal.dismiss();
    };

    onDeleteAndAssignUser = (): void => {
        if(this.data?.event === ActionButtonType.OpenUserDeleteOptionModal && this.data?.roleIdentifier === Roles.DOCTOR && !this.removeDoctorFromClinicWithId && this.loggedInUserRole === Roles.SUPER_ADMIN){
          this.noClinicSelected = true;
          return;
        }
        if(this.data?.event === ActionButtonType.OpenUserDeleteOptionModal && this.data?.roleIdentifier === Roles.CONTRACT_MANUFACTURER && !this.removeManufacturerFromDistributorWithId && this.loggedInUserRole === Roles.SUPER_ADMIN){
          this.noDistributorSelected = true;
          return;
        }
        this._activeModal.close({ action: ActionButtonType.DeleteAndAssignUser, assigneeUserId: this.userId, removeDoctorFromClinicWithId: this.removeDoctorFromClinicWithId, removeManufacturerFromDistributorWithId: this.removeManufacturerFromDistributorWithId });
    };

    onDeleteUser = (): void => {
      if(this.data?.event === ActionButtonType.OpenUserDeleteOptionModal && this.data?.roleIdentifier === Roles.DOCTOR && !this.removeDoctorFromClinicWithId && this.loggedInUserRole === Roles.SUPER_ADMIN){
        this.noClinicSelected = true;
        return;
      }
      if(this.data?.event === ActionButtonType.OpenUserDeleteOptionModal && this.data?.roleIdentifier === Roles.CONTRACT_MANUFACTURER && !this.removeManufacturerFromDistributorWithId && this.loggedInUserRole === Roles.SUPER_ADMIN){
        this.noDistributorSelected = true;
        return;
      }
      this._activeModal.close({ action: ActionButtonType.DeleteUser, deleteUserId: this.data?.data?.id, removeDoctorFromClinicWithId: this.removeDoctorFromClinicWithId, removeManufacturerFromDistributorWithId: this.removeManufacturerFromDistributorWithId });
    };

    /**
     * Get assignee list who is under the super of the user getting deleted.
     * @param roleName
     */
    getAssigneeUserListWithSuperiorId = (roleName: Roles): void => {
        let params: GetRoleSpecificUsersQueryVariables = {
            isClinic: false,
            isDesigner: false,
            isDistributor: false,
            isDoctor: false,
            isManufacturer: false,
            deletingUserId: this.data?.data?.id,
        };
        params = { ...params, superiorId: this.deletingUserSuperiorId };
        if (roleName === Roles.EXCLUSIVE_DISTRIBUTOR || roleName === Roles.SELECTIVE_DISTRIBUTOR) {
            this._store.dispatch(
                getRoleSpecificUsers({
                    ...params,
                    isDistributor: true,
                    distributorType: roleName
                })
            );
        }
        if (roleName === Roles.FRANCHISE_CLINIC || roleName === Roles.REGULAR_CLINIC) {
            this._store.dispatch(
                getRoleSpecificUsers({
                    ...params,
                    isClinic: true,
                    clinicType: roleName
                })
            );
        }
        if (roleName === Roles.DOCTOR) {
            this._store.dispatch(
                getRoleSpecificUsers({
                    ...params,
                    isDoctor: true,
                })
            );
        }
    };

    /**
     * Get assignee list without passing any superior id.
     * @param roleName
     */
    getAssigneeUserListWithOutSuperiorId = (roleName: Roles): void => {
        let params: GetRoleSpecificUsersQueryVariables = {
            isClinic: false,
            isDesigner: false,
            isDistributor: false,
            isDoctor: false,
            isManufacturer: false,
            deletingUserId: this.data?.data?.id,
        };
        if (roleName === Roles.DESIGNER) {
            this._store.dispatch(
                getRoleSpecificUsers({ ...params, roleId: this.data?.data?.role_id, isDesigner: true })
            );
        }
        if (roleName === Roles.DOCTOR) {
          this._store.dispatch(
            getRoleSpecificUsers({ ...params, roleId: this.data?.data?.role_id, isDoctor: true })
        );
        }

        if (roleName === Roles.CONTRACT_MANUFACTURER) {
          this._store.dispatch(
              getRoleSpecificUsers({
                ...params,
                isManufacturer: true
              })
          )
        }
    };

    getClinicsByDoctorId = () => {
      this._doctorService.getClinicsByDoctorId({doctorId: this.data?.data?.id}).pipe((takeWhile(() => this.isAlive))).subscribe((clinics: User[]) => {
        if(clinics){
          this.clinics = clinics;
        }
      });
    }

    getDistributorListByManufacturerId = () => {
      this._contractedManufacturerService.getDistributorListByManufacturerId({manufacturerId: this.data?.data?.id}).pipe((takeWhile(() => this.isAlive))).subscribe((distributorList: User[]) => {
        if(distributorList){
          this.distributors = distributorList;
        }
      })
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        this._store.dispatch(getDeletingUserSuperiorSuccess(''));
    }
}
