import { Component, Input } from '@angular/core';
import { PatientInfo } from '@app/shared/models/patient-details.model';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent {
  @Input() patientInfo$: Observable<PatientInfo[]> = of([]);
}
