import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-lens-info',
    templateUrl: './lens-info.component.html',
    styleUrls: ['./lens-info.component.scss'],
})
export class LensInfoComponent implements OnChanges {
    lensMaterialRight = '';
    lensMaterialLeft = '';
    @Input() hideButtons = false;
    @Input() lensDetails: {
        lensColorRight: string;
        lensColorLeft: string;
        lensMaterialRight: string;
        lensMaterialLeft: string;
        lab_of_choice: string;
    } = {
      lensColorRight: '',
      lensColorLeft: '',
      lensMaterialLeft: '',
      lensMaterialRight: '',
      lab_of_choice: ''
    }
    @Output() submitOrderEvent = new EventEmitter<boolean>();
    @Output() cancelOrderEvent = new EventEmitter<boolean>();
    @Output() gotoFilledForm = new EventEmitter<boolean>();
    @Output() saveDraftEvent = new EventEmitter<boolean>()

    ngOnChanges() {
        // lens material name is store to these variables for right and left eye
        this.lensMaterialRight = this.lensDetails.lensMaterialRight || '';
        this.lensMaterialLeft = this.lensDetails.lensMaterialLeft || '';
    }

    /**
     * Submits the order and inform parent regarding this
     * @params Nil
     * @returns void
     */
    submitOrder = (): void => {
        this.submitOrderEvent.emit(true);
    };

    /**
     * Cancels the order and inform parent regarding this
     * @params Nil
     * @returns void
     */
    onCancel = (): void => {
        this.cancelOrderEvent.emit(true);
    };

    onPreviousSelection = (): void => {
        this.gotoFilledForm.emit(true);
    };

    /**
     * Save order as draft.
     */
    onSaveDraft = (): void => {
      this.saveDraftEvent.emit(true);
    }
}
