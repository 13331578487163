import { Component, Input } from '@angular/core';

import { Observable, of } from 'rxjs';

import { PatientInfo } from '@app/shared/models/patient-details.model';
import { DoctorSlipDetails, EyeSide, eye } from '@app/core/treatment-engine/interfaces';
import { initializeDoctorSlipDetails } from '@app/core/util/treatment-output-details';
import { LensType } from '@app/core/util/common';

@Component({
    selector: 'app-doctors-slip',
    templateUrl: './doctors-slip.component.html',
    styleUrls: ['./doctors-slip.component.scss'],
})
export class DoctorsSlipComponent {
    @Input() showBothSidesData = false;
    @Input() doctorFormData: DoctorSlipDetails = initializeDoctorSlipDetails;
    @Input() patientInfoDetailsToPass$: Observable<PatientInfo[]> = of();
    @Input() patientDataToDisplay: PatientInfo[] = [];

    ngOnChanges(): void {

        // update special instructions value based on target eye, to show only data for that eye
        if (this.doctorFormData && !this.showBothSidesData) {
            const side = this.doctorFormData.targetEye;
            const instructions = side === EyeSide.RIGHT ? eye.right : eye.left;
            this.doctorFormData = {
                ...this.doctorFormData,
                specialInstructions: instructions
            }
        }
    }

    getMaterialStyle = (side: string): {'color': string} => {
      if(side === EyeSide.LEFT){
        return {
          'color': this.doctorFormData?.lensColor?.left
        }
      }
      return {
        'color': this.doctorFormData?.lensColor?.right
      }
    }

    getLensTypeStyle = (value: string): any => {
      if(value === LensType.TORIC_LENS){
        return {
          'color': '#ff3154',
          'background-color': 'rgb(133 169 131 / 26%)',
          'padding': '5px'
        }
      }
      return {};
    }
}
