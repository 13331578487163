import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';

import { ROUTES } from '@app/core/util/ui-common-data';
import { RouteInfo } from '@app/shell/models/admin.model';
import { UserPermissionsData } from '@app/ui/models/ui.model';
import { getLoggedInUserPermissions } from '@app/ui/selectors/ui.selector';
import { setOpaqueLoadingSpinner, updateModuleChange } from '@app/ui/actions/ui.actions';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Roles, Url } from '@app/core/util/common';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy{
    path = '';
    isAlive = true;
    clinicType = '';
    role = '';
    isMobile = window.innerWidth <= 586;
    isSmallDevice = window.innerWidth <= 1200;
    menuItems: RouteInfo[] = [];
    completeMenuItems: RouteInfo[] = [];
    userRolePermission!: UserPermissionsData;
    rolePermissions$ = new Observable<UserPermissionsData>();
    rolePermissionsSubscription$ = new Subscription();

    @Input() isExpand!: boolean;
    @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

    constructor(private _router: Router, private _store: Store, private _credentialService: CredentialsService) {
        this._store.dispatch(setOpaqueLoadingSpinner({ status: true }));
        this.completeMenuItems = ROUTES.filter((menuItem) => menuItem);
    }

    ngOnInit(): void {
        this.clinicType = this._credentialService.credentials?.clinicType || '';
        this.role = this._credentialService.credentials?.role || '';
        // Set sidebar active link from url path.
        this.path = this._router.url;
        this.menuItems = ROUTES.filter((menuItem) => menuItem);

        this.rolePermissions$ = this._store.pipe(
            takeWhile(() => this.isAlive),
            select(getLoggedInUserPermissions)
        );

        this.rolePermissionsSubscription$ = this.rolePermissions$
        .subscribe((permissions: UserPermissionsData) => {
            this.userRolePermission = permissions;
            if (Object.keys(this.userRolePermission?.module_details)?.length > 0) {
                this.updateSideBarBasedOnPermissions()
            }
            else {
                this.closeOpaqueLoader();
            }
        });
    }

    /**
     * Updates menuItem arrays isViewableByCurrentRole boolean value
     * This based on whether the user is allowed to see that module
     * @param Nil
     * @returns void
     */
    updateSideBarBasedOnPermissions = (): void => {
        this.menuItems = this.completeMenuItems;
        if(this.role !== Roles.PATIENT){
          this.menuItems = [
            ...this.menuItems,
            {
              path: '',
              title: 'Insights',
              iconClass: '',
              class: '',
              iconSrc: 'review-history',
              iconSrcHover: 'review-history-white',
              permissionName: 'show_insights',
              isViewableByCurrentRole: true,
              showRightArrowIcon: true,
              notClickable: true,
              showSideMenu: false,
              sideMenu: [
                {
                path: '/user/insights',
                title: 'Reports',
                iconClass: '',
                class: '',
                iconSrc: '',
                iconSrcHover: '',
                permissionName: 'show_reports',
                isViewableByCurrentRole: true,
                showDownArrowIcon: false,
                showRightArrowIcon: false,
                showUpArrowIcon: false,
                showSideMenu: false
              },
            ]
            }
          ]
        }
        this.menuItems.map((item: RouteInfo) => {
            const permissionName = item?.permissionName;
            item.isViewableByCurrentRole = permissionName && item.permissionName !== "main_item"
                ? this.userRolePermission?.module_details[permissionName]
                : true;

            return item;
        });
        const targetIndex2 = this.menuItems.findIndex((item) => item.title === "Partner");
        const targetIndex5 = this.menuItems.findIndex((item) => item.title === "Gold Partners");
        const targetIndex6 = this.menuItems.findIndex((item) => item.title === "Partners");

        this.menuItems[targetIndex2].isViewableByCurrentRole =
            this.menuItems[targetIndex5]?.isViewableByCurrentRole ||
            this.menuItems[targetIndex6]?.isViewableByCurrentRole
            ? true
            : false;
        this.menuItems = this.menuItems.filter(item => item.isViewableByCurrentRole);
        if (this.clinicType === Roles.REGULAR_CLINIC) {
          this.menuItems = this.menuItems.filter(item => {
            // Remove unwanted menu items based on their path
            return item.path !== Url.EYE_INFO && item.path !== Url.TREATMENT_REVIEW;
          });
        }
        this.closeOpaqueLoader();
    }

    /**
     * Overlay screen hide after ui is done loading
     */
    closeOpaqueLoader = (): void => {
        setTimeout(() => {
            this._store.dispatch(setOpaqueLoadingSpinner({ status: false }));
        }, 1000);
    }

    /**
     * Return icons based on hover and non hover and
     * also for menus where icon is not needed it is discarded
     * @param item serves details of menu item clicked
     */
    getMenuIcon = (menuItem: RouteInfo): string => {
        this.path = this._router.url;
        if (this.path.includes(menuItem.path) && menuItem.path !== '') {
            return '/assets/icons/sidebar/' + menuItem.iconSrcHover + '.svg';
        }
        return '/assets/icons/sidebar/' + menuItem.iconSrc + '.svg'
    }

    /**
     * Set current path based on sidebar link click
     * @param item serves name of the link clicked
     */
    onNavigateToLink = (item: RouteInfo, event?: MouseEvent): void => {
        event?.stopPropagation();
        if(item?.showRightArrowIcon && Array.isArray(item?.sideMenu)){
            item.showSideMenu = true;
        }
        if (item.notClickable) {
            this.menuItems.forEach((menuItem) => {
                if(item?.path !== menuItem?.path){
                  this.hideAllSideMenu(menuItem);
                }
            });
            return;
        }
        this.menuItems.forEach((menuItem) => {
            this.hideAllSideMenu(menuItem);
        });
        this._store.dispatch(updateModuleChange(true));
        this.path = item.path;
        this._router.navigateByUrl(item.path);
        if(this.isMobile){
          this.onToggleView();
        }
    };

    hideAllSideMenu = (item: RouteInfo):void => {
      item.showSideMenu = false;
      item?.sideMenu?.forEach((i) => {
          this.hideAllSideMenu(i);
      })
  }


    /**
     * Used to expand or collapse sidebar
     * @var this.isExpand controls the expand and collapse state of sidebar
     * @event this.onToggle emits sidebar ui status to admin component
     * So that admin component can adjust ui with of main section accordingly
     */
    onToggleView = (): void => {
      this.onToggle.emit(this.isExpand);
      this.menuItems.forEach((menuItem) => {
        this.hideAllSideMenu(menuItem);
      });
    };

    /**
     * Used to show/hide sub items of a main menu
     * hideLink key of the menu item object is set to false/true based on the click
     * this is then checked in li tag ngClass and if its true a class is assigned to hide it
     * @param item Single menu item
     * @returns void
     */
    onShowHideSubMenu = (item: RouteInfo): void => {
        item.showDownArrowIcon = !item.showDownArrowIcon;
        item.showUpArrowIcon = !item.showUpArrowIcon;
        if (item.title === 'Partner') {
            this.menuItems.map((item: RouteInfo) => {
                if (item.isChildLink && item.ChildLinkOf === 'Partner') {
                    item.hideLink = !item.hideLink;
                }
                return item;
            });
        }
    }
    ngOnDestroy() {
        this.isAlive = false;
        this.rolePermissionsSubscription$.unsubscribe();
    }
}
