import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2, TemplateRef } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, takeWhile } from 'rxjs';

import { Roles, Url } from '@app/core/util/common';
import { UrlCollection } from '@app/ui/models/ui.model';
import { UtilService } from '@app/core/services/util.service';
import { AuthService } from '@app/core/services/auth.service';
import { GetMoreDetailsOfUserQuery } from '@app/generated/graphql';
import { getMoreDetailsOfUser } from '@app/ui/actions/ui.actions';
import { CredentialsService } from '@app/core/services/credentials.service';
import { selectUrlCollection, userMoreDetails } from '@app/ui/selectors/ui.selector';
import { selectParentPatientId, selectSiblingList } from '@app/auth/selectors/auth.selector';
import { Patient } from '@app/auth/models/auth.model';
import { getSiblingList } from '@app/auth/actions/auth.action';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {

    // Consider app is loaded in mobile if screen width is <= 586.
    isMobile = window.innerWidth <= 600;
    isAlive = true;
    role = '';
    userId = '';
    userRoleName = '';
    userProfileName = '';
    organizationLogo = 'assets/images/authentication/kalculus-logo.png';
    profilePic = 'assets/icons/navbar-profile/profile-pic.png';
    showFamilyMemberLink = false;
    parentPatientId = '';
    screenWidth = window?.innerWidth;
    viewChangePassword = true;
    widthTarget = 991;
    breadCrumbs: UrlCollection[] = [];
    userDetails$ = new Observable<GetMoreDetailsOfUserQuery>();
    breadCrumbs$ = new Observable<{ rootUrl: string; urlCollection: UrlCollection[] }>();
    userDetailSubscription = new Subscription();
    familyMembers$!: Observable<Patient[] | null>;
    // Emit sidebar toggle (expand or collapse) event.
    @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _renderer: Renderer2,
        private _credentialService: CredentialsService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _store: Store,
        private _modalService: NgbModal,
        private _utilService: UtilService,
        private _authService: AuthService
    ) {}

    ngOnInit(): void {
        this.role = this._credentialService?.credentials?.role || '';
        this.userId = this._credentialService?.credentials?.userId || '';
        this.parentPatientId =  localStorage.getItem('parentPatientId') || '';
        // Get user details to fill user name, role etc.
        this.userDetails$ = this._store.pipe(
            takeWhile(() => this.isAlive),
            select(userMoreDetails)
        );
        this.getUserRoleNameForDisplay();

        // breadcrumbs related
        this.breadCrumbs$ = this._store.pipe(
            takeWhile(() => this.isAlive),
            select(selectUrlCollection)
        );
        this.breadCrumbs$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((urlCollection: { rootUrl: string; urlCollection: UrlCollection[] }) => {
                this.breadCrumbs = urlCollection?.urlCollection;
        });

        if(this.role === Roles.PATIENT){
            if(this.parentPatientId){
              this.getFamilyMemberDetails();
            }
        }
    }

    getUserRoleNameForDisplay = (): void => {
        this.userDetailSubscription = this.userDetails$.subscribe((userDetails: GetMoreDetailsOfUserQuery) => {
            const roleName = this._credentialService.getActualRoleNameForDisplay(
                userDetails?.user_role_mapping?.[0]?.role?.name
            ) || '';
            this.userProfileName = this._utilService.createUserLogoBasedOnName(userDetails.user_role_mapping?.[0]?.name || '');
            switch(roleName) {
                case 'Doctor':
                    this.userRoleName = 'Practitioner';
                break;
                case 'Regular Clinic':
                    this.userRoleName = 'Partner';
                break;
                case 'Franchise Clinic':
                    this.userRoleName = 'Gold Partner'
                break;
                default:
                    this.userRoleName = roleName
            }
        });
    }

    /**
     * Toggle dropdown on click.
     * @param el represents the dropdown element.
     * @constant hasShow stores elements which has @class show.
     */
    onDropdownClicked = (el: HTMLUListElement): void => {
        const hasShow = el.classList.contains('show');
        hasShow ? this._renderer.removeClass(el, 'show') : this._renderer.addClass(el, 'show');
    };

    /**
     * Hide dropdown when clicked outside.
     * @param el represents the element which should be hidden.
     */
    onClickOutside = (el: HTMLUListElement): void => {
        this._renderer.removeClass(el, 'show');
    };

    /**
     * Emit open sidebar event.
     * Triggers when hamburger icon is clicked in mobile view.
     */
    onToggleSidebar = (): void => {
        this.onToggle.emit(true);
    };

    /**
     * Clear all local storage details like token, redirect to login
     * Change isLoggedIn key in state to false for auth state
     * @param Nil
     * @returns void
     */
    onLogout = (): void => {
        this._authService.clearAllUserDetailsAndLogOut();
    };

    /**
     * Opens a modal from ng bootstrap
     * Pass in the config, actual content of the modal to the service to open the modal
     * @param content Referece to the actual modal template
     * @returns void
     */
    openVerticallyCentered = (content: TemplateRef<unknown>): void => {
        this._modalService.dismissAll();
        this._modalService.open(content, {
            centered: true,
            windowClass: 'change-password-container',
            backdrop: 'static',
            keyboard: false,
        });
    };

    getFamilyDetailsAndOpenModal = (content: TemplateRef<unknown>) => {
      this._store.dispatch(getSiblingList({parentId: this.parentPatientId}));
      this.familyMembers$ = this._store.select(selectSiblingList).pipe(takeWhile(() => this.isAlive));
      this.openVerticallyCentered(content);
    }

    /**
     * Event emitted from popup close is catched here, which inturn closes all popups
     * @param event Data emitted from child component, here change password
     * @returns void
     */
    closePopup = (): void => {
        this._modalService.dismissAll();
    };

    /**
     * Event emitted from user profile edit popup close is catched here, which inturn closes all popups
     */
    closeEditUserPopup = (event: {userId: string, roleId: string}): void => {
        this._modalService.dismissAll();
        this._store.dispatch(getMoreDetailsOfUser({ userId: event.userId, roleId: event.roleId }));

    };

    /**
     * On clicking logo goto Home page
     * @param Nil
     * @returns void
     */
    onLogoClick = (): void => {
        this._router.navigateByUrl(Url.DASHBOARD);
    };

    /**
     * Clicking on a breadcrumb, redirect user to respective page
     * @param link Link to redirect to
     */
    onBreadCrumbClick = (link: UrlCollection): void => {
      const { baseUrl, queryParams } = this.splitUrlAndQueryParams(link?.urlWithParams);
      this._router.navigate([baseUrl], { relativeTo: this._route, queryParams: {...queryParams} });
    };

    splitUrlAndQueryParams(url: string) {
      // Split the URL by the '?' character
      const [baseUrl, queryString] = url.split('?');

      // Initialize an object to hold the query parameters
      const queryParams: { [key: string]: any } = {};

      // Split the query string by the '&' character
      queryString?.split('&').forEach(param => {
        // Split each parameter into a key-value pair
        const [key, value] = param.split('=');

        // Decode the URI components and add them to the queryParams object
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
      });

      // Return the base URL and query parameters as an object
      return {
        baseUrl,
        queryParams
      };
    }

    getFamilyMemberDetails = ():void => {
      this._store.dispatch(getSiblingList({parentId: this.parentPatientId}));
      this._store.select(selectSiblingList).pipe(takeWhile(() => this.isAlive)).subscribe((siblingList) => {
        if(siblingList && siblingList?.length > 0){
          this.showFamilyMemberLink = true;
        }
      })
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        if(this.userDetailSubscription){
          this.userDetailSubscription.unsubscribe();
        }
    }
}
