import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Use this modal for cases which has confirm and cancel button.
 */
@Component({
  selector: 'app-alert-popup2',
  templateUrl: './alert-popup2.component.html',
  styleUrls: ['./alert-popup2.component.scss']
})
export class AlertPopup2Component implements OnInit {
  @Input() dataFromParent!: { title: string, description: string };

  constructor(public _activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  onConfirm = (): void => {
    this._activeModal.close(true);
  }

  onCancel = (): void => {
    this._activeModal.close(false);
  }

}
