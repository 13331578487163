import { Component, Input } from '@angular/core';

import { AData } from '@app/core/treatment-engine/interfaces';
import { ReviewSummaryHeading } from '@app/core/util/common';
import { initializeAmendDetails } from '@app/core/util/treatment-output-details';

@Component({
  selector: 'app-amend-summary-details',
  templateUrl: './amend-summary-details.component.html',
  styleUrls: ['./amend-summary-details.component.scss']
})
export class AmendSummaryDetailsComponent {
  @Input() amendData: AData = initializeAmendDetails;
  @Input() summaryFormName: ReviewSummaryHeading = ReviewSummaryHeading.EMPTY;
}
