<div class="modal-header justify-content-end no-border">
    <img
        class="close-icon cursor-pointer"
        src="/assets/icons/navbar-profile/popup-close.png"
        (click)="onCloseChangeUserRoleModal()"
    />
</div>
<div class="modal-body">
    <div class="container-fluid" *ngIf="!isDoctor">
        <div class="row">
            <div class="change-pwd-head mb-4">
                <h3 class="kl-text1-type-1">User Role</h3>
            </div>
            <div class="change-pwd-form-container">
                <form class="role-selection-form mt-4" [formGroup]="roleSelectionForm" (ngSubmit)="onSubmit()">
                    <div class="role-selection-container" id="select-role-div">
                        <div class="select-role-dropdown">
                            <label class="kl-text2-type-1 mb-2">Select User Role</label>
                            <select
                                class="form-select kl-input-text line-height"
                                formControlName="userRole"
                                (change)="onChangeRole($event)"
                            >
                                <option value="" disabled selected hidden>Select a role</option>
                                <option
                                    *ngFor="let roleItem of (availableRoles$ | async)"
                                    [value]="roleItem?.id"
                                >
                                    {{
                                        getActualRoleName(roleItem?.role?.name) === 'Doctor'
                                            ? 'Practitioner'
                                            : getActualRoleName(roleItem?.role?.name) === 'Franchise Clinic'
                                            ? 'Gold Partner'
                                            : getActualRoleName(roleItem?.role?.name) === 'Regular Clinic'
                                            ? 'Partner'
                                            : getActualRoleName(roleItem?.role?.name)
                                    }}
                                </option>
                            </select>
                        </div>
                        <div class="error-message-container-common">
                            <p class="error-text" *ngIf="isDirty('userRole') && isRequired('userRole')">
                                Please select a role
                            </p>
                        </div>
                    </div>
                    <div
                        class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center"
                        id="sign-in-btn-div"
                    >
                        <button
                            type="submit"
                            class="kl-btn-primary"
                            id="sign-in-action-btn"
                            [disabled]="!roleSelectionForm.valid"
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="isDoctor">
        <app-select-clinic (closeSelectClinicForm)="onCloseChangeUserRoleModal()"></app-select-clinic>
    </div>
</div>
