import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from '../services/credentials.service';
import { Url } from '../util/common';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private _router: Router, private _credentialsService: CredentialsService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Allow navigation only if user is logged in.
        if (this._credentialsService.isAuthenticated()) {
            return true;
        }
        this._router.navigateByUrl(Url.LOGIN); // Navigate to login page.
        return false;
    }
}
