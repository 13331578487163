<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0">
            <div class="patient-info-container">
                <app-patient-info
                    [patientInfo$]="patientInfoDetailsToPass$"
                ></app-patient-info>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 p-0">
            <div class="slip-treatment-data-container">
                <div class="row label-row">
                    <div class="col-3 col-lg-5">
                        <span class="kl-text3-type-6">Medical Info</span>
                    </div>
                    <div class="col-1"></div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-8">Right Eye(OD)</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-8">Left Eye(OS)</span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">Material & Color</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>

                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7" [ngStyle]="getMaterialStyle('Right')">
                            {{ doctorFormData.materialAndColor.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7" [ngStyle]="getMaterialStyle('Left')">
                            {{ doctorFormData.materialAndColor.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">Lens Type</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7" [ngStyle]="getLensTypeStyle(doctorFormData.lensType.right)">
                            {{ doctorFormData.lensType.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7" [ngStyle]="getLensTypeStyle(doctorFormData.lensType.left)">
                            {{ doctorFormData.lensType.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">BV</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.BV.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.BV.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">Diameter</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.diameter.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.diameter.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">CT</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.ct.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.ct.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5"></div>
                    <div class="col-1 p-0"></div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'RIGHT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataRight"
                        >
                            <div class="col-4">
                            </div>
                            <div class="col-4">
                                <span
                                    class="kl-text3-type-6"
                                    *ngIf="doctorFormData.lensType.right === 'TORIC LENS'"
                                >
                                    Toric
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text3-type-6">Width</span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataRight>
                            --
                        </ng-template>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'LEFT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataLeft"
                        >
                            <div class="col-4">
                            </div>
                            <div class="col-4">
                                <span
                                    class="kl-text3-type-6"
                                    *ngIf="doctorFormData.lensType.left === 'TORIC LENS'"
                                >
                                    Toric
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text3-type-6">Width</span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataLeft>
                            --
                        </ng-template>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">BC</span>
                    </div>
                    <div class="col-1 p-0"></div>


                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'RIGHT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataRight"
                        >
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.bc.right.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span
                                    class="kl-text5-type-7"
                                    *ngIf="doctorFormData.lensType.right === 'TORIC LENS'"
                                >
                                    {{ doctorFormData.bc.right.value2
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.bc.right.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataRight>
                            --
                        </ng-template>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'LEFT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataLeft"
                        >
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.bc.left.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span
                                    class="kl-text5-type-7"
                                    *ngIf="doctorFormData.lensType.left === 'TORIC LENS'"
                                >
                                    {{ doctorFormData.bc.left.value2
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.bc.left.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataLeft>
                            --
                        </ng-template>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">BC Eccentricity</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.bc_eccentricity.right
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                        </span>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.bc_eccentricity.left
                                | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                        </span>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">TAZ</span>
                    </div>
                    <div class="col-1 p-0"></div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'RIGHT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataRight"
                        >
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.taz.right.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span
                                    class="kl-text5-type-7"
                                    *ngIf="doctorFormData.lensType.right === 'TORIC LENS'"
                                >
                                    {{ doctorFormData.taz.right.value2
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.taz.right.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataRight>
                            --
                        </ng-template>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'LEFT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataLeft"
                        >
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.taz.left.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span
                                    class="kl-text5-type-7"
                                    *ngIf="doctorFormData.lensType.left === 'TORIC LENS'"
                                >
                                    {{ doctorFormData.taz.left.value2
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.taz.left.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataLeft>
                            --
                        </ng-template>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">TPZ</span>
                    </div>
                    <div class="col-1 p-0"></div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-6': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-2': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'RIGHT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataRight"
                        >
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.tpz.right.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7"></span>
                            </div>
                            <div class="col-4 multi-split-content">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.tpz.right.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'right' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataRight>
                            --
                        </ng-template>
                    </div>
                    <div
                        class="col-lg-3 p-0"
                        [ngClass]="{
                            'col-2': doctorFormData.specialInstructions === 'RIGHT ONLY' ,
                            'col-6': doctorFormData.specialInstructions === 'LEFT ONLY'
                        }"
                    >
                        <div
                            class="row"
                            *ngIf="doctorFormData.specialInstructions === 'LEFT ONLY' ||
                                doctorFormData.specialInstructions === 'RIGHT AND LEFT';
                                else showEmptyDataLeft"
                        >
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.tpz.left.value1
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text5-type-7"></span>
                            </div>
                            <div class="col-4">
                                <span class="kl-text5-type-7">
                                    {{ doctorFormData.tpz.left.width
                                        | facdDataTransform: '': doctorFormData.specialInstructions: 'left' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #showEmptyDataLeft>
                            --
                        </ng-template>
                    </div>
                </div>
                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">Special Instruction</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div class="col-8 col-lg-6 p-0">
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.specialInstructions }}
                        </span>
                    </div>
                </div>

                <div class="row doctor-slip-details">
                    <div class="dr-slip-field-name col-3 col-lg-5">
                        <span class="kl-text5-type-6">Lens Manufacturer</span>
                    </div>
                    <div class="col-1 p-0">
                        <span class="kl-text5-type-6">:</span>
                    </div>
                    <div class="col-8 col-lg-6 p-0">
                        <span class="kl-text5-type-7">
                            {{ doctorFormData.lensManufacturer || '--' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
