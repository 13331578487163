<ng-template #content let-modal>
    <div class="modal-header justify-content-end">
        <img
            class="close-icon cursor-pointer"
            src="/assets/icons/navbar-profile/popup-close.png"
            (click)="onCloseModal()"
        />
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="alert-title-heading mb-2">
                    <ng-content select=".popup-main-title"></ng-content>
                </div>
                <div class="alert-container text-center mb-5">
                    <ng-content select=".popup-body-content"></ng-content>
                </div>
                <div class="popup-btn-container d-flex justify-content-center mb-3">
                    <button 
                        type="submit" 
                        class="kl-btn-primary"
                        (click)="onCloseModal()"
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
