import { createAction } from "@ngrx/store";

import { ActionType, OrderRelatedIds, OrderSequenceForBothEye, UserBasicDetails } from "../models/orders.model";
import { CreateOrderForRemakeMutationVariables, GetListOfOrderCountQueryVariables, GetListOfOrdersBasedOnRoleQuery, GetListOfOrdersBasedOnRoleQueryVariables, GetOrderSequencesQueryVariables } from "@app/generated/graphql";

export const getTotalOrderListCount = createAction(
    ActionType.getTotalOrderListCount,
    (countListParams: GetListOfOrderCountQueryVariables) => ({ countListParams })
);
export const getTotalOrderListCountSuccess = createAction(
    ActionType.getTotalOrderListCountSuccess,
    (orderListCount: number) => ({ orderListCount })
);

export const getOrdersListBasedOnRole = createAction(
    ActionType.getOrdersListBasedOnRole,
    (countListParams: GetListOfOrdersBasedOnRoleQueryVariables) => ({ countListParams })
);
export const getOrdersListBasedOnRoleSuccess = createAction(
    ActionType.getOrdersListBasedOnRoleSuccess,
    (orderListData: GetListOfOrdersBasedOnRoleQuery) => ({ orderListData })
);
export const updatePatientIdStored = createAction(
    ActionType.updatePatientIdStored,
    (patientId: string) => ({ patientId })
);
export const updateOrderIdInState = createAction(
    ActionType.updateOrderIdInState,
    (orderIds: OrderRelatedIds) => ({ orderIds })
);
export const getDetailsOfUser = createAction(
    ActionType.getDetailsOfUser,
    (userId: string) => ({ userId })
);
export const getDetailsOfUserSuccess = createAction(
    ActionType.getDetailsOfUserSuccess,
    (userDetails: UserBasicDetails) => ({ userDetails })
);

export const createOrderForRemake = createAction(
  ActionType.createOrderForRemake,
  (createOrderForRemakeParams: CreateOrderForRemakeMutationVariables) => ({ createOrderForRemakeParams })
);

// Get order sequence list.
export const getOrderSequences = createAction(
  ActionType.getOrderSequences,
  (orderSequencesParam: GetOrderSequencesQueryVariables) => ({ orderSequencesParam })
);
export const getOrderSequencesSuccess = createAction(
  ActionType.getOrderSequencesSuccess,
  (orderSequences: OrderSequenceForBothEye) => ({ orderSequences })
)
