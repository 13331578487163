import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import {
    GetDistributorsDetailsByIdsUnderDesignerGQL,
    GetDistributorsDetailsByIdsUnderDesignerQuery,
    GetDistributorsDetailsByIdsUnderDesignerQueryVariables,
    GetDoctorAndClinicOfPatientGQL,
    GetDoctorAndClinicOfPatientQueryVariables,
    GetPractitionerIdFromDoctorPatientMappingGQL,
    GetPractitionerIdFromDoctorPatientMappingQuery,
    GetPractitionerIdFromDoctorPatientMappingQueryVariables,
    GetSelectedDesignerIdForDistributorFormGQL,
    GetSelectedDesignerIdForDistributorFormQueryVariables,
    GetSelectedDistributorIdForManufacturerFormGQL,
    GetSelectedDistributorIdForManufacturerFormQueryVariables,
    GetWarrantyPeriodGQL,
    GetWarrantyPeriodQueryVariables,
} from '@app/generated/graphql';

@Injectable({
    providedIn: 'root',
})
export class FormDataHandlerService {
    constructor(
        private _getWarrantyPeriod: GetWarrantyPeriodGQL,
        private _getDoctorAndClinicOfPatientGQL: GetDoctorAndClinicOfPatientGQL,
        private _getSelectedDesignerIdForDistributorFormGQL: GetSelectedDesignerIdForDistributorFormGQL,
        private _getSelectedDistributorIdForManufacturerFormGQL: GetSelectedDistributorIdForManufacturerFormGQL,
        private _getDistributorsDetailsByIdsUnderDesignerGQL: GetDistributorsDetailsByIdsUnderDesignerGQL,
        private _getPractitionerIdFromDoctorPatientMappingGQL: GetPractitionerIdFromDoctorPatientMappingGQL
    ) {}

    /**
     * For distributor onboarding form, to show selected designer fetching the id
     * Returns a promise, so that async await can be used to synchronously get value
     */
    fetchDesignerIdForDistributorOnboardForm = async (distributorId: string): Promise<string> => {
        const payload: GetSelectedDesignerIdForDistributorFormQueryVariables = {
            distributorId,
        };
        const designerData$ = this._getSelectedDesignerIdForDistributorFormGQL.watch(payload).valueChanges;
        try {
            const designerId = await firstValueFrom(designerData$);
            return designerId?.data?.designer_distributor_mapping?.[0]?.designer_id;
        } catch (error) {
            return '';
        }
    };

    /**
     * For manufacturer onboarding form, to show selected distributor fetching the id
     * Returns a promise, so that async await can be used to synchronously get value
     */
    fetchDistributorIdForManufacturerOnboardForm = async (manufacturerId: string): Promise<string> => {
        const payload: GetSelectedDistributorIdForManufacturerFormQueryVariables = {
            manufacturerId,
        };
        const distributorData$ = this._getSelectedDistributorIdForManufacturerFormGQL.watch(payload).valueChanges;
        try {
            const distributorId = await firstValueFrom(distributorData$);
            return distributorId?.data?.distributor_contract_manufacturer_mapping?.[0]?.distributor_id;
        } catch (error) {
            return '';
        }
    };

    /**
     * Fetch distributor list data upto 5 items for displaying in designer details page
     */
    fetchDistributorDetailsForAdditionalDetails = async (
        payload: GetDistributorsDetailsByIdsUnderDesignerQueryVariables
    ): Promise<GetDistributorsDetailsByIdsUnderDesignerQuery> => {
        const distributorData$ = this._getDistributorsDetailsByIdsUnderDesignerGQL.watch(payload).valueChanges;
        try {
            const distributorData = await firstValueFrom(distributorData$);
            return distributorData?.data;
        } catch (error) {
            return { user: [] };
        }
    };

    /**
     * Fetch practitioner associated with a patient from doctor patient mapping table
     */
    fetchPatientMappedDoctorIdFromTable = async (
        payload: GetPractitionerIdFromDoctorPatientMappingQueryVariables
    ): Promise<GetPractitionerIdFromDoctorPatientMappingQuery> => {
        const doctorData$ = this._getPractitionerIdFromDoctorPatientMappingGQL.watch(payload).valueChanges;
        try {
            const doctorData = await firstValueFrom(doctorData$);
            return doctorData?.data;
        } catch (error) {
            return { doctor_patient_mapping: [] };
        }
    };

    fetchWarrantyPeriodOfManufacturer = async(
        payload: GetWarrantyPeriodQueryVariables
    ): Promise<number> => {
        const warrantyPeriodData$ = this._getWarrantyPeriod.watch(payload).valueChanges;
        try {
            const warrantyData = await firstValueFrom(warrantyPeriodData$);
            return warrantyData?.data?.distributor_contract_manufacturer_mapping?.[0]?.warranty_period || 0;
        } catch (error) {
            return 0;
        }
    }

    /**
     * Fetches doctor & clinic id associated with patient
     * @param payload Patient Id
     * @returns Doctor & Clinic id associated with patient
     */
    fetchClinicAndDoctorIdOfPatient = async(
        payload: GetDoctorAndClinicOfPatientQueryVariables
    ): Promise<{doctor_id: string, clinic_id: string}> => {
        const doctorClinicData$ = this._getDoctorAndClinicOfPatientGQL.watch(payload).valueChanges;
        try {
            const doctorClinicData = await firstValueFrom(doctorClinicData$);
            const {doctor_id, clinic_id} = doctorClinicData?.data?.doctor_patient_mapping?.[0];
            return {
                doctor_id,
                clinic_id
            }
        } catch (error) {
            return {
                doctor_id: '',
                clinic_id: ''
            };
        }
    }
}
