import { Component, Input } from '@angular/core';

import { LabFormDetails, eye } from '@app/core/treatment-engine/interfaces';
import { initializeLabformDetails } from '@app/core/util/treatment-output-details';
import { orderDetailsForTrackerData } from '@app/exclusive-distributors/models/exclusive-distributors.model';

@Component({
    selector: 'app-manufacturer-view-labform',
    templateUrl: './manufacturer-view-labform.component.html',
    styleUrls: ['./manufacturer-view-labform.component.scss'],
})
export class ManufacturerViewLabformComponent {
    @Input() eyeTreated: eye = eye.empty;
    @Input() labFormData: LabFormDetails = initializeLabformDetails;
    @Input() orderDetailsForTracker!: orderDetailsForTrackerData;
    @Input() manufacturerStatus = '';
}
