<div class="kl-reset-container">
    <h2 class="reset-pwd kl-h6-type-1 mb-3" id="reset-pwd-label">Reset password</h2>
    <form 
        id="resetPwdForm" 
        class="reset-password-main-form"
        [formGroup]="resetPasswordForm" 
        (ngSubmit)="onResetPassword()"
    >
        <div class="password-container mb-3" id="password-contain-div">
            <label for="password" class="kl-text2-type-1 mb-2"> New password </label>
            <div class="input-with-icon-type-1" id="password-with-icon-div">
                <input
                    [type]="showNewPassword ? 'text' : 'password'"
                    formControlName="newPassword"
                    class="kl-input-text input-text-type-1"
                    placeholder="New password"
                    id="password"
                    autocomplete="on"
                />
                <i 
                    class="bi bi-eye input-icon to-right gray-80 cursor-pointer" 
                    (click)="toggleNewPassword()"
                > 
                </i>
            </div>
            <div class="error-message-container-common">
                <p
                    class="error-text"
                    *ngIf="isRequired('newPassword') && 
                        (isDirty('newPassword') || isTouched('newPassword'))"
                >
                    Password is required
                </p>
                <p 
                    class="error-text d-flex align-items-center" 
                    *ngIf="isPasswordStrong('newPassword') && !isRequired('newPassword')"
                >
                    Please enter a strong password
                    <app-password-criteria-popover></app-password-criteria-popover>
                </p>
                <p 
                    class="error-text" 
                    *ngIf="!isRequired('newPassword') && 
                        isTouched('newPassword') &&
                        isMaxLengthError('newPassword')"
                >
                    Password should not be greater than 128 characters
                </p>
            </div>
        </div>
        <div class="new-password-container" id="new-password-contain-div">
            <label for="password" class="kl-text2-type-1 mb-2"> Confirm password </label>
            <div class="input-with-icon-type-1" id="password-with-icon-div">
                <input
                    [type]="showConfirmPassword ? 'text' : 'password'"
                    formControlName="confirmPassword"
                    class="kl-input-text input-text-type-1"
                    placeholder="Confirm password"
                    id="confirm-password"
                    autocomplete="on"
                />
                <i 
                    class="bi bi-eye input-icon to-right gray-80 cursor-pointer" 
                    (click)="toggleConfirmPassword()"
                > 
                </i>
            </div>
            <div class="error-message-container-common">
                <p
                    class="error-text"
                    *ngIf="
                        isRequired('confirmPassword') && (isDirty('confirmPassword') || 
                            isTouched('confirmPassword'))
                    "
                >
                    Password is required
                </p>
                <p
                    class="error-text"
                    *ngIf="
                        resetPasswordForm.errors &&
                        resetPasswordForm.errors['mismatch'] &&
                        isTouched('confirmPassword') &&
                        !isRequired('newPassword') &&
                        !isRequired('confirmPassword')
                    "
                >
                    Passwords do not match
                </p>
                <p 
                    class="error-text" 
                    *ngIf="!isRequired('confirmPassword') && 
                        isTouched('confirmPassword') &&
                        isMaxLengthError('confirmPassword')"
                >
                    Password should not be greater than 128 characters
                </p>
            </div>
        </div>
        <div 
            class="auth-form-btn-container mt-5 mb-sm-2 mb-md-2 mb-lg-2 d-flex justify-content-center" 
            id="reset-pwd-btn-div"
        >
            <button 
                type="submit" 
                class="kl-btn-primary" 
                id="reset-pwd-action-btn" 
                [disabled]="!resetPasswordForm.valid"
            >
                Continue
            </button>
        </div>
    </form>
</div>
