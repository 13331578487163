<div class="bg-white kl-table-container" >
    <div class="kl-table custom-scrollbar m-2 table-container">
        <div class="list-utility-container d-md-flex d-block justify-content-md-end">
          <div class="d-flex form-group kl-select-container" *ngIf="footerDropdownItems.length > 0">
            <select
                id="footer-dropdown-list"
                name="footer-dropdown-list"
                class="kl-select w-100"
                (change)="onFooterDropdownChange($event)"
                #footerDropdown
            >
              <option *ngFor="let item of footerDropdownItems" [selected]="item.isSelected" [value]="item.key">
                  {{ item.value }}
              </option>
            </select>
          </div>
        </div>
        <table class="table table-condensed table-hover my-0">
            <thead>
                <tr>
                    <th *ngFor="let col of tableColumns" scope="col" [ngClass]="{'checkbox-container': col.elementType === 'checkbox'}">
                        <div class="header-container d-flex align-items-center">
                            <span  [ngbTooltip]="col?.header"  *ngIf="col.elementType !== 'checkbox'" class="kl-text5-type-1 trim-text">{{ col.header }}</span>
                            <div class="d-flex align-items-center" *ngIf="col.isSortable">
                                <span class="sort-icon ms-1">
                                    <img
                                        class="cursor-pointer sort-icon-up"
                                        src="/assets/icons/common/sort-up-arrow.png"
                                        alt="Sort Asc"
                                        (click)="sortData('asc', col.columnDef)"
                                    />
                                    <img
                                        class="cursor-pointer sort-icon-down"
                                        src="/assets/icons/common/sort-down-arrow.png"
                                        alt="Sort Desc"
                                        (click)="sortData('desc', col.columnDef)"
                                    />
                                </span>
                            </div>
                        </div>
                        <form [formGroup]="allSelectedFormGroup">
                          <label for="checkbox-item-column" class="custom-checkbox" *ngIf="col.elementType === 'checkbox'">
                            <input
                                id="checkbox-item-column"
                                type="checkbox"
                                (change)="onSelectAll($event)"
                                formControlName="allSelected"
                                class="hidden-checkbox"
                            />
                            <span class="styled-checkbox"></span>
                          </label>
                        </form>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="data-row" *ngFor="let data of tableData; let i = index">
                    <td *ngFor="let col of tableColumns" [ngClass]="{'checkbox-container': col.elementType === 'checkbox'}">
                        <span
                            (click)="addRolePermissions(data)"
                            class="kl-text5-type4"
                            placement="bottom"
                            appStatusColor
                            *ngIf="col.elementType === 'span'"
                            >
                            <span *ngIf="col.columnDef != 'order_type' && col.columnDef != 'design_type'"
                                class="trim-text cursor-pointer"
                                [ngbTooltip]="data[col.columnDef]?.length > 20 ? data[col.columnDef] : ''"
                            >
                                {{
                                    data[col.columnDef]?.length > 20
                                        ? (data[col.columnDef] | slice : 0 : 20) + '...'
                                        : (data[col.columnDef] | capitalizeOrderStatus : col.columnDef)
                                }}
                             </span>
                             <span *ngIf=" col.columnDef === 'order_type' || col.columnDef === 'design_type'">{{ (data[col.columnDef] | CapitalizeType )}}</span>
                        </span>
                        <label [for]="i" class="custom-checkbox" *ngIf="col.elementType === 'checkbox'" [ngClass]="{'disabled-checkbox': !data?.isSelectable}">
                          <input
                              [id]="i"
                              type="checkbox"
                              [value]="data?.id"
                              [checked]="toggleCheckStatus(data?.id)"
                              (change)="onCheckboxChange(data?.id, $event)"
                              class="hidden-checkbox"
                          />
                          <span class="styled-checkbox"></span>
                        </label>
                        <span *ngIf="col.elementType === 'span-array'">
                          <span *ngFor="let spanData of data[col.columnDef]" (click)="onSpanArrayClick(spanData, data)" [ngbTooltip]="spanData.title" [ngClass]="spanData.classes">{{ spanData?.value }}</span>
                        </span>
                    </td>
                    <td class="action-space" *ngIf="acceptOrRejectActionId !== 'accept-or-reject' && !isViewable">
                        <div class="user-action-container">
                            <img
                                [src]="trashImgSrc"
                                [ngbTooltip]="'Delete'"
                                class="cursor-pointer mr-2 trash-icon-img"
                                (mouseenter)="trashImgSrc = '/assets/icons/common/trash-hover-icon.png'"
                                (mouseleave)="trashImgSrc = '/assets/icons/common/trash-non-hover-icon.png'"
                                (click)="onDeleteUser(data)"
                            />
                            <img
                                [src]="editImgSrc"
                                [ngbTooltip]="'Edit'"
                                class="cursor-pointer edit-icon-img"
                                (mouseenter)="editImgSrc = '/assets/icons/common/edit-hover-icon.png'"
                                (mouseleave)="editImgSrc = '/assets/icons/common/edit-non-hover-icon.png'"
                                (click)="editUserDetails(data)"
                            />
                            <img *ngIf="showLeftRightIcon" [ngbTooltip]="leftRightIconText" class="cursor-pointer" src="/assets/icons/common/left-right-arrow.svg" (click)="onLeftRightIconClick(data)" alt="swap icon">
                        </div>
                    </td>
                    <!-- accept or reject buttons start -->
                    <td class="action-space" *ngIf="acceptOrRejectActionId === 'accept-or-reject' && !isViewable">
                        <div class="user-action-container">
                            <button
                                class="warning-btn-with-icon-type-1 py-1 px-3 me-2"
                                (click)="onAcceptOrRejectButtonClick(data, 'reject')"
                            >
                                Reject
                                <img src="/assets/icons/common/thumbs-down.svg" />
                            </button>
                            <button
                                class="succuss-btm-with-icon-type-1 py-1 px-3"
                                (click)="onAcceptOrRejectButtonClick(data, 'accept')"
                            >
                                Accept
                                <img src="/assets/icons/common/thumbs-up.svg" />
                            </button>
                        </div>
                    </td>
                    <!-- accept or reject buttons end -->
                </tr>
            </tbody>
        </table>
        <div *ngIf="collectionSize === 0" class="no-data-found-label d-flex justify-content-center">
            <span class="kl-table-empty-text">No Data Found</span>
        </div>
    </div>

    <div class="pagination-container flex-container w-100 p-2" *ngIf="collectionSize > 0">
      <div class="flex-tem">
          <span class="kl-text5-type-1 me-2">Row Count</span>
      </div>
      <div class="limit-select-container flex-item">
          <select class="form-select kl-input-text limit-selection me-2" (change)="onLimitSelection($event)">
              <option value="10" selected>10</option>
              <option value="20">20</option>
          </select>
      </div>
      <div class="flex-item">
          <div class="page-content-track">
              <span class="kl-text5-type-1 me-2">{{ totalCountLabel }}</span>
          </div>
      </div>
      <div class="flex-item">
          <ngb-pagination
              [collectionSize]="collectionSize"
              [(page)]="page"
              [maxSize]="paginationSize"
              [pageSize]="pageSize"
              [boundaryLinks]="false"
              [rotate]="true"
              [ellipses]="false"
              (pageChange)="onChangePage($event)"
              aria-label="Custom pagination"
              >
              <ng-template ngbPaginationPrevious><span class="prev-link-custom"><</span></ng-template>
              <ng-template ngbPaginationNext><span class="prev-link-custom">></span></ng-template>
          </ngb-pagination>
      </div>
  </div>
</div>
