import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { State } from './models/auth.model';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: ['authFlowDetails', 'loginDataForLaterClinicSelection', 'availableUserRoles'],
        rehydrate: true,
        storage: localStorage,
    })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];
