import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-alert-popup',
    templateUrl: './alert-popup.component.html',
    styleUrls: ['./alert-popup.component.scss'],
})
export class AlertPopupComponent implements OnInit, AfterViewInit {
    @ViewChild('content', {static: false}) private content!: NgbModal;
    @Output() closePopup = new EventEmitter<boolean>();

    constructor(private _modalService: NgbModal) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.openVerticallyCenteredPopup(this.content);
    }

    /**
     * Opens a modal from ng bootstrap
     * Pass in the config, actual content of the modal to the service to open the modal
     * @param content Reference to the actual modal template
     * @returns void
     */
    openVerticallyCenteredPopup = (content: NgbModal): void => {
        this._modalService.open(content, {
            centered: true,
            windowClass: 'common-alert-popup',
            backdrop: 'static',
            keyboard: false,
        });
    };

    /**
     * Closes the popup modal
     * @param Nil
     * @returns void
     */
    onCloseModal = (): void => {
        this.closePopup.emit(true);
        this._modalService.dismissAll();
    };
}
