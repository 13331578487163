import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from 'src/environment/environment';
import { authReducer } from '@app/auth/reducers/auth.reducer';
import { designersReducer } from '@app/designers/reducers/designers.reducer';
import { exclusiveDistributorsReducer } from '@app/exclusive-distributors/reducers/exclusive-distributors.reducer';
import { patientsReducer } from '@app/patients/reducers/patients.reducer';
import { ordersReducer } from '@app/orders/reducers/orders.reducer';
import { sharedReducer } from '@app/shared/reducers/shared.reducer';
import { doctorsReducer } from '@app/doctors/reducers/doctors.reducers';
import { regularClinicReducer } from '@app/regular-clinics/reducers/regular-clinic.reducers';
import { franchiseClinicReducer } from '@app/franchise-clinics/reducers/franchise-clinics.reducer';
import * as SharedModule from '@app/shared/models/shared.model';
import * as AuthModel from '@app/auth/models/auth.model';
import * as DesignersModel from '@app/designers/models/designers.model';
import * as ExclusiveDistributor from '@app/exclusive-distributors/models/exclusive-distributors.model';
import * as Patients from '@app/patients/models/patients.model';
import * as Orders from '@app/orders/models/orders.model';
import * as RegularClinics from '@app/regular-clinics/models/regular-clinic.model';
import * as FranchiseClinics from '@app/franchise-clinics/models/franchise-clinics.model';
import * as Doctors from '@app/doctors/models/doctors.model';
import * as PatientProfile from '@app/patient-profile/models/patient-profile.model';
import { patientProfileReducer } from '@app/patient-profile/reducers/patient-profile.reducer';

export interface State {
    authentication: AuthModel.State,
    designers: DesignersModel.State,
    exclusiveDistributors: ExclusiveDistributor.State,
    doctors: Doctors.State,
    patients: Patients.State,
    patientProfile: PatientProfile.State,
    orders: Orders.State,
    regularClinics: RegularClinics.State,
    franchiseClinics: FranchiseClinics.State
    sharedReducer: SharedModule.SharedState,

}

export const reducers: ActionReducerMap<State> = {
    authentication: authReducer,
    designers: designersReducer,
    exclusiveDistributors: exclusiveDistributorsReducer,
    doctors: doctorsReducer,
    patients: patientsReducer,
    patientProfile: patientProfileReducer,
    orders: ordersReducer,
    regularClinics: regularClinicReducer,
    franchiseClinics: franchiseClinicReducer,
    sharedReducer: sharedReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
