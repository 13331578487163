import { createAction } from "@ngrx/store";

import { ActionType } from "../models/review-history.model";
import { GetListofReviewDateBasedOnPatientIdQuery, GetListofReviewDateBasedOnPatientIdQueryVariables, GetPatientReviewDetailsQuery, GetPatientReviewDetailsQueryVariables } from "@app/generated/graphql";

// Action to get list of review dates by patient id
export const getListOfReviewDatesBasedOnPatientId = createAction(
    ActionType.getListOfReviewHistoryDateBasedOnPatientId,
    (reviewDateListParams: GetListofReviewDateBasedOnPatientIdQueryVariables) => ({reviewDateListParams})
)

export const getListOfReviewDatesBasedOnPatientIdSuccess = createAction(
    ActionType.getListOfReviewHistoryDateBasedOnPatientIdSuccess,
    (reviewDateList: GetListofReviewDateBasedOnPatientIdQuery) => ({reviewDateList})
)

// Action to get review details based on patient_id and reviewed date
export const getReviewHistoryDetails = createAction(
    ActionType.getReviewHistoryDetails,
    (reviewHistoryDetailsParams: GetPatientReviewDetailsQueryVariables) => ({reviewHistoryDetailsParams})
)

export const getReviewHistoryDetailsSuccess = createAction(
    ActionType.getReviewHistoryDetailsSuccess,
    (reviewHistoryDetails: GetPatientReviewDetailsQuery) => ({reviewHistoryDetails})
);

export const resetReviewHistoryState = createAction(
  ActionType.resetReviewHistoryState
)
