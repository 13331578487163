<form [formGroup]="priceSettingsForm" [ngClass]="{'field-disabled': mode === 'view'}">
    <div class="container p-0">
        <div class="row">
            <div class="price-settings-heading mb-3">
                <p class="kl-text5-type-10">Pricing Details</p>
                <p class="kl-text5-type-11">The currency code for all prices is <span class="kl-text5-type-12">SGD(S$)</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 price-input-wrap" *ngFor="let item of userPriceData">
                <label
                    for="price"
                    class="kl-text5-type-9 mb-1"
                >
                    {{ item.priceLabel }} <sup class="field-required">*</sup>
                </label>
                <input
                    type="number"
                    class="price-input kl-input-text input-text-type-2"
                    [formControlName]="item.unique_field_name"
                    placeholder="Type here"
                    [ngClass]="{
                        'required-error': isRequired(item.unique_field_name) &&
                        (isDirty(item.unique_field_name) || isTouched(item.unique_field_name))
                    }"
                />
                <div class="error-common-container">
                    <span
                        class="error-text"
                        *ngIf="isRequired(item.unique_field_name) &&
                            (isDirty(item.unique_field_name) || isTouched(item.unique_field_name))"
                    >
                        Please add a price
                    </span>
                </div>
            </div>
        </div>
    </div>
</form>
