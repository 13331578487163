import { createAction } from '@ngrx/store';

import { ActionType } from '../models/designers.model';
import { Designer } from '@app/shared/models/designers.model';
import {
    GetDesignerTableListQueryVariables,
    GetTotalTableItemCountQueryVariables,
    OnboardDesignerMutationVariables,
} from '@app/generated/graphql';
import { GetDesignerTableListQuery } from '@app/generated/graphql';

export const onboardDesigner = createAction(ActionType.OnboardDesigner, (data: OnboardDesignerMutationVariables) => ({
    data,
}));

export const getDesignersList = createAction(ActionType.getDesignersList, (id: string) => ({ id }));

export const getDesignersListSuccess = createAction(ActionType.getDesignersListSuccess, (designers: Designer[]) => ({
    designers,
}));

// Designer table listing related actions
export const getDesignerTableList = createAction(
    ActionType.getDesignerTableList,
    (designerListParams: GetDesignerTableListQueryVariables) => ({ designerListParams })
);

export const getDesignerTableListSuccess = createAction(
    ActionType.getDesignerTableListSuccess,
    (designerList: GetDesignerTableListQuery) => ({ designerList })
);

export const getDesignerTableListFailure = createAction(ActionType.getDesignerTableListFailure);
export const getTotalCountOfTableList = createAction(
    ActionType.getTotalTableItemsCount,
    (countListParams: GetTotalTableItemCountQueryVariables) => ({ countListParams })
);
export const getTotalTableItemsCountSuccess = createAction(
    ActionType.getTotalTableItemsCountSuccess,
    (tableItemCount: number) => ({ tableItemCount })
);
