<div class="kl-send-otp-container">
    <h5 class="kl-h6-type-1">OTP</h5>
    <span *ngIf="otpServiceType == 'sms'">OTP sent to registered phone number</span>
    <span *ngIf="otpServiceType == 'email'">OTP sent to registered email</span>
    <form [formGroup]="otpForm" (submit)="onSubmit()">
        <label class="kl-text2-type-1">Enter your OTP</label>
        <div class="d-flex otp-input-container">
            <div class="form-group" *ngFor="let input of formInput; index as i">
                <input
                    #formRow
                    type="text"
                    formControlName="{{ input }}"
                    class="form-control"
                    maxlength="1"
                    (keyup)="keyUpEvent($event, input, i)"
                    (paste)="onPaste()"
                />
            </div>
        </div>
        <div class="error-message-container-common">
            <p
                class="error-text"
                *ngIf="!isValid && hasSubmitted"
            >
                Please enter a valid OTP
            </p>
        </div>
        <div class="text-right">
            <button
                type="button"
                class="kl-btn-text-only mt-3"
                (click)="onResendOtp()"
            >
                Resend OTP
            </button>
        </div>
        <div class="text-center">
            <button type="submit" class="kl-btn-primary">Continue</button>
        </div>
    </form>
</div>

<!-- Popup for alerting user otp has been sent -->
<div
    class="kl-popup-container"
    *ngIf="(isOtpSendSuccess$ | async)?.isOpen"
    (closePopup)="closeAlertPopup()"
>
    <app-alert-popup>
        <h3
            class="popup-main-title kl-text1-type-1 text-center"
            *ngIf="(isOtpSendSuccess$ | async)?.type ==='login'"
        >
            OTP
        </h3>
        <h3
            class="popup-main-title kl-text1-type-1 text-center"
            *ngIf="(isOtpSendSuccess$ | async)?.type ==='forgot-password'"
        >
            Forgot Password
        </h3>

        <h4 
            class="popup-body-content kl-text5-type3"
            *ngIf="otpServiceType === 'email'"
        >
            We have sent an OTP to your Email
        </h4>
        <h4 
            class="popup-body-content kl-text5-type3"
            *ngIf="otpServiceType === 'sms'"
        >
            We have sent an OTP to your Phone Number
        </h4>
    </app-alert-popup>
</div>
