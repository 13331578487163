import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';

import { Common, Roles } from '@app/core/util/common';
import { Column } from '@app/shared/models/user-table-list.model';
import { CredentialsService } from '@app/core/services/credentials.service';
import { FooterDropdownItems } from '@app/shared/models/shared.model';

@Component({
    selector: 'app-user-listing-table',
    templateUrl: './user-listing-table.component.html',
    styleUrls: ['./user-listing-table.component.scss'],
})
export class UserListingTableComponent<T> implements OnChanges, OnInit {
    page = 1;
    paginationSize = 5;
    pageSize = 10;
    totalCountLabel = '0 - 0 of 0';
    trashImgSrc = '/assets/icons/common/trash-non-hover-icon.png';
    editImgSrc = '/assets/icons/common/edit-non-hover-icon.png';
    tableHeaderCount = 0;
    role!: string;
    checkedList: any[] = [];
    footerDropdownValue = '';
    allSelectedFormGroup!: FormGroup;

    @Input() clearCheckedList = false;
    @Input() collectionSize = 0;
    @Input() tableColumns: Array<Column> = [];
    @Input() tableData: any[] = [];
    @Input() isViewable: boolean = false;
    @Input() acceptOrRejectActionId!: string;
    @Input() showLeftRightIcon = false;
    @Input() leftRightIconText = '';
    @Input() footerDropdownItems:FooterDropdownItems[] = [];
    @Output() onPageChangeEmitter = new EventEmitter<number>();
    @Output() sortDataEmitter = new EventEmitter<{ sortOrder: string; columnName: string }>();
    @Output() changePageLimitEmitter = new EventEmitter<number>();
    @Output() onItemDataEmitter = new EventEmitter<{ data: {}; type: string }>();
    @Output() deleteUser = new EventEmitter<{}>();
    @Output() actionButtonClick = new EventEmitter<any>();
    @Output() leftRightButtonClick = new EventEmitter<any>();
    @Output() sendCheckedItemsId = new EventEmitter<any[]>();
    @Output() sendFooterDropdownChangeEvent = new EventEmitter<Event>();
    @Output() sendFooterDropdownValue = new EventEmitter<string>();
    @Output() sendSpanArrayData = new EventEmitter<{spanData: any, data: any}>();
    @ViewChild('footerDropdown') footerDropdown!: ElementRef;

    constructor(private _credentialService: CredentialsService, private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.role = this._credentialService?.credentials?.role as string;
        this.tableHeaderCount = this.tableColumns?.length;
        this.initForm();
    }

    ngOnChanges(): void {
        this.updateTotalPageCountLabel();
        this.updateSelectedItems();
        if (this.clearCheckedList) {
          this.allSelectedFormGroup?.get('allSelected')?.patchValue(false);
            this.checkedList = [];
        }
        if(this.footerDropdownItems?.length > 0 && !this.footerDropdownValue){
          const selectedValue = this.footerDropdown?.nativeElement?.value;
          this.footerDropdownValue = selectedValue;
          this.sendFooterDropdownValue.emit(selectedValue);
        }
    }

    initForm = () => {
      this.allSelectedFormGroup = this._fb.group({
        allSelected: [false]
      })
    }

    addRolePermissions(data: {}): void {
        if (!this.isViewable) {
            this.editUserDetails(data);
        } else {
            this.viewUserDetails(data);
        }
    }

    /**
     * Remove user from list, emit to parent for doing actions
     * @param data Complete data of a row related
     * @returns void
     */
    onDeleteUser = (data: {}): void => {
        this.deleteUser.emit(data);
    };

    /**
     * Edit user from list, emit to parent for show details page
     * @param data Complete data of a row related
     * @returns void
     */
    editUserDetails = (data: {}): void => {
        this.onItemDataEmitter.emit({ data: data, type: Common.EDIT });
    };

    /**
     * When page is changed show data of that page, emit to parent to call query
     * @param event Page number
     * @returns void
     */
    onChangePage = (event: any): void => {
        this.onPageChangeEmitter.next(event);
    };

    /**
     * Sorting data based on column and order
     * @param sortOrder Whether ascending or descending order
     * @param columnName Name of column to be sorted
     * @returns void
     */
    sortData = (sortOrder: string, columnName: string): void => {
        this.page = 1;
        this.sortDataEmitter.next({ sortOrder, columnName });
    };

    /**
     * When number of items to show on a page is changed, eg: 10, 20 per page
     * @param event Click Event with number value from drop down
     * @returns void
     */
    onLimitSelection = (event: any): void => {
        this.page = 1;
        this.pageSize = +event?.target?.value;
        this.changePageLimitEmitter.next(this.pageSize);
    };

    /**
     * The label to show at the bottom of table
     * Information regarding total item count, how many items are shown till now
     * @returns void
     */
    updateTotalPageCountLabel = (): void => {
        if (this.collectionSize === 0) {
            this.totalCountLabel = '0 - 0 of 0';
        } else {
            const startNum = this.page === 1 ? 1 : (this.page - 1) * this.pageSize + 1;
            const endNum =
                this.page === 1
                    ? this.page - 1 + this.tableData?.length
                    : (this.page - 1) * this.pageSize + this.tableData?.length;
            this.totalCountLabel = `${startNum} - ${endNum} of ${this.collectionSize}`;
        }
    };

    viewUserDetails = (data: {}): void => {
        if (this.role === Roles.CONTRACT_MANUFACTURER) {
            this.onItemDataEmitter.emit({ data: data, type: Common.LAB_FORM });
        } else {
            this.onItemDataEmitter.emit({ data: data, type: Common.VIEW });
        }
    };

    onAcceptOrRejectButtonClick = (data: any, actionType: string): void => {
        this.actionButtonClick.emit({ data, actionType });
    };

    onLeftRightIconClick = (data: any): void => {
        this.leftRightButtonClick.emit(data);
    };

    /**
     * Add checked items id to the checkedList array.
     * @param option shares the id of the item.
     * @param event shares the checked status.
     */
    onCheckboxChange = (option: string, event: Event): void => {
        if ((<HTMLInputElement>event.target).checked) {
            const selectedItem = this.tableData.find((item) => item?.id === option);
            this.checkedList.push(selectedItem);
        } else {
            for (var i = 0; i < this.checkedList.length; i++) {
                if (this.checkedList[i]?.id === option) {
                    this.checkedList.splice(i, 1); // Remove the checked item after un-checking.
                }
                this.allSelectedFormGroup?.get('allSelected')?.patchValue(false);
            }
        }
        this.sendItemArrayToParent();
    };

    /**
     * Updated selected items array if page is changed.
     */
    updateSelectedItems = (): void => {
        if (this.allSelectedFormGroup?.get('allSelected')?.value) {
            const itemArray: any[] = this.tableData.map((item: any) => item);
            // Using Set to ensure uniqueness
            this.checkedList = Array.from(new Set([...this.checkedList, ...itemArray]));
        }
        this.sendItemArrayToParent();
    };

    /**
     * Add all items in the loaded list to checked list array.
     * @param event shares the checked status of the checkbox.
     */
    onSelectAll = (event: Event): void => {
        this.checkedList = []; // Clear the array to prevent duplicates
        let itemListArray = [];

        if (this.tableData && this.tableData?.[0]?.id) {
            itemListArray = this.tableData.filter((data) => data?.isSelectable === true);
        }
        if ((<HTMLInputElement>event.target).checked) {
            // If the "Select All" checkbox is checked, add all options to the array
            this.checkedList = [...itemListArray];
        }
        this.sendItemArrayToParent();
    };

    toggleCheckStatus = (id: string): boolean => {
     return this.checkedList.some((dataIn) => dataIn.id === id);
    }

    onFooterDropdownChange = (event: Event):void => {
      this.sendFooterDropdownChangeEvent.emit(event);
    }

    sendItemArrayToParent = (): void => {
        this.sendCheckedItemsId.emit(this.checkedList);
    };

    onSpanArrayClick = (spanData: any, data: any):void => {
      this.sendSpanArrayData.emit({spanData, data});
    }
}
