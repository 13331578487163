import { Action, createReducer, on } from '@ngrx/store';

import { DoctorListData, State, initialState } from '../models/doctors.model';
import { getDoctorFormDataSuccess, getDoctorTableListSuccess, getFranchiseClinicListSuccess, getRegularClinicListSuccess, getTotalTableItemsCountSuccess } from '../actions/doctors.actions';
import { resetAppState } from '@app/actions';

const _reducer = createReducer(
    initialState,
    on(getFranchiseClinicListSuccess, (state, payload) => ({
        ...state,
        franchiseClinicList: payload?.franchiseClinicList,
    })),
    on(getRegularClinicListSuccess, (state, payload) => ({
        ...state,
        regularClinicList: payload?.regularClinicList,
    })),
    on(getDoctorFormDataSuccess, (state, payload) => ({
        ...state,
        doctorDetails: payload?.doctorDetails,
    })),

    on(getDoctorTableListSuccess, (state, payload: { doctorList: DoctorListData[] }) => ({
        ...state,
        doctorTableList: payload.doctorList
    })),

    on(getTotalTableItemsCountSuccess, (state, payload: { tableItemCount: number }) => ({
        ...state,
        totalTableListCount: payload.tableItemCount
    })),
    on(resetAppState, () => initialState)
);

export function doctorsReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
