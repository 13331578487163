import { Common } from "@app/core/util/common";
import { DeleteUserMutationVariables, GetDesignerListQuery, GetExclusiveDistributorListBasedOnRoleQueryVariables, Manufacturer_Status_Enum } from "@app/generated/graphql";

export enum ActionType {
  onBoardDistributors = '[Distributors] Onboard distributors',
  onBoardDistributorsSuccess = '[Distributors] Onboard distributors success',
  getDesignersList = "[Distributors] Get designers",
  getDesignersListSuccess = "[Distributors] Get designers success",
  getExclusiveDistributorsListBasedOnRole= "[Distributors] Get exclusive distributor list based on role",
  getExclusiveDistributorsListBasedOnRoleSuccess = "[Distributors] Get exclusive distributor list based on role success",
  getTotalTableItemsCount = '[Distributors] Get total table list count',
  getTotalTableItemsCountSuccess = '[Distributors] Get total table list count success',
}

export interface State {
  designers: GetDesignerListQuery,
  exclusiveDistributorsList: any,
  totalTableListCount: number,
}

export const initialState: State = {
  designers: { user_role_mapping: []},
  exclusiveDistributorsList: {user_role_mapping: []},
  totalTableListCount: 0,
};

export interface OnboardingUpdateExclusiveDistributorUserDetails {
  contact_number: string,
  country_code: string,
  updated_at: string,
  updated_by: string,
  email: string
}

export interface OnboardingUpdateExclusiveDistributorUserRoleDetails {
  name: string,
  role_details: OnboardingExclusiveDistributorRoleDetails
}

export interface OnboardingExclusiveDistributorRoleDetails {
  designer: string,
  registration_number: string,
  address: string,
  device_licence_no: string,
  distributor_licence_number: string,
}

export interface OnboardingUpdatePatientUserDetails {
  email?: string,
  contact_number: string,
  country_code: string,
  updated_by: string
}

export interface OnboardingUpdatePatientUserRoleDetails {
  role_details: OnboardingPatientRoleDetails
}

export interface OnboardingPatientRoleDetails {
  clinic: string,
  clinic_type: string,
  doctor: string,
  patient_reference_number: string,
}

export interface orderDetailsForTrackerData{
  order_id: string;
  order_tracking_id: string;
  order_status: string;
  previous_status: string;
  created_at: string;
}

export interface DisplayOrderStatusOnTable{
  title: string,
  value: string,
  classes: string
}

export type ManufacturerDistributorOrderListPageQueryParamObjType = {
  mode: Common;
  distributorId: string;
  roleMappingId: string;
  breadCrumbName: string;
  filterBy?: string;
}

export type ManufacturerDistributorOrderListFilterBy = {
  manufacturerStatus: Manufacturer_Status_Enum
}
