import { Injectable } from '@angular/core';

import {filter, take } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { Engine } from '../treatment-engine/engine';
import { D1 } from '../treatment-engine/inputForms/D1';
import {
    AData,
    D1Data,
    D1DataLeftEye,
    D1DataRightEye,
    axis,
    correction,
    eye,
    optimisationLevel,
    orderType,
} from '../treatment-engine/interfaces';
import * as calculation from "../services/util.service";
import { Lens } from '@app/patients/models/patients.model';
import { FacdFormData } from '@app/patients/model/facd-form';
import { AmendFormData } from '@app/patients/model/amend-form';
import { EbcWorksheet } from '../treatment-engine/worksheet/worksheet';
import { Troubleshoot } from '../treatment-engine/worksheet/troubleshoot';
import { selectControlPanelValuesByDesignerId } from '@app/patients/selectors/patients.selector';
import { SATControlPanel, MATControlPanel, FACDControlPanel } from '../treatment-engine/worksheet/controlPanels';
import { updateLoopToMatchCorrectness } from '@app/patients/actions/patients.action';

@Injectable({
    providedIn: 'root',
})
export class TreatmentService {
    facdControlPanel = FACDControlPanel;
    satControlPanel = SATControlPanel;
    matControlPanel = MATControlPanel;

    constructor(private _store: Store) {
        this.fetchControlPanelValues();
    }

    fetchControlPanelValues = (): void => {
        this._store.pipe(
            take(1),
            filter(value => value !== null),
            select(selectControlPanelValuesByDesignerId)
        ).subscribe((controlPanelDetails) => {
            if (controlPanelDetails && controlPanelDetails.designer_control_panel) {
                const { facd_control_panel, mat_control_panel, sat_control_panel } =
                    controlPanelDetails?.designer_control_panel?.[0] || undefined;
                try {
                    if (facd_control_panel) {
                        this.facdControlPanel = JSON.parse(facd_control_panel);
                    } else {
                        this.facdControlPanel = FACDControlPanel;
                    }
                }
                catch (error) {
                    this.facdControlPanel = FACDControlPanel;
                }
                try {
                    if (sat_control_panel) {
                        this.satControlPanel = JSON.parse(sat_control_panel);
                    } else {
                        this.satControlPanel = SATControlPanel;
                    }
                }
                catch (error) {
                    this.satControlPanel = SATControlPanel;
                }
                try {
                    if (mat_control_panel) {
                        this.matControlPanel = JSON.parse(mat_control_panel);
                    } else {
                        this.matControlPanel = MATControlPanel;
                    }
                }
                catch (error) {
                    this.matControlPanel = MATControlPanel;
                }
                this.facdControlPanel = Object.assign({}, FACDControlPanel, this.facdControlPanel);
                this.satControlPanel = Object.assign({}, SATControlPanel, this.satControlPanel);
                this.matControlPanel = Object.assign({}, MATControlPanel, this.matControlPanel);
            }
        });
    }

    /**
     * Practitioner entered e value is given as input and calculated red value is obtained
     * The two values are then compared to check if they are equal or having a difference of 0.001
     * If equal loop is broken and final e value is assigned, if not it continues the loop
     * For now the maximum iteration for main loop is set as 200, from -0.99 to +0.99 counts
     * @param d1Data Actual facd data entered by practitioner
     * @returns facd data with new evalue got as a result of loop to match
     */
    loopToMatchLeft = (d1Data: D1Data) => {
        let maxIteration = 0;
        let isValuesNotMatched = false;
        while (maxIteration <= 200) {
            const d1 = new D1(d1Data);
            const worksheet = new EbcWorksheet(d1, this.facdControlPanel);
            const osEngine = new Engine(eye.left);
            const troubleshoot = new Troubleshoot(d1, this.satControlPanel);
            const troubleshoot2 = new Troubleshoot(d1, this.matControlPanel);
            let evalue = d1.K25;
            let redValue = d1.K26(worksheet, osEngine, troubleshoot, troubleshoot2);
            let absoluteDiff = calculation.cropValueToMaxExcelDecimalPoints(Math.abs(evalue - redValue), 3);
            if (evalue === redValue || absoluteDiff === 0) {
                d1Data.question7.left = redValue;
                break;
            }
            else if (absoluteDiff === 0.001) {
                let iterationCount = 0;
                let eValueCollection: number[] = [];
                while (iterationCount <= 5) {
                    const d1 = new D1(d1Data);
                    const worksheet = new EbcWorksheet(d1, this.facdControlPanel);
                    const osEngine = new Engine(eye.left);
                    const troubleshoot = new Troubleshoot(d1, this.satControlPanel);
                    const troubleshoot2 = new Troubleshoot(d1, this.matControlPanel);
                    evalue = d1.K25;
                    redValue = d1.K26(worksheet, osEngine, troubleshoot, troubleshoot2);
                    absoluteDiff = calculation.cropValueToMaxExcelDecimalPoints(Math.abs(evalue - redValue), 3);
                    if (absoluteDiff === 0.001) {
                        d1Data.question7.left = redValue;
                        eValueCollection.push(redValue);
                    }
                    if (evalue === redValue) {
                        eValueCollection.push(redValue);
                        break;
                    }
                    iterationCount++;
                }
                d1Data.question7.left = Math.min(...eValueCollection);
            }
            else if (Number.isNaN(redValue) || redValue === undefined) {
                d1Data.question7.left = evalue;
                this._store.dispatch(updateLoopToMatchCorrectness(false));
                break;
            }
            else {
                d1Data.question7.left = this.getRandomNumberBetweenEvalueAndRedValue(evalue, redValue);
                isValuesNotMatched = maxIteration === 200 ? true : false;
            }
            maxIteration++;
        }
        isValuesNotMatched && this._store.dispatch(updateLoopToMatchCorrectness(false));
        d1Data.evalueHistory.left.calculatedValue = d1Data.question7.left || 0;
        return d1Data;
    }

    /**
     * Practitioner entered e value is given as input and calculated red value is obtained
     * The two values are then compared to check if they are equal or having a difference of 0.001
     * If equal loop is broken and final e value is assigned, if not it continues the loop
     * For now the maximum iteration for main loop is set as 200
     * @param d1Data Actual facd data entered by practitioner
     * @returns facd data with new evalue got as a result of loop to match
     */
    loopToMatchRight = (d1Data: D1Data) => {
        let maxIteration = 0;
        let isValuesNotMatched = false;
        while (maxIteration <= 200) {
            const d1 = new D1(d1Data);
            const worksheet = new EbcWorksheet(d1, this.facdControlPanel);
            const odEngine = new Engine(eye.right);
            const troubleshoot = new Troubleshoot(d1, this.satControlPanel);
            const troubleshoot2 = new Troubleshoot(d1, this.matControlPanel);
            let evalue = d1.H25;
            let redValue = d1.H26(worksheet, odEngine, troubleshoot, troubleshoot2);
            let absoluteDiff = calculation.cropValueToMaxExcelDecimalPoints(Math.abs(evalue - redValue), 3);
            if (evalue === redValue || absoluteDiff === 0) {
                d1Data.question7.right = Number(redValue.toFixed(3));
                break;
            }
            else if (absoluteDiff === 0.001) {
                let iterationCount = 0;
                let eValueCollection: number[] = [];
                while (iterationCount <= 5) {
                    const d1 = new D1(d1Data);
                    const worksheet = new EbcWorksheet(d1, this.facdControlPanel);
                    const odEngine = new Engine(eye.right);
                    const troubleshoot = new Troubleshoot(d1, this.satControlPanel);
                    const troubleshoot2 = new Troubleshoot(d1, this.matControlPanel);
                    evalue = d1.H25;
                    redValue = d1.H26(worksheet, odEngine, troubleshoot, troubleshoot2);
                    absoluteDiff = calculation.cropValueToMaxExcelDecimalPoints(Math.abs(evalue - redValue), 3);
                    if (absoluteDiff === 0.001) {
                        d1Data.question7.right = redValue;
                        eValueCollection.push(redValue);
                    }
                    if (evalue === redValue) {
                        eValueCollection.push(redValue);
                        break;
                    }
                    iterationCount++;
                }
                d1Data.question7.right = Math.min(...eValueCollection);
            }
            else if (Number.isNaN(redValue) || redValue === undefined) {
                d1Data.question7.right = evalue;
                this._store.dispatch(updateLoopToMatchCorrectness(false));
                break;
            }
            else {
                d1Data.question7.right = this.getRandomNumberBetweenEvalueAndRedValue(evalue, redValue);
                isValuesNotMatched = maxIteration === 200 ? true : false;
            }
            maxIteration++;
        }
        isValuesNotMatched && this._store.dispatch(updateLoopToMatchCorrectness(false));
        d1Data.evalueHistory.right.calculatedValue = d1Data.question7.right || 0;
        return d1Data;
    }

    getRandomNumber = (min: number, max: number):number => {
      if (min > max) {
        [min, max] = [max, min];
      }
      return Math.random() * (max - min) + min;
    }

    getRandomNumberBetweenEvalueAndRedValue(min: number, max: number):number {
      return Number(this.getRandomNumber(min, max).toFixed(3));
    }

    /**
     * Round numbers
     * @param value Input number
     * @param decimals number of decimal places to round to
     * @returns rounded number
     */
    public round(value: number, decimals: number): number {
        return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
    }

    /**
     * Calculates red values to be shown in d1 form for chord diameters
     * @param d1Data facd Data
     * @returns Red values in d1 form
     */
    d1OtherRedValues(d1Data: D1Data) {
        const d1 = new D1(d1Data);
        const worksheet = new EbcWorksheet(d1, this.facdControlPanel);
        const troubleshoot = new Troubleshoot(d1, this.satControlPanel);
        const troubleshoot2 = new Troubleshoot(d1, this.matControlPanel);
        const valueRight = worksheet.E30(troubleshoot, troubleshoot2);
        const valueLeft = worksheet.E89(troubleshoot, troubleshoot2);
        const valueRight_2 = this.round(worksheet.F45(troubleshoot, troubleshoot2), 3);
        const valueLeft_2 = this.round(worksheet.F104(troubleshoot, troubleshoot2), 3);
        return {
            valueRight,
            valueLeft,
            valueRight_2,
            valueLeft_2,
        };
    }

    /**
     * Assign facd data to required format for processing
     * Transforms the data got from facd form and converts to processable format
     * Lens color is obtained from lens name string
     */
    processFacdInputData = (facdData: FacdFormData, lensList: Lens[]): D1Data => {
        const lensMaterialRight = facdData?.lens_material?.lens_material_right || '';
        const lensMaterialLeft = facdData?.lens_material?.lens_material_left || '';
        const d1Data: D1Data = {
            clinicName: '',
            clinicAddress: '',
            patientName: '',
            patientReference: '',
            doctorName: '',
            OrderType: orderType.new,
            question1:
                facdData.eye_selection.eye_selection === eye.right
                    ? eye.right
                    : facdData.eye_selection.eye_selection === eye.left
                    ? eye.left
                    : eye.both,
            question2: {
                right: (Number(facdData.hvid.hvid_right) as number) || 0,
                left: (Number(facdData.hvid.hvid_left) as number) || 0,
            },
            question3: {
                right: {
                    sphere: (Number(facdData.refractive_error.sphere_right) as number) || 0,
                    cylinder: (Number(facdData.refractive_error.cylinder_right) as number) || 0,
                    axis:(()=>{
                      if (axis.ATR === facdData?.refractive_error?.axis_right) {
                        return axis.ATR;
                      }
                      if (axis.Oblique === facdData?.refractive_error?.axis_right) {
                        return axis.Oblique;
                      }
                      if (axis.WTR === facdData?.refractive_error?.axis_right) {
                        return axis.WTR;
                      }
                      return axis.WTR;
                    })()
                },
                left: {
                    sphere: (Number(facdData.refractive_error.sphere_left) as number) || 0,
                    cylinder: (Number(facdData.refractive_error.cylinder_left) as number) || 0,
                    axis:(() => {
                      if (axis.ATR === facdData?.refractive_error?.axis_left) {
                        return axis.ATR;
                      }
                      if (axis.Oblique === facdData?.refractive_error?.axis_left) {
                        return axis.Oblique;
                      }
                      if (axis.WTR === facdData?.refractive_error?.axis_left) {
                        return axis.WTR;
                      }
                      return axis.WTR;
                    })()
                },
            },
            question4: {
                right:
                    facdData.orthok_correction.orthok_correction_right === correction.miopiaControl
                        ? correction.miopiaControl
                        : facdData.orthok_correction.orthok_correction_right === correction.adult
                        ? correction.adult
                        : facdData.orthok_correction.orthok_correction_right === correction.presbyopia
                        ? correction.presbyopia
                        : correction.miopiaControl,
                left:
                    facdData.orthok_correction.orthok_correction_left === correction.miopiaControl
                        ? correction.miopiaControl
                        : facdData.orthok_correction.orthok_correction_left === correction.adult
                        ? correction.adult
                        : facdData.orthok_correction.orthok_correction_left === correction.presbyopia
                        ? correction.presbyopia
                        : correction.miopiaControl,
            },
            question5: {
                right: facdData.flatk.flatk_right || 0,
                left: facdData.flatk.flatk_left || 0,
            },
            question6: {
                right: facdData.sag_height_cornea?.sag_height_cornea_right,
                left: facdData.sag_height_cornea?.sag_height_cornea_left,
            },
            question7: {
                right: (facdData?.average_evalue_cornea?.average_evalue_cornea_right as number) || 0,
                left: (facdData?.average_evalue_cornea?.average_evalue_cornea_left as number) || 0,
            },
            question8: {
                a: {
                    right: facdData?.sagittal_height_diff?.sagittal_height_diff_right || 0,
                    left: facdData?.sagittal_height_diff?.sagittal_height_diff_left || 0,
                },
                b: {
                    right: facdData?.corneal_toricity?.corneal_toricity_right || 0,
                    left: facdData?.corneal_toricity?.corneal_toricity_left || 0,
                },
            },
            question9: {
                right:
                    facdData?.edge_lift_profile?.edge_lift_profile_right === optimisationLevel.optimised
                        ? optimisationLevel.optimised
                        : facdData?.edge_lift_profile?.edge_lift_profile_right === optimisationLevel.oneStepLoose
                        ? optimisationLevel.oneStepLoose
                        : facdData?.edge_lift_profile?.edge_lift_profile_right === optimisationLevel.twoStepLoose
                        ? optimisationLevel?.twoStepLoose
                        : optimisationLevel.optimised,
                left:
                    facdData?.edge_lift_profile?.edge_lift_profile_left === optimisationLevel.optimised
                        ? optimisationLevel.optimised
                        : facdData?.edge_lift_profile?.edge_lift_profile_left === optimisationLevel.oneStepLoose
                        ? optimisationLevel.oneStepLoose
                        : facdData?.edge_lift_profile?.edge_lift_profile_left === optimisationLevel.twoStepLoose
                        ? optimisationLevel?.twoStepLoose
                        : optimisationLevel.optimised,
            },
            question10: {
                right: facdData?.supercharge_myopia?.supercharge_myopia_right === 'Yes' ? true : false,
                left: facdData?.supercharge_myopia?.supercharge_myopia_left === 'Yes' ? true : false,
            },
            question11: {
                right: facdData?.lens_material?.lens_material_right || '',
                left: facdData?.lens_material?.lens_material_left || '',
            },
            question12: (facdData?.lab_of_choice?.lab_of_choice),
            evalueHistory: {
                right: {
                    evalue: facdData?.average_evalue_cornea?.average_evalue_cornea_right || 0,
                    calculatedValue: 0,
                },
                left: {
                    evalue: facdData?.average_evalue_cornea?.average_evalue_cornea_left || 0,
                    calculatedValue: 0,
                },
            },
            lensColor: {
                right: this.getColorCodeFromLensSelection(lensMaterialRight, lensList),
                left: this.getColorCodeFromLensSelection(lensMaterialLeft, lensList),
            }
        };
        return d1Data;
    };

    /**
     * Give right eye data and initialize left values when doing treatment for right eye
     * @param facdData facd data
     * @returns Modified facd data for right eye alone
     */
    initializeFacdRightEyeData = (facdData: D1Data): D1Data => {
        const facdRightEyeData: D1Data = {
            clinicName: facdData.clinicName,
            clinicAddress: facdData.clinicAddress,
            patientName: facdData.patientName,
            patientReference: facdData.patientReference,
            doctorName: facdData.doctorName,
            OrderType: facdData.OrderType,
            question1: eye.right,
            question2: {
                right: facdData.question2.right,
                left: 0,
            },
            question3: {
                right: {
                    sphere: facdData.question3.right?.sphere,
                    cylinder: facdData.question3.right?.cylinder,
                    axis: facdData.question3.right?.axis,
                },
                left: {
                    sphere: 0,
                    cylinder: 0,
                    axis: axis.WTR,
                },
            },
            question4: {
                right: facdData.question4.right,
                left: correction.miopiaControl,
            },
            question5: {
                right: facdData.question5.right,
                left: 0,
            },
            question6: {
                right: facdData.question6.right,
                left: 0,
            },
            question7: {
                right: facdData.question7.right,
                left: 0,
            },
            question8: {
                a: {
                    right: facdData.question8.a.right,
                    left: 0,
                },
                b: {
                    right: facdData.question8.b.right,
                    left: 0,
                },
            },
            question9: {
                right: facdData.question9.right,
                left: optimisationLevel.optimised,
            },
            question10: {
                right: facdData.question10.right,
                left: false,
            },
            question11: {
                right: facdData.question11.right,
                left: '',
            },
            question12: facdData.question12,
            evalueHistory: {
                right: {
                    evalue: facdData.evalueHistory?.right.evalue,
                    calculatedValue: facdData.evalueHistory?.right.calculatedValue,
                },
                left: {
                    evalue: 0,
                    calculatedValue: 0,
                },
            },
            lensColor: {
                right: facdData.lensColor?.right,
                left: ''
            },
            labName: facdData?.labName || facdData.question12
        };
        return facdRightEyeData;
    };

    /**
     * Give left eye data and initialize right values when doing treatment for left eye
     * @param facdData facd data
     * @returns Modified facd data for left eye alone
     */
    initializeFacdLeftEyeData = (facdData: D1Data): D1Data => {
        const facdLeftEyeData: D1Data = {
            clinicName: facdData.clinicName,
            clinicAddress: facdData.clinicAddress,
            patientName: facdData.patientName,
            patientReference: facdData.patientReference,
            doctorName: facdData.doctorName,
            OrderType: facdData.OrderType,
            question1: eye.left,
            question2: {
                right: 0,
                left: facdData.question2.left,
            },
            question3: {
                right: {
                    sphere: 0,
                    cylinder: 0,
                    axis: axis.WTR,
                },
                left: {
                    sphere: facdData.question3.left?.sphere,
                    cylinder: facdData.question3.left?.cylinder,
                    axis: facdData.question3.left?.axis,
                },
            },
            question4: {
                right: correction.miopiaControl,
                left: facdData.question4.left,
            },
            question5: {
                right: 0,
                left: facdData.question5.left,
            },
            question6: {
                right: 0,
                left: facdData.question6.left,
            },
            question7: {
                right: 0,
                left: facdData.question7.left,
            },
            question8: {
                a: {
                    right: 0,
                    left: facdData.question8.a.left,
                },
                b: {
                    right: 0,
                    left: facdData.question8.b.left,
                },
            },
            question9: {
                right: optimisationLevel.optimised,
                left: facdData.question9.left,
            },
            question10: {
                right: false,
                left: facdData.question10.left,
            },
            question11: {
                right: '',
                left: facdData.question11.left,
            },
            question12: facdData.question12,
            evalueHistory: {
                right: {
                    evalue: 0,
                    calculatedValue: 0,
                },
                left: {
                    evalue: facdData.evalueHistory?.left.evalue,
                    calculatedValue: facdData.evalueHistory?.left.calculatedValue,
                },
            },
            lensColor: {
                right: '',
                left: facdData.lensColor?.left
            },
            labName: facdData?.labName || facdData.question12
        };
        return facdLeftEyeData;
    };

    // Convert boolean as string to boolean.
    getBooleanValue = (value: string | boolean): boolean => {
      if (typeof value === 'string') {
        value = value === 'true';
      } else if (typeof value === 'boolean') {
        value = value;
      }
      return value
    }

    /**
     * Modify the form data to desired model
     * @param amendInputData Form data from SAT
     * @returns Processed amend data in model to be saved to db
     */
    processAmendInputData = (
      amendInputData: AmendFormData,
      lensList: Lens[],
      lensFromPreviousOrder: {right: string, left: string} | null,
      manufacturerIdFromPreviousOrder: string
      ): AData => {
      let lensMaterialRight = amendInputData?.lens_material?.lens_material_right || '';
      let lensMaterialLeft = amendInputData?.lens_material?.lens_material_left || '';
      if(!lensMaterialLeft){
        lensMaterialLeft = lensFromPreviousOrder?.left || '';
      }
      if(!lensMaterialRight){
        lensMaterialRight = lensFromPreviousOrder?.right || '';
      }
      const amendData: AData = {
          clinicName: '',
          patientName: '',
          patientReference: '',
          practitionerName: '',
          eyes:
              amendInputData.eye_selection.eye_selection === eye.right
                  ? eye.right
                  : amendInputData.eye_selection.eye_selection === eye.left
                  ? eye.left
                  : eye.both,
          question1: {
              right: Number(amendInputData.tangential_treatment_zone.tangential_treatment_zone_right) || 0,
              left: Number(amendInputData.tangential_treatment_zone.tangential_treatment_zone_left) || 0,
          },
          question2: {
              right: Number(amendInputData.treatment_zone.treatment_zone_right) || 0,
              left: Number(amendInputData.treatment_zone.treatment_zone_left) || 0,
          },
          question3: {
              right: Number(amendInputData.axial_power_diff_map.axial_power_diff_map_right) || 0,
              left: Number(amendInputData.axial_power_diff_map.axial_power_diff_map_left) || 0,
          },
          question4: {
              right: {
                  1: this.getBooleanValue(amendInputData.cornea_condition.ces_right),
                  2: this.getBooleanValue(amendInputData.cornea_condition.csa_right),
                  3: this.getBooleanValue(amendInputData.cornea_condition.mpspk_right),
                  4: this.getBooleanValue(amendInputData.cornea_condition.rza_right),
                  5: this.getBooleanValue(amendInputData.cornea_condition.ela_right),
                  6: this.getBooleanValue(amendInputData.cornea_condition.ls_right),
              },
              left: {
                  1: this.getBooleanValue(amendInputData.cornea_condition.ces_left),
                  2: this.getBooleanValue(amendInputData.cornea_condition.csa_left),
                  3: this.getBooleanValue(amendInputData.cornea_condition.mpspk_left),
                  4: this.getBooleanValue(amendInputData.cornea_condition.rza_left),
                  5: this.getBooleanValue(amendInputData.cornea_condition.ela_left),
                  6: this.getBooleanValue(amendInputData.cornea_condition.ls_left),
              },
          },
          question5: {
              right: {
                  1: Number(amendInputData.blurry_vision.blurry_vision_sphere_right) || 0,
                  2: Number(amendInputData.blurry_vision.blurry_vision_cylinder_right) || 0,
                  3:(() => {
                    const amendAxisRight = amendInputData?.blurry_vision?.blurry_vision_axis_right;

                    if (axis.ATR === amendAxisRight) {
                      return axis.ATR;
                    } else if (axis.Oblique === amendAxisRight) {
                      return axis.Oblique;
                    } else if (axis.WTR === amendAxisRight) {
                      return axis.WTR;
                    } else {
                      return axis.WTR;  // default value
                    }
                  })()
              },
              left: {
                  1: Number(amendInputData.blurry_vision.blurry_vision_sphere_left) || 0,
                  2: Number(amendInputData.blurry_vision.blurry_vision_cylinder_left) || 0,
                  3: (() => {
                    if (axis.ATR === amendInputData?.blurry_vision?.blurry_vision_axis_left) {
                      return axis.ATR;
                    }
                    if (axis.Oblique === amendInputData?.blurry_vision?.blurry_vision_axis_left) {
                      return axis.Oblique;
                    }
                    if (axis.WTR === amendInputData?.blurry_vision?.blurry_vision_axis_left) {
                      return axis.WTR;
                    }
                    return axis.WTR;
                  })()
              },
          },
          question6: {
              right: this.getBooleanValue(amendInputData.symptomatic_hoa.symptomatic_hoa_right),
              left: this.getBooleanValue(amendInputData.symptomatic_hoa.symptomatic_hoa_left),
          },
          question7: {
              right: this.getBooleanValue(amendInputData.near_work_difficulty.near_work_difficulty_right),
              left: this.getBooleanValue(amendInputData.near_work_difficulty.near_work_difficulty_left),
          },
          question8: {
              right: Number(amendInputData.myopia_control.myopia_control_right) || 0,
              left: Number(amendInputData.myopia_control.myopia_control_left) || 0,
          },
          question9: amendInputData?.lab_of_choice?.lab_of_choice || manufacturerIdFromPreviousOrder,
          question10: {
            right: amendInputData?.lens_material?.lens_material_right || lensFromPreviousOrder?.right || '',
            left: amendInputData?.lens_material?.lens_material_left || lensFromPreviousOrder?.left || ''
          },
          lensColor: {
            right: this.getColorCodeFromLensSelection(lensMaterialRight, lensList),
            left: this.getColorCodeFromLensSelection(lensMaterialLeft, lensList),
          }

      };
      return amendData;
    };

    /**
     * Assign values to facd based on eye selection of latest order
     * @param facdData
     * @returns facd complete data
     */
    appendValuesToD1 = (facdRight: D1DataRightEye, facdLeft: D1DataLeftEye, selectedEye: eye) => {
        const d1Data: D1Data = {
            clinicName: facdRight.clinicName !== '' ? facdRight?.clinicName : facdLeft?.clinicName,
            clinicAddress: facdRight.clinicAddress !== '' ? facdRight?.clinicAddress : facdLeft?.clinicAddress,
            patientName: facdRight.patientName !== '' ? facdRight?.patientName : facdLeft?.patientName,
            patientReference:
                facdRight.patientReference !== '' ? facdRight?.patientReference : facdLeft?.patientReference,
            doctorName: facdRight.doctorName !== '' ? facdRight?.doctorName : facdLeft?.doctorName,
            OrderType: orderType.new,
            question1: selectedEye,
            question2: {
                right: facdRight.question2.right,
                left: facdLeft.question2.left,
            },
            question3: {
                right: {
                    sphere: facdRight.question3.right?.sphere || 0,
                    cylinder: facdRight.question3.right?.cylinder || 0,
                    axis: facdRight.question3.right?.axis || axis.WTR,
                },
                left: {
                    sphere: facdLeft.question3.left?.sphere || 0,
                    cylinder: facdLeft.question3.left?.cylinder || 0,
                    axis: facdLeft.question3.left?.axis || axis.WTR,
                },
            },
            question4: {
                right: facdRight.question4.right || correction.miopiaControl,
                left: facdLeft.question4.left || correction.miopiaControl,
            },
            question5: {
                right: facdRight.question5.right || 0,
                left: facdLeft.question5.left || 0,
            },
            question6: {
                right: facdRight.question6.right || 0,
                left: facdLeft.question6.left || 0,
            },
            question7: {
                right: facdRight.question7.right || 0,
                left: facdLeft.question7.left || 0,
            },
            question8: {
                a: {
                    right: facdRight.question8.a.right || 0,
                    left: facdLeft.question8.a.left || 0,
                },
                b: {
                    right: facdRight.question8.b.right || 0,
                    left: facdLeft.question8.b.left || 0,
                },
            },
            question9: {
                right: facdRight.question9.right || optimisationLevel.optimised,
                left: facdLeft.question9.left || optimisationLevel.optimised,
            },
            question10: {
                right: facdRight.question10.right,
                left: facdLeft.question10.left,
            },
            question11: {
                right: facdRight.question11.right || '',
                left: facdLeft.question11.left || '',
            },
            question12: facdRight.question12,
            evalueHistory: {
                right: {
                    evalue: facdRight.evalueHistory ? facdRight.evalueHistory.right.evalue : 0,
                    calculatedValue: facdRight.evalueHistory ? facdRight.evalueHistory.right.calculatedValue : 0,
                },
                left: {
                    evalue: facdLeft.evalueHistory ? facdLeft.evalueHistory.left.evalue : 0,
                    calculatedValue: facdLeft.evalueHistory ? facdLeft.evalueHistory.left.calculatedValue : 0,
                },
            },
            lensColor: {
                right: facdRight?.lensColor?.right,
                left: facdLeft?.lensColor?.left
            }
        };
        return d1Data;
    };

    /**
     * Give right eye data and initialize left values when doing treatment for right eye
     * @param facdData sat/mat data
     * @returns Modified sat/mat data for right eye alone
     */
    initializeAmendLeftEyeData = (amendInputData: AData): AData => {
        const amendDataRight: AData = {
            clinicName: amendInputData.clinicName,
            patientName: amendInputData.patientName,
            patientReference: amendInputData.patientReference,
            practitionerName: amendInputData.practitionerName,
            eyes: amendInputData.eyes,
            question1: {
                right: amendInputData.question1.right,
                left: 0,
            },
            question2: {
                right: amendInputData.question2.right,
                left: 0,
            },
            question3: {
                right: amendInputData.question3.right,
                left: 0,
            },
            question4: {
                right: {
                    1: amendInputData.question4.right[1],
                    2: amendInputData.question4.right[2],
                    3: amendInputData.question4.right[3],
                    4: amendInputData.question4.right[4],
                    5: amendInputData.question4.right[5],
                    6: amendInputData.question4.right[6],
                },
                left: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                },
            },
            question5: {
                right: {
                    1: amendInputData.question5.right[1],
                    2: amendInputData.question5.right[2],
                    3: amendInputData.question5.right[3],
                },
                left: {
                    1: 0,
                    2: 0,
                    3: axis.WTR,
                },
            },
            question6: {
                right: amendInputData.question6.right,
                left: false,
            },
            question7: {
                right: amendInputData.question7.right,
                left: false,
            },
            question8: {
                right: amendInputData.question8.right,
                left: 0,
            },
            question9: amendInputData.question9,
            question10: {
              right: amendInputData.question10.right,
              left: ''
            },
            lensColor: {
              right: amendInputData.lensColor?.right,
              left: ''
            },
        };
        return amendDataRight;
    };

    /**
     * Give left eye data and initialize right values when doing treatment for left eye
     * @param facdData sat/mat data
     * @returns Modified sat/mat data for left eye alone
     */
    initializeAmendRightEyeData = (amendInputData: AData): AData => {
        const amendDataLeft: AData = {
            clinicName: amendInputData.clinicName,
            patientName: amendInputData.patientName,
            patientReference: amendInputData.patientReference,
            practitionerName: amendInputData.practitionerName,
            eyes: amendInputData.eyes,
            question1: {
                right: 0,
                left: amendInputData.question1.left,
            },
            question2: {
                right: 0,
                left: amendInputData.question2.left,
            },
            question3: {
                right: 0,
                left: amendInputData.question3.left,
            },
            question4: {
                right: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                },
                left: {
                    1: amendInputData.question4.left[1],
                    2: amendInputData.question4.left[2],
                    3: amendInputData.question4.left[3],
                    4: amendInputData.question4.left[4],
                    5: amendInputData.question4.left[5],
                    6: amendInputData.question4.left[6],
                },
            },
            question5: {
                right: {
                    1: 0,
                    2: 0,
                    3: axis.WTR,
                },
                left: {
                    1: amendInputData.question5.left[1],
                    2: amendInputData.question5.left[2],
                    3: amendInputData.question5.left[3],
                },
            },
            question6: {
                right: false,
                left: amendInputData.question6.left,
            },
            question7: {
                right: false,
                left: amendInputData.question7.left,
            },
            question8: {
                right: 0,
                left: amendInputData.question8.left,
            },
            question9: amendInputData.question9,
            question10: {
              right: '',
              left: amendInputData.question10.left
            },
            lensColor: {
              right: '',
              left: amendInputData.lensColor?.left,
            },
        };
        return amendDataLeft;
    };

    /**
     * Takes in the lens name + list of lens, finds the hex code of lens based on the comparison
     * @param lensName name of lens selected
     * @param lensList list of lens under selected lab
     * @returns hexcode of color
     */
    getColorCodeFromLensSelection = (lensName: string, lensList: Lens[]): string => {
        const lensObject = lensList?.find(lens => lens?.name === lensName);
        return lensObject
            ? lensObject?.color || ''
            : '';
    }
}
