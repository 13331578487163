import { Component, Input, OnChanges } from '@angular/core';

import { Observable, of } from 'rxjs';

import { PatientInfo } from '@app/shared/models/patient-details.model';
import { EyeSide, LabFormDetails, eye } from '@app/core/treatment-engine/interfaces';
import { initializeLabformDetails } from '@app/core/util/treatment-output-details';
import { LensType } from '@app/core/util/common';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-lab-form',
    templateUrl: './lab-form.component.html',
    styleUrls: ['./lab-form.component.scss'],
})
export class LabFormComponent implements OnChanges {
    patientInfoDetails: PatientInfo[] = [];
    patientInfoDetailsObs$: Observable<PatientInfo[]> = of([]);

    @Input() labFormData: LabFormDetails = initializeLabformDetails;
    @Input() treatmentEye: eye = eye.empty;

    constructor(private _datePipe: DatePipe){}

    ngOnChanges(): void {
        if (this.labFormData) {
            this.patientInfoDetails = [
                {
                    fieldName: 'Full Name',
                    fieldValue: this.labFormData.patientName || '--',
                    uniqueTypeName: 'fullName',
                },
                {
                    fieldName: 'Reference Number',
                    fieldValue: this.labFormData.patientReference || '--',
                    uniqueTypeName: 'refNumber',
                },
                {
                    fieldName: 'Practice Name',
                    fieldValue: this.labFormData.clinicName || '--',
                    uniqueTypeName: 'practiceName',
                },
                {
                    fieldName: 'Practitioner',
                    fieldValue: this.labFormData.doctorName || '--',
                    uniqueTypeName: 'practitioner',
                },
                {
                    fieldName: 'Order Date',
                    fieldValue: this.removeTimeIfInISOFormatAndReturnDate(this.labFormData.orderDate) || '--',
                    uniqueTypeName: 'orderDate',
                },
            ];
            this.patientInfoDetailsObs$ = of(this.patientInfoDetails);
        }
    }

    removeTimeIfInISOFormatAndReturnDate = (date: string) => {
      const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|([+-])(\d{2}):(\d{2}))?$/;
      if(regex.test(date)){
        return this._datePipe.transform(date, "dd/MM/yyyy");
      };
      return date;
    }

    getMaterialStyle = (side: string): {'color': string} => {
      if(side === EyeSide.LEFT){
        return {
          'color': this.labFormData?.lensColor?.left
        }
      }
      return {
        'color': this.labFormData?.lensColor?.right
      }
    }

    getToricTypeStyle = (value: string): {'color': string, 'background-color': string, 'padding': string} | {} => {
      if(value === LensType.TORIC_LENS){
        return {
          'color': '#ff3154',
          'background-color': 'rgb(133 169 131 / 26%)',
          'padding': '5px'
        }
      }
      return {};
    }
}
