<div class="row">
    <div class="col-12">
        <div class="table-upper-container bg-white kl-table-upper-container">
            <div class="row d-flex justify-content-between h-100 align-items-center px-3">
                <div class="col-xl-7 col-12">
                    <div class="d-flex">
                      <div class="cursor-pointer position-relative filter-button-container me-2">
                          <img src="assets/icons/common/filter.svg" (click)="openFilterModal()" alt="filter" />
                          <i
                              class="bi bi-x-circle-fill clear-filter position-absolute"
                              [ngbTooltip]="'Clear Filter'"
                              *ngIf="showClearFilterIcon"
                              (click)="onClearFilter()"
                          ></i>
                      </div>
                      <div class="input-with-icon-type-1">
                          <input
                              type="text"
                              class="kl-input-search-bar"
                              placeholder="Search"
                              id="search-user"
                              (input)="onSearchInputChange($event)"
                          />
                          <i class="bi bi-search input-icon to-left gray-80"> </i>
                      </div>
                    </div>
                </div>
                <div class="col-lg-5 col-12 d-flex justify-content-md-end">
                  <div *ngIf="(tableData?.length || 0) > 0" class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end px-1 px-md-2 kl-select-container">
                    <select
                      id="order-status-dropdown"
                      (change)="onChangeOrderStatus()"
                      name="clinic"
                      class="kl-select"
                      [formControl]="orderStatusControl"
                      [disabled]="selectedItems.length === 0"
                    >
                      <option value="" selected>Change order status</option>
                      <option *ngFor="let status of manufacturerStatus" [value]="status">{{status}}</option>
                    </select>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <app-user-listing-table
            [tableColumns]="tableColumns"
            [tableData]="tableData"
            [collectionSize]="totalDataCount"
            [isViewable]="isViewOnly"
            [clearCheckedList]="clearCheckedList"
            (onPageChangeEmitter)="onPageChange($event)"
            (sortDataEmitter)="sortTableData($event)"
            (changePageLimitEmitter)="onLimitChange($event)"
            (onItemDataEmitter)="navigateWithAction($event)"
            (sendCheckedItemsId)="saveSelectedItems($event)"
        >
        </app-user-listing-table>
    </div>

    <app-confirmation-dialog #confirmationDialogCmp (cancelConfirmationStatus)="onCancelOrderChangeRequest()" (confirmationStatus)="onConfirmationStatusUpdate()">
      <h3 class="popup-main-title kl-text3-type-7 text-center">
          Are you sure?
      </h3>
      <h4 class="popup-body-content kl-text5-type3">
          Do you want to change order to {{orderStatusControl.value}}?
      </h4>
    </app-confirmation-dialog>
</div>
