import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { ApolloQueryResult } from '@apollo/client/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, combineLatest, filter, map, takeWhile, throwError } from 'rxjs';

import { Common, Roles, Url } from '@app/core/util/common';
import { UserRole } from '@app/ui/models/ui.model';
import { CommonService } from '@app/core/services/common.service';
import { ToasterService } from '@app/core/services/toaster.service';
import {
    ControlDataItem,
    grantMultipleRoleFormControlsData,
    grantMultipleRoleFormControlsDataDistributorMapping,
} from '@app/core/util/initial-values';
import { getGrantedRoleListPerUser, getRoleDetails } from '@app/ui/actions/ui.actions';
import { selectGrantedUserRoleList, selectRoleDetails } from '@app/ui/selectors/ui.selector';
import {
    Clinic_Type_Enum,
    GetListOfUserBasedOnRoleIdQuery,
    GetRoleDetailsQuery,
    GrantMultipleRolesToUsersMutationVariables,
} from '@app/generated/graphql';
import { insertInCostTable } from '@app/shared/actions/shared.action';
import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
    selector: 'app-grant-multiple-role',
    templateUrl: './grant-multiple-role.component.html',
    styleUrls: ['./grant-multiple-role.component.scss'],
})
export class GrantMultipleRoleComponent implements OnInit, OnDestroy {
    isAlive = true;
    userId = '';
    userName = '';
    userData!: any;
    selectedRole = '';
    grantAccessFor = '';
    selectedDistributor = '';
    grantingUserRoleName = '';
    roleList: UserRole[] = [];
    completeRoleList: UserRole[] = [];
    grantMultipleRoleFormControlsData = grantMultipleRoleFormControlsData;
    grantMultipleRoleDistributorFormControlsData = grantMultipleRoleFormControlsDataDistributorMapping;
    grantRoleForm: FormGroup = new FormGroup({});
    loggedInUserId = '';

    roleList$: Observable<GetRoleDetailsQuery> = this._store.pipe(
        takeWhile(() => this.isAlive),
        select(selectRoleDetails)
    );

    grantedRoleList$: Observable<UserRole[] | null> = this._store.pipe(
        takeWhile(() => this.isAlive),
        select(selectGrantedUserRoleList)
    );

    constructor(
        public activeModal: NgbActiveModal,
        private _commonService: CommonService,
        private _store: Store,
        private _fb: FormBuilder,
        private _toast: ToasterService,
        private _router: Router,
        private _activatedRouter: ActivatedRoute,
        private _credentialsService: CredentialsService
    ) {}

    ngOnInit(): void {
        this._store.dispatch(getRoleDetails({}));
        this._store.dispatch(getGrantedRoleListPerUser({ userId: this.userId }));
        this.filterAccessibleRoles();
        this.initForm();
        this.loggedInUserId = this._credentialsService.credentials?.userId || '';
    }

    /**
     * Fetch list of roles and based on users current role filter out the roles shown
     * Current role shouldnt be shown in the role selection dropdown
     */
    filterAccessibleRoles = (): void => {
        combineLatest([this.roleList$, this.grantedRoleList$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter(
                    ([roleListIn, grantedRoles]: [GetRoleDetailsQuery, UserRole[] | null]) =>
                        !!grantedRoles && !!roleListIn
                ),
                map(([roleListIn, grantedRoles]: [GetRoleDetailsQuery, UserRole[] | null]) => {
                    this.completeRoleList = roleListIn?.role;
                    let roleList = roleListIn?.role;
                    const grantedRoleNames = grantedRoles?.map((role) => role.name) || [];
                    const grantedRoleIds = grantedRoles?.map((role) => role.id) || [];

                    const roleMapping: Record<string, string> = {
                        [Roles.SELECTIVE_DISTRIBUTOR]: Roles.EXCLUSIVE_DISTRIBUTOR,
                        [Roles.REGULAR_CLINIC]: Roles.FRANCHISE_CLINIC,
                        [Roles.EXCLUSIVE_DISTRIBUTOR]: Roles.SELECTIVE_DISTRIBUTOR,
                        [Roles.FRANCHISE_CLINIC]: Roles.REGULAR_CLINIC,
                    };

                    for (const [hasRole, removeRole] of Object.entries(roleMapping)) {
                        if (grantedRoleNames.includes(hasRole)) {
                            const removeRoleId = roleList?.find((role) => role?.name === removeRole)?.id;
                            // Remove existing roles.
                            roleList = roleList?.filter((role) => role?.id !== removeRoleId);
                        }
                    }
                    // remove care taker role and return role list ( since care taker role is related to patients and not considered for grant role feature).
                    return roleList
                        ?.filter((role) => !grantedRoleIds.includes(role?.id))
                        ?.filter((role) => role?.name !== Roles.CARE_TAKER);
                })
            )
            .subscribe((filteredRoles) => {
                this.roleList = filteredRoles;
            });
    };

    initForm = (): void => {
        this.grantRoleForm = this._fb.group({
            role: ['', Validators.required],
        });
    };

    /**
     * Helper function to get a control's validation status
     */
    isInvalid(controlName: string): boolean {
        const control = this.grantRoleForm.get(controlName);
        if (control?.invalid && control?.touched) {
            return true;
        }
        return false;
    }

    /**
     * Assign selected role
     * Adds form controls based on selected roles required fields
     * @param selectedRoleIn shares selected role.
     */
    onSelectingRole = (selectedRoleIn: Event): void => {
        const selectedRole = selectedRoleIn.target as HTMLSelectElement;
        if (selectedRole?.value) {
            const selectedRoleId = selectedRole?.value;

            // Clear existing form controls.
            Object.keys(this.grantRoleForm?.controls).forEach((controlName: string) => {
                if (controlName !== 'role') {
                    this.grantRoleForm.removeControl(controlName);
                }
            });
            const roleName = this.roleList.find((role) => role.id === selectedRoleId);
            this.selectedRole = roleName?.name ? roleName?.name : '';
            const key = Object.keys(this.grantMultipleRoleFormControlsData).find((key) => key === this.selectedRole);

            if (key) {
                const requestedElement: ControlDataItem[] = this.grantMultipleRoleFormControlsData[key];

                // Iterate through each control under the selected role
                // If the selected role is of distributor, call api to show list of designers
                requestedElement?.forEach((controlData, index) => {
                    if (controlData?.type === 'select') {
                        if (controlData?.callApi) {
                            const designerRole = this.completeRoleList.find((role) => role.name === Roles.DESIGNER) || {
                                id: '',
                                name: '',
                            };
                            this.getUserList(designerRole.id, requestedElement[index]);
                        }
                        this.grantRoleForm.addControl(controlData?.id, this._fb.control('', Validators.required));
                    } else {
                        this.grantRoleForm.addControl(controlData?.id, this._fb.control('', Validators.required));
                    }
                });
            }
        }
    };

    /**
     * Get user list based on the value selected.
     * @param value shares dynamic select control value.
     */
    onControlDataSelectChange = (value: Event): void => {
        const selectedValue = (value.target as HTMLSelectElement).value;
        if (selectedValue === Roles.EXCLUSIVE_DISTRIBUTOR || selectedValue === Roles.SELECTIVE_DISTRIBUTOR) {
            this.onSelectDistributorType(selectedValue);
        }
    };

    /**
     * Based on selected distributor type show the list of distributors
     * Add controls based on the key from the initial values constant 'grantMultipleRoleDistributorFormControlsData'
     */
    onSelectDistributorType = (selectedDistributorType: string): void => {
        this.selectedDistributor = selectedDistributorType;
        const distributorRoleId =
            this.completeRoleList?.find((role) => role?.name === selectedDistributorType)?.id || '';
        const selectedDistributorKey = Object.keys(this.grantMultipleRoleDistributorFormControlsData).find(
            (key) => key === selectedDistributorType
        );

        // Remove any distributors control exist
        [Roles.EXCLUSIVE_DISTRIBUTOR, Roles.SELECTIVE_DISTRIBUTOR]?.forEach((controlName: string) => {
            this.grantRoleForm.removeControl(controlName);
        });

        let requestedElement: ControlDataItem[];

        if (selectedDistributorKey) {
            requestedElement = this.grantMultipleRoleDistributorFormControlsData[selectedDistributorKey];

            requestedElement?.forEach((element: ControlDataItem) => {
                if (element?.callApi) {
                    this.getUserList(distributorRoleId, element);
                }
                this.grantRoleForm.addControl(element?.id, this._fb.control('', Validators.required));
            });
        }
    };

    /**
     * Fetches the list of users based on role Id
     * Given a dummy value so as to get all users
     * Populates the data to options field of the metadata to show the dropdown list
     */
    getUserList = (userRoleId: string, requestedElement: ControlDataItem): void => {
        this._commonService
            .getListOfUserByRoleId({ roleId: userRoleId })
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((userList: ApolloQueryResult<GetListOfUserBasedOnRoleIdQuery>) => {
                if (requestedElement) {
                    requestedElement.options = userList?.data?.user_role_mapping?.map((item) => {
                        return {
                            id: item?.user?.id || '',
                            name: item?.name || '',
                        };
                    });
                }
            });
    };

    /**
     * Prepares payload for different user role access
     * @returns Pyload to be passed for role access mutation
     */
    prepareGrantRolePayload = (): GrantMultipleRolesToUsersMutationVariables => {
        const roleId = this.grantRoleForm.get('role')?.value;
        const selectedDistributor = this.grantRoleForm.get('distributor')?.value || '';
        const selectedDesigner = this.grantRoleForm.get('designer')?.value || '';
        const distributorType = this.grantRoleForm.get('distributor_type')?.value || '';
        const warrantyPeriod = this.grantRoleForm.get('warranty_period')?.value || 0;
        const roleName = this.completeRoleList?.find((role) => role?.id === roleId)?.name || '';
        this.grantingUserRoleName = roleName;
        const grantMultipleRolesToUsersMutationVariables: GrantMultipleRolesToUsersMutationVariables = {
            roleId,
            userId: this.userId,
            warrantyPeriod,
            distributorType,
            roleDetails: { ...this.userData, ...this.grantRoleForm.value },
            superiorId:
                roleName === Roles.EXCLUSIVE_DISTRIBUTOR || roleName === Roles.SELECTIVE_DISTRIBUTOR
                    ? selectedDesigner
                    : selectedDistributor,
            clinicType:
                roleName === Roles.FRANCHISE_CLINIC ? Clinic_Type_Enum.FranchiseClinic : Clinic_Type_Enum.RegularClinic,
            isOfClinicType: roleName === Roles.FRANCHISE_CLINIC || roleName === Roles.REGULAR_CLINIC ? true : false,
            isGoldPartner: roleName === Roles.FRANCHISE_CLINIC ? true : false,
            isPartner: roleName === Roles.REGULAR_CLINIC ? true : false,
            isManufacturer: roleName === Roles.CONTRACT_MANUFACTURER ? true : false,
            isDistributor:
                roleName === Roles.EXCLUSIVE_DISTRIBUTOR || roleName === Roles.SELECTIVE_DISTRIBUTOR ? true : false,
            name: this.userData?.name,
        };
        return grantMultipleRolesToUsersMutationVariables;
    };

    /**
     * Call mutation to insert to user role mapping and other mapping tables
     * This ensures that the user heirarchy is proper
     */
    onGrantRole = (): void => {
        this.grantRoleForm.markAllAsTouched();
        if (!this.grantRoleForm.valid) {
            return;
        }
        const grantMultipleRolesToUsersMutationVariables: GrantMultipleRolesToUsersMutationVariables =
            this.prepareGrantRolePayload();
        this._commonService
            .grantMultipleRolesToUsers(grantMultipleRolesToUsersMutationVariables)
            .pipe(
                takeWhile(() => this.isAlive),
                catchError((error) => {
                    this._toast.error('Granting role fail. Please try again.');
                    return throwError(() => error);
                })
            )
            .subscribe((res) => {
                const roleMappingId = res.data?.insert_user_role_mapping?.returning?.[0]?.id || '';
                const userName = res.data?.insert_user_role_mapping?.returning?.[0]?.name || '';
                this._toast.success('Granted Selected Role');
                if (
                    this.grantingUserRoleName === Roles.REGULAR_CLINIC ||
                    this.grantingUserRoleName === Roles.FRANCHISE_CLINIC ||
                    this.grantingUserRoleName === Roles.DESIGNER ||
                    this.grantingUserRoleName === Roles.EXCLUSIVE_DISTRIBUTOR ||
                    this.grantingUserRoleName === Roles.SELECTIVE_DISTRIBUTOR
                ) {
                    this._store.dispatch(
                        insertInCostTable({ userRoleMappingId: roleMappingId, createdBy: this.loggedInUserId })
                    );
                }
                this.activeModal.dismiss();
                if (this.grantingUserRoleName === Roles.DOCTOR) {
                    this.redirectToPractitionerEditPage(
                        grantMultipleRolesToUsersMutationVariables,
                        roleMappingId,
                        userName
                    );
                }
            });
    };

    redirectToPractitionerEditPage = (
        grantMultipleRolesToUsersMutationVariables: GrantMultipleRolesToUsersMutationVariables,
        roleMappingId: string,
        userName: string
    ): void => {
        this._router.navigate([`${Url.DOCTORS_LISTING}/${grantMultipleRolesToUsersMutationVariables.userId}`], {
            queryParams: {
                mode: Common.EDIT,
                doctorId: grantMultipleRolesToUsersMutationVariables.userId,
                roleMappingId: roleMappingId,
                breadCrumbName: userName,
            },
            relativeTo: this._activatedRouter,
        });
    };

    ngOnDestroy(): void {
        this.isAlive = false;
    }
}
