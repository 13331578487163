import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { MutationResult } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, throwError, of, tap, switchMap } from 'rxjs';

import {
    ActionTypes,
    ClinicForUserDetailsPage,
    DistributorForUserDetailsPage,
    PatientForUserDetailsPage,
} from '../models/shared.model';
import {
    clearCommonDetailsSuccess,
    getContractManufacturersForUserDetailsPage,
    getContractManufacturersForUserDetailsPageSuccess,
    getDetailsOfOrderByIdSuccess,
    getDistributorsForUserDetailsPage,
    getDistributorsForUserDetailsPageSuccess,
    getDoctorsForUserDetailsPage,
    getDoctorsForUserDetailsPageSuccess,
    getFormDataFilledByDoctorSuccess,
    getFranchiseClinicsForUserDetailsPage,
    getFranchiseClinicsForUserDetailsPageSuccess,
    getOrdersForUserDetailsPage,
    getOrdersForUserDetailsPageSuccess,
    getPatientsForUserDetailsPage,
    getPatientsForUserDetailsPageSuccess,
    getRegularClinicsForUserDetailsPage,
    getRegularClinicsForUserDetailsPageSuccess,
    getTotalCountOfClinicForUserDetailsPage,
    getTotalCountOfClinicForUserDetailsPageSuccess,
    getTotalCountOfContractManufacturerForUserDetailsPage,
    getTotalCountOfContractManufacturerForUserDetailsPageSuccess,
    getTotalCountOfDistributorForUserDetailsPage,
    getTotalCountOfDistributorForUserDetailsPageSuccess,
    getTotalCountOfDoctorForUserDetailsPage,
    getTotalCountOfDoctorForUserDetailsPageSuccess,
    getTotalCountOfOrdersForUserDetailsPage,
    getTotalCountOfOrdersForUserDetailsPageSuccess,
    getTotalCountOfPatientForUserDetailsPage,
    getTotalCountOfPatientForUserDetailsPageSuccess,
    getRoleSpecificUsersSuccess,
    refreshUserListAfterUserDelete,
    updateOrderDetailsSuccess,
    getControlPanelDetailsByOrderIdSuccess,
    getFilterByValuesSuccess,
    getPatientFormDataSuccess,
    getOrdersForManufacturerSuccess,
    getTotalOrderCountForManufacturerSuccess,
    getDistributorDataForManufacturerSuccess,
    getOrderDetailsByOrderIdSuccess,
    getRoleHierarchyForLoggedInUserSuccess,
    getUserIdAndPriceDataForPaymentSuccess,
    getDeletingUserSuperiorSuccess,
    updateBuyerRoleIdToPaymentIdSuccess,
    updateSellerRoleIdToPaymentIdSuccess,
} from '../actions/shared.action';
import { ApolloQueryResult } from '@apollo/client/core';
import { OrdersService } from '@app/core/services/orders.service';
import { CommonService } from '@app/core/services/common.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import {
    BulkUpdateOrderStatusMutationVariables,
    DeleteUserOrDeleteUserAndReassignMutationVariables,
    GetControlPanelValuesByOrderIdQuery,
    GetControlPanelValuesByOrderIdQueryVariables,
    GetDetailsOfOrderByIdQuery,
    GetDetailsOfOrderByIdQueryVariables,
    GetDistributorByDoctorAndClinicIdQuery,
    GetDistributorByDoctorAndClinicIdQueryVariables,
    GetFormDataFilledByDoctorForDesignViewQuery,
    GetFormDataFilledByDoctorForDesignViewQueryVariables,
    GetOrderDetailsByIdQuery,
    GetOrderDetailsByIdQueryVariables,
    GetOrdersCountBasedOnDistributorIdQuery,
    GetOrdersCountBasedOnDistributorIdQueryVariables,
    GetOrdersListBasedOnDistributorIdQuery,
    GetOrdersListBasedOnDistributorIdQueryVariables,
    GetUserFormData1Query,
    GetUserFormData1QueryVariables,
    GetRoleHierarchyForLoggedInUserQuery,
    GetRoleHierarchyForLoggedInUserQueryVariables,
    GetRoleSpecificUsersQuery,
    GetRoleSpecificUsersQueryVariables,
    GetSuperiorIdOfUserQuery,
    GetSuperiorIdOfUserQueryVariables,
    GetUserFormDataQueryVariables,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables,
    InsertInCostTableMutationVariables,
    InsertOrderStatusTrackingDataMutationVariables,
    InsertToPaymentForTotalCostAndSalesMutation,
    InsertToPaymentForTotalCostAndSalesMutationVariables,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutation,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserMutation,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateUserDetailsMutationVariables,
    UpdateUserRoleDetailsMutation,
    UpdateUserRoleDetailsMutationVariables,
} from '@app/generated/graphql';
import { Roles } from '@app/core/util/common';
import { setLoadingSpinner } from '@app/ui/actions/ui.actions';
import { LoginResponseData } from '@app/auth/models/auth.model';
import { filterValues } from '@app/core/util/initial-values';
import { PaymentHandlerService } from '@app/core/services/payment-handler.service';

@Injectable()
export class SharedModuleEffects {
    constructor(
        private _store: Store,
        private _action$: Actions,
        private _toast: ToastrService,
        private _ordersService: OrdersService,
        private _commonService: CommonService,
        private _credentialService: CredentialsService,
        private _paymentHandleService: PaymentHandlerService
    ) {}

    /**
     * Clear common data on logout
     * @param Nil
     * @returns Success action
     */
    clearCommonData$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.clearCommonDetailsAfterLogin),
            map(() => {
                const storageItem = localStorage.getItem('commonDetailsAfterLogin');
                if (storageItem) {
                    localStorage.removeItem('commonDetailsAfterLogin');
                }
                return clearCommonDetailsSuccess();
            })
        )
    );

    /**
     * Get user list based on specific role.
     */
    getRoleSpecificUserList$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getRoleSpecificUsers),
            mergeMap((action: { roleObj: GetRoleSpecificUsersQueryVariables }) =>
                this._commonService.getRoleSpecificUserList(action.roleObj).pipe(
                    map((result: ApolloQueryResult<GetRoleSpecificUsersQuery>) => {
                      const { isClinic, isDesigner, isDistributor, isDoctor } = action?.roleObj;
                        const users: { name: string; id: string, email?: string }[] = this._commonService.filterRoleSpecifUsers(
                            result?.data,
                            action?.roleObj
                        );
                        return getRoleSpecificUsersSuccess(users);
                    }),
                    catchError((error) => of())
                )
            )
        )
    );
    /*
     * Get details of order by order id
     */
    getDetailsOfOrderById$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getDetailsOfOrderById),
            mergeMap((params: { orderId: string }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                const orderIdParam: GetDetailsOfOrderByIdQueryVariables = {
                    orderId: params.orderId,
                };
                return this._ordersService.getOrderDetailsByOrderId(orderIdParam).pipe(
                    map((result: ApolloQueryResult<GetDetailsOfOrderByIdQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getDetailsOfOrderByIdSuccess(result?.data);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /*
     * Get form data filled by doctor (facd, sat, mat)
     */
    getFormDataForDesignView$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getFormDataFilledByDoctor),
            mergeMap((params: { orderIds: GetFormDataFilledByDoctorForDesignViewQueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._ordersService.getFormDataFilledByDoctorForDesignView(params.orderIds).pipe(
                    map((result: ApolloQueryResult<GetFormDataFilledByDoctorForDesignViewQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getFormDataFilledByDoctorSuccess(result?.data);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    deleteUserOrDeleteUserAndReassign$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.deleteUserOrDeleteUserAndReassign),
            mergeMap((action: { params: DeleteUserOrDeleteUserAndReassignMutationVariables }) =>
                this._commonService.deleteUserOrDeleteUserAndReassign(action?.params).pipe(
                    tap(() => this._toast.success('User deleted successfully.')),
                    map((response) => refreshUserListAfterUserDelete(action?.params?.role)),
                    catchError((error) =>
                        throwError(() => {
                            this._toast.error('Something went wrong!');
                            return new Error('Failed to delete User');
                        })
                    )
                )
            )
        )
    );

    // Fetches the list of franchise clinics.
    getFranchiseClinicsForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getFranchiseClinicsForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getListOfFranchiseClinicsForDetailsPage(action.data).pipe(
                    map((response) => {
                        const franchiseClinic: ClinicForUserDetailsPage[] =
                            this._commonService.transformClinicForUserDetailsPage(response?.data, 'Franchise');
                        return getFranchiseClinicsForUserDetailsPageSuccess(franchiseClinic);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the list of regular clinics.
    getRegularClinicsForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getRegularClinicsForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getListOfRegularClinicsForDetailsPage(action.data).pipe(
                    map((response) => {
                        const regularClinics: ClinicForUserDetailsPage[] =
                            this._commonService.transformClinicForUserDetailsPage(response?.data, 'Regular');
                        return getRegularClinicsForUserDetailsPageSuccess(regularClinics);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the list of distributors.
    getDistributorsForUserDetailsPage$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(getDistributorsForUserDetailsPage),
                mergeMap((action) =>
                    this._commonService.getDistributorsForDetailsPage(action.data).pipe(
                        map((response) => {
                            let distributors: DistributorForUserDetailsPage[] = [];
                            this._commonService
                                .transformDistributorForUserDetailsPage(response?.data)
                                .then((response) => {
                                    distributors = response;
                                    this._store.dispatch(getDistributorsForUserDetailsPageSuccess(distributors));
                                });
                        }),
                        catchError(() => throwError(() => new Error('')))
                    )
                )
            ),
        { dispatch: false }
    );

    // Fetches the list of contract manufacturers.
    getContractManufacturersForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getContractManufacturersForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getContractManufacturersForDetailsPage(action?.data).pipe(
                    map((response) => {
                        const contractManufacturers =
                            this._commonService.transformContractManufacturerForUserDetailsPage(response?.data);
                        return getContractManufacturersForUserDetailsPageSuccess(contractManufacturers);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the list of doctors.
    getDoctorsForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getDoctorsForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getDoctorsListForDetailsPage(action.data).pipe(
                    map((response) => {
                        const doctors = this._commonService.transformDoctorsForUserDetailsPage(
                            response?.data,
                            action?.data?.regularClinic
                        );
                        return getDoctorsForUserDetailsPageSuccess(doctors);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the list of patients.
    getPatientsForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getPatientsForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getPatientListForDetailsPage(action.data).pipe(
                    map((response) => {
                        const patientList: PatientForUserDetailsPage[] =
                            this._commonService.transformPatientsForUserDetailsPage(
                                response?.data,
                                action?.data?.isClinic
                            );
                        return getPatientsForUserDetailsPageSuccess(patientList);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the list of orders.
    getOrdersForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getOrdersForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getOrdersForDetailsPage(action?.data).pipe(
                    map((response) => {
                        const orders = this._commonService.transformOrdersForUserDetailsPage(
                            response?.data,
                            action?.data?.isDoctor,
                            action?.data?.isClinic,
                            action?.data?.isDistributor
                        );
                        return getOrdersForUserDetailsPageSuccess(orders);
                    }),
                    catchError(() => throwError(() => new Error('')))
                )
            )
        )
    );

    // Fetches the total count of clinics.
    getTotalCountOfClinicForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfClinicForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfClinicForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        let totalCount;
                        if (action?.data?.isDesigner) {
                            totalCount = response?.data?.designer?.aggregate?.count;
                        } else {
                            totalCount = response?.data?.distributor?.aggregate?.count;
                        }
                        return getTotalCountOfClinicForUserDetailsPageSuccess(totalCount as number);
                    }),
                    catchError(() =>
                        throwError(() => new Error('Failed to get total count of clinics for user details page'))
                    )
                )
            )
        )
    );

    // Fetches the total count of contract manufacturers.
    getTotalCountOfContractManufacturerForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfContractManufacturerForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfContractManufacturerForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        const totalCount = response?.data?.distributor?.aggregate?.count as number;
                        return getTotalCountOfContractManufacturerForUserDetailsPageSuccess(totalCount);
                    }),
                    catchError(() =>
                        throwError(
                            () => new Error('Failed to get total count of contract manufacturers for user details page')
                        )
                    )
                )
            )
        )
    );

    // Fetches the total count of distributors.
    getTotalCountOfDistributorsForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfDistributorForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfDistributorsForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        const totalCount = response?.data?.designer?.aggregate?.count as number;
                        return getTotalCountOfDistributorForUserDetailsPageSuccess(totalCount);
                    }),
                    catchError(() =>
                        throwError(() => new Error('Failed to get total count of distributor for user details page'))
                    )
                )
            )
        )
    );

    // Fetches the total count of orders.
    getTotalCountOfOrdersForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfOrdersForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfOrdersForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        let totalCount;
                        if (action?.data?.isClinic) {
                            totalCount = response?.data?.clinic?.aggregate?.count;
                        } else if (action?.data?.isDistributor) {
                            totalCount = response?.data?.distributor?.aggregate?.count;
                        } else if (action?.data?.isDoctor) {
                            totalCount = response?.data?.doctor?.aggregate?.count;
                        } else if (action?.data?.isDesigner) {
                            totalCount = response?.data?.designer?.aggregate?.count;
                        } else {
                            totalCount = response?.data?.manufacturer?.aggregate?.count;
                        }
                        return getTotalCountOfOrdersForUserDetailsPageSuccess(totalCount as number);
                    }),
                    catchError(() =>
                        throwError(() => new Error('Failed to get total count of orders for user details page'))
                    )
                )
            )
        )
    );

    // Fetches the total count of doctors.
    getTotalCountOfDoctorForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfDoctorForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfDoctorForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        let totalCount;
                        if (action?.data?.isFranchiseClinic) {
                            totalCount = response?.data?.franchiseClinic?.aggregate?.count;
                        } else {
                            totalCount = response?.data?.regularClinic?.aggregate?.count;
                        }
                        return getTotalCountOfDoctorForUserDetailsPageSuccess(totalCount as number);
                    }),
                    catchError(() =>
                        throwError(() => new Error('Failed to get total count of doctors for user details page'))
                    )
                )
            )
        )
    );

    // Fetches the total count of patients.
    getTotalCountOfPatientForUserDetailsPage$ = createEffect(() =>
        this._action$.pipe(
            ofType(getTotalCountOfPatientForUserDetailsPage),
            mergeMap((action) =>
                this._commonService.getTotalCountOfPatientForUserDetailsPage(action.data).pipe(
                    map((response) => {
                        let totalCount;
                        if (action?.data?.isClinic) {
                            totalCount = response?.data?.clinic?.aggregate?.count;
                        } else {
                            totalCount = response?.data?.doctor?.aggregate?.count;
                        }
                        return getTotalCountOfPatientForUserDetailsPageSuccess(totalCount as number);
                    }),
                    catchError(() =>
                        throwError(() => new Error('Failed to get total count of patients for user details page'))
                    )
                )
            )
        )
    );

    /*
     * Order status updation
     */
    updateOrderStatus$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.updateOrderStatus),
            mergeMap((orderDetailsParams: { orderDetailsParams: UpdateOrderStatusMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._ordersService.updateOrderStatus(orderDetailsParams.orderDetailsParams).pipe(
                    map((result: MutationResult<UpdateOrderStatusMutation>) => {
                        const data: any = result?.data;
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        this._toast.success('Order cancelled successfully');
                        this._commonService.sendUpdateOrderStatus(orderDetailsParams.orderDetailsParams.order_status ?? '');
                        return updateOrderDetailsSuccess(data);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        this._toast.error('Something went wrong. Please try again.');;
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /*
     * Get control panel details based on order id
     */
    getControlPanelDetailsByOrderId$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getControlPanelDetailsByOrderId),
            mergeMap((params: { id: GetControlPanelValuesByOrderIdQueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonService.getControlPanelDetailsByOrderId(params?.id).pipe(
                    map((result: ApolloQueryResult<GetControlPanelValuesByOrderIdQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getControlPanelDetailsByOrderIdSuccess(result?.data);
                    }),
                    catchError(() => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    getDistributorByDoctorAndClinicId$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.getDistributorByDoctorAndClinicId),
                mergeMap((params: { getDistributorIdParams: GetDistributorByDoctorAndClinicIdQueryVariables }) => {
                    return this._commonService.getDistributorByDoctorAndClinicId(params?.getDistributorIdParams).pipe(
                        map((result: ApolloQueryResult<GetDistributorByDoctorAndClinicIdQuery>) => {
                            const distributorId =
                                result?.data?.user?.[0]?.clinicDoctorMappingsByDoctorId?.[0]?.user
                                    ?.clinic_distributors_by_clinic_id?.[0]?.distributor_id;
                            let credentials = this._credentialService.credentials as LoginResponseData;
                            credentials = { ...credentials, distributorId: distributorId };
                            this._credentialService.setCredentials(credentials, true);
                        }),
                        catchError(() => {
                            return throwError(() => {
                                return new Error('');
                            });
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    getFilterByValues$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getFilterByValues),
            mergeMap((params: { filterByParams: keyof typeof filterValues }) => {
                try {
                    const filterByValues = this._commonService.getSavedFilterByValues(params.filterByParams);
                    return of(getFilterByValuesSuccess(filterByValues));
                } catch (error) {
                    return throwError(() => {
                        return new Error('');
                    });
                }
            })
        )
    );

    /*
     * Get data of onboarded patients to populate onboarded form - used for editing user onboard form.
     */
    getPatientFormDetails$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getPatientFormData),
            mergeMap((params: { userId: GetUserFormData1QueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonService.getPatientFormDetailsGQL(params.userId).pipe(
                    map((result: ApolloQueryResult<GetUserFormData1Query>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getPatientFormDataSuccess(result?.data);
                    }),
                    catchError(() => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Get list of orders to display in contract manufacturer side
     */
    getOrdersListForManufacturer$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getOrdersForManufacturer),
            mergeMap(
                (orderSummaryListParams: {
                    orderSummaryListParams: GetOrdersListBasedOnDistributorIdQueryVariables;
                }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._commonService
                        .getOrderListForManufacturer(orderSummaryListParams?.orderSummaryListParams)
                        .pipe(
                            map((result: ApolloQueryResult<GetOrdersListBasedOnDistributorIdQuery>) => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                return getOrdersForManufacturerSuccess(result?.data);
                            }),
                            catchError((error) => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                this._toast.error('Failed to fetch the list, please try again!');
                                return throwError(() => {
                                    return new Error('');
                                });
                            })
                        );
                }
            )
        )
    );

    /**
     * Get count of orders
     */
    getOrdersListCountForManufacturer$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getTotalOrderCountForManufacturer),
            mergeMap(
                (orderSummaryListCountParams: {
                    orderSummaryListCountParams: GetOrdersCountBasedOnDistributorIdQueryVariables;
                }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._commonService
                        .getOrdersListCountForManufacturer(orderSummaryListCountParams?.orderSummaryListCountParams)
                        .pipe(
                            map((result: ApolloQueryResult<GetOrdersCountBasedOnDistributorIdQuery>) => {
                                const totalCount: any = result?.data || 0;
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                return getTotalOrderCountForManufacturerSuccess(totalCount);
                            }),
                            catchError((error) => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                this._toast.error('Failed to fetch the count, please try again!');
                                return throwError(() => {
                                    return new Error('');
                                });
                            })
                        );
                }
            )
        )
    );

    /**
     * Gets distributor data to display in contract manufacturer login
     * @returns Stores the distributor data to state / throws error
     */
    getDistributorDataForManufacturer$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getDistributorDataForManufacturer),
            mergeMap((userFormDataParams: { userFormDataParams: GetUserFormDataQueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonService.getPatientFormDetailsGQL(userFormDataParams?.userFormDataParams).pipe(
                    map((result: ApolloQueryResult<GetUserFormData1Query>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getDistributorDataForManufacturerSuccess(result?.data);
                    }),
                    catchError(() => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Insert order_id and status to tracking(order_status_info) table
     */
    insertOrderDataToTrackingTable$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.insertOrderStatusTrackingData),
                mergeMap((orderTrackingData: { orderTrackingData: InsertOrderStatusTrackingDataMutationVariables }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._commonService.insertOrderDataToTrackingTable(orderTrackingData.orderTrackingData).pipe(
                        map(() => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                        }),
                        catchError((error) => {
                            return throwError(() => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                this._toast.error('Something went wrong. Please try again.');
                                return new Error('');
                            });
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    // Fetches the order status details based on order id from tracking(order_status_info).
    getOrderDetailsByOrderId$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getOrderDetailsByOrderId),
            mergeMap((params: { orderIdParams: GetOrderDetailsByIdQueryVariables }) => {
                return this._commonService.getOrderDetailsByOrderId(params?.orderIdParams).pipe(
                    map((result: ApolloQueryResult<GetOrderDetailsByIdQuery>) => {
                        return getOrderDetailsByOrderIdSuccess(result?.data);
                    }),
                    catchError(() => {
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    bulkUpdateOrderStatus$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.bulkUpdateOrderStatus),
                mergeMap((action: { bulkUpdateOrderStatusParams: BulkUpdateOrderStatusMutationVariables }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._commonService.bulkUpdateOrderStatus(action.bulkUpdateOrderStatusParams).pipe(
                        map(() => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            this._toast.success('Order status updated successfully.');
                            this._ordersService.emitOrderStatusUpdatedEvent(true);
                        }),
                        catchError((error) => {
                            return throwError(() => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                this._toast.error('Failed to update order. Please try again.');
                                return new Error('');
                            });
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    getRoleHierarchyForLoggedInUser$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.getRoleHierarchyForLoggedInUser),
            mergeMap((params: { loggedInUserDetails: GetRoleHierarchyForLoggedInUserQueryVariables }) => {
                return this._commonService.getRoleHierarchyForLoggedInUser(params?.loggedInUserDetails).pipe(
                    map((result: ApolloQueryResult<GetRoleHierarchyForLoggedInUserQuery>) => {
                        const roleHierarchyForLoggedInUser = this._commonService.transformRoleHierarchyForLoggedInUser(
                            result?.data
                        );
                        return getRoleHierarchyForLoggedInUserSuccess(roleHierarchyForLoggedInUser);
                    }),
                    catchError(() => {
                        return throwError(() => {
                            return new Error('An error occurred while fetching the role hierarchy.');
                        });
                    })
                );
            })
        )
    );

    /**
     * Fetch user id's and price settings data of users above doctors and store them for payment entry purpose
     */
    getUserIdsAndPriceSettingData$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.getUserIdAndPriceDataForPayment),
                mergeMap(
                    (action: { userData: GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables }) => {
                        this._store.dispatch(setLoadingSpinner({ status: true }));
                        return this._commonService.getUserIdsAndPriceSettingsData(action.userData).pipe(
                            map(
                                (
                                    result: ApolloQueryResult<GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery>
                                ) => {
                                    const clinicType = action.userData.isGoldPartner
                                        ? Roles.FRANCHISE_CLINIC
                                        : Roles.REGULAR_CLINIC;
                                    if (result?.data?.clinic_doctor_mapping?.length > 0) {
                                        const userIdAndPriceDataForPayment =
                                            this._paymentHandleService.processUserIdAndPriceSettingsDataForPayment(
                                                result?.data,
                                                clinicType,
                                                action.userData.doctorId
                                            );
                                        this._store.dispatch(
                                            getUserIdAndPriceDataForPaymentSuccess(userIdAndPriceDataForPayment)
                                        );
                                    }
                                    this._store.dispatch(setLoadingSpinner({ status: false }));
                                }
                            ),
                            catchError((error) => {
                                this._store.dispatch(setLoadingSpinner({ status: false }));
                                return throwError(() => {
                                    return new Error('');
                                });
                            })
                        );
                    }
                )
            ),
        { dispatch: false }
    );

    /**
     * Effect for inserting payment entries to payment table for an order
     */
    InsertToPaymentTableForOrder$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.insertToPaymentCollection),
                mergeMap((params: { paymentDataPayload: InsertToPaymentForTotalCostAndSalesMutationVariables }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._commonService.insertToPaymentTableForAnOrder(params.paymentDataPayload).pipe(
                        map((result: MutationResult<InsertToPaymentForTotalCostAndSalesMutation>) => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            this._toast.success('Payment entries successful');
                            return result;
                        }),
                        catchError((error) => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            this._toast.error('Failed to create payment entries for order');
                            return throwError(() => {
                                return new Error('');
                            });
                        })
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    insertInCostTable$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(ActionTypes.insertInCostTable),
                mergeMap((params: { param: InsertInCostTableMutationVariables }) => {
                    return this._commonService.insertInCostTable(params.param);
                }),
                catchError((error) => {
                    this._toast.error('Failed insert in cost table');
                    return throwError(() => {
                        return new Error('');
                    });
                })
            ),
        { dispatch: false }
    );

    getDeletingUserSuperiors$ = createEffect(() =>
      this._action$.pipe(
          ofType(ActionTypes.getDeletingUserSuperiors),
          mergeMap((params: { params: GetSuperiorIdOfUserQueryVariables }) => {
              return this._commonService.getSuperiorIdOfUser(params?.params).pipe(
                  map((result: ApolloQueryResult<GetSuperiorIdOfUserQuery>) => {
                      const deletingUserSuperiorId = this._commonService.transformSuperiorIdOfUser(
                          result?.data,
                          params?.params
                      );
                      return getDeletingUserSuperiorSuccess(deletingUserSuperiorId);
                  }),
                  catchError(() => {
                      return throwError(() => {
                          return new Error('An error occurred while fetching the role hierarchy.');
                      });
                  })
              );
          })
      )
  );
    /*
     * Update buyer role id to payment table when a regular_clinic / selective_distributor is upgraded
     */
    updateBuyerRoleIdToPaymentTable$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.updateBuyerRoleIdToPaymentId),
            mergeMap((upgradedUserIdAndRoleIdParams: { upgradedUserIdAndRoleIdParams: UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonService.updateBuyerRoleIdsToUpgradedUser(upgradedUserIdAndRoleIdParams.upgradedUserIdAndRoleIdParams).pipe(
                    map((result: MutationResult<UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutation>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return updateBuyerRoleIdToPaymentIdSuccess();
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /*
     * Update seller role id to payment table when a regular_clinic / selective_distributor is upgraded
     */
    updateSellerRoleIdToPaymentTable$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionTypes.updateSellerRoleIdToPaymentId),
            mergeMap((upgradedUserIdAndRoleIdParams: { upgradedUserIdAndRoleIdParams: UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonService.updateSellerRoleIdsToUpgradedUser(upgradedUserIdAndRoleIdParams.upgradedUserIdAndRoleIdParams).pipe(
                    map((result: MutationResult<UpdateSellerRoleIdToPaymentTableForUpgradedUserMutation>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return updateSellerRoleIdToPaymentIdSuccess();
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    updateUserRoleDetailsJSON$ = createEffect(() =>
      this._action$.pipe(
        ofType(ActionTypes.updateUserRoleDetailsJSON),
        switchMap((params: {params: UpdateUserRoleDetailsMutationVariables}) => {
          return this._commonService.updateUserRoleDetails(params?.params)
        })
      ), {dispatch: false}
    )
}
