import { Manufacturer_Status_Enum, Order_Status_Enum } from "@app/generated/graphql";
import { ManufacturerStatusHierarchy, OrderStatusHierarchy } from "@app/shared/models/shared.model";

export enum Url {
    USER = '/user',
    DASHBOARD = '/user/dashboard',
    AUTH = 'auth',
    LOGIN = '/auth/login',
    OTP = '/auth/otp',
    RESET_PASSWORD = '/auth/reset-password',
    ROLE_SELECTION = '/auth/select-role',
    OTP_SERVICE = '/auth/otp-service',
    TERMS_AND_CONDITIONS = '/auth/terms-and-conditions',
    CLINIC_SELECTION = '/auth/select-clinic',
    DESIGNER_LISTING = '/user/designers',
    DESIGNER = '/user/designers/designer',
    EXCLUSIVE_DIST_LISTING = 'user/exclusive-distributors',
    SELECTIVE_DIST_LISTING = 'user/selective-distributors',
    ADD_SELECTIVE_DISTRIBUTOR = 'user/selective-distributors/add-selective-distributor',
    ADD_EXCLUSIVE_DISTRIBUTOR = 'user/exclusive-distributors/add-exclusive-distributor',
    PATIENTS_LISTING = '/user/patients',
    ADD_PATIENT = '/user/patients/add-patients',
    ADD_DOCTOR = '/user/doctors/add-doctors',
    REVIEW_D1 = '/user/patients/treatment-form/review-d1',
    QUESTIONS_FORM = '/user/patients/treatment-form',
    DOCTORS_LISTING = '/user/doctors',
    REGULAR_CLINIC_LISTING = '/user/regular-clinics',
    ADD_FRANCHISE_CLINIC = 'user/franchise-clinics/add-franchise-clinic',
    ADD_REGULAR_CLINIC = 'user/regular-clinics/add-regular-clinic',
    FRANCHISE_CLINIC_LISTING = "user/franchise-clinics",
    ORDER_LIST="user/orders",
    ORDER_DETAILS = "user/orders/details",
    PATIENTS_TRANSFER_REQUEST_LIST = "user/patients/patient-transfer-requests",
    ADD_CONTRACT_MANUFACTURER = 'user/contracted-manufacturers/add-contracted-manufacturers',
    CONTRACT_MANUFACTURER_LISTING = "user/contracted-manufacturers",
		ADD_DESIGNER = "user/designers/add-designers",
    PATIENT_PROFILE = "user/patient-profile",
    TREATMENT_REVIEW = "/user/treatment-review",
    EYE_INFO = "/user/eye-info",
    SIBLING_SELECTOR = "/auth/select-family-member"
}

export enum RootUrl {
  HOME = "/user/dashboard",
  EXCLUSIVE_DISTRIBUTORS = "/user/exclusive-distributors",
  SELECTIVE_DISTRIBUTORS = "/user/selective-distributors",
  CONTRACT_MANUFACTURERS = "/user/contracted-manufacturers",
  DESIGNERS = "/user/designers",
  FRANCHISE_CLINICS = "/user/franchise-clinics",
  REGULAR_CLINICS = "/user/regular-clinics",
  PATIENTS = "/user/patients",
  DOCTORS = "/user/doctors",
  ORDERS = "/user/orders",
  LENSES = "/user/lenses",
  CONTROL_PANEL = "/user/control-panel",
  PATIENT_PROFILE = "/user/patient-profile",
  TREATMENT_REVIEW = "/user/treatment-review",
  EYE_INFO = "/user/eye-info"
}

export enum Roles {
    SUPER_ADMIN = 'super_admin',
    DESIGNER = 'designer',
    DISTRIBUTOR = 'exclusive_distributor',
    DISTRIBUTOR_PLACEHOLDER = 'distributor',
    MANUFACTURER = 'manufacturer',
    CONTRACT_MANUFACTURER = 'contract_manufacturer',
    CLINIC = 'clinic',
    REGULAR_CLINIC = 'regular_clinic',
    FRANCHISE_CLINIC = 'franchise_clinic',
    DOCTOR = 'doctor',
    PATIENT = 'patient',
    EXCLUSIVE_DISTRIBUTOR = 'exclusive_distributor',
    SELECTIVE_DISTRIBUTOR = 'selective_distributor',
    CARE_TAKER = 'care_taker',
    EMPTY = ''
}

export enum DisplayNamesForRoles {
  super_admin = 'super admin',
  designer = 'designer',
  contract_manufacturer = 'contract manufacturer',
  regular_clinic = 'partner',
  franchise_clinic = 'gold partner',
  doctor = 'practitioner',
  patient = 'patient',
  exclusive_distributor = 'exclusive distributor',
  selective_distributor = 'selective distributor',
  care_taker = 'family member'
}

export enum NewRoleNames {
    PARTNER = 'partner',
    GOLD_PARTNER = 'gold_partner',
    PRACTITIONER = 'practitioner',
    EMPTY = ''
}

export enum Common {
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view',
  CREATE = 'create',
  ACCEPT = 'accept',
  REJECT = 'reject',
  LAB_FORM = 'lab_form'
}

export enum TreatmentHistory {
  FACD = 'facd',
  SAT = 'sat',
  MAT = 'mat',
  EMPTY = '',
}

export enum ReviewSummaryHeading {
  D1 = 'D1 Form',
  A2 = 'A2 Form',
  A3 = 'A3 Form',
  EMPTY = ''
}

export enum TreatmentFormNames {
  FACD = 'FACD Form',
  SAT = 'SAT Form',
  MAT = 'MAT Form',
  EMPTY = ''
}

export enum ActionButtonType {
  AcceptOrReject = 'accept-or-reject',
  DeleteAndAssignUser = 'delete-and-assign-user', // Used on user delete and assign users to another user.
  DeleteUser = 'delete-user',
  OpenUserDeleteOptionModal = 'open-user-delete-modal',
  OpenUserDeleteAndAssignModal = 'open-user-delete-and-assign-modal',
  OpenUserDeleteConfirmMessageModal = 'open-user-delete-confirm-message-modal'
}

export enum DeleteUserAction {
  Delete = 'deletion',
  DeleteAndAssign = 'reassign'
}

export enum LensType {
  TORIC_LENS = 'TORIC LENS',
  SPHERICAL_LENS = 'SPHERICAL LENS'
}

export enum DesignType {
  TORIC = 'toric',
  SPHERICAL = 'spherical'
}

export enum FilterTypesEnum {
  ORDER_TYPE = 'orderType',
  ORDER_STATUS = 'orderStatus',
  ORDER_STATUS_SNAKE_CASE = 'order_status',
  MANUFACTURER_STATUS = 'manufacturerStatus',
  MANUFACTURER_STATUS_SNAKE_CASE = 'manufacturer_status',
}

export enum OrderStatusNameInUi {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Ordered = 'Ordered',
  OutForDelivery = 'Out For Delivery',
  Shipped = 'Shipped',
  Submitted = 'Submitted',
  DraftSaved = 'Draft Saved',
  Received = 'Recieved',
  Manufactured = 'Manufactured',
  Delayed = 'Delayed',
  ShippedToCountry = 'Shipped to Country'
}

export const orderStatusHierarchyArray: OrderStatusHierarchy[] = [
  {status: Order_Status_Enum.Delivered, hierarchy: 6},
  {status: Order_Status_Enum.Cancelled, hierarchy: 5},
  {status: Order_Status_Enum.OutForDelivery, hierarchy: 4},
  {status: Order_Status_Enum.Shipped, hierarchy: 3},
  {status: Order_Status_Enum.Ordered, hierarchy: 2},
  {status: Order_Status_Enum.Submitted, hierarchy: 1},
  {status: Order_Status_Enum.DraftSaved, hierarchy: 0},
];

export const manufacturerStatusHierarchyArray: ManufacturerStatusHierarchy[] = [
  {status: Manufacturer_Status_Enum.Shipped, hierarchy: 3},
  {status: Manufacturer_Status_Enum.Manufactured, hierarchy: 2},
  {status: Manufacturer_Status_Enum.Delayed, hierarchy: 1},
  {status: Manufacturer_Status_Enum.Recieved, hierarchy: 0},
];

export enum Period {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  CUSTOM = 'custom',
}

export enum EyeSideEnum {
  LEFT = 'Left',
  RIGHT = 'Right'
}
