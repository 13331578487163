<div>
    <div class="onboarding-form row justify-content-center">
        <div class="col-4 d-flex icons">
            <img width="50" height="50" class="icon_img" src="/assets/icons/common/people.svg" />
            <span>Patients</span>
            <span><Strong class="count">1564</Strong></span>
        </div>
        <div class="col-4 d-flex icons">
            <img width="50" height="50" class="icon_img" src="/assets/icons/common/file.svg" />
            <span>Orders</span>
            <span><Strong class="count">1230</Strong></span>
        </div>
    </div>
     <!-- New Patients List -->
     <div class="onboarding-form row">
        <div class="d-flex align-items-center justify-content-between info">
            <div class="heading d-flex align-items-center">
            <span class="contract_icon  d-block">
                <img class="icon_img" width="20" height="20" src="/assets/icons/common/people.svg" />
            </span>
            <span class="contract_manufacture ms-1">New Patients List</span>
        </div>
            <div class="details-info">
                <img class="icon_img" style="margin: 2px" src="/assets/icons/common/add_1.svg" />
                <img class="icon_img" src="/assets/icons/common/list_square.svg" />
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let heading of headings">{{ heading }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of data">
                    <td scope="row">{{ data.name }}</td>
                    <td>{{ data.referenceNumber }}</td>
                    <td>{{ data.contactNumber }}</td>
                  </tr>
            </tbody>
        </table>
    </div>
    <!-- Existing Patients List -->
    <div class="onboarding-form row">
        <div class="d-flex align-items-center justify-content-between info">
            <div class="heading d-flex align-items-center">
            <span class="contract_icon  d-block">
                <img class="icon_img" width="20" height="20" src="/assets/icons/common/people.svg" />
            </span>
            <span class="contract_manufacture ms-1">Existing Patients List</span>
        </div>
            <div class="details-info">
                <img class="icon_img" src="/assets/icons/common/list_square.svg" />
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let heading of existingPatientsHeading">{{ heading }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of existingPatientsData">
                    <td scope="row">{{ data.name }}</td>
                    <td>{{ data.referenceNumber }}</td>
                    <td>{{ data.contactNumber }}</td>
                  </tr>
            </tbody>
        </table>
    </div>
    <!-- Order -->
    <div class="onboarding-form row">
        <div class="d-flex align-items-center justify-content-between info">
            <div class="heading d-flex align-items-center">
            <span class="contract_icon  d-block">
                <img class="icon_img" width="20" height="20" src="/assets/icons/common/file.svg" />
            </span>
            <span class="contract_manufacture ms-1">Orders</span>
        </div>
            <div class="details-info">
                <img class="icon_img" src="/assets/icons/common/list_square.svg" />
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let heading of orderHeading">{{ heading }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of orderData">
                    <td scope="row">{{ data.orderId }}</td>
                    <td>{{ data.patientName }}</td>
                    <td>{{ data.Date }}</td>
                    <td>{{ data.Status }}</td>
                  </tr>
            </tbody>
        </table>
    </div>
</div>
