<div class="col">
    <ul class="nav nav-tabs flex-column flex-sm-row" id="myTabs">
        <li class="nav-item flex-fill text-center" *ngIf="isFacdVisible">
            <a class="nav-link active kl-text6-type-6" id="tab1" data-bs-toggle="tab" href="#content1">FACD</a>
        </li>
        <li class="nav-item flex-fill text-center" *ngIf="isSatVisible">
            <a class="nav-link kl-text6-type-6" id="tab2" data-bs-toggle="tab" href="#content2">SAT</a>
        </li>
        <li class="nav-item flex-fill text-center" *ngIf="isMatVisible">
            <a class="nav-link kl-text6-type-6" id="tab3" data-bs-toggle="tab" href="#content3">MAT</a>
        </li>
    </ul>
</div>
<div class="tab-content mt-3">
    <div 
        class="tab-pane fade show active" 
        id="content1" 
        *ngIf="isFacdVisible"
    >
        <div class="container-fluid">
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div class="patient-info-container">
                        <app-patient-info
                            [patientInfo$]="patientInfoDetailsToPassFacd$"
                        ></app-patient-info>
                    </div>
                </div>
            </div>
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div 
                        class="d1-form-summary" 
                    >
                        <app-facd-summary-details
                            [facdSummaryData]="facdData"
                        ></app-facd-summary-details>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-0">
                    <div 
                        class="d1-form-lens-details"
                    >
                        <app-lens-info
                            [hideButtons]="hideButtonsForFacdSubmit"
                            [lensDetails]="lensDetails"
                        ></app-lens-info>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div 
        class="tab-pane fade" 
        id="content2"
        *ngIf="isSatVisible"
    >
        <div class="container-fluid">
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div class="patient-info-container">
                        <app-patient-info
                            [patientInfo$]="patientInfoDetailsToPassSat$"
                        ></app-patient-info>
                    </div>
                </div>
            </div>
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div 
                        class="amend-summary" 
                    >
                        <app-amend-summary-details
                            [amendData]="satDataFilledByDoctor"
                            [summaryFormName]="summaryFormName"
                        ></app-amend-summary-details>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div 
        class="tab-pane fade" 
        id="content3"
        *ngIf="isMatVisible"
    >
        <div class="container-fluid">
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div class="patient-info-container">
                        <app-patient-info
                            [patientInfo$]="patientInfoDetailsToPassMat$"
                        ></app-patient-info>
                    </div>
                </div>
            </div>
            <div class="row patient-info-row">
                <div class="col-12 p-0">
                    <div 
                        class="amend-summary" 
                    >
                        <app-amend-summary-details
                            [amendData]="matDataFilledByDoctor"
                            [summaryFormName]="summaryFormName"
                        ></app-amend-summary-details>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
