import { createAction } from '@ngrx/store';

import { filterValues } from '@app/core/util/initial-values';
import { LoginResponseData } from '@app/auth/models/auth.model';
import {
    ActionTypes,
    ClinicForUserDetailsPage,
    ContractManufacturerForUserDetailsPage,
    DistributorForUserDetailsPage,
    DoctorForUserDetailsPage,
    FilterParams,
    OrderForUserDetailsPage,
    PatientForUserDetailsPage,
    RoleHierarchyForLoggedInUser,
} from '../models/shared.model';
import {
    GetRoleSpecificUsersQueryVariables,
    DeleteUserOrDeleteUserAndReassignMutationVariables,
    GetDetailsOfOrderByIdQuery,
    GetFormDataFilledByDoctorForDesignViewQueryVariables,
    GetFormDataFilledByDoctorForDesignViewQuery,
    GetFranchiseClinicsForUserDetailsPageQueryVariables,
    GetRegularClinicsForUserDetailsPageQueryVariables,
    GetDistributorsForUserDetailsPageQueryVariables,
    GetDoctorsForUserDetailsPageQueryVariables,
    GetPatientsForUserDetailsPageQueryVariables,
    GetOrdersForUserDetailsPageQueryVariables,
    GetContractManufacturersForUserDetailsPageQueryVariables,
    GetTotalCountOfClinicForUserDetailsPageQueryVariables,
    GetTotalCountOfContractManufacturerForUserDetailsPageQueryVariables,
    GetTotalCountOfDoctorForUserDetailsPageQueryVariables,
    GetTotalCountOfOrdersForUserDetailsPageQueryVariables,
    GetTotalCountOfPatientForUserDetailsPageQueryVariables,
    GetTotalCountOfDistributorsForUserDetailsPageQueryVariables,
    UpdateOrderStatusMutationVariables,
    UpdateOrderStatusMutation,
    GetControlPanelValuesByOrderIdQuery,
    GetControlPanelValuesByOrderIdQueryVariables,
    GetDistributorByDoctorAndClinicIdQueryVariables,
    GetUserFormData1QueryVariables,
    GetUserFormData1Query,
    GetOrdersListBasedOnDistributorIdQueryVariables,
    GetOrdersListBasedOnDistributorIdQuery,
    GetOrdersCountBasedOnDistributorIdQueryVariables,
    GetOrdersCountBasedOnDistributorIdQuery,
    GetUserFormDataQueryVariables,
    BulkUpdateOrderStatusMutationVariables,
    InsertOrderStatusTrackingDataMutationVariables,
    GetOrderDetailsByIdQueryVariables,
    GetOrderDetailsByIdQuery,
    GetRoleHierarchyForLoggedInUserQueryVariables,
    InsertToPaymentForTotalCostAndSalesMutationVariables,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables,
    InsertInCostTableMutationVariables,
    GetSuperiorIdOfUserQueryVariables,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateUserRoleDetailsMutationVariables,
} from '@app/generated/graphql';
import { UsersAndChargesForPaymentBaseData } from '../models/user-price-settings.model';

export const roleSelectionSuccess = createAction(
    ActionTypes.roleSelectionSuccess,
    (commonDetailsAfterLogin: LoginResponseData) => ({ commonDetailsAfterLogin })
);
export const roleSelectionFailure = createAction(
    ActionTypes.roleSelectionFailure,
    (commonDetailsAfterLogin: LoginResponseData) => ({ commonDetailsAfterLogin })
);
export const clearCommonDetailsAfterLogin = createAction(ActionTypes.clearCommonDetailsAfterLogin);
export const clearCommonDetailsSuccess = createAction(ActionTypes.clearCommonDetailsSuccess);
export const getRoleSpecificUsers = createAction(
    ActionTypes.getRoleSpecificUsers,
    (roleObj: GetRoleSpecificUsersQueryVariables) => ({ roleObj })
);
export const getRoleSpecificUsersSuccess = createAction(
    ActionTypes.getRoleSpecificUsersSuccess,
    (roleSpecificUsers: { name: string; id: string, email?: string }[]) => ({ roleSpecificUsers })
);
export const getDetailsOfOrderById = createAction(ActionTypes.getDetailsOfOrderById, (orderId: string) => ({
    orderId,
}));
export const getDetailsOfOrderByIdSuccess = createAction(
    ActionTypes.getDetailsOfOrderByIdSuccess,
    (orderDetails: GetDetailsOfOrderByIdQuery) => ({ orderDetails })
);
export const getFormDataFilledByDoctor = createAction(
    ActionTypes.getFormDataFilledByDoctor,
    (orderIds: GetFormDataFilledByDoctorForDesignViewQueryVariables) => ({ orderIds })
);
export const getFormDataFilledByDoctorSuccess = createAction(
    ActionTypes.getFormDataFilledByDoctorSuccess,
    (formDataFilled: GetFormDataFilledByDoctorForDesignViewQuery) => ({ formDataFilled })
);

export const deleteUserOrDeleteUserAndReassign = createAction(
    ActionTypes.deleteUserOrDeleteUserAndReassign,
    (params: DeleteUserOrDeleteUserAndReassignMutationVariables) => ({ params })
);

export const refreshUserListAfterUserDelete = createAction(
    ActionTypes.refreshUserListAfterUserDelete,
    (role: string) => ({ role })
);

export const saveUserListParams = createAction(ActionTypes.saveUserListParams, (userListParams: any) => ({
    userListParams,
}));

export const getFranchiseClinicsForUserDetailsPage = createAction(
    ActionTypes.getFranchiseClinicsForUserDetailsPage,
    (data: GetFranchiseClinicsForUserDetailsPageQueryVariables) => ({ data })
);
export const getFranchiseClinicsForUserDetailsPageSuccess = createAction(
    ActionTypes.getFranchiseClinicsForUserDetailsPageSuccess,
    (franchiseClinicsForUserDetailsPage: ClinicForUserDetailsPage[]) => ({ franchiseClinicsForUserDetailsPage })
);
export const getRegularClinicsForUserDetailsPage = createAction(
    ActionTypes.getRegularClinicsForUserDetailsPage,
    (data: GetRegularClinicsForUserDetailsPageQueryVariables) => ({ data })
);
export const getRegularClinicsForUserDetailsPageSuccess = createAction(
    ActionTypes.getRegularClinicsForUserDetailsPageSuccess,
    (regularClinicsForUserDetailsPage: ClinicForUserDetailsPage[]) => ({ regularClinicsForUserDetailsPage })
);
export const getDistributorsForUserDetailsPage = createAction(
    ActionTypes.getDistributorsForUserDetailsPage,
    (data: GetDistributorsForUserDetailsPageQueryVariables) => ({ data })
);
export const getDistributorsForUserDetailsPageSuccess = createAction(
    ActionTypes.getDistributorsForUserDetailsPageSuccess,
    (distributorsForUserDetailsPage: DistributorForUserDetailsPage[]) => ({ distributorsForUserDetailsPage })
);
export const getContractManufacturersForUserDetailsPage = createAction(
    ActionTypes.getContractManufacturersForUserDetailsPage,
    (data: GetContractManufacturersForUserDetailsPageQueryVariables) => ({ data })
);
export const getContractManufacturersForUserDetailsPageSuccess = createAction(
    ActionTypes.getContractManufacturersForUserDetailsPageSuccess,
    (contractManufacturerForUserDetailsPage: ContractManufacturerForUserDetailsPage[]) => ({
        contractManufacturerForUserDetailsPage,
    })
);
export const getDoctorsForUserDetailsPage = createAction(
    ActionTypes.getDoctorsForUserDetailsPage,
    (data: GetDoctorsForUserDetailsPageQueryVariables) => ({ data })
);
export const getDoctorsForUserDetailsPageSuccess = createAction(
    ActionTypes.getDoctorsForUserDetailsPageSuccess,
    (doctorsForUserDetailsPage: DoctorForUserDetailsPage[]) => ({ doctorsForUserDetailsPage })
);
export const getPatientsForUserDetailsPage = createAction(
    ActionTypes.getPatientsForUserDetailsPage,
    (data: GetPatientsForUserDetailsPageQueryVariables) => ({ data })
);
export const getPatientsForUserDetailsPageSuccess = createAction(
    ActionTypes.getPatientsForUserDetailsPageSuccess,
    (patientsForUserDetailsPage: PatientForUserDetailsPage[]) => ({ patientsForUserDetailsPage })
);
export const getOrdersForUserDetailsPage = createAction(
    ActionTypes.getOrdersForUserDetailsPage,
    (data: GetOrdersForUserDetailsPageQueryVariables) => ({ data })
);
export const getOrdersForUserDetailsPageSuccess = createAction(
    ActionTypes.getOrdersForUserDetailsPageSuccess,
    (ordersForUserDetailsPage: OrderForUserDetailsPage[]) => ({ ordersForUserDetailsPage })
);

export const getTotalCountOfDistributorForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfDistributorForUserDetailsPage,
    (data: GetTotalCountOfDistributorsForUserDetailsPageQueryVariables) => ({ data })
);

export const getTotalCountOfDistributorForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfDistributorForUserDetailsPageSuccess,
    (totalCountOfDistributorForUserDetailsPage: number) => ({ totalCountOfDistributorForUserDetailsPage })
);

export const getTotalCountOfClinicForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfClinicForUserDetailsPage,
    (data: GetTotalCountOfClinicForUserDetailsPageQueryVariables) => ({ data })
);
export const getTotalCountOfClinicForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfClinicForUserDetailsPageSuccess,
    (totalCountOfClinicForUserDetailsPage: number) => ({ totalCountOfClinicForUserDetailsPage })
);
export const getTotalCountOfContractManufacturerForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfContractManufacturerForUserDetailsPage,
    (data: GetTotalCountOfContractManufacturerForUserDetailsPageQueryVariables) => ({ data })
);
export const getTotalCountOfContractManufacturerForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfContractManufacturerForUserDetailsPageSuccess,
    (totalCountOfContractManufacturerForUserDetailsPage: number) => ({
        totalCountOfContractManufacturerForUserDetailsPage,
    })
);
export const getTotalCountOfOrdersForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfOrdersForUserDetailsPage,
    (data: GetTotalCountOfOrdersForUserDetailsPageQueryVariables) => ({ data })
);
export const getTotalCountOfOrdersForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfOrdersForUserDetailsPageSuccess,
    (totalCountOfOrdersForUserDetailsPage: number) => ({ totalCountOfOrdersForUserDetailsPage })
);
export const getTotalCountOfDoctorForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfDoctorForUserDetailsPage,
    (data: GetTotalCountOfDoctorForUserDetailsPageQueryVariables) => ({ data })
);
export const getTotalCountOfDoctorForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfDoctorForUserDetailsPageSuccess,
    (totalCountOfDoctorForUserDetailsPage: number) => ({ totalCountOfDoctorForUserDetailsPage })
);
export const getTotalCountOfPatientForUserDetailsPage = createAction(
    ActionTypes.getTotalCountOfPatientForUserDetailsPage,
    (data: GetTotalCountOfPatientForUserDetailsPageQueryVariables) => ({ data })
);
export const getTotalCountOfPatientForUserDetailsPageSuccess = createAction(
    ActionTypes.getTotalCountOfPatientForUserDetailsPageSuccess,
    (totalCountOfPatientForUserDetailsPage: number) => ({ totalCountOfPatientForUserDetailsPage })
);
export const updateOrderDetails = createAction(
    ActionTypes.updateOrderStatus,
    (orderDetailsParams: UpdateOrderStatusMutationVariables) => ({ orderDetailsParams })
);
export const updateOrderDetailsSuccess = createAction(
    ActionTypes.updateOrderStatusSuccess,
    (orderDetails: UpdateOrderStatusMutation) => ({ orderDetails })
);
export const getControlPanelDetailsByOrderIdSuccess = createAction(
    ActionTypes.getControlPanelDetailsByOrderIdSuccess,
    (controlPanelDetails: GetControlPanelValuesByOrderIdQuery) => ({
        controlPanelDetails,
    })
);

export const getControlPanelDetailsByOrderId = createAction(
    ActionTypes.getControlPanelDetailsByOrderId,
    (id: GetControlPanelValuesByOrderIdQueryVariables) => ({
        id,
    })
);

export const getDistributorByDoctorAndClinicId = createAction(
    ActionTypes.getDistributorByDoctorAndClinicId,
    (getDistributorIdParams: GetDistributorByDoctorAndClinicIdQueryVariables) => ({ getDistributorIdParams })
);

export const getFilterByValues = createAction(
    ActionTypes.getFilterByValues,
    (filterByParams: keyof typeof filterValues) => ({ filterByParams })
);

export const getFilterByValuesSuccess = createAction(ActionTypes.getFilterByValuesSuccess, (filterBy: string[]) => ({
    filterBy,
}));

export const saveAppliedFilters = createAction(
    ActionTypes.saveAppliedFilters,
    (appliedFilters: FilterParams | null) => ({ appliedFilters })
);

export const getPatientFormData = createAction(
    ActionTypes.getPatientFormData,
    (userId: GetUserFormData1QueryVariables) => ({ userId })
);

export const getPatientFormDataSuccess = createAction(
    ActionTypes.getPatientFormDataSuccess,
    (patientDetails: GetUserFormData1Query | null) => ({ patientDetails })
);

// Order summary list action - contract manufacturer
export const getOrdersForManufacturer = createAction(
    ActionTypes.getOrdersForManufacturer,
    (orderSummaryListParams: GetOrdersListBasedOnDistributorIdQueryVariables) => ({ orderSummaryListParams })
);
export const getOrdersForManufacturerSuccess = createAction(
    ActionTypes.getOrdersForManufacturerSuccess,
    (orderSummaryList: GetOrdersListBasedOnDistributorIdQuery) => ({ orderSummaryList })
);

// fetching count of orders - contract manufacturer
export const getTotalOrderCountForManufacturer = createAction(
    ActionTypes.getTotalOrderCountForManufacturer,
    (orderSummaryListCountParams: GetOrdersCountBasedOnDistributorIdQueryVariables) => ({ orderSummaryListCountParams })
);
export const getTotalOrderCountForManufacturerSuccess = createAction(
    ActionTypes.getTotalOrderCountForManufacturerSuccess,
    (ordersCountData: GetOrdersCountBasedOnDistributorIdQuery) => ({ ordersCountData })
);

// Fetch distributor data for displaying manufacturer login
export const getDistributorDataForManufacturer = createAction(
    ActionTypes.getDistributorDataForManufacturer,
    (userFormDataParams: GetUserFormDataQueryVariables) => ({ userFormDataParams })
);
export const getDistributorDataForManufacturerSuccess = createAction(
    ActionTypes.getDistributorDataForManufacturerSuccess,
    (userFormData: GetUserFormData1Query) => ({ userFormData })
);
export const bulkUpdateOrderStatus = createAction(
    ActionTypes.bulkUpdateOrderStatus,
    (bulkUpdateOrderStatusParams: BulkUpdateOrderStatusMutationVariables) => ({ bulkUpdateOrderStatusParams })
);

// Add order details to order status order status info table
export const InsertOrderStatusToTrackingData = createAction(
    ActionTypes.insertOrderStatusTrackingData,
    (orderTrackingData: InsertOrderStatusTrackingDataMutationVariables) => ({ orderTrackingData })
);

// fetching order status and date details based on order id from order status info table
export const getOrderDetailsByOrderId = createAction(
    ActionTypes.getOrderDetailsByOrderId,
    (orderIdParams: GetOrderDetailsByIdQueryVariables) => ({ orderIdParams })
);

export const getOrderDetailsByOrderIdSuccess = createAction(
    ActionTypes.getOrderDetailsByOrderIdSuccess,
    (orderDetails: GetOrderDetailsByIdQuery) => ({ orderDetails })
);

export const getRoleHierarchyForLoggedInUser = createAction(
    ActionTypes.getRoleHierarchyForLoggedInUser,
    (loggedInUserDetails: GetRoleHierarchyForLoggedInUserQueryVariables) => ({ loggedInUserDetails })
);
export const getRoleHierarchyForLoggedInUserSuccess = createAction(
    ActionTypes.getRoleHierarchyForLoggedInUserSuccess,
    (roleHierarchyForLoggedInUser: RoleHierarchyForLoggedInUser) => ({ roleHierarchyForLoggedInUser })
);
export const resetUserDetailsPageExtraDetails = createAction(ActionTypes.resetUserDetailsPageExtraDetails);

// action to fetch user id's and price settings data for roles above doctor
export const getUserIdAndPriceDataForPayment = createAction(
    ActionTypes.getUserIdAndPriceDataForPayment,
    (userData: GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables) => ({ userData })
);
// action to fetch user id's and price settings data for roles above doctor
export const getUserIdAndPriceDataForPaymentSuccess = createAction(
    ActionTypes.getUserIdAndPriceDataForPaymentSuccess,
    (userIdAndPriceDataForPayment: UsersAndChargesForPaymentBaseData) => ({ userIdAndPriceDataForPayment })
);
export const insertToPaymentCollection = createAction(
    ActionTypes.insertToPaymentCollection,
    (paymentDataPayload: InsertToPaymentForTotalCostAndSalesMutationVariables) => ({ paymentDataPayload })
);
export const insertInCostTable = createAction(
  ActionTypes.insertInCostTable,
  (param: InsertInCostTableMutationVariables) => ({ param })
);

export const getDeletingUserSuperiors = createAction(
  ActionTypes.getDeletingUserSuperiors,
  (params: GetSuperiorIdOfUserQueryVariables) => ({ params })
);

export const getDeletingUserSuperiorSuccess = createAction(
  ActionTypes.getDeletingUserSuperiorSuccess,
  (deletingUserSuperiorId: string) => ({ deletingUserSuperiorId })
);
// action to update role id's to payment table when a regular_clinic / selective_distributor is upgraded
export const updateBuyerRoleIdToPaymentId = createAction(
    ActionTypes.updateBuyerRoleIdToPaymentId,
    (upgradedUserIdAndRoleIdParams: UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables) => ({ upgradedUserIdAndRoleIdParams })
);

export const updateBuyerRoleIdToPaymentIdSuccess = createAction(
    ActionTypes.updateBuyerRoleIdToPaymentIdSuccess,
);

export const updateSellerRoleIdToPaymentId = createAction(
    ActionTypes.updateSellerRoleIdToPaymentId,
    (upgradedUserIdAndRoleIdParams: UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables) => ({ upgradedUserIdAndRoleIdParams })
);

export const updateSellerRoleIdToPaymentIdSuccess = createAction(
    ActionTypes.updateBuyerRoleIdToPaymentIdSuccess
);

export const resetPatientDetails = createAction(
  ActionTypes.resetPatientDetails
);

export const updateUserRoleDetailsJSON = createAction(
  ActionTypes.updateUserRoleDetailsJSON,
  (params: UpdateUserRoleDetailsMutationVariables) => ({ params })
);
