import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import { CredentialsService } from './credentials.service';
import {
    ClinicSelectAndUpdateGQL,
    ClinicSelectAndUpdateMutation,
    ClinicSelectAndUpdateMutationVariables,
    ForgotPasswordGQL,
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables,
    GetClinicsByDesignerIdGQL,
    GetClinicsByDesignerIdQuery,
    GetClinicsByDesignerIdQueryVariables,
    GetFamilyMembersUnderFranchiseClinicGQL,
    GetFamilyMembersUnderFranchiseClinicQuery,
    GetFamilyMembersUnderFranchiseClinicQueryVariables,
    GetListOfClinicsForDoctorGQL,
    GetListOfClinicsForDoctorQuery,
    GetListOfClinicsForDoctorQueryVariables,
    GetRoleHierarchyForLoggedInUserQueryVariables,
    GetRolesBasedOnUserIdGQL,
    GetRolesBasedOnUserIdQuery,
    GetRolesBasedOnUserIdQueryVariables,
    GetSiblingListGQL,
    GetSiblingListQuery,
    GetSiblingListQueryVariables,
    GetUserAndUserRoleMappingByEmailGQL,
    GetUserAndUserRoleMappingByEmailQuery,
    GetUserAndUserRoleMappingByEmailQueryVariables,
    GetUserDetailsGQL,
    GetUserDetailsQuery,
    GetUserDetailsQueryVariables,
    GetUserDetailsToCheckAgainstLocalStorageDetailsGQL,
    GetUserDetailsToCheckAgainstLocalStorageDetailsQuery,
    GetUserDetailsToCheckAgainstLocalStorageDetailsQueryVariables,
    GetUserProfileGQL,
    GetUserProfileQuery,
    GetUserProfileQueryVariables,
    OnLoginGQL,
    OnLoginMutation,
    OnLoginMutationVariables,
    OnSendOtpBothServiceGQL,
    OnSendOtpBothServiceMutation,
    OnSendOtpBothServiceMutationVariables,
    ResetPasswordWithNewGQL,
    ResetPasswordWithNewMutation,
    ResetPasswordWithNewMutationVariables,
    RoleSelectAndUpdateGQL,
    RoleSelectAndUpdateMutation,
    RoleSelectAndUpdateMutationVariables,
    UpdateNewUserDetailsGQL,
    UpdateNewUserDetailsMutation,
    UpdateNewUserDetailsMutationVariables,
    VerifyOtpGQL,
    VerifyOtpMutation,
    VerifyOtpMutationVariables,
} from '@app/generated/graphql';
import { Roles, Url } from '../util/common';
import {
    clearAuthRelatedInitialData,
    getClinicsByDesignerIdSuccess,
} from '@app/auth/actions/auth.action';
import { clearCommonDetailsAfterLogin, getRoleHierarchyForLoggedInUser } from '@app/shared/actions/shared.action';
import { getContractManufacturerListSuccess } from '@app/patients/actions/patients.action';
import { User } from '@app/shared/models/shared.model';
import { clearBreadCrumpUrlCollection } from '@app/ui/actions/ui.actions';
import { UserDetailsToCheckAgainstLocalStorage } from '@app/auth/models/auth.model';
import { Patient } from '@app/auth/models/auth.model';
import { resetAppState } from '@app/actions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    onLoginSubject$ = new BehaviorSubject<boolean>(false);
    constructor(
        private _store: Store,
        private _router: Router,
        private _credentialsService: CredentialsService,
        private _onLoginGQL: OnLoginGQL,
        private _onSendOtpBothServices: OnSendOtpBothServiceGQL,
        private _getUserDetailsGQL: GetUserDetailsGQL,
        private _verifyOtpGQL: VerifyOtpGQL,
        private _getRolesBasedOnUserId: GetRolesBasedOnUserIdGQL,
        private _roleSelectionAndUpdate: RoleSelectAndUpdateGQL,
        private _resetForgotPassword: ForgotPasswordGQL,
        private _changePassword: ResetPasswordWithNewGQL,
        private _getListOfClinicsForDoctorGQL: GetListOfClinicsForDoctorGQL,
        private _clinicSelectAndUpdateGQL: ClinicSelectAndUpdateGQL,
        private _validateDoctorEmail: GetUserAndUserRoleMappingByEmailGQL,
        private _getUserProfileDetailsGQL: GetUserProfileGQL,
        private _updateNewUserDetailsGQL: UpdateNewUserDetailsGQL,
        private _getClinicsByDesignerIdGQL: GetClinicsByDesignerIdGQL,
        private _getUserDetailsToCheckAgainstLocalStorageDetails: GetUserDetailsToCheckAgainstLocalStorageDetailsGQL,
        private _getSiblingListGQL: GetSiblingListGQL,
        private _getFamilyMembersUnderFranchiseClinic: GetFamilyMembersUnderFranchiseClinicGQL
    ) {}

    /**
     * Returns list of queries names that doesnt require token to work
     */
    getListOfQueriesWithNoTokenNeeded = (): String[] => {
        return [
            'getUserAndUserRoleMappingByEmail',
            'updateNewUserDetails',
            'getRolesBasedOnUserId',
            'getListOfClinicsForDoctor',
            'getUserDetails',
            'onLogin',
            'onSendOtpBothService',
            'verifyOtp',
            'roleSelectAndUpdate',
            'forgotPassword',
            'resetPasswordWithNew',
            'clinicSelectAndUpdate',
            'getFamilyMembersUnderFranchiseClinic'
        ];
    };

    /**
     * Authenticates the user
     * @param payload The login parameters
     * @return Observable with user credentials
     */
    login(payload: OnLoginMutationVariables): Observable<MutationResult<OnLoginMutation>> {
        return this._onLoginGQL.mutate(payload);
    }

    /**
     * Send otp to both services
     * @param payload shares userId and method like whether sms or email
     * @returns observable with message.
     */
    sendOtpToBothServices = (
        payload: OnSendOtpBothServiceMutationVariables
    ): Observable<MutationResult<OnSendOtpBothServiceMutation>> => {
        return this._onSendOtpBothServices.mutate(payload);
    };

    /**
     * Get user details form email.
     * @param payload shares user email.
     * @returns user id.
     */
    getUserDetails = (payload: GetUserDetailsQueryVariables): Observable<ApolloQueryResult<GetUserDetailsQuery>> => {
        return this._getUserDetailsGQL.watch(payload).valueChanges;
    };

    /**
     * Logs out the user and clear credentials
     * @return True if the user was logged out successfully
     */
    logout(): Observable<boolean> {
        this.clearAllUserDetailsAndLogOut();
        return of(true);
    }

    /**
     * Reset password.
     * @param password shares new password.
     * @return reset password status message.
     */
    resetPassword = (data: ForgotPasswordMutationVariables): Observable<MutationResult<ForgotPasswordMutation>> => {
        return this._resetForgotPassword.mutate(data);
    };

    /**
     * To get list of user roles based on user details logging in
     * @param token user initial token.
     * @return api response.
     */
    onUserRolesFetch = (
        userId: GetRolesBasedOnUserIdQueryVariables
    ): Observable<ApolloQueryResult<GetRolesBasedOnUserIdQuery>> => {
        return this._getRolesBasedOnUserId.watch(userId).valueChanges;
    };

    /**
     * To update with user selected role, new access token
     * @param role user selected role
     * @return api response.
     */
    onUserRoleSelectionUpdate = (
        roleSelectionPayload: RoleSelectAndUpdateMutationVariables
    ): Observable<MutationResult<RoleSelectAndUpdateMutation>> => {
        return this._roleSelectionAndUpdate.mutate(roleSelectionPayload);
    };

    /**
     * To fetch list of clinics under a doctor while loggin in
     */
    getListOfClinicsAndNameOfDoctor = (
        doctorId: GetListOfClinicsForDoctorQueryVariables
    ): Observable<ApolloQueryResult<GetListOfClinicsForDoctorQuery>> => {
        return this._getListOfClinicsForDoctorGQL.watch(doctorId).valueChanges;
    };

    /**
     * Get access token on first login before role selection
     * @param empty
     * @return api response with token details
     */
    getAccessBeforeRoleSelection = (): { email: string; token: string } => {
        if (localStorage.getItem('initial-access-token')) {
            const tokeDetails = JSON.parse(localStorage.getItem('initial-access-token')!);
            return tokeDetails;
        } else {
            return { email: '', token: '' };
        }
    };

    /**
     * Calls api for verifying otp entered.
     * @param payload shares user entered otp and user id.
     * @returns Observable with otp response data.
     */
    verifyOtp = (payload: VerifyOtpMutationVariables): Observable<MutationResult<VerifyOtpMutation>> => {
        return this._verifyOtpGQL.mutate(payload);
    };

    /**
     * Change password.
     * @param data Object with new password
     * @return change password status message.
     */
    changePassword = (
        data: ResetPasswordWithNewMutationVariables
    ): Observable<MutationResult<ResetPasswordWithNewMutation>> => {
        return this._changePassword.mutate(data);
    };

    /**
     * Get user details form email.
     * @param payload shares user email.
     * @returns user id.
     */
    checkIfEmailValidForgotPwd = (
        payload: GetUserDetailsQueryVariables
    ): Observable<ApolloQueryResult<GetUserDetailsQuery>> => {
        return this._getUserDetailsGQL.watch(payload).valueChanges;
    };

    /**
     * Action to be called when doctor selects a clinic on logging in
     * @param clinicDetails Clinic id, type, user id
     * @returns Observable
     */
    onClinicSelectAndUpdate = (
        clinicDetails: ClinicSelectAndUpdateMutationVariables
    ): Observable<MutationResult<ClinicSelectAndUpdateMutation>> => {
        return this._clinicSelectAndUpdateGQL.mutate(clinicDetails);
    };

    /**
     * Validate the email from server.
     * @param emailPayload email of doctor
     * @returns
     */
    validateDoctorEmail = (
        emailPayload: GetUserAndUserRoleMappingByEmailQueryVariables
    ): Observable<ApolloQueryResult<GetUserAndUserRoleMappingByEmailQuery>> => {
        return this._validateDoctorEmail.watch(emailPayload).valueChanges;
    };

    /**
     * To fetch user profile information for profile page
     */
    getUserProfileDetails = (
        payload: GetUserProfileQueryVariables
    ): Observable<ApolloQueryResult<GetUserProfileQuery>> => {
        return this._getUserProfileDetailsGQL.watch(payload).valueChanges;
    };

    onUpdateNewUserDetails = (
        data: UpdateNewUserDetailsMutationVariables
    ): Observable<MutationResult<UpdateNewUserDetailsMutation>> => {
        return this._updateNewUserDetailsGQL.mutate(data);
    };

    /**
     * Necessary action to make user logout and clear all data
     */
    clearAllUserDetailsAndLogOut = (): void => {
        this._store.dispatch(resetAppState());
        this._credentialsService.setCredentials(undefined);
        localStorage.clear();
        this._router.navigateByUrl(Url.LOGIN);
    };

    getClinicsByDesignerId = (
        params: GetClinicsByDesignerIdQueryVariables
    ): Observable<ApolloQueryResult<GetClinicsByDesignerIdQuery>> => {
        return this._getClinicsByDesignerIdGQL.watch(params).valueChanges;
    };

    transformClinicsByDesigners(query: GetClinicsByDesignerIdQuery): User[] {
        const clinics: User[] = [];

        // Loop through designer_distributor_mapping array
        query.designer_distributor_mapping.forEach((distributorMapping) => {
            // Loop through clinic_distributor_mappings array
            distributorMapping.userByDistributorId.clinic_distributor_mappings.forEach((clinicMapping) => {
                // Push the clinic details into the clinics array
                clinics.push({
                    id: clinicMapping?.user?.user_role_mappings?.[0]?.user_id,
                    name: clinicMapping.user?.user_role_mappings?.[0]?.name || '',
                });
            });
        });

        return clinics;
    }

    getRoleHierarchyForLoggedInUser = (): void => {
        let getRoleHierarchyForLoggedInUserParam: GetRoleHierarchyForLoggedInUserQueryVariables = {
            isClinic: false,
            isDistributor: false,
            isDoctor: false,
        };

        const role = this._credentialsService.credentials?.role;
        const userId = this._credentialsService.credentials?.userId;
        const clinicId = this._credentialsService.credentials?.clinicId;
        if (role === Roles.EXCLUSIVE_DISTRIBUTOR || role === Roles.SELECTIVE_DISTRIBUTOR) {
            getRoleHierarchyForLoggedInUserParam = {
                ...getRoleHierarchyForLoggedInUserParam,
                isDistributor: true,
                distributorId: userId,
            };
        }
        if (role === Roles.REGULAR_CLINIC || role === Roles.FRANCHISE_CLINIC) {
            getRoleHierarchyForLoggedInUserParam = {
                ...getRoleHierarchyForLoggedInUserParam,
                isClinic: true,
                clinicId: userId,
            };
        }
        if (role === Roles.DOCTOR) {
            getRoleHierarchyForLoggedInUserParam = {
                ...getRoleHierarchyForLoggedInUserParam,
                isDoctor: true,
                doctorId: userId,
                clinicId: clinicId,
            };
        }
        this._store.dispatch(getRoleHierarchyForLoggedInUser(getRoleHierarchyForLoggedInUserParam));
    };

    getUserDetailsToCheckAgainstLocalStorageDetails = (
        params: GetUserDetailsToCheckAgainstLocalStorageDetailsQueryVariables
    ): Observable<ApolloQueryResult<GetUserDetailsToCheckAgainstLocalStorageDetailsQuery>> => {
        return this._getUserDetailsToCheckAgainstLocalStorageDetails.watch(params).valueChanges;
    };

    transformGetUserDetailsToCheckAgainstLocalStorageDetailsQuery = (
        queryResult: GetUserDetailsToCheckAgainstLocalStorageDetailsQuery
    ): UserDetailsToCheckAgainstLocalStorage => {
        const userRoleMapping = queryResult.user_role_mapping?.[0];
        const clinicDoctorMapping = queryResult.clinic_doctor_mapping?.[0];
        const transformed: UserDetailsToCheckAgainstLocalStorage = {
            user_id: userRoleMapping?.user_id || '',
            role_mapping_id: userRoleMapping?.id || '',
            role_id: userRoleMapping?.role?.id || '',
            role: userRoleMapping?.role?.name || '',
            clinic_id: clinicDoctorMapping?.clinic_id || '',
            clinic_type: clinicDoctorMapping?.user?.user_role_mappings?.[0]?.role?.name || '',
            distributor_id: clinicDoctorMapping?.user?.clinic_distributors_by_clinic_id[0]?.distributor_id || '',
            designer_id: clinicDoctorMapping?.user?.clinic_distributors_by_clinic_id?.[0]?.distributor?.designerDistributorMappingsByDistributorId?.[0]?.designer_id
        };

        return transformed;
    };

    checkIfHasCredentialIssues = (userDetails: UserDetailsToCheckAgainstLocalStorage): boolean => {
        const {
            role: roleForCheck,
            role_id,
            role_mapping_id,
            clinic_id,
            clinic_type,
            distributor_id,
            designer_id,
        } = userDetails;
        const role = this._credentialsService?.credentials?.role;
        const roleId = this._credentialsService?.credentials?.roleId;
        const designerId = this._credentialsService?.credentials?.designerId;
        const distributorId = this._credentialsService?.credentials?.distributorId;
        const roleMappingId = this._credentialsService?.credentials?.roleMappingId;
        const clinicType = this._credentialsService?.credentials?.clinicType;
        const clinicId = this._credentialsService?.credentials?.clinicId;

        if (role !== roleForCheck || roleId !== role_id || role_mapping_id !== roleMappingId) {
            return true;
        }
        switch (role) {
            case Roles.DOCTOR:
                if (
                    clinicId !== clinic_id ||
                    clinicType !== clinic_type
                ) {
                    return true;
                }
                if(designerId && designerId !== designer_id){
                  return true;
                }
                if (
                  distributorId && distributorId !== distributor_id
                ){
                  return true;
                }
                break;

            default:
                break;
        }
        return false;
    };


  getSiblingListGQL = (params: GetSiblingListQueryVariables):Observable<ApolloQueryResult<GetSiblingListQuery>> => {
    return this._getSiblingListGQL.watch(params).valueChanges;
  };

  transformSiblingList = (query: GetSiblingListQuery): Patient[] => {
    const siblingIn = query.siblings;
    const careTakerPatient = query.care_taker?.[0]?.user_role_mappings?.[0];
    let users: Patient[] = [];
    users = siblingIn.map((patient) => {
      const clinicType = patient.user_role_mappings?.[0]?.user?.doctorPatientMappingsByPatientId?.[0]?.userByClinicId?.user_role_mappings?.[0]?.role?.name;
      return {
        id: patient?.user_role_mappings?.[0]?.user_id,
        name: patient?.user_role_mappings?.[0]?.name,
        role_id: patient?.user_role_mappings?.[0]?.role_id,
        role_mapping_id: patient?.user_role_mappings?.[0]?.id,
        clinicType: clinicType || ''
      } as Patient;
    });
    if(careTakerPatient?.id){
      const careTakerClinicType = careTakerPatient?.user?.doctorPatientMappingsByPatientId?.[0]?.userByClinicId?.user_role_mappings?.[0]?.role?.name;
      users = [
        ...users,
        {
          id: careTakerPatient?.user_id || '',
          name: careTakerPatient?.name || '',
          role_id: careTakerPatient?.role_id,
          role_mapping_id: careTakerPatient?.id,
          clinicType: careTakerClinicType || ''
        } as Patient
      ];
    }
    return users;
  }

  onLoginEvent = ():void => {
    this.onLoginSubject$.next(true);
  }

  onLoginEventAsObs = ():Observable<boolean> => {
    return this.onLoginSubject$.asObservable();
  }

  getFamilyMembersUnderFranchiseClinic = (params: GetFamilyMembersUnderFranchiseClinicQueryVariables): Observable<ApolloQueryResult<GetFamilyMembersUnderFranchiseClinicQuery>> => {
    return this._getFamilyMembersUnderFranchiseClinic.watch(params).valueChanges;
  }
}
