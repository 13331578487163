import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';

import { Url } from '@app/core/util/common';
import { isNonAuthDataExist } from '@app/auth/selectors/auth.selector';

const roleBasedKey = 'role-based-token';

@Injectable()
export class NoAuthGuard implements CanActivate {
    private auth: string | undefined;

    constructor(private _router: Router, private _store: Store) {}

    /**
     * CanActivate Guard for checking non authentication page routing, if logged in user redirected to
     * dashboard/home
     * If user tried to access pages like otp, reset password without proper data, redirected to login
     * @param route Route details of route hitted
     * @param state Represents the state of the router at a moment in time.
     * @returns Boolean, true for allowing the route to load, false to not allow
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.checkUserAuthentication();
        if (this.auth) {
            this._router.navigate([Url.DASHBOARD]);
            return of(false);
        } else {
            return this.isNonAuthDataExists().pipe(
                map((isValid) => {
                    if (isValid) {
                        return true;
                    } else {
                        if (state.url === Url.AUTH || state.url === Url.LOGIN) {
                            return true;
                        }
                        this._router.navigateByUrl(Url.LOGIN);
                        return false;
                    }
                }),
                catchError((err) => {
                    this._router.navigate([Url.LOGIN]);
                    return of(false);
                })
            );
        }
    }

    /**
     * Check whether user is authenticated by checking for token in localStorage
     * @param empty
     * @returns Void
     */
    checkUserAuthentication(): void {
        this.auth = localStorage.getItem(roleBasedKey) || undefined;
    }

    /**
     * Check whether in state there exists values for proper login/forgot password 
     * functionalities like initial token or userId
     * @param empty
     * @returns Observable boolean
     */
    isNonAuthDataExists(): Observable<boolean> {
        return this._store.select(isNonAuthDataExist);
    }
}
