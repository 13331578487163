import { eye } from './interfaces';
import { Troubleshoot } from './worksheet/troubleshoot';
import { EbcWorksheet } from './worksheet/worksheet';
import * as calculation from '../services/util.service';

export class Engine {
    constructor(private _eye: eye) {
        this.I6 = this.K8;
        this.J6 = this.N8;
        this.AA6 = this.AC8;
        this.AB6 = this.AF8;
        this.Y9 = this.G9;
        this.I16 = this.K18;
        this.Y17 = this.G17;
    }
    workbook = {
        WSi86: (worksheet: EbcWorksheet) => {
            return this._eye === eye.left ? worksheet.I86() : worksheet.I27();
        },
        WSi114: (worksheet: EbcWorksheet) => (this._eye === eye.left ? worksheet.I114() : worksheet.I55()),
        WSg91: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G91(troubleshoot, troubleshoot2)
                : worksheet.G32(troubleshoot, troubleshoot2),
        WSh87: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.H87(troubleshoot, troubleshoot2)
                : worksheet.H28(troubleshoot, troubleshoot2),

        WSi71: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) => {
            return this._eye === eye.left
                ? worksheet.I71(troubleshoot, troubleshoot2)
                : worksheet.I12(troubleshoot, troubleshoot2);
        },
        WSh111: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) => {
            return this._eye === eye.left
                ? worksheet.H111(troubleshoot, troubleshoot2)
                : worksheet.H52(troubleshoot, troubleshoot2);
        },
        WSg78: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G78(troubleshoot, troubleshoot2)
                : worksheet.G19(troubleshoot, troubleshoot2),
        WSg82: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G82(troubleshoot, troubleshoot2)
                : worksheet.G23(troubleshoot, troubleshoot2),
        WSg83: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left ? worksheet.G83(troubleshoot, troubleshoot2) : worksheet.G24(troubleshoot, troubleshoot2),
        WSi65: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.I65(troubleshoot, troubleshoot2)
                : worksheet.I6(troubleshoot, troubleshoot2),
        WSi68: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.I68(troubleshoot, troubleshoot2)
                : worksheet.I9(troubleshoot, troubleshoot2),
        WSg80: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G80(troubleshoot, troubleshoot2)
                : worksheet.G21(troubleshoot, troubleshoot2),
        WSh72: (worksheet: EbcWorksheet) => (this._eye === eye.left ? worksheet.H72 : worksheet.H13),
        WSg81: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G81(troubleshoot, troubleshoot2)
                : worksheet.G22(troubleshoot, troubleshoot2),

        WSg74: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G74(troubleshoot, troubleshoot2)
                : worksheet.G15(troubleshoot, troubleshoot2),
        WSi73: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.I73(troubleshoot, troubleshoot2)
                : worksheet.I14(troubleshoot, troubleshoot2),
        WSg84: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) =>
            this._eye === eye.left
                ? worksheet.G84(troubleshoot, troubleshoot2)
                : worksheet.G25(troubleshoot, troubleshoot2),
    };
    C2 = this.workbook.WSi86;
    U2 = this.workbook.WSi114;
    C3 = this.workbook.WSg91;
    E3 = this.workbook.WSh87;
    F3 = this.workbook.WSi71;
    I3(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            (this.C34 +
                this.C30 +
                this.C26(worksheet, troubleshoot, troubleshoot2) +
                this.C22(worksheet, troubleshoot, troubleshoot2) +
                this.C18(worksheet, troubleshoot, troubleshoot2) +
                this.C14(worksheet) +
                this.C10(worksheet, troubleshoot, troubleshoot2) +
                this.C6(worksheet, troubleshoot, troubleshoot2) / 2) *
            2
        );
    }
    C34 = 0;
    C30 = 0;
    C27 = 0;
    G25 = this.workbook.WSg84;
    C25(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);
        const C3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const C10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const C14 = this.C14(worksheet);
        const C18 = this.C18(worksheet, troubleshoot, troubleshoot2);
        const C22 = this.C22(worksheet, troubleshoot, troubleshoot2);
        const C26 = this.C26(worksheet, troubleshoot, troubleshoot2);
        const C27 = this.C27;
        const G21 = this.G21(worksheet, troubleshoot, troubleshoot2);
        const G25 = this.G25(worksheet, troubleshoot, troubleshoot2);

        let result = Math.sqrt(
            (((C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22 + C26, 2))) /
                (1 - C3 * Math.abs(C3)) -
                (C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22, 2))) /
                    (1 - C3 * Math.abs(C3)) +
                G21 / 1000 -
                G25 / 1000) **
                4 *
                (1 - Math.abs(C27) * C27) ** 2 +
                ((C6 / 2 + C10 + C14 + C18 + C22) ** 2 + (C6 / 2 + C10 + C14 + C18 + C22 + C26) ** 2) ** 2 +
                2 *
                    ((C2 -
                        Math.sqrt(
                            C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22 + C26, 2)
                        )) /
                        (1 - C3 * Math.abs(C3)) -
                        (C2 -
                            Math.sqrt(
                                C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22, 2)
                            )) /
                            (1 - C3 * Math.abs(C3)) +
                        G21 / 1000 -
                        G25 / 1000) **
                        2 *
                    ((C6 / 2 + C10 + C14 + C18 + C22) ** 2 + (C6 / 2 + C10 + C14 + C18 + C22 + C26) ** 2) *
                    (1 - Math.abs(C27) * C27) -
                4 * (C6 / 2 + C10 + C14 + C18 + C22) ** 2 * (C6 / 2 + C10 + C14 + C18 + C22 + C26) ** 2) /
                (4 *
                    ((C2 -
                        Math.sqrt(
                            C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22 + C26, 2)
                        )) /
                        (1 - C3 * Math.abs(C3)) -
                        (C2 -
                            Math.sqrt(
                                C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14 + C18 + C22, 2)
                            )) /
                            (1 - C3 * Math.abs(C3)) +
                        G21 / 1000 -
                        G25 / 1000) **
                        2)
        );
        return result;
    }

    C26 = this.workbook.WSi73;
    C22 = this.workbook.WSg74;
    U3 = this.workbook.WSg91;
    W3(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return -this.workbook.WSh111(worksheet, troubleshoot, troubleshoot2);
    }
    X3 = this.F3;
    C5(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        let result = Math.round(this.D5(worksheet, troubleshoot, troubleshoot2) / 0.05) * 0.05;
        return result;
    }
    D5(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        return (
            337.5 /
            (337.5 / c2 +
                this.E3(worksheet, troubleshoot, troubleshoot2) -
                this.F3(worksheet, troubleshoot, troubleshoot2))
        );
    }
    G5 = this.workbook.WSg78;
    J5 = this.N17;
    U5(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        let result = Math.round(this.V5(worksheet, troubleshoot, troubleshoot2) / 0.05) * 0.05;
        return calculation.cropValueToMaxExcelDecimalPoints(result, 2);
    }
    V5(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const osEngneU2 = this.U2(worksheet);
        const osEngineW3 = this.W3(worksheet, troubleshoot, troubleshoot2);
        const osEngineX3 = this.X3(worksheet, troubleshoot, troubleshoot2);
        return 337.5 / (337.5 / osEngneU2 + osEngineW3 - osEngineX3);
    }
    Y5 = this.G5;
    C6 = this.workbook.WSi68;
    I6: number;
    J6: number;
    U6 = this.C6;
    AA6: number;
    AB6: number;
    C7 = this.workbook.WSg83;
    I7 = this.K9;
    J7 = this.N9;
    U7 = this.C7;
    AA7 = this.AC9;
    AB7 = this.AF9;
    I8 = this.K10;
    J8 = this.N10;
    K8 = 0;
    L8 = this.O8;
    M8 = 0;
    N8 = 0;
    O8(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        1000 * (this.M8 + this.G5(worksheet, troubleshoot, troubleshoot2) / 1000 - this.N8);
    }
    AA8 = this.AC10;
    AB8 = this.AF10;
    AC8 = 0;
    AD8 = this.AG8;
    AE8 = 0;
    AF8 = 0;
    AG8(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return 1000 * (this.AE8 + this.Y5(worksheet, troubleshoot, troubleshoot2) / 1000 - this.AF8);
    }
    C9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2: number = this.C2(worksheet);
        const c3: number = this.C3(worksheet, troubleshoot, troubleshoot2);
        const c5: number = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c6: number = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10: number = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c7: number = this.C7(worksheet, troubleshoot, troubleshoot2);
        const c11: number = this.C11;
        const g5: number = this.G5(worksheet, troubleshoot, troubleshoot2);
        const g9: number = this.G9;
        let result = Math.sqrt(
            (((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10, 2))) /
                (1 - c3 * Math.abs(c3)) -
                (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2, 2))) / (1 - c3 * Math.abs(c3)) +
                ((c2 - Math.sqrt(c2 ** 2 - (1 - Math.abs(c3) * c3) * (c6 / 2) ** 2)) / (1 - Math.abs(c3) * c3) -
                    (c5 - Math.sqrt(c5 ** 2 - (1 - Math.abs(c7 * c7)) * (c6 / 2) ** 2)) / (1 - Math.abs(c7 * c7)) +
                    g5 / 1000) -
                g9 / 1000) **
                4 *
                (1 - Math.abs(c11) * c11) ** 2 +
                ((c6 / 2) ** 2 + (c6 / 2 + c10) ** 2) ** 2 +
                2 *
                    ((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10, 2))) /
                        (1 - c3 * Math.abs(c3)) -
                        (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2, 2))) /
                            (1 - c3 * Math.abs(c3)) +
                        ((c2 - Math.sqrt(c2 ** 2 - (1 - Math.abs(c3) * c3) * (c6 / 2) ** 2)) / (1 - Math.abs(c3) * c3) -
                            (c5 - Math.sqrt(c5 ** 2 - (1 - Math.abs(c7 * c7)) * (c6 / 2) ** 2)) /
                                (1 - Math.abs(c7 * c7)) +
                            g5 / 1000) -
                        g9 / 1000) **
                        2 *
                    ((c6 / 2) ** 2 + (c6 / 2 + c10) ** 2) *
                    (1 - Math.abs(c11) * c11) -
                4 * (c6 / 2) ** 2 * (c6 / 2 + c10) ** 2) /
                (4 *
                    ((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10, 2))) /
                        (1 - c3 * Math.abs(c3)) -
                        (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2, 2))) /
                            (1 - c3 * Math.abs(c3)) +
                        ((c2 - Math.sqrt(c2 ** 2 - (1 - Math.abs(c3) * c3) * (c6 / 2) ** 2)) / (1 - Math.abs(c3) * c3) -
                            (c5 - Math.sqrt(c5 ** 2 - (1 - Math.abs(c7 * c7)) * (c6 / 2) ** 2)) /
                                (1 - Math.abs(c7 * c7)) +
                            g5 / 1000) -
                        g9 / 1000) **
                        2)
        );
        return result;
    }
    G9 = 20.0;
    I9 = this.K11;
    J9 = this.N11;
    K9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.C6(worksheet, troubleshoot, troubleshoot2) / 20;
    }
    L9 = this.O9;
    M9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2: number = this.C2(worksheet);
        const C3: number = this.C3(worksheet, troubleshoot, troubleshoot2);
        const K9: number = this.K9(worksheet, troubleshoot, troubleshoot2);
        const numerator: number = C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(K9, 2));
        const denominator: number = 1 - C3 * Math.abs(C3);
        const result: number = numerator / denominator;
        return result;
    }
    N9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C5: number = this.C5(worksheet, troubleshoot, troubleshoot2);
        const C7: number = this.C7(worksheet, troubleshoot, troubleshoot2);
        const K9: number = this.K9(worksheet, troubleshoot, troubleshoot2);
        const absC7 = Math.abs(C7);
        const numerator = C5 - Math.sqrt(C5 * C5 - (1 - absC7 * C7) * K9 * K9);
        const denominator = 1 - absC7 * C7;
        return numerator / denominator;
    }

    O9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const M9 = this.M9(worksheet, troubleshoot, troubleshoot2);
        const G5 = this.G5(worksheet, troubleshoot, troubleshoot2);
        const N9 = this.N9(worksheet, troubleshoot, troubleshoot2);
        return 1000 * (M9 + G5 / 1000 - N9);
    }

    U9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U2 = this.U2(worksheet);
        const U3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const U5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const U7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const U10 = this.U10(worksheet, troubleshoot, troubleshoot2);
        const U11 = this.U11;
        const Y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const Y9 = this.Y9;

        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const result = sqrt(
            (((U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2 + U10, 2))) / (1 - U3 * abs(U3)) -
                (U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2, 2))) / (1 - U3 * abs(U3)) +
                ((U2 - sqrt(U2 ** 2 - (1 - abs(U3) * U3) * (U6 / 2) ** 2)) / (1 - abs(U3) * U3) -
                    (U5 - sqrt(U5 ** 2 - (1 - abs(U7 * U7)) * (U6 / 2) ** 2)) / (1 - abs(U7 * U7)) +
                    Y5 / 1000) -
                Y9 / 1000) **
                4 *
                (1 - abs(U11) * U11) ** 2 +
                ((U6 / 2) ** 2 + (U6 / 2 + U10) ** 2) ** 2 +
                2 *
                    ((U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2 + U10, 2))) / (1 - U3 * abs(U3)) -
                        (U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2, 2))) / (1 - U3 * abs(U3)) +
                        ((U2 - sqrt(U2 ** 2 - (1 - abs(U3) * U3) * (U6 / 2) ** 2)) / (1 - abs(U3) * U3) -
                            (U5 - sqrt(U5 ** 2 - (1 - abs(U7 * U7)) * (U6 / 2) ** 2)) / (1 - abs(U7 * U7)) +
                            Y5 / 1000) -
                        Y9 / 1000) **
                        2 *
                    ((U6 / 2) ** 2 + (U6 / 2 + U10) ** 2) *
                    (1 - abs(U11) * U11) -
                4 * (U6 / 2) ** 2 * (U6 / 2 + U10) ** 2) /
                (4 *
                    ((U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2 + U10, 2))) / (1 - U3 * abs(U3)) -
                        (U2 - sqrt(U2 * U2 - (1 - U3 * abs(U3)) * power(U6 / 2, 2))) / (1 - U3 * abs(U3)) +
                        ((U2 - sqrt(U2 ** 2 - (1 - abs(U3) * U3) * (U6 / 2) ** 2)) / (1 - abs(U3) * U3) -
                            (U5 - sqrt(U5 ** 2 - (1 - abs(U7 * U7)) * (U6 / 2) ** 2)) / (1 - abs(U7 * U7)) +
                            Y5 / 1000) -
                        Y9 / 1000) **
                        2)
        );
        return result;
    }

    Y9: number;
    AA9 = this.AC11;
    AB9 = this.AF11;
    AC9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        return U6 / 20;
    }
    AD9 = this.AG9;
    AE9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U2 = this.U2(worksheet);
        const U3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const AC9 = this.AC9(worksheet, troubleshoot, troubleshoot2);

        return (U2 - Math.sqrt(U2 * U2 - (1 - Math.abs(U3) * U3) * Math.pow(AC9, 2))) / (1 - Math.abs(U3) * U3);
    }
    AF9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const U7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const AC9 = this.AC9(worksheet, troubleshoot, troubleshoot2);
        const numerator = U5 - Math.sqrt(U5 * U5 - (1 - U7 * Math.abs(U7)) * Math.pow(AC9, 2));
        const denominator = 1 - U7 * Math.abs(U7);
        return numerator / denominator;
    }

    AG9(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae9 = this.AE9(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af9 = this.AF9(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (ae9 + y5 / 1000 - af9);
    }
    C10 = this.workbook.WSi65;
    I10 = this.K12;
    J10 = this.N12;
    K10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        return (C6 * 1) / 10;
    }
    L10 = this.O10;
    M10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);
        const C3 = this.C3(worksheet, troubleshoot, troubleshoot2);

        const k10 = this.K10(worksheet, troubleshoot, troubleshoot2);

        const numerator = C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(k10, 2));

        const denominator = 1 - Math.abs(C3) * C3;

        return (1000 * numerator) / denominator;
    }
    N10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const C7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k10 = this.K10(worksheet, troubleshoot, troubleshoot2);

        const numerator = C5 - Math.sqrt(C5 * C5 - (1 - C7 * Math.abs(C7)) * Math.pow(k10, 2));
        const denominator = 1 - C7 * Math.abs(C7);
        return numerator / denominator;
    }
    O10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m10 = this.M10(worksheet, troubleshoot, troubleshoot2);
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const n10 = this.N10(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (m10 + c5 / 1000 - n10);
    }
    Q10 = this.C9;
    U10 = this.C10;
    AA10 = this.AC12;
    AB10 = this.AF12;
    AC10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        return (U6 * 1) / 10;
    }
    AD10 = this.AG10;
    AE10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U2 = this.U2(worksheet);
        const U3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const AC10 = this.AC10(worksheet, troubleshoot, troubleshoot2);

        const numerator = U2 - Math.sqrt(U2 * U2 - (1 - Math.abs(U3) * U3) * Math.pow(AC10, 2));

        const denominator = 1 - Math.abs(U3) * U3;

        return numerator / denominator;
    }

    AF10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const U7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const AC10 = this.AC10(worksheet, troubleshoot, troubleshoot2);

        const numerator = U5 - Math.sqrt(U5 * U5 - (1 - U7 * Math.abs(U7)) * Math.pow(AC10, 2));
        const denominator = 1 - U7 * Math.abs(U7);
        return numerator / denominator;
    }
    AG10(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae10 = this.AE10(worksheet, troubleshoot, troubleshoot2);

        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);

        const af10 = this.AF10(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (ae10 + y5 / 1000 - af10);
    }
    AI10 = this.U9;
    C11 = 0.0;
    I11 = this.K13;
    J11 = this.N13;
    K11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        return (C6 * 3) / 20;
    }
    L11 = this.O11;
    M11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);
        const C3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k11 = this.K11(worksheet, troubleshoot, troubleshoot2);

        const numerator = C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(k11, 2));
        const denominator = 1 - C3 * Math.abs(C3);
        return numerator / denominator;
    }
    N11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const C7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k11 = this.K11(worksheet, troubleshoot, troubleshoot2);

        const numerator = C5 - Math.sqrt(C5 * C5 - (1 - C7 * Math.abs(C7)) * Math.pow(k11, 2));
        const denominator = 1 - C7 * Math.abs(C7);
        return numerator / denominator;
    }
    O11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m11 = this.M11(worksheet, troubleshoot, troubleshoot2);
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const n11 = this.N11(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (m11 + c5 / 1000 - n11);
    }
    U11 = 0.0;
    AA11 = this.AC13;
    AB11 = this.AF13;
    AC11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        return (U6 * 3) / 20;
    }
    AD11 = this.AG11;
    AE11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U2 = this.U2(worksheet);
        const U3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac11 = this.AC11(worksheet, troubleshoot, troubleshoot2);

        const numerator = U2 - Math.sqrt(U2 * U2 - (1 - U3 * Math.abs(U3)) * Math.pow(ac11, 2));
        const denominator = 1 - U3 * Math.abs(U3);
        return numerator / denominator;
    }
    AF11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const U7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac11 = this.AC11(worksheet, troubleshoot, troubleshoot2);

        const numerator = U5 - Math.sqrt(U5 * U5 - (1 - U7 * Math.abs(U7)) * Math.pow(ac11, 2));
        const denominator = 1 - U7 * Math.abs(U7);

        return numerator / denominator;
    }
    AG11(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae11 = this.AE11(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af11 = this.AF11(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (ae11 + y5 / 1000 - af11);
    }
    I12 = this.K14;
    J12 = this.N14;
    K12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        return (C6 * 2) / 10;
    }
    L12 = this.O12;
    M12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);
        const C3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k12 = this.K12(worksheet, troubleshoot, troubleshoot2);

        const numerator = C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(k12, 2));
        const denominator = 1 - C3 * Math.abs(C3);
        return numerator / denominator;
    }
    N12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const C7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k12 = this.K12(worksheet, troubleshoot, troubleshoot2);

        const numerator = C5 - Math.sqrt(C5 * C5 - (1 - C7 * Math.abs(C7)) * Math.pow(k12, 2));
        const denominator = 1 - C7 * Math.abs(C7);
        return numerator / denominator;
    }
    O12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m12 = this.M12(worksheet, troubleshoot, troubleshoot2);
        const g5 = this.G5(worksheet, troubleshoot, troubleshoot2);
        const n12 = this.N12(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (m12 + g5 / 1000 - n12);
    }
    AA12 = this.AC14;
    AB12 = this.AF14;
    AC12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        return (U6 * 2) / 10;
    }

    AD12 = this.AG12;
    AE12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U2 = this.U2(worksheet);
        const U3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac12 = this.AC12(worksheet, troubleshoot, troubleshoot2);

        const numerator = U2 - Math.sqrt(U2 * U2 - (1 - U3 * Math.abs(U3)) * Math.pow(ac12, 2));

        const denominator = 1 - U3 * Math.abs(U3);
        return numerator / denominator;
    }
    AF12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const U7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac12 = this.AC12(worksheet, troubleshoot, troubleshoot2);

        const numerator = U5 - Math.sqrt(U5 * U5 - (1 - U7 * Math.abs(U7)) * Math.pow(ac12, 2));
        const denominator = 1 - U7 * Math.abs(U7);
        return numerator / denominator;
    }
    AG12(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae12 = this.AE12(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af12 = this.AF12(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (ae12 + y5 / 1000 - af12);
    }
    C13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);
        const C3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const C10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const C14 = this.C14(worksheet);
        const G9 = this.G9;
        const G13 = this.G13(worksheet, troubleshoot, troubleshoot2);
        const C15 = this.C15;

        const a =
            C2 -
            Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10 + C14, 2)) / (1 - C3 * Math.abs(C3));
        const b =
            C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(C6 / 2 + C10, 2)) / (1 - C3 * Math.abs(C3));
        const c = G9 / 1000 - G13 / 1000;
        const d =
            (Math.pow(a - b + c, 4) * Math.pow(1 - Math.abs(C15) * C15, 2) +
                Math.pow(Math.pow(C6 / 2 + C10, 2) + Math.pow(C6 / 2 + C10 + C14, 2), 2) +
                2 *
                    Math.pow(a - b + c, 2) *
                    (Math.pow(C6 / 2 + C10, 2) + Math.pow(C6 / 2 + C10 + C14, 2)) *
                    (1 - Math.abs(C15) * C15) -
                4 * Math.pow(C6 / 2 + C10, 2) * Math.pow(C6 / 2 + C10 + C14, 2)) /
            (4 * Math.pow(a - b + c, 2));

        const result = Math.sqrt(d);
        return result;
    }
    G13 = this.workbook.WSg80;
    I13 = this.K15;
    J13 = this.N15;
    K13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        return (C6 * 5) / 20;
    }
    L13 = this.O13;
    M13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C2 = this.C2(worksheet);

        const C3: number = this.C3(worksheet, troubleshoot, troubleshoot2);
        const K13: number = this.K13(worksheet, troubleshoot, troubleshoot2);
        const result = (C2 - Math.sqrt(C2 * C2 - (1 - C3 * Math.abs(C3)) * Math.pow(K13, 2))) / (1 - C3 * Math.abs(C3));
        return result;
    }
    N13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const C5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const C7: number = this.C7(worksheet, troubleshoot, troubleshoot2);
        const K13: number = this.K13(worksheet, troubleshoot, troubleshoot2);
        const result = (C5 - Math.sqrt(C5 * C5 - (1 - C7 * Math.abs(C7)) * Math.pow(K13, 2))) / (1 - C7 * Math.abs(C7));
        return result;
    }
    O13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const M13 = this.M13(worksheet, troubleshoot, troubleshoot2);
        const N13 = this.N13(worksheet, troubleshoot, troubleshoot2);
        const G5 = this.G5(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (M13 + G5 / 1000 - N13);
    }

    U13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);
        const u14 = this.U14(worksheet);
        const u15 = this.U15;
        const y9 = this.Y9;
        const y13 = this.Y13(worksheet, troubleshoot, troubleshoot2);

        let result = sqrt(
            (((u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10 + u14, 2))) / (1 - u3 * abs(u3)) -
                (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10, 2))) / (1 - u3 * abs(u3)) +
                y9 / 1000 -
                y13 / 1000) **
                4 *
                (1 - abs(u15) * u15) ** 2 +
                ((u6 / 2 + u10) ** 2 + (u6 / 2 + u10 + u14) ** 2) ** 2 +
                2 *
                    ((u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10 + u14, 2))) / (1 - u3 * abs(u3)) -
                        (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10, 2))) / (1 - u3 * abs(u3)) +
                        y9 / 1000 -
                        y13 / 1000) **
                        2 *
                    ((u6 / 2 + u10) ** 2 + (u6 / 2 + u10 + u14) ** 2) *
                    (1 - abs(u15) * u15) -
                4 * (u6 / 2 + u10) ** 2 * (u6 / 2 + u10 + u14) ** 2) /
                (4 *
                    ((u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10 + u14, 2))) / (1 - u3 * abs(u3)) -
                        (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(u6 / 2 + u10, 2))) / (1 - u3 * abs(u3)) +
                        y9 / 1000 -
                        y13 / 1000) **
                        2)
        );

        return result;
    }
    Y13 = this.G13;
    AA13 = this.AC15;
    AB13 = this.AF15;
    AC13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const U6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        return (U6 * 5) / 20;
    }
    AD13 = this.AG13;
    AE13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac13 = this.AC13(worksheet, troubleshoot, troubleshoot2);

        const result = (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(ac13, 2))) / (1 - u3 * abs(u3));

        return result;
    }
    AF13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac13 = this.AC13(worksheet, troubleshoot, troubleshoot2);

        const result = (u5 - sqrt(u5 * u5 - (1 - u7 * abs(u7)) * power(ac13, 2))) / (1 - u7 * abs(u7));

        return result;
    }
    AG13(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.AE13(worksheet, troubleshoot, troubleshoot2) +
                this.Y5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.AF13(worksheet, troubleshoot, troubleshoot2))
        );
    }
    C14 = this.workbook.WSh72;
    I14 = this.K16;
    J14 = this.N16;
    K14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.C6(worksheet, troubleshoot, troubleshoot2) * 6) / 20;
    }
    L14 = this.O14;
    M14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k14 = this.K14(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - sqrt(c2 * c2 - (1 - c3 * abs(c3)) * power(k14, 2))) / (1 - c3 * abs(c3));

        return result;
    }
    N14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k14 = this.K14(worksheet, troubleshoot, troubleshoot2);

        const result = (c5 - sqrt(c5 * c5 - (1 - c7 * abs(c7)) * power(k14, 2))) / (1 - c7 * abs(c7));

        return result;
    }
    O14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.M14(worksheet, troubleshoot, troubleshoot2) +
                this.G5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.N14(worksheet, troubleshoot, troubleshoot2))
        );
    }
    Q14 = this.C13;
    U14 = this.C14;
    AA14 = this.AC16;
    AB14 = this.AF16;
    AC14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.U6(worksheet, troubleshoot, troubleshoot2) * 6) / 20;
    }
    AD14 = this.AG14;
    AE14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac14 = this.AC14(worksheet, troubleshoot, troubleshoot2);

        const result = (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(ac14, 2))) / (1 - u3 * abs(u3));

        return result;
    }
    AF14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac14 = this.AC14(worksheet, troubleshoot, troubleshoot2);

        const result = (u5 - sqrt(u5 * u5 - (1 - u7 * abs(u7)) * power(ac14, 2))) / (1 - u7 * abs(u7));

        return result;
    }
    AG14(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.AE14(worksheet, troubleshoot, troubleshoot2) +
                this.Y5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.AF14(worksheet, troubleshoot, troubleshoot2))
        );
    }
    AI14 = this.U13;
    C15 = 0.0;
    I15 = this.K17;
    J15 = this.N17;
    K15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.C6(worksheet, troubleshoot, troubleshoot2) * 7) / 20;
    }
    L15 = this.O15;
    M15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k15 = this.K15(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - sqrt(c2 * c2 - (1 - c3 * abs(c3)) * power(k15, 2))) / (1 - c3 * abs(c3));

        return result;
    }
    N15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k15 = this.K15(worksheet, troubleshoot, troubleshoot2);

        const result = (c5 - sqrt(c5 * c5 - (1 - c7 * abs(c7)) * power(k15, 2))) / (1 - c7 * abs(c7));

        return result;
    }

    O15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.M15(worksheet, troubleshoot, troubleshoot2) +
                this.G5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.N15(worksheet, troubleshoot, troubleshoot2))
        );
    }
    U15 = 0.0;
    AA15 = this.AC17;
    AB15 = this.AF17;
    AC15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.U6(worksheet, troubleshoot, troubleshoot2) * 7) / 20;
    }
    AD15 = this.AG15;
    AE15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac15 = this.AC15(worksheet, troubleshoot, troubleshoot2);

        const result = (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(ac15, 2))) / (1 - u3 * abs(u3));

        return result;
    }
    AF15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac15 = this.AC15(worksheet, troubleshoot, troubleshoot2);

        const result = (u5 - sqrt(u5 * u5 - (1 - u7 * abs(u7)) * power(ac15, 2))) / (1 - u7 * abs(u7));

        return result;
    }
    AG15(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.AE15(worksheet, troubleshoot, troubleshoot2) +
                this.Y5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.AF15(worksheet, troubleshoot, troubleshoot2))
        );
    }
    I16: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) => number;
    Y17: (worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) => number;

    J16 = this.N18;
    K16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.C6(worksheet, troubleshoot, troubleshoot2) * 4) / 10;
    }
    L16 = this.O16;
    M16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k16 = this.K16(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - sqrt(c2 * c2 - (1 - c3 * abs(c3)) * power(k16, 2))) / (1 - c3 * abs(c3));

        return result;
    }
    N16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k16 = this.K16(worksheet, troubleshoot, troubleshoot2);

        const result = (c5 - sqrt(c5 * c5 - (1 - c7 * abs(c7)) * power(k16, 2))) / (1 - c7 * abs(c7));

        return result;
    }

    O16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.M16(worksheet, troubleshoot, troubleshoot2) +
                this.G5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.N16(worksheet, troubleshoot, troubleshoot2))
        );
    }
    AA16 = this.AC18;
    AB16 = this.AF18;
    AC16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.U6(worksheet, troubleshoot, troubleshoot2) * 4) / 10;
    }
    AD16 = this.AG16;
    AE16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac16 = this.AC16(worksheet, troubleshoot, troubleshoot2);

        const result = (u2 - sqrt(u2 * u2 - (1 - u3 * abs(u3)) * power(ac16, 2))) / (1 - u3 * abs(u3));

        return result;
    }
    AF16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac16 = this.AC16(worksheet, troubleshoot, troubleshoot2);

        const result = (u5 - sqrt(u5 * u5 - (1 - u7 * abs(u7)) * power(ac16, 2))) / (1 - u7 * abs(u7));

        return result;
    }
    AG16(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            1000 *
            (this.AE16(worksheet, troubleshoot, troubleshoot2) +
                this.Y5(worksheet, troubleshoot, troubleshoot2) / 1000 -
                this.AF16(worksheet, troubleshoot, troubleshoot2))
        );
    }
    C17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);

        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c14 = this.C14(worksheet);
        const c18 = this.C18(worksheet, troubleshoot, troubleshoot2);
        const c19 = this.C19;

        const g13 = this.G13(worksheet, troubleshoot, troubleshoot2);
        const g17 = this.G17(worksheet, troubleshoot, troubleshoot2);

        let result = Math.sqrt(
            (((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14 + c18, 2))) /
                (1 - c3 * Math.abs(c3)) -
                (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14, 2))) /
                    (1 - c3 * Math.abs(c3)) +
                g13 / 1000 -
                g17 / 1000) **
                4 *
                (1 - Math.abs(c19) * c19) ** 2 +
                (Math.pow(c6 / 2 + c10 + c14, 2) + Math.pow(c6 / 2 + c10 + c14 + c18, 2)) ** 2 +
                2 *
                    ((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14 + c18, 2))) /
                        (1 - c3 * Math.abs(c3)) -
                        (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14, 2))) /
                            (1 - c3 * Math.abs(c3)) +
                        g13 / 1000 -
                        g17 / 1000) **
                        2 *
                    (Math.pow(c6 / 2 + c10 + c14, 2) + Math.pow(c6 / 2 + c10 + c14 + c18, 2)) *
                    (1 - Math.abs(c19) * c19) -
                4 * Math.pow(c6 / 2 + c10 + c14, 2) * Math.pow(c6 / 2 + c10 + c14 + c18, 2)) /
                (4 *
                    ((c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14 + c18, 2))) /
                        (1 - c3 * Math.abs(c3)) -
                        (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(c6 / 2 + c10 + c14, 2))) /
                            (1 - c3 * Math.abs(c3)) +
                        g13 / 1000 -
                        g17 / 1000) **
                        2)
        );
        return result;
    }
    G17 = this.workbook.WSg81;
    I17 = this.K19;
    J17 = this.N19;
    K17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.C6(worksheet, troubleshoot, troubleshoot2) * 9) / 20;
    }
    L17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.O17(worksheet, troubleshoot, troubleshoot2);
    }
    M17(
        worksheet: EbcWorksheet,

        troubleshoot: Troubleshoot,

        troubleshoot2: Troubleshoot
    ) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k17 = this.K17(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(k17, 2))) / (1 - c3 * Math.abs(c3));
        return result;
    }
    N17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k17 = this.K17(worksheet, troubleshoot, troubleshoot2);

        const result = (c5 - Math.sqrt(c5 * c5 - (1 - c7 * Math.abs(c7)) * Math.pow(k17, 2))) / (1 - c7 * Math.abs(c7));
        return result;
    }
    O17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m17 = this.M17(worksheet, troubleshoot, troubleshoot2);
        const g5 = this.G5(worksheet, troubleshoot, troubleshoot2);
        const g17 = this.G17(worksheet, troubleshoot, troubleshoot2);

        const result = m17 - g5 / 1000 + g17 / 1000;
        return result;
    }
    U19 = 0;

    U17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);
        const u14 = this.U14(worksheet);
        const u18 = this.U18(worksheet, troubleshoot, troubleshoot2);
        const u19 = this.U19;
        const y13 = this.Y13(worksheet, troubleshoot, troubleshoot2);
        const y17 = this.Y17(worksheet, troubleshoot, troubleshoot2);

        const result = Math.sqrt(
            (((u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14 + u18, 2))) /
                (1 - u3 * Math.abs(u3)) -
                (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14, 2))) /
                    (1 - u3 * Math.abs(u3)) +
                y13 / 1000 -
                y17 / 1000) **
                4 *
                (1 - Math.abs(u19) * u19) ** 2 +
                ((u6 / 2 + u10 + u14) ** 2 + (u6 / 2 + u10 + u14 + u18) ** 2) ** 2 +
                2 *
                    ((u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14 + u18, 2))) /
                        (1 - u3 * Math.abs(u3)) -
                        (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14, 2))) /
                            (1 - u3 * Math.abs(u3)) +
                        y13 / 1000 -
                        y17 / 1000) **
                        2 *
                    ((u6 / 2 + u10 + u14) ** 2 + (u6 / 2 + u10 + u14 + u18) ** 2) *
                    (1 - Math.abs(u19) * u19) -
                4 * (u6 / 2 + u10 + u14) ** 2 * (u6 / 2 + u10 + u14 + u18) ** 2) /
                (4 *
                    ((u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14 + u18, 2))) /
                        (1 - u3 * Math.abs(u3)) -
                        (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14, 2))) /
                            (1 - u3 * Math.abs(u3)) +
                        y13 / 1000 -
                        y17 / 1000) **
                        2)
        );

        return result;
    }
    V17 = this.G17;
    AA17 = this.AC19;
    AB17 = this.AF19;
    AC17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const result = (u6 * 9) / 20;
        return result;
    }
    AD17 = this.AG17;
    AE17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac17 = this.AC17(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(ac17, 2))) / (1 - u3 * Math.abs(u3));
        return result;
    }
    AF17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac17 = this.AC17(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u5 - Math.sqrt(u5 * u5 - (1 - u7 * Math.abs(u7)) * Math.pow(ac17, 2))) / (1 - u7 * Math.abs(u7));
        return result;
    }

    AG17(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae17: number = this.AE17(worksheet, troubleshoot, troubleshoot2);
        const y5: number = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af17: number = this.AF17(worksheet, troubleshoot, troubleshoot2);

        const result = 1000 * (ae17 + y5 / 1000 - af17);
        return result;
    }
    C18 = this.workbook.WSg74;
    I18 = this.K20;
    J18 = this.N20;
    K18 = this.C6;
    L18 = this.O18;
    M18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k18 = this.K18(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(k18, 2))) / (1 - c3 * Math.abs(c3));
        return result;
    }
    N18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const k18 = this.K18(worksheet, troubleshoot, troubleshoot2);

        const result = (c5 - Math.sqrt(c5 * c5 - (1 - c7 * Math.abs(c7)) * Math.pow(k18, 2))) / (1 - c7 * Math.abs(c7));
        return result;
    }
    O18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m18 = this.M18(worksheet, troubleshoot, troubleshoot2);
        const n18 = this.N18(worksheet, troubleshoot, troubleshoot2);
        const g5 = this.G5(worksheet, troubleshoot, troubleshoot2);

        const result = 1000 * (m18 + g5 / 1000 - n18);
        return result;
    }
    Q18 = this.C17;
    U18 = this.C18;
    AA18 = this.AC20;
    AB18 = this.AF20;
    AC18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const result = u6 / 2;
        return result;
    }
    AD18 = this.AG18;
    AE18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac18 = this.AC18(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(ac18, 2))) / (1 - u3 * Math.abs(u3));
        return result;
    }
    AF18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ac18 = this.AC18(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u5 - Math.sqrt(u5 * u5 - (1 - u7 * Math.abs(u7)) * Math.pow(ac18, 2))) / (1 - u7 * Math.abs(u7));
        return result;
    }
    AG18(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae18 = this.AE18(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af18 = this.AF18(worksheet, troubleshoot, troubleshoot2);
        const result = 1000 * (ae18 + y5 / 1000 - af18);
        return result;
    }
    AI18 = this.U17;
    C19 = 0.0;
    I19 = this.K21;
    J19 = this.N21;
    K19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const result =
            this.C6(worksheet, troubleshoot, troubleshoot2) / 2 +
            (this.C10(worksheet, troubleshoot, troubleshoot2) * 1) / 5;
        return result;
    }
    L19 = this.O19;
    M19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k19 = this.K19(worksheet, troubleshoot, troubleshoot2);
        const result = (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(k19, 2))) / (1 - c3 * Math.abs(c3));
        return result;
    }
    N19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const q10 = this.Q10(worksheet, troubleshoot, troubleshoot2);
        const c11 = this.C11;
        const k19 = this.K19(worksheet, troubleshoot, troubleshoot2);

        const result =
            (c5 - Math.sqrt(c5 * c5 - (1 - c7 * Math.abs(c7)) * Math.pow(c6 / 2, 2))) / (1 - c7 * Math.abs(c7)) +
            (Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * Math.pow(c6 / 2, 2)) / (1 - c11 * Math.abs(c11)) -
                Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * Math.pow(k19, 2)) / (1 - c11 * Math.abs(c11)));

        return result;
    }
    O19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m19 = this.M19(worksheet, troubleshoot, troubleshoot2);
        const g5 = this.G5(worksheet, troubleshoot, troubleshoot2);
        const n19 = this.N19(worksheet, troubleshoot, troubleshoot2);

        const result = 1000 * (m19 + g5 / 1000 - n19);
        return result;
    }
    Q19 = 0.0;
    AA19 = this.AC21;
    AB19 = this.AF21;
    AC19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);

        const result = u6 / 2 + (u10 * 1) / 5;

        return result;
    }
    AD19 = this.AG19;

    AE19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac19 = this.AC19(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(ac19, 2))) / (1 - u3 * Math.abs(u3));
        return result;
    }
    AF19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const ai10 = this.AI10(worksheet, troubleshoot, troubleshoot2);
        const u11 = this.U11;
        const ac19 = this.AC19(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u5 - Math.sqrt(u5 * u5 - (1 - u7 * Math.abs(u7)) * Math.pow(u6 / 2, 2))) / (1 - u7 * Math.abs(u7)) +
            (Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * (((u6 / 2) * u6) / 2)) / (1 - u11 * Math.abs(u11)) -
                Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * Math.pow(ac19, 2)) / (1 - u11 * Math.abs(u11)));

        return result;
    }
    AG19(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae19 = this.AE19(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af19 = this.AF19(worksheet, troubleshoot, troubleshoot2);
        return 1000 * (ae19 + y5 / 1000 - af19);
    }
    // I20 = this.K22;
    // J20 = this.N22;
    I20 = 99;
    J20 = 99;
    K20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        return c6 / 2 + (c10 * 2) / 5;
    }
    L20 = this.O20;
    M20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k20 = this.K20(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(k20, 2))) / (1 - c3 * Math.abs(c3));
        return result;
    }
    N20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const q10 = this.Q10(worksheet, troubleshoot, troubleshoot2);
        const c11 = this.C11;
        const k20 = this.K20(worksheet, troubleshoot, troubleshoot2);

        const result =
            (c5 - Math.sqrt(c5 * c5 - (1 - c7 * Math.abs(c7)) * Math.pow(c6 / 2, 2))) / (1 - c7 * Math.abs(c7)) +
            (Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * (((c6 / 2) * c6) / 2)) / (1 - c11 * Math.abs(c11)) -
                Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * Math.pow(k20, 2)) / (1 - c11 * Math.abs(c11)));

        return result;
    }

    O20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m20 = this.M20(worksheet, troubleshoot, troubleshoot2);
        const g5 = this.G5(worksheet, troubleshoot, troubleshoot2);
        const n20 = this.N20(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (m20 + g5 / 1000 - n20);
    }
    // AA20 = this.AC22;
    // AB20 = this.AF22;
    AA20 = 99;
    AB20 = 99;
    AC20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);
        return u6 / 2 + (u10 * 2) / 5;
    }
    AD20 = this.AG20;
    AE20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const ac20 = this.AC20(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(ac20, 2))) / (1 - u3 * Math.abs(u3));
        return result;
    }
    AF20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const ai10 = this.AI10(worksheet, troubleshoot, troubleshoot2);
        const u11 = this.U11;
        const ac20 = this.AC20(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u5 - Math.sqrt(u5 * u5 - (1 - u7 * Math.abs(u7)) * Math.pow(u6 / 2, 2))) / (1 - u7 * Math.abs(u7)) +
            (Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * (((u6 / 2) * u6) / 2)) / (1 - u11 * Math.abs(u11)) -
                Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * Math.pow(ac20, 2)) / (1 - u11 * Math.abs(u11)));

        return result;
    }
    AG20(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const ae20 = this.AE20(worksheet, troubleshoot, troubleshoot2);
        const y5 = this.Y5(worksheet, troubleshoot, troubleshoot2);
        const af20 = this.AF20(worksheet, troubleshoot, troubleshoot2);

        return 1000 * (ae20 + y5 / 1000 - af20);
    }
    C21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c14 = this.C14(worksheet);
        const c18 = this.C18(worksheet, troubleshoot, troubleshoot2);
        const c22 = this.C22(worksheet, troubleshoot, troubleshoot2);
        const g17 = this.G17(worksheet, troubleshoot, troubleshoot2);
        const g21 = this.workbook.WSg82(worksheet, troubleshoot, troubleshoot2);
        const c23 = this.C23;

        let result = Math.sqrt(((((c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18+c22,2)))/(1-(c3)*Math.abs((c3)))-(c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18,2)))/(1-(c3)*Math.abs((c3)))+g17/1000-g21/1000)**4)*(1-Math.abs(c23)*c23)**2+((c6/2+c10+c14+c18)**2+(c6/2+c10+c14+c18+c22)**2)**2+2*((c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18+c22,2)))/(1-(c3)*Math.abs((c3)))-(c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18,2)))/(1-(c3)*Math.abs((c3)))+g17/1000-g21/1000)**2*((c6/2+c10+c14+c18)**2+(c6/2+c10+c14+c18+c22)**2)*(1-Math.abs(c23)*c23)-4*(c6/2+c10+c14+c18)**2*(c6/2+c10+c14+c18+c22)**2)/(4*((c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18+c22,2)))/(1-(c3)*Math.abs((c3)))-(c2-Math.sqrt(c2*c2-(1-(c3)*Math.abs((c3)))*Math.pow(c6/2+c10+c14+c18,2)))/(1-(c3)*Math.abs((c3)))+g17/1000-g21/1000)**2));

        return result;
    }
    G21 = this.workbook.WSg82;
    I21 = this.K23;
    J21 = this.N23;
    K21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            this.C6(worksheet, troubleshoot, troubleshoot2) / 2 +
            (this.C10(worksheet, troubleshoot, troubleshoot2) * 3) / 5
        );
    }
    L21 = this.O21;
    M21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c2 = this.C2(worksheet);
        const c3 = this.C3(worksheet, troubleshoot, troubleshoot2);
        const k21 = this.K21(worksheet, troubleshoot, troubleshoot2);

        const result = (c2 - Math.sqrt(c2 * c2 - (1 - c3 * Math.abs(c3)) * Math.pow(k21, 2))) / (1 - c3 * Math.abs(c3));
        return result;
    }
    N21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const q10 = this.Q10(worksheet, troubleshoot, troubleshoot2);
        const c11 = this.C11;
        const k21 = this.K21(worksheet, troubleshoot, troubleshoot2);

        const result =
            (c5 - Math.sqrt(c5 * c5 - (1 - c7 * Math.abs(c7)) * Math.pow(c6 / 2, 2))) / (1 - c7 * Math.abs(c7)) +
            (Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * (((c6 / 2) * c6) / 2)) / (1 - c11 * Math.abs(c11)) -
                Math.sqrt(q10 * q10 - (1 - c11 * Math.abs(c11)) * Math.pow(k21, 2)) / (1 - c11 * Math.abs(c11)));

        return result;
    }
    //   //"=((C5-SQRT(C5*C5-(1-C7*ABS(C7))*POWER(C6/2,2)))/(1-C7*ABS(C7)))+((SQRT(Q10*Q10-((1-C11*ABS(C11))*(C6/2*C6/2)))/(1-C11*ABS(C11)))-(SQRT((Q10*Q10-(1-C11*ABS(C11))*POWER(K21,2)))/(1-C11*ABS(C11))))",
    O21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const m21: number = this.M21(worksheet, troubleshoot, troubleshoot2);
        const g5: number = this.G5(worksheet, troubleshoot, troubleshoot2);
        const n21: number = this.N21(worksheet, troubleshoot, troubleshoot2);

        const result = 1000 * (m21 + g5 / 1000 - n21);
        return result;
    }

    // U21(
    //   worksheet: EbcWorksheet,
    //   troubleshoot: Troubleshoot,
    //   troubleshoot2: Troubleshoot
    // ) {
    //   const u2 = this.U2(worksheet);
    //   const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
    //   const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
    //   const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);
    //   const u14 = this.U14(worksheet);
    //   const u18 = this.U18(worksheet, troubleshoot, troubleshoot2);
    //   const u22 = this.U22();
    //   const y17 = this.Y17(worksheet, troubleshoot, troubleshoot2);
    //   const y21 = this.Y21(worksheet, troubleshoot, troubleshoot2);
    //   const u23 = this.U23(worksheet, troubleshoot, troubleshoot2);

    //   const result = Math.sqrt(
    //     ((u2 -
    //       Math.sqrt(
    //         u2 * u2 -
    //           (1 - u3 * Math.abs(u3)) *
    //             Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)
    //       )) /
    //       (1 - u3 * Math.abs(u3)) -
    //       (u2 -
    //         Math.sqrt(
    //           u2 * u2 -
    //             (1 - u3 * Math.abs(u3)) * Math.pow(u6 / 2 + u10 + u14 + u18, 2)
    //         )) /
    //         (1 - u3 * Math.abs(u3)) +
    //       y17 / 1000 -
    //       y21 / 1000) **
    //       4 *
    //       (1 - Math.abs(u23) * u23) ** 2 +
    //       ((Math.pow(u6 / 2 + u10 + u14 + u18, 2) +
    //         Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)) **
    //         2 +
    //         2 *
    //           ((u2 -
    //             Math.sqrt(
    //               u2 * u2 -
    //                 (1 - u3 * Math.abs(u3)) *
    //                   Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)
    //             )) /
    //             (1 - u3 * Math.abs(u3)) -
    //             (u2 -
    //               Math.sqrt(
    //                 u2 * u2 -
    //                   (1 - u3 * Math.abs(u3)) *
    //                     Math.pow(u6 / 2 + u10 + u14 + u18, 2)
    //               )) /
    //               (1 - u3 * Math.abs(u3)) +
    //             y17 / 1000 -
    //             y21 / 1000) **
    //             2 *
    //           (Math.pow(u6 / 2 + u10 + u14 + u18, 2) +
    //             Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)) *
    //           (1 - Math.abs(u23) * u23) -
    //         4 *
    //           Math.pow(u6 / 2 + u10 + u14 + u18, 2) *
    //           Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)) /
    //         (4 *
    //           ((u2 -
    //             Math.sqrt(
    //               u2 * u2 -
    //                 (1 - u3 * Math.abs(u3)) *
    //                   Math.pow(u6 / 2 + u10 + u14 + u18 + u22, 2)
    //             )) /
    //             (1 - u3 * Math.abs(u3)) -
    //             (u2 -
    //               Math.sqrt(
    //                 u2 * u2 -
    //                   (1 - u3 * Math.abs(u3)) *
    //                     Math.pow(u6 / 2 + u10 + u14 + u18, 2)
    //               )) /
    //               (1 - u3 * Math.abs(u3)) +
    //             y17 / 1000 -
    //             y21 / 1000) **
    //             2)
    //   );

    //   return result;
    // }
    U21 = 99;
    Y21 = this.G21;
    AA21 = 99;
    AB21 = this.AF23;
    AC21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u10 = this.U10(worksheet, troubleshoot, troubleshoot2);

        const result = u6 / 2 + (u10 * 3) / 5;
        return result;
    }
    // AD21 = this.AG21;
    AD21 = 99;
    AE21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u2 = this.U2(worksheet);
        const u3 = this.U3(worksheet, troubleshoot, troubleshoot2);
        const AC21 = this.AC21(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u2 - Math.sqrt(u2 * u2 - (1 - u3 * Math.abs(u3)) * Math.pow(AC21, 2))) / (1 - u3 * Math.abs(u3));

        return result;
    }
    AF21(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const ai10 = this.AI10(worksheet, troubleshoot, troubleshoot2);
        const u11 = this.U11;
        const ac21 = this.AC21(worksheet, troubleshoot, troubleshoot2);
        const result =
            (u5 - Math.sqrt(u5 * u5 - (1 - u7 * Math.abs(u7)) * Math.pow(u6 / 2, 2))) / (1 - u7 * Math.abs(u7)) +
            (Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * Math.pow(u6 / 2, 2)) / (1 - u11 * Math.abs(u11)) -
                Math.sqrt(ai10 * ai10 - (1 - u11 * Math.abs(u11)) * Math.pow(ac21, 2)) / (1 - u11 * Math.abs(u11)));

        return result;
    }
    AB26 = this.AF28;
    AF28(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;
        const ai14 = this.AI14(worksheet, troubleshoot, troubleshoot2);
        const u15 = this.U15;
        const ac23 = this.AC23(worksheet, troubleshoot, troubleshoot2);
        const af23 = this.AF23(worksheet, troubleshoot, troubleshoot2);
        const ac28 = this.AC28(worksheet, troubleshoot, troubleshoot2);

        const part1 = sqrt(ai14 * ai14 - (1 - u15 * abs(u15)) * ac23 ** 2) / (1 - u15 * abs(u15));
        const part2 = sqrt(ai14 * ai14 - (1 - u15 * abs(u15)) * power(ac28, 2)) / (1 - u15 * abs(u15));

        const result = af23 + (part1 - part2);

        return result;
    }
    AC23 = this.U6;
    AF23(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const u5 = this.U5(worksheet, troubleshoot, troubleshoot2);
        const u6 = this.U6(worksheet, troubleshoot, troubleshoot2);
        const u7 = this.U7(worksheet, troubleshoot, troubleshoot2);
        const ai10 = this.AI10(worksheet, troubleshoot, troubleshoot2);
        const u11 = this.U11;
        const ac23 = this.AC23(worksheet, troubleshoot, troubleshoot2);

        const result =
            (u5 - sqrt(u5 * u5 - (1 - u7 * abs(u7)) * power(u6 / 2, 2))) / (1 - u7 * abs(u7)) +
            (sqrt(ai10 * ai10 - (1 - u11 * abs(u11)) * (((u6 / 2) * u6) / 2)) / (1 - u11 * abs(u11)) -
                sqrt(ai10 * ai10 - (1 - u11 * abs(u11)) * power(ac23, 2)) / (1 - u11 * abs(u11)));

        return result;
    }
    AC28(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            this.U6(worksheet, troubleshoot, troubleshoot2) / 2 +
            this.U10(worksheet, troubleshoot, troubleshoot2) +
            this.U14(worksheet)
        );
    }
    J26 = this.N28;
    N28(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const n23: number = this.N23(worksheet, troubleshoot, troubleshoot2);
        const q14: number = this.Q14(worksheet, troubleshoot, troubleshoot2);
        const c15: number = this.C15;
        const k23: number = this.K23(worksheet, troubleshoot, troubleshoot2);
        const k28: number = this.K28(worksheet, troubleshoot, troubleshoot2);

        const part1 = sqrt(q14 * q14 - (1 - c15 * abs(c15)) * k23 ** 2) / (1 - c15 * abs(c15));
        const part2 = sqrt(q14 * q14 - (1 - c15 * abs(c15)) * power(k28, 2)) / (1 - c15 * abs(c15));

        const result = n23 + (part1 - part2);

        return result;
    }

    N23(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const c5 = this.C5(worksheet, troubleshoot, troubleshoot2);
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c7 = this.C7(worksheet, troubleshoot, troubleshoot2);
        const c11 = this.C11;
        const q10 = this.Q10(worksheet, troubleshoot, troubleshoot2);
        const k23 = this.K23(worksheet, troubleshoot, troubleshoot2);

        const part1 = (c5 - sqrt(c5 * c5 - (1 - c7 * abs(c7)) * power(c6 / 2, 2))) / (1 - c7 * abs(c7));
        const part2 =
            sqrt(q10 * q10 - (1 - c11 * abs(c11)) * ((c6 / 2) * (c6 / 2))) / (1 - c11 * abs(c11)) -
            sqrt(q10 * q10 - (1 - c11 * abs(c11)) * power(k23, 2)) / (1 - c11 * abs(c11));

        const result = part1 + part2;
        return result;
    }

    K23(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);

        const result = c6 / 2 + c10;
        return result;
    }

    K28(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c14 = this.C14(worksheet);

        const result = c6 / 2 + c10 + c14;
        return result;
    }

    J36 = this.N38;
    N38(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const n33: number = this.N33(worksheet, troubleshoot, troubleshoot2);
        const q22: number = this.Q22(worksheet, troubleshoot, troubleshoot2);
        const c23 = this.C23;
        const k33: number = this.K33(worksheet, troubleshoot, troubleshoot2);
        const k38: number = this.K38(worksheet, troubleshoot, troubleshoot2);

        const part1 = Math.sqrt(q22*q22-(1-0*Math.abs(c23))*(k33)**2)/(1-c23*Math.abs(c23));
        const part2 = Math.sqrt(q22*q22-(1-c23*Math.abs(c23))*Math.pow(k38,2))/(1-c23*Math.abs(c23));
        const part3 = (n33 + part1);
        const part4 = part3 - part2;
        return calculation.cropValueToMaxExcelDecimalPoints(part4, 9);
    }

    K38(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c6: number = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10: number = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c14: number = this.C14(worksheet);
        const c18: number = this.C18(worksheet, troubleshoot, troubleshoot2);
        const c22: number = this.C22(worksheet, troubleshoot, troubleshoot2);

        return c6 / 2 + c10 + c14 + c18 + c22;
    }
    C23 = 0;
    Q22 = this.C21;
    N33(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sqrt = Math.sqrt;
        const abs = Math.abs;
        const power = Math.pow;

        const n28 = this.N28(worksheet, troubleshoot, troubleshoot2);
        const q18: number = this.Q18(worksheet, troubleshoot, troubleshoot2);
        const c19: number = this.C19;
        const k28: number = this.K28(worksheet, troubleshoot, troubleshoot2);
        const k33: number = this.K33(worksheet, troubleshoot, troubleshoot2);

        const result =
            n28 +
            sqrt(q18 * q18 - (1 - c19 * abs(c19)) * power(k28, 2)) / (1 - c19 * abs(c19)) -
            sqrt(q18 * q18 - (1 - c19 * abs(c19)) * power(k33, 2)) / (1 - c19 * abs(c19));

        return result;
    }

    K33(worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const c6 = this.C6(worksheet, troubleshoot, troubleshoot2);
        const c10 = this.C10(worksheet, troubleshoot, troubleshoot2);
        const c14 = this.C14(worksheet);
        const c18 = this.C18(worksheet, troubleshoot, troubleshoot2);

        const result = c6 / 2 + c10 + c14 + c18;
        return result;
    }
}
