import { createAction } from '@ngrx/store';

import { ActionType, InUseLensePerEye, OrderListItem } from '../models/patient-profile.model';
import { GetOrdersOfAPatientQueryVariables } from '@app/generated/graphql';

export const getListOfOrdersOfPatient = createAction(
  ActionType.getListOfOrdersOfPatient,
  (params: Partial<GetOrdersOfAPatientQueryVariables>) => ({ params })
);

export const getListOfOrdersOfPatientSuccess = createAction(
  ActionType.getListOfOrdersOfPatientSuccess,
  (orderList: OrderListItem[]) => ({ orderList })
);

export const getInUseLenseDetails = createAction(
  ActionType.getInUseLenseDetails,
  (inUseLenseDetails: InUseLensePerEye[] | null) => ({ inUseLenseDetails })
);

export const resetPatientProfileState = createAction(
  ActionType.resetPatientProfileState
);
