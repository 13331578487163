import { Component, Input, OnInit } from '@angular/core';

import { CredentialsService } from '@app/core/services/credentials.service';
import { Column } from '@app/shared/models/user-table-list.model';

@Component({
    selector: 'app-dashboard-user-listing-table',
    templateUrl: './dashboard-user-listing-table.component.html',
    styleUrls: ['./dashboard-user-listing-table.component.scss'],
})
export class DashboardUserListingTableComponent implements OnInit {
    currentRole!: string;
    allowLogoForTableDataList = false;

    @Input() tableColumns: Array<Column> = [];
    @Input() tableData: any[] = [];
    @Input() collectionSize = 0;
    @Input() currentTable = '';

    constructor(private _credentialService: CredentialsService) {}

    ngOnInit(): void {
        this.currentRole = this._credentialService?.credentials?.role || '';
        this.permitToShowLogo();
    }

    /**
     * Show and hide user logo for patient and doctor list tables
     */
    permitToShowLogo(){
        if(this.currentTable === 'doctors' || this.currentTable === 'patients'){
            this.allowLogoForTableDataList = true
        }
        else{
            this.allowLogoForTableDataList = false
        }
    }
}
