import { Action, createReducer, on } from '@ngrx/store';

import { getDesignerTableListSuccess, getTotalTableItemsCountSuccess } from '../actions/designers.action';
import { initialState, State } from '../models/designers.model';
import { GetDesignerTableListQuery } from '@app/generated/graphql';
import { resetAppState } from '@app/actions';

const _reducer = createReducer(
    initialState,
    on(getDesignerTableListSuccess, (state, payload: { designerList: GetDesignerTableListQuery }) => ({
        ...state,
        designerTableList: payload.designerList
    })),
    on(getTotalTableItemsCountSuccess, (state, payload: { tableItemCount: number }) => ({
        ...state,
        totalTableListCount: payload.tableItemCount
    })),
    on(resetAppState, () => initialState)
);

export function designersReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
