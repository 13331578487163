import { Action, createReducer, on } from '@ngrx/store';

import { TreatmentHistory } from '@app/core/util/common';
import { Order_Type_Enum } from '@app/generated/graphql';
import { eye } from '@app/core/treatment-engine/interfaces';
import { initialState, State, TreatmentMethod } from '../models/patients.model';
import {
    clearFacdDataSuccess,
    getDoctorListSuccess,
    getPatientInfoForTreatmentSuccess,
    getRegularClinicListSuccess,
    getSavedFacdDataSucess,
    onSaveFacdQuestionsData,
    saveFacdQuestionsToState,
    getPatientListSuccess,
    totalCountFetchSuccess,
    storeCurrentPatientId,
    clearPatientDataSuccess,
    distributorIdFetchSuccess,
    addDoctorAndLabFormDataToStore,
    getLatestOrderDetailOfPatientSuccess,
    getLatestOrderDetailOfPatientFailure,
    getPreviousLatestOrderDetailsSuccess,
    getPreviousLatestOrderDetailsFailure,
    storeTreatmentHistoryByDoctor,
    initializeCurrentTreatmentSelection,
    saveAmendQuestionsToState,
    clearAmendQuestionsStored,
    getPreviousFacdForAmendTreatmentSuccess,
    updatePrefillFormDataStatus,
    getListOfOrdersOfPatientSuccess,
    getPreviousSatForAmendTreatmentSuccess,
    loadPatientTransferRequestListSuccess,
    loadPatientTransferRequestListCountSuccess,
    storeLastPayloadForPatientTransferRequestListAndCount,
    addPatientFormAsDraft,
    getDesignerIdByDoctorIdSuccess,
    getControlPanelValuesByDesignerIdSuccess,
    getContractManufacturerListSuccess,
    getOrderSequencesSuccess,
    getLensListSuccess,
    getSatDataSuccess,
    getMatDataSuccess,
    getFranchiseClinicListSuccess,
    getFranchiseClinicDoctorsListSuccess,
    getDistributorTypeBasedOnDoctorIdSuccess,
    updateLoopToMatchCorrectness,
    updateLabSelected,
    clearLensList,
    getManufacturerHistoryPerPatientSuccess,
    updatePatientListActiveItems,
    getOldPatientListSuccess,
    updateOldPatientTotalCount,
    updateCareOfPatient,
    getTransferredPatientDetailsSuccess,
    resetPatientList,
    resetTotalTableItemsCount,
} from '../actions/patients.action';
import { initializeAmendDetails, initializeFacdDetails } from '@app/core/util/treatment-output-details';
import { resetAppState } from '@app/actions';

const _reducer = createReducer(
    initialState,
    on(getRegularClinicListSuccess, (state, payload) => ({
        ...state,
        regularClinicList: payload?.regularClinicList,
    })),
    on(getDoctorListSuccess, (state, payload) => ({
        ...state,
        doctorsList: payload?.doctorsList,
    })),
    on(onSaveFacdQuestionsData, (state, payload) => ({
        ...state,
        facdFormData: payload?.facdFormData,
    })),
    on(getSavedFacdDataSucess, saveFacdQuestionsToState, (state, payload) => ({
        ...state,
        facdFormData: payload?.facdData,
    })),
    on(addDoctorAndLabFormDataToStore, (state, payload) => ({
        ...state,
        doctorSlipDetails: payload.doctorSlipData,
        labFormDetails: payload.labFormDetails,
    })),
    on(clearFacdDataSuccess, (state) => ({
        ...state,
        facdFormData: initializeFacdDetails,
    })),
    on(getPatientInfoForTreatmentSuccess, (state, payload) => ({
        ...state,
        patientInfo: payload.patientInfo,
    })),
    on(getPatientListSuccess, (state, payload) => ({
        ...state,
        patientList: payload?.patientList,
    })),
    on(resetPatientList, (state) => ({
      ...state,
      patientList: null,
      totalTableListCount: null
    })),
    on(totalCountFetchSuccess, (state, { tableItemCount }) => ({
        ...state,
        totalTableListCount: tableItemCount,
    })),
    on(resetTotalTableItemsCount, (state) => ({
      ...state,
      totalOldPatientCount: null
    })),
    on(storeCurrentPatientId, (state, payload) => ({
        ...state,
        patientId: payload.patientId,
    })),
    on(clearPatientDataSuccess, (state) => ({
        ...state,
        patientId: null,
    })),
    on(distributorIdFetchSuccess, (state, payload) => ({
        ...state,
        distributorIdOfClinic: payload.distributorId,
    })),
    on(getLatestOrderDetailOfPatientSuccess, (state, payload) => ({
        ...state,
        latestOrderDetailsOfPatient: payload.orderDetails,
    })),
    on(getLatestOrderDetailOfPatientFailure, (state, payload) => ({
        ...state,
        latestOrderDetailsOfPatient: payload.orderDetails,
    })),
    on(getPreviousLatestOrderDetailsSuccess, (state, payload) => ({
        ...state,
        treatmentHistory: payload.treatmentHistory,
    })),
    on(getPreviousLatestOrderDetailsFailure, (state, payload) => ({
        ...state,
        treatmentHistory: { order: [] },
    })),
    on(storeTreatmentHistoryByDoctor, (state, payload) => ({
        ...state,
        currentTreatmentBasicDetails: payload.treatmentTarget,
    })),
    on(initializeCurrentTreatmentSelection, (state) => ({
        ...state,
        currentTreatmentBasicDetails: {
            formType: TreatmentHistory.EMPTY,
            isPrefilledView: false,
            treatmentProcedure: TreatmentMethod.EMPTY,
            facdId: '',
            satId: '',
            matId: '',
            eye: eye.empty,
            fetchAndView: false,
            orderType: Order_Type_Enum.New,
            orderSequence: {left: 0, right: 0},
            contractManufacturerId: '',
            lens: {
              right: '',
              left: ''
            }
        },
    })),
    on(saveAmendQuestionsToState, (state, payload) => ({
        ...state,
        amendFormData: payload?.amendData,
    })),
    on(clearAmendQuestionsStored, (state) => ({
        ...state,
        amendFormData: initializeAmendDetails,
    })),
    on(getPreviousFacdForAmendTreatmentSuccess, (state, payload) => ({
        ...state,
        facdForAmendFormDoctorSlipGeneration: payload.prevFacdData,
    })),
    on(updatePrefillFormDataStatus, (state, payload: { prefillStatus: boolean }) => ({
        ...state,
        currentTreatmentBasicDetails: {
            ...state.currentTreatmentBasicDetails,
            isPrefilledView: payload.prefillStatus,
        },
    })),
    on(getListOfOrdersOfPatientSuccess, (state, payload) => ({
        ...state,
        ordersList: payload.ordersList,
    })),
    on(getOrderSequencesSuccess, (state, { orderSequences }) => ({
      ...state,
      orderSequences
    })),
    on(getPreviousSatForAmendTreatmentSuccess, (state, payload) => ({
        ...state,
        satForAmendFormDoctorSlipGeneration: payload.prevSatData,
    })),
    on(loadPatientTransferRequestListSuccess, (state, { patientTransferRequestList }) => ({
        ...state,
        patientTransferRequestList,
    })),
    on(loadPatientTransferRequestListCountSuccess, (state, { totalPatientTransferRequestListCount }) => {
        return { ...state, totalPatientTransferRequestListCount };
    }),
    on(
        storeLastPayloadForPatientTransferRequestListAndCount,
        (state, { lastPayloadForPatientTransferRequestListAndCount }) => ({
            ...state,
            lastPayloadForPatientTransferRequestListAndCount: {
                list: lastPayloadForPatientTransferRequestListAndCount.list,
                count: lastPayloadForPatientTransferRequestListAndCount.count,
            },
        })
    ),
    on(
      addPatientFormAsDraft,
      (state, {isDraft}) => ({
        ...state,
        savePatientTreatmentFormAsDraft: isDraft
      })
    ),
    on(getDesignerIdByDoctorIdSuccess, (state, payload) => ({
        ...state,
        designerId: payload.designerId,
    })),
    on(getControlPanelValuesByDesignerIdSuccess, (state, payload) => ({
        ...state,
        getControlPanelValuesByDesignerId: payload.getControlPanelValuesByDesignerId,
    })),
    on(getContractManufacturerListSuccess, (state, {contractManufacturerList}) => ({
      ...state,
      contractManufacturerList
    })),
    on(getLensListSuccess, (state, {lensList}) => ({
      ...state,
      lensList
    })),
    on(clearLensList, (state) => ({
      ...state,
      lensList: null
    })),
    on(getSatDataSuccess, (state, {satData}) => ({
      ...state,
      satData
    })),
    on(getMatDataSuccess, (state, {matData}) => ({
      ...state,
      matData
    })),
    on(getFranchiseClinicListSuccess, (state, payload) => ({
      ...state,
      franchiseClinicList: payload?.franchiseClinicList,
    })),
    on(getFranchiseClinicDoctorsListSuccess, (state, payload) => ({
      ...state,
      franchiseClinicDoctorsList: payload?.franchiseClinicDoctorsList,
    })),
    on(getDistributorTypeBasedOnDoctorIdSuccess, (state, {distributorType}) => ({
        ...state,
        distributorType
    })),
    on(updateLoopToMatchCorrectness, (state, {loopToMatchCorrectness}) => ({
        ...state,
        loopToMatchCorrectness
    })),
    on(updateLabSelected, (state, { contractManufacturerId }) => ({
      ...state,
      currentTreatmentBasicDetails: {
          ...state.currentTreatmentBasicDetails,
          contractManufacturerId,
      },
    })),
    on(getManufacturerHistoryPerPatientSuccess, (state, { manufacturerHistoryPerPatient }) => ({
      ...state,
      manufacturerHistoryPerPatient
    })),
    on(updatePatientListActiveItems, (state: State, {listActiveItems}) => ({
      ...state,
      listActiveItems
    })),
    on(getOldPatientListSuccess, (state, { oldPatientList })  => ({
      ...state,
      oldPatientList
    })),
    on(updateCareOfPatient, (state, { careOfPatient }) => ({
      ...state,
      careOfPatient
    })),
    on(getTransferredPatientDetailsSuccess, (state, { patientTransferDetails }) => ({
      ...state,
      patientTransferDetails
    })),
    on(updateOldPatientTotalCount, (state, {totalOldPatientCount}) => ({
      ...state,
      totalOldPatientCount
    })),
    on(resetAppState, () => initialState)
);

export function patientsReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
