import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-password-criteria-popover',
  templateUrl: './password-criteria-popover.component.html',
  styleUrls: ['./password-criteria-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordCriteriaPopoverComponent {

}
