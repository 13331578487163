import { RouteInfo } from "@app/shell/models/admin.model";

export const ROUTES: RouteInfo[] = [
    {
        path: '/user/dashboard',
        title: 'Home',
        iconClass: 'bi bi-columns',
        class: '',
        iconSrc: 'dashboard',
        iconSrcHover: 'dashboard-white',
        permissionName: 'show_home_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/designers',
        title: 'Designers',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'designers',
        iconSrcHover: 'designers-white',
        permissionName: 'show_designers_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/exclusive-distributors',
        title: 'Exclusive Distributors',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'distributors',
        iconSrcHover: 'distributors-white',
        permissionName: 'show_exclusive_distributors_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/selective-distributors',
        title: 'Selective Distributors',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'distributors',
        iconSrcHover: 'distributors-white',
        permissionName: 'show_selective_distributors_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/contracted-manufacturers',
        title: 'Contract Manufacturers',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'manufacturers',
        iconSrcHover: 'manufactures-white',
        permissionName: 'show_contract_manufacturers_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '',
        title: 'Partner',
        iconClass: 'bi-person-circle',
        class: '',
        notClickable: true,
        iconSrc: 'clinics',
        iconSrcHover: 'clinics-white',
        showDownArrowIcon: true,
        showUpArrowIcon: false,
        permissionName: 'main_item',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/franchise-clinics',
        title: 'Gold Partners',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: '',
        iconSrcHover: '',
        isChildLink: true,
        ChildLinkOf: 'Partner',
        hideLink: false,
        permissionName: 'show_franchise_clinics_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/regular-clinics',
        title: 'Partners',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: '',
        iconSrcHover: '',
        isChildLink: true,
        ChildLinkOf: 'Partner',
        hideLink: false,
        permissionName: 'show_regular_clinics_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/doctors',
        title: 'Practitioners',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'doctors',
        iconSrcHover: 'doctors-white',
        permissionName: 'show_doctors_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/patients',
        title: 'Patients',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'patients',
        iconSrcHover: 'patients-white',
        permissionName: 'show_patients_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/orders',
        title: 'Orders',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'orders',
        iconSrcHover: 'orders-white',
        permissionName: 'show_orders_menu',
        isViewableByCurrentRole: false,
        showSideMenu:false,
    },
    {
        path: '/user/lenses',
        title: 'Lenses',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'lenses',
        iconSrcHover: 'lenses-white',
        permissionName: 'show_lens_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/control-panel',
        title: 'Control Panel',
        iconClass: 'bi-person-circle',
        class: '',
        iconSrc: 'settings',
        iconSrcHover: 'settings-white',
        permissionName: 'show_control_panel_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
      path: '/user/patient-profile',
      title: 'Patient Info',
      iconClass: 'bi-person-circle',
      class: '',
      iconSrc: 'user-profile-info',
      iconSrcHover: 'user-profile-info-white',
      permissionName: 'show_patient_info_in_patient_side',
      isViewableByCurrentRole: false,
      showSideMenu: false,
    },
    {
        path: '/user/eye-info',
        title: 'Eye Info',
        iconClass: '',
        class: '',
        iconSrc: 'lenses',
        iconSrcHover: 'lenses-white',
        permissionName: 'show_patient_orders_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false,
    },
    {
        path: '/user/treatment-review',
        title: 'Treatment Review',
        iconClass: '',
        class: '',
        iconSrc: 'review-history',
        iconSrcHover: 'review-history-white',
        permissionName: 'show_treatment_review_menu',
        isViewableByCurrentRole: false,
        showSideMenu: false
    }
];
