import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ElementType } from '@app/shared/models/user-table-list.model';
import { FilterTypesEnum, manufacturerStatusHierarchyArray } from '@app/core/util/common';
import { InsertOrderStatusToTrackingData, bulkUpdateOrderStatus, saveAppliedFilters } from '@app/shared/actions/shared.action';
import { FilterParams, FilterTypesRef, ManufacturerStatusHierarchy, OrderDetails } from '@app/shared/models/shared.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { manufacturerOrderListHeadingsUnderDistributor, manufacturerStatus } from '@app/core/util/initial-values';
import {
    BulkUpdateOrderStatusMutationVariables,
    GetOrdersListBasedOnDistributorIdQueryVariables,
    Manufacturer_Status_Enum,
    Order_By,
    Order_Status_Enum,
    Order_Status_Info_Insert_Input,
} from '@app/generated/graphql';
import { FilterComponent } from '../filter/filter.component';

@Component({
    selector: 'app-manufacturer-order-details',
    templateUrl: './manufacturer-order-details.component.html',
    styleUrls: ['./manufacturer-order-details.component.scss'],
})
export class ManufacturerOrderDetailsComponent implements OnInit, OnDestroy {
    clearCheckedList = false;
    selectedItems: OrderDetails[] = [];
    manufacturerStatus = manufacturerStatus;
    tableColumns = manufacturerOrderListHeadingsUnderDistributor;
    orderStatusControl = new FormControl({ value: '', disabled: true });
    orderDetails: Array<Order_Status_Info_Insert_Input> = [];
    private searchDebouncer$: Subject<string> = new Subject();
    filterTypes: FilterTypesRef[] = [
      { name: 'Order Type', value: FilterTypesEnum.ORDER_TYPE },
      { name: 'Manufacturer Status', value: FilterTypesEnum.MANUFACTURER_STATUS },
    ];
    showClearFilterIcon = false;

    @Input() isViewOnly = false;
    @Input() totalDataCount = 0;
    @Input() tableData: any = [];
    @Input() orderListingParams!: GetOrdersListBasedOnDistributorIdQueryVariables;
    @Output() emitOrderListingParams = new EventEmitter<GetOrdersListBasedOnDistributorIdQueryVariables>();
    @Output() searchOrderEvent = new EventEmitter<string>();
    @Output() onPageChangeEvent = new EventEmitter<number>();
    @Output() onLimitChangeEvent = new EventEmitter<number>();
    @Output() navigateWithActionEvent = new EventEmitter<any>();
    @Output() emitFilterParams = new EventEmitter<FilterParams>();
    @Output() emitClearFilter = new EventEmitter();
    @ViewChild('confirmationDialogCmp') confirmationDialogCmp!: ConfirmationDialogComponent;

    constructor(private _store: Store, private _toast: ToastrService, private _modalService: NgbModal,) {}

    ngOnInit(): void {
        this.setupSearchDebouncer();
        this.updateSelectCheckboxPermission();
    }

    /**
     * Debouncer to do search after a wait time
     * Using event emitter value is passed to parent component
     * @param Nil
     * @returns void
     */
    setupSearchDebouncer = (): void => {
        this.searchDebouncer$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term: string) => {
            const searchTerm: string = '%' + term?.trim() + '%';
            this.searchOrderEvent.emit(searchTerm);
        });
    };

    /**
     * Emit new value when input is entered
     * @param event Search Event
     */
    onSearchInputChange = (event: Event): void => {
        this.searchDebouncer$.next((event.target as HTMLInputElement).value);
    };

    onPageChange = (pageNumber: number): void => {
        this.onPageChangeEvent.emit(pageNumber);
    };

    onLimitChange = (pageLimit: number): void => {
        this.onLimitChangeEvent.emit(pageLimit);
    };

    sortTableData = (event: { sortOrder: string; columnName: string }): void => {
      let listingParams = { ...this.orderListingParams };
      if (event.columnName === 'first_name') {
          listingParams.order_by = [
              {
                  userByUserId: {
                      user_authentication: {
                        user_role_mapping: {
                          name: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
                        }
                      }
                  },
              },
          ];
      } else if (event.columnName === 'created_at') {
          listingParams.order_by = [
              {
                  created_at: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
              },
          ];
      }else if (event.columnName === 'order_type') {
          listingParams.order_by = [
            {
              order_type: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
            }
          ]
      }else if (event.columnName === 'design_type') {
        listingParams.order_by = [
          {
            design_type: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
          }
        ]
      }else if (event.columnName === 'order_status') {
        listingParams.order_by = [
          {
            order_status: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
          }
        ]
      }else if (event.columnName === 'reference_number') {
        listingParams.order_by = [
          {
            userByUserId: {
              user_authentication: {
                user_role_mapping: {
                  reference_number: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
                }
              }
            }
          }
        ]
      }else if (event.columnName === 'distributor'){
        listingParams.order_by = [
          {
            userByDistributorId: {
              user_authentication: {
                user_role_mapping: {
                  name: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
                }
              }
            }
          }
        ]
      }else if (event.columnName === 'partner'){
        listingParams.order_by = [
          {
            userByClinicId: {
              user_authentication: {
                user_role_mapping: {
                  name: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
                }
              }
            }
          }
        ]
      }else if (event.columnName === 'practitioner'){
        listingParams.order_by = [
          {
            userByDoctorId: {
              user_authentication: {
                user_role_mapping: {
                  name: event.sortOrder === 'asc' ? Order_By.Asc : Order_By.Desc,
                }
              }
            }
          }
        ]
      }
      listingParams.offset = 0;
      this.orderListingParams = { ...listingParams };
      this.emitOrderListingParams.emit(this.orderListingParams);
    };

    navigateWithAction = (event: { data: any; type: string }): void => {
        this.navigateWithActionEvent.emit(event);
    };

    /**
     * Function checks if the manufacturer status selected from the manufacturer status change drop down contains manufacturer status -
     * which are not allowed to change to the dropdown selected manufacturer status.
     * @param changeOrderStatusTo shares the manufacturer status which user is updating to.
     */
    validateSelectedOrders = (changeOrderStatusTo: Manufacturer_Status_Enum): void => {
        try {
            const changeOrderStatusHierarchy = manufacturerStatusHierarchyArray?.find(
                (order) => order.status === changeOrderStatusTo
            )?.hierarchy;
            this.selectedItems?.forEach((orderIn: OrderDetails) => {
                const selectedOrdersStatusHierarchy: ManufacturerStatusHierarchy[] =
                    manufacturerStatusHierarchyArray?.filter((order) => {
                        if (order?.status === orderIn?.manufacturer_status) {
                            return order;
                        }
                        return null;
                    });
                if (
                    changeOrderStatusHierarchy &&
                    selectedOrdersStatusHierarchy?.some(
                        (order: ManufacturerStatusHierarchy) => order?.hierarchy >= changeOrderStatusHierarchy
                    )
                ) {
                    throw new Error('You trying to change a higher hierarchy order to a lower order.');
                }
            });
        } catch (error: any) {
            this.orderStatusControl.patchValue('');
            this._toast.error(error.message);
            throw error;
        }
    };

    /**
     * Send request to change order status.
     */
    changeOrderStatus = (): void => {
        const status = this.orderStatusControl?.value || '';
        const orderStatus = this.orderStatusControl?.value as Order_Status_Enum;
        const normalizedStatus = status.toLowerCase(); // Normalize the case

        const isManufacturerStatus = Object.values(Manufacturer_Status_Enum)
            .map((value) => value.toLowerCase())
            .includes(normalizedStatus);

        const isOrderStatus = Object.values(Order_Status_Enum)
            .map((value) => value.toLowerCase())
            .includes(normalizedStatus);

        // Validate selected order.
        this.validateSelectedOrders(status as Manufacturer_Status_Enum);

        if (status && (isOrderStatus || isManufacturerStatus)) {
            const selectedItemIds = this.selectedItems?.map((order: OrderDetails) => order?.id);

            let payload: BulkUpdateOrderStatusMutationVariables = {
                ids: selectedItemIds,
                isContractManufacturer: true,
                isOtherRoles: false,
                isOrderShipped: false
            };
            if (isManufacturerStatus) {
                payload = { ...payload, manufacturerStatus: status as Manufacturer_Status_Enum};
            }
            if(orderStatus.toLocaleLowerCase() === Order_Status_Enum.Shipped){
                payload = { ...payload, isOrderShipped: true }
            }
            if (status === Manufacturer_Status_Enum.Shipped) {
                this.orderDetails = [];
                this.selectedItems?.forEach((order: OrderDetails) => {
                    let trackingData = {
                        order_id: order?.id,
                        manufacturer_order_status: orderStatus,
                        order_status: Order_Status_Enum.Shipped,
                        manufacturer_created_at: new Date(),
                    };
                    this.orderDetails = [...this.orderDetails, trackingData];
                    this._store.dispatch(InsertOrderStatusToTrackingData({ orderTrackingData: this.orderDetails }));
                });
            }
            this._store.dispatch(bulkUpdateOrderStatus(payload));
            this.selectedItems = [];
            this.clearCheckedList = true;
            this.orderStatusControl.patchValue('');
        }
    };

    /**
     * Enable select order feature per roles.
     * @returns void
     */
    updateSelectCheckboxPermission = (): void => {
        this.tableColumns = [
            {
                columnDef: 'select_order',
                header: '',
                isLink: false,
                url: 'string',
                isSortable: false,
                elementType: ElementType.checkbox,
            },
            ...this.tableColumns,
        ];
    };

    saveSelectedItems = (selectedOrders: OrderDetails[]): void => {
        this.selectedItems = selectedOrders;
        if (this.selectedItems?.length > 0) {
            this.orderStatusControl.enable();
            return;
        }
        this.orderStatusControl.disable();
    };

    onChangeOrderStatus = (): void => {
        this.confirmationDialogCmp.openConfirmDialog();
    };

    /**
     * Recieves event from modal component upon confirmation
     * Dispatches action to delete user
     */
    onConfirmationStatusUpdate = (): void => {
        this.confirmationDialogCmp.onCancelConfirmDialogOnly();
        this.changeOrderStatus();
    };

    onCancelOrderChangeRequest = (): void => {
        this.orderStatusControl.patchValue('');
        this.clearCheckedList = true;
    };

    openFilterModal = (): void => {
      const modalRef = this._modalService.open(FilterComponent);
      // Pass parameters to modal
      modalRef.componentInstance.filterTypes = this.filterTypes;
      modalRef.result.then(
          (result) => {
              if (result) {
                  this.showClearFilterIcon = true;
                  this.selectedItems = [];
                  this.clearCheckedList = true;
                  this.emitFilterParams.emit(result);
              }
          },
          (reason) => {}
      );
  };

   /**
    * Clear applied filters.
   */
   onClearFilter = (): void => {
    this.emitClearFilter.emit();
    this.showClearFilterIcon = false; // Hide the clear filter icon.
    this._store.dispatch(saveAppliedFilters(null));
   };

    ngOnDestroy(): void {
        this.searchDebouncer$.unsubscribe();
    }
}
