import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class ToasterService {

  constructor(private _toaster: ToastrService) { }

  /**
   * Show success toaster
   * @param successMessage Message to be shown in toaster
   * @return void
   */
  success(successMessage: string): void {
    this._toaster.success(successMessage);
  }

  /**
   * Create login form and controls
   * @param errorMessage Message to be shown in toaster
   * @return void
   */
  error(errorMessage: string): void {
    this._toaster.error(errorMessage);
  }
}
