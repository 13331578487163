import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'CapitalizeType',
})

export class CapitalizeTypePipe implements PipeTransform{

    transform(snakeCaseText: string): string {
        if(!snakeCaseText){
            return '';
        }    
        let text = snakeCaseText.charAt(0)?.toUpperCase() + snakeCaseText?.slice(1); // Change first char of each word to uppercase.
        return text;
    }
}