import { GetRolesBasedOnUserIdQuery, GetUserAndUserRoleMappingByEmailQuery, GetUserProfileQuery } from '@app/generated/graphql';
import { User } from '@app/shared/models/shared.model';

export enum AuthModuleStates {
    Login = '[Auth] Login',
    LoginOk = '[Auth] Login OK',
    LoginFailure = '[Auth] Login Failure',
    OtpServiceSelection = '[Auth] Enter otp service selection',
    sendOtpToBothServices = '[Auth] Send otp to both services',
    sendOtpPreferences = '[Auth] Send otp based on selected method',
    resendOtpToUser = '[Auth] Resend otp',
    ResetPassword = '[Auth] Reset Password',
    ResetPasswordSuccess = '[Auth] Reset Password Success',
    ResetPasswordFailure = '[Auth] Reset Password Failure',
    UserRolesFetch = '[Auth] Fetch all roles of user against a userid',
    UserRolesFetchSuccess = '[Auth] User Roles Fetch Success',
    UserRolesFetchFailure = '[Auth] User Roles Fetch Failure',
    roleSelectionInitiate = '[Auth] Select and Proceed with a Role',
    VerifyOtp = '[Auth] Send Otp',
    VerifyOtpStatus = '[Auth] Otp Verification Initiate',
    ChangePassword = '[Auth] Change Password Initiate',
    GetUserDetails = '[Auth] Get user details',
    GetUserDetailsSuccess = '[Auth] Get user details success',
    SaveOtpExtras = '[Auth] Save otp extras',
    ForgotPasswordEmailValidation = '[Auth] Validate email',
    ForgotPasswordEmailValidationSuccess = '[Auth] Validate email Success',
    ClearAuthRelatedInitialData = '[Auth] Clear all auth related data',
    ClearAuthRelatedInitialDataSuccess = '[Auth] Clear all auth related data success',
    ClinicListFetchForSelection = '[Auth] Fetch list of clinics for selection when doctor logs in',
    ClinicListFetchForSelectionSuccess = '[Auth] Fetch list of clinics for doctor success',
    SaveLoginDataForLaterClinicSelection = '[Auth] Save role select data for doctor clinic selection',
    OnClinicSelectForDoctor = '[Auth] Select and update a clinic choosen by doctor',
    OnClinicSelectForDoctorSuccess = '[Auth] Update a clinic choosen by doctor success',
    SelectClinicAfterLogin = '[Auth] clinic selection after login ',
    ValidateDoctorEmail = '[Auth] Validate if doctor email is valid',
    ValidateDoctorEmailSuccess = '[Auth] Validate if doctor email is valid success',
    DoctorEmailValidationFailed = '[Auth] Validate if doctor email is valid failed',
    LoginSuperAdminToTheRequestedDoctorProfile = '[Auth] Login super admin to the requested doctor profile',
    getUserProfileDetails = '[ Auth ] get user profile details',
    getUserProfileDetailsSuccess = '[ Auth ] get user profile details success',
    updateNewUserDetails = '[ Auth ] update new user details',
    getClinicsByDesignerId = '[ Auth] Get clinics by designer id',
    getClinicsByDesignerIdSuccess = '[ Auth] Get clinics by designer id Success',
    getSiblingList = '[Auth] Get siblings list',
    getSiblingListSuccess = '[Auth] Get siblings list success',
    saveUserIdOfLoggedInUser = '[ Auth ] save user id of logged in user',
    checkIfPatientIsUnderFranchiseClinic = '[Auth] Check if patient is under franchise clinic',
    saveParentPatientId = '[Patient] save parent patient details'
  }

export interface State {
    roleSelected: string | null;
    isPasswordReset: boolean;
    availableUserRoles: GetRolesBasedOnUserIdQuery;
    authFlowDetails: LoginFlowCompleteDetails;
    loginDataForLaterClinicSelection: LoginResponseData;
    clinicList: ClinicListForSelection[];
    showClinicSelection: boolean;
    isValidatingDoctorEmail: boolean;
    doctorEmailFailedMsg: string | null;
    doctorDetails: GetUserAndUserRoleMappingByEmailQuery | null;
    userProfileDetails: GetUserProfileQuery | null;
    clinicsByDesigner: User[] | null;
    siblingList: Patient[] | null;
    parentPatientId: string | null;
}

export const initialState: State = {
    roleSelected: null,
    isPasswordReset: false,
    availableUserRoles: { user_role_mapping: [] },
    authFlowDetails: {
        initialAuthBasicDetails: {
            userId: '',
            token: '',
            email: ''
        },
        otpServiceSelectionDetails: {
            method: '',
            userId: ''
        },
        otpVerificationDetails: {
            otp: null,
            userId: ''
        },
        authenticationType: '',
    },
    loginDataForLaterClinicSelection: {
        token: '',
        roleMappingId: '',
        userId: '',
        role: '',
        roleId: '',
    },
    clinicList: [],
    showClinicSelection: false,
    isValidatingDoctorEmail: false,
    doctorDetails: null,
    doctorEmailFailedMsg: null,
    userProfileDetails: null,
    clinicsByDesigner: null,
    siblingList: null,
    parentPatientId: null
};

export interface LoginFlowCompleteDetails {
    initialAuthBasicDetails: InitialTokenDetails;
    otpServiceSelectionDetails: OtpSelectionDetails;
    otpVerificationDetails: verifyOtpDetails;
    authenticationType: string;
}
export interface InitialTokenDetails {
    userId: string;
    token: string;
    email: string;
}
export interface OtpSelectionDetails {
    method: string;
    userId: string;
}
export interface verifyOtpDetails {
    otp: number | null;
    userId: string;
}

export interface AnonymousUser {
    userId: string | undefined;
    token: string | undefined;
}

export interface LoginResponseData {
    token: string;
    roleMappingId: string;
    userId: string;
    role: string;
    roleId?: string;
    clinicId?: string;
    distributorId?: string;
    clinicType?: string;
    designerId?: string;
    clinicRoleId?: string;
    parentPatientId?: string;
}

export interface OtpTarget {
    email: string;
    sourceType: string;
}

export interface SelectedRoleDetails {
    id: string;
    name: string;
}

export interface ClinicListForSelection {
    clinicId: string;
    clinicName: string;
    clinicType: string;
    clinicRoleId: string
}

// enum for role types actual names for display
export enum ROLE_NAMES {
    super_admin = "Super Admin",
    designer = "Designer",
    distributor = "Exclusive Distributor",
    exclusive_distributor = "Exclusive Distributor",
    selective_distributor = "Selective Distributor",
    regular_clinic = "Regular Clinic",
    doctor = "Doctor",
    franchise_clinic = "Franchise Clinic",
    contract_manufacturer = "Contract manufacturer",
    patient = "Patient",
    partner = "Partner",
    gold_partner = "Gold Partner",
    family_member = "Family"
}

export interface UpdateUserProfileUserDetails {
    name: string,
    contact_number: string,
    updated_at: string,
    updated_by: string
}

export interface UpdateUserProfileRoleDetails {
    name: string,
    role_details: UpdateUserRoleDetails
}

export interface UpdateUserRoleDetails {
    address: string,
}

export interface NetworkRequestBodyInterface {
    operationName: string;
    query: string;
    variables: Object;
}

export interface NetworkRequestErrorInterface {
    body: { errors: Array<{extensions: Object, message: string}>};
    error: Object;
    headers: Object;
    message: string;
    name: string;
    ok: boolean;
    status: number;
    statusText: string;
    type: number;
    url: string;
}

export enum AuthErrorMessages {
    UNAUTHORIZED = 'webhook authentication request failed',
    NO_JWT_PASSED_1 = 'Invalid response from authorization hook: Error in $: not enough input'
}

export interface UserDetailsToCheckAgainstLocalStorage {
  user_id: string,
  role_mapping_id: string,
  role_id: string,
  role: string,
  clinic_id?: string,
  clinic_type?: string,
  distributor_id?: string,
  designer_id?: string
}
export type Patient = User & {
  clinicType: string,
  careOf: string
}
