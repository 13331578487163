<div
    class="d-flex phone-input-with-country-code position-relative"
    [ngClass]="{'disable-dropdown': mode === 'view'}"
    appClickOutside
    (clickOutside)="onDismissCountryCodeSelector()"
>
    <div class="selected-country-code d-flex align-items-center justify-content-between" tabindex="0"
    (keydown)="keyDown($event)" (click)="onCountryCodeClick()">
        {{ country_code }}
        <i class="bi bi-chevron-down"></i>
    </div>
    <ng-container>
      <ul #countryCodeList *ngIf="dismissPhoneSelector" class="position-absolute country-code-list">
          <li *ngFor="let country of filteredCountryList" (click)="onCountryCodeChange(country.countryCode)" [value]="country.countryCode">
              <div class="d-flex">
                <div><img class="flag" [src]="country.flag" /></div>
                <div class="mx-3">{{country.countryCode}}</div>
                <div>{{country.name}}</div>
              </div>
          </li>
      </ul>
    </ng-container>
</div>
