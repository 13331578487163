<div class="select-form-container">
    <div class="view-details-form">
        <div class="col-12 p-0 d-flex justify-content-between">
            <span class="kl-text3-type-6">Order Info</span>
            <span
                class="order-id-data cursor-pointer"
                [cdkCopyToClipboard]="orderIdForDisplay"
                (click)="onCopyToClipboard()"
            >
                <i class="file-icon bi bi-files"></i>
                <span>{{ orderIdForDisplay }}</span>
            </span>
        </div>
        <div class="container row mt-3">
            <div class="order-tracking col-12">
                <div *ngIf="!isOrderCancelled">
                  <ng-container *ngFor="let status of orderStatusList">
                      <div
                          class="order-status"
                          *ngIf="
                              order_status !== 'cancelled' ||
                              (order_status === 'cancelled' && (status.id <= previousStatusId || status.id === 6))
                          "
                      >
                          <div class="status-circle" [ngClass]="{ active: status.active }"></div>
                          <div class="status-label" [ngClass]="{ active: status.active }">{{ status.label }}</div>
                          <div
                              class="status-line"
                              *ngIf="status.label !== orderStatusList[orderStatusList.length - 1].label"
                          ></div>
                          <p class="orderedDate" *ngIf="status.label === 'Designed'">
                            {{
                                orderStatusObject.submittedDate != ''
                                    ? (orderStatusObject.submittedDate | date : 'dd - MM - yyyy')
                                    : '--'
                            }}
                        </p>
                        <p class="orderedDate" *ngIf="status.label === 'Ordered'">
                            {{
                                orderStatusObject.orderedDate != ''
                                    ? (orderStatusObject.orderedDate | date : 'dd - MM - yyyy')
                                    : '--'
                            }}
                        </p>
                        <p class="orderedDate" *ngIf="status.label === 'Shipped to country'">
                            {{
                                orderStatusObject.shippedDate != ''
                                    ? (orderStatusObject.shippedDate | date : 'dd - MM - yyyy')
                                    : '--'
                            }}
                        </p>
                        <p class="orderedDate" *ngIf="status.label === 'Out for Delivery'">
                            {{
                                orderStatusObject.outForDeliveryDate != ''
                                    ? (orderStatusObject.outForDeliveryDate | date : 'dd - MM - yyyy')
                                    : '--'
                            }}
                        </p>
                        <p class="orderedDate" *ngIf="status.label === 'Delivered'">
                            {{
                                orderStatusObject.deliveredDate != ''
                                    ? (orderStatusObject.deliveredDate | date : 'dd - MM - yyyy')
                                    : '--'
                            }}
                        </p>

                      </div>
                  </ng-container>
                </div>
                <div class="d-flex align-items-center" *ngIf="isOrderCancelled">
                  <div class="status-circle"></div>
                  <div class="status-label">Cancelled</div>
                </div>
            </div>
        </div>
        <div class="row m-3" *ngIf="hideCancelButton && !isOrderCancelled && checkManufacturerStatus()">
            <button class="kl-btn-cancel-order-btn" (click)="openOrderCancelConfirmModal()">
                <img src="/assets/icons/common/cancel_icon.svg" />&nbsp;Cancel this order
            </button>
        </div>
    </div>
</div>

<app-confirmation-dialog
        #confirmationDialogCmp
        (confirmationStatus)="onConfirmationStatusUpdate()"
        (writtenConfirmationStatus)="writtenConfirmation"
    >

        <h3 class="popup-main-title kl-text3-type-7 text-center">Are you sure?</h3>
        <h4 class="popup-body-content kl-text5-type3">
            Do you want to change the order status to cancel this order?
        </h4>
        <div class="popup-body-content-2">
          <input
              type="text"
              [(ngModel)]="writtenConfirmation"
              class="kl-input-text input-text-type-2 alert-place-holder"
              id="cancel-order"
              placeholder='TYPE -  "CANCEL"'
          />
        </div>
</app-confirmation-dialog>
