export const FACDControlPanel: FacdControlPanel = {
  hvid: 0.98,
  vertex: 0.01,
  rcRxLimitLower: 2.91,
  rcRxLimitUpper: 6.32,
  rcLower: 0.5,
  rcMiddle: 0.6,
  rcUpper: 0.7,
  bozdRxLimitLower: 2.91,
  bozdRxLimitUpper: 5.66,
  bozdLower: 5.4,
  bozdMiddle: 5.8,
  bozdUpper: 6.2,
  jessenMultiply: 1.23,
  jessenAddition: 1.27,
  elHvidLimit: 10.4,
  apicalBozdLimitLower: 5.8,
  apicalBozdLimitUpper: 6.2,
  apicalLower: 0.01,
  apicalUpper: 0.014,
  apicalMiddle: 0.012,
  apicalRxLimit: 5.66,
  rc2Tlt: 0.005,
  ac1Tlt: 0,
  ac2Tlt: 0.005,
  baseEccLower: 0,
  baseEccUpper: 0.99,
  elTlt2Loose: 0.12,
  elTlt1Loose: 0.11,
  elTltOptimal: 0.1,
  sagToAcConversion: 0.058,
  deltaKToAcConversion: 0.55,
};

export interface FacdControlPanel {
  hvid: number;
  vertex: number;
  rcRxLimitLower: number;
  rcRxLimitUpper: number;
  rcLower: number;
  rcMiddle: number;
  rcUpper: number;
  bozdRxLimitLower: number;
  bozdRxLimitUpper: number;
  bozdLower: number;
  bozdMiddle: number;
  bozdUpper: number;
  jessenMultiply: number;
  jessenAddition: number;
  elHvidLimit: number;
  apicalBozdLimitLower: number;
  apicalBozdLimitUpper: number;
  apicalLower: number;
  apicalUpper: number;
  apicalMiddle: number;
  apicalRxLimit: number;
  rc2Tlt: number;
  ac1Tlt: number;
  ac2Tlt: number;
  baseEccLower: number;
  baseEccUpper: number;
  elTlt2Loose: number;
  elTlt1Loose: number;
  elTltOptimal: number;
  sagToAcConversion: number;
  deltaKToAcConversion: number;
}

export interface TroubleshootControlPanel {
  oneEValue: number;
  twoEValueLower: number;
  twoEValueMiddle: number;
  twoEValueUpper: number;
  threeEValueLower: number;
  threeEValueUpper: number;
  fourEValue: number;
  twoHvidLower: number;
  twoHvidUpper: number;
  fiveSphere: number;
  sevenSphere: number;
  eightSphere: number;
  twoAc1Tlt: number;
  threeAc1TltLower: number;
  threeAc1TltMiddle: number;
  threeAc1TltUpper: number;
  fourAc1Tlt: number;
  twoAc2TltLower: number;
  twoAc2TltMiddle: number;
  twoAc2TltUpper: number;
  threeAc2TltLower: number;
  threeAc2TltMiddle: number;
  threeAc2TltUpper: number;
  fourAc2Tlt: number;
  fourRc2Tlt: number;
  fourElTltLower: number;
  fourElTltUpper: number;
  sixAcWidth: number;
  eightAcWidth: number;
  eightBaseEcc: number;
  fourApicalTlt: number;
}

export const SATControlPanel: TroubleshootControlPanel = {
  oneEValue: 0.04,
  twoEValueLower: 0.04,
  twoEValueMiddle: 0.02,
  twoEValueUpper: 0,
  threeEValueLower: 0.04,
  threeEValueUpper: 0,
  fourEValue: 0.03,
  twoHvidLower: 0.2,
  twoHvidUpper: 0.3,
  fiveSphere: 0.5,
  sevenSphere: 0.5,
  eightSphere: 0.5,
  twoAc1Tlt: 0.004,
  threeAc1TltLower: 0,
  threeAc1TltMiddle: 0.003,
  threeAc1TltUpper: 0.004,
  fourAc1Tlt: 0.003,
  twoAc2TltLower: 0,
  twoAc2TltMiddle: 0.006,
  twoAc2TltUpper: 0.007,
  threeAc2TltLower: 0,
  threeAc2TltMiddle: 0.006,
  threeAc2TltUpper: 0.008,
  fourAc2Tlt: 0.005,
  fourRc2Tlt: 0.003,
  fourElTltLower: 0.01,
  fourElTltUpper: 0.02,
  sixAcWidth: 0.05,
  eightAcWidth: 0.05,
  eightBaseEcc: 0.99,
  fourApicalTlt: 0.006,
};

export const MATControlPanel: TroubleshootControlPanel = {
  oneEValue: 0.03,
  twoEValueLower: 0.03,
  twoEValueMiddle: 0.02,
  twoEValueUpper: 0,
  threeEValueLower: 0.03,
  threeEValueUpper: 0,
  fourEValue: 0.02, //Not Used
  twoHvidLower: 0.2, //Not Used
  twoHvidUpper: 0.2, //Not Used
  fiveSphere: 0.25, //Not Used
  sevenSphere: 0.5, //Not Used
  eightSphere: 0.25, //Not Used
  twoAc1Tlt: 0.004, //Not Used
  threeAc1TltLower: 0, //Not Used
  threeAc1TltMiddle: 0.003, //Not Used
  threeAc1TltUpper: 0.004, //Not Used
  fourAc1Tlt: 0.003, //Not Used
  twoAc2TltLower: 0,
  twoAc2TltMiddle: 0.006,
  twoAc2TltUpper: 0.007,
  threeAc2TltLower: 0,
  threeAc2TltMiddle: 0.006,
  threeAc2TltUpper: 0.008,
  fourAc2Tlt: 0.005,
  fourRc2Tlt: 0.003,
  fourElTltLower: 0.01,
  fourElTltUpper: 0.01,
  sixAcWidth: 0.05,
  eightAcWidth: 0.05,
  eightBaseEcc: 1.2,
  fourApicalTlt: 0.006,
};
