import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs';

import { selectSiblingList } from '@app/auth/selectors/auth.selector';

@Component({
  selector: 'app-sibling-selection',
  templateUrl: './sibling-selection.component.html',
  styleUrls: ['./sibling-selection.component.scss']
})
export class SiblingSelectionComponent implements OnDestroy{
  isAlive = true;
  familyMembers$ = this._store.select(selectSiblingList).pipe(takeWhile(() => this.isAlive));

  constructor(private _store: Store){}

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
