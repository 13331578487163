import { Action, createReducer, on } from "@ngrx/store";

import { State, initialState } from "../models/patient-profile.model";
import { getInUseLenseDetails, getListOfOrdersOfPatientSuccess, resetPatientProfileState } from "../actions/patient-profile.action";
import { resetAppState } from "@app/actions";

const _reducer = createReducer(
  initialState,
  on(getListOfOrdersOfPatientSuccess, (state, {orderList}) => ({
    ...state,
    orderList,
  })),
  on(getInUseLenseDetails, (state, {inUseLenseDetails }) => ({
    ...state,
    inUseLenseDetails
  })),
  on(resetPatientProfileState, () => ({
    ...initialState
  })),
  on(resetAppState, () => initialState)
)

export function patientProfileReducer(state: State | undefined, action: Action) {
  return _reducer(state, action);
}
