<div class="kl-login-container">
    <h2 
        class="sign-in kl-h6-type-1" 
        id="sign-in-label" 
        *ngIf="!isRoleSelctionActive"
    >
        Sign in
    </h2>
    <h2 
        class="sign-in kl-h6-type-1" 
        id="sign-in-label" 
        *ngIf="isRoleSelctionActive"
    >
        User role
    </h2>
    <div class="role-selection-div" *ngIf="isRoleSelctionActive">
        <app-role-selection></app-role-selection>
    </div>
    <form
        class="sign-in-form-main"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        id="main-form-element-login"
        *ngIf="!isRoleSelctionActive"
    >
        <div class="mb-2" id="user-name-or-email-div">
            <label for="email" class="kl-text2-type-1 mb-2"> Enter your Email Address </label>
            <input
                type="email"
                formControlName="email"
                class="kl-input-text input-text-type-1"
                id="email"
                placeholder="Email address"
                autocomplete="on"
            />
            <div class="error-message-container-common">
                <p 
                    class="error-text" 
                    *ngIf="isRequired('email') && (isDirty('email') || isTouched('email'))"
                >
                    Email is required
                </p>
                <p
                    class="error-text"
                    *ngIf="!isValid('email') && 
                        (isDirty('email') || isTouched('email')) && !isRequired('email')"
                >
                    Please enter a valid email
                </p>
            </div>
        </div>
        <div class="password-container" id="password-contain-div">
            <label for="password" class="kl-text2-type-1 mb-2"> Enter your password </label>
            <div class="input-with-icon-type-1" id="password-with-icon-div">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                    class="kl-input-text input-text-type-1"
                    placeholder="Password"
                    id="password"
                    autocomplete="on"
                />
                <i 
                    class="bi bi-eye input-icon to-right gray-80 cursor-pointer" 
                    (click)="togglePassword()"
                > 
                </i>
            </div>
            <div class="error-message-container-common">
                <p 
                    class="error-text" 
                    *ngIf="!isValid('password') && 
                        isTouched('password') &&
                        isRequired('password')"
                    >
                    Password is required
                </p>
                <p 
                    class="error-text" 
                    *ngIf="!isRequired('password') && 
                        isTouched('password') &&
                        isMaxLengthError('password')"
                >
                    Password should not be greater than 128 characters
                </p>
            </div>
        </div>
        <div class="forgot-password-link-container mt-1 text-end kl-text4-type-1">
            <span 
                class="kl-text4-type-1 cursor-pointer" 
                (click)="onNavigateToForgotPwd()"
            > 
                Forgot Password?
            </span>
        </div>
        <div
            class="auth-form-btn-container mt-4 mb-sm-2 mb-md-2 mb-lg-2 d-flex justify-content-center"
            id="sign-in-btn-div"
        >
            <button 
                type="submit" 
                class="kl-btn-primary" 
                id="sign-in-action-btn" 
                [disabled]="!loginForm.valid"
            >
                Continue
            </button>
        </div>
    </form>
</div>
