import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from '../models/auth.model';

const _userRoleList = (state: State) => (state ? state.availableUserRoles : { user_role_mapping: [] });

const _userIdFromInitialLogin = (state: State) =>
    (state ? state.authFlowDetails.initialAuthBasicDetails?.userId : '');

const _emailFromAuthData = (state: State) =>
    (state ? state.authFlowDetails.initialAuthBasicDetails?.email : '');

const _sendOtpRelatedParams = (state: State) =>
    (state ? state.authFlowDetails.otpServiceSelectionDetails : { method: '', userId: '' });

const _authenticationType = (state: State) =>
    (state ? state.authFlowDetails.authenticationType : '');

const _isValidNonAuthDataExists = (state: State) =>
    (state ?
        state.authFlowDetails.authenticationType !== '' &&
        state.authFlowDetails.initialAuthBasicDetails.userId !== '' &&
        state.authFlowDetails.initialAuthBasicDetails.email
        ? true
        : false
    : false);

const _clinicListForDoctor = (state: State) =>
    (state ? state.clinicList : []);

const _userIdForClinicSelection = (state: State) =>
    (state ? state.loginDataForLaterClinicSelection.userId as string : '');

const _loginDataForClinicSelection = (state: State) =>
    (state
        ? state.loginDataForLaterClinicSelection
        : {
            token: '',
            roleMappingId: '',
            userId: '',
            role: '',
            roleId: '',
        }
    );
const _showClinicSelection= (state: State) => (state ? state.showClinicSelection : false);
const _getUserProfileDetails = (state: State) => (state ? state.userProfileDetails : {user_role_mapping: []})

// Select validate doctor email state values.
const _selectIsValidatingDoctorEmail = (state: State) => (state ? state.isValidatingDoctorEmail : false);
const _selectDoctorDetailsByEmailForAuth = (state: State) => (state ? state.doctorDetails : null);
const _selectDoctorEmailValidationFailedMsg = (state: State) => (state ? state.doctorEmailFailedMsg : null);
const _selectClinicsByDesigner = (state: State) => (state ? state.clinicsByDesigner : null);
const _selectSiblingList = (state: State) => (state ? state.siblingList : null);

const _selectParentPatientId = (state: State) => (state ? state.parentPatientId : null);

export const selectFeature = createFeatureSelector<State>('authentication');

export const availableUserRoles = createSelector(selectFeature, _userRoleList);
export const getUserIdFromInitialLogin = createSelector(selectFeature, _userIdFromInitialLogin);
export const getEmailFromAuthData = createSelector(selectFeature, _emailFromAuthData);
export const getSelectedOtpMethodAndUserId = createSelector(selectFeature, _sendOtpRelatedParams);
export const getAuthenticationType = createSelector(selectFeature, _authenticationType);
export const isNonAuthDataExist = createSelector(selectFeature, _isValidNonAuthDataExists);
export const getListOfClinicsByDoctor = createSelector(selectFeature, _clinicListForDoctor);
export const getUserIdOfDoctorForClinicList = createSelector(selectFeature, _userIdForClinicSelection);
export const getloginDataForClinicSelection = createSelector(selectFeature, _loginDataForClinicSelection);
export const showClinicSelection = createSelector(selectFeature, _showClinicSelection);
export const showUserProfileDetails = createSelector(selectFeature, _getUserProfileDetails);
export const selectIsValidatingDoctorEmail = createSelector(selectFeature, _selectIsValidatingDoctorEmail);
export const selectDoctorDetailsByEmailForAuth = createSelector(selectFeature, _selectDoctorDetailsByEmailForAuth);
export const selectDoctorEmailValidationFailedMsg = createSelector(selectFeature, _selectDoctorEmailValidationFailedMsg);
export const selectClinicsByDesigner = createSelector(selectFeature, _selectClinicsByDesigner);
export const selectSiblingList = createSelector(selectFeature, _selectSiblingList);
export const selectParentPatientId = createSelector(selectFeature, _selectParentPatientId);
