<div class="spinner-wrap" *ngIf="loaderType === 'simple'">
    <div class="lds-dual-ring"></div>
</div>

<div class="opaque-loader-wrapper d-flex justify-content-center align-items-center" *ngIf="loaderType === 'opaque'">
  <div class="text-center loader-content">
    <img src="/assets/images/authentication/kalculus-logo.png" alt="Logo" class="img-fluid loader-logo">
    <p class="mt-3 kl-text1-type-3">Hang tight, we're setting things up for you!</p>
  </div>
</div>
