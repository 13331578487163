import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Roles, Url } from '../util/common';
import { CredentialsService } from './credentials.service';
import { Store } from '@ngrx/store';
import { resetReviewHistoryState } from '@app/review-history/actions/review-history.action';
import { resetPatientProfileState } from '@app/patient-profile/actions/patient-profile.action';
import { resetPatientDetails } from '@app/shared/actions/shared.action';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
  loginOrSwitchUserSubject$ = new Subject<boolean>();
    constructor(private _credentialService: CredentialsService, private _router: Router, private _store: Store) {}

    checkEmptyObject = (Obj: {}): boolean => {
        return Obj && Object.keys(Obj).length === 0 && Object.getPrototypeOf(Obj) === Object.prototype;
    };

    /** Looks for logged in user role and return user id if role is not super admin.
     * Super admin requires complete list of designers.
     * If any other role other than super admin. They need only want to see distributor they onboard.
     * When returned empty object hasura will return all distributor list which is a requirement for super admin.
     * @returns object with user id or empty object.
     */
    getCreatedById = (userId: string): { _eq: string } | {} => {
        const role = this._credentialService.credentials?.role;
        if (role === Roles.SUPER_ADMIN) {
            return {};
        }
        return {
            _eq: userId,
        };
    };

    /**
     * Based on user role navigate user to corresponding module
     */
    navigateToRoutesBasedOnRole = (): void => {
        const userRole = this._credentialService.credentials?.role;
        this.sendLoginOrSwitchUserEvent(true);
        if (userRole === Roles.CONTRACT_MANUFACTURER) {
            this._router.navigateByUrl(Url.EXCLUSIVE_DIST_LISTING);
        } else if (userRole === Roles.PATIENT) {
            this._store.dispatch(resetPatientProfileState());
            this._store.dispatch(resetReviewHistoryState());
            this._store.dispatch(resetPatientDetails());
            this._router.navigateByUrl(Url.PATIENT_PROFILE);

        } else {
            this._router.navigateByUrl(Url.DASHBOARD);
        }
    };

    /*
     * Logo creation based on first name and last name for doctor and patient list
     */
    createUserLogoBasedOnName = (name: string | undefined): string => {
        const fullName = name?.trim().split(/\s+/) || [];
        const firstName = fullName?.[0]?.[0];
        const lastName = fullName?.[1]?.[0];
        let nameForLogo = ''
        if (fullName?.length > 1 && firstName != undefined) {
            nameForLogo = firstName?.toUpperCase() + lastName?.toUpperCase();
        }
        else {
            nameForLogo = firstName?.toUpperCase() || ''
        }
        return nameForLogo;
    }

    sendLoginOrSwitchUserEvent = (value: boolean) => {
      this.loginOrSwitchUserSubject$.next(value);
    }

    loginOrSwitchUserEventAsObservable = ():Observable<boolean> => {
      return this.loginOrSwitchUserSubject$.asObservable();
    }
}

export const roundValueToPrecision = (number: number, decimals: number): number => {
    decimals = decimals || 0;
    const factor = Math.pow(10, decimals);
    return Math.floor(number * factor + 0.5) / factor;
};

export const cropValueToMaxExcelDecimalPoints = (digit: number, decimal: number): number => {
    const croppedNumber = digit.toFixed(decimal);
    return parseFloat(croppedNumber);
}
