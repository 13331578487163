import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { SharedState } from './models/shared.model';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: ['commonDetailsAfterLogin'],
        rehydrate: true,
        storage: localStorage,
    })(reducer);
}

export const metaReducers: MetaReducer<SharedState>[] = [localStorageSyncReducer];
