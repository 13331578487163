import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";
import { ApolloQueryResult } from "@apollo/client/core";
import { MutationResult } from "apollo-angular";

import { GetContractManufacturerBasedOnRoleGQL, GetContractManufacturerBasedOnRoleQuery, GetContractManufacturerBasedOnRoleQueryVariables, GetDistributorListByManufacturerIdGQL, GetDistributorListByManufacturerIdQuery, GetDistributorListByManufacturerIdQueryVariables, GetListOfDistributorsFromUserAndUserRoleMappingsTableGQL, GetListOfDistributorsFromUserAndUserRoleMappingsTableQuery, GetListOfDistributorsFromUserAndUserRoleMappingsTableQueryVariables, GetTotalTableItemCountForContractManufacturerGQL, GetTotalTableItemCountForContractManufacturerQuery, GetTotalTableItemCountForContractManufacturerQueryVariables, OnboardContractManufacturerGQL, OnboardContractManufacturerMutation, OnboardContractManufacturerMutationVariables } from "@app/generated/graphql";
import { User } from "@app/shared/models/shared.model";
import { DistributorUser } from "@app/contracted-manufacturers/models/contracted-manufacturers.model";
import { Roles } from "../util/common";
import { CredentialsService } from "./credentials.service";

@Injectable({
    providedIn: 'root',
})
export class ContractedManufacturerService{
    constructor(
        private _getContractManufacturerListGQL: GetContractManufacturerBasedOnRoleGQL,
        private _getTableListTotalCount: GetTotalTableItemCountForContractManufacturerGQL,
        private _onboardContractManufacturerGQL: OnboardContractManufacturerGQL,
        private _getListOfDistributorsFromUserAndUserRoleMappingsTableGQL: GetListOfDistributorsFromUserAndUserRoleMappingsTableGQL,
        private _getDistributorListByManufacturerIdGQL: GetDistributorListByManufacturerIdGQL,
        private _credentialsService: CredentialsService
    ){ }

    getContractedManufacturerList = (payload: GetContractManufacturerBasedOnRoleQueryVariables): Observable<ApolloQueryResult<GetContractManufacturerBasedOnRoleQuery>> => {
        return this._getContractManufacturerListGQL.watch(payload).valueChanges;
    }

    onboardContractedManufacturer = (data: OnboardContractManufacturerMutationVariables): Observable<MutationResult<OnboardContractManufacturerMutation>> => {
      return this._onboardContractManufacturerGQL.mutate(data);
    };

    /**
     * Service to get total list of data in table for pagination
     * @param role_id Role id of designer
     * @returns Observable data
     */
    getTotalTableItemCount = (params: GetTotalTableItemCountForContractManufacturerQueryVariables): Observable<ApolloQueryResult<GetTotalTableItemCountForContractManufacturerQuery>> => {
        return this._getTableListTotalCount.watch(params).valueChanges;
    }

    getListOfDistributorsFromUserAndUserRoleMappingsTable = (params: GetListOfDistributorsFromUserAndUserRoleMappingsTableQueryVariables): Observable<ApolloQueryResult<GetListOfDistributorsFromUserAndUserRoleMappingsTableQuery>> => {
      return this._getListOfDistributorsFromUserAndUserRoleMappingsTableGQL.watch(params).valueChanges;
    }

    transformListOfDistributorsFromUserAndUserRoleMappingsTable = (distributorList: GetListOfDistributorsFromUserAndUserRoleMappingsTableQuery): {exclusive_distributor: DistributorUser[], selective_distributor: DistributorUser[]} => {
      const role = this._credentialsService.credentials?.role || '';
      const transformUser = (user: any, distributorType: string): DistributorUser => ({
        distributorId: user?.user?.id,
        distributorName: user?.name || '',
        distributorType
      });
      if(role === Roles.DESIGNER){
        return {
          exclusive_distributor: distributorList?.exclusive_distributor_under_designer?.flatMap(userIn => userIn?.userByDistributorId?.user_role_mappings?.map((user) => transformUser(user, Roles.EXCLUSIVE_DISTRIBUTOR))),
          selective_distributor: distributorList?.selective_distributor_under_designer?.flatMap(userIn => userIn?.userByDistributorId?.user_role_mappings?.map((user) => transformUser(user, Roles.SELECTIVE_DISTRIBUTOR))),
        }
      }
      return {
        exclusive_distributor: distributorList?.exclusive_distributor?.map((user) => transformUser(user, Roles.EXCLUSIVE_DISTRIBUTOR)),
        selective_distributor: distributorList?.selective_distributor?.map((user) => transformUser(user, Roles.SELECTIVE_DISTRIBUTOR))
      }
    }

    getDistributorListByManufacturerId = (params: GetDistributorListByManufacturerIdQueryVariables): Observable<User[]> => {
      return this._getDistributorListByManufacturerIdGQL.watch(params).valueChanges.pipe(
        map((data: ApolloQueryResult<GetDistributorListByManufacturerIdQuery>) => {
          const distributorList: User[] = this.transformResponseOfDistributorByManufacturerId(data?.data);
          return distributorList
        })
      );
    }

    transformResponseOfDistributorByManufacturerId = (data: GetDistributorListByManufacturerIdQuery):User[]  => {
      const distributors = data?.distributor_contract_manufacturer_mapping?.map((item) => {
        let distributor: User  = { id: item?.user?.user_role_mappings?.[0]?.user?.id, name: item?.user?.user_role_mappings?.[0]?.name || ''};
        return distributor;
      })
      return distributors;
    }
  };
