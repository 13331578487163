import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { OtpTarget } from '@app/auth/models/auth.model';
import { checkIfEmailIsValid, clearAuthRelatedInitialData } from '@app/auth/actions/auth.action';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm!: FormGroup;

    constructor(
        private _store: Store,
    ) {
        this.forgotPasswordFormInit();
    }

    ngOnInit(): void {

        // clears the authentication state data and localStorage data
        this._store.dispatch(clearAuthRelatedInitialData());
        localStorage.removeItem('authFlowDetails');
    }

    /**
     * This is to create form controls for forgot password form
     * @param empty
     * @returns void
     */
    forgotPasswordFormInit = (): void => {
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl<string | null>(null, [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
            ]),
        });
    };

    /**
     * Checks if the control is valid without any errors
     * @param name of the form control.
     */
    isValid = (name: string): boolean => {
        return this.forgotPasswordForm.controls[name]?.valid;
    };

    /**
     * Checks if the control is touched.
     * @param name of the form control.
     */
    isTouched = (name: string): boolean => {
        return this.forgotPasswordForm.controls[name]?.touched;
    };

    /**
     * Checks if the control is dirty.
     * @param name of the form control.
     */
    isDirty = (name: string): boolean => {
        return this.forgotPasswordForm.controls[name]?.dirty;
    };
    /**
     * Checks if the control is required.
     * @param name of the form control.
     */
    isRequired = (name: string): boolean => {
        return this.forgotPasswordForm.controls[name]?.errors?.['required'];
    };

    /**
     * This is to submit forgot password request
     * Api call to validate user email and to send otp to email
     * @param empty
     * @returns void
     */
    submitForgotPwdForm = (): void => {
        if (this.forgotPasswordForm.valid) {
            const emailId: string = this.forgotPasswordForm.get('email')?.value || '';
            const lowerCaseEmail = emailId ? emailId.toLocaleLowerCase() : '';
            const data: OtpTarget = {
                email: lowerCaseEmail,
                sourceType: 'forgot-password'
            };
            this._store.dispatch(checkIfEmailIsValid(data));
        }
    };
}
