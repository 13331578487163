<div class="select-form-container">
    <div class="view-details-form">
        <p class="kl-text5-type2 form-type-selection">Select form type</p>
        <form [formGroup]="detailViewSelectionForm">
            <div class="row">
                <div class="col-12">
                    <div class="d-block d-md-flex justify-content-flex-start">
                        <div
                            class="flex-item lab-form-select"
                            *ngIf="(rolePermissions$ | async)?.permissions?.lab_form"
                        >
                            <label
                                for="labform"
                                class="kl-check-default kl-radio-container"
                                (click)="onSelectFormType('lab-form')"
                            >
                                Lab Form
                                <input
                                    type="radio"
                                    value="lab-form"
                                    id="labform-radio"
                                    name="selectedOption"
                                    formControlName="selectedOption"
                                />
                                <span></span>
                            </label>
                        </div>
                        <div
                            class="flex-item doctor-slip-select"
                            *ngIf="(rolePermissions$ | async)?.permissions?.dr_form"
                        >
                            <label
                                for="doctor_slip"
                                class="kl-check-default kl-radio-container"
                                (click)="onSelectFormType('doctor-slip')"
                            >
                                Practitioner's Slip
                                <input
                                    type="radio"
                                    value="doctor-slip"
                                    id="doctor-slip-radio"
                                    name="selectedOption"
                                    formControlName="selectedOption"
                                />
                                <span></span>
                            </label>
                        </div>
                        <div
                            class="flex-item view-design-select"
                            *ngIf="(rolePermissions$ | async)?.permissions?.view_design"
                        >
                            <label
                                for="view_design"
                                class="kl-check-default kl-radio-container"
                                (click)="onSelectFormType('view-design')"
                            >
                                View Design
                                <input
                                    type="radio"
                                    value="view-design"
                                    id="view-design-radio"
                                    name="selectedOption"
                                    formControlName="selectedOption"
                                />
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
