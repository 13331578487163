<div class="alert-modal2-container">
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ dataFromParent.title }}</h4>
  </div>
  <div class="modal-body">
      <p class="kl-text5-type3 text-center">{{dataFromParent.description}}</p>
      <div class="popup-btn-container d-flex justify-content-around mb-3 px-3">
          <button type="button" class="kl-btn-primary kl-btn-outline-type-1" (click)="onCancel()">Cancel</button>
          <button type="button" class="kl-btn-primary" (click)="onConfirm()">Confirm</button>
      </div>
  </div>
</div>
