import {  createAction } from "@ngrx/store";

import { GetFranchiseClinicFormDataQuery, GetFranchiseClinicFormDataQueryVariables, GetListOfFranchiseClinicCountQueryVariables, GetListOfFranchiseClinicsQuery, GetListOfFranchiseClinicsQueryVariables, GetUserListBasedOnRoleQueryVariables, OnboardFranchiseClinicMutationVariables } from "@app/generated/graphql";
import { ActionType } from "../models/franchise-clinics.model";
import { User } from "@app/shared/models/shared.model";

export const getFranchiseClinicListCount = createAction(
    ActionType.getFranchiseClinicListCount,
    (countListParams: GetListOfFranchiseClinicCountQueryVariables) => ({ countListParams })
);
export const getFranchiseClinicListCountSuccess = createAction(
    ActionType.getFranchiseClinicListCountSuccess,
    (franchiseClinicListCount: number) => ({ franchiseClinicListCount })
);
export const getFranchiseClinicsListBasedOnRole = createAction(
    ActionType.getFranchiseClinicsListBasedOnRole,
    (countListParams: GetListOfFranchiseClinicsQueryVariables) => ({ countListParams })
);
export const getFranchiseClinicsListBasedOnRoleSuccess = createAction(
    ActionType.getFranchiseClinicsListBasedOnRoleSuccess,
    (franchiseClinicListData: GetListOfFranchiseClinicsQuery) => ({ franchiseClinicListData })
);

export const getExclusiveDistributorList = createAction(
    ActionType.getExclusiveDistributorList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getExclusiveDistributorListSuccess = createAction(
    ActionType.getExclusiveDistributorListSuccess,
    (getExclusiveDistributorList: User[] | null) => ({ getExclusiveDistributorList })
);
export const onboardFranchiseClinic = createAction(
    ActionType.onboardFranchiseClinic,
    (franchiseClinicDetails: OnboardFranchiseClinicMutationVariables) => ({ franchiseClinicDetails })
);
export const getFranchiseClinicFormData = createAction(
    ActionType.getFranchiseClinicFormData,
    (userId: GetFranchiseClinicFormDataQueryVariables) => ({ userId })
  );

  export const getFranchiseClinicFormDataSuccess = createAction(
    ActionType.getFranchiseClinicFormDataSuccess,
    (franchiseClinicFormDataDetails: GetFranchiseClinicFormDataQuery) => ({ franchiseClinicFormDataDetails })
  );
