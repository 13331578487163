import { Component, Input, OnChanges } from '@angular/core';

import { Roles } from '@app/core/util/common';
import { D1Data, eye } from '@app/core/treatment-engine/interfaces';
import { TreatmentService } from '@app/core/services/treatment.service';
import { initializeFacdDetails } from '@app/core/util/treatment-output-details';
import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
    selector: 'app-facd-summary-details',
    templateUrl: './facd-summary-details.component.html',
    styleUrls: ['./facd-summary-details.component.scss'],
})
export class FacdSummaryDetailsComponent implements OnChanges {
    sagHeightUnitValRight = '';
    sagHeightUnitValLeft = '';
    sagittalHeightDiffRight = '';
    sagittalHeightDiffLeft = '';
    facdData: D1Data = initializeFacdDetails;

    @Input() facdSummaryData: D1Data = initializeFacdDetails;

    currentUserRole = this._credentialService?.credentials?.role

    constructor(
        private _treatmentService: TreatmentService,
        private _credentialService: CredentialsService
    ) {}

    ngOnChanges(): void {

        // in the facd summary page, there are some values that are calculated based on input values in facd
        // from the calc sheet, it can be seen for question 6, 8 those red values are calculated and shown on summary ui with facd data as input
        this.sagHeightUnitValRight = 'micron at ';
        this.sagHeightUnitValLeft = 'micron at ';
        this.sagittalHeightDiffRight = 'micron at ';
        this.sagittalHeightDiffLeft = 'micron at ';
        let redValueCollection = {
            valueRight: 0,
            valueLeft: 0,
            valueRight_2: 0,
            valueLeft_2: 0,
        };
        redValueCollection = this._treatmentService.d1OtherRedValues(this.facdSummaryData);
        this.sagHeightUnitValRight = this.sagHeightUnitValRight + redValueCollection.valueRight;
        this.sagHeightUnitValLeft = this.sagHeightUnitValLeft + redValueCollection.valueLeft;
        this.sagittalHeightDiffRight = this.sagittalHeightDiffRight + redValueCollection.valueRight_2;
        this.sagittalHeightDiffLeft = this.sagittalHeightDiffLeft + redValueCollection.valueLeft_2;

        // for doctor, clinics show e value before loop to match
        this.facdData = {...this.facdSummaryData};
        this.facdData.question7 = {
            ...this.facdData.question7,
            right: 
            (this.facdData.question1 === eye.right || this.facdData.question1 === eye.both) && 
            (this.currentUserRole === Roles.DOCTOR || this.currentUserRole === Roles.REGULAR_CLINIC || this.currentUserRole === Roles.FRANCHISE_CLINIC)
                ? this.facdData.evalueHistory.right.evalue
                : this.facdData.question7.right
        };
        this.facdData.question7 = {
            ...this.facdData.question7,
            left: 
            (this.facdData.question1 === eye.left || this.facdData.question1 === eye.both) && 
            (this.currentUserRole === Roles.DOCTOR || this.currentUserRole === Roles.REGULAR_CLINIC || this.currentUserRole === Roles.FRANCHISE_CLINIC)
                ? this.facdData.evalueHistory.left.evalue
                : this.facdData.question7.left
        };
    }
}
