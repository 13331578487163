<i
    class="bi bi-question-circle kl-password-criteria-icon ms-1"
    placement="bottom"
    [ngbPopover]="popContent"
    popoverClass="kl-password-criteria-container"
>
</i>
<ng-template #popContent>
    <ul class="kl-password-criteria-text">
        <li>Password should be a minimum of <strong>8 characters</strong> </li>
        <li>Password should contain atleast <strong>1 capital letter</strong></li>
        <li>Password should contain atleast <strong>1 special character</strong></li>
        <li>Password should contain atleast <strong>1 number</strong></li>
    </ul>
</ng-template>
