import { GetListOfOrdersBasedOnRoleQuery } from "@app/generated/graphql";

export enum OrderDetailsFormTypes {
    LABFORM = "lab-form",
    DOCTOR_SLIP = "doctor-slip",
    VIEW_DESIGN = "view-design"
}

export enum ActionType {
    getTotalOrderListCount = '[Orders] Get total count of orders',
    getTotalOrderListCountSuccess = '[Orders] Update order list total count to state',
    getOrdersListBasedOnRole = '[Orders] Fetch orders list based on logged in user',
    getOrdersListBasedOnRoleSuccess = '[Orders] Fetched orders list and store to state',
    updatePatientIdStored = '[Orders] Save and update patientId',
    getDetailsOfUser = '[Orders] Fetch patient details',
    getDetailsOfUserSuccess = '[Orders] Fetch patient details success',
    updateOrderIdInState = '[Orders] Store/update currently active order Id',
    createOrderForRemake = '[Orders] Remake order',
    getOrderSequences = '[Patient] Get order sequences',
    getOrderSequencesSuccess = '[Patient] Get order sequences success',
}

export const orderListInitialState = {
    doctor: [],
    clinic: [],
    superadmin: [],
    distributor: [],
    designer: []
}

export interface State {
    orderTotalCount: number;
    orderListData: GetListOfOrdersBasedOnRoleQuery;
    patientIdUsed: string;
    activeOrderId: OrderRelatedIds;
    userBasicDetails: UserBasicDetails;
    orderSequences: OrderSequenceForBothEye | null;

}

export const initialState: State = {
    orderTotalCount: 0,
    orderListData: orderListInitialState,
    patientIdUsed: "",
    activeOrderId: {
        uniqueId: "",
        orderTrackingId: ""
    },
    userBasicDetails: {
        firstName: "",
        lastName: "",
        countryCode: "",
        contactNumber: 0,
        email: "string",
        referenceNumber: "",
        address: ""
    },
    orderSequences: null,
}

export interface OrderListItem {
    id: string,
    name: string,
    created_at: string,
    order_status: string,
    action: string
}

export interface UserBasicDetails {
    firstName: string;
    lastName: string;
    countryCode: string;
    contactNumber: number;
    email: string;
    referenceNumber: string;
    address: string;
}

export interface OrderRelatedIds {
    uniqueId: string;
    orderTrackingId: string;
}

export interface UserAddress {
  unitNumber:string;
  streetAddress: string;
  city: string;
  country: string;
  postalCode: string
}

export interface OrderSequenceForBothEye {
  left: number,
  right: number
}
