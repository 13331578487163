import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from '@app/static-pages/container/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'auth' },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((i) => i.AuthModule),
    },
    {
        path: 'user',
        loadChildren: () => import('./shell/shell.module').then((i) => i.ShellModule),
        canActivate: [AuthGuard],
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
