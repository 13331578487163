import { LoginResponseData } from '@app/auth/models/auth.model';
import {
    Clinic_Type_Enum,
    GetControlPanelValuesByOrderIdQuery,
    GetDetailsOfOrderByIdQuery,
    GetFormDataFilledByDoctorForDesignViewQuery,
    GetOrderDetailsByIdQuery,
    GetOrdersCountBasedOnDistributorIdQuery,
    GetUserFormData1Query,
    Manufacturer_Status_Enum,
    Order_Status_Enum,
    Order_Type_Enum,
} from '@app/generated/graphql';
import { UsersAndChargesForPaymentBaseData } from './user-price-settings.model';

export enum ActionTypes {
    roleSelectionSuccess = '[Shared] User role selection success',
    roleSelectionFailure = '[Shared] User role selection failed',
    clearCommonDetailsAfterLogin = '[Shared] Clear common details',
    clearCommonDetailsSuccess = '[Shared] Clear common details success',
    getRoleSpecificUsers = '[Shared] Get role specific users',
    getRoleSpecificUsersSuccess = '[Shared] Get role specific users success',
    getDetailsOfOrderById = '[Orders] Fetch order details based on order id',
    getDetailsOfOrderByIdSuccess = '[Orders] Successfully fetched order details',
    getFormDataFilledByDoctor = '[Orders] Get form data filled by doctor to display in tab view',
    getFormDataFilledByDoctorSuccess = '[Orders] Data filled by doctor to display in tab view fetched successfully',
    deleteUserOrDeleteUserAndReassign = '[Shared] Delete user or delete user and reassign',
    refreshUserListAfterUserDelete = '[Shared] refresh user list after user delete',
    saveUserListParams = '[Shared] save user list params',
    getOrdersForUserDetailsPage = '[Shared] Get orders for user details page',
    getOrdersForUserDetailsPageSuccess = '[Shared] Get orders for user details page success',
    getFranchiseClinicsForUserDetailsPage = '[Shared] Get franchise clinics for user details page',
    getFranchiseClinicsForUserDetailsPageSuccess = '[Shared] Get franchise clinics for user details page Success',
    getRegularClinicsForUserDetailsPage = '[Shared] Get regular clinics for user details page',
    getRegularClinicsForUserDetailsPageSuccess = '[Shared] Get regular clinics for user details page Success',
    resetUserDetailsPageExtraDetails = '[Shared] Reset user details page extra details',
    getDoctorsForUserDetailsPage = '[Shared] Get doctors for user details page',
    getDoctorsForUserDetailsPageSuccess = '[Shared] Get doctors for user details page Success',
    getDistributorsForUserDetailsPage = '[Shared] Get distributors for user details page',
    getDistributorsForUserDetailsPageSuccess = '[Shared] Get distributors for user details page Success',
    getContractManufacturersForUserDetailsPage = '[Shared] Get contract manufacturers for user details page',
    getContractManufacturersForUserDetailsPageSuccess = '[Shared] Get contract manufacturers for user details page success',
    getPatientsForUserDetailsPage = '[Shared] Get patients for user details page',
    getPatientsForUserDetailsPageSuccess = '[Shared] Get patients for user details page Success',
    getTotalCountOfDistributorForUserDetailsPage = '[Shared] Get total count of distributor for user details page',
    getTotalCountOfDistributorForUserDetailsPageSuccess = '[Shared] Get total count of distributor for user details page success',
    getTotalCountOfClinicForUserDetailsPage = '[Shared] Get total count of clinic for user details page',
    getTotalCountOfClinicForUserDetailsPageSuccess = '[Shared] Get total count of clinic for user details page success',
    getTotalCountOfContractManufacturerForUserDetailsPage = '[Shared] Get total count of contract manufacturer for user details page',
    getTotalCountOfContractManufacturerForUserDetailsPageSuccess = '[Shared] Get total count of contract manufacturer for user details page success',
    getTotalCountOfOrdersForUserDetailsPage = '[Shared] Get total count of Orders for user details page',
    getTotalCountOfOrdersForUserDetailsPageSuccess = '[Shared] Get total count of Orders for user details page success',
    getTotalCountOfDoctorForUserDetailsPage = '[Shared] Get total count of Doctor for user details page',
    getTotalCountOfDoctorForUserDetailsPageSuccess = '[Shared] Get total count of Doctor for user details page success',
    getTotalCountOfPatientForUserDetailsPage = '[Shared] Get total count of Patient for user details page',
    getTotalCountOfPatientForUserDetailsPageSuccess = '[Shared] Get total count of Patient for user details page success',
    updateOrderStatus = '[ Order ]update order status by user',
    updateOrderStatusSuccess = '[ Order ]update order status by user success',
    getControlPanelDetailsByOrderId = '[Shared] get the control panel details by order id',
    getControlPanelDetailsByOrderIdSuccess = '[Shared] get the control panel details by order id success',
    getDistributorByDoctorAndClinicId = '[Shared] Get distributor id by doctor and clinic id',
    getDistributorByDoctorAndClinicIdSuccess = '[Shared] Get distributor id by doctor and clinic id success',
    getFilterByValues = '[Shared] Get filter by values',
    getFilterByValuesSuccess = '[Shared] Get filter by values success',
    saveAppliedFilters = '[Shared] Get applied filters',
    getPatientFormData = '[Patient] get patient form data',
    getPatientFormDataSuccess = '[Patient] get patient form data success',
    getOrdersForManufacturer = '[Distributors] Get order summary list to show in contract manufacturer login',
    getOrdersForManufacturerSuccess = '[Distributors] Get orders for contract manufacturer success',
    getTotalOrderCountForManufacturer = '[Distributors] Get total count of orders for manufacturer',
    getTotalOrderCountForManufacturerSuccess = '[Distributors] Get total count of orders for manufacturer success',
    getDistributorDataForManufacturer = '[Distributors] Get distributors form data for displaying at manufacturer side',
    getDistributorDataForManufacturerSuccess = '[Distributors] Get distributors form data for displaying at manufacturer side success',
    insertOrderStatusTrackingData = '[Orders] update order status to tracking table',
    getOrderDetailsByOrderId = '[Orders] get order status details by order id',
    getOrderDetailsByOrderIdSuccess = '[Orders] get order status details by order id success',
    bulkUpdateOrderStatus = '[Orders] Bulk update order status',
    getDesignerDetailsFromDistributorId = '[Shared] Get designer details from distributor id',
    getDistributorDetailsFromClinicId = '[Shared] Get distributor details from clinic id',
    getRoleHierarchyForLoggedInUser = '[Shared] Get role hierarchy for logged in user',
    getRoleHierarchyForLoggedInUserSuccess = '[Shared] Get role hierarchy for logged in user success',
    getUserIdAndPriceDataForPayment = '[Payment] Get id, price settings data of users above doctors',
    getUserIdAndPriceDataForPaymentSuccess = '[Payment] Fetched id, price settings data of users and processed',
    insertToPaymentCollection = '[Payment] Insert to payment table to get total sales and cost for an order',
    insertInCostTable = '[Cost] Insert in cost table',
    getDeletingUserSuperiors = '[Shared] Get deleting user superior',
    getDeletingUserSuperiorSuccess = '[Shared] Get deleting user superior success',
    updateBuyerRoleIdToPaymentId = '[ Payment ] Update buyer role_id when regular_clinic / selective_distributor getting upgraded',
    updateBuyerRoleIdToPaymentIdSuccess = '[ Payment ] Update buyer role_id when regular_clinic / selective_distributor getting upgraded success',
    updateSellerRoleIdToPaymentId = '[ Payment ] Update seller role_id when regular_clinic / selective_distributor getting upgraded',
    updateSellerRoleIdToPaymentIdSuccess = '[ Payment ] Update seller role_id when regular_clinic / selective_distributor getting upgraded success',
    resetPatientDetails = '[Shared] reset patient details',
    updateUserRoleDetailsJSON = '[Shared] update user role details json',
  }

export interface SharedState {
    commonDetailsAfterLogin: LoginResponseData;
    orderDetailsBasedOnId: GetDetailsOfOrderByIdQuery;
    formDataFilledForDesignView: GetFormDataFilledByDoctorForDesignViewQuery;
    roleSpecificUsers: { name: string; id: string, email?: string }[] | null;
    userListParams: any;
    contractManufacturerForUserDetailsPage: ContractManufacturerForUserDetailsPage[];
    ordersForUserDetailsPage: OrderForUserDetailsPage[] | [];
    franchiseClinicsForUserDetailsPage: ClinicForUserDetailsPage[];
    regularClinicsForUserDetailsPage: ClinicForUserDetailsPage[];
    patientsForUserDetailsPage: PatientForUserDetailsPage[] | [];
    doctorsForUserDetailsPage: DoctorForUserDetailsPage[] | [];
    distributorsForUserDetailsPage: DistributorForUserDetailsPage[] | [];
    totalCountOfDistributorForUserDetailsPage: number;
    totalCountOfClinicForUserDetailsPage: number;
    totalCountOfContractManufacturerForUserDetailsPage: number;
    totalCountOfOrdersForUserDetailsPage: number;
    totalCountOfDoctorForUserDetailsPage: number;
    totalCountOfPatientForUserDetailsPage: number;
    controlPanelDetailsByOrderId: GetControlPanelValuesByOrderIdQuery;
    filterBy: string[] | null;
    appliedFilters: FilterParams | null;
    patientDetails: GetUserFormData1Query | null;
    orderListForManufacturer: any;
    ordersListCount: GetOrdersCountBasedOnDistributorIdQuery;
    distributorCommonFormData: any;
    orderDetails: GetOrderDetailsByIdQuery | null;
    roleHierarchyForLoggedInUser: RoleHierarchyForLoggedInUser | null;
    userIdAndPriceDataForPayment: UsersAndChargesForPaymentBaseData | null;
    deletingUserSuperiorId: string | null;
}

export const initialState: SharedState = {
    commonDetailsAfterLogin: {
        token: '',
        roleMappingId: '',
        userId: '',
        role: '',
        roleId: '',
        clinicId: '',
        clinicType: '',
        distributorId: '',
    },
    orderDetailsBasedOnId: {
        order: [],
    },
    formDataFilledForDesignView: {
        facd: [],
        sat: [],
        mat: [],
    },
    roleSpecificUsers: null,
    userListParams: {},
    contractManufacturerForUserDetailsPage: [],
    franchiseClinicsForUserDetailsPage: [],
    regularClinicsForUserDetailsPage: [],
    doctorsForUserDetailsPage: [],
    ordersForUserDetailsPage: [],
    patientsForUserDetailsPage: [],
    distributorsForUserDetailsPage: [],
    totalCountOfClinicForUserDetailsPage: 0,
    totalCountOfDistributorForUserDetailsPage: 0,
    totalCountOfContractManufacturerForUserDetailsPage: 0,
    totalCountOfOrdersForUserDetailsPage: 0,
    totalCountOfDoctorForUserDetailsPage: 0,
    totalCountOfPatientForUserDetailsPage: 0,
    controlPanelDetailsByOrderId: { order: [] },
    filterBy: null,
    appliedFilters: null,
    patientDetails: null,
    orderListForManufacturer: [],
    ordersListCount: { order_aggregate: {} },
    distributorCommonFormData: { user: {}, user_role_mapping: [] },
    orderDetails: null,
    roleHierarchyForLoggedInUser: null,
    userIdAndPriceDataForPayment: null,
    deletingUserSuperiorId: null,
};

export interface PriceSettingFields {
    newToric: number;
    newSpherical: number;
    amendToric: number;
    amendSpherical: number;
    remakeToric: number;
    remakeSpherical: number;
}

export interface UserDeleteConfirmModalData {
    heading: string;
    message: string;
    deleteAndAssignMessage: string;
    deleteMessage: string;
    data: any;
    role: string;
    showCancelButton: boolean;
    event: string;
    roleIdentifier?: string,
}
export interface ContractManufacturerForUserDetailsPage {
    name: string;
    contactNumber: string;
    licenseNumber: string;
}

export interface OrderForUserDetailsPage {
    orderId: string;
    patientName: string;
    date: string;
    status: string;
}

export interface ClinicForUserDetailsPage {
    name: string;
    contactNumber: string;
    clinicType: string;
    registerNumber: string;
}

export interface PatientForUserDetailsPage {
    name: string;
    referenceNumber: string;
    contactNumber: string;
}

export interface DoctorForUserDetailsPage {
    name: string;
    contactNumber: string;
}

export interface DistributorForUserDetailsPage {
    name: string;
    contactNumber: string;
    distributorLicenseNumber: string;
    distributorType: string;
}

export interface DesignerForUserDetailsPage {
    name: string;
    contactNumber: string;
    companyRegisterNumber: string;
}

export interface OrderDetails {
    id: any;
    facd_id?: any | null;
    sat_id?: any | null;
    mat_id?: any | null;
    side?: string | null;
    drformjson?: any | null;
    order_status: Order_Status_Enum;
    manufacturer_status?: Manufacturer_Status_Enum;
    order_type?: Order_Type_Enum | null;
    order_tracking_number?: string | null;
    created_at: any;
    user_id: any;
    doctor_id: any;
    clinic_id: any;
    distributor_id: any;
    clinic_type?: Clinic_Type_Enum | null;
    is_paid: boolean;
    patient_category?: string | null;
    is_approved?: boolean | null;
    labformjson?: any | null;
    order_code?: string | null;
    contract_manufacturer_id?: any | null;
    design_type?: string | null;
    order_sequence?: number | null;
}

export interface PriceSettingsCommonFields {
    newToric: number;
    amendToric: number;
    remakeToric: number;
    newSpherical: number;
    amendSpherical: number;
    remakeSpherical: number;
}
export interface PriceFieldGenericType {
    [key: string]: number;
}

export interface FilterTypesRef {
    name: string;
    value: string;
}

export interface FilterParams {
    filterType: string;
    filterValue: string;
    startDate: { year: number; month: number; day: number };
    endDate: { year: number; month: number; day: number };
}

export interface PatientDetailsFormData {
    id: string;
    first_name: string;
    last_name: string;
    reference_number: string;
    country_code: string;
    contact_number: string;
    email: string;
    nationality: string;
    age: number;
    dob: {
        year: number;
        month: number;
        day: number;
    };
    gender: string;
    clinic_type?: string;
    clinic?: string;
    regular_clinics?: string;
    franchise_clinics?: string;
    doctor?: string;
    leftEyePower: {
        sphere: number;
        sphereSign: string;
        cyl: number;
        cylSign: string;
        axis: number;
    };
    rightEyePower: {
        sphere: number;
        sphereSign: string;
        cyl: number;
        cylSign: string;
        axis: number;
    };
    leftEyeAxialLength: number;
    rightEyeAxialLength: number;
    address: {
        unitNumber: string;
        streetAddress: string;
        city: string;
        country: string;
        postalCode: string;
    };
    emergencyContact: {
        name: string;
        relationship: string;
        contact_number: string;
        country_code: string;
    };
    language: string;
    care_of: string;
}

export interface UpdatePatientDetails {
    country_code: string;
    contact_number: number;
    updated_by: string;
    email: string;
    care_of?: string | null;
}

export interface UpdatePatientRoleDetails {
    nationality: string;
    age: number;
    dob: {
        year: number;
        month: number;
        day: number;
    };
    gender: string;
    clinic: string;
    clinic_type: string;
    doctor: string;
    leftEyePower: {
        sphere: number;
        sphereSign: string;
        cyl: number;
        cylSign: string;
        axis: number;
    };
    rightEyePower: {
        sphere: number;
        sphereSign: string;
        cyl: number;
        cylSign: string;
        axis: number;
    };
    leftEyeAxialLength: number;
    rightEyeAxialLength: number;
    address: {
        unitNumber: string;
        streetAddress: string;
        city: string;
        country: string;
        postalCode: string;
    };
    emergencyContact: {
        name: string;
        relationship: string;
        contact_number: string;
        country_code: string;
    };
    language: string;
}

export interface PatientAddress {
    unitNumber: string;
    streetAddress: string;
    city: string;
    country: string;
    postalCode: string;
};

export interface EmergencyContact {
  name: string,
  relationship: string;
  contact_number: string;
  country_code: string;
}

export interface UpdatePatientData {
    userDetails: UpdatePatientDetails;
    roleDetails: UpdatePatientRoleDetails;
}

export enum PatientFormEnum {
    PATIENT_BASIC_INFO = 'patientBasicInfo',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    PATIENT_REFERENCE_NUMBER = 'patient_reference_number',
    COUNTRY_CODE = 'country_code',
    CONTACT_NUMBER = 'contact_number',
    EMAIL = 'email',
    NATIONALITY = 'nationality',
    AGE = 'age',
    LEFT_EYE_POWER = 'leftEyePower',
    RIGHT_EYE_POWER = 'rightEyePower',
    SPHERE = 'sphere',
    CYL = 'cyl',
    AXIS = 'axis',
    LEFT_EYE_AXIAL_LENGTH = 'leftEyeAxialLength',
    RIGHT_EYE_AXIAL_LENGTH = 'rightEyeAxialLength',
    DOB = 'dob',
    GENDER = 'gender',
    CLINICS = 'clinics',
    FRANCHISE_CLINICS = 'franchise_clinics',
    REGULAR_CLINICS = 'regular_clinics',
    DOCTOR = 'doctor',
    ADDRESS = 'address',
    UNIT_NUMBER = 'unitNumber',
    STREET_ADDRESS = 'streetAddress',
    CITY = 'city',
    COUNTRY = 'country',
    POSTAL_CODE = 'postalCode',
    EMERGENCY_CONTACT = 'emergencyContact',
    CONTACT_NAME = 'name',
    RELATIONSHIP = 'relationship',
    CONTACT_COUNTRY_CODE = 'country_code',
    LANGUAGE = 'language',
}

export interface DistributorCommonDetailsForManufacturer {
    name: string;
    email: string;
    contact_number: string;
    country_code: string;
    address: string;
    device_licence_no: string;
    distributor_licence_number: string;
    registration_number: string;
}

export type AdditionalDetailsTableHeadings = {
    [key: string]: {
        [key: string]: String[];
    };
};

export interface OrderStatusHierarchy {
    status: Order_Status_Enum;
    hierarchy: number;
}
export interface ManufacturerStatusHierarchy {
    status: Manufacturer_Status_Enum;
    hierarchy: number;
}
export interface orderStatusDateModal {
    submittedDate: string;
    orderedDate: string;
    shippedDate: string;
    outForDeliveryDate: string;
    deliveredDate: string;
    cancelledDate: string;
}

export interface orderStatusDateModal {
    submittedDate: string;
    orderedDate: string;
    shippedDate: string;
    outForDeliveryDate: string;
    deliveredDate: string;
    cancelledDate: string;
}

export interface RoleHierarchyForLoggedInUser {
    superAdminId: string | null;
    designerId: string | null;
    distributorId: string | null;
    clinicId: string | null;
    doctorId: string | null;
    designerRoleId?: string | null;
    distributorRoleId?: string | null;
}

export interface UserSuperiors {
  superAdminId: string | null;
  designerId: string | null;
  distributorId: string | null;
  clinicId: string | null;
  doctorId: string | null;
}

export interface CustomDate {
    year: number;
    month: number;
    day: number;
}

export type User = {
  id: string;
  name: string;
  roleName?: string;
  email?: string;
  address?: string;
  role_id?: string;
  role_mapping_id?: string;
  token?: string;
}

export type FooterDropdownItems = {
  key: string,
  value: string,
  isSelected: boolean,
}

export type UserRole = {
  id: string,
  role: Role
}

export type Role = {
  name: string;
  id: string
}

export enum EVENT_MSG {
  UNLINK_FAMILY_MEMBER = 'link family member and register the patient as new',
  DEFAULT = ''
}

export type EventExtra = {
  [key: string]: string
}
