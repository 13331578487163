<div class="modal-header no-border">
    <h5>Profile Information</h5>
    <div class="justify-content-end">
        <img 
            class="close-icon cursor-pointer" 
            src="/assets/icons/navbar-profile/popup-close.png" 
            (click)="onCloseEditUserProfileModal()" 
        />
    </div>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="edit-profile-form-container">
                <form [formGroup]="userProfileForm" (ngSubmit)="updateUserProfileDetails()">
                    <div class="row mb-2">
                        <label class="kl-text2-type-1 mb-2">Name</label>
                        <div class="input-with-icon-type-1">
                            <input
                                type="text"
                                formControlName="name"
                                class="kl-input-text input-text-type-1"
                                [ngClass]="disableFields ? 'disabled-color' : ''"
                            >
                        </div>
                        <div class="error-message-container-common">
                            <p
                                class="error-text m-1"
                                *ngIf="isDirty('name') &&
                                    isRequired('name')
                                "
                            >
                                Please enter name
                            </p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label class="kl-text2-type-1 mb-2">Email</label>
                        <div class="input-with-icon-type-1">
                            <input
                                type="email"
                                formControlName="email"
                                class="kl-input-text input-text-type-1 disabled-color"
                            >
                        </div>
                        <div class="error-message-container-common">
                            <p
                                class="error-text m-1"
                                *ngIf="isDirty('email') &&
                                    isRequired('email')
                                "
                            >
                                Please enter email
                            </p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label class="kl-text2-type-1 mb-2">Phone Number</label>
                        <div class="input-with-icon-type-1">
                            <input
                                type="email"
                                formControlName="contact_number"
                                class="kl-input-text input-text-type-1"
                                [ngClass]="disableFields ? 'disabled-color' : ''"
                            >
                        </div>
                        <div class="error-message-container-common">
                            <p
                                class="error-text m-1"
                                *ngIf="isDirty('contact_number') &&
                                    isRequired('contact_number')
                                "
                            >
                                Please enter phone number
                            </p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label class="kl-text2-type-1 mb-2">Address</label>
                        <div class="input-with-icon-type-1">
                            <input
                                type="email"
                                formControlName="address"
                                class="kl-input-text input-text-type-1"
                                [ngClass]="disableFields ? 'disabled-color' : ''"
                            >
                        </div>
                        <div class="error-message-container-common">
                            <p
                                class="error-text m-1"
                                *ngIf="isDirty('address') &&
                                    isRequired('address')
                                "
                            >
                                Please enter address
                            </p>
                        </div>
                    </div>
                    <div class="d-flex flex-md-row flex-column justify-content-end pt-3">
                        <button type="submit" class="kl-btn-add-save-user" *ngIf="!disableFields">
                            <span style="font-size: 14px;">  <img
                                [src]="editImgSrc"
                                class="cursor-pointer"                                
                            /> {{submitButtonText}}
                            </span>
                        </button>
                        <button type="button" class="kl-btn-add-save-user" (click)="editUserDetails()" *ngIf="disableFields">
                            <span style="font-size: 14px;">  <img
                                [src]="editImgSrc"
                                class="cursor-pointer"                                
                            /> {{submitButtonText}}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>