import { AData, axis, eye } from "../interfaces";
import { D1 } from "./D1";

export class A {
  D6: eye;
  F7: number;
  I7: number;
  F9: number;
  I9: number;
  F18: number;
  I18: number;
  F30: boolean;
  F31: boolean;
  F32: boolean;
  F33: boolean;
  F34: boolean;
  F35: boolean;
  I30: boolean;
  I31: boolean;
  I32: boolean;
  I33: boolean;
  I34: boolean;
  I35: boolean;
  F36: number;
  F37: number;
  F38: axis;
  I36: number;
  I37: number;
  I38: axis;
  F39: boolean;
  I39: boolean;
  F41: boolean;
  I41: boolean;
  F43: number;
  I43: number;

  constructor(data: AData, D1: D1) {
    this.D6 = data.eyes;
    this.F7 = data.eyes !== eye.left ? data.question1.right : 0;
    this.I7 = data.eyes !== eye.right ? data.question1.left : 0;
    this.F9 = data.eyes !== eye.left ? data.question2.right : 0;
    this.I9 = data.eyes !== eye.right ? data.question2.left : 0;
    this.F18 = data.eyes !== eye.left ? data.question3.right : 0;
    this.I18 = data.eyes !== eye.right ? data.question3.left : 0;
    this.F30 = data.eyes !== eye.left ? data.question4.right[1] : false;
    this.I30 = data.eyes !== eye.right ? data.question4.left[1] : false;
    this.F31 = data.eyes !== eye.left ? data.question4.right[2] : false;
    this.I31 = data.eyes !== eye.right ? data.question4.left[2] : false;
    this.F32 = data.eyes !== eye.left ? data.question4.right[3] : false;
    this.I32 = data.eyes !== eye.right ? data.question4.left[3] : false;
    this.F33 = data.eyes !== eye.left ? data.question4.right[4] : false;
    this.I33 = data.eyes !== eye.right ? data.question4.left[4] : false;
    this.F34 = data.eyes !== eye.left ? data.question4.right[5] : false;
    this.I34 = data.eyes !== eye.right ? data.question4.left[5] : false;
    this.F35 = data.eyes !== eye.left ? data.question4.right[6] : false;
    this.I35 = data.eyes !== eye.right ? data.question4.left[6] : false;
    this.F36 = data.eyes !== eye.left ? data.question5.right[1] : 0;
    this.I36 = data.eyes !== eye.right ? data.question5.left[1] : 0;
    this.F37 = data.eyes !== eye.left ? data.question5.right[2] : 0;
    this.I37 = data.eyes !== eye.right ? data.question5.left[2] : 0;
    this.F38 = data.eyes !== eye.left ? data.question5.right[3] : axis.WTR;
    this.I38 = data.eyes !== eye.right ? data.question5.left[3] : axis.WTR;
    this.F39 = data.eyes !== eye.left ? data.question6.right : false;
    this.I39 = data.eyes !== eye.right ? data.question6.left : false;
    this.F41 = data.eyes !== eye.left ? data.question7.right : false;
    this.I41 = data.eyes !== eye.right ? data.question7.left : false;
    this.F43 = data.eyes !== eye.left ? data.question8.right : 0;
    this.I43 = data.eyes !== eye.right ? data.question8.left : 0;
  }
}
