<div class="container">
    <div class="row label-row">
        <div class="col-6 p-0">
            <span class="kl-text3-type-6">D1 Form</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-8">Right Eye</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-8">Left Eye</span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">HVID</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question2.right 
                    | facdDataTransform: 'MM': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question2.left 
                    | facdDataTransform: 'MM': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="col-12 p-0">
            <span class="kl-text3-type-6">Patient's Refractive Error</span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Sphere</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.right.sphere
                    | facdDataTransform: 'Diopters': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.left.sphere
                    | facdDataTransform: 'Diopters': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Cylinder</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.right.cylinder
                    | facdDataTransform: 'Diopters': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.left.cylinder
                    | facdDataTransform: 'Diopters': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Axis</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.right.axis
                    | facdDataTransform: '': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question3.left.axis
                    | facdDataTransform: '': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Ortho-k correction</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question4.right 
                    | facdDataTransform: '': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question4.left 
                    | facdDataTransform: '': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Flat - K</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question5.right
                    | facdDataTransform: 'Diopters only': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question5.left
                    | facdDataTransform: 'Diopters only': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Cornea sag height</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question6.right
                    | facdDataTransform: sagHeightUnitValRight: facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question6.left
                    | facdDataTransform: sagHeightUnitValLeft: facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Average cornea e-value</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question7.right
                    | facdDataTransform: '': facdData.question1: 'right': true }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question7.left
                    | facdDataTransform: '': facdData.question1: 'left' : true }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Cornea sagittal height difference</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question8.a.right
                    | facdDataTransform: sagittalHeightDiffRight: facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question8.a.left
                    | facdDataTransform: sagittalHeightDiffLeft: facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Corneal toricity</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question8.b.right
                    | facdDataTransform: 'Diopters': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question8.b.left
                    | facdDataTransform: 'Diopters': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Edge lift profile</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question9.right
                    | facdDataTransform: '': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question9.left
                    | facdDataTransform: '': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-facd-details">
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Supercharge myopia</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question10.right 
                    ? 'Yes'
                    : 'Not Necessary' 
                    | facdDataTransform: '': facdData.question1: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ facdData.question10.left
                    ? 'Yes'
                    : 'Not Necessary'
                    | facdDataTransform: '': facdData.question1: 'left' }}
            </span>
        </div>
    </div>
</div>