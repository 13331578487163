<div class="card date-list custom-scrollbar">
    <div class="card-header kl-text3-type-6">Review Dates</div>
    <div id="review-date-selector" class="kl-select-container mx-auto my-2">
        <select class="kl-select" #date (change)="onDateClickedFromSelectDropdown(date.value)">
            <option *ngFor="let date of reviewDates; let i = index" [value]="date?.id" [selected]="i === 0">
                {{ date?.starting_date | date : 'dd/MM/yyy' }}
            </option>
        </select>
    </div>
    <ul id="review-date-card" class="list-group list-group-flush">
        <ng-container *ngFor="let date of reviewDates; let i = index">
            <li
                (click)="onDateClicked(date.id, i)"
                [ngClass]="{ 'active-type-2 active-arrow': activeLink === i, 'arrow-item': activeLink !== i }"
                class="list-group-item kl-text5-type-1 d-flex justify-content-between align-items-center"
            >
                {{ date?.starting_date | date : 'dd/MM/yyy' }}
            </li>
        </ng-container>
    </ul>
</div>
