import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { Roles } from '@app/core/util/common';
import { Patient, SelectedRoleDetails } from '@app/auth/models/auth.model';
import { fetchAllRolesOfUser, roleSelectionInitiate, saveUserIdOfLoggedInUser } from '@app/auth/actions/auth.action';

@Component({
    selector: 'app-sibling-selection-form',
    templateUrl: './sibling-selection-form.component.html',
    styleUrls: ['./sibling-selection-form.component.scss'],
})
export class SiblingSelectionFormComponent implements OnInit {
    siblingSelectionForm: FormGroup = new FormGroup({});

    @Input() isModal = false;
    @Output() closeSelectSiblingForm = new EventEmitter<boolean>();
    @Input() familyMembers: Patient[] = [];
    constructor(private _fb: FormBuilder, private _store: Store) {}

    ngOnInit(): void {
        this.initForm();
    }

    /**
     * Create sibling Selection form and control
     * @param
     * @return void
     */
    initForm = (): void => {
        this.siblingSelectionForm = this._fb.group({
            sibling: ['', Validators.required],
        });
    };

    /**
     * Checks if the control is touched
     * @param name of the form control
     * @return boolean
     */
    isTouched = (name: string): boolean => {
        return this.siblingSelectionForm.controls[name].touched;
    };

    /**
     * Checks if the control is dirty.
     * @param name of the form control.
     */
    isDirty = (name: string): boolean => {
        return this.siblingSelectionForm.controls[name]?.dirty;
    };

    /**
     * Checks if the control is required.
     * @param name of the form control.
     */
    isRequired = (name: string): boolean => {
        return this.siblingSelectionForm.controls[name]?.errors?.['required'];
    };

    onSubmit = (): void => {
        if (this.siblingSelectionForm.valid) {
            const selectedSiblingId = this.siblingSelectionForm.get('sibling')?.value;
            const selectedSibling = this.familyMembers?.find((member) => member?.id === selectedSiblingId);
            if (selectedSibling?.clinicType === Roles.REGULAR_CLINIC) {
                return;
            }
            this._store.dispatch(saveUserIdOfLoggedInUser(selectedSibling?.id || ''));
            const loggedInPatient = {
                selectedRole: selectedSibling?.role_mapping_id || '',
                role: { id: selectedSibling?.role_id, name: Roles.PATIENT } as SelectedRoleDetails,
            };
            this._store.dispatch(roleSelectionInitiate(loggedInPatient, false));
            this._store.dispatch(fetchAllRolesOfUser(selectedSibling?.id || ''));
            this.closeSelectSiblingForm.emit(true);
        }
    };

    onCloseModal = () => {
        this.closeSelectSiblingForm.emit(true);
    };
}
