import { Pipe, PipeTransform } from '@angular/core';
import { amendFormData } from '@app/core/util/amend-form-data';

@Pipe({
    name: 'amendDataTransform',
})
export class AmendDataTransformPipe implements PipeTransform {
    transform(value: string | number | boolean, indexPos: number, eyeSelection: string | undefined, eyePos: string): unknown {
        if (
            eyeSelection === 'RIGHT ONLY' && 
            eyePos === 'right' && 
            value !== ''
        ) {
            value = indexPos >= 1 ? this.getValueLabel(value, indexPos) : value;
            return value;
        } else if (
            eyeSelection === 'LEFT ONLY' && 
            eyePos === 'left' && 
            value !== ''
        ) {
            value = indexPos >= 1 ? this.getValueLabel(value, indexPos) : value;
            return value;
        } else if (
            eyeSelection === 'RIGHT AND LEFT' && 
            (eyePos === 'left' || eyePos === 'right') && 
            value !== ''
        ) {
            value = indexPos >= 1 ? this.getValueLabel(value, indexPos) : value;
            return value;
        }
        return '--';
    }

    /**
     * Converts value to label based on excel sheet
     * @param value Actual value which is to be transformed
     * @param indexPos position of the value in the questions data for amend form
     * @returns label for corresponding value
     */
    getValueLabel = (value: string | number | boolean, indexPos: number): string => {
        const inputElementData = amendFormData[indexPos].inputElementData;
        const element = inputElementData.find((item) => item.value === value);
        return element ? element.label : '';
    };
}
