<div class="container">
    <div class="row label-row">
        <div class="col-12 p-0">
            <span class="kl-text3-type-6">Lens Info</span>
        </div>
    </div>
    <div
        class="row lens-details-row"
    >
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Lens Material</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-6 p-0 d-flex align-items-center">
            <div
                class="color-section me-2"
                [ngStyle]="{
                    'background': lensDetails.lensColorRight
                }"
                *ngIf="lensDetails.lensMaterialRight"
            ></div>
            <span class="kl-text5-type-7 me-2">{{ lensMaterialRight }}</span>
            <div
                class="color-section me-2"
                [ngStyle]="{
                    'background': lensDetails.lensColorLeft
                }"
                *ngIf="lensDetails.lensMaterialLeft"
            ></div>
            <span class="kl-text5-type-7">{{ lensMaterialLeft }}</span>
        </div>
    </div>
    <div
        class="row lens-details-row"
    >
        <div class="d1-field-name col-5 p-0">
            <span class="kl-text5-type-6">Lab</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-6 p-0">
            <span class="kl-text5-type-7">{{ lensDetails.lab_of_choice }}</span>
        </div>
    </div>
    <div
        class="button-container"
        *ngIf="!hideButtons"
    >
        <button
            type="button"
            class="kl-btn-cancel-action kl-btn-outline me-2 mb-2"
            (click)="onPreviousSelection()"
        >
            <span>Previous</span>
        </button>
        <button
            type="button"
            class="kl-btn-cancel-action kl-btn-outline me-2 mb-2"
            (click)="onCancel()"
        >
            <span>Cancel</span>
        </button>
        <button
            type="submit"
            class="kl-save-draft-btn me-2 mb-2"
            (click)="onSaveDraft()"
        >
            <span>Save Draft</span>
        </button>
        <button
            type="submit"
            class="kl-btn-add-save-user"
            (click)="submitOrder()"
        >
            <span>Submit Order</span>
        </button>
    </div>
</div>
