import { Action, createReducer, on } from "@ngrx/store";

import { State, initialState } from "../models/franchise-clinics.model";
import { getExclusiveDistributorListSuccess, getFranchiseClinicFormDataSuccess, getFranchiseClinicListCountSuccess, getFranchiseClinicsListBasedOnRoleSuccess } from "../actions/franchise-clinics.action";
import { resetAppState } from "@app/actions";

const _reducer = createReducer(
    initialState,
    on(getFranchiseClinicListCountSuccess, (state, payload) => ({
        ...state,
        franchiseClinicTotalCount: payload.franchiseClinicListCount,
    })),
    on(getFranchiseClinicsListBasedOnRoleSuccess, (state, payload) => ({
        ...state,
        franchiseClinicListData: payload.franchiseClinicListData,
    })),
    on(getExclusiveDistributorListSuccess, (state, payload) => ({
        ...state,
        exclusiveDistributorList: payload?.getExclusiveDistributorList,
    })),
    on(getFranchiseClinicFormDataSuccess, (state, payload) => ({
        ...state,
        franchiseClinicFormDataDetails: payload?.franchiseClinicFormDataDetails,
    })),
    on(resetAppState, () => initialState)
);

export function franchiseClinicReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
