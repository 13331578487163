import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs';

import { CustomDate, PatientDetailsFormData } from '@app/shared/models/shared.model';
import { CommonService } from '@app/core/services/common.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { CareOfPatient } from '@app/patients/models/patients.model';
import { FormMode } from '@app/ui/models/ui.model';
import { Roles } from '@app/core/util/common';

@Component({
    selector: 'app-patient-basic-info-form',
    templateUrl: './patient-basic-info-form.component.html',
    styleUrls: ['./patient-basic-info-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientBasicInfoFormComponent implements OnInit, AfterViewInit, OnDestroy {
    country_code = '+65';
    // Today's date for limiting patient birthday select after today date.
    // Fix min date of dob calender.
    minDate = { year: 1900, month: 1, day: 1 };
    // Max date of dob calender.
    maxDate: NgbDateStruct = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };
    // Used to render eye power form in html.
    eyeSides = [
      { side: 'rightEyePower', label: 'Right eye power' },
      { side: 'leftEyePower', label: 'Left eye power'}
    ];
    // Used to render axial length in html.
    axialLengths = [
      {label: 'Right eye axial length', formControlName: 'rightEyeAxialLength'},
      {label: 'Left eye axial length', formControlName: 'leftEyeAxialLength'},
    ];
    isEmailExist = false;
    isAlive = true;
    isEmailTaken = false;
    role = '';
    isDependable = false;
    @Input() showSiblingsUI = false;
    @Input() patientBasicInfo!: FormGroup;
    @Input() submitted = false;
    @Input() formData!: PatientDetailsFormData;
    @Input() userValidationData!: { id: string, email: string };
    @Input() mode: FormMode = null;
    @Input() careOf!: CareOfPatient | null;
    @ViewChild('phoneInput') phoneInput!: ElementRef;
    @ViewChild('eyePowerElements') eyePowerElements!:ElementRef;
    @ViewChild('axialLengthElements') axialLengthElements!: ElementRef;
    @Output() sendValidity = new EventEmitter<{isValid: boolean, controlName: string}>();
    @Output() sendShowSiblingsUIEvent = new EventEmitter<boolean>();
    @Output() sendIsDependableEvent = new EventEmitter<boolean>();

    constructor(private _renderer: Renderer2, private _commonService: CommonService, private _cdf: ChangeDetectorRef, private _credentialService: CredentialsService, private _renderer2: Renderer2){}

    ngOnInit(): void {
      this.role = this._credentialService.credentials?.role || '';
      this.patientBasicInfo.patchValue(this.formData);
      this.patchCurrentAge();
      this.patientBasicInfo
          .get('email')
          ?.valueChanges.pipe(
              distinctUntilChanged(),
              debounceTime(300),
              takeWhile(() => this.isAlive)
          )
          .subscribe(async () => {
              const emailCtrl = this.patientBasicInfo?.get('email') as FormControl;
              const error = await this._commonService.validateUserEmail(emailCtrl, this.userValidationData);
              if (error) {
                  this.sendValidity.emit({isValid: false, controlName: 'email'});
                  this.isEmailTaken = true;
                  this.patientBasicInfo.get('email')?.setErrors(error);
                  if(this.patientBasicInfo.controls['email']?.errors?.['isPatient']){
                    this.sendShowSiblingsUIEvent.emit(true);
                  }
                  this._cdf.detectChanges();
              } else {
                  this.sendValidity.emit({isValid: true, controlName: 'email'});
                  this.isEmailTaken = false;
                  this.sendShowSiblingsUIEvent.emit(false);
              }
          });
    }

    ngAfterViewInit(): void {
      if(this.role === Roles.PATIENT){
        this.styleDisabledFields();
      }
    }

    patchCurrentAge = () => {
      const age = this.getPatientAge(this.formData?.dob);
      this.patientBasicInfo.get('age')?.patchValue(age);
    }

    /**
     *
     * @param key shares the formControlName of an input.
     * @returns true/false based on the checks.
     */
    isValid(key: string): boolean {
        return !!this.patientBasicInfo.controls[key]?.errors && this.patientBasicInfo.controls[key]?.touched;
    }

      /**
   * Function sets the country code value.
   * @param code shares the country code selected by the user.
   */
    onSelectCode = (code: string): void => {
        this.patientBasicInfo.controls['country_code'].patchValue(code);
    };

      /**
     * Function add styles for focus and blur on the input.
     * Currently used in input which takes phone number with country code.
     * @param type shares if the input is on focus or on blur.
     * @returns void
     */
    onFocusPhoneInput = (type: string): void => {
      if (type === 'focus') {
          this._renderer.setStyle(this.phoneInput.nativeElement, 'outline', '2px solid #db9600');
          return;
      }
      if (this.patientBasicInfo.get('contact_number')?.valid) {
          this._renderer.setStyle(this.phoneInput.nativeElement, 'outline', '1px solid #adadad');
          return;
      }
      this._renderer.setStyle(this.phoneInput.nativeElement, 'outline', '1px solid red');
  };

  getAxisControl = (eyeSide:string):boolean | undefined => {
    return this.patientBasicInfo.get(eyeSide)?.get('axis')?.hasError('axisError');
  }

  onDobSelect = ():void => {
   const age = this.getPatientAge(this.patientBasicInfo?.get('dob')?.value);
   this.patientBasicInfo?.get('age')?.patchValue(age);
  }

  getPatientAge = (dobIn: CustomDate):number | null => {
    if(dobIn){
      const dob = new Date(this._commonService.convertToTimestampz(dobIn));
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      if(monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())){
        age--;
      }
      return age
    }
   return null
  };

  onAddAsSiblings = ():void => {
    this.patientBasicInfo.get('email')?.setErrors(null);
    this.isEmailTaken = false;
    this.isDependable = true;
    this.sendShowSiblingsUIEvent.emit(false);
    this.sendIsDependableEvent.emit(true);
    this.sendValidity.emit({isValid: true, controlName: 'email'});
  }

  onCancelSiblings = ():void => {
    this.patientBasicInfo.get('email')?.setValue('');
    this.isDependable = false;
    this.patientBasicInfo.get('email')?.setErrors(null);
    this.sendShowSiblingsUIEvent.emit(false);
    this.sendIsDependableEvent.emit(false);
  }

  styleDisabledFields = ():void => {
    const eyePowerInputs = this.eyePowerElements.nativeElement.querySelectorAll('input');
    const axialLengthInput = this.axialLengthElements.nativeElement.querySelectorAll('input');
    eyePowerInputs.forEach((input: HTMLInputElement) => {
      if (input.disabled) {
        input.classList.add('input-disabled');
      }
    });
    axialLengthInput.forEach((input: HTMLInputElement) => {
      if (input.disabled) {
        input.classList.add('input-disabled');
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
