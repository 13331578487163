import { Action, createReducer, on } from '@ngrx/store';

import { initialState, State } from '../models/exclusive-distributors.model';
import { GetDesignerListQuery, GetExclusiveDistributorListBasedOnRoleQuery } from '@app/generated/graphql';
import { getDesignersListSuccess, getExclusiveDistributorsListBasedOnRoleSuccess, getTotalTableItemsCountSuccess } from '../actions/exclusive-distributors.action';
import { resetAppState } from '@app/actions';

const _reducer = createReducer(
    initialState,
    on(getDesignersListSuccess, (state: State, payload: { designers: GetDesignerListQuery }) => ({
      ...state,
      designers: payload.designers,
    })),
    on(getExclusiveDistributorsListBasedOnRoleSuccess, (state, payload: { payload: GetExclusiveDistributorListBasedOnRoleQuery}) => ({
      ...state,
      exclusiveDistributorsList: payload?.payload
    })),
    on(getTotalTableItemsCountSuccess, (state, payload: { tableItemCount: number }) => ({
      ...state,
      totalTableListCount: payload.tableItemCount
    })),
    on(resetAppState, () => initialState)
);

export function exclusiveDistributorsReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
