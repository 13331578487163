import { GetListofReviewDateBasedOnPatientIdQuery, GetPatientReviewDetailsQuery } from '@app/generated/graphql';

export enum ActionType {
    getListOfReviewHistoryDateBasedOnPatientId = '[ patient review ] get list of patient review dates based on patient id ',
    getListOfReviewHistoryDateBasedOnPatientIdSuccess = '[ patient review ] get list of patient review dates based on patient id success',
    getReviewHistoryDetails = '[ patient review ] get review history details based on review date',
    getReviewHistoryDetailsSuccess = '[ patient review ] get review history details based on review date success',
    resetReviewHistoryState = '[ patient review ] reset patient review history state',
}

export interface State {
    reviewDateList: GetListofReviewDateBasedOnPatientIdQuery | null;
    reviewHistoryDetails: GetPatientReviewDetailsQuery | null;
}

export const initialState: State = {
    reviewDateList: null,
    reviewHistoryDetails: null,
};

export interface ReviewHistoryDateList {
    starting_date?: string;
    id: string;
}

export interface ReviewHistoryDetailsParams {
    review_id: string;
}
