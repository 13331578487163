import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs';

import { getCountryCodeDetails } from '@app/ui/actions/ui.actions';
import { CountryCodeDetails } from '@app/ui/models/ui.model';
import { selectCountryDetails } from '@app/ui/selectors/ui.selector';



/**
 * This component is used in phone number select with country code.
 * Component renders country code.
 */

@Component({
    selector: 'app-country-code-selector',
    templateUrl: './country-code-selector.component.html',
    styleUrls: ['./country-code-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCodeSelectorComponent implements OnInit, OnDestroy {
    // Phone number details.
    @Input('country_code') country_code = '+65';
    @Output() selectedCode = new EventEmitter<string>();
    @Input() mode = '';
    dismissPhoneSelector = false;
    searchText = '';
    countryCodeDetails$ = this._store.select(selectCountryDetails);
    countryDetails: CountryCodeDetails[] | null | undefined = [];
    filteredCountryList:CountryCodeDetails[] = [];
    isAlive = true;

    constructor(private _store: Store){}

    ngOnInit(): void {
      this.countryCodeDetails$.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
        if(value){
          this.countryDetails = value;
          this.filteredCountryList = this.countryDetails;
        }
        if(!value){
          this._store.dispatch(getCountryCodeDetails());
        }
      });

    }

    keyDown(event: KeyboardEvent) {
      // Check if the character code corresponds to an alphabetic character (ignoring case)
      if (/^[a-zA-Z]$/.test(event.key)) {
        this.searchText += event.key;
      }

      if (event.key === 'Backspace') {
        this.searchText = this.searchText?.slice(0, -1); // Remove last character
      }

      this.filterCountries();

    }

    filterCountries = ():void => {
      const filterCountries = this.countryDetails?.filter(country => country.name.toLowerCase().includes(this.searchText.toLowerCase()));
      if(filterCountries && filterCountries.length > 0){
        this.filteredCountryList = filterCountries;
      }
    }

    /**
     *
     * @param code shares the code selected by user.
     */
    onCountryCodeChange = (code: string): void => {
        this.country_code = code;
        this.selectedCode.emit(code); // Emit code for parent component so that parent can set the code.
        this.dismissPhoneSelector = false; // Dismiss the dropdown.
    };

    /**
     * Function shows the dropdown to select country code.
     */
    onCountryCodeClick = (): void => {
        this.dismissPhoneSelector = true; // Show dropdown.
    };

    /**
     * Function dismiss dropdown if user clicks outside the dropdown.
     */
    onDismissCountryCodeSelector = (): void => {
        this.searchText = "";
        this.dismissPhoneSelector = false;
    };

    ngOnDestroy(): void {
      this.isAlive = false;
    }
}
