import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TreatmentHistory } from '@app/core/util/common';
import { eye } from '@app/core/treatment-engine/interfaces';
import { Order_Type_Enum } from '@app/generated/graphql';
import { State, TreatmentMethod } from '../models/patients.model';
import { initializeAmendDetails, initializeDoctorSlipDetails, initializeFacdDetails, initializeLabformDetails } from '@app/core/util/treatment-output-details';

const _selectRegularClinicList = (state: State) => (state ? state.regularClinicList : null);
const _selectDoctorsList = (state: State) => (state ? state.doctorsList : null);
const _selectFacdFormData = (state: State) => (state ? state.facdFormData : initializeFacdDetails);
const _selectPatientInfo = (state: State) => (state ? state.patientInfo : null);
const _selectPatientList = (state: State) => (state ? state.patientList : null);
const _tableTotalItemCount = (state: State) => (state ? state.totalTableListCount : null);
const _selectPatientIdBeingEdited = (state: State) => (state ? state.patientId : null);
const _selectDistributorIdOfClinic = (state: State) => (state ? state.distributorIdOfClinic : '');
const _selectDoctorSlipData = (state: State) => (state ? state.doctorSlipDetails : initializeDoctorSlipDetails);
const _selectLabFormData = (state: State) => (state ? state.labFormDetails : initializeLabformDetails);
const _selectLatestOrderDetails = (state: State) => (state ? state.latestOrderDetailsOfPatient : { order: [] });
const _selectTreatmentHistory = (state: State) => (state ? state.treatmentHistory : { order: [] });
const _selectCurrentTreatmentBasicDetails = (state: State) => (state ? state.currentTreatmentBasicDetails : {
    formType: TreatmentHistory.EMPTY,
    isPrefilledView: false,
    treatmentProcedure: TreatmentMethod.EMPTY,
    facdId: "",
    satId: "",
    matId: "",
    eye: eye.empty,
    fetchAndView: false,
    orderType: Order_Type_Enum.New,
    orderSequence: {left: 0, right: 0},
    contractManufacturerId: '',
    lens: {
      right: '',
      left: ''
    }
});
const _selectAmendFormDataFilled = (state: State) => (state ? state.amendFormData : initializeAmendDetails);
const _selectFacdForAmendDoctorSlip = (state: State) => (state ? state.facdForAmendFormDoctorSlipGeneration : {
    facd: []
});
const _selectSatForAmendDoctorSlip = (state: State) => (state ? state.satForAmendFormDoctorSlipGeneration : {
    sat: []
});
const _selectListOfOrdersOfPatient = (state: State) => (state ? state.ordersList : {
    order: [],
    totalOrders: {
        aggregate: {
          count: 0
        }
    }
});

const _selectOrderSequences = (state: State) => (state ? state.orderSequences : null);

const _selectPatientTransferRequestList = (state: State) => state.patientTransferRequestList;

const _selectTotalPatientTransferRequestListCount = (state: State) => state.totalPatientTransferRequestListCount;

const _selectLastPayloadForPatientTransferRequestListAndCount = (state: State) => state.lastPayloadForPatientTransferRequestListAndCount;

const _selectPatientFormAsDraftRequestStatus = (state: State) => state.savePatientTreatmentFormAsDraft;
const _selectDesignerIdBasedOnDoctorId = (state: State) => state.designerId;
const _selectControlPanelValuesByDesignerId = (state: State) => state.getControlPanelValuesByDesignerId;

const _selectContractManufacturerList = (state: State) => state.contractManufacturerList;

const _selectLensList = (state: State) => state.lensList;

const _selectSatData = (state: State) => state.satData;
const _selectMatData = (state: State) => state.matData;

const _selectFranchiseClinicList = (state: State) => ( state ? state.franchiseClinicList : null);
const _selectFranchiseClinicDoctorsList = (state: State) => (state ? state.franchiseClinicDoctorsList : null);
const _selectDistributorType = (state: State) => (state ? state.distributorType : null);
const _isLoopToMatchCorrect = (state: State) => (state ? state.loopToMatchCorrectness : true);
const _selectManufacturerHistoryPerPatient = (state: State) => (state ? state.manufacturerHistoryPerPatient : null);
const _selectListActiveItems = (state: State) => state.listActiveItems;
const _selectOldPatientList = (state: State) => state.oldPatientList;
const _selectTotalOldPatientCount = (state: State) => state.totalOldPatientCount;
const _selectCareOfPatient = (state: State) => (state ? state.careOfPatient : null);
const _selectPatientTransferDetails = (state: State) => (state ? state.patientTransferDetails : null);

export const selectFeature = createFeatureSelector<State>('patients');

export const selectRegularClinicList = createSelector(selectFeature, _selectRegularClinicList);
export const selectDoctorList = createSelector(selectFeature, _selectDoctorsList);
export const selectFacdFormData = createSelector(selectFeature, _selectFacdFormData);
export const selectPatientInfo = createSelector(selectFeature, _selectPatientInfo);
export const selectPatientList = createSelector(selectFeature, _selectPatientList);
export const selectTableTotalItemCount = createSelector(selectFeature, _tableTotalItemCount);
export const selectPatientIdBeingEdited = createSelector(selectFeature, _selectPatientIdBeingEdited);
export const selectDistributorIdOfClinic = createSelector(selectFeature, _selectDistributorIdOfClinic);
export const selectDoctorSlipData = createSelector(selectFeature, _selectDoctorSlipData);
export const selectLabFormData = createSelector(selectFeature, _selectLabFormData);
export const selectLatestOrderDetails = createSelector(selectFeature, _selectLatestOrderDetails);
export const selectTreatmentHistory = createSelector(selectFeature, _selectTreatmentHistory);
export const selectCurrentTreatmentBasicDetails = createSelector(selectFeature, _selectCurrentTreatmentBasicDetails);
export const selectAmendFormDataFilled = createSelector(selectFeature, _selectAmendFormDataFilled);
export const selectFacdForAmendDoctorSlip = createSelector(selectFeature, _selectFacdForAmendDoctorSlip);
export const selectSatForAmendDoctorSlip = createSelector(selectFeature, _selectSatForAmendDoctorSlip);
export const selectListOfOrdersOfPatient = createSelector(selectFeature, _selectListOfOrdersOfPatient);
export const selectPatientTransferRequestList = createSelector(selectFeature, _selectPatientTransferRequestList);
export const selectTotalPatientTransferRequestListCount = createSelector(selectFeature, _selectTotalPatientTransferRequestListCount);
export const selectLastPayloadForPatientTransferRequestListAndCount = createSelector(selectFeature, _selectLastPayloadForPatientTransferRequestListAndCount);
export const selectPatientFormAsDraftRequestStatus = createSelector(selectFeature, _selectPatientFormAsDraftRequestStatus);
export const selectDesignerIdBasedOnDoctorId = createSelector(selectFeature, _selectDesignerIdBasedOnDoctorId);
export const selectControlPanelValuesByDesignerId = createSelector(selectFeature, _selectControlPanelValuesByDesignerId);
export const selectContractManufacturerList = createSelector(selectFeature, _selectContractManufacturerList);
export const selectOrderSequences = createSelector(selectFeature, _selectOrderSequences);
export const selectLensesList = createSelector(selectFeature, _selectLensList);
export const selectSatData = createSelector(selectFeature, _selectSatData);
export const selectMatData = createSelector(selectFeature, _selectMatData);
export const selectFranchiseClinicList = createSelector(selectFeature, _selectFranchiseClinicList);
export const selectFranchiseClinicDoctorsList = createSelector(selectFeature, _selectFranchiseClinicDoctorsList);
export const selectDistributorTypeBasedOnDoctorId = createSelector(selectFeature, _selectDistributorType);
export const selectLoopToMatchCorrectness = createSelector(selectFeature, _isLoopToMatchCorrect);
export const selectManufacturerHistoryPerPatient = createSelector(selectFeature, _selectManufacturerHistoryPerPatient);
export const selectListActiveItems = createSelector(selectFeature, _selectListActiveItems);
export const selectOldPatientList = createSelector(selectFeature, _selectOldPatientList);
export const selectTotalOldPatientCount = createSelector(selectFeature, _selectTotalOldPatientCount);
export const selectCareOfPatient = createSelector(selectFeature, _selectCareOfPatient);
export const selectPatientTransferDetails = createSelector(selectFeature, _selectPatientTransferDetails);
