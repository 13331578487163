import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoaderComponent } from './components/loader/loader.component';
import { uiReducer } from './reducers/ui.reducer';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AuthModule } from '@app/auth/auth.module';
import { UiEffects } from './effects/ui.effect';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    declarations: [LoaderComponent, NavBarComponent, SideBarComponent],
    imports: [
        CommonModule,
        RouterModule,
        AuthModule,
        SharedModule,
        EffectsModule.forFeature([UiEffects]),
        StoreModule.forFeature('ui', uiReducer),
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    ],
    exports: [LoaderComponent, NgxSkeletonLoaderModule, NavBarComponent, SideBarComponent],
})
export class UiModule {}
