import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SharedState } from '../models/shared.model';

const _roleIdOfLoggedInUser = (state: SharedState) => (state ? state.commonDetailsAfterLogin.roleId : '');

const _userIdOfLoggedInUser = (state: SharedState) => (state ? state.commonDetailsAfterLogin.userId : '');

const _doctorClinicDetailsCommon = (state: SharedState) =>
    state.commonDetailsAfterLogin
        ? state.commonDetailsAfterLogin
        : {
              token: '',
              roleMappingId: '',
              userId: '',
              role: '',
              roleId: '',
              clinicId: '',
              clinicType: '',
          };

const _getClinicDetailsForDoctor = (state: SharedState) =>
    state
        ? state.commonDetailsAfterLogin
        : {
              token: '',
              roleMappingId: '',
              userId: '',
              role: '',
              roleId: '',
              clinicId: '',
              clinicType: '',
          };

const _getCommonDetailsAfterLogin = (state: SharedState) =>
    state
        ? state.commonDetailsAfterLogin
        : {
              token: '',
              roleMappingId: '',
              userId: '',
              role: '',
              roleId: '',
              clinicId: '',
              clinicType: '',
          };

const _orderDetails = (state: SharedState) =>
    state
        ? state.orderDetailsBasedOnId
        : {
              order: [],
          };
const _formDataFilledForDesignView = (state: SharedState) =>
    state
        ? state.formDataFilledForDesignView
        : {
              facd: [],
              sat: [],
              mat: [],
          };
const _selectRoleSpecificUsers = (state: SharedState) => (state ? state.roleSpecificUsers : null);
const _selectUserListParams = (state: SharedState) => (state ? state.userListParams : {});
const _selectDistributorsForUserDetailsPage = (state: SharedState) =>
    state ? state.distributorsForUserDetailsPage : null;
const _selectContractManufacturersForUserDetailsPage = (state: SharedState) =>
    state ? state.contractManufacturerForUserDetailsPage : null;
const _selectFranchiseClinicsForUserDetailsPage = (state: SharedState) =>
    state ? state.franchiseClinicsForUserDetailsPage : [];
const _selectRegularClinicsForUserDetailsPage = (state: SharedState) =>
    state ? state.regularClinicsForUserDetailsPage : [];
const _selectDoctorsForUserDetailsPage = (state: SharedState) => (state ? state.doctorsForUserDetailsPage : null);
const _selectPatientsForUserDetailsPage = (state: SharedState) => (state ? state.patientsForUserDetailsPage : null);
const _selectOrdersForUserDetailsPage = (state: SharedState) => (state ? state.ordersForUserDetailsPage : null);

const _selectTotalCountOfClinicForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfClinicForUserDetailsPage : null;
const _selectTotalCountOfContractManufacturerForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfContractManufacturerForUserDetailsPage : null;
const _selectTotalCountOfOrdersForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfOrdersForUserDetailsPage : null;
const _selectTotalCountOfDoctorForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfDoctorForUserDetailsPage : null;
const _selectTotalCountOfPatientForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfPatientForUserDetailsPage : null;
const _selectTotalCountOfDistributorForUserDetailsPage = (state: SharedState) =>
    state ? state.totalCountOfDistributorForUserDetailsPage : null;
const _selectControlPanelDetails = (state: SharedState) => (state ? state.controlPanelDetailsByOrderId : { order: [] });
const _selectFilterBy = (state: SharedState) => state.filterBy || null;
const _selectAppliedFilters = (state: SharedState) => state.appliedFilters || null;
const _selectPatientDetails = (state: SharedState) => (state ? state.patientDetails : null);
const _selectOrderListForManufacturer = (state: SharedState) => state.orderListForManufacturer;
const _selectTotalOrderCountForManufacturer = (state: SharedState) => (state ? state.ordersListCount : 0);
const _selectDistributorCommonFormData = (state: SharedState) => (state ? state.distributorCommonFormData : []);
const _selectOrderDetails = (state: SharedState) => (state ? state.orderDetails : null);
const _selectRoleHierarchyForLoggedInUser = (state: SharedState) => (state ? state.roleHierarchyForLoggedInUser : null);
const _selectUserIdAndPriceSettingsData = (state: SharedState) => (state ? state.userIdAndPriceDataForPayment : null);
const _selectDeletingUserSuperiorId = (state: SharedState) => (state ? state.deletingUserSuperiorId : null);

export const selectFeature = createFeatureSelector<SharedState>('sharedReducer');

export const getRoleIdOfLoggedInUser = createSelector(selectFeature, _roleIdOfLoggedInUser);
export const getUserIdOfLoggedInUser = createSelector(selectFeature, _userIdOfLoggedInUser);
export const getDoctorClinicCommonDetails = createSelector(selectFeature, _doctorClinicDetailsCommon);
export const getClinicDetailsForDoctor = createSelector(selectFeature, _getClinicDetailsForDoctor);
export const getCommonDetailsAfterLogin = createSelector(selectFeature, _getCommonDetailsAfterLogin);
export const getOrderDetails = createSelector(selectFeature, _orderDetails);
export const getFormDataFilledForDesignView = createSelector(selectFeature, _formDataFilledForDesignView);
export const selectRoleSpecificUsers = createSelector(selectFeature, _selectRoleSpecificUsers);
export const selectUserListParams = createSelector(selectFeature, _selectUserListParams);
export const selectDistributorsForUserDetailsPage = createSelector(
    selectFeature,
    _selectDistributorsForUserDetailsPage
);
export const selectFranchiseClinicsForUserDetailsPage = createSelector(
    selectFeature,
    _selectFranchiseClinicsForUserDetailsPage
);
export const selectRegularClinicsForUserDetailsPage = createSelector(
    selectFeature,
    _selectRegularClinicsForUserDetailsPage
);
export const selectClinicsForUserDetailsPage = createSelector(
    selectFranchiseClinicsForUserDetailsPage,
    selectRegularClinicsForUserDetailsPage,
    (franchiseClinics, regularClinics) => [...franchiseClinics, ...regularClinics].slice(0, 5)
);
export const selectDoctorsForUserDetailsPage = createSelector(selectFeature, _selectDoctorsForUserDetailsPage);
export const selectPatientsForUserDetailsPage = createSelector(selectFeature, _selectPatientsForUserDetailsPage);
export const selectOrdersForUserDetailsPage = createSelector(selectFeature, _selectOrdersForUserDetailsPage);

export const selectContractManufacturersForUserDetailsPage = createSelector(
    selectFeature,
    _selectContractManufacturersForUserDetailsPage
);
export const selectTotalCountOfClinicForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfClinicForUserDetailsPage
);
export const selectTotalCountOfContractManufacturerForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfContractManufacturerForUserDetailsPage
);
export const selectTotalCountOfOrdersForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfOrdersForUserDetailsPage
);
export const selectTotalCountOfDoctorForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfDoctorForUserDetailsPage
);
export const selectTotalCountOfPatientForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfPatientForUserDetailsPage
);
export const selectTotalCountOfDistributorForUserDetailsPage = createSelector(
    selectFeature,
    _selectTotalCountOfDistributorForUserDetailsPage
);
export const selectControlPanelDetailsByOrderId = createSelector(selectFeature, _selectControlPanelDetails);
export const selectFilterBy = createSelector(selectFeature, _selectFilterBy);
export const selectAppliedFilters = createSelector(selectFeature, _selectAppliedFilters);
export const selectPatientDetails = createSelector(selectFeature, _selectPatientDetails);
export const selectOrderListForContractManufacturer = createSelector(selectFeature, _selectOrderListForManufacturer);
export const selectTotalOrderCountForManufacturer = createSelector(
    selectFeature,
    _selectTotalOrderCountForManufacturer
);
export const selectDistributorCommonFormData = createSelector(selectFeature, _selectDistributorCommonFormData);
export const selectOrderDetailsById = createSelector(selectFeature, _selectOrderDetails);
export const selectRoleHierarchyForLoggedInUser = createSelector(selectFeature, _selectRoleHierarchyForLoggedInUser);
export const selectUserIdAndPriceSettingsData = createSelector(selectFeature, _selectUserIdAndPriceSettingsData);
export const selectDeletingUserSuperiorId = createSelector(selectFeature, _selectDeletingUserSuperiorId);
