import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import * as calculation from "../../core/services/util.service";

@Pipe({
    name: 'facdDataTransform',
})
export class FacdDataTransformPipe implements PipeTransform {
    constructor(private _decimalPipe: DecimalPipe) {}

    transform(
        value: any,
        valueUnit: string,
        eyeSelection: string | undefined,
        eyePos: string,
        noRounding?: boolean
    ): string {
        if (value !== '' && typeof value === 'number' && !noRounding) {
            value = calculation.roundValueToPrecision(value, 2);
            value = this._decimalPipe.transform(value, '1.2-2');
        }
        if (eyeSelection === 'RIGHT ONLY' && eyePos === 'right' && value !== '') {
            return value + ' ' + valueUnit;
        } else if (eyeSelection === 'LEFT ONLY' && eyePos === 'left' && value !== '') {
            return value + ' ' + valueUnit;
        } else if (eyeSelection === 'RIGHT AND LEFT' && (eyePos === 'left' || eyePos === 'right') && value !== '') {
            return value + ' ' + valueUnit;
        }
        return '--';
    }
}
