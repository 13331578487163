<nav class="navbar navbar-light px-2">
    <div class="px-2 py-2 hamburger cursor-pointer" (click)="onToggleSidebar()">
        <i class="bi bi-list"></i>
    </div>
    <a class="navbar-brand me-0">
        <img class="organization-logo-img cursor-pointer" [src]="organizationLogo" alt="Logo" (click)="onLogoClick()" />
    </a>
    <div class="d-flex navbar-right-section">
        <div class="d-flex align-items-center breadcrumbs-container" *ngIf="screenWidth > widthTarget">
            <div
                (click)="onBreadCrumbClick(link)"
                [ngClass]="last ? 'kl-h5-type-4' : 'kl-h5-type-3 cursor-pointer'"
                *ngFor="
                    let link of (breadCrumbs$ | async)?.urlCollection;
                    let i = index;
                    let first = first;
                    let last = last
                "
            >
                <ng-container *ngIf="breadCrumbs.length > 1"
                    >{{ link?.name }} <span *ngIf="!last" class="mx-2">/</span></ng-container
                >
            </div>
        </div>
        <div class="profile-container d-flex align-items-center me-xl-5 me-lg-5 me-md-0 me-sm-0 me-xs-0">
            <div
                class="dropdown mx-1 cursor-pointer"
                appClickOutside
                (click)="onDropdownClicked(userDropdown)"
                (clickOutside)="onClickOutside(userDropdown)"
            >
                <a
                    id="userDropdownMenuLink"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="true"
                    class="d-flex profile-options-main"
                >
                    <span class="contact-image kl-profile-image">{{ userProfileName }}</span>
                    <div class="d-grid user-basic-detail">
                        <span class="kl-h5-type-1">{{ (userDetails$ | async)?.user_role_mapping?.at(0)?.name }}</span>
                        <span class="kl-h5-type-3">{{ userRoleName }}</span>
                    </div>
                </a>
                <ul class="dropdown-menu end-0" #userDropdown aria-labelledby="userDropdownMenuLink">
                    <li (click)="openVerticallyCentered(content2)" *ngIf="role !== 'patient'">
                        <div class="icon-menu-container d-flex align-items-center">
                            <img src="/assets/icons/navbar-profile/profile-icon.png" />
                            <span class="profile-menu-items"> Profile </span>
                        </div>
                    </li>
                    <li (click)="getFamilyDetailsAndOpenModal(content3)" *ngIf="role === 'patient' && showFamilyMemberLink">
                        <div class="icon-menu-container d-flex align-items-center">
                            <img src="/assets/icons/navbar-profile/profile-icon.png" />
                            <span class="profile-menu-items" data-bs-target="#staticBackdrop"> Family Members </span>
                        </div>
                    </li>
                    <li (click)="openVerticallyCentered(content1)" *ngIf="role !== 'patient'">
                        <div class="icon-menu-container d-flex align-items-center">
                            <img src="/assets/icons/navbar-profile/profile-icon.png" />
                            <span class="profile-menu-items" data-bs-target="#staticBackdrop"> User Role </span>
                        </div>
                    </li>
                    <li (click)="openVerticallyCentered(content)">
                        <div class="icon-menu-container d-flex align-items-center">
                            <img src="/assets/icons/navbar-profile/profile-icon.png" />
                            <span class="profile-menu-items" data-bs-target="#staticBackdrop"> Change Password </span>
                        </div>
                    </li>
                    <li (click)="onLogout()">
                        <div class="icon-menu-container d-flex align-items-center">
                            <img src="/assets/icons/navbar-profile/profile-icon.png" />
                            <span class="profile-menu-items"> Logout </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<ng-template #content let-modal>
    <app-change-password (closeChangePwdForm)="closePopup()"></app-change-password>
</ng-template>
<ng-template #content1 let-modal>
    <app-user-role (closeChangeUserRoleForm)="closePopup()"></app-user-role>
</ng-template>
<ng-template #content2 let-modal>
    <app-user-profile (closeEditUserProfileForm)="closeEditUserPopup($event)"></app-user-profile>
</ng-template>
<ng-template #content3 let-modal>
    <ng-container *ngIf="familyMembers$ | async as familyMembers">
        <app-sibling-selection-form
            [isModal]="true"
            [familyMembers]="familyMembers"
            (closeSelectSiblingForm)="closePopup()"
        ></app-sibling-selection-form>
    </ng-container>
</ng-template>
