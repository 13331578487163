<div class="container">
    <div class="row label-row">
        <div class="col-6 p-0">
            <span class="kl-text3-type-6">{{ summaryFormName }}</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-8">Right Eye</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-8">Left Eye</span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Mapping the entire tangential treatment zone, does it look round?</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question1.right
                    | amendDataTransform: 1: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question1.left
                    | amendDataTransform: 1: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">In tangential map, where is the treatment zone in relation to the geometric centre of the cornea?</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question2.right 
                    | amendDataTransform: 2: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question2.left 
                    | amendDataTransform: 2: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">In Axial power DIFFERENCE map (normalised scale ONLY), what do you observe?</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question3.right 
                    | amendDataTransform: 3: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question3.left 
                    | amendDataTransform: 3: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="col-12 p-0">
            <span class="kl-text3-type-6">What is the cornea's response and ocular health?</span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Central epithelial SPK</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[1]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[1]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Central stromal absorbtion</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[2]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[2]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Mid-peripheral SPK</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[3]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[3]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Reverse zone adhesion</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[4]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[4]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Edge lift adhesion</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[5]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[5]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Limbal staining</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.right[6]
                    | amendDataTransform: 4: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question4.left[6]
                    | amendDataTransform: 4: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="col-12 p-0">
            <span class="kl-text3-type-6">What is the cornea's response and ocular health?</span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Sphere</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.right[1]
                    | amendDataTransform: 0: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.left[1]
                    | amendDataTransform: 0: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Cylinder</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.right[2]
                    | amendDataTransform: 0: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.left[2]
                    | amendDataTransform: 0: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Axis</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.right[3]
                    | amendDataTransform: 0: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question5.left[3]
                    | amendDataTransform: 0: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Is the patient experiencing symptomatic HOA</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question6.right
                    | amendDataTransform: 6: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question6.left
                    | amendDataTransform: 6: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">Is the patient having difficulties with near work?</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question7.right
                    | amendDataTransform: 7: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question7.left
                    | amendDataTransform: 7: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
    <div class="row patient-amend-details">
        <div class="field-name col-5 p-0">
            <span class="kl-text5-type-6">How is the patient's myopia control?</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question8.right
                    | amendDataTransform: 8: amendData.eyes: 'right' }}
            </span>
        </div>
        <div class="col-3 p-0">
            <span class="kl-text5-type-7">
                {{ amendData.question8.left
                    | amendDataTransform: 8: amendData.eyes: 'left' }}
            </span>
        </div>
    </div>
</div>
