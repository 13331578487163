import { Action, createReducer, on } from '@ngrx/store';

import { LoginResponseData } from '@app/auth/models/auth.model';
import { SharedState, initialState } from '../models/shared.model';
import {
    getRoleSpecificUsersSuccess,
    saveUserListParams,
    clearCommonDetailsSuccess,
    getDetailsOfOrderByIdSuccess,
    getFormDataFilledByDoctorSuccess,
    roleSelectionFailure,
    roleSelectionSuccess,
    getFranchiseClinicsForUserDetailsPageSuccess,
    getRegularClinicsForUserDetailsPageSuccess,
    getDistributorsForUserDetailsPageSuccess,
    getDoctorsForUserDetailsPageSuccess,
    getPatientsForUserDetailsPageSuccess,
    getContractManufacturersForUserDetailsPageSuccess,
    getOrdersForUserDetailsPageSuccess,
    getTotalCountOfDistributorForUserDetailsPageSuccess,
    getTotalCountOfClinicForUserDetailsPageSuccess,
    getTotalCountOfContractManufacturerForUserDetailsPageSuccess,
    getTotalCountOfOrdersForUserDetailsPageSuccess,
    getTotalCountOfDoctorForUserDetailsPageSuccess,
    getTotalCountOfPatientForUserDetailsPageSuccess,
    getControlPanelDetailsByOrderIdSuccess,
    getFilterByValuesSuccess,
    saveAppliedFilters,
    getPatientFormDataSuccess,
    getOrdersForManufacturerSuccess,
    getTotalOrderCountForManufacturerSuccess,
    getDistributorDataForManufacturerSuccess,
    getOrderDetailsByOrderIdSuccess,
    getRoleHierarchyForLoggedInUserSuccess,
    resetUserDetailsPageExtraDetails,
    getUserIdAndPriceDataForPaymentSuccess,
    getDeletingUserSuperiorSuccess,
    resetPatientDetails,
} from '../actions/shared.action';
import {
    GetDetailsOfOrderByIdQuery,
    GetFormDataFilledByDoctorForDesignViewQuery,
    GetOrdersCountBasedOnDistributorIdQuery,
    GetOrdersListBasedOnDistributorIdQuery,
    GetUserFormData1Query,
} from '@app/generated/graphql';
import { resetAppState } from '@app/actions';

const sharedModuleReducer = createReducer(
    initialState,
    on(roleSelectionSuccess, (state, payload: { commonDetailsAfterLogin: LoginResponseData }) => ({
        ...state,
        commonDetailsAfterLogin: payload.commonDetailsAfterLogin,
    })),
    on(roleSelectionFailure, (state, payload: { commonDetailsAfterLogin: LoginResponseData }) => ({
        ...state,
        commonDetailsAfterLogin: payload.commonDetailsAfterLogin,
    })),
    on(clearCommonDetailsSuccess, (state) => ({
        ...state,
        commonDetailsAfterLogin: {
            token: '',
            roleMappingId: '',
            userId: '',
            role: '',
            roleId: '',
            clinicId: '',
            clinicType: '',
        },
    })),
    on(getDetailsOfOrderByIdSuccess, (state, payload: { orderDetails: GetDetailsOfOrderByIdQuery }) => ({
        ...state,
        orderDetailsBasedOnId: payload.orderDetails,
    })),
    on(
        getFormDataFilledByDoctorSuccess,
        (state, payload: { formDataFilled: GetFormDataFilledByDoctorForDesignViewQuery }) => ({
            ...state,
            formDataFilledForDesignView: payload.formDataFilled,
        })
    ),
    on(getRoleSpecificUsersSuccess, (state, { roleSpecificUsers }) => ({
        ...state,
        roleSpecificUsers,
    })),
    on(saveUserListParams, (state, { userListParams }) => ({
        ...state,
        userListParams,
    })),
    on(getFranchiseClinicsForUserDetailsPageSuccess, (state, { franchiseClinicsForUserDetailsPage }) => ({
        ...state,
        franchiseClinicsForUserDetailsPage,
    })),
    on(getRegularClinicsForUserDetailsPageSuccess, (state, { regularClinicsForUserDetailsPage }) => ({
        ...state,
        regularClinicsForUserDetailsPage,
    })),
    on(getDistributorsForUserDetailsPageSuccess, (state, { distributorsForUserDetailsPage }) => ({
        ...state,
        distributorsForUserDetailsPage,
    })),
    on(getDoctorsForUserDetailsPageSuccess, (state, { doctorsForUserDetailsPage }) => ({
        ...state,
        doctorsForUserDetailsPage,
    })),
    on(getPatientsForUserDetailsPageSuccess, (state, { patientsForUserDetailsPage }) => ({
        ...state,
        patientsForUserDetailsPage,
    })),
    on(getContractManufacturersForUserDetailsPageSuccess, (state, { contractManufacturerForUserDetailsPage }) => ({
        ...state,
        contractManufacturerForUserDetailsPage,
    })),
    on(getOrdersForUserDetailsPageSuccess, (state, { ordersForUserDetailsPage }) => ({
        ...state,
        ordersForUserDetailsPage,
    })),
    on(getTotalCountOfDistributorForUserDetailsPageSuccess, (state, { totalCountOfDistributorForUserDetailsPage }) => ({
        ...state,
        totalCountOfDistributorForUserDetailsPage,
    })),
    on(getTotalCountOfClinicForUserDetailsPageSuccess, (state, { totalCountOfClinicForUserDetailsPage }) => ({
        ...state,
        totalCountOfClinicForUserDetailsPage,
    })),
    on(
        getTotalCountOfContractManufacturerForUserDetailsPageSuccess,
        (state, { totalCountOfContractManufacturerForUserDetailsPage }) => ({
            ...state,
            totalCountOfContractManufacturerForUserDetailsPage,
        })
    ),
    on(getTotalCountOfOrdersForUserDetailsPageSuccess, (state, { totalCountOfOrdersForUserDetailsPage }) => ({
        ...state,
        totalCountOfOrdersForUserDetailsPage,
    })),
    on(getTotalCountOfDoctorForUserDetailsPageSuccess, (state, { totalCountOfDoctorForUserDetailsPage }) => ({
        ...state,
        totalCountOfDoctorForUserDetailsPage,
    })),
    on(getTotalCountOfPatientForUserDetailsPageSuccess, (state, { totalCountOfPatientForUserDetailsPage }) => ({
        ...state,
        totalCountOfPatientForUserDetailsPage,
    })),
    on(getControlPanelDetailsByOrderIdSuccess, (state, payload) => ({
        ...state,
        controlPanelDetailsByOrderId: payload.controlPanelDetails,
    })),
    on(getFilterByValuesSuccess, (state, { filterBy }) => ({
        ...state,
        filterBy,
    })),
    on(saveAppliedFilters, (state, { appliedFilters }) => ({
        ...state,
        appliedFilters,
    })),
    on(getPatientFormDataSuccess, (state, payload) => ({
        ...state,
        patientDetails: payload?.patientDetails,
    })),
    on(
        getOrdersForManufacturerSuccess,
        (state, payload: { orderSummaryList: GetOrdersListBasedOnDistributorIdQuery }) => ({
            ...state,
            orderListForManufacturer: payload.orderSummaryList,
        })
    ),
    on(
        getTotalOrderCountForManufacturerSuccess,
        (state, payload: { ordersCountData: GetOrdersCountBasedOnDistributorIdQuery }) => ({
            ...state,
            ordersListCount: payload.ordersCountData,
        })
    ),
    on(getDistributorDataForManufacturerSuccess, (state, payload: { userFormData: GetUserFormData1Query }) => ({
        ...state,
        distributorCommonFormData: payload.userFormData,
    })),
    on(getOrderDetailsByOrderIdSuccess, (state, payload) => ({
        ...state,
        orderDetails: payload.orderDetails,
    })),
    on(getRoleHierarchyForLoggedInUserSuccess, (state, { roleHierarchyForLoggedInUser }) => ({
        ...state,
        roleHierarchyForLoggedInUser,
    })),
    on(resetUserDetailsPageExtraDetails, (state) => ({
        ...state,
        regularClinicsForUserDetailsPage: [],
        franchiseClinicsForUserDetailsPage: [],
        distributorsForUserDetailsPage: [],
        doctorsForUserDetailsPage: [],
        patientsForUserDetailsPage: [],
        contractManufacturerForUserDetailsPage: [],
        ordersForUserDetailsPage: [],
        totalCountOfDistributorForUserDetailsPage: 0,
        totalCountOfClinicForUserDetailsPage: 0,
        totalCountOfContractManufacturerForUserDetailsPage: 0,
        totalCountOfOrdersForUserDetailsPage: 0,
        totalCountOfDoctorForUserDetailsPage: 0,
        totalCountOfPatientForUserDetailsPage: 0,
    })),
    on(getUserIdAndPriceDataForPaymentSuccess, (state, { userIdAndPriceDataForPayment }) => ({
        ...state,
        userIdAndPriceDataForPayment,
    })),
    on(getDeletingUserSuperiorSuccess, (state, { deletingUserSuperiorId }) =>({
      ...state,
      deletingUserSuperiorId
    })),
    on(resetPatientDetails, (state) => ({
      ...state,
      patientDetails: null
    })),
    on(resetAppState, () => initialState)
);

export function sharedReducer(state: SharedState | undefined, action: Action) {
    return sharedModuleReducer(state, action);
}
