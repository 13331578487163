import {
  GetOnboardUserFormDataQuery,
  GetMoreDetailsOfUserQuery,
  GetRoleDetailsQuery} from '@app/generated/graphql';

export interface UIState {
    showLoading: boolean;
    showOpaqueLoader: boolean;
    errorMessage: string;
    lookUp: LookUp;
    emailOfLoggedInUser: string;
    formMode: FormMode;
}

export const initialState: UIState = {
    showLoading: false,
    showOpaqueLoader: false,
    errorMessage: '',
    lookUp: {
        userRoleId: { role: [] },
        role_permissions: {
            module_details: {},
            role: {},
            permissions: {},
        },
        onboardUserFormData: {},
        userMoreDetails: {
            user: [{ email: '', contact_number: '', country_code: '' }],
            user_role_mapping: [{ name: '', role: { id: '', name: '' } }],
        },
        breadCrumbs: [],
        urlCollection: { rootUrl: '', urlCollection: [], isModuleChanged: false },
        hideSideAndNavBar: false,
        grantedRoles: null,
        countryCodeDetails: null
    },
    emailOfLoggedInUser: '',
    formMode: null
};

export interface UserRoleId {
    superAdmin: string;
    designer: string;
}

export interface LookUp {
    userRoleId: GetRoleDetailsQuery;
    role_permissions: UserPermissionsData;
    onboardUserFormData: GetOnboardUserFormDataQuery | {};
    userMoreDetails: GetMoreDetailsOfUserQuery;
    breadCrumbs: BreadCrumb[];
    urlCollection: { rootUrl: string; urlCollection: UrlCollection[]; isModuleChanged: boolean };
    hideSideAndNavBar: boolean;
    grantedRoles: UserRole[] | null;
    countryCodeDetails: CountryCodeDetails[] | null
}

export interface CountryCodeDetails {
  flag: string,
  name: string,
  countryCode: string
}

export interface BreadCrumb {
    url: string;
    name: string;
    isActive: boolean;
    extraData?: any;
}

export interface UrlCollection {
    name: string;
    url: string;
    urlWithParams: string;
}

export interface UserPermissionsData {
    module_details: any;
    permissions: any;
    role: any;
}

export interface UserRole {
  id: string,
  name: string
}

export type FormMode = "view" | "edit" | "create" | null;
