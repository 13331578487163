import { UserPriceSettingsStructure } from '@app/shared/models/user-price-settings.model';

export const UserPriceSettingsData: UserPriceSettingsStructure[] = [
    {
        priceLabel: 'New spherical',
        priceValue: 0,
        unique_field_name: 'newSpherical',
    },
    {
        priceLabel: 'Amend spherical',
        priceValue: 0,
        unique_field_name: 'amendSpherical',
    },
    {
        priceLabel: 'New toric',
        priceValue: 0,
        unique_field_name: 'newToric',
    },
    {
        priceLabel: 'Amend toric',
        priceValue: 0,
        unique_field_name: 'amendToric',
    },
    {
        priceLabel: 'Remake spherical',
        priceValue: 0,
        unique_field_name: 'remakeSpherical',
    },
    {
        priceLabel: 'Remake toric',
        priceValue: 0,
        unique_field_name: 'remakeToric',
    }
];
