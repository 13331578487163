import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { setOpaqueLoadingSpinner } from '@app/ui/actions/ui.actions';
import { ClinicSelectAndUpdateMutationVariables } from '@app/generated/graphql';
import { ClinicListForSelection, SelectedRoleDetails } from '@app/auth/models/auth.model';
import { clinicListFetchForSelection, onClinicSelectForDoctor } from '@app/auth/actions/auth.action';
import { getListOfClinicsByDoctor, getUserIdOfDoctorForClinicList } from '@app/auth/selectors/auth.selector';

@Component({
    selector: 'app-select-clinic',
    templateUrl: './select-clinic.component.html',
    styleUrls: ['./select-clinic.component.scss'],
})
export class SelectClinicComponent implements OnInit, OnDestroy {
    isAlive = true;
    userId = '';
    clinic!: SelectedRoleDetails;
    availableClinicList!: ClinicListForSelection[];
    clinicSelectionForm: FormGroup = new FormGroup({});
    getUserIdSubscription$ = new Subscription();
    availableClinicSubscription$ = new Subscription();

    @Output() closeSelectClinicForm = new EventEmitter<boolean>();

    availableClinics$: Observable<ClinicListForSelection[]> = this._store.select(getListOfClinicsByDoctor);

    constructor(private _store: Store, private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.createClinicSelectionForm();
        this._store.dispatch(clinicListFetchForSelection());

        this.availableClinicSubscription$ = this.availableClinics$.subscribe((clinicList: ClinicListForSelection[]) => {
            this.availableClinicList = clinicList;
        });

        this.getUserIdSubscription$ = this._store.select(getUserIdOfDoctorForClinicList).subscribe((userId: string) => {
            this.userId = userId;
        });
    }

    createClinicSelectionForm = (): void => {
        this.clinicSelectionForm = this._fb.group({
            clinic: ['', Validators.required],
        });
    };

    get clinicSelected(): any {
        return this.clinicSelectionForm.get('clinic');
    }

    get clinicTypeByClinicId(): string {
        const clinic = this.availableClinicList.find(
            (clinic: ClinicListForSelection) => clinic.clinicId === this.clinicSelected.value
        );
        return clinic ? clinic.clinicType : 'null';
    }

    onSubmit = (): void => {
        if (this.clinicSelectionForm.valid) {
            this._store.dispatch(setOpaqueLoadingSpinner({ status: true }));
            const clinicDetails: ClinicSelectAndUpdateMutationVariables = {
                clinic_id: this.clinicSelected.value,
                clinic_type: this.clinicTypeByClinicId,
                user_id: this.userId,
            };
            const selectedClinic = this.availableClinicList.find((clinic) => clinic?.clinicId === clinicDetails?.clinic_id);
            const clinicExtra = {clinicRoleId: selectedClinic?.clinicRoleId ?? ''};
            this._store.dispatch(onClinicSelectForDoctor(clinicDetails, clinicExtra));
            this.closeSelectClinicForm.emit(true);
        }
    };

    isDirty = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name]?.dirty;
    };

    isTouched = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name]?.touched;
    };

    isRequired = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name]?.errors?.['required'];
    };

    ngOnDestroy() {
        this.isAlive = false;
        this.availableClinicSubscription$.unsubscribe();
        this.getUserIdSubscription$.unsubscribe();
    }
}
