import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UIState } from '../models/ui.model';

const getUIState = createFeatureSelector<UIState>('ui');

const _getLoading = (state: UIState) => {
    return state.showLoading;
};

const _selectOpaqueLoading = (state: UIState) => state.showOpaqueLoader;

const _getErrorMessage = (state: UIState) => {
    return state.errorMessage;
};

const _getRoleDetails = (state: UIState) => {
    return state.lookUp.userRoleId;
};

const _getEmailOfLoggedInUser = (state: UIState) => {
    return state.emailOfLoggedInUser;
};

const _getLoggedInUserPermissions = (state: UIState) => {
    return state.lookUp.role_permissions;
};

const _getUserMoreDetails = (state: UIState) => {
    return state.lookUp?.userMoreDetails;
};

const _selectOnboardUserFormData = (state: UIState) => {
    return state.lookUp?.onboardUserFormData;
};

const _selectUrlCollection = (state: UIState) => {
    return state?.lookUp?.urlCollection;
};

const _displaySideAndNavbar = (state: UIState) => {
    return state?.lookUp?.hideSideAndNavBar;
};

const _selectGrantedUserRoleList = (state: UIState) => {
    return state?.lookUp?.grantedRoles
}

const _selectCountryDetails = (state: UIState) => {
  return state?.lookUp?.countryCodeDetails
}

const _selectFormMode = (state: UIState) => {
  return state?.formMode
}

export const getLoading = createSelector(getUIState, _getLoading);

export const getErrorMessage = createSelector(getUIState, _getErrorMessage);

export const selectRoleDetails = createSelector(getUIState, _getRoleDetails);

export const getEmailIdOfLoggedInUser = createSelector(getUIState, _getEmailOfLoggedInUser);

export const getLoggedInUserPermissions = createSelector(getUIState, _getLoggedInUserPermissions);

export const userMoreDetails = createSelector(getUIState, _getUserMoreDetails);

export const selectOnboardUserFormData = createSelector(getUIState, _selectOnboardUserFormData);

export const selectUrlCollection = createSelector(getUIState, _selectUrlCollection);

export const getSideAndNavBarStatus = createSelector(getUIState, _displaySideAndNavbar);

export const selectOpaqueLoading = createSelector(getUIState, _selectOpaqueLoading);

export const selectGrantedUserRoleList = createSelector(getUIState, _selectGrantedUserRoleList);

export const selectCountryDetails = createSelector (getUIState, _selectCountryDetails);

export const selectFormMode = createSelector(getUIState, _selectFormMode);
