import { Pipe, PipeTransform } from '@angular/core';

import { NewRoleNames, Roles } from '@app/core/util/common';

@Pipe({
  name: 'snakeCaseNormalText'
})
export class SnakeCaseNormalTextPipe implements PipeTransform {

  transform(inputText: string): string {
    if(!inputText){
      return '';
    }
    if (inputText === Roles.DOCTOR) {
      inputText = NewRoleNames.PRACTITIONER;
    }
    else if (inputText === Roles.REGULAR_CLINIC) {
      inputText = NewRoleNames.PARTNER;
    }
    else if (inputText === Roles.FRANCHISE_CLINIC) {
      inputText = NewRoleNames.GOLD_PARTNER;
    }
    let text = inputText.replace(/_/g, ' '); // Replace _ wil empty space.
    text = text.replace(/\b\w/g, (char) => char.toUpperCase()); // Change first char of each word to uppercase.
    return text;
  }

}
