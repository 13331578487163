import { Component, ViewChildren, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';

import { resendOtp, verifyOtp } from '@app/auth/actions/auth.action';
import { sendOtpValidator } from './send-otp-validator';
import { OnSendOtpBothServiceMutationVariables, VerifyOtpMutationVariables } from '@app/generated/graphql';
import { HandlePopupService } from '@app/core/services/handle-popup.service';
import { getSelectedOtpMethodAndUserId } from '@app/auth/selectors/auth.selector';
import { OtpSelectionDetails } from '@app/auth/models/auth.model';

@Component({
    selector: 'app-send-otp',
    templateUrl: './send-otp.component.html',
    styleUrls: ['./send-otp.component.scss'],
})
export class SendOtpComponent implements OnInit, OnDestroy {
    title = 'otp';
    userId = '';
    isAlive = true;
    otpForm!: FormGroup;
    formInput = ['input1', 'input2', 'input3', 'input4', 'input5'];
    @ViewChildren('formRow') rows: any;
    hasSubmitted = false;
    otpServiceType = '';
    oldPos = 0;
    count = 0;
    isOtpSendSuccess$ = new Observable<{ type: string; isOpen: boolean }>();
    otpServiceSelectionDetails$ = new Observable<OtpSelectionDetails>();
    subscription$ = new Subscription();
    constructor(
        private _store: Store,
        private _popupService: HandlePopupService,
    ) {
        this.otpForm = this.toFormGroup(this.formInput);
        this.isOtpSendSuccess$ = this._popupService.getOtpStatus();
    }

    ngOnInit(): void {

        // gets which otp service is selected, userid and stores it
        this.otpServiceSelectionDetails$ = this._store.select(getSelectedOtpMethodAndUserId);
        this.subscription$ = this.otpServiceSelectionDetails$.pipe(
            takeWhile(() => this.isAlive))
            .subscribe((otpSelectionDetail: OtpSelectionDetails) => {
                this.userId = otpSelectionDetail?.userId;
                this.otpServiceType = otpSelectionDetail?.method;
            });
    }

    /**
     *
     * @param elements Passes the array of formControlName.
     * @returns FormGroup
     */
    toFormGroup = (elements: string[]): FormGroup => {
        const group: any = {};

        elements.forEach((key) => {
            group[key] = new FormControl('', sendOtpValidator());
        });
        return new FormGroup(group);
    };

    /**
     * Function handles the focus of the input field when user types.
     * It moves back and forth based on the key entered.
     * @param event gives the keyboard event.
     * @param input gives the control name.
     * @param index gives input field index number.
     */
    keyUpEvent = (event: KeyboardEvent, input: string, index: number): void => {
        let pos = index;
        if (this.oldPos != pos) {
            this.count = 0
        }
        this.oldPos = pos
        if (event.key === 'Backspace' && event.code === 'Backspace') {
            this.count = this.count + 1
            if (this.count > 1) {
                this.rows._results[pos - 1].nativeElement.focus();
            }
            else {
                this.rows._results[pos].nativeElement.focus();
            }
        } else {
            pos = index + 1;
            if (pos > -1 && pos < this.formInput.length) {
                this.rows._results[pos].nativeElement.focus();
            }
        }
        // Patch the value of the input field if user enters a valid input.
        // This helps to change the input value, if user choose to reenter value without using backspace.
        if (/^\d+$/.test(event.key)) {
            this.otpForm.get(input)?.patchValue(event.key);
        }
    };

    /**
     * OnPaste function patches the input field if the user pastes the otp, rather typing it
     * If user copied the otp, get the otp from the clipboard
     * Split the otp and transform it to an array
     * Patch the input field from the otp array
     * Move focus on the last input field
     * @param Nil
     * @returns Promise<void>
     */
    onPaste = async (): Promise<void> => {
        const otp = await navigator.clipboard.readText();
        const otpToArray = Array.from(String(otp), Number);
        Object.keys(this.otpForm.controls).forEach((key, index) => {
            this.otpForm.get(key)?.patchValue(otpToArray[index]);
            this.rows._results[index].nativeElement.focus();
        });
    };

    /**
     * Check whether th form is valid
     * @param Nil
     * @returns Boolean, returns the validity of the form
     */
    get isValid(): boolean {
        return this.otpForm.valid;
    }

    /**
     * Converts the otp to a single string
     * @param Nil
     * @returns otp as a single string
     */
    transformOtpString = (): number => {
        let otp = '';
        const formVal = this.otpForm.value;
        Object.keys(formVal).forEach((key) => {
            otp += formVal[key];
        });
        return Number(otp);
    };

    /**
     * Resends the otp
     * @param Nil
     * @returns void
     */
    onResendOtp = (): void => {
        const resendOtpParams: OnSendOtpBothServiceMutationVariables = {
            payload: {
                method: this.otpServiceType,
                userId: this.userId
            }
        }
        this._store.dispatch(resendOtp(resendOtpParams))
    };

    /**
     * Submits the form to verify the otp entered
     * @param Nil
     * @returns void
     */
    onSubmit = (): void => {
        if (!this.otpForm.valid) {
            this.hasSubmitted = true;
            return;
        }
        const otpVerificationData: VerifyOtpMutationVariables = {
            payload: {
                otp:  this.transformOtpString(),
                userId: this.userId,
            },
        };
        this._store.dispatch(verifyOtp(otpVerificationData));
    };

    /**
     * Close the popup
     * @param event Data passed from Event emitter
     * @returns void
     */
    closeAlertPopup = (): void => {
        this._popupService.setOtpStatus('', false);
    };

    ngOnDestroy(): void {
        this.isAlive = false;

        // update observable with popup status to false so that it wont open again
        this._popupService.setOtpStatus('', false);
        this.subscription$.unsubscribe();
    }
}
