import { D1 } from '../inputForms/D1';
import { correction, eye, optimisationLevel } from '../interfaces';

import { Troubleshoot } from './troubleshoot';
import { Engine } from '../engine';
import { FacdControlPanel } from './controlPanels';
import * as calculation from '../../services/util.service';

export class EbcWorksheet {
    private _D1: D1;
    F5: number;
    G5(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const f5 = this.F5;
        const i65 = troubleshoot.I65();
        const i65_2 = troubleshoot2.I65();
        const result = f5 + i65 + i65_2;
        return result;
    }
    H5(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const g5 = this.G5(troubleshoot, troubleshoot2);
        const l5 = this.L5;
        const result = g5 * l5;
        return result;
    }
    I5(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.round(this.H5(troubleshoot, troubleshoot2) / 0.2) * 0.2;
    }
    L5: number;
    F6: number;
    G6(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.F6 / (1 - this.L6 * -this.F6) + troubleshoot.I66() + troubleshoot2.I66();
    }
    H6(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G6 = this.G6(troubleshoot, troubleshoot2);

        if (G6 <= this.L7) {
            return this.L9;
        } else if (G6 <= this.L8 && G6 > this.L7) {
            return this.L10;
        } else if (G6 > this.L8) {
            return this.L11;
        } else {
            return 0;
        }
    }
    I6 = this.H6;

    L6: number;
    H7(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G6 = this.G6(troubleshoot, troubleshoot2);
        if (G6 <= this.L12) {
            return this.L16;
        } else if (G6 <= this.L13 && G6 > this.L12) {
            return this.L15;
        } else if (G6 > this.L13) {
            return this.L14;
        } else {
            return 0;
        }
    }
    L7: number;
    H8(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G6 = this.G6(troubleshoot, troubleshoot2);
        return G6 * this.L17 + this.L18 - G6;
    }
    L8: number;
    F9: correction;
    H9(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        if (this.F9 === correction.miopiaControl) {
            return 5.4;
        } else {
            return this.H7(troubleshoot, troubleshoot2);
        }
    }
    I9(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const i5 = this.I5(troubleshoot, troubleshoot2);
        const i6 = this.I6(troubleshoot, troubleshoot2);
        const G15 = this.G15(troubleshoot, troubleshoot2);
        const I14 = this.I14(troubleshoot, troubleshoot2);
        const H13 = this.H13;
        const result = i5 - i6 * 2 - H13 * 2 - G15 * 4 - I14 * 2;
        return result;
    }
    L9: number;
    L10: number;
    L11: number;
    L12: number;
    L13: number;
    L14: number;
    L15: number;
    L16: number;
    L17: number;
    L18: number;
    L19: number;
    L20: number;
    L21: number;
    L22: number;
    L23: number;
    L24: number;
    L25: number;
    L26: number;
    L27: number;
    L28: number;
    L29: number;
    L30: number;
    L31: number;
    L32: number;
    L33: number;
    L34: number;
    L35: number;
    H12(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        if (this.F9 == correction.miopiaControl || this.F9 == correction.adult) {
            return this.H8(troubleshoot, troubleshoot2);
        } else {
            return this.H8(troubleshoot, troubleshoot2) / 2;
        }
    }
    I12(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.round(this.H12(troubleshoot, troubleshoot2) / 0.25) * 0.25;
    }
    H13 = 0.3;
    H14(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        if (this.I5(troubleshoot, troubleshoot2) <= this.L19) {
            return 0.3;
        } else {
            return 0.4;
        }
    }
    I14(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.H14(troubleshoot, troubleshoot2);
    }
    G15(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.H15(troubleshoot, troubleshoot2) + troubleshoot.I72() + troubleshoot2.I72();
    }
    H15(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const I5 = this.I5(troubleshoot, troubleshoot2);
        const H9 = this.H9(troubleshoot, troubleshoot2);
        const H6 = this.H6(troubleshoot, troubleshoot2);
        const H13 = this.H13;
        const H14 = this.H14(troubleshoot, troubleshoot2);
        return (I5 - H9 - H6 * 2 - H13 * 2 - H14 * 2) / 4;
    }
    I15(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.round(this.H15(troubleshoot, troubleshoot2) * 10) / 10;
    }
    H16(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H15 = this.H15(troubleshoot, troubleshoot2);
        const I5 = this.I5(troubleshoot, troubleshoot2);
        return (H15 * 4) / I5;
    }
    H17(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const i9 = this.I9(troubleshoot, troubleshoot2);
        const l20 = this.L20;
        const l21 = this.L21;
        const l22 = this.L22;
        const l23 = this.L23;
        const l24 = this.L24;
        if (i9 < l20) {
            return l22;
        } else if (i9 < l21 && i9 >= l20) {
            return l24;
        } else {
            return l23;
        }
    }
    H18(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G6 = this.G6(troubleshoot, troubleshoot2);
        if (G6 <= this.L25) {
            return this.L22;
        } else {
            return this.L24;
        }
    }
    G19(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H19 = this.H19(troubleshoot, troubleshoot2);
        return (H19 + troubleshoot.I74() + troubleshoot2.I74()) * 1000;
    }
    H19(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H17 = this.H17(troubleshoot, troubleshoot2);
        const H18 = this.H18(troubleshoot, troubleshoot2);
        return Math.max(H17, H18);
    }
    H20(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const h19 = this.H19(troubleshoot, troubleshoot2);
        const result = h19 * 1000;
        return result;
    }
    G21(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H21 = this.H21;
        return (H21 + troubleshoot.I70() + troubleshoot2.I70()) * 1000;
    }
    H21: number;
    G22(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H22 = this.H22;
        return (H22 + troubleshoot.I68() + troubleshoot2.I68()) * 1000;
    }
    H22: number;
    G23(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H23 = this.H23;
        return (H23 + troubleshoot.I69() + troubleshoot2.I69()) * 1000;
    }
    H23: number;
    F24: boolean;
    G24(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.max(this.H24(), troubleshoot.I73(), troubleshoot2.I73());
    }
    H24() {
        if (this.F24) {
            return this.L30;
        } else {
            return this.L29;
        }
    }
    F25: string;
    G25(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H25() + troubleshoot.I71() + troubleshoot2.I71()) * 1000;
    }
    H25() {
        if (this.F25 === optimisationLevel.optimised) {
            return this.L33;
        } else if (this.F25 === optimisationLevel.oneStepLoose) {
            return this.L32;
        } else if (this.F25 === optimisationLevel.twoStepLoose) {
            return this.L31;
        } else {
            return 0;
        }
    }
    F27: number;
    H27() {
        return this.F27;
    }
    I27() {
        return 337.5 / this.H27();
    }
    H28(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return -this.G6(troubleshoot, troubleshoot2);
    }
    H29(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const I14 = this.I14(troubleshoot, troubleshoot2);
        const I5 = this.I5(troubleshoot, troubleshoot2);

        return I5 - I14 * 2;
    }
    E30(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const E30 = this.H29(troubleshoot, troubleshoot2);
        return E30;
    }
    F30: number;
    F32: number;
    G32(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const sum = this.F32 + troubleshoot.I64() + troubleshoot2.I64();
        return sum;
    }
    H33(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const f30 = this.F30;
        const H20 = this.H20(troubleshoot, troubleshoot2);
        const result = f30 + H20;
        return result;
    }
    E34(odEngine: Engine, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot, worksheet: EbcWorksheet) {
        return odEngine.J36(worksheet, troubleshoot, troubleshoot2) * 1000;
    }
    H35(odEngine: Engine, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot, worksheet: EbcWorksheet) {
        const E34 = this.E34(odEngine, troubleshoot, troubleshoot2, worksheet);
        const H33 = this.H33(troubleshoot, troubleshoot2);
        const result = E34 - H33;
        return result;
    }
    H36(odEngine: Engine, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot, worksheet: EbcWorksheet) {
        const h35 = this.H35(odEngine, troubleshoot, troubleshoot2, worksheet);
        const result = h35 / 250;
        return result;
    }
    H37(odEngine: Engine, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot, worksheet: EbcWorksheet) {
        const f32 = this.F32;
        const H36 = this.H36(odEngine, troubleshoot, troubleshoot2, worksheet);
        const result = f32 + H36;
        return calculation.cropValueToMaxExcelDecimalPoints(result, 3);
    }
    F38 = 1.0;
    H38(odEngine: Engine, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot, worksheet: EbcWorksheet) {
        if (this.F38 === 1) {
            return this.H37(odEngine, troubleshoot, troubleshoot2, worksheet);
        } else if (this.F38 === 2) {
            return this.F32;
        } else {
            return 0;
        }
    }
    F44: 'TORIC LENS' | 'SPHERICAL LENS';
    F45(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.I9(troubleshoot, troubleshoot2) + this.I6(troubleshoot, troubleshoot2) * 2 + this.H13 * 2;
    }
    F46: number;
    F47: number;
    H47() {
        return (this.F47 * 0.55) / 0.0275;
    }
    F51: number;
    G51() {
        return this.F51 / (1 - this.L6 * -this.F51);
    }
    G52(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.G6(troubleshoot, troubleshoot2) + this.G51() + troubleshoot.I67() + troubleshoot2.I67();
    }
    H52 = this.G52;
    H53() {
        return (this.F46 / 2) * this.L34 + this.H27();
    }
    H54() {
        return this.F47 * this.L35 + this.H27();
    }
    H55() {
        return Math.max(this.H53(), this.H54());
    }
    I55() {
        return 337.5 / this.H55();
    }
    F64: number;
    G64(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.F64 + troubleshoot.I140() + troubleshoot2.I140_2();
    }
    H64(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.G64(troubleshoot, troubleshoot2) * this.L5;
    }
    I64(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.round(this.H64(troubleshoot, troubleshoot2) / 0.2) * 0.2;
    }
    F65: number;
    G65(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.F65 / (1 - this.L6 * -this.F65) + troubleshoot.I141() + troubleshoot2.I141();
    }
    H65(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G65 = this.G65(troubleshoot, troubleshoot2);
        if (G65 <= this.L7) {
            return this.L9;
        } else if (G65 <= this.L8 && G65 > this.L7) {
            return this.L10;
        } else {
            return this.L11;
        }
    }
    I65 = this.H65;
    H66(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G65 = this.G65(troubleshoot, troubleshoot2);
        if (G65 <= this.L12) {
            return this.L16;
        } else if (G65 <= this.L13 && G65 > this.L12) {
            return this.L15;
        } else {
            return this.L14;
        }
    }
    H67(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G65 = this.G65(troubleshoot, troubleshoot2);
        return G65 * this.L17 + this.L18 - G65;
    }
    F68: correction;
    H68(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        if (this.F68 === correction.miopiaControl) {
            return 5.4;
        } else {
            return this.H66(troubleshoot, troubleshoot2);
        }
    }
    I68(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (
            this.I64(troubleshoot, troubleshoot2) -
            this.I65(troubleshoot, troubleshoot2) * 2 -
            this.H72 * 2 -
            this.G74(troubleshoot, troubleshoot2) * 4 -
            this.I73(troubleshoot, troubleshoot2) * 2
        );
    }
    H71(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        if (this.F68 === correction.miopiaControl || this.F68 === correction.adult) {
            return this.H67(troubleshoot, troubleshoot2);
        } else {
            return this.H67(troubleshoot, troubleshoot2) / 2;
        }
    }
    I71(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.round(this.H71(troubleshoot, troubleshoot2) / 0.25) * 0.25;
    }
    H72 = 0.3;
    H73(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const I64 = this.I64(troubleshoot, troubleshoot2);
        if (I64 <= this.L19) {
            return 0.3;
        }
        return 0.4;
    }
    I73 = this.H73;
    G74(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.H74(troubleshoot, troubleshoot2) + troubleshoot.I147() + troubleshoot2.I147();
    }
    H74(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const I64 = this.I64(troubleshoot, troubleshoot2);
        const H68 = this.H68(troubleshoot, troubleshoot2);
        const H65 = this.H65(troubleshoot, troubleshoot2);
        const H72 = this.H72;
        const H73 = this.H73(troubleshoot, troubleshoot2);
        return (I64 - H68 - H65 * 2 - H72 * 2 - H73 * 2) / 4;
    }
    I74(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.floor(this.H74(troubleshoot, troubleshoot2) * 10) / 10;
    }
    H75(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H74(troubleshoot, troubleshoot2) * 4) / this.I64(troubleshoot, troubleshoot2);
    }
    H76(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const I68 = this.I68(troubleshoot, troubleshoot2);

        if (I68 < this.L20) {
            return this.L22;
        } else if (I68 >= this.L20 && I68 < this.L21) {
            return this.L24;
        } else {
            return this.L23;
        }
    }
    H77(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const G65 = this.G65(troubleshoot, troubleshoot2);
        if (G65 <= this.L25) {
            return this.L22;
        } else {
            return this.L24;
        }
    }
    G78(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H78(troubleshoot, troubleshoot2) + troubleshoot.I149() + troubleshoot2.I149()) * 1000;
    }
    H78(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.max(this.H76(troubleshoot, troubleshoot2), this.H77(troubleshoot, troubleshoot2));
    }
    H79(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.H78(troubleshoot, troubleshoot2) * 1000;
    }
    G80(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H80 + troubleshoot.I145() + troubleshoot2.I145()) * 1000;
    }
    H80: number;
    G81(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H81 + troubleshoot.I143() + troubleshoot2.I143()) * 1000;
    }
    H81: number;
    G82(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const H82 = this.H82;
        const tbsI44 = troubleshoot.I144();
        const tbs2I44 = troubleshoot2.I144();
        const result = (H82 + tbsI44 + tbs2I44) * 1000;
        return result;
    }
    H82: number;
    F83: boolean;
    G83(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return Math.max(this.H83(), troubleshoot.I148(), troubleshoot2.I148());
    }
    H83() {
        return this.F83 ? this.L30 : this.L29;
    }
    F84: optimisationLevel | '';
    G84(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return (this.H84() + troubleshoot.I146() + troubleshoot2.I146()) * 1000;
    }
    H84() {
        if (this.F84 === optimisationLevel.optimised) {
            return this.L33;
        } else if (this.F84 === optimisationLevel.oneStepLoose) {
            return this.L32;
        } else if (this.F84 === optimisationLevel.twoStepLoose) {
            return this.L31;
        }
        return 0;
    }
    F86: number;
    H86: number;
    I86() {
        return 337.5 / this.H86;
    }
    H87(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return -this.G65(troubleshoot, troubleshoot2);
    }
    H88(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        let result = this.I64(troubleshoot, troubleshoot2) - this.I73(troubleshoot, troubleshoot2) * 2;
        return result;
    }
    E89 = this.H88;
    F89: number;
    F91: number;
    G91(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.F91 + troubleshoot.I139() + troubleshoot2.I139();
    }
    H91(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.G91(troubleshoot, troubleshoot2) * 1000;
    }
    H92(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.F89 + this.H79(troubleshoot, troubleshoot2);
    }
    E93(osEngine: Engine, worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return osEngine.J36(worksheet, troubleshoot, troubleshoot2) * 1000;
    }
    H94(osEngine: Engine, worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const e93 = this.E93(osEngine, worksheet, troubleshoot, troubleshoot2);
        const h92 = this.H92(troubleshoot, troubleshoot2);
        const result = e93 - h92;
        return result;
    }
    H95(osEngine: Engine, worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.H94(osEngine, worksheet, troubleshoot, troubleshoot2) / 250;
    }
    H96(osEngine: Engine, worksheet: EbcWorksheet, troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        const h95 = this.H95(osEngine, worksheet, troubleshoot, troubleshoot2);
        const result = this.F91 + h95;
        return calculation.cropValueToMaxExcelDecimalPoints(result, 3);
    }
    F97 = 1.0;
    H97(osEngine: Engine, troubleshoot: Troubleshoot, worksheet: EbcWorksheet, troubleshoot2: Troubleshoot) {
        if (this.F97 === 1) {
            return this.H96(osEngine, worksheet, troubleshoot, troubleshoot2);
        } else if (this.F97 === 2) {
            return this.F91 + this.H79(troubleshoot, troubleshoot2);
        }
        return 0;
    }
    F103: 'SPHERICAL LENS' | 'TORIC LENS';
    F104(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.I68(troubleshoot, troubleshoot2) + this.I65(troubleshoot, troubleshoot2) * 2 + this.H72 * 2;
    }
    F105: number;
    F106: number;
    H106() {
        return (this.F106 * 0.55) / 0.0275;
    }
    F110: number;
    G110() {
        return this.F110 / (1 - this.L6 * -this.F110);
    }
    G111(troubleshoot: Troubleshoot, troubleshoot2: Troubleshoot) {
        return this.G65(troubleshoot, troubleshoot2) + this.G110() + troubleshoot.I142() + troubleshoot2.I142();
    }
    H111 = this.G111;
    H112() {
        return (this.F105 / 2) * this.L34 + this.H86;
    }
    H113() {
        return this.F106 * this.L35 + this.H86;
    }
    H114() {
        return Math.max(this.H112(), this.H113());
    }
    I114() {
        return 337.5 / this.H114();
    }
    F121: eye;

    constructor(d1: D1, fACDControlPanel: FacdControlPanel) {
        this.L27 = fACDControlPanel.ac1Tlt;
        this.L28 = fACDControlPanel.ac2Tlt;
        this.L6 = fACDControlPanel.vertex;
        this.L34 = fACDControlPanel.sagToAcConversion;
        this.L11 = fACDControlPanel.rcUpper;
        this.L8 = fACDControlPanel.rcRxLimitUpper;
        this.L7 = fACDControlPanel.rcRxLimitLower;
        this.L10 = fACDControlPanel.rcMiddle;
        this.L9 = fACDControlPanel.rcLower;
        this.L26 = fACDControlPanel.rc2Tlt;
        this.L17 = fACDControlPanel.jessenMultiply;
        this.L18 = fACDControlPanel.jessenAddition;
        this.L5 = fACDControlPanel.hvid;
        this.L33 = fACDControlPanel.elTltOptimal;
        this.L31 = fACDControlPanel.elTlt2Loose;
        this.L32 = fACDControlPanel.elTlt1Loose;
        this.L19 = fACDControlPanel.elHvidLimit;
        this.L35 = fACDControlPanel.deltaKToAcConversion;
        this.L16 = fACDControlPanel.bozdUpper;
        this.L13 = fACDControlPanel.bozdRxLimitUpper;
        this.L12 = fACDControlPanel.bozdRxLimitLower;
        this.L15 = fACDControlPanel.bozdMiddle;
        this.L14 = fACDControlPanel.bozdLower;
        this.L30 = fACDControlPanel.baseEccUpper;
        this.L29 = fACDControlPanel.baseEccLower;
        this.L23 = fACDControlPanel.apicalUpper;
        this.L25 = fACDControlPanel.apicalRxLimit;
        this.L24 = fACDControlPanel.apicalMiddle;
        this.L22 = fACDControlPanel.apicalLower;
        this.L21 = fACDControlPanel.apicalBozdLimitUpper;
        this.L20 = fACDControlPanel.apicalBozdLimitLower;
        this.L28 = fACDControlPanel.ac2Tlt;
        this.L27 = fACDControlPanel.ac1Tlt;

        this._D1 = d1;
        this.F5 = d1.H12;
        this.F6 = d1.H17;
        this.F9 = d1.H19;
        this.H21 = this.L26;
        this.H22 = this.L27;
        this.H23 = this.L28;
        this.F24 = d1.H37;
        this.F25 = d1.H36;
        this.F27 = d1.H22;
        this.F121 = d1.H10;
        this.F30 = d1.H23;
        this.F32 = d1.H25;
        this.F44 = d1.H35 ? 'TORIC LENS' : 'SPHERICAL LENS';
        this.F46 = d1.H28;
        this.F47 = d1.H31;
        this.F51 = d1.H32;
        this.F64 = d1.K12;
        this.F65 = d1.K17;
        this.F68 = d1.K19;
        this.F83 = d1.K37;
        this.F84 = d1.K36;
        this.F86 = d1.K22;
        this.F89 = d1.K23;
        this.F91 = d1.K25;
        this.F103 = d1.K35 ? 'TORIC LENS' : 'SPHERICAL LENS';
        this.F105 = d1.K28;
        this.F106 = d1.K31;
        this.H80 = this.L26;
        this.H81 = this.L27;
        this.H82 = this.L28;
        this.H86 = this.F86;
        this.F110 = d1.K32;
    }
}
