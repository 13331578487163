<div class="row mt-2">
    <div class="col-xl-6 col-12">
        <app-lab-form [labFormData]="labFormData" [treatmentEye]="eyeTreated"></app-lab-form>
    </div>
    <div class="col-xl-6 col-12">
        <app-order-status
            [orderId]="orderDetailsForTracker.order_id"
            [orderIdForDisplay]="orderDetailsForTracker.order_tracking_id"
            [order_status]="orderDetailsForTracker.order_status"
            [previous_status]="orderDetailsForTracker.previous_status"
            [created_at]="orderDetailsForTracker.created_at"
            [manufacturerStatus]="manufacturerStatus"
        >
        </app-order-status>
    </div>
</div>
