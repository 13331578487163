import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { updateUserDetails } from '@app/ui/actions/ui.actions';
import { getUserProfileDetails } from '@app/auth/actions/auth.action';
import { showUserProfileDetails } from '@app/auth/selectors/auth.selector';
import { CredentialsService } from '@app/core/services/credentials.service';
import { UpdateUserProfileRoleDetails, UpdateUserProfileUserDetails } from '@app/auth/models/auth.model';
import {
    GetUserProfileQuery,
    GetUserProfileQueryVariables,
    UpdateUserDetailsMutationVariables,
    User_Role_Mapping_Set_Input,
} from '@app/generated/graphql';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
    userRoleId = '';
    roleMappingId = '';
    currentUserId = '';
    submitButtonText = 'Edit Profile';
    editImgSrc = '/assets/icons/common/edit-icon.svg';
    disableFields = true;
    userProfileDetails!: GetUserProfileQuery;
    userDetails!: GetUserProfileQuery;
    userProfileForm: FormGroup = new FormGroup({});
    userProfileDetailsParams!: GetUserProfileQueryVariables;
    userProfileDetailsSubscription$ = new Subscription();

    @Output() closeEditUserProfileForm = new EventEmitter<{userId: string, roleId: string}>();

    constructor(private _fb: FormBuilder, private _credentialService: CredentialsService, private _store: Store) {}

    ngOnInit(): void {
        this.currentUserId = this._credentialService?.credentials?.userId || '';
        this.roleMappingId = this._credentialService?.credentials?.roleMappingId || '';
        this.userRoleId = this._credentialService?.credentials?.roleId || '';
        this.createEditProfileForm();
        this.disableFields = true;
        this.userProfileDetailsParams = {
            user_id: this.currentUserId,
            role_id: this.userRoleId
        };
        this._store.dispatch(getUserProfileDetails(this.userProfileDetailsParams));
        this.userProfileDetailsSubscription$ = this._store
            .select(showUserProfileDetails)
            .subscribe((userProfileDetails: GetUserProfileQuery | null) => {
                if(userProfileDetails){
                  this.userDetails = userProfileDetails;
                  this.updateQueriedDataToFields(userProfileDetails);
                }
            });
    }

    isDirty = (name: string): boolean => {
        return this.userProfileForm.controls[name]?.dirty;
    };

    isTouched = (name: string): boolean => {
        return this.userProfileForm.controls[name].touched;
    };

    isRequired = (name: string): boolean => {
        return this.userProfileForm.controls[name]?.errors?.['required'];
    };

    onCloseEditUserProfileModal = (): void => {
        this.closeEditUserProfileForm.emit({userId: this.currentUserId, roleId: this.userRoleId});
    };

    createEditProfileForm(): void {
        this.userProfileForm = this._fb.group({
            name: [{ value: '', disabled: this.disableFields }, Validators.required],
            email: [{ value: '', disabled: this.disableFields }, Validators.required],
            contact_number: [{ value: '', disabled: this.disableFields }, Validators.required],
            address: [{ value: '', disabled: this.disableFields }, Validators.required],
        });
    }

    /**
     * Enable the edit option once user clicks edit profile button
     */
    editUserDetails = (): void => {
        this.disableFields = false;
        this.submitButtonText = 'Update Profile';
        this.userProfileForm.controls['name'].enable();
        this.userProfileForm.controls['contact_number'].enable();
        this.userProfileForm.controls['address'].enable();
    };

    updateUserProfileDetails = (): void => {
        if (this.userProfileForm?.valid && this.submitButtonText === 'Update Profile') {
            const userDetails: UpdateUserProfileUserDetails = {
                name: this.userProfileForm.get('name')?.value,
                contact_number: this.userProfileForm.get('contact_number')?.value as string,
                updated_at: new Date().toUTCString(),
                updated_by: this.currentUserId,
            };

            const roleDetails: UpdateUserProfileRoleDetails = {
                name: (this.userProfileForm.get('name')?.value as string)?.toUpperCase(),
                role_details: {
                    ...this.userDetails?.user_role_mapping?.[0]?.role_details,
                    address: (this.userProfileForm.get('address')?.value as string)?.toUpperCase(),
                },
            };

            // Prepare update user object.
            const updateUserDetailsMutationVariables: UpdateUserDetailsMutationVariables = {
                id: this.currentUserId, // Id of the user who is getting updated.
                role_mapping_id: this.roleMappingId, // user_role_mapping table id against the updated user.
                userDetails: userDetails,
                roleDetails: roleDetails as User_Role_Mapping_Set_Input,
                cost: {},
                isInCostTable: false,
                isInDistributorManufacturerMappingTable: false,
                isInClinicDoctorMappingTable: false,
                isInDistributorFranchiseMappingTable: false,
                isInDistributorRegularMappingTable: false,
                isInDesignerDistributorMappingTable: false,
                isInClinicDistributorMappingTable: false,
                distributorObj: []
            };

            this._store.dispatch(updateUserDetails('dashboard', updateUserDetailsMutationVariables));
            this.userProfileForm.reset();
            this.onCloseEditUserProfileModal();
        }
    };

    /**
     * To append values to the respective form fields
     * @param userProfileDetails - queried data have users profile details
     */
    updateQueriedDataToFields = (userProfileDetails: GetUserProfileQuery | null): void => {
        const user = userProfileDetails?.user_role_mapping?.[0]?.user;
        this.userProfileForm.patchValue({
            ...user
        });
        this.userProfileForm.get('name')?.setValue(userProfileDetails?.user_role_mapping?.[0]?.name);
        if (userProfileDetails?.user_role_mapping?.[0]?.role_details != null) {
            this.userProfileForm.patchValue({
                address: userProfileDetails?.user_role_mapping?.[0]?.role_details?.address,
            });
        }
    };

    ngOnDestroy(): void {
        this.userProfileDetailsSubscription$.unsubscribe();
    }
}
