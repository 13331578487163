export interface Column {
    columnDef: string;
    header: string;
    isLink?: boolean;
    url?: string;
    isSortable: boolean,
    elementType?: ElementType
}

export enum ElementType {
  inputText = 'input-type-text',
  checkbox = 'checkbox',
  span = 'span',
  spanArray = 'span-array',
  empty = ''
}
