import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { catchError, Observable, tap, throwError } from 'rxjs';

import { AuthService } from './auth.service';
import { CredentialsService } from './credentials.service';
import { AuthErrorMessages, NetworkRequestBodyInterface, NetworkRequestErrorInterface } from '@app/auth/models/auth.model';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    apiUrl = '';
    constructor(
        private _authService: AuthService,
        private _credentialService: CredentialsService
    ) {}

    /**
     * Intercepts each requests and modified it to a new one
     * adds required headers as needed, eg: token for protected resource access
     * x-hasura-public-route - added for accessing data that donot have a jwt
     * @param request Http request object, containing req url e.t.c
     * @param next Handler that forwards the request to the chain
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let token;
        if (localStorage.getItem('role-based-token')) {
          token = this._credentialService.credentials?.token;
        } else {
          const tokenFromLocalStorage = localStorage.getItem('authFlowDetails') || "{}";
          token =  JSON.parse(tokenFromLocalStorage)?.initialAuthBasicDetails?.token;
        }
        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token),
            });
        }

        const jwtValidationExcludedQueries = this._authService.getListOfQueriesWithNoTokenNeeded();
        if (request) {
            const reqBody = request.body as NetworkRequestBodyInterface;
            const operationName = reqBody?.operationName;
            if (jwtValidationExcludedQueries.includes(operationName)) {
                request = request.clone({
                    headers: request.headers.set('x-hasura-public-route', 'true'),
                });
            }
        }

        request = request.clone({
            headers: request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate'),
        });
        request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        request = request.clone({
            headers: request.headers.set('ngrok-skip-browser-warning', 'true'),
        });
        return next.handle(request).pipe(
            tap((data) => {
                this.handleErrorCases(data as NetworkRequestErrorInterface);
            }),
            catchError((error: any) => {
                this.handleErrorCases(error);
                return throwError(() => new Error(error));
            })
        );
    }

    /**
     * Handles the different api error cases
     * @param error Http Error Response Object
     */
    private handleErrorCases(error: NetworkRequestErrorInterface) {
        const errorMessage = error?.body?.errors?.[0]?.message || error.name;

        switch(errorMessage) {
            case AuthErrorMessages.UNAUTHORIZED:
            case AuthErrorMessages.NO_JWT_PASSED_1:
                this._authService.clearAllUserDetailsAndLogOut();
                break;
        }
    }
}
