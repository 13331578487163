import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReviewDate } from '@app/patients/models/patients-review.model';

@Component({
  selector: 'app-date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.scss']
})
export class DateListComponent {
  activeLink = 0;

  @Input() reviewDates!: ReviewDate[];
  @Output() emitDateClickEvent = new EventEmitter<string>();

  onDateClicked = (id: string, index: number):void => {
    if(index >= 0){
      this.activeLink = index;
    }
    this.emitDateClickEvent.emit(id);
  }

  onDateClickedFromSelectDropdown = (id: string):void => {
    this.emitDateClickEvent.emit(id);
  }
}
