import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { metaReducers } from '.';

import { sharedReducer } from './reducers/shared.reducer';
import { SharedModuleEffects } from './effects/shared.effects';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { FacdDataTransformPipe } from './pipes/facd-data-transform.pipe';
import { AmendDataTransformPipe } from './pipes/amend-data-transform.pipe';
import { StatusColorDirective } from './directives/status-color.directive';
import { LabFormComponent } from './components/lab-form/lab-form.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LensInfoComponent } from './components/lens-info/lens-info.component';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { AlertPopup2Component } from './components/alert-popup2/alert-popup2.component';
import { PatientInfoComponent } from './components/patient-info/patient-info.component';
import { DoctorsSlipComponent } from './components/doctors-slip/doctors-slip.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { UserListingTableComponent } from './components/user-listing-table/user-listing-table.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AmendSummaryDetailsComponent } from './components/amend-summary-details/amend-summary-details.component';
import { CountryCodeSelectorComponent } from './components/forms/country-code-selector/country-code-selector.component';
import { PasswordCriteriaPopoverComponent } from './components/password-criteria-popover/password-criteria-popover.component';
import { PriceSettingsComponent } from './components/price-settings/price-settings.component';
import { ViewDesignTabsComponent } from './components/view-design-tabs/view-design-tabs.component';
import { FacdSummaryDetailsComponent } from './components/facd-summary-details/facd-summary-details.component';
import { AdditionalInfoComponent } from './components/additional_info/additional-info/additional-info.component';
import { AdditionalInfoClinicComponent } from './components/additional-info-clinic/additional-info-clinic/additional-info-clinic.component';
import { AdditionalInfoDoctorComponent } from './components/additional-info-doctor/additional-info-doctor/additional-info-doctor.component';
import { ViewDetailsSelectionComponent } from './components/view-details-selection/view-details-selection.component';
import { UserDeleteConfirmModalComponent } from './components/user-delete-confirm-modal/user-delete-confirm-modal.component';
import { DashboardUserListingTableComponent } from './components/dashboard-user-listing-table/dashboard-user-listing-table.component';
import { FilterComponent } from './components/filter/filter.component';
import { GrantMultipleRoleComponent } from './components/grant-multiple-role/grant-multiple-role.component';
import { SnakeCaseNormalTextPipe } from './pipes/snake-case-normal-text.pipe';
import { PatientBasicInfoFormComponent } from './components/patient-basic-info-form/patient-basic-info-form.component';
import { CapitalizeOrderStatusPipe } from './pipes/capitalize-order-status.pipe';
import { ManufacturerOrderDetailsComponent } from './components/manufacturer-order-details/manufacturer-order-details.component';
import { DistributorCommonDetailsComponent } from './components/distributor-common-details/distributor-common-details.component';
import { ManufacturerViewLabformComponent } from './components/manufacturer-view-labform/manufacturer-view-labform.component';
import { CapitalizeTypePipe } from './pipes/capitalize-type-tranform';
import { DateListComponent } from './components/date-list/date-list.component';
import { SiblingSelectionFormComponent } from './components/sibling-selection-form/sibling-selection-form.component';

@NgModule({
    declarations: [
        NavbarComponent,
        FooterComponent,
        ClickOutsideDirective,
        CountryCodeSelectorComponent,
        AlertPopupComponent,
        AlertPopup2Component,
        UserListingTableComponent,
        PasswordCriteriaPopoverComponent,
        AlertPopup2Component,
        ConfirmationDialogComponent,
        PatientInfoComponent,
        FacdDataTransformPipe,
        AmendDataTransformPipe,
        DoctorsSlipComponent,
        AmendSummaryDetailsComponent,
        LabFormComponent,
        OrderStatusComponent,
        PriceSettingsComponent,
        ViewDesignTabsComponent,
        FacdSummaryDetailsComponent,
        LensInfoComponent,
        AdditionalInfoComponent,
        AdditionalInfoClinicComponent,
        AdditionalInfoDoctorComponent,
        ViewDetailsSelectionComponent,
        UserDeleteConfirmModalComponent,
        DashboardUserListingTableComponent,
        FilterComponent,
        GrantMultipleRoleComponent,
        SnakeCaseNormalTextPipe,
        PatientBasicInfoFormComponent,
        ManufacturerOrderDetailsComponent,
        DistributorCommonDetailsComponent,
        ManufacturerViewLabformComponent,
        StatusColorDirective,
        CapitalizeOrderStatusPipe,
        CapitalizeTypePipe,
        DateListComponent,
        SiblingSelectionFormComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgbTooltipModule,
        NgbPopoverModule,
        NgbDatepickerModule,
        ClipboardModule,
        EffectsModule.forFeature([SharedModuleEffects]),
        StoreModule.forFeature('sharedReducer', sharedReducer, {metaReducers}),
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        ClickOutsideDirective,
        CountryCodeSelectorComponent,
        AlertPopupComponent,
        AlertPopup2Component,
        UserListingTableComponent,
        PasswordCriteriaPopoverComponent,
        ConfirmationDialogComponent,
        PatientInfoComponent,
        FacdDataTransformPipe,
        AmendDataTransformPipe,
        DoctorsSlipComponent,
        AmendSummaryDetailsComponent,
        LabFormComponent,
        OrderStatusComponent,
        PriceSettingsComponent,
        ViewDesignTabsComponent,
        FacdSummaryDetailsComponent,
        LensInfoComponent,
        AdditionalInfoComponent,
        AdditionalInfoClinicComponent,
        AdditionalInfoDoctorComponent,
        ViewDetailsSelectionComponent,
        UserDeleteConfirmModalComponent,
        DashboardUserListingTableComponent,
        FilterComponent,
        GrantMultipleRoleComponent,
        SnakeCaseNormalTextPipe,
        PatientBasicInfoFormComponent,
        DistributorCommonDetailsComponent,
        ManufacturerOrderDetailsComponent,
        ManufacturerViewLabformComponent,
        StatusColorDirective,
        CapitalizeOrderStatusPipe,
        CapitalizeTypePipe,
        DateListComponent,
        SiblingSelectionFormComponent
    ],
    providers: [
      DecimalPipe
    ]
})
export class SharedModule {}
