import {
    DeleteUserMutationVariables,
    GetDesignerTableListQuery,
    GetDesignerTableListQueryVariables,
} from '@app/generated/graphql';
import { Designer } from '@app/shared/models/designers.model';

export enum ActionType {
    OnboardDesigner = '[Designers] Onboard designer',
    OnboardDesignerSuccess = '[Designers] Onboard designer success',
    getDesignersList = '[Designers] Get designers list',
    getDesignersListSuccess = '[Designers] Get designers list success',
    getDesignerRoleDetails = '[Designers] Get designers role details',
    getDesignerRoleDetailsSuccess = '[Designers] Get designers role details success',
    getDesignerTableList = '[Designers] Get designers table list',
    getDesignerTableListSuccess = '[Designers] Get designers table list success',
    getDesignerTableListFailure = '[Designers] Get designers table list failure',
    getTotalTableItemsCount = '[Designers] Get total table list count',
    getTotalTableItemsCountSuccess = '[Designers] Get total table list count success',
}

export interface State {
    designers: Designer[];
    onboardingRoleId: string;
    designerTableList: GetDesignerTableListQuery;
    totalTableListCount: number;
}

export const initialState: State = {
    designers: [],
    onboardingRoleId: '',
    designerTableList: { user_role_mapping: [] },
    totalTableListCount: 0,
};

export interface OnboardingUpdateDesignerUserDetails {
    contact_number: string;
    country_code: string;
    updated_at: string;
    updated_by: string;
    email: string;
}

export interface OnboardingUpdateDesignerUserRoleDetails {
    name: string,
    role_details: OnboardingDesignerRoleDetails;
}

export interface OnboardingDesignerRoleDetails {
    address: string;
    lens_brand_name: string;
    registration_number: string;
}

/* Designer table listing model - 23-03-2023 */
export interface DesignerListData {
    id: string;
    name: string;
    contact_number: string;
    lens_brand_name: string;
    registration_number: string;
    created_at: string;
    role_mapping_id: string;
    role_id: string;
}
