import { Component, Input, OnChanges } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ReviewSummaryHeading } from '@app/core/util/common';
import { PatientInfo } from '@app/shared/models/patient-details.model';
import { AData, D1Data } from '@app/core/treatment-engine/interfaces';
import { initializeAmendDetails, initializeFacdDetails } from '@app/core/util/treatment-output-details';

@Component({
    selector: 'app-view-design-tabs',
    templateUrl: './view-design-tabs.component.html',
    styleUrls: ['./view-design-tabs.component.scss'],
})
export class ViewDesignTabsComponent implements OnChanges {
    summaryFormName = ReviewSummaryHeading.EMPTY;
    lensDetails: {
        lensColorRight: string; 
        lensColorLeft: string;
        lensMaterialRight: string;
        lensMaterialLeft: string;
        lab_of_choice: string;
    } = {
        lensColorRight: '', 
        lensColorLeft: '',
        lensMaterialRight: '',
        lensMaterialLeft: '',
        lab_of_choice: '',
    };
    patientInfoDetailsToPassFacd$: Observable<PatientInfo[]> = of();
    patientInfoDetailsToPassSat$: Observable<PatientInfo[]> = of();
    patientInfoDetailsToPassMat$: Observable<PatientInfo[]> = of();

    @Input() isFacdVisible = true;
    @Input() isSatVisible = false;
    @Input() isMatVisible = false;
    @Input() hideButtonsForFacdSubmit = true;
    @Input() facdData: D1Data = initializeFacdDetails;
    @Input() satDataFilledByDoctor: AData = initializeAmendDetails;
    @Input() matDataFilledByDoctor: AData = initializeAmendDetails;
    @Input() patientInfoDetailsToForFacd: PatientInfo[] = [];
    @Input() patientInfoDetailsToForSat: PatientInfo[] = [];
    @Input() patientInfoDetailsToForMat: PatientInfo[] = [];

    ngOnChanges(): void {
        // if facd summary is to be shown, then lens details are also shown
        // that data is fetched from facd json and passed to lens data variable
        if (this.facdData) {
            this.lensDetails = {
                ...this.lensDetails,
                lensColorRight: this.facdData?.lensColor.right,
                lensColorLeft: this.facdData?.lensColor.left,
                lensMaterialRight: this.facdData?.question11.right,
                lensMaterialLeft: this.facdData?.question11.left,
                lab_of_choice: this.facdData?.labName || '',
            };
        }

        // for facd/sat/mat patient details are being shown on top of the summary
        // they differ in the data they are showing, hence three variables are used
        this.patientInfoDetailsToPassFacd$ = of(this.patientInfoDetailsToForFacd);
        this.patientInfoDetailsToPassSat$ = of(this.patientInfoDetailsToForSat);
        this.patientInfoDetailsToPassMat$ = of(this.patientInfoDetailsToForMat);
    }
}
