<div class="grant-multiple-role-container px-5 pb-5 pt-3 position-relative">
    <img
        src="/assets/icons/common/close.svg"
        class="cursor-pointer position-absolute top-0 end-0 me-3 mt-3"
        aria-label="close"
        (click)="activeModal.dismiss()"
        alt="close modal"
    />
    <div class="text-center mt-5">
        <h2 class="kl-h7-type-1">Grant Multiple User Role</h2>
        <p class="kl-text5-type2">Please add the following details to grant {{ userName }} additional roles</p>
    </div>
    <form [formGroup]="grantRoleForm" (ngSubmit)="onGrantRole()">
        <div *ngIf="roleList.length > 0">
            <div class="form-group kl-select-container w-100">
                <label for="role" class="kl-text5-type-11 mb-1">Select Role</label>
                <select class="kl-select w-100" (change)="onSelectingRole($event)" formControlName="role" name="role">
                    <option [value]="''" selected>Select Desired Role</option>
                    <ng-container *ngFor="let role of roleList">
                        <option
                            *ngIf="
                                role.name !== 'super_admin' && role.name !== 'patient' && grantAccessFor !== role.name
                            "
                            [value]="role.id"
                        >
                            {{ role.name | snakeCaseNormalText }}
                        </option>
                    </ng-container>
                </select>
            </div>
        </div>
        <span class="error-text" *ngIf="isInvalid('role')"> Please select a role. </span>
        <div *ngFor="let controlData of grantMultipleRoleFormControlsData[selectedRole]">
            <div *ngIf="controlData.type === 'input'">
                <label [for]="controlData.id" class="kl-text5-type-11 mt-3 mb-1">{{ controlData.label }}</label>
                <input
                    [type]="controlData?.valueType || 'text'"
                    class="kl-input-text input-text-type-2"
                    [formControlName]="controlData.id"
                    [placeholder]="controlData.placeholder"
                />
                <div *ngIf="isInvalid(controlData.id)" class="error-text">{{ controlData.label }} is required</div>
            </div>
            <div class="form-group kl-select-container w-100" *ngIf="controlData.type === 'select'">
                <label [for]="controlData.id" class="kl-text5-type-11 mt-3 mb-1">{{ controlData.label }}</label>
                <select
                    (change)="onControlDataSelectChange($event)"
                    class="kl-select w-100"
                    [id]="controlData.id"
                    [formControlName]="controlData.id"
                    [name]="controlData.id"
                >
                    <option value="" disabled>{{ controlData.placeholder }}</option>
                    <ng-container *ngFor="let item of controlData.options" >
                      <option *ngIf="!(selectedRole === 'franchise_clinic' && item.name === 'Selective Distributor')" [value]="item.id" >{{ item.name }}</option>
                    </ng-container>
                </select>
                <div *ngIf="isInvalid(controlData.id)" class="error-text">{{ controlData.label }} is required</div>
            </div>
        </div>

        <ng-container *ngIf="grantRoleForm.get('distributor_type')?.value">
            <div *ngFor="let controlData of grantMultipleRoleDistributorFormControlsData[selectedDistributor]">
                <div class="form-group kl-select-container w-100" *ngIf="controlData.type === 'select'">
                    <label [for]="controlData.id" class="kl-text5-type-11 mt-3 mb-1">{{ controlData.label }}</label>
                    <select
                        class="kl-select w-100"
                        [id]="controlData.id"
                        [formControlName]="controlData.id"
                        [name]="controlData.id"
                    >
                        <option value="" disabled>{{ controlData.placeholder }}</option>
                        <option *ngFor="let item of controlData.options" [value]="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div *ngIf="isInvalid(controlData.id)" class="error-text">{{ controlData.label }} is required</div>
            </div>
        </ng-container>
        <div class="col-12" *ngIf="selectedRole === 'doctor'">
            <span class="kl-text5-type3 required-alert-text">Please visit practitioners details page to add partners</span>
        </div>
        <div
            class="col-12"
            *ngIf="selectedRole !== 'doctor' &&
                selectedRole !== 'contract_manufacturer' &&
                selectedRole"
        >
            <span class="kl-text5-type3 required-alert-text">Please visit the added users details to configure price</span>
        </div>
        <div class="d-md-flex d-block justify-content-center mt-5 mb-3">
            <div class="col-12 col-md-6 pe-2 mb-2 mb-md-0">
                <button type="button" class="btn w-100 kl-btn-outline-type-1" (click)="activeModal.dismiss()">
                    Cancel
                </button>
            </div>
            <div class="col-12 col-md-6 ps-2">
                <button type="submit" class="btn w-100 kl-btn-primary-type-2">Grant</button>
            </div>
        </div>
    </form>
</div>
