import { createAction, props } from '@ngrx/store';

import { CountryCodeDetails, FormMode, UrlCollection, UserPermissionsData, UserRole } from '../models/ui.model';
import { GetOnboardUserFormDataQuery, GetOnboardUserFormDataQueryVariables,  GetMoreDetailsOfUserQuery, GetMoreDetailsOfUserQueryVariables, GetRoleDetailsQuery, GetRoleDetailsQueryVariables, GetUserEmailBasedOnIdQueryVariables, UpdateUserDetailsMutationVariables, UpdateUserDetailsMutation, GetGrantedRoleListPerUserQueryVariables } from '@app/generated/graphql';
import { EventExtra } from '@app/shared/models/shared.model';

export enum ActionType {
    SET_LOADING_ACTION = '[shared state] set loading spinner',
    SET_OPAQUE_LOADING_ACTION = '[shared state] set opaque loading spinner',
    SET_ERROR_MESSAGE = '[shared state] set error message',
    getLoggedInUserEmail = '[shared state] Get logged in user email id',
    getLoggedInUserEmailSuccess = '[shared state] Get logged in user email id success',
    storeUserPermissions = '[shared state] Store logged in user permissions based on role',
    storeUserPermissionsSuccess = '[shared state] Store logged in user permissions success',
    getOnboardUserFormData = '[shared state] Get onboard user form data',
    getOnboardUserFormDataSuccess = '[shared state] Get onboard user form data success',
    updateUserDetails = '[shared state] Update user details',
    updateUserDetailsSuccess = '[shared state] Update user details success',
    getRoleDetails = '[UI] Get designers role details',
    getRoleDetailsSuccess = '[UI] Get designers role details success',
    getMoreDetailsOfUser = '[UI] Get more details of user',
    getMoreDetailsOfUserSuccess = '[UI] Get more details of user success',
    updateBreadCrumpUrlCollection = '[UI] Update url collection',
    addItemToBreadCrumpUrlCollection = '[UI] Add item to url collection',
    clearBreadCrumpUrlCollection = '[UI] Clear url collection',
    removeItemFromBreadCrumpUrlCollection = '[UI] Remove item from url collection',
    updateBreadCrumpRootUrl = '[UI] Update root url',
    updateSideNavBarStatus = '[UI] Show/hide side and navbar based on facd form',
    updateModuleChange = '[UI] Update module change',
    resetOnboardedUserData = '[shared state] Initialize onboarded data',
    getGrantedRoleListPerUser = '[UI] Get granted role list per user',
    getGrantedRoleListPerUserSuccess = '[UI] Get granted role list per user success',
    getCountryCodeDetails = '[UI] get country code details',
    getCountryCodeDetailsSuccess = '[UI] get country code details Success',
    updateFormMode = '[UI] Update form mode',
}

export const setLoadingSpinner = createAction(ActionType.SET_LOADING_ACTION, props<{ status: boolean }>());

export const setOpaqueLoadingSpinner = createAction(ActionType.SET_OPAQUE_LOADING_ACTION, props<{ status: boolean }>());

export const setErrorMessage = createAction(ActionType.SET_ERROR_MESSAGE, props<{ message: string }>());

// fetch some common details after a user is logged in
// action to store role permissions
export const getRoleDetails = createAction(ActionType.getRoleDetails, (data: GetRoleDetailsQueryVariables) => ({
    data,
}));
export const getRoleDetailsSuccess = createAction(ActionType.getRoleDetailsSuccess, (data: GetRoleDetailsQuery) => ({
    data,
}));
export const getLoggedInUserEmail = createAction(
    ActionType.getLoggedInUserEmail,
    (data: GetUserEmailBasedOnIdQueryVariables) => ({ data })
);
export const getLoggedInUserEmailSuccess = createAction(ActionType.getLoggedInUserEmailSuccess, (data: string) => ({
    data,
}));
export const getMoreDetailsOfUser = createAction(
    ActionType.getMoreDetailsOfUser,
    (userIdObj: GetMoreDetailsOfUserQueryVariables) => userIdObj
);
export const getMoreDetailsOfUserSuccess = createAction(
    ActionType.getMoreDetailsOfUserSuccess,
    (moreUserDetails: GetMoreDetailsOfUserQuery) => ({ moreUserDetails })
);
export const storeUserPermissions = createAction(ActionType.storeUserPermissions);
export const storeUserPermissionsSuccess = createAction(
    ActionType.storeUserPermissionsSuccess,
    (permissions: UserPermissionsData) => ({ permissions })
);

// common actions used to onboard
export const getOnboardUserFormData = createAction(
    ActionType.getOnboardUserFormData,
    (userObj: GetOnboardUserFormDataQueryVariables) => ({ userObj })
);
export const getOnboardUserFormDataSuccess = createAction(
    ActionType.getOnboardUserFormDataSuccess,
    (onboardFormData: GetOnboardUserFormDataQuery) => ({ onboardFormData })
);
export const resetOnboardedUserData = createAction(ActionType.resetOnboardedUserData);

// Update user basic info from onboarding form.
export const updateUserDetails = createAction(
    ActionType.updateUserDetails,
    (role: string, userDetails: UpdateUserDetailsMutationVariables, eventExtra?: EventExtra, ) => ({ role, userDetails, eventExtra })
);
export const updateUserDetailsSuccess = createAction(
    ActionType.updateUserDetailsSuccess,
    (updateUserDetailsSuccess: UpdateUserDetailsMutation) => ({ updateUserDetailsSuccess })
);

// actions to add data to breadcrumps
export const updateBreadCrumpUrlCollection = createAction(
    ActionType.updateBreadCrumpUrlCollection,
    (urlCollection: UrlCollection[]) => ({ urlCollection })
);
export const addItemToBreadCrumpUrlCollection = createAction(
    ActionType.addItemToBreadCrumpUrlCollection,
    (urlCollection: UrlCollection) => ({ urlCollection })
);
export const updateBreadCrumpRootUrl = createAction(ActionType.updateBreadCrumpRootUrl, (rootUrl: string) => ({
    rootUrl,
}));

export const updateModuleChange = createAction(ActionType.updateModuleChange, (isModuleChanged: boolean) => ({isModuleChanged}));

export const clearBreadCrumpUrlCollection = createAction(ActionType.clearBreadCrumpUrlCollection);

// actions to hide/show navbar when facd form is shown
export const updateSideNavBarStatus = createAction(
  ActionType.updateSideNavBarStatus,
  (sideNavBarStatus: boolean) => ({ sideNavBarStatus })
);

export const getGrantedRoleListPerUser = createAction(
  ActionType.getGrantedRoleListPerUser,
  (getGrantedRoleListPerUserQueryParam: GetGrantedRoleListPerUserQueryVariables) => ({ getGrantedRoleListPerUserQueryParam })
);

export const getGrantedRoleListPerUserSuccess = createAction(
  ActionType.getGrantedRoleListPerUserSuccess,
  (grantedRoles: UserRole[]) => ({ grantedRoles })
);

export const getCountryCodeDetails = createAction(
  ActionType.getCountryCodeDetails
);

export const getCountryCodeDetailsSuccess = createAction(
  ActionType.getCountryCodeDetailsSuccess,
  (countryCodeDetails: CountryCodeDetails[]) => ({ countryCodeDetails })
);

export const updateFormMode = createAction(
  ActionType.updateFormMode,
  (formMode: FormMode) => ({ formMode })
);
