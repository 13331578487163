import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { distributorCommonDetailsInitialData } from '@app/core/util/initial-values';
import { DistributorCommonDetailsForManufacturer } from '@app/shared/models/shared.model';

@Component({
    selector: 'app-distributor-common-details',
    templateUrl: './distributor-common-details.component.html',
    styleUrls: ['./distributor-common-details.component.scss'],
})
export class DistributorCommonDetailsComponent implements OnChanges {
    distributorsForm!: FormGroup;

    @Input() distributorFormData: DistributorCommonDetailsForManufacturer = distributorCommonDetailsInitialData;

    constructor(private _fb: FormBuilder) {
        this.initializeForm();
    }
    
    ngOnChanges(): void {
        if (this.distributorsForm) {
            this.distributorsForm.patchValue(this.distributorFormData);
        }
    }

    initializeForm = (): void => {
        this.distributorsForm = this._fb.group({
            name: [''],
            registration_number: [''],
            address: [''],
            contact_number: [''],
            country_code: ['65'],
            email: [''],
            device_licence_no: [''],
            distributor_licence_number: [''],
        });
    };
}
