<div class="row">
    <div class="col-12">
        <div class="onboarding-form w-100">
            <p class="kl-text3-type-6 distributor-info">Distributor Info</p>
            <form [formGroup]="distributorsForm">
                <div class="row">
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-name" class="kl-text5-type2 mb-1">
                            Company name <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="text"
                            formControlName="name"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-name"
                            placeholder="Company name"
                        />
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-registration-number" class="kl-text5-type2 mb-1">
                            Company registration number <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="text"
                            formControlName="registration_number"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-registration-number"
                            placeholder="Company registration number"
                        />
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-address" class="kl-text5-type2 mb-1">
                            Address <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="text"
                            formControlName="address"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-address"
                            placeholder="Address"
                        />
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-phone" class="kl-text5-type2 mb-1">
                            Contact number <sup class="field-required">*</sup>
                        </label>
                        <div
                            class="d-flex justify-content-start align-items-center select-phone-number-wrap"
                            #phoneInput
                        >
                            <input formControlName="country_code" hidden />
                            <app-country-code-selector></app-country-code-selector>
                            <input
                                type="tel"
                                formControlName="contact_number"
                                class="kl-input-text input-text-type-2 no-edit"
                                id="company-phone"
                                placeholder="Contact number"
                            />
                        </div>
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-email" class="kl-text5-type2 mb-1">
                            Company email ID <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="email"
                            formControlName="email"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-email"
                            placeholder="Email ID"
                        />
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-license-number" class="kl-text5-type2 mb-1">
                            Medical device import license number <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="text"
                            formControlName="device_licence_no"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-license-number"
                            placeholder="Company license number"
                        />
                    </div>
                    <div class="col-md-6 col-12 onboard-input-wrap mb-2">
                        <label for="company-distributor-license-number" class="kl-text5-type2 mb-1">
                            Medical device distributor license number <sup class="field-required">*</sup>
                        </label>
                        <input
                            type="text"
                            formControlName="distributor_licence_number"
                            class="kl-input-text input-text-type-2 no-edit"
                            id="company-distributor-license-number"
                            placeholder="Company distributor license number"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
