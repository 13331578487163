import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of } from 'rxjs';
import { Store } from '@ngrx/store';

import {
    GetDesignerListGQL,
    GetDesignerListQuery,
    GetDesignerListQueryVariables,
    GetOnboardUserFormDataGQL,
    GetOnboardUserFormDataQuery,
    GetOnboardUserFormDataQueryVariables,
    GetRoleDetailsGQL,
    GetRoleDetailsQuery,
    GetRoleDetailsQueryVariables,
    GetUserEmailBasedOnIdGQL,
    GetUserEmailBasedOnIdQuery,
    GetUserEmailBasedOnIdQueryVariables,
    GetMoreDetailsOfUserGQL,
    GetMoreDetailsOfUserQuery,
    GetMoreDetailsOfUserQueryVariables,
    UpdateUserDetailsGQL,
    UpdateUserDetailsMutationVariables,
    UpdateUserDetailsMutation,
    DeleteUserMutationVariables,
    DeleteUserGQL,
    DeleteUserMutation,
    GetUserListBasedOnRoleGQL,
    GetUserListBasedOnRoleQueryVariables,
    GetUserListBasedOnRoleQuery,
    GetPermissionsBasedOnRoleGQL,
    GetPermissionsBasedOnRoleQueryVariables,
    GetPermissionsBasedOnRoleQuery,
    GetDoctorsListQuery,
    GetDoctorsListQueryVariables,
    GetDoctorsListGQL,
    OnboardPatientMutationVariables,
    OnboardPatientMutation,
    OnboardPatientGQL,
    GetPatientClinicInfoGQL,
    GetPatientClinicInfoQuery,
    GetPatientClinicInfoQueryVariables,
    GetUserFormData1GQL,
    GetUserFormData1QueryVariables,
    GetUserFormData1Query,
    OnboardDoctorMutationVariables,
    OnboardDoctorMutation,
    OnboardDoctorGQL,
    OnboardRegularClinicGQL,
    OnboardRegularClinicMutationVariables,
    OnboardFranchiseClinicGQL,
    OnboardFranchiseClinicMutationVariables,
    OnboardFranchiseClinicMutation,
    GetFranchiseClinicFormDataGQL,
    GetFranchiseClinicFormDataQueryVariables,
    GetFranchiseClinicFormDataQuery,
    OnboardRegularClinicMutation,
    GetFranchiseClinicDoctorsListQueryVariables,
    GetFranchiseClinicDoctorsListQuery,
    GetFranchiseClinicDoctorsListGQL,
    GetDoctorInfoQueryVariables,
    GetDoctorInfoQuery,
    GetDoctorInfoGQL,
    Cost_Set_Input,
    GetPatientTransferRequestListGQL,
    GetPatientTransferRequestListCountGQL,
    GetPatientTransferRequestListQueryVariables,
    GetPatientTransferRequestListQuery,
    GetPatientTransferRequestListCountQueryVariables,
    GetPatientTransferRequestListCountQuery,
    OnboardContractManufacturerMutationVariables,
    OnboardContractManufacturerMutation,
    OnboardContractManufacturerGQL,
    GetAllLensesCountQuery,
    GetAllLensesCountQueryVariables,
    GetAllLensesCountGQL,
    GetLensDetailsQueryVariables,
    GetLensDetailsQuery,
    GetLensDetailsGQL,
    CreateNewLensMutationVariables,
    CreateNewLensMutation,
    CreateNewLensGQL,
    SaveControlPanelDetailsMutationVariables,
    SaveControlPanelDetailsMutation,
    SaveControlPanelDetailsGQL,
    GetControlPanelDetailsGQL,
    GetControlPanelDetailsQueryVariables,
    GetControlPanelDetailsQuery,
    UpdateControlPanelDetailsMutation,
    UpdateControlPanelDetailsMutationVariables,
    UpdateControlPanelDetailsGQL,
    GetControlPanelValuesByOrderIdGQL,
    GetControlPanelValuesByOrderIdQueryVariables,
    GetControlPanelValuesByOrderIdQuery,
    GetRoleSpecificUsersQueryVariables,
    GetRoleSpecificUsersQuery,
    GetRoleSpecificUsersGQL,
    DeleteUserOrDeleteUserAndReassignGQL,
    DeleteUserOrDeleteUserAndReassignMutation,
    DeleteUserOrDeleteUserAndReassignMutationVariables,
    GetFranchiseClinicsForUserDetailsPageQueryVariables,
    GetFranchiseClinicsForUserDetailsPageQuery,
    GetFranchiseClinicsForUserDetailsPageGQL,
    GetRegularClinicsForUserDetailsPageQueryVariables,
    GetRegularClinicsForUserDetailsPageQuery,
    GetRegularClinicsForUserDetailsPageGQL,
    GetDoctorsForUserDetailsPageQueryVariables,
    GetDoctorsForUserDetailsPageQuery,
    GetDoctorsForUserDetailsPageGQL,
    GetPatientsForUserDetailsPageQueryVariables,
    GetPatientsForUserDetailsPageQuery,
    GetPatientsForUserDetailsPageGQL,
    GetOrdersForUserDetailsPageQueryVariables,
    GetOrdersForUserDetailsPageQuery,
    GetOrdersForUserDetailsPageGQL,
    GetContractManufacturersForUserDetailsPageQueryVariables,
    GetContractManufacturersForUserDetailsPageGQL,
    GetContractManufacturersForUserDetailsPageQuery,
    GetTotalCountOfOrdersForUserDetailsPageGQL,
    GetTotalCountOfDoctorForUserDetailsPageGQL,
    GetTotalCountOfPatientForUserDetailsPageGQL,
    GetTotalCountOfClinicForUserDetailsPageQueryVariables,
    GetTotalCountOfClinicForUserDetailsPageQuery,
    GetTotalCountOfClinicForUserDetailsPageGQL,
    GetTotalCountOfContractManufacturerForUserDetailsPageGQL,
    GetTotalCountOfContractManufacturerForUserDetailsPageQueryVariables,
    GetTotalCountOfContractManufacturerForUserDetailsPageQuery,
    GetTotalCountOfOrdersForUserDetailsPageQuery,
    GetTotalCountOfOrdersForUserDetailsPageQueryVariables,
    GetTotalCountOfDoctorForUserDetailsPageQueryVariables,
    GetTotalCountOfDoctorForUserDetailsPageQuery,
    GetTotalCountOfPatientForUserDetailsPageQuery,
    GetTotalCountOfPatientForUserDetailsPageQueryVariables,
    GetTotalCountOfDistributorsForUserDetailsPageGQL,
    GetTotalCountOfDistributorsForUserDetailsPageQueryVariables,
    GetTotalCountOfDistributorsForUserDetailsPageQuery,
    GetUserFormDataGQL,
    GetUserFormDataQueryVariables,
    GetUserFormDataQuery,
    GetDesignerIdByDoctorIdQueryVariables,
    GetDesignerIdByDoctorIdQuery,
    GetDesignerIdByDoctorIdGQL,
    GetControlPanelValuesByDesignerIdQueryVariables,
    GetControlPanelValuesByDesignerIdQuery,
    GetControlPanelValuesByDesignerIdGQL,
    GetDistributorManufacturerMappingsIdQueryVariables,
    GetDistributorManufacturerMappingsIdGQL,
    InsertContractDistributorIdToMappingsTableGQL,
    InsertContractDistributorIdToMappingsTableMutationVariables,
    GetDistributorManufacturerMappingsIdQuery,
    InsertContractDistributorIdToMappingsTableMutation,
    GetDistributorByDoctorAndClinicIdQuery,
    GetDistributorByDoctorAndClinicIdQueryVariables,
    GetDistributorByDoctorAndClinicIdGQL,
    GetDetailsOfOrderByIdQuery,
    CheckIfUserEmailExistInUserTableGQL,
    CheckIfUserEmailExistInUserTableQueryVariables,
    CheckIfUserEmailExistInUserTableQuery,
    GetUserByUserIdQueryVariables,
    GetUserByUserIdQuery,
    GetOrdersOfAPatientQueryVariables,
    GetOrdersOfAPatientQuery,
    GetUserByUserIdGQL,
    GetOrdersOfAPatientGQL,
    DeleteSelectedLensGQL,
    DeleteSelectedLensMutationVariables,
    GetOrdersListBasedOnDistributorIdQueryVariables,
    GetOrdersListBasedOnDistributorIdGQL,
    GetOrdersListBasedOnDistributorIdQuery,
    GetOrdersCountBasedOnDistributorIdQueryVariables,
    GetOrdersCountBasedOnDistributorIdQuery,
    GetOrdersCountBasedOnDistributorIdGQL,
    GrantMultipleRolesToUsersMutationVariables,
    GrantMultipleRolesToUsersMutation,
    GrantMultipleRolesToUsersGQL,
    GetGrantedRoleListPerUserGQL,
    GetGrantedRoleListPerUserQueryVariables,
    GetGrantedRoleListPerUserQuery,
    BulkUpdateOrderStatusMutationVariables,
    BulkUpdateOrderStatusMutation,
    BulkUpdateOrderStatusGQL,
    InsertOrderStatusTrackingDataMutationVariables,
    InsertOrderStatusTrackingDataMutation,
    GetOrderDetailsByIdQuery,
    GetOrderDetailsByIdQueryVariables,
    InsertOrderStatusTrackingDataGQL,
    GetOrderDetailsByIdGQL,
    GetRoleHierarchyForLoggedInUserGQL,
    GetRoleHierarchyForLoggedInUserQueryVariables,
    GetRoleHierarchyForLoggedInUserQuery,
    GetDistributorsForUserDetailsPageGQL,
    GetDistributorsForUserDetailsPageQueryVariables,
    GetDistributorsForUserDetailsPageQuery,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionGQL,
    InsertToPaymentForTotalCostAndSalesGQL,
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery,
    InsertToPaymentForTotalCostAndSalesMutation,
    InsertToPaymentForTotalCostAndSalesMutationVariables,
    GetListOfUserBasedOnRoleIdQueryVariables,
    GetListOfUserBasedOnRoleIdQuery,
    GetListOfUserBasedOnRoleIdGQL,
    GetDistributorsDetailsByIdsUnderDesignerQueryVariables,
    GetDistributorsDetailsByIdsUnderDesignerQuery,
    InsertInCostTableGQL,
    InsertInCostTableMutationVariables,
    InsertInCostTableMutation,
    GetSuperiorIdOfUserQueryVariables,
    GetSuperiorIdOfUserQuery,
    GetSuperiorIdOfUserGQL,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutation,
    UpdateBuyerRoleIdToPaymentTableForUpgradedUserGQL,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserGQL,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables,
    UpdateSellerRoleIdToPaymentTableForUpgradedUserMutation,
    GetCountryCodeDetailsGQL,
    GetCountryCodeDetailsQuery,
    Order_Status_Enum,
    UpdateUserRoleDetailsGQL,
    UpdateUserRoleDetailsMutationVariables,
    UpdateUserRoleDetailsMutation,
} from '@app/generated/graphql';
import {
    ClinicForUserDetailsPage,
    ContractManufacturerForUserDetailsPage,
    CustomDate,
    DistributorForUserDetailsPage,
    DoctorForUserDetailsPage,
    OrderDetails,
    OrderForUserDetailsPage,
    PatientForUserDetailsPage,
    PriceSettingFields,
    RoleHierarchyForLoggedInUser,
    User,
} from '@app/shared/models/shared.model';
import { filterValues } from '../util/initial-values';
import { CountryCodeDetails, UserRole } from '@app/ui/models/ui.model';
import { Roles } from '../util/common';
import { FormDataHandlerService } from './form-data-handler.service';
import { CredentialsService } from './credentials.service';
import { CareOfPatient } from '@app/patients/models/patients.model';
import { updateCareOfPatient } from '@app/patients/actions/patients.action';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    userRoleUpdateSubject$ = new BehaviorSubject<boolean>(false);
    updateOrderSubject$ = new BehaviorSubject<Order_Status_Enum | string>('');
    constructor(
        private _store: Store,
        private _onGetRoleDetailsGQL: GetRoleDetailsGQL,
        private _getDesignerListGQL: GetDesignerListGQL,
        private _getEmailOfUser: GetUserEmailBasedOnIdGQL,
        private _getOnboardUserFormDataGQL: GetOnboardUserFormDataGQL,
        private _getMoreDetailsOfUser: GetMoreDetailsOfUserGQL,
        private _updateUserDetailsGQL: UpdateUserDetailsGQL,
        private _deleteUserMutationGQL: DeleteUserGQL,
        private _getUserListBasedOnRoleGQL: GetUserListBasedOnRoleGQL,
        private _getRolePermissionsOfUser: GetPermissionsBasedOnRoleGQL,
        private _getDoctorsList: GetDoctorsListGQL,
        private _onboardPatientGQL: OnboardPatientGQL,
        private _getPatientClinicInfoGQL: GetPatientClinicInfoGQL,
        private _getPatientFormDataGQL: GetUserFormData1GQL,
        private _onboardDoctorGQL: OnboardDoctorGQL,
        private _onboardRegularClinicGQL: OnboardRegularClinicGQL,
        private _onboardFranchiseClinicGQL: OnboardFranchiseClinicGQL,
        private _getFranchiseClinicFormDataGQL: GetFranchiseClinicFormDataGQL,
        private _getFranchiseClinicDoctorsListGQL: GetFranchiseClinicDoctorsListGQL,
        private _getDoctorFormDataGQL: GetDoctorInfoGQL,
        private _getPatientTransferRequestListGQL: GetPatientTransferRequestListGQL,
        private _getPatientTransferRequestListCountGQL: GetPatientTransferRequestListCountGQL,
        private _onboardContractManufacturerGQL: OnboardContractManufacturerGQL,
        private _getAllLensesCountGQL: GetAllLensesCountGQL,
        private _getLensDetailsGQL: GetLensDetailsGQL,
        private _createNewLensGQL: CreateNewLensGQL,
        private _getRoleSpecificUsers: GetRoleSpecificUsersGQL,
        private _deleteUserOrDeleteUserAndReassignGQL: DeleteUserOrDeleteUserAndReassignGQL,
        private _getDistributorsForUserDetailsPageGQL: GetDistributorsForUserDetailsPageGQL,
        private _getFranchiseClinicsForUserDetailsPageGQL: GetFranchiseClinicsForUserDetailsPageGQL,
        private _getRegularClinicsForUserDetailsPageGQL: GetRegularClinicsForUserDetailsPageGQL,
        private _getDoctorsForUserDetailsPageGQL: GetDoctorsForUserDetailsPageGQL,
        private _getPatientsForUserDetailsPageGQL: GetPatientsForUserDetailsPageGQL,
        private _getOrdersForUserDetailsPageGQL: GetOrdersForUserDetailsPageGQL,
        private _getContractManufacturersForUserDetailsPageGQL: GetContractManufacturersForUserDetailsPageGQL,
        private _getTotalCountOfClinicForUserDetailsPageGQL: GetTotalCountOfClinicForUserDetailsPageGQL,
        private _getTotalCountOfContractManufacturerForUserDetailsPageGQL: GetTotalCountOfContractManufacturerForUserDetailsPageGQL,
        private _getTotalCountOfOrdersForUserDetailsPageGQL: GetTotalCountOfOrdersForUserDetailsPageGQL,
        private _getTotalCountOfDoctorForUserDetailsPageGQL: GetTotalCountOfDoctorForUserDetailsPageGQL,
        private _getTotalCountOfPatientForUserDetailsPageGQL: GetTotalCountOfPatientForUserDetailsPageGQL,
        private _getTotalCountOfDistributorsForUserDetailsPageGQL: GetTotalCountOfDistributorsForUserDetailsPageGQL,
        private _getUserFormDataGQL: GetUserFormDataGQL,
        private _saveControlPanelDetailsGQL: SaveControlPanelDetailsGQL,
        private _getControlPanelDetailsGQL: GetControlPanelDetailsGQL,
        private _getControlPanelValuesByOrderIdGQL: GetControlPanelValuesByOrderIdGQL,
        private _updateControlPanelDetailsGQL: UpdateControlPanelDetailsGQL,
        private _getDesignerIdByDoctorIdGQL: GetDesignerIdByDoctorIdGQL,
        private _getControlPanelValuesByDesignerIdGQL: GetControlPanelValuesByDesignerIdGQL,
        private _getDistributorManufacturerMappingsIdGQL: GetDistributorManufacturerMappingsIdGQL,
        private _insertDistributorManufacturerMappingIdToLensMappingTableGQL: InsertContractDistributorIdToMappingsTableGQL,
        private _getDistributorByDoctorAndClinicId: GetDistributorByDoctorAndClinicIdGQL,
        private _checkIfUserEmailExistInUserTable: CheckIfUserEmailExistInUserTableGQL,
        private _getUserByUserId: GetUserByUserIdGQL,
        private _getOrderListForManufacturer: GetOrdersListBasedOnDistributorIdGQL,
        private _getOrderListCountGQL: GetOrdersCountBasedOnDistributorIdGQL,
        private _getListOfOrderForPatientGQL: GetOrdersOfAPatientGQL,
        private _deleteSelectedLensGQL: DeleteSelectedLensGQL,
        private _grantMultipleRolesToUsers: GrantMultipleRolesToUsersGQL,
        private _getGrantedRoleListPerUser: GetGrantedRoleListPerUserGQL,
        private _bulkUpdateOrderStatus: BulkUpdateOrderStatusGQL,
        private _insertOrderDataGQL: InsertOrderStatusTrackingDataGQL,
        private _getOrderDetailsGQL: GetOrderDetailsByIdGQL,
        private _getRoleHierarchyForLoggedInUser: GetRoleHierarchyForLoggedInUserGQL,
        private _getUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionGQL: GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionGQL,
        private _insertToPaymentForTotalCostAndSalesGQL: InsertToPaymentForTotalCostAndSalesGQL,
        private _getListOfUsersBasedOnRoleId: GetListOfUserBasedOnRoleIdGQL,
        private _formDataHandlerService: FormDataHandlerService,
        private _insertInCostTable: InsertInCostTableGQL,
        private _getSuperiorIdOfUserGQL: GetSuperiorIdOfUserGQL,
        private _UpdateBuyerRoleIdToPaymentTableForUpgradedUserGQL: UpdateBuyerRoleIdToPaymentTableForUpgradedUserGQL,
        private _UpdateSellerRoleIdToPaymentTableForUpgradedUserGQL: UpdateSellerRoleIdToPaymentTableForUpgradedUserGQL,
        private _getCountryCodeDetails: GetCountryCodeDetailsGQL,
        private _credentialsService: CredentialsService,
        private _updateUserRoleDetailsGQL: UpdateUserRoleDetailsGQL,
    ) {}

    /**
     * Get designers list from api.
     * @param id
     * @returns
     */
    getDesignersList = (
        payload: GetDesignerListQueryVariables
    ): Observable<ApolloQueryResult<GetDesignerListQuery>> => {
        return this._getDesignerListGQL.watch(payload).valueChanges;
    };

    /**
     * Get user list based on role from api.
     * @param roleObj shares role name and createdBy id.
     * @returns
     */
    getUserListBasedOnRoleGQL = (
        roleObj: GetUserListBasedOnRoleQueryVariables
    ): Observable<ApolloQueryResult<GetUserListBasedOnRoleQuery>> => {
        return this._getUserListBasedOnRoleGQL.watch(roleObj).valueChanges;
    };

    /**
     * Get role specific user list.
     * @param roleObj shares role_id.
     * @returns user list for specific roles.
     */
    getRoleSpecificUserList = (
        roleObj: GetRoleSpecificUsersQueryVariables
    ): Observable<ApolloQueryResult<GetRoleSpecificUsersQuery>> => {
        return this._getRoleSpecificUsers.watch(roleObj).valueChanges;
    };

    /**
     * Get doctor list based on clinic id from api.
     * @param clinicId shares doctors name.
     * @returns
     */
    getDoctorsListGQL = (
        clinicId: GetDoctorsListQueryVariables
    ): Observable<ApolloQueryResult<GetDoctorsListQuery>> => {
        return this._getDoctorsList.watch(clinicId).valueChanges;
    };

    /**
     * Get franchise clinic doctor list based on clinic id from api.
     * @param clinicId shares doctors name of franchise clinic.
     * @returns
     */
    getFranchiseClinicDoctorsListGQL = (
        clinicId: GetFranchiseClinicDoctorsListQueryVariables
    ): Observable<ApolloQueryResult<GetFranchiseClinicDoctorsListQuery>> => {
        return this._getFranchiseClinicDoctorsListGQL.watch(clinicId).valueChanges;
    };

    /**
     * Get requested user details.
     * @param userId shares userId of user against which data to be fetched.
     * @returns
     */
    getPatientFormDetailsGQL = (
        userId: GetUserFormData1QueryVariables
    ): Observable<ApolloQueryResult<GetUserFormData1Query>> => {
        return this._getPatientFormDataGQL.watch(userId).valueChanges;
    };

    /**
     * Get requested user details.
     * @param userId shares userId of user against which data to be fetched.
     * @returns
     */
    getDoctorFormDetailsGQL = (
        userId: GetDoctorInfoQueryVariables
    ): Observable<ApolloQueryResult<GetDoctorInfoQuery>> => {
        return this._getDoctorFormDataGQL.watch(userId).valueChanges;
    };

    /**
     * Call api for onboarding patients.
     * @param payload shares onboarding patient data.
     * @returns Observable with api response.
     */
    onboardPatient = (data: OnboardPatientMutationVariables): Observable<MutationResult<OnboardPatientMutation>> => {
        return this._onboardPatientGQL.mutate(data);
    };

    /**
     * Get user role id based on name.
     * @param userRole shares user role name.
     * @returns response observable with role id.
     */
    getRoleDetails = (userRole: GetRoleDetailsQueryVariables): Observable<ApolloQueryResult<GetRoleDetailsQuery>> => {
        return this._onGetRoleDetailsGQL.watch(userRole).valueChanges;
    };

    /**
     * Get email by userid
     * @param userIdPayload User id of logged in user
     * @returns response observable with email
     */
    getLoggedInUserEmail = (
        userIdPayload: GetUserEmailBasedOnIdQueryVariables
    ): Observable<ApolloQueryResult<GetUserEmailBasedOnIdQuery>> => {
        return this._getEmailOfUser.watch(userIdPayload).valueChanges;
    };

    /**
     * Get requested user details.
     * @param userObj shares userId of user against which data to be fetched.
     * @returns
     */
    getOnboardUserFormDataGQL = (
        userObj: GetOnboardUserFormDataQueryVariables
    ): Observable<ApolloQueryResult<GetOnboardUserFormDataQuery>> => {
        return this._getOnboardUserFormDataGQL.watch(userObj).valueChanges;
    };

    /**
     * Get user details like name, role, phone number etc.
     * @param userIdObj shares userId
     * @returns
     */
    getMoreDetailsOfUser = (
        userIdObj: GetMoreDetailsOfUserQueryVariables
    ): Observable<ApolloQueryResult<GetMoreDetailsOfUserQuery>> => {
        return this._getMoreDetailsOfUser.watch({ userId: userIdObj?.userId, roleId: userIdObj?.roleId }).valueChanges;
    };

    /**
     * Get permissions of user being logged in
     */
    getRolePermissionsOfUser = (
        role_id: GetPermissionsBasedOnRoleQueryVariables
    ): Observable<ApolloQueryResult<GetPermissionsBasedOnRoleQuery>> => {
        return this._getRolePermissionsOfUser.watch(role_id).valueChanges;
    };

    /**
     * update basic user info.
     * @param userDetails shares updated user onboarding form values.
     * @returns
     */
    updateUserDetailsGQL = (
        userDetails: UpdateUserDetailsMutationVariables
    ): Observable<MutationResult<UpdateUserDetailsMutation>> => {
        return this._updateUserDetailsGQL.mutate(userDetails);
    };

    /**
     * Soft delete user.
     * @param deleteUserMutationVariables shares role_mapping_id, deleted_at, deleted_by
     * @returns
     */
    deleteUserMutationGQL = (
        deleteUserMutationVariables: DeleteUserMutationVariables
    ): Observable<MutationResult<DeleteUserMutation>> => {
        return this._deleteUserMutationGQL.mutate(deleteUserMutationVariables);
    };

    /**
     * Get Patient Info details
     * @param payload Patient Id, Clinic Id, Doctor Id
     * @returns Observable
     */
    getPatientInfoForDoctor = (
        payload: GetPatientClinicInfoQueryVariables
    ): Observable<ApolloQueryResult<GetPatientClinicInfoQuery>> => {
        return this._getPatientClinicInfoGQL.watch(payload).valueChanges;
    };

    /**
     * Call api for onboarding doctors.
     * @param payload shares onboarding doctor data.
     * @returns Observable with api response.
     */
    onboardDoctor = (data: OnboardDoctorMutationVariables): Observable<MutationResult<OnboardDoctorMutation>> => {
        return this._onboardDoctorGQL.mutate(data);
    };

    /**
     * Call api for onboarding regular clinics.
     * @param payload shares onboarding regular clinics data.
     * @returns Observable with api response.
     */
    onboardRegularClinic = (
        data: OnboardRegularClinicMutationVariables
    ): Observable<MutationResult<OnboardRegularClinicMutation>> => {
        return this._onboardRegularClinicGQL.mutate(data);
    };

    /**
     * Get requested user details.
     * @param userId shares userId of user against which data to be fetched.
     * @returns
     */
    onboardFranchiseClinic = (
        data: OnboardFranchiseClinicMutationVariables
    ): Observable<MutationResult<OnboardFranchiseClinicMutation>> => {
        return this._onboardFranchiseClinicGQL.mutate(data);
    };

    /**
     * Get requested user details.
     * @param userId shares userId of user against which data to be fetched.
     * @returns
     */
    getFranchiseClinicFormDetails = (
        userId: GetFranchiseClinicFormDataQueryVariables
    ): Observable<ApolloQueryResult<GetFranchiseClinicFormDataQuery>> => {
        return this._getFranchiseClinicFormDataGQL.watch(userId).valueChanges;
    };

    /**
     * For cost data updation the fields names are different
     * Modify the payload data to match the needed type
     */
    prepareCostDataForEdit = (costData: PriceSettingFields): Cost_Set_Input => {
        const modifiedData: Cost_Set_Input = {
            new_spherical: costData?.newSpherical,
            new_toric: costData?.newToric,
            amend_spherical: costData?.amendSpherical,
            amend_toric: costData?.amendToric,
            remake_spherical: costData?.remakeSpherical,
            remake_toric: costData?.remakeToric,
        };
        return modifiedData;
    };

    /**
     * Fetches a list of patient transfer requests based on the provided criteria.
     * @param {GetPatientTransferRequestListQueryVariables} payload - The criteria used to filter and fetch the patient transfer request list.
     * @returns {Observable<ApolloQueryResult<GetPatientTransferRequestListQuery>>} An Observable that emits the result of the GraphQL query.
     */
    getPatientTransferRequestList = (
        payload: GetPatientTransferRequestListQueryVariables
    ): Observable<ApolloQueryResult<GetPatientTransferRequestListQuery>> => {
        return this._getPatientTransferRequestListGQL.watch(payload).valueChanges;
    };

    /**
     * Fetches the count of patient transfer requests based on the provided criteria.
     * @param {GetPatientTransferRequestListCountQueryVariables} payload - The criteria used to filter and count the patient transfer requests.
     * @returns {Observable<ApolloQueryResult<GetPatientTransferRequestListCountQuery>>} An Observable that emits the result of the GraphQL query.
     */
    getPatientTransferRequestListCount = (
        payload: GetPatientTransferRequestListCountQueryVariables
    ): Observable<ApolloQueryResult<GetPatientTransferRequestListCountQuery>> => {
        return this._getPatientTransferRequestListCountGQL.watch(payload).valueChanges;
    };

    onboardContractManufacturer = (
        data: OnboardContractManufacturerMutationVariables
    ): Observable<MutationResult<OnboardContractManufacturerMutation>> => {
        return this._onboardContractManufacturerGQL.mutate(data);
    };

    saveControlPanelDetails = (
        data: SaveControlPanelDetailsMutationVariables
    ): Observable<MutationResult<SaveControlPanelDetailsMutation>> => {
        return this._saveControlPanelDetailsGQL.mutate(data);
    };

    updateControlPanelDetails = (
        data: UpdateControlPanelDetailsMutationVariables
    ): Observable<MutationResult<UpdateControlPanelDetailsMutation>> => {
        return this._updateControlPanelDetailsGQL.mutate(data);
    };

    getControlPanelDetails = (
        payload: GetControlPanelDetailsQueryVariables
    ): Observable<ApolloQueryResult<GetControlPanelDetailsQuery>> => {
        return this._getControlPanelDetailsGQL.watch(payload).valueChanges;
    };

    getControlPanelDetailsByOrderId = (
        payload: GetControlPanelValuesByOrderIdQueryVariables
    ): Observable<ApolloQueryResult<GetControlPanelValuesByOrderIdQuery>> => {
        return this._getControlPanelValuesByOrderIdGQL.watch(payload).valueChanges;
    };

    getLensesListCount = (
        params: GetAllLensesCountQueryVariables
    ): Observable<ApolloQueryResult<GetAllLensesCountQuery>> => {
        return this._getAllLensesCountGQL.watch(params).valueChanges;
    };

    getLensesList = (params: GetLensDetailsQueryVariables): Observable<ApolloQueryResult<GetLensDetailsQuery>> => {
        return this._getLensDetailsGQL.watch(params).valueChanges;
    };

    onboardNewLens = (data: CreateNewLensMutationVariables): Observable<MutationResult<CreateNewLensMutation>> => {
        return this._createNewLensGQL.mutate(data);
    };

    /**
     * Service that calls mutation to delete lens by id
     */
    deleteLensById = (data: DeleteSelectedLensMutationVariables): Observable<MutationResult<CreateNewLensMutation>> => {
        return this._deleteSelectedLensGQL.mutate(data);
    };

    updateUserRoleDetails = (data: UpdateUserRoleDetailsMutationVariables): Observable<MutationResult<UpdateUserRoleDetailsMutation>> => {
      return this._updateUserRoleDetailsGQL.mutate(data);
    }

    getDistributorsForDetailsPage = (
        data: GetDistributorsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetDistributorsForUserDetailsPageQuery>> => {
        return this._getDistributorsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getContractManufacturersForDetailsPage = (
        data: GetContractManufacturersForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetContractManufacturersForUserDetailsPageQuery>> => {
        return this._getContractManufacturersForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getListOfFranchiseClinicsForDetailsPage = (
        data: GetFranchiseClinicsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetFranchiseClinicsForUserDetailsPageQuery>> => {
        return this._getFranchiseClinicsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getListOfRegularClinicsForDetailsPage = (
        data: GetRegularClinicsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetRegularClinicsForUserDetailsPageQuery>> => {
        return this._getRegularClinicsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getDoctorsListForDetailsPage = (
        data: GetDoctorsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetDoctorsForUserDetailsPageQuery>> => {
        return this._getDoctorsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getPatientListForDetailsPage = (
        data: GetPatientsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetPatientsForUserDetailsPageQuery>> => {
        return this._getPatientsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getOrdersForDetailsPage = (
        data: GetOrdersForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetOrdersForUserDetailsPageQuery>> => {
        return this._getOrdersForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfClinicForUserDetailsPage = (
        data: GetTotalCountOfClinicForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfClinicForUserDetailsPageQuery>> => {
        return this._getTotalCountOfClinicForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfContractManufacturerForUserDetailsPage = (
        data: GetTotalCountOfContractManufacturerForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfContractManufacturerForUserDetailsPageQuery>> => {
        return this._getTotalCountOfContractManufacturerForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfOrdersForUserDetailsPage = (
        data: GetTotalCountOfOrdersForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfOrdersForUserDetailsPageQuery>> => {
        return this._getTotalCountOfOrdersForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfDoctorForUserDetailsPage = (
        data: GetTotalCountOfDoctorForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfDoctorForUserDetailsPageQuery>> => {
        return this._getTotalCountOfDoctorForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfPatientForUserDetailsPage = (
        data: GetTotalCountOfPatientForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfPatientForUserDetailsPageQuery>> => {
        return this._getTotalCountOfPatientForUserDetailsPageGQL.watch(data).valueChanges;
    };

    getTotalCountOfDistributorsForUserDetailsPage = (
        data: GetTotalCountOfDistributorsForUserDetailsPageQueryVariables
    ): Observable<ApolloQueryResult<GetTotalCountOfDistributorsForUserDetailsPageQuery>> => {
        return this._getTotalCountOfDistributorsForUserDetailsPageGQL.watch(data).valueChanges;
    };

    /**
     * Delete user or delete user and reassign.
     * @param data details of user to and assignee if any.
     * @returns message - success/failure.
     */
    deleteUserOrDeleteUserAndReassign = (
        params: DeleteUserOrDeleteUserAndReassignMutationVariables
    ): Observable<MutationResult<DeleteUserOrDeleteUserAndReassignMutation>> => {
        return this._deleteUserOrDeleteUserAndReassignGQL.mutate(params);
    };

    /**
     * Get requested user details.
     * @param userId shares userId of user against which data to be fetched.
     * @returns
     */
    getUserFormDetailsGQL = (
        userId: GetUserFormDataQueryVariables
    ): Observable<ApolloQueryResult<GetUserFormDataQuery>> => {
        return this._getUserFormDataGQL.watch(userId).valueChanges;
    };

    /**
     * Get designer id based on doctor id.
     * @param doctorId shares doctor id.
     * @returns designer id.
     */
    getDesignerIdBasedOnDoctorId = (
        doctorId: GetDesignerIdByDoctorIdQueryVariables
    ): Observable<ApolloQueryResult<GetDesignerIdByDoctorIdQuery>> => {
        return this._getDesignerIdByDoctorIdGQL.watch(doctorId).valueChanges;
    };

    getControlPanelValuesByDesignerId = (
        designerId: GetControlPanelValuesByDesignerIdQueryVariables
    ): Observable<ApolloQueryResult<GetControlPanelValuesByDesignerIdQuery>> => {
        return this._getControlPanelValuesByDesignerIdGQL.watch(designerId).valueChanges;
    };

    /**
     * Service to get distributor contract manufacturer mapping id
     * @param params manufacturer id to fetch mapping id
     * @returns
     */
    getDistributorManufacturerMappingsId = (
        params: GetDistributorManufacturerMappingsIdQueryVariables
    ): Observable<ApolloQueryResult<GetDistributorManufacturerMappingsIdQuery>> => {
        return this._getDistributorManufacturerMappingsIdGQL.watch(params).valueChanges;
    };

    /**
     * Service to insert distributor and contract manufacturer mapping id to lens mapping table
     * @param params distributor and contract manufacturer mapping id to be inserted
     * @returns
     */
    insertDistributorManufacturerMappingsIdToLensMappingTable = (
        params: InsertContractDistributorIdToMappingsTableMutationVariables
    ): Observable<MutationResult<InsertContractDistributorIdToMappingsTableMutation>> => {
        return this._insertDistributorManufacturerMappingIdToLensMappingTableGQL.mutate(params);
    };

    /**
     * Get distributor of the clinic.
     * @param params shares doctorId and clinicId
     * @returns distributor id.
     */
    getDistributorByDoctorAndClinicId = (
        params: GetDistributorByDoctorAndClinicIdQueryVariables
    ): Observable<ApolloQueryResult<GetDistributorByDoctorAndClinicIdQuery>> => {
        return this._getDistributorByDoctorAndClinicId.watch(params).valueChanges;
    };

    /**
     * Check if user email exist in user table.
     * @param checkIfUserEmailExistInUserTableParam shares email.
     * @returns user id against email.
     */
    checkIfUserEmailExistInUserTable = (
        checkIfUserEmailExistInUserTableParam: CheckIfUserEmailExistInUserTableQueryVariables
    ): Observable<ApolloQueryResult<CheckIfUserEmailExistInUserTableQuery>> => {
        return this._checkIfUserEmailExistInUserTable.watch(checkIfUserEmailExistInUserTableParam).valueChanges;
    };

    /**
     * Service to get total list of orders for table - contract manufacturer login
     */
    getOrderListForManufacturer = (
        params: GetOrdersListBasedOnDistributorIdQueryVariables
    ): Observable<ApolloQueryResult<GetOrdersListBasedOnDistributorIdQuery>> => {
        return this._getOrderListForManufacturer.watch(params).valueChanges;
    };

    /**
     * Service to get total count of orders in table for pagination - contract manufacturer login
     */
    getOrdersListCountForManufacturer = (
        params: GetOrdersCountBasedOnDistributorIdQueryVariables
    ): Observable<ApolloQueryResult<GetOrdersCountBasedOnDistributorIdQuery>> => {
        return this._getOrderListCountGQL.watch(params).valueChanges;
    };

    /**
     * Check if email exist.
     * @param control pass the email control
     * @returns validation error or null based on the user details available.
     */
    validateUserEmail(
        control: AbstractControl,
        initialData: { id: string; email: string }
    ): Promise<ValidationErrors | null> {
        if (!control.dirty) return Promise.resolve(null);
        const emailId = initialData?.email?.toLocaleLowerCase();
        initialData.email = emailId;
        const emailCheck$ = this.checkIfUserEmailExistInUserTable({ email: control?.value?.toLocaleLowerCase() }).pipe(
            map((result) => {
                const userEmail = result?.data?.user?.[0]?.email;
                const userId = result?.data?.user?.[0]?.id;
                const roles = result?.data?.user?.[0]?.user_role_mappings;
                const hasPatientRole = roles?.find((role) => role?.role?.name === Roles.PATIENT && role?.is_active);
                if (userId && userEmail) {
                    if (userEmail === initialData?.email && userId === initialData?.id) {
                        return null;
                    }
                    if (hasPatientRole){
                      const careOfPatient: CareOfPatient = {
                        user_id: hasPatientRole?.user_id,
                        email: userEmail,
                        name: hasPatientRole?.name || ''
                      }
                      this._store.dispatch(updateCareOfPatient(careOfPatient));
                      return { isPatient: true }
                    }
                    if (userEmail !== initialData?.email && userId !== initialData?.id) {
                        return { emailTaken: true };
                    }
                }
                this._store.dispatch(updateCareOfPatient(null));
                return null;
            }),
            catchError(() => of(null))
        );

        return firstValueFrom(emailCheck$);
    }

    getSavedFilterByValues(filterByParam: keyof typeof filterValues): string[] {
        return filterValues[filterByParam] || [];
    }

    getUserByUserId = (getUserByUserIdParam: GetUserByUserIdQueryVariables): Observable<GetUserByUserIdQuery> => {
        return this._getUserByUserId.watch(getUserByUserIdParam).valueChanges.pipe(map((result) => result.data));
    };

    /**
     * Fetch list of order for patient details page when doctor is viewing it
     */
    fetchListOfOrdersOfPatient = (
        ordersPayload: GetOrdersOfAPatientQueryVariables
    ): Observable<ApolloQueryResult<GetOrdersOfAPatientQuery>> => {
        return this._getListOfOrderForPatientGQL.watch(ordersPayload).valueChanges;
    };

    /**
     * Grant multiple roles to users.
     */
    grantMultipleRolesToUsers = (
        grantMultipleRolesToUsers: GrantMultipleRolesToUsersMutationVariables
    ): Observable<MutationResult<GrantMultipleRolesToUsersMutation>> => {
        return this._grantMultipleRolesToUsers.mutate(grantMultipleRolesToUsers);
    };

    /**
     * Get all roles assigned to the current user.
     * @param getGrantedRoleListPerUserParams shares userId
     * @returns
     */
    getGrantedRoleListPerUser = (
        getGrantedRoleListPerUserParams: GetGrantedRoleListPerUserQueryVariables
    ): Observable<ApolloQueryResult<GetGrantedRoleListPerUserQuery>> => {
        return this._getGrantedRoleListPerUser.watch(getGrantedRoleListPerUserParams).valueChanges;
    };

    /**
     *
     * Bulk update order status.
     * @param bulkUpdateOrderStatusParams shares order id array.
     * @returns observable with mutation status.
     */
    bulkUpdateOrderStatus = (
        bulkUpdateOrderStatusParams: BulkUpdateOrderStatusMutationVariables
    ): Observable<MutationResult<BulkUpdateOrderStatusMutation>> => {
        return this._bulkUpdateOrderStatus.mutate(bulkUpdateOrderStatusParams);
    };

    /**
     * Service to insert order data to tracking(order_status_info) table
     */
    insertOrderDataToTrackingTable = (
        orderTrackingData: InsertOrderStatusTrackingDataMutationVariables
    ): Observable<MutationResult<InsertOrderStatusTrackingDataMutation>> => {
        return this._insertOrderDataGQL.mutate(orderTrackingData);
    };

    /**
     * Service to fetch order status details by order id from tracking(order_status_info) table
     */
    getOrderDetailsByOrderId = (
        orderIdParams: GetOrderDetailsByIdQueryVariables
    ): Observable<ApolloQueryResult<GetOrderDetailsByIdQuery>> => {
        return this._getOrderDetailsGQL.watch(orderIdParams).valueChanges;
    };

    getRoleHierarchyForLoggedInUser = (
        loggedInUserDetails: GetRoleHierarchyForLoggedInUserQueryVariables
    ): Observable<ApolloQueryResult<GetRoleHierarchyForLoggedInUserQuery>> => {
        return this._getRoleHierarchyForLoggedInUser.watch(loggedInUserDetails).valueChanges;
    };

    /**
     * Fetch userIds with respect to doctor and client id, price settings of each user
     */
    getUserIdsAndPriceSettingsData = (
        payload: GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQueryVariables
    ): Observable<ApolloQueryResult<GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery>> => {
        return this._getUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionGQL.watch(payload).valueChanges;
    };

    /**
     * Based on order submission to find cost and sales for a user, entried has to be
     * inserted to payment table for each user in the heirarchy except manufacturer and patient
     */
    insertToPaymentTableForAnOrder = (
        paymentPayload: InsertToPaymentForTotalCostAndSalesMutationVariables
    ): Observable<MutationResult<InsertToPaymentForTotalCostAndSalesMutation>> => {
        return this._insertToPaymentForTotalCostAndSalesGQL.mutate(paymentPayload);
    };

    /**
     * Get list of all active user based on role id
     * @returns user list for specific roles
     */
    getListOfUserByRoleId = (
        roleObj: GetListOfUserBasedOnRoleIdQueryVariables
    ): Observable<ApolloQueryResult<GetListOfUserBasedOnRoleIdQuery>> => {
        return this._getListOfUsersBasedOnRoleId.watch(roleObj).valueChanges;
    };

    /**
     * Create entry in payment table
     * @param param user role mapping id and created by id.
     * @returns
     */
    insertInCostTable = (
        param: InsertInCostTableMutationVariables
    ): Observable<MutationResult<InsertInCostTableMutation>> => {
        return this._insertInCostTable.mutate(param);
    };

    /**
     * Get name and id of users.
     * @param query from the role specific user query.
     * @returns name and id array of the users from user table.
     */
    filterRoleSpecifUsers(
        query: GetRoleSpecificUsersQuery,
        input: GetRoleSpecificUsersQueryVariables
    ): { name: string; id: string }[] {
        let result: Array<{ id: string; name: string; email: string }> = [];

        if (input?.isDesigner) {
            query?.designerList?.forEach((item) => {
                if (item?.user) {
                    result.push({ id: item?.user?.id, name: item?.name || '', email: item?.user?.email || '' });
                }
            });
        }

        if (input?.isDoctor) {
            query?.doctorList?.forEach((item) => {
                result.push({
                    id: item?.userByDoctorId?.user_role_mappings?.[0]?.user?.id,
                    name: item?.userByDoctorId?.user_role_mappings?.[0]?.name || '',
                    email: item?.userByDoctorId?.user_role_mappings?.[0]?.user?.email || '',
                });
            });
        }

        if (input?.isDistributor) {
            query?.distributorList?.forEach((item) => {
                result.push({
                    id: item?.userByDistributorId?.id,
                    name: item?.userByDistributorId?.user_role_mappings?.[0]?.name || '',
                    email: item?.userByDistributorId?.email || '',
                });
            });
        }

        if (input?.isClinic) {
            query?.clinicList?.forEach((item) => {
                result.push({
                    id: item?.user?.id,
                    name: item?.user?.user_role_mappings?.[0]?.name || '',
                    email: item?.user?.email || '',
                });
            });
        }

        if (input?.isManufacturer) {
            query?.manufacturerList?.forEach((item) => {
                result?.push({ id: item?.user?.id, name: item?.name || '', email: item?.user?.email || '' });
            });
        }

        return result;
    }

    transformClinicForUserDetailsPage(
        query: GetRegularClinicsForUserDetailsPageQuery | GetFranchiseClinicsForUserDetailsPageQuery,
        role: string
    ): ClinicForUserDetailsPage[] {
        let data;
        data = query.distributor;
        if (!data) return [];

        const clinics: ClinicForUserDetailsPage[] = data?.flatMap((item) => {
            let user;
            user = item?.user;
            if (user) {
                const clinicForUser: ClinicForUserDetailsPage = {
                    name: user?.user_role_mappings?.[0]?.name || '',
                    contactNumber: user?.contact_number || '',
                    clinicType: role,
                    registerNumber:
                        role === 'Franchise'
                            ? user?.user_role_mappings?.[0]?.role_details || '--'
                            : user?.user_role_mappings?.[0]?.role_details?.registration_number || '--',
                };
                return [clinicForUser];
            }

            return [];
        });
        return clinics;
    }

    /**
     * Transform the data into the required format for exclusive and selective distributors
     */
    transformDistributorForUserDetailsPage = async (
        query: GetDistributorsForUserDetailsPageQuery
    ): Promise<DistributorForUserDetailsPage[]> => {
        const data = query.designer;
        if (!data) return [];

        let distributorObjects: DistributorForUserDetailsPage[] = [];
        const resultingIds = query.designer.map((obj) => obj.distributor_id);
        const payload: GetDistributorsDetailsByIdsUnderDesignerQueryVariables = {
            distributorIds: resultingIds,
        };
        const distributorData: GetDistributorsDetailsByIdsUnderDesignerQuery =
            await this._formDataHandlerService.fetchDistributorDetailsForAdditionalDetails(payload);

        distributorData?.user?.map((item) => {
            item.user_role_mappings.forEach((userRole) => {
                const role = userRole?.role?.name;
                const roleName =
                    role === Roles.EXCLUSIVE_DISTRIBUTOR ? 'Exclusive Distributor' : 'Selective Distributor';
                const exDistributorDetails: DistributorForUserDetailsPage = {
                    name: userRole?.name || '',
                    contactNumber: item?.contact_number || '',
                    distributorLicenseNumber: userRole?.role_details?.distributor_licence_number || '',
                    distributorType: roleName || '',
                };
                distributorObjects.push(exDistributorDetails);
            });
        });
        return distributorObjects;
    };

    transformDoctorsForUserDetailsPage(
        query: GetDoctorsForUserDetailsPageQuery,
        isRegularClinic: boolean
    ): DoctorForUserDetailsPage[] {
        if (isRegularClinic) {
            // Only transform regular clinic doctors
            return query?.regularClinic?.map((item) => {
                const user = item?.userByDoctorId;

                return {
                    name: user?.user_role_mappings?.[0]?.name || '',
                    contactNumber: user?.contact_number || '',
                };
            });
        } else {
            // Only transform franchise clinic doctors
            return query?.franchiseClinic?.map((item) => {
                const user = item?.userByDoctorId;

                return {
                    name: user?.user_role_mappings?.[0]?.name || '',
                    contactNumber: user?.contact_number || '',
                };
            });
        }
    }

    transformPatientsForUserDetailsPage = (
        query: GetPatientsForUserDetailsPageQuery,
        isClinic: boolean
    ): PatientForUserDetailsPage[] => {
        if (isClinic) {
            // Only transform patients from clinic mapping
            return query.clinic
                .map((item) => {
                    const user = item?.userByPatientId?.user_role_mappings?.[0]?.user || null;

                    if (!user) return null;
                    return {
                        name: item?.userByPatientId?.user_role_mappings?.[0]?.name,
                        contactNumber: user?.contact_number || '',
                        referenceNumber: item?.userByPatientId?.user_role_mappings?.[0]?.reference_number || '',
                    };
                })
                .filter((item) => item !== null) as PatientForUserDetailsPage[];
        } else {
            // Only transform patients from doctor mapping
            return query?.doctor
                ?.map((item) => {
                    const user = item?.userByPatientId?.user_role_mappings?.[0]?.user || null;

                    if (!user) return null;

                    return {
                        name: item?.userByPatientId?.user_role_mappings?.[0]?.name,
                        contactNumber: user?.contact_number || '',
                        referenceNumber: item?.userByPatientId?.user_role_mappings?.[0]?.reference_number || '',
                    };
                })
                .filter((item) => item !== null) as PatientForUserDetailsPage[];
        }
    };

    transformOrdersForUserDetailsPage = (
        data: GetOrdersForUserDetailsPageQuery,
        isDoctor: boolean,
        isClinic: boolean,
        isDistributor: boolean
    ): OrderForUserDetailsPage[] => {
        let orders;
        if (isDoctor) {
            orders = data?.doctor;
        } else if (isClinic) {
            orders = data?.clinic;
        } else if (isDistributor) {
            orders = data?.distributor;
        } else {
            orders = data?.manufacturer;
        }

        if (!orders) {
            return [];
        }

        return orders?.map((order) => ({
            orderId: order?.order_id || '',
            patientName: order?.userDetails?.user_role_mappings?.[0]?.name ?? '',
            date: order?.created_at,
            status: order?.order_status,
        }));
    };

    transformContractManufacturerForUserDetailsPage(
        result: GetContractManufacturersForUserDetailsPageQuery
    ): ContractManufacturerForUserDetailsPage[] {
        if (!result.distributor) {
            throw new Error('No data found');
        }
        return result.distributor.map((manufacturerMapping) => {
            const { userByManufacturerId } = manufacturerMapping;
            if (!userByManufacturerId) {
                throw new Error('Invalid data structure');
            }

            const { user_role_mappings } = userByManufacturerId;

            if (!user_role_mappings || !user_role_mappings?.[0]?.role_details) {
                throw new Error('Role details not found');
            }

            const licenseNumber =
                user_role_mappings?.[0]?.role_details?.manufacturingLicenceNumber ||
                user_role_mappings?.[0]?.role_details?.manufacturing_licence_no;

            return {
                name: user_role_mappings?.[0]?.name ?? '',
                contactNumber: user_role_mappings?.[0]?.user?.contact_number || '',
                licenseNumber: licenseNumber || '',
            };
        });
    }
    onSwitchRoles = () => {
        this.userRoleUpdateSubject$.next(true);
    };

    getSwitchUserRoleAsObs = () => {
        return this.userRoleUpdateSubject$.asObservable();
    };

    /**
     * Remove typename and id of the existing order.
     * Required to submit remake order.
     * @param orderIn order details
     * @returns order details
     */
    extractOrderDetails(orderIn: GetDetailsOfOrderByIdQuery): Array<OrderDetails> {
        const ordersWithTypeName = orderIn.order;
        // map over the orders and delete __typename key from each one
        const orders = ordersWithTypeName.map((order) => {
            const { __typename, id, ...orderWithoutTypeName } = order;
            return orderWithoutTypeName as OrderDetails;
        });
        return orders;
    }

    /**
     * Convert {year: number, month: number, day: number} to  ISO 8601 date-time format.
     * @param dateObj {year: number, month: number, day: number}
     * @param isTodayTheEndDate shares boolean based on selected end date is today or not.
     * @returns void
     */
    convertToTimestampz = (dateObj: { year: number; month: number; day: number }, isEndDate = false): string => {
        let year = String(dateObj.year);
        let month = String(dateObj.month).padStart(2, '0');
        let day = String(dateObj.day).padStart(2, '0');

        // If it's an end date, adjust to the end of the day in local timezone
        if (isEndDate) {
            const adjustedDate = new Date(Date.UTC(dateObj.year, dateObj.month - 1, dateObj.day, 23, 59, 59));
            year = String(adjustedDate.getUTCFullYear());
            month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0');
            day = String(adjustedDate.getUTCDate()).padStart(2, '0');
            const hours = String(adjustedDate.getUTCHours()).padStart(2, '0');
            const minutes = String(adjustedDate.getUTCMinutes()).padStart(2, '0');
            const seconds = String(adjustedDate.getUTCSeconds()).padStart(2, '0');

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        }

        return `${year}-${month}-${day}T00:00:00Z`;
    };

    // Convert iso to { year: number; month: number; day: number } format.
    convertIsoToCustomDateObj = (isoString: string): CustomDate => {
        const date = new Date(isoString);
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
        };
    };

    transformGrantedRoles = (grantedRoles: GetGrantedRoleListPerUserQuery): UserRole[] => {
        return grantedRoles?.user_role_mapping
            .filter((mapping) => mapping?.role !== null)
            .map((mapping) => ({
                id: mapping?.role!.id,
                name: mapping?.role!.name,
            }));
    };

    transformRoleHierarchyForLoggedInUser = (
        data: GetRoleHierarchyForLoggedInUserQuery
    ): RoleHierarchyForLoggedInUser => {
        let superAdminId: string | null = null;
        let designerId: string | null = null;
        let distributorId: string | null = null;
        let clinicId: string | null = null;
        let doctorId: string | null = null;
        let designerRoleId: string | null = null;
        let distributorRoleId: string | null = null;

        // Extract doctor data
        if (data?.doctorData && data?.doctorData.length > 0) {
            clinicId = data?.doctorData?.[0].user?.id;
            const distributorData = data.doctorData?.[0]?.user?.clinic_distributors_by_clinic_id?.[0]?.distributor;
            if (distributorData) {
                distributorId = distributorData?.id;
                designerId = distributorData?.designerDistributorMappingsByDistributorId?.[0]?.designer_id;
            }
        }

        // Extract clinic data
        if (data?.clinicData && data?.clinicData?.length > 0) {
            const distributorData = data?.clinicData?.[0]?.distributor;
            if (distributorData) {
                distributorId = distributorData?.id;
                designerId = distributorData?.designerDistributorMappingsByDistributorId?.[0]?.designer_id;
                distributorRoleId = distributorData?.user_role_mappings?.[0]?.role?.id;
            }
        }

        // Extract distributor data
        if (data?.distributorData && data?.distributorData?.length > 0) {
            designerId = data?.distributorData?.[0]?.designer_id;
            designerRoleId = data?.distributorData?.[0]?.userByDesignerId?.user_role_mappings?.[0]?.role?.id;
        }

        if (data?.superAdminData && data?.superAdminData?.length > 0) {
            superAdminId = data?.superAdminData?.[0]?.user?.id;
        }

        return {
            superAdminId,
            designerId,
            distributorId,
            clinicId,
            doctorId,
            distributorRoleId,
            designerRoleId,
        };
    };

    transformSuperiorIdOfUser = (query: GetSuperiorIdOfUserQuery, input: GetSuperiorIdOfUserQueryVariables): string => {
        if (input.isClinic) {
            // Assuming there's only one entry for each clinic
            const clinicData = query.clinicData[0];
            if (clinicData) {
                return clinicData.distributor_id;
            }
        }

        if (input.isDistributor) {
            // Assuming there's only one entry for each distributor
            const distributorData = query.distributorData[0];
            if (distributorData) {
                return distributorData.designer_id;
            }
        }

        return '';
    };

    getSuperiorIdOfUser = (
        param: GetSuperiorIdOfUserQueryVariables
    ): Observable<ApolloQueryResult<GetSuperiorIdOfUserQuery>> => {
        return this._getSuperiorIdOfUserGQL.watch(param).valueChanges;
    };
    /**
     * Update buyer role id to payment table when a regular_clinic / selective_distributor is upgraded
     */
    updateBuyerRoleIdsToUpgradedUser = (
        upgradedBuyerUserIdAndRoleIdParams: UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutationVariables
    ): Observable<MutationResult<UpdateBuyerRoleIdToPaymentTableForUpgradedUserMutation>> => {
        return this._UpdateBuyerRoleIdToPaymentTableForUpgradedUserGQL.mutate(upgradedBuyerUserIdAndRoleIdParams);
    };

    /**
     * Update seller role id to payment table when a regular_clinic / selective_distributor is upgraded
     */
    updateSellerRoleIdsToUpgradedUser = (
        upgradedSellerUserIdAndRoleIdParams: UpdateSellerRoleIdToPaymentTableForUpgradedUserMutationVariables
    ): Observable<MutationResult<UpdateSellerRoleIdToPaymentTableForUpgradedUserMutation>> => {
        return this._UpdateSellerRoleIdToPaymentTableForUpgradedUserGQL.mutate(upgradedSellerUserIdAndRoleIdParams);
    };

    getCountryCodeDetails = (): Observable<ApolloQueryResult<GetCountryCodeDetailsQuery>> => {
        return this._getCountryCodeDetails.watch().valueChanges;
    };

    transformCountryCodeDetails = (query: GetCountryCodeDetailsQuery): CountryCodeDetails[] => {
        const list = query?.countryCodeDetails?.map((value) => {
            return {
                countryCode: value?.country_code,
                flag: value?.flag,
                name: value?.name,
            };
        });
        return list;
    };

    sendUpdateOrderStatus = (value: Order_Status_Enum | string): void => {
        this.updateOrderSubject$.next(value);
    };

    updateOrderStatusAsObs = (): Observable<Order_Status_Enum | string> => {
        return this.updateOrderSubject$.asObservable();
    };

    prepareUserListBasedOnRoleQueryVariables = (getRole: string): GetUserListBasedOnRoleQueryVariables => {
      const role = this._credentialsService?.credentials?.role || '';
      const userId = this._credentialsService?.credentials?.userId || '';
        let params: GetUserListBasedOnRoleQueryVariables = {
            name: getRole,
            userId: userId,
            getDistributorsUnderDesigner: false,
            getFranchiseClinicsUnderDesigner: false,
            getFranchiseClinicsUnderDistributor: false,
            getRegularClinicsUnderDesigner: false,
            getRegularClinicsUnderDistributor: false,
            getUsersUnderSuperAdmin: false,
        };
        switch (role) {
            case Roles.SUPER_ADMIN:
                params.getUsersUnderSuperAdmin = true;
                break;
            case Roles.DESIGNER:
                if (getRole === Roles.FRANCHISE_CLINIC) {
                    params.getFranchiseClinicsUnderDesigner = true;
                }
                if (getRole === Roles.REGULAR_CLINIC) {
                    params.getRegularClinicsUnderDesigner = true;
                }
                if (getRole === Roles.EXCLUSIVE_DISTRIBUTOR || getRole === Roles.SELECTIVE_DISTRIBUTOR) {
                    params.getDistributorsUnderDesigner = true;
                }
                break;
            case Roles.EXCLUSIVE_DISTRIBUTOR:
                if (getRole === Roles.FRANCHISE_CLINIC) {
                    params.getFranchiseClinicsUnderDistributor = true;
                }
                if (getRole === Roles.REGULAR_CLINIC) {
                    params.getRegularClinicsUnderDistributor = true;
                }
                break;
            case Roles.SELECTIVE_DISTRIBUTOR:
                if (getRole === Roles.REGULAR_CLINIC) {
                    params.getRegularClinicsUnderDistributor = true;
                }
                break;
        }

        return params;
    };

    /**
     * Used mainly to transform data to render user list dropdowns while onboarding or editing users.
     * @param query shares the user list below specific user with specific role.
     * @param getRole shares which user list is required.
     * @returns user array.
     */
    transformUserListBasedOnRole = (query: GetUserListBasedOnRoleQuery, getRole: string): User[] | null => {
      const role = this._credentialsService?.credentials?.role || '';
        let userList: User[] | null = [];
        type UserIn = {
          __typename?: 'user_role_mapping' | undefined;
          name?: string | null | undefined;
          user?:
              | {
                    __typename?: 'user' | undefined;
                    id: any;
                }
              | null
              | undefined;
        };

        const getUser = (user: UserIn): User => {
            return {
                id: user?.user?.id,
                name: user?.name || '',
            };
        };
          switch (getRole) {
            case Roles.EXCLUSIVE_DISTRIBUTOR:
                if (role === Roles.DESIGNER) {
                    userList =
                        query?.exclusive_distributor_under_designer?.flatMap((data1) =>
                            data1?.userByDistributorId?.user_role_mappings?.map((data) => getUser(data))
                        ) ?? null;
                } else if (role === Roles.SUPER_ADMIN) {
                    userList = query?.user_role_mapping?.map((data) => getUser(data)) ?? null;
                }
                break;
            case Roles.SELECTIVE_DISTRIBUTOR:
              if (role === Roles.DESIGNER) {
                userList =
                    query?.selective_distributor_under_designer?.flatMap((data1) =>
                        data1?.userByDistributorId?.user_role_mappings?.map((data) => getUser(data))
                    ) ?? null;
              } else if (role === Roles.SUPER_ADMIN) {
                userList = query?.user_role_mapping?.map((data) => getUser(data)) ?? null;
              }
              break;
            case Roles.FRANCHISE_CLINIC:
                if (role === Roles.DESIGNER) {
                    userList =
                        query?.franchise_clinic_under_designer?.flatMap((data1) =>
                            data1?.user?.user_role_mappings?.map((data) => getUser(data))
                        ) ?? null;
                } else if (role === Roles.EXCLUSIVE_DISTRIBUTOR || role === Roles.SELECTIVE_DISTRIBUTOR) {
                    userList =
                        query?.franchise_clinic_under_distributor?.flatMap((data1) =>
                            data1?.user?.user_role_mappings?.map((data) => getUser(data))
                        ) ?? null;
                } else if (role === Roles.SUPER_ADMIN) {
                    userList = query?.user_role_mapping?.map((data) => getUser(data)) ?? null;
                }
                break;
            case Roles.REGULAR_CLINIC:
                if (role === Roles.DESIGNER) {
                    userList =
                        query?.regular_clinic_under_designer?.flatMap((data1) =>
                            data1?.user?.user_role_mappings?.map((data) => getUser(data))
                        ) ?? null;
                } else if (role === Roles.EXCLUSIVE_DISTRIBUTOR || role === Roles.SELECTIVE_DISTRIBUTOR) {
                    userList =
                        query?.regular_clinic_under_distributor?.flatMap((data1) =>
                            data1?.user?.user_role_mappings?.map((data) => getUser(data))
                        ) ?? null;
                } else if (role === Roles.SUPER_ADMIN) {
                    userList = query?.user_role_mapping?.map((data) => getUser(data)) ?? null;
                }
                break;
            default:
                break;
        }

        return userList;
    };
}
