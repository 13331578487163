import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, takeWhile } from 'rxjs';

import { PasswordStrengthValidator } from '@app/core/validators/password-strength-validator';
import { changePassword } from '@app/auth/actions/auth.action';
import { HandlePopupService } from '@app/core/services/handle-popup.service';
import { ResetPasswordWithNewMutationVariables } from '@app/generated/graphql';
import { getEmailIdOfLoggedInUser } from '@app/ui/selectors/ui.selector';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    isAlive = true;
    showNewPassword = false;
    showConfirmPassword = false;
    emailIdOfUser = '';
    changePasswordForm: FormGroup = new FormGroup({});
    closeChangePasswordStatus$: Subscription = new Subscription();
    @Output() closeChangePwdForm = new EventEmitter<boolean>();

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _popupService: HandlePopupService,
    ) {}

    ngOnInit(): void {
        this.addPasswordValidationControls();
        this.closeChangePasswordStatus$ = this._popupService.changePasswordCloseStatus()
        .pipe(
            takeWhile(() => this.isAlive)
        )
        .subscribe((res: boolean) => {
            if (res) {
                this.onCloseChangePwdModal();
            }
        });

        // fetch email if from store
        this._store.select(getEmailIdOfLoggedInUser)
        .pipe(
            takeWhile(() => this.isAlive)
        )
        .subscribe((res: string) => {
            this.emailIdOfUser = res;
        });
    }

    /**
     * Adds new password form controls
     * When continue is clicked from first popup, these controls are set on second ui
     * @param Nil
     * @returns void
     */
    addPasswordValidationControls = (): void => {
        this.changePasswordForm = this._fb.group(
            {
                newPassword: [null, [Validators.required, PasswordStrengthValidator, Validators.maxLength(128)]],
                confirmPassword: [null, [Validators.required, PasswordStrengthValidator, Validators.maxLength(128)]],
            },
            {
                validators: this.pwdMatchValidator,
            }
        );
    };

    /**
     * This is to check if both passwords match
     * @param control formcontrol of two fields
     * @returns null | Object with error key and boolean value
     */
    pwdMatchValidator = (control: AbstractControl): { mismatch: boolean } | null => {
        return control.value.newPassword === control.value.confirmPassword ? null : { mismatch: true };
    };

    /**
     * Hide/show password New Password in second popup
     * @param Nil
     * @returns void
     */
    onToggleNewPassword = (): void => {
        this.showNewPassword = !this.showNewPassword;
    };

    /**
     * Hide/show password Confirm Password in second popup
     * @param Nil
     * @returns void
     */
    onToggleConfirmPassword = (): void => {
        this.showConfirmPassword = !this.showConfirmPassword;
    };

    /**
     * Checks if the control is valid without any errors
     * @param name of the form control
     * @returns boolean
     */
    isValid = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.valid;
    };

    /**
     * Checks if the control is touched
     * @param name of the form control
     * @returns boolean
     */
    isTouched = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.touched;
    };

    /**
     * Checks if the control is dirty
     * @param name of the form control
     * @returns boolean
     */
    isDirty = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.dirty;
    };

    /**
     * Checks if the control is required
     * @param name of the form control
     * @returns boolean
     */
    isRequired = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.errors?.['required'];
    };

    /**
     * Checks if the control is having strength error
     * @param name of the form control
     * @return boolean
     */
    isPasswordStrong = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.hasError('passwordStrength');
    };

    /**
     * Checks whether there is max length error
     * @param name of the form control
     * @returns boolean
     */
    isMaxLengthError = (name: string): boolean => {
        return this.changePasswordForm.controls[name]?.errors?.['maxlength'];
    };

    /**
     * On Confirm button click update the password with new one
     * @param Nil
     * @returns void
     */
    onUpdateWithNewPassword = (): void => {
        if (this.changePasswordForm.valid) {
            const passwords: ResetPasswordWithNewMutationVariables = {
                params: {
                    email: this.emailIdOfUser,
                    newPassword: this.changePasswordForm.get('newPassword')?.value
                }
            }
            this._store.dispatch(changePassword(passwords));
        }
    };

    /**
     * For closing popup emit an event to parent
     * @param Nil
     * @returns void
     */
    onCloseChangePwdModal = (): void => {
        this.closeChangePwdForm.emit(true);
    };

    ngOnDestroy(): void {
        this.isAlive = false;
        this.closeChangePasswordStatus$.unsubscribe();
    }
}
