import { TreatmentHistory } from '@app/core/util/common';
import { PatientInfoForDoctor } from '@app/shared/models/patient-details.model';
import { AData, D1Data, DoctorSlipDetails, LabFormDetails, eye } from '@app/core/treatment-engine/interfaces';
import {
    initializeAmendDetails,
    initializeDoctorSlipDetails,
    initializeFacdDetails,
    initializeLabformDetails,
} from '@app/core/util/treatment-output-details';
import {
    GetUserListBasedOnRoleQuery,
    DeleteUserMutationVariables,
    GetLatestCreatedOrderForPatientQuery,
    GetCurrentTreatmentDataByPatientQuery,
    GetPatientListBasedOnRolesQuery,
    GetPatientListTotalCountByRolesQueryVariables,
    GetPatientListBasedOnRolesQueryVariables,
    GetLatestFacdDataForFurtherTreatmentQuery,
    GetListOfOrderForPatientQuery,
    GetLatestSatDataForFurtherTreatmentQuery,
    GetPatientTransferRequestListQueryVariables,
    GetPatientTransferRequestListCountQueryVariables,
    GetDesignerIdByDoctorIdQuery,
    GetControlPanelValuesByDesignerIdQuery,
    Order_Type_Enum,
    } from '@app/generated/graphql';
import { FooterDropdownItems } from '@app/shared/models/shared.model';
import { User } from '@app/shared/models/shared.model';

export enum TreatmentMethod {
    START_NEW_DESIGN = 'New Design',
    WITHOUT_TROUBLESHOOT = 'Without Troubleshoot',
    WITH_TROUBLESHOOT = 'With Troubleshoot',
    EMPTY = '',
}

export enum ActionType {
    onboardPatient = '[Patient] Onboard patient',
    getRegularClinicList = '[Patient] Get regular clinic list',
    getRegularClinicListSuccess = '[Patient] Get regular clinic list success',
    getDoctorList = '[Patient] Get doctor list',
    getDoctorListSuccess = '[Patient] Get doctor list success',
    saveFacdFormData = '[Patient] Save Facd question answers',
    saveFacdFormDataAndSubmit = '[Patient] Save Facd question answers and Submit Order',
    addFacdDataToTreatment = '[Patient] After adding facd data, update to treatment with facd id',
    addFacdDataToOrderCollection = '[Patient] Add facd data to order table with treatment id and other details',
    getFacdDataById = '[Patient] Fetch Facd data',
    getFacdDataSuccess = '[Patient] Fetch Facd data success',
    getSatData = '[Patient] Fetch saved sat data',
    getSatDataSuccess = '[Patient] Fetch saved sat data success',
    getMatData = '[Patient] Fetch saved mat data',
    getMatDataSuccess = '[Patient] Fetch saved mat data success',
    getDistributorIdForRegularClinic = '[Patient] Fetch distributor id based on regular clinic',
    getDistributorIdForFranchiseClinic = '[Patient] Fetch distributor id based on franchise clinic',
    distributorIdFetchSuccess = '[Patient] Store fetched distributor id based on clinic',
    storeFacdDataToState = '[Patient] Store facd data to state',
    addDoctorAndLabFormDataToStore = '[Patient] Store doctor and lab form data',
    clearFacdStateFromStore = '[Patient] Clear facd data from state',
    clearFacdStateFromStoreSuccess = '[Patient] Clear facd data from state success',
    clearPatientDetailsFromStore = '[Patient] Clear patient details from store',
    clearPatientDetailsFromStoreSuccess = '[Patient] Clear patient data from state success',
    getPatientInfoForTreatment = '[Patient] Get Patient Info',
    getPatientInfoForTreatmentSuccess = '[Patient] Get Patient Info success',
    getPatientList = '[Patient] Get patient list',
    getPatientListSuccess = '[Patient]asdfsdfasdfasdfasdf Get patient list success',
    resetPatientList = '[Patient] Reset patient list',
    getPatientListFailure = '[Patient] Get patient list failure',
    getTotalTableItemsCountSuccess = '[Patient] Get total table list count success',
    resetTotalTableItemsCount = '[Patient] Reset total table items count',
    onDeletePatient = '[Patient] Delete patient',
    storeCurrentPatientId = '[Patient] Store current patient id (edit-patient detail) in state',
    getLatestOrderDetailOfPatient = '[Patient] Check if atleast one order exists for a patient',
    getLatestOrderDetailOfPatientSuccess = '[Patient] Successfully got latest order details',
    getLatestOrderDetailOfPatientFailure = '[Patient] Failed to Get latest order details of patient',
    getPreviousLatestOrderDetails = '[Patient] Get treatment order history to handle ui logic',
    getPreviousLatestOrderDetailsSuccess = '[Patient] Fetched treatment history successfully',
    getPreviousLatestOrderDetailsFailure = '[Patient] Failed to treatment history',
    storeTreatmentHistoryByDoctor = '[Patient] Store doctor selected treatment method',
    initializeCurrentTreatmentSelection = '[Patient] Clear details of treatment history',
    saveAmendQuestionsToState = '[Patient] Store sat/mat questions to state',
    clearAmendQuestionsStored = '[Patient] Clear sat/mat questions from state',
    getPreviousFacdForAmendTreatment = '[Patient] Fetch latest previous facd data for doctor slip generation in sat/mat',
    getPreviousFacdForAmendTreatmentSuccess = '[Patient] Facd data for doctor slip generation in sat/mat fetched successfully',
    onSaveAmendQuestionsDataAndSubmit = '[Patient] Save Sat data filled by user to sat table',
    addA2DataToTreatment = '[Patient] Insert data to treatment table for sat treatment',
    addA2DataToOrderCollection = '[Patient] Insert data to Order table for sat treatment',
    onSaveSatQuestionsDataAndSubmit = '[Patient] Save Mat data filled by user to mat table',
    addA3DataToTreatment = '[Patient] Insert data to treatment table for mat treatment',
    addA3DataToOrderCollection = '[Patient] Insert data to Order table for mat treatment',
    getPatientListTotalCount = '[Patient] Get the number of patients under a role',
    updatePrefillFormDataStatus = '[Patient] Reset the prefill status for form questions',
    getListOfOrdersOfPatient = '[Patient] Get list of orders of a patient',
    getListOfOrdersOfPatientSuccess = '[Patient] List of orders of a patient fetched successfully',
    getPreviousSatForAmendTreatment = '[Patient] Fetch latest previous sat data for doctor slip generation in mat',
    getPreviousSatForAmendTreatmentSuccess = '[Patient] Sat data for doctor slip generation in mat fetched successfully',
    createOrderForSpareLens = '[Patient] Create order for spare lens',
    loadPatientTransferRequestList = '[Patient Transfer Request List] Load Patient Transfer Request List',
    loadPatientTransferRequestListSuccess = '[Patient Transfer Request List] Load Patient Transfer Request List Success',
    loadPatientTransferRequestListFailure = '[Patient Transfer Request List] Load Patient Transfer Request List Failure',
    loadPatientTransferRequestListCount = '[Patient Transfer Request List] Load Patient Transfer Request List Count',
    loadPatientTransferRequestListCountSuccess = '[Patient Transfer Request List] Load Patient Transfer Request List Count Success',
    loadPatientTransferRequestListCountFailure = '[Patient Transfer Request List] Load Patient Transfer Request List Count Failure',
    storeLastPayloadForPatientTransferRequestListAndCount = '[Patient Transfer Request List] Store Last payload for Getting Patient List and Count',
    acceptPatientTransferRequest = '[Patient Transfer] Accept Request',
    rejectPatientTransferRequest = '[Patient Transfer] Reject Request',
    addPatientFormAsDraftRequest = '[Patient Transfer] add form data as draft',
    getDesignerIdByDoctorId = '[Patient] Get designer id using doctor id',
    getDesignerIdByDoctorIdSuccess = '[Patient] Get designer id using doctor id Success',
    getControlPanelValuesByDesignerId = '[Patient] Get control panel values by designer id',
    getControlPanelValuesByDesignerIdSuccess = '[Patient] Get control panel values by designer id success',
    getContractManufacturerList = '[Patient] Get contract manufacturer list',
    getContractManufacturerListSuccess = '[Patient] Get contract manufacturer list success',
    getOrderSequences = '[Patient] Get order sequences',
    getOrderSequencesSuccess = '[Patient] Get order sequences success',
    getLensList = '[Patient] Get lens list',
    getLensListSuccess = '[Patient] Get lens list success',
    clearLensList = '[Patient] Clear lens list',
    getFranchiseClinicList = '[Patient] Get franchise clinic list',
    getFranchiseClinicListSuccess = '[Patient] Get franchise list success',
    getFranchiseClinicDoctorsList = '[Patient] Get franchise clinic doctor list',
    getFranchiseClinicDoctorsListSuccess = '[Patient] Get franchise clinic doctor list success',
    inUseLensSelection = '[Patient] Update in use lens selection status of order under a patient',
    getDistributorTypeBasedOnDoctorId = '[ Patient ] Get distributor type based on logged in doctor id',
    getDistributorTypeBasedOnDoctorIdSuccess = '[ Patient ] Get distributor type based on logged in doctor id success',
    updateLoopToMatchCorrectness = '[ Patient ] Update whether loop to match is correct or not',
    updateLabSelected = '[Patient] Update lab selected to currentTreatmentBasicDetails',
    getManufacturerHistoryPerPatient = '[Patient] Get manufacturer history per patient',
    getManufacturerHistoryPerPatientSuccess = '[Patient] Get manufacturer history per patient success',
    updateListActiveItems = '[Patient] Update list active item',
    getOldPatientList = '[Patient] get old patient list',
    getOldPatientListSuccess = '[Patient] get old patient list success',
    updateOldPatientTotalCount = '[Patient] update old patient total count',
    updateCareOfPatient = '[Patient] update care of patient',
    sendLoginCredToPatient = '[Patient] send login cred to patient',
    getTransferredPatientDetails = '[Patient] get transferred patient details',
    getTransferredPatientDetailsSuccess = '[Patient] get transferred patient details success',
    discardDraft = '[Patient] discard draft',
}

export interface State {
    facdFormData: D1Data;
    patientInfo: PatientInfoForDoctor | null;
    patientList: GetPatientListBasedOnRolesQuery | null;
    totalTableListCount: number | null;
    patientId: string | null;
    distributorIdOfClinic: string;
    doctorSlipDetails: DoctorSlipDetails;
    labFormDetails: LabFormDetails;
    latestOrderDetailsOfPatient: GetLatestCreatedOrderForPatientQuery;
    treatmentHistory: GetCurrentTreatmentDataByPatientQuery;
    currentTreatmentBasicDetails: TreatmentBasicDetailCollection;
    amendFormData: AData;
    facdForAmendFormDoctorSlipGeneration: GetLatestFacdDataForFurtherTreatmentQuery;
    satForAmendFormDoctorSlipGeneration: GetLatestSatDataForFurtherTreatmentQuery;
    ordersList: GetListOfOrderForPatientQuery;
    patientTransferRequestList: PatientTransferRequestList[] | null;
    lastPayloadForPatientTransferRequestListAndCount: {
        list: GetPatientTransferRequestListQueryVariables | null;
        count: GetPatientTransferRequestListCountQueryVariables | null;
    };
    totalPatientTransferRequestListCount: number;
    savePatientTreatmentFormAsDraft: boolean;
    designerId: GetDesignerIdByDoctorIdQuery;
    getControlPanelValuesByDesignerId: GetControlPanelValuesByDesignerIdQuery | null;
    contractManufacturerList: ContractManufacturerListForLabSelection[] | [];
    orderSequences: OrderSequenceForBothEye | null;
    lensList: Lens[] | null;
    satData: AData | null;
    matData: AData | null;
    doctorsList: DoctorsForListDropdown[] | null;
    franchiseClinicDoctorsList: DoctorsForListDropdown[] | null;
    franchiseClinicList: User[] | null;
    regularClinicList: User[] | null;
    distributorType: string | null;
    loopToMatchCorrectness: boolean;
    manufacturerHistoryPerPatient: ManufacturerHistoryPerPatient[] | null;
    listActiveItems: ListActiveItems[];
    oldPatientList: OldPatient[] | null;
    totalOldPatientCount: number | null;
    careOfPatient: CareOfPatient | null;
    patientTransferDetails: PatientTransferDetails | null;
}

export const initialState: State = {
    regularClinicList: null,
    facdFormData: initializeFacdDetails,
    patientInfo: null,
    patientList: null,
    totalTableListCount: null,
    patientId: null,
    distributorIdOfClinic: '',
    doctorSlipDetails: initializeDoctorSlipDetails,
    labFormDetails: initializeLabformDetails,
    latestOrderDetailsOfPatient: { order: [] },
    treatmentHistory: { order: [] },
    currentTreatmentBasicDetails: {
        formType: TreatmentHistory.FACD,
        isPrefilledView: false,
        treatmentProcedure: TreatmentMethod.EMPTY,
        facdId: '',
        satId: '',
        matId: '',
        eye: eye.empty,
        fetchAndView: false,
        orderType: Order_Type_Enum.New,
        orderSequence: {left: 0, right: 0},
        contractManufacturerId: '',
        lens: {
          left: '',
          right: ''
        }
    },
    amendFormData: initializeAmendDetails,
    facdForAmendFormDoctorSlipGeneration: {
        facd: [],
    },
    satForAmendFormDoctorSlipGeneration: {
        sat: [],
    },
    ordersList: {
        order: [],
        totalOrders: {
            aggregate: {
                count: 0,
            },
        },
    },
    patientTransferRequestList: null,
    lastPayloadForPatientTransferRequestListAndCount: {
        list: null,
        count: null,
    },
    totalPatientTransferRequestListCount: 0,
    savePatientTreatmentFormAsDraft: false,
    designerId: { user: [] },
    getControlPanelValuesByDesignerId: null,
    contractManufacturerList: [],
    orderSequences: null,
    lensList: null,
    satData: null,
    matData: null,
    doctorsList:null,
    franchiseClinicDoctorsList: null,
    franchiseClinicList: null,
    distributorType: null,
    loopToMatchCorrectness: true,
    manufacturerHistoryPerPatient: null,
    listActiveItems: [{key: "patients", value: "Patients", isSelected: true}, {key: "oldPatients", value: "Old Patients", isSelected: false}],
    oldPatientList: null,
    totalOldPatientCount: null,
    careOfPatient: null,
    patientTransferDetails: null,
};

/* Patient listing model */
export type PatientListData = {
    id: string;
    patient_name: string;
    contact_number: string;
    reference_number: string;
    email_id: string;
    role_mapping_id: string;
    clinic_name?: string;
    doctor_name?: string;
    created_by?: string;
    doctor_id?: string;
}

export interface DeletePatientAndLoadListQueryVariables {
    patientListParams: GetPatientListBasedOnRolesQueryVariables;
    patientCountParams: GetPatientListTotalCountByRolesQueryVariables;
    deleteUserMutationVariables: DeleteUserMutationVariables;
}

export interface TreatmentBasicDetailCollection {
    formType: TreatmentHistory;
    isPrefilledView: boolean;
    treatmentProcedure: TreatmentMethod;
    id?: string;
    facdId: string;
    satId: string;
    matId: string;
    eye: eye;
    fetchAndView: boolean;
    orderType: Order_Type_Enum;
    orderSequence: OrderSequenceForBothEye;
    designType?: string;
    contractManufacturerId: string;
    lens: {
      right: string;
      left: string
    }
}

export interface PatientTransferRequestList {
    id: string;
    patient_id: string;
    patient_name: string;
    contact_number: string;
    reference_number: string;
    email_id: string;
    doctor_id: string;
    clinic_id: string;
    clinic_type: string;
    roleDetails: JSON

}

export interface ExtraDataForPatientTransfer {
    patientId: string;
    patientName: string;
    currentClinicName: string;
    currentDoctorName: string;
    createdBy: string;
}

export interface ContractManufacturerListForLabSelection {
  id: string;
  name: string;
}

export interface OrderSequenceForBothEye {
  left: number,
  right: number
}

export interface Lens {
  id: string;
  name: string;
  color: string | null | undefined;
}

export interface DoctorsForListDropdown {
  id: string,
  name: string
}

export interface ManufacturerHistoryPerPatient {
  id: string,
  updatedOn: string,
  tableId: string,
}

export type ListActiveItems = FooterDropdownItems;

export type OldPatient = {
  id: string,
  role_mapping_id: string,
  role_details: string,
  name: string,
  reference_number: string,
  country_code: string,
  contact_number: string,
  email: string
  doctorId: string,
  clinicId: string,
  doctor_name: string,
  clinic_name: string,
  created_by: string
}

export type PatientActiveStatus = {
  is_active: boolean,
  status: boolean
}
export interface CareOfPatient {
  user_id: string,
  email: string,
  name: string
}

export interface PatientTransferDetails {
  transferredFrom: string,
  transferredClinicType: string,
  patientId: string,
  email: string,
  hasCredentials?: boolean
}
