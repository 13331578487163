<div class="onboarding-form mt-1">
    <div class="d-flex align-items-center justify-content-between info">
        <div class="heading d-flex align-items-center">
            <span class="contract_icon d-block">
                <img class="icon_img" width="20" height="20" [src]="imageSrc" />
            </span>
            <span class="contract_manufacture ms-1">{{ heading }}</span>
        </div>
    </div>
    <div class="table-responsive" [ngClass]="{ 'hide-scroll': data?.length === 0 }">
        <div *ngIf="isLoading; else tableTemplate" class="text-center">
            <p class="kl-table-empty-text">Loading...</p>
        </div>
        <ng-template #tableTemplate>
            <table class="table">
                <thead>
                    <tr class="pull-row-left">
                        <th *ngFor="let key of tableHeadings">{{ key }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data" class="pull-row-left">
                        <td *ngFor="let key of keys; let isFirst = first" scope="row" appStatusColor>
                            <ng-container *ngIf="showInitials && isFirst; else normalCell">
                                <span class="rounded-with-bg">{{ getInitials(item) }}</span>
                                &nbsp;{{ item?.name }}
                            </ng-container>
                            <ng-template #normalCell>
                                {{ formatValue(item, key) }}
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
        <div *ngIf="data?.length === 0 && !isLoading" class="no-data-found-label d-flex justify-content-center">
            <span class="kl-table-empty-text">No Data Found</span>
        </div>
    </div>
</div>
