import { AData, ADataLeftEye, ADataRightEye, D1Data, D1DataLeftEye, D1DataRightEye, DoctorSlipDetails, EyeSide, LabFormDetails, axis, correction, eye, optimisationLevel, orderType } from '../treatment-engine/interfaces';

/**
 * Initialise doctor slip details
 */
export const initializeDoctorSlipDetails: DoctorSlipDetails = {
    patientName: "",
    referenceName: "",
    contactNumber: "",
    practiceName: "",
    orderDate: "",
    side: {
        right: '',
        left: '',
    },
    materialAndColor: {
        right: '',
        left: '',
    },
    lensType: {
        right: '',
        left: '',
    },
    BV: {
        right: 0,
        left: 0,
    },
    diameter: {
        right: 0,
        left: 0,
    },
    ct: {
        right: 0,
        left: 0,
    },
    bc: {
        right: {
            value1: 0,
            value2: 0,
            width: 0,
        },
        left: {
            value1: 0,
            value2: 0,
            width: 0,
        },
    },
    bc_eccentricity: {
        right: '',
        left: '',
    },
    taz: {
        right: {
            value1: 0,
            value2: null,
            width: 0,
        },
        left: {
            value1: 0,
            value2: null,
            width: 0,
        },
    },
    tpz: {
        right: {
            value1: 0,
            value2: null,
            width: 0,
        },
        left: {
            value1: 0,
            value2: null,
            width: 0,
        },
    },
    orthoCorrectionReason: '',
    estimated_delivery: '',
    specialInstructions: '',
    lensManufacturer: '',
    targetEye: EyeSide.EMPTY,
    lensColor: {
        right: '',
        left: ''
    }
};

/**
 * Initialise labform details
 */
export const initializeLabformDetails: LabFormDetails = {
    orderDate: '',
    patientName: '',
    doctorName: '',
    clinicName: '',
    patientReference: '',
    side: {
        right: '',
        left: '',
    },
    materialAndColor: {
        right: '',
        left: '',
    },
    lensType: {
        right: '',
        left: '',
    },
    BV: {
        right: 0,
        left: 0,
    },
    diameter: {
        right: 0,
        left: 0,
    },
    ct: {
        right: 0,
        left: 0,
    },
    foz: {
        right: 0,
        left: 0,
    },
    et: {
        right: 0,
        left: 0,
    },
    bc: {
        right: {
            value1: 0,
            value2: 0,
            width: 0,
        },
        left: {
            value1: 0,
            value2: 0,
            width: 0,
        },
    },
    bc_eccentricity: {
        right: 0,
        left: 0,
    },
    ic1: {
        right: {
            value1: 0,
            value2: 0,
            width: 0,
        },
        left: {
            value1: 0,
            value2: 0,
            width: 0,
        },
    },
    ic2: {
        right: {
            value1: 0,
            value2: 0,
            width: 0,
        },
        left: {
            value1: 0,
            value2: 0,
            width: 0,
        },
    },
    ic3: {
        right: {
            value1: 0,
            value2: 0,
            width: 0,
        },
        left: {
            value1: 0,
            value2: 0,
            width: 0,
        },
    },
    ic4: {
        right: {
            value1: 0,
            value2: null,
            width: 0,
        },
        left: {
            value1: 0,
            value2: null,
            width: 0,
        },
    },
    ic5: {
        right: {
            value1: 0,
            value2: null,
            width: 0,
        },
        left: {
            value1: 0,
            value2: null,
            width: 0,
        },
    },
    specialInstructions: '',
    lensManufacturer: '',
    lensColor: {
        right: '',
        left: ''
    }
};

export const initializeFacdDetails: D1Data = {
    clinicName: '',
    clinicAddress: '',
    patientName: '',
    patientReference: '',
    doctorName: '',
    OrderType: orderType.new,
    question1: eye.empty,
    question2: {
        right: 0,
        left: 0,
    },
    question3: {
        right: {
            sphere: 0,
            cylinder: 0,
            axis: axis.WTR,
        },
        left: {
            sphere: 0,
            cylinder: 0,
            axis: axis.WTR,
        },
    },
    question4: {
        right: correction.miopiaControl,
        left: correction.miopiaControl
    },
    question5: {
        right: 0,
        left: 0,
    },
    question6: {
        right: 0,
        left: 0,
    },
    question7: {
        right: 0,
        left: 0,
    },
    question8: {
        a: {
            right: 0,
            left: 0,
        },
        b: {
            right: 0,
            left: 0,
        },
    },
    question9: {
        right: optimisationLevel.optimised,
        left: optimisationLevel.optimised,
    },
    question10: {
        right: false,
        left: false,
    },
    question11: {
        right: '',
        left: '',
    },
    question12: '',
    evalueHistory: {
        right: {
            evalue: 0,
            calculatedValue: 0,
        },
        left: {
            evalue: 0,
            calculatedValue: 0,
        }
    },
    lensColor: {
        right: '',
        left: ''
    }
}

export const initializeFacdRightEye: D1DataRightEye = {
    clinicName: '',
    clinicAddress: '',
    patientName: '',
    patientReference: '',
    doctorName: '',
    OrderType: orderType.new,
    question1: eye.empty,
    question2: {
        right: 0,
    },
    question3: {
        right: {
            sphere: 0,
            cylinder: 0,
            axis: axis.WTR,
        },
    },
    question4: {
        right: correction.miopiaControl,
    },
    question5: {
        right: 0,
    },
    question6: {
        right: 0,
    },
    question7: {
        right: 0,
    },
    question8: {
        a: {
            right: 0,
        },
        b: {
            right: 0,
        },
    },
    question9: {
        right: optimisationLevel.optimised,
    },
    question10: {
        right: false,
    },
    question11: {
        right: '',
    },
    question12: '',
    evalueHistory: {
        right: {
            evalue: 0,
            calculatedValue: 0,
        },
    },
    lensColor: {
        right: ''
    }
}

export const initializeFacdLeftEye: D1DataLeftEye = {
    clinicName: '',
    clinicAddress: '',
    patientName: '',
    patientReference: '',
    doctorName: '',
    OrderType: orderType.new,
    question1: eye.empty,
    question2: {
        left: 0,
    },
    question3: {
        left: {
            sphere: 0,
            cylinder: 0,
            axis: axis.WTR,
        },
    },
    question4: {
        left: correction.miopiaControl
    },
    question5: {
        left: 0,
    },
    question6: {
        left: 0,
    },
    question7: {
        left: 0,
    },
    question8: {
        a: {
            left: 0,
        },
        b: {
            left: 0,
        },
    },
    question9: {
        left: optimisationLevel.optimised,
    },
    question10: {
        left: false,
    },
    question11: {
        left: '',
    },
    question12: '',
    evalueHistory: {
        left: {
            evalue: 0,
            calculatedValue: 0,
        }
    },
    lensColor: {
        left: ''
    }
}

export const initializeAmendDetails: AData = {
    clinicName: '',
    patientName: '',
    patientReference: '',
    practitionerName: '',
    eyes: eye.empty,
    question1: {
        right: 0,
        left: 0,
    },
    question2: {
        right: 0,
        left: 0,
    },
    question3: {
        right: 0,
        left: 0,
    },
    question4: {
        right: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
        left: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
    },
    question5: {
        right: {
            1: 0,
            2: 0,
            3: axis.WTR,
        },
        left: {
            1: 0,
            2: 0,
            3: axis.WTR,
        },
    },
    question6: {
        right: false,
        left: false,
    },
    question7: {
        right: false,
        left: false,
    },
    question8: {
        right: 0,
        left: 0,
    },
    question9: '',
    question10: {
      right: '',
      left: ''
    },
    lensColor: {
      right: '',
      left: '',
    }
}
export const initializeAmendRightEye: ADataRightEye = {
    clinicName: '',
    patientName: '',
    patientReference: '',
    practitionerName: '',
    eyes: eye.empty,
    question1: {
        right: 0,
    },
    question2: {
        right: 0,
    },
    question3: {
        right: 0,
    },
    question4: {
        right: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
    },
    question5: {
        right: {
            1: 0,
            2: 0,
            3: axis.WTR,
        },
    },
    question6: {
        right: false,
    },
    question7: {
        right: false,
    },
    question8: {
        right: 0,
    },
    question9: '',
    question10: {
      left: ''
    }
}
export const initializeAmendLeftEye: ADataLeftEye = {
    clinicName: '',
    patientName: '',
    patientReference: '',
    practitionerName: '',
    eyes: eye.empty,
    question1: {
        left: 0,
    },
    question2: {
        left: 0,
    },
    question3: {
        left: 0,
    },
    question4: {
        left: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
    },
    question5: {
        left: {
            1: 0,
            2: 0,
            3: axis.WTR,
        },
    },
    question6: {
        left: false,
    },
    question7: {
        left: false,
    },
    question8: {
        left: 0,
    },
    question9: '',
    question10: {
      left: ''
    }
}
