import { Component } from '@angular/core';

import { UtilService } from '@app/core/services/util.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
    constructor(
        private _utilService: UtilService,
    ) {}

    /**
     * Navigate to home page if 404 encountered
     * @param
     * @returns void
     */
    onNavigateToHome = (): void => {
        this._utilService.navigateToRoutesBasedOnRole();
    };  
}
