<div class="modal-header">
    <h4 class="modal-title kl-h5-type-5">Filter</h4>
    <img
        src="/assets/icons/common/close.svg"
        class="cursor-pointer"
        aria-label="close"
        (click)="activeModal.dismiss()"
        alt="close modal"
    />
</div>
<form [formGroup]="filterForm" (submit)="applyFilter()">
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="form-group kl-select-container w-100">
                    <label for="filterType" class="kl-text5-type-11">Filter Type</label>
                    <select class="kl-select w-100" formControlName="filterType" name="filterType">
                        <option [value]="''" selected>Select Filter Type</option>
                        <option *ngFor="let type of filterTypes" [value]="type.value">{{ type.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group kl-select-container w-100">
                    <label for="filterValue" class="kl-text5-type-11">Filter By</label>
                    <select class="kl-select w-100" formControlName="filterValue" name="filterValue">
                        <option [value]="''" selected>Select Filter By</option>
                        <option *ngFor="let value of filterByValues$ | async" [value]="value">
                            {{ value | capitalizeOrderStatus : 'order_status' }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="startDate" class="kl-text5-type-11">Start Date</label>
                    <div class="position-relative w-100">
                        <input
                            class="form-control pr-5 w-100"
                            formControlName="startDate"
                            placeholder="yyyy-mm-dd"
                            name="startDate"
                            ngbDatepicker
                            #dStart="ngbDatepicker"
                        />
                        <img
                            class="position-absolute top-50 end-0 translate-middle-y me-2"
                            (click)="dStart.toggle()"
                            src="/assets/icons/common/calendar-black.svg"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="endDate" class="kl-text5-type-11">End Date</label>
                    <div class="position-relative w-100">
                        <input
                            class="form-control w-100"
                            formControlName="endDate"
                            placeholder="yyyy-mm-dd"
                            name="endDate"
                            ngbDatepicker
                            #dEnd="ngbDatepicker"
                        />
                        <img
                            class="position-absolute top-50 end-0 translate-middle-y me-2"
                            (click)="dEnd.toggle()"
                            src="/assets/icons/common/calendar-black.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-md-flex d-block justify-content-center px-1 mb-3">
        <div class="col-12 col-md-6 px-2 mb-2 mb-md-0">
            <button type="button" class="btn w-100 kl-btn-outline-type-1" (click)="activeModal.dismiss()">
                Cancel
            </button>
        </div>
        <div class="col-12 col-md-6 px-2">
            <button type="submit" class="btn w-100 kl-btn-primary-type-2">Apply Filter</button>
        </div>
    </div>
</form>
