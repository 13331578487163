<div class="user-delete-confirm-modal-container position-relative">
    <button type="button" class="close position-absolute top-10" aria-label="Close" (click)="closeModal()">
        <img class="close-icon cursor-pointer" src="/assets/icons/navbar-profile/popup-close.png" />
    </button>
    <div class="modal-header d-flex justify-content-center">
        <h4 class="modal-title text-center kl-h7-type-1">{{ data?.heading }}</h4>
    </div>
    <div class="modal-body text-center">
        <p class="kl-text5-type2">{{ data?.message }}</p>
        <p *ngIf="data?.deleteMessage === 'Delete user' || data?.heading === 'Delete and Assign Users'" class="error-text">Deleted emails can not be onboarded again</p>
        <div id="select-clinic-from-list" class="my-5" *ngIf="data?.roleIdentifier === 'doctor' && loggedInUserRole === 'super_admin' && data?.event === 'open-user-delete-modal'">
          <h4 class="kl-text5-type-11 text-start">Select a Partner from which you want to remove the practitioner.</h4>
          <select class="form-select kl-input-text" [(ngModel)]="removeDoctorFromClinicWithId">
            <option value="" selected disabled>Select a Partner</option>
            <option *ngFor="let clinic of clinics" [value]="clinic?.id">
              {{ clinic?.name }}
            </option>
          </select>
          <div class="error-common-container text-left" *ngIf="noClinicSelected && !removeDoctorFromClinicWithId">
            <span class="error-text">Please select a clinic</span>
          </div>
        </div>
        <div id="select-distributor-from-list" class="my-5" *ngIf="data?.roleIdentifier === 'contract_manufacturer' && loggedInUserRole === 'super_admin' && data?.event === 'open-user-delete-modal'">
          <h4 class="kl-text5-type-11 text-start">Select a distributor from which you want to remove the contract manufacturer.</h4>
          <select class="form-select kl-input-text" [(ngModel)]="removeManufacturerFromDistributorWithId">
            <option value="" selected disabled>Select a Distributor</option>
            <option *ngFor="let distributor of distributors" [value]="distributor?.id">{{ distributor?.name }}</option>
          </select>
          <div class="error-common-container text-left" *ngIf="noDistributorSelected && !removeDoctorFromClinicWithId">
            <span class="error-text">Please select a distributor</span>
          </div>
        </div>
        <div id="select-user-from-list" class="my-5" *ngIf="event === 'open-user-delete-and-assign-modal'">
          <ng-container *ngIf="(userList$ | async)?.length as length; else noData">
            <h4 class="kl-text5-type-11 text-start">Select {{data?.role}}</h4>
            <select class="form-select kl-input-text" [(ngModel)]="userId">
              <option value="" selected>Select a {{ data?.role }}</option>
              <ng-container *ngFor="let user of userList$ | async">
                <option *ngIf="user?.id !== data?.id" [value]="user?.id">
                  {{ user?.name }} ({{ user?.email }})
                </option>
              </ng-container>
            </select>
          </ng-container>
          <ng-template #noData>
            <div class="error-common-container highted-bg p-4"><span class="error-text">No {{data?.role}} found to reassign.</span></div>
          </ng-template>
        </div>
        <div [ngClass]="{'button-container d-flex flex-row flex-wrap justify-content-center': event === 'open-user-delete-confirm-message-modal'}">
          <button
              *ngIf="data?.deleteAndAssignMessage"
              (click)="onDeleteAndAssignUser()"
              [ngClass]="{
                  'assign-user-btn': event === 'open-user-delete-modal',
                  'delete-user-btn': event === 'open-user-delete-and-assign-modal'
              }"
              class="action-button mb-3 me-3"
          >
              <img
                  *ngIf="event === 'open-user-delete-and-assign-modal'"
                  src="/assets/icons/common/Trash-f.svg"
                  class="me-1"
                  alt="trash button"
              />
              <span>{{ data?.deleteAndAssignMessage }}</span>
              <img
                  *ngIf="event === 'open-user-delete-modal'"
                  src="/assets/icons/common/arrow-right-red.svg"
                  class="ms-1"
                  alt="arrow right"
              />
          </button>
          <button *ngIf="data?.deleteMessage" (click)="onDeleteUser()" class="action-button delete-user-btn mb-3 me-3">
              <span
                  ><img src="/assets/icons/common/Trash-f.svg" class="me-1" alt="trash button" />{{
                      data?.deleteMessage
                  }}</span
              >
          </button>
          <button *ngIf="data?.showCancelButton" (click)="closeModal()" class="action-button assign-user-btn mb-3">
              <span>Cancel</span>
          </button>
      </div>

    </div>
</div>
