import { Action, createReducer, on } from '@ngrx/store';

import { OrderRelatedIds, State, UserBasicDetails, initialState } from '../models/orders.model';
import { GetListOfOrdersBasedOnRoleQuery } from '@app/generated/graphql';
import { getDetailsOfUserSuccess, getOrderSequencesSuccess, getOrdersListBasedOnRoleSuccess, getTotalOrderListCountSuccess, updateOrderIdInState, updatePatientIdStored } from '../actions/orders.action';
import { resetAppState } from '@app/actions';

const _reducer = createReducer(
    initialState,
    on(getTotalOrderListCountSuccess, (state, payload) => ({
        ...state,
        orderTotalCount: payload.orderListCount,
    })),
    on(getOrdersListBasedOnRoleSuccess, (state, payload: {orderListData: GetListOfOrdersBasedOnRoleQuery}) => ({
        ...state,
        orderListData: payload.orderListData
    })),
    on(updatePatientIdStored, (state, payload: {patientId: string}) => ({
        ...state,
        patientIdUsed: payload.patientId
    })),
    on(getDetailsOfUserSuccess, (state, payload: {userDetails: UserBasicDetails}) => ({
        ...state,
        userBasicDetails: payload.userDetails
    })),
    on(updateOrderIdInState, (state, payload: {orderIds: OrderRelatedIds}) => ({
        ...state,
        activeOrderId: payload.orderIds
    })),
    on(getOrderSequencesSuccess, (state, { orderSequences }) => ({
      ...state,
      orderSequences
    })),
    on(resetAppState, () => initialState)
);
export function ordersReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
