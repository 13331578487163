import { Action, createReducer, on } from "@ngrx/store";

import { State, initialState } from '../models/regular-clinic.model';
import { getExclusiveDistributorListSuccess, getRegularClinicFormDataSuccess, getRegularClinicListCountSuccess, getRegularClinicsListBasedOnRoleSuccess, getSelectiveDistributorListSuccess } from "../actions/regular-clinic.action";
import { resetAppState } from "@app/actions";

const _reducer = createReducer(
    initialState,
    on(getExclusiveDistributorListSuccess, (state, payload) => ({
        ...state,
        exclusiveDistributorList: payload?.getExclusiveDistributorList,
    })),
    on(getSelectiveDistributorListSuccess, (state, payload) => ({
        ...state,
        selectiveDistributorList: payload?.getSelectiveDistributorList,
    })),
    on(getRegularClinicListCountSuccess, (state, payload) => ({
        ...state,
        regularClinicTotalCount: payload.regularClinicListCount,
    })),
    on(getRegularClinicsListBasedOnRoleSuccess, (state, payload) => ({
        ...state,
        regularClinicListData: payload.regularClinicListData,
    })),
    on(getRegularClinicFormDataSuccess, (state, payload) => ({
        ...state,
        regularClinicDetails: payload?.regularClinicDetails,
    })),
    on(resetAppState, () => initialState)
);

export function regularClinicReducer(state: State | undefined, action: Action) {
    return _reducer(state, action);
}
