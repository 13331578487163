<!-- Sidebar start -->
<div
    class="sidebar custom-scrollbar toggle-view"
    [ngClass]="{
        'move-left': isExpand,
        'move-right': !isExpand && isMobile
    }"
>
    <div class="logo-name-wrapper">
        <!-- Collapse sidebar button start -->
        <button class="logo-name-button" (click)="onToggleView()">
            <i class="bi bi-x-circle logo-name-icon" id="logo-name-icon"></i>
        </button>
        <!-- Collapse sidebar button end -->
    </div>
    <!-- List all sidebar links start -->
    <ul class="features-list ps-0">
        <ng-container *ngFor="let item of menuItems">
            <li
                class="features-item kl-text3-type-3 hover-type-2 cursor-pointer position-relative"
                [ngClass]="{
                    'ms-4': !item?.iconSrc,
                    'hide-sub-item': item?.hideLink,
                    'show-side-menu': item?.showRightArrowIcon
                }"
                (click)="onNavigateToLink(item)"
                [routerLink]="item.path ? item?.path : null"
                routerLinkActive="active-type-1"
                *ngIf="item.isViewableByCurrentRole"
            >
                <div class="d-flex w-100">
                    <img *ngIf="item?.iconSrc" [src]="getMenuIcon(item)" />
                    <span class="features-item-text">{{ item?.title }}</span>
                    <span class="ms-auto" *ngIf="item?.showDownArrowIcon || item?.showUpArrowIcon">
                        <span class="right-icon">
                            <i
                                [ngClass]="{
                                    'bi bi-chevron-down': item?.showDownArrowIcon,
                                    'bi bi-chevron-up': item?.showUpArrowIcon
                                }"
                                (click)="onShowHideSubMenu(item)"
                            ></i>
                        </span>
                    </span>
                    <span class="ms-auto" *ngIf="item?.showRightArrowIcon">
                        <span class="right-icon">
                            <i class="bi bi-chevron-right"></i>
                        </span>
                    </span>
                </div>
                <div class="side-menu position-absolute top-0" *ngIf="item.showRightArrowIcon && item.showSideMenu">
                    <ul>
                        <li
                            class="features-item kl-text3-type-3 hover-type-2 cursor-pointer"
                            *ngFor="let sideMenu of item.sideMenu"
                            (click)="onNavigateToLink(sideMenu, $event)"
                        >
                            {{ sideMenu?.title }}
                        </li>
                    </ul>
                </div>
            </li>
        </ng-container>
    </ul>
    <!-- List all sidebar links end -->
</div>
<!-- Sidebar end -->
