import { Injectable } from '@angular/core';

import {
    GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery,
    InsertToPaymentForTotalCostAndSalesMutationVariables,
    Order_Type_Enum,
    Payment_Insert_Input,
} from '@app/generated/graphql';
import {
    PriceSettingsSkuFields,
    UsersAndChargesForPaymentBaseData,
} from '@app/shared/models/user-price-settings.model';
import { DesignType, Roles } from '../util/common';
import { PriceSettingSkuInitialData } from '../util/initial-values';
import { CredentialsService } from './credentials.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentHandlerService {

    constructor(
        private _credentialService: CredentialsService
    ){}

    /**
     * Process data from api to format that state expects
     * converts charges of doctor to number format as price value in of type string
     * Creates data which is close enough to be payload for inserting to payment table
     */
    processUserIdAndPriceSettingsDataForPayment = (
        userData: GetUserIdAndPriceDataBasedOnHeirarchyForPaymentInsertionQuery,
        clinicType: string,
        doctorId: string
    ): UsersAndChargesForPaymentBaseData => {
        const distributorDesignerData =
            clinicType === Roles.FRANCHISE_CLINIC
                ? userData?.clinic_doctor_mapping?.[0]?.user.distributor_franchise
                : userData?.clinic_doctor_mapping?.[0]?.user.distributor_regular;
        const clinicId: string = userData?.clinic_doctor_mapping?.[0]?.clinic_id;
        const clinicRoleId: string  = userData?.clinic_doctor_mapping?.[0]?.user?.clinic_charges?.[0]?.user?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.REGULAR_CLINIC || mapping?.role?.name === Roles.FRANCHISE_CLINIC)?.role?.id;
        const distributorId: string = distributorDesignerData?.[0]?.distributor_id;
        const distributorRoleId: string = distributorDesignerData?.[0]?.distributor_charges?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.SELECTIVE_DISTRIBUTOR || mapping?.role?.name === Roles.EXCLUSIVE_DISTRIBUTOR)?.role?.id;
        const designerId: string =
            distributorDesignerData?.[0]?.distributor_charges?.designer_charges?.[0]?.designer_id;
        const designerRoleId: string = distributorDesignerData?.[0]?.distributor_charges?.designer_charges?.[0]?.userByDesignerId?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.DESIGNER)?.role?.id;
        const superadminId: string =
            distributorDesignerData?.[0]?.distributor_charges?.designer_charges?.[0]?.userByDesignerId
                ?.user_role_mappings?.[0]?.admin_id;
        const superadminRoleId:string = userData?.admin_role_id?.find((mapping) => mapping?.role?.name === Roles.SUPER_ADMIN)?.role?.id;
        const doctorCharges = userData?.clinic_doctor_mapping?.[0]?.doctor_charges || PriceSettingSkuInitialData;
        const doctorRoleId: string = userData?.clinic_doctor_mapping?.[0]?.userByDoctorId?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.DOCTOR)?.role?.id;
        const convertedPriceSettings = Object.fromEntries(
            Object.keys(doctorCharges)?.map((key) => [key, parseFloat(doctorCharges[key])])
        );
        const userDataModified: UsersAndChargesForPaymentBaseData = {
            doctor: {
                user_id: doctorId || '',
                charges: convertedPriceSettings,
                buyer_id: doctorId || '',
                buyer_role_id: doctorRoleId || '',
                seller_id: clinicId || '',
                seller_role_id: clinicRoleId || '',
                cost: 0,
            },
            clinics: {
                user_id: doctorId,
                charges:
                    userData?.clinic_doctor_mapping?.[0]?.user.clinic_charges?.[0]?.user?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.REGULAR_CLINIC || mapping?.role?.name === Roles.FRANCHISE_CLINIC)
                        ?.costs?.[0] || PriceSettingSkuInitialData,
                buyer_id: clinicId || '',
                buyer_role_id: clinicRoleId || '',
                seller_id: distributorId || '',
                seller_role_id: distributorRoleId || '',
                cost: 0,
            },
            distributor: {
                user_id: doctorId,
                charges:
                    distributorDesignerData?.[0]?.distributor_charges?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.SELECTIVE_DISTRIBUTOR || mapping?.role?.name === Roles.EXCLUSIVE_DISTRIBUTOR)?.costs?.[0] ||
                    PriceSettingSkuInitialData,
                buyer_id: distributorId || '',
                buyer_role_id: distributorRoleId || '',
                seller_id: designerId || '',
                seller_role_id: designerRoleId || '',
                cost: 0,
            },
            designer: {
                user_id: doctorId,
                charges:
                    distributorDesignerData?.[0]?.distributor_charges?.designer_charges?.[0]?.userByDesignerId
                        ?.user_role_mappings?.find((mapping) => mapping?.role?.name === Roles.DESIGNER)?.costs?.[0] || PriceSettingSkuInitialData,
                buyer_id: designerId || '',
                buyer_role_id: designerRoleId || '',
                seller_id: superadminId || '',
                seller_role_id: superadminRoleId || '',
                cost: 0,
            },
        };
        return userDataModified;
    };

    /**
     * Loops through each user and prepares entry to be inserted to payment table
     */
    processDataToBeInsertedToPaymentTable = (
        lens_type: string,
        order_type: string,
        order_id: string,
        userData: UsersAndChargesForPaymentBaseData | null
    ): InsertToPaymentForTotalCostAndSalesMutationVariables => {
        const roleId = this._credentialService?.credentials?.roleId
        const paymentInsertPayload: InsertToPaymentForTotalCostAndSalesMutationVariables = {
            paymentInsertPayload: [],
        };
        let paymentEntryCollection: Payment_Insert_Input[] = [];
        paymentInsertPayload.paymentInsertPayload = [...paymentEntryCollection];
        for (const role in userData) {
            if (userData.hasOwnProperty(role)) {
                const roleData = userData[role as keyof UsersAndChargesForPaymentBaseData];
                const charges = roleData?.charges || PriceSettingSkuInitialData;
                const costOfOrder = this.calculatePriceOfOrder(charges, lens_type, order_type);
                let paymentEntry: Payment_Insert_Input = {
                    buyer_id: roleData?.buyer_id,
                    buyer_role_id: roleData?.buyer_role_id,
                    seller_id: roleData?.seller_id,
                    seller_role_id: roleData?.seller_role_id,
                    user_id: roleData?.user_id,
                    type: lens_type,
                    order_id,
                    cost: costOfOrder
                };
                paymentEntryCollection.push(paymentEntry);
            }
        }
        paymentInsertPayload.paymentInsertPayload = [...paymentEntryCollection];
        return paymentInsertPayload;
    };

    /**
     * Calculates cost of order based on user and order params
     * @param charges Price setting skus
     * @param lens_type Whether lens in spherical or toric
     * @param order_type New or Amend or Remake
     * @returns Cost of order
     */
    calculatePriceOfOrder = (charges: PriceSettingsSkuFields, lens_type: string, order_type: string): number => {
        let cost = 0;
        if (lens_type === DesignType.SPHERICAL) {
            cost =
                order_type === Order_Type_Enum.New
                    ? charges.new_spherical || 0
                    : order_type === Order_Type_Enum.Amend
                    ? charges.amend_spherical || 0
                    : order_type === Order_Type_Enum.Remake
                    ? charges.remake_spherical || 0
                    : 0;
        } else if (lens_type === DesignType.TORIC) {
            cost =
                order_type === Order_Type_Enum.New
                    ? charges.new_toric || 0
                    : order_type === Order_Type_Enum.Amend
                    ? charges.amend_toric || 0
                    : order_type === Order_Type_Enum.Remake
                    ? charges.remake_toric || 0
                    : 0;
        }
        return cost;
    };
}
