import { Pipe, PipeTransform } from '@angular/core';

import { FilterTypesEnum } from '@app/core/util/common';
import { Order_Status_Enum } from '@app/generated/graphql';

@Pipe({
    name: 'capitalizeOrderStatus',
})
export class CapitalizeOrderStatusPipe implements PipeTransform {
    transform(value: string, type: string): string {
        if (!value) {
            return value;
        }
        if (value === Order_Status_Enum.Shipped) {
            value = 'Shipped to Country';
        }
        if (type === FilterTypesEnum.ORDER_STATUS_SNAKE_CASE || type === FilterTypesEnum.MANUFACTURER_STATUS_SNAKE_CASE) {
            const words = value?.split('_');
            const capitalizedWords = words.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1));
            return capitalizedWords?.join(' ');
        }
        return value;
    }
}
