<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="mb-2">
                <h3 class="kl-text1-type-1">Terms of Use</h3>
            </div>
        </div>
        <div class="row">
            <div class="terms-and-conditions">
                <p>
                  Welcome to KALCULUS! By accessing and using this platform, you agree to be bound by these Terms of Use. If you find any aspect of these terms disagreeable, we kindly request you refrain from utilizing our platform.
                </p>
                <h6>1.	User Responsibilities</h6>
                <p>
                  •	Users are responsible for maintaining the confidentiality of their account information and for all activities occurring under their account.
                </p>
                <p>
                  •	Users agree not to share their login credentials and to notify KALCULUS immediately of any unauthorized use of their account.
                </p>
                <h6>2.	Information Privacy</h6>
                <p>
                  •	We are committed to protecting your privacy. Your information will be handled according to our Privacy Policy.
                </p>
                <p>
                  •	Users consent to the collection, processing, and storage of their data for the purpose of utilizing our services.
                </p>
                <h6>
                  3.	Use of Platform
                </h6>
                <p>
                  •	Users will not use this platform for any unlawful purpose or engage in any harmful activities such as hacking, data breaches, or distribution of malware.
                </p>
                <p>
                  •	Users agree not to use any automated devices or processes to access, scrape, or index the platform.
                </p>
                <h6>
                  4.	Intellectual Property
                </h6>
                <p>
                  •	All content and materials on this platform are the property of KALCULUS and protected by intellectual property laws. Users may not reproduce, distribute, or modify any content without written permission.
                </p>
                <h6>
                  5.	Liability
                </h6>
                <p>
                  •	KALCULUS is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the platform.
                </p>
                <h6>
                  6.	Service Changes
                </h6>
                <p>
                  •	KALCULUS reserves the right to modify or discontinue, temporarily or permanently, the platform or any part thereof without notice.
                </p>
                <h6>
                  7.	Termination
                </h6>
                <p>
                  •	KALCULUS may terminate or suspend access to our platform immediately, without prior notice or liability, for any reason whatsoever.
                </p>
                <h6>
                  8.	Governing Law
                </h6>
                <p>
                  •	These terms shall be governed and construed in accordance with applicable laws, regulations, and standards in the user's respective territory, without regard to conflicts of law principles.
                </p>
            </div>
            <div class="custom-checkbox d-flex align-items-center">
                <input type="checkbox" id="myCheckbox" (change)="onChangeCheckbox()" />
                <label for="myCheckbox" class="checkbox-label">I agree with the Privacy Policy</label>
            </div>
            <div
                class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center"
                id="sign-in-btn-div"
            >
                <button
                    type="submit"
                    class="kl-btn-primary"
                    id="sign-in-action-btn"
                    [disabled]="!isEnabled"
                    (click)="onSignIn()"
                >
                    Sign in
                </button>
            </div>
        </div>
    </div>
</div>
