import { GetFranchiseClinicFormDataQuery, GetListOfFranchiseClinicsQuery } from "@app/generated/graphql";
import { User } from "@app/shared/models/shared.model";

export enum ActionType {
    getFranchiseClinicsListBasedOnRole = '[Franchise clinic] Get franchise clinic list',
    getFranchiseClinicsListBasedOnRoleSuccess = '[Franchise clinic] Get franchise clinic list success',
    getFranchiseClinicListCount= '[Franchise clinic] Get total count of franchise clinics',
    getFranchiseClinicListCountSuccess= '[Franchise clinic] Update franchise clinic list total count to state',
    getExclusiveDistributorList = '[Franchise Clinic] get exclusive distributor list',
    getExclusiveDistributorListSuccess = '[Franchise Clinic] get exclusive distributor list success',
    onboardFranchiseClinic = '[Franchise Clinic] Onboard franchise clinic',
    onboardFranchiseClinicSuccess = '[Franchise Clinic] Onboard franchise clinic success',
    getFranchiseClinicFormData = '[Franchise Clinic] get Franchise Clinic form data',
    getFranchiseClinicFormDataSuccess = '[Franchise Clinic] get Franchise Clinic form data success',
}
export interface FranchiseClinicListData {
    id: string;
    name: string;
    contact_number: string;
    registration_number: string;
    email_id: string;
    distributor_name?: string;
}
export const initialState: State = {
    exclusiveDistributorList: null,
    franchiseClinicFormDataDetails: { user_role_mapping: [], user: [] },
    franchiseClinicTotalCount: 0,
    franchiseClinicListData: {
      superadmin: [],
      distributor: [],
      designer: []
    },
    deletedFranchiseClinic:true
  }

  export interface State {
    exclusiveDistributorList: User[] | null,
    franchiseClinicFormDataDetails: GetFranchiseClinicFormDataQuery,
    franchiseClinicTotalCount: number;
    franchiseClinicListData: GetListOfFranchiseClinicsQuery;
    deletedFranchiseClinic:boolean
  }

export interface OnboardingUpdateFranchiseClinicUserDetails {
  email?: string,
  contact_number: string,
  country_code: string,
  updated_by: string
}

export interface OnboardingUpdateFranchiseClinicUserRoleDetails {
  name: string,
  role_details: OnboardingFranchiseRoleDetails
}

export interface OnboardingFranchiseRoleDetails {
    address: string,
    distributor: string,
    registration_number: string
}
