import { Order_Status_Enum } from "@app/generated/graphql";

export enum ActionType {
  getListOfOrdersOfPatient = '[Patient Profile] Get list of orders of a patient',
  getListOfOrdersOfPatientSuccess = '[Patient Profile] List of orders of a patient fetched successfully',
  getInUseLenseDetails = '[Patient Profile] Get in use lense details',
  resetPatientProfileState = '[Patient Profile] Reset patient profile state'
}

export interface State {
  orderList: OrderListItem[] | null;
  inUseLenseDetails: InUseLensePerEye[] | null;
}

export const initialState: State = {
  orderList: null,
  inUseLenseDetails: null,
}

export interface OrderListItem {
  id: string;
  orderId: string;
  date: string;
  status: Order_Status_Enum;
}

export interface InUseLensePerEye {
  orderSequence: number;
  status: Order_Status_Enum;
  labFormJson: any;
  side: string
}

export interface InUseLenseUIStateInPatientInfo {
  label: string;
  leftEye: number | string | null;
  rightEye: number | string | null;
}
