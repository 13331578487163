import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { OrderDetailsFormTypes } from '@app/orders/models/orders.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { UserPermissionsData } from '@app/ui/models/ui.model';
import { getLoggedInUserPermissions } from '@app/ui/selectors/ui.selector';

@Component({
    selector: 'app-view-details-selection',
    templateUrl: './view-details-selection.component.html',
    styleUrls: ['./view-details-selection.component.scss'],
})
export class ViewDetailsSelectionComponent implements OnInit {
    detailViewSelectionForm!: FormGroup;
    rolePermissions$ = new Observable<UserPermissionsData>();
    @Output() toggleFormTypes: EventEmitter<OrderDetailsFormTypes> = new EventEmitter<OrderDetailsFormTypes>();

    constructor(
        private _fb: FormBuilder,
        private _store: Store
    ) {}

    ngOnInit(): void {
        this.initSelectionForm();

        // fetch role permissions
        this.rolePermissions$ = this._store.pipe(
            select(getLoggedInUserPermissions)
        );
    }

    /**
     * Initialize form
     */
    initSelectionForm = (): void => {
        this.detailViewSelectionForm = this._fb.group({
            selectedOption: [''],
        });
    };

    /**
     * Based on selection, determines what data to see
     * @param event radio option value
     */
    onSelectFormType = (event: string): void => {
        if (event === OrderDetailsFormTypes.LABFORM) {
            this.detailViewSelectionForm.controls['selectedOption'].setValue(event);
            this.toggleFormTypes.emit(OrderDetailsFormTypes.LABFORM);
        }
        else if (event === OrderDetailsFormTypes.DOCTOR_SLIP) {
            this.detailViewSelectionForm.controls['selectedOption'].setValue(event);
            this.toggleFormTypes.emit(OrderDetailsFormTypes.DOCTOR_SLIP);
        }
        else {
            this.detailViewSelectionForm.controls['selectedOption'].setValue(event);
            this.toggleFormTypes.emit(OrderDetailsFormTypes.VIEW_DESIGN);
        }
    };
}
