import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription, map, takeWhile } from 'rxjs';

import { Roles } from '@app/core/util/common';
import { CommonService } from '@app/core/services/common.service';
import { SelectedRoleDetails } from '@app/auth/models/auth.model';
import { GetRolesBasedOnUserIdQuery } from '@app/generated/graphql';
import { CredentialsService } from '@app/core/services/credentials.service';
import { getUserIdOfLoggedInUser } from '@app/shared/selectors/shared.selector';
import { fetchAllRolesOfUser, roleSelectionInitiate } from '@app/auth/actions/auth.action';
import { availableUserRoles, showClinicSelection } from '@app/auth/selectors/auth.selector';
import { clearBreadCrumpUrlCollection } from '@app/ui/actions/ui.actions';
import { UserRole } from '@app/shared/models/shared.model';

@Component({
    selector: 'app-user-role',
    templateUrl: './user-role.component.html',
    styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent implements OnInit, OnDestroy {
    isAlive = true;
    isDoctor = false;
    isRoleSelctionActive = false;
    role!: SelectedRoleDetails;
    userId: string | undefined = '';
    availableRoles!: UserRole[];
    roleSelectionForm: FormGroup = new FormGroup({});
    userIdData$ = new Observable<string>();
    availableRoles$ = new Observable<UserRole[]>();
    getUserIdSubscription$ = new Subscription();
    getLoginSubscription$ = new Subscription();
    availableRoleSubscription$ = new Subscription();

    @Output() closeChangeUserRoleForm = new EventEmitter<boolean>();

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _credentialService: CredentialsService,
        private _commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.createRoleSelectionForm();
        this.getUserIdSubscription$ = this._store
            .select(getUserIdOfLoggedInUser)
            .subscribe((userId: string | undefined) => {
                this.userId = userId;
                if (this.userId) {
                    this._store.dispatch(fetchAllRolesOfUser(this.userId));
                }
            });
        this.availableRoles$ = this._store.pipe(
            takeWhile(() => this.isAlive),
            select(availableUserRoles),
            map(roles => {
              let rolesOut:UserRole[] = [];
              if(roles?.user_role_mapping && Array.isArray(roles.user_role_mapping)){
                rolesOut = roles?.user_role_mapping?.map((role) => {
                  return {
                    id: role?.id || '',
                    role: {
                      id: role?.role?.id || '',
                      name: role?.role?.name || ''
                    }
                  }
                })
                rolesOut = rolesOut?.filter(role => role?.role?.name !== Roles.CARE_TAKER);
              }
              return rolesOut;
            })
        );

        this.availableRoleSubscription$ = this.availableRoles$.subscribe((roles: UserRole[]) => {
            this.availableRoles = roles;
        });
    }

    createRoleSelectionForm = (): void => {
        this.roleSelectionForm = this._fb.group({
            userRole: ['', Validators.required],
        });
    };

    get roleSelected(): AbstractControl<any, any> | null {
        return this.roleSelectionForm.get('userRole');
    }

    isDirty = (name: string): boolean => {
        return this.roleSelectionForm.controls[name]?.dirty;
    };

    isTouched = (name: string): boolean => {
        return this.roleSelectionForm.controls[name].touched;
    };

    isRequired = (name: string): boolean => {
        return this.roleSelectionForm.controls[name]?.errors?.['required'];
    };

    onChangeRole(e: any): void {
        this.roleSelected?.setValue(e.target.value, {
            onlySelf: true,
        });
        this.role = this.getRoleId(this.roleSelected?.value);
    }

    onCloseChangeUserRoleModal = (): void => {
        this.closeChangeUserRoleForm.emit(true);
    };

    getRoleId = (selectedRoleMappingId: string): SelectedRoleDetails => {
        const selectedRole = this.availableRoles?.find(
            (role: UserRole) => selectedRoleMappingId === role?.id
        );
        return selectedRole?.role as SelectedRoleDetails;
    };

    getActualRoleName = (roleValue: string | undefined): string | void => {
        return this._credentialService.getActualRoleNameForDisplay(roleValue);
    };

    onSubmit = (): void => {
        if (this.roleSelectionForm.valid) {
            const selectedRoleDetails: {
                selectedRole: string;
                role: SelectedRoleDetails;
            } = {
                selectedRole: this.roleSelected?.value,
                role: this.role,
            };
            this._store.dispatch(roleSelectionInitiate(selectedRoleDetails, true));
            if (this.role.name === Roles.DOCTOR) {
                this.clinicSelectionAfterLogin();
            } else {
                this.onCloseChangeUserRoleModal();
            }
            this._commonService.onSwitchRoles();
            this._store.dispatch(clearBreadCrumpUrlCollection());
        }
    };
    clinicSelectionAfterLogin = (): void => {
        this.getLoginSubscription$ = this._store.select(showClinicSelection).subscribe((value: boolean) => {
            this.isDoctor = value;
        });
    };

    ngOnDestroy() {
        this.isAlive = false;
        this.isDoctor = false;
        this.getUserIdSubscription$.unsubscribe();
        this.availableRoleSubscription$.unsubscribe();
        if(this.getLoginSubscription$){
          this.getLoginSubscription$.unsubscribe();
        }
    }
}
