<div [ngClass]="{'p-5': isModal}" class="position-relative">
    <img
        *ngIf="isModal"
        class="close-icon cursor-pointer position-absolute"
        src="/assets/icons/navbar-profile/popup-close.png"
        (click)="onCloseModal()"
    />
    <h2 class="sign-in kl-h6-type-1">Family Members</h2>
    <form class="mt-4" [formGroup]="siblingSelectionForm" (ngSubmit)="onSubmit()">
        <div>
            <div>
                <select class="form-select kl-input-text" formControlName="sibling">
                    <option value="" disabled selected>Select a member</option>
                    <option
                        [disabled]="member.clinicType === 'regular_clinic'"
                        *ngFor="let member of familyMembers"
                        [value]="member.id"
                    >
                        {{ member.name }}
                    </option>
                </select>
            </div>
            <div class="error-message-container-common">
                <p class="error-text" *ngIf="(isDirty('sibling') || isTouched('sibling')) && isRequired('sibling')">
                    Please select a member
                </p>
            </div>
        </div>
        <div class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center">
            <button type="submit" class="kl-btn-primary" id="sign-in-action-btn">Sign In</button>
        </div>
    </form>
</div>
