<div class="container">
    <div class="row label-row">
        <div class="col-6 p-0">
            <span class="kl-text3-type-6">Patient Info</span>
        </div>
    </div>
    <div 
        class="row patient-details-row" 
        *ngFor="let patientDetail of patientInfo$ | async"
    >
        <div class="col-6 p-0">
            <span class="kl-text5-type-6">{{ patientDetail.fieldName }}</span>
        </div>
        <div class="col-1 p-0">
            <span class="kl-text5-type-6">:</span>
        </div>
        <div class="col-5 p-0">
            <span class="kl-text5-type-7">{{ patientDetail.fieldValue }}</span>
        </div>
    </div>
</div>