<div class="modal-header justify-content-end">
    <img 
        class="close-icon cursor-pointer" 
        src="/assets/icons/navbar-profile/popup-close.png" 
        (click)="onCloseChangePwdModal()" 
    />
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="change-pwd-head mb-4">
                <h3 class="kl-text1-type-1">Change password</h3>
                <p 
                    class="kl-h5-type-3" 
                >
                    Enter your new password
                </p>
            </div>
            <div class="change-pwd-form-container">
                <form 
                    class="change-pwd-form" 
                    id="cpw-form-element" 
                    [formGroup]="changePasswordForm"
                >
                    <!-- New and Confirm password div section -->
                    <div 
                        class="new-password-input-container"
                    >
                        <div class="password-container mb-3" id="cpw-new-pwd-div">
                            <label for="cpw-new-password" class="kl-text2-type-1 mb-2"> New password </label>
                            <div class="input-with-icon-type-1" id="cpw-icon-container">
                                <input
                                    [type]="showNewPassword ? 'text' : 'password'"
                                    formControlName="newPassword"
                                    class="kl-input-text input-text-type-1"
                                    placeholder="New password"
                                    id="cpw-new-password"
                                />
                                <i 
                                    class="bi bi-eye input-icon to-right gray-80 cursor-pointer" 
                                    (click)="onToggleNewPassword()"
                                > 
                                </i>
                            </div>
                            <div class="error-message-container-common">
                                <p
                                    class="error-text"
                                    *ngIf="isRequired('newPassword') && 
                                        (isDirty('newPassword') || isTouched('newPassword'))"
                                >
                                    Password is required
                                </p>
                                <p 
                                    class="error-text d-flex align-items-center" 
                                    *ngIf="isPasswordStrong('newPassword') && !isRequired('newPassword')"
                                >
                                    Please enter a strong password 
                                    <app-password-criteria-popover></app-password-criteria-popover>
                                </p>
                                <p 
                                    class="error-text" 
                                    *ngIf="!isRequired('newPassword') && 
                                        isTouched('newPassword') &&
                                        isMaxLengthError('newPassword')"
                                >
                                    Password should not be greater than 128 characters
                                </p>
                            </div>
                        </div>
                        <div class="new-password-container" id="cpw-new-password-contain-div">
                            <label for="cpw-confirm-password" class="kl-text2-type-1 mb-2"> Confirm password </label>
                            <div class="input-with-icon-type-1" id="cpw-password-with-icon-div">
                                <input
                                    [type]="showConfirmPassword ? 'text' : 'password'"
                                    formControlName="confirmPassword"
                                    class="kl-input-text input-text-type-1"
                                    placeholder="Confirm password"
                                    id="cpw-confirm-password"
                                />
                                <i 
                                    class="bi bi-eye input-icon to-right gray-80 cursor-pointer" 
                                    (click)="onToggleConfirmPassword()"
                                > 
                                </i>
                            </div>
                            <div class="error-message-container-common">
                                <p
                                    class="error-text"
                                    *ngIf="
                                        isRequired('confirmPassword') && (isDirty('confirmPassword') || 
                                            isTouched('confirmPassword'))
                                    "
                                >
                                    Password is required
                                </p>
                                <p
                                    class="error-text"
                                    *ngIf="
                                        changePasswordForm.errors &&
                                        changePasswordForm.errors['mismatch'] &&
                                        isTouched('confirmPassword') &&
                                        !isRequired('newPassword') &&
                                        !isRequired('confirmPassword')
                                    "
                                >
                                    Passwords do not match
                                </p>
                                <p 
                                    class="error-text" 
                                    *ngIf="!isRequired('confirmPassword') && 
                                        isTouched('confirmPassword') &&
                                        isMaxLengthError('confirmPassword')"
                                >
                                    Password should not be greater than 128 characters
                                </p>
                            </div>
                        </div>
                        <div 
                            class="auth-form-btn-container mt-5 mb-sm-2 mb-md-2 mb-lg-2 d-flex justify-content-center" 
                            id="cpw-reset-pwd-btn-div"
                        >
                            <button 
                                type="submit" 
                                class="kl-btn-primary" 
                                id="cpw-final-action" 
                                [disabled]="!changePasswordForm.valid"
                                (click)="onUpdateWithNewPassword()"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
