import { Roles } from './common';
import { LensColorSelect } from '@app/lenses/models/lenses.model';
import { Column, ElementType } from '@app/shared/models/user-table-list.model';
import { PriceSettingsSkuFields } from '@app/shared/models/user-price-settings.model';
import { DistributorCommonDetailsForManufacturer, FooterDropdownItems } from '@app/shared/models/shared.model';
import { Manufacturer_Status_Enum, Order_Status_Enum, Order_Type_Enum } from '@app/generated/graphql';

export const patientListHeadingInitial: Array<Column> = [
    {
        columnDef: 'patient_name',
        header: 'Patient Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'reference_number',
        header: 'Reference Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'email_id',
        header: 'Email ID',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
];

export const patientTransferRequestsHeadingInitial: Array<Column> = [
    {
        columnDef: 'patient_name',
        header: 'Patient Name',
        isLink: true,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'reference_number',
        header: 'Reference Number',
        isLink: false,
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'email_id',
        header: 'Email ID',
        isLink: false,
        isSortable: false,
        elementType: ElementType.span
    },
];

export const manufacturerOrderListHeadingsUnderDistributor: Array<Column> = [
    {
        columnDef: 'order_id',
        header: 'Order ID',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'created_at',
        header: 'Order Date',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'first_name',
        header: 'Patient First Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'last_name',
        header: 'Patient Last Name',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'reference_number',
        header: 'Patient Reference No.',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'rightOrLeft',
        header: 'Right/Left',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'order_sequence',
        header: 'Order Sequence',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'order_type',
        header: 'Order Type',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'design_type',
        header: 'Design Type',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'manufacturer_status',
        header: 'Status',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
];

export const selectiveDistributorListHeadings: Array<Column> = [
    {
        columnDef: 'name',
        header: 'Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'address',
        header: 'Address',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'created_at',
        header: 'Created Date',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'device_licence_no',
        header: 'Import License No',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'distributor_licence_number',
        header: 'Distributor License No',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
];

export const partnerListTableMetaData: Array<Column> = [
    {
        columnDef: 'name',
        header: 'Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'distributor_name',
        header: 'Distributor',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'registration_number',
        header: 'Registration Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'count',
        header: 'Practitioners',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
];

export const partnerListForDistributorTableMetaData: Array<Column> = [
    {
        columnDef: 'name',
        header: 'Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'distributor_name',
        header: 'Distributor',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'count',
        header: 'Practitioners',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'order',
        header: 'Orders',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
];

export const doctorListHeadingMetaData: Array<Column> = [
    {
        columnDef: 'name',
        header: 'Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'email',
        header: 'Email',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'created_at',
        header: 'Created Date',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
];

export const contractManufacturerListMetaData: Array<Column> = [
    {
        columnDef: 'name',
        header: 'Name',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'contact_number',
        header: 'Contact Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'address',
        header: 'Address',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
    {
        columnDef: 'created_at',
        header: 'Created Date',
        isLink: false,
        url: 'string',
        isSortable: true,
        elementType: ElementType.span
    },
    {
        columnDef: 'registration_number',
        header: 'Registration Number',
        isLink: false,
        url: 'string',
        isSortable: false,
        elementType: ElementType.span
    },
];

export const manufacturerStatusValues = [
  Manufacturer_Status_Enum.Delayed,
  Manufacturer_Status_Enum.Manufactured,
  Manufacturer_Status_Enum.Recieved,
  Manufacturer_Status_Enum.Shipped
]

export const orderStatusValues = [
    Order_Status_Enum.Submitted,
    Order_Status_Enum.Ordered,
    Order_Status_Enum.Shipped,
    Order_Status_Enum.OutForDelivery,
    Order_Status_Enum.Delivered,
    Order_Status_Enum.Cancelled,
];

export const orderTypeValues = [
    Order_Type_Enum.New,
    Order_Type_Enum.Amend,
    Order_Type_Enum.Spare,
    Order_Type_Enum.Remake,
];

export const filterValues: { orderType: Order_Type_Enum[]; orderStatus: Order_Status_Enum[], manufacturerStatus: Manufacturer_Status_Enum[] } = {
    orderType: orderTypeValues,
    orderStatus: orderStatusValues,
    manufacturerStatus: manufacturerStatusValues
};

export interface ControlDataItem {
    type: string;
    valueType?: string;
    id: string;
    label: string;
    placeholder: string;
    options?: { id: string; name: string }[];
    callApi?: boolean;
}
export interface ControlDataType {
    [key: string]: ControlDataItem[];
}

export const grantMultipleRoleFormControlsData: ControlDataType = {
    [Roles.DESIGNER]: [
        {
            type: 'input',
            id: 'lens_brand_name',
            label: 'Lens Brand Name',
            placeholder: 'Enter Lens Brand Name',
        },
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
    ],
    [Roles.EXCLUSIVE_DISTRIBUTOR]: [
        {
            type: 'input',
            id: 'device_licence_no',
            label: 'Device Licence Number',
            placeholder: 'Enter Device Licence Number',
        },
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
        {
            type: 'input',
            id: 'distributor_licence_number',
            label: 'Distributor Licence Number',
            placeholder: 'Enter Distributor Licence Number',
        },
        {
            type: 'select',
            id: 'designer',
            label: 'Designer',
            placeholder: 'Select Designer',
            options: [],
            callApi: true,
        },
    ],
    [Roles.SELECTIVE_DISTRIBUTOR]: [
        {
            type: 'input',
            id: 'device_licence_no',
            label: 'Device Licence Number',
            placeholder: 'Enter Device Licence Number',
        },
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
        {
            type: 'input',
            id: 'distributor_licence_number',
            label: 'Distributor Licence Number',
            placeholder: 'Enter Distributor Licence Number',
        },
        {
            type: 'select',
            id: 'designer',
            label: 'Designer',
            placeholder: 'Select Designer',
            options: [],
            callApi: true,
        },
    ],

    [Roles.FRANCHISE_CLINIC]: [
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
        {
            type: 'select',
            id: 'distributor_type',
            label: 'Distributor Type',
            placeholder: 'Select Distributor Type',
            options: [
                { id: Roles.EXCLUSIVE_DISTRIBUTOR, name: 'Exclusive Distributor' },
                { id: Roles.SELECTIVE_DISTRIBUTOR, name: 'Selective Distributor' },
            ],
        },
    ],

    [Roles.REGULAR_CLINIC]: [
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
        {
            type: 'select',
            id: 'distributor_type',
            label: 'Distributor Type',
            placeholder: 'Select Distributor Type',
            options: [
                { id: Roles.EXCLUSIVE_DISTRIBUTOR, name: 'Exclusive Distributor' },
                { id: Roles.SELECTIVE_DISTRIBUTOR, name: 'Selective Distributor' },
            ],
        },
    ],

    [Roles.DOCTOR]: [
        {
            type: 'input',
            id: 'professional_license_number',
            label: 'Professional License Number',
            placeholder: 'Enter Professional License Number',
        },
    ],

    [Roles.CONTRACT_MANUFACTURER]: [
        {
            type: 'input',
            id: 'registration_number',
            label: 'Registration Number',
            placeholder: 'Enter Registration Number',
        },
        {
            type: 'input',
            id: 'manufacturing_licence_no',
            label: 'Manufacturing Licence Number',
            placeholder: 'Enter Manufacturing Licence Number',
        },
        {
            type: 'input',
            valueType: 'number',
            id: 'warranty_period',
            label: 'Warranty Period',
            placeholder: 'Enter Warranty period',
        },
        {
            type: 'select',
            id: 'distributor_type',
            label: 'Distributor Type',
            placeholder: 'Select Distributor Type',
            options: [
                { id: Roles.EXCLUSIVE_DISTRIBUTOR, name: 'Exclusive Distributor' },
                { id: Roles.SELECTIVE_DISTRIBUTOR, name: 'Selective Distributor' },
            ],
        },
    ],
};

export const grantMultipleRoleFormControlsDataDistributorMapping: ControlDataType = {
    [Roles.EXCLUSIVE_DISTRIBUTOR]: [
        {
            type: 'select',
            id: Roles.DISTRIBUTOR_PLACEHOLDER,
            label: 'Exclusive Distributor',
            placeholder: 'Select Exclusive Distributor',
            options: [],
            callApi: true,
        },
    ],
    [Roles.SELECTIVE_DISTRIBUTOR]: [
        {
            type: 'select',
            id: Roles.DISTRIBUTOR_PLACEHOLDER,
            label: 'Selective Distributor',
            placeholder: 'Select Selective Distributor',
            options: [],
            callApi: true,
        },
    ],
};
export const commonColors: LensColorSelect[] = [
    { colorName: 'Red', code: '#FF0000' },
    { colorName: 'Blue', code: '#0000FF' },
    { colorName: 'Green', code: '#008000' },
    { colorName: 'Yellow', code: '#FFFF00' },
    { colorName: 'Orange', code: '#FFA500' },
    { colorName: 'Purple', code: '#800080' },
    { colorName: 'Pink', code: '#FFC0CB' },
    { colorName: 'Gray', code: '#808080' },
    { colorName: 'White', code: '#FFFFFF' },
    { colorName: 'Black', code: '#000000' },
    { colorName: 'Violet', code: '#7F00FF' },
];

export const distributorCommonDetailsInitialData: DistributorCommonDetailsForManufacturer = {
    name: '',
    email: '',
    contact_number: '',
    country_code: '',
    address: '',
    device_licence_no: '',
    distributor_licence_number: '',
    registration_number: '',
};
export enum OrderStatusColor {
    Cancelled = 'red',
    Delivered = '#52AAEB',
    DraftSaved = '#EDC700',
    Ordered = '#52aaeb',
    OutForDelivery = '#65BE3B',
    Shipped = '#db9600',
    Submitted = '#65be3b',
    Received = '#52aaeb',
    Manufactured = '#EDC700',
    Delayed = '#a80808',
    Default = '#000',
}

export const orderStatus: Order_Status_Enum[] = [
  Order_Status_Enum.Submitted,
  Order_Status_Enum.Ordered,
  Order_Status_Enum.Shipped,
  Order_Status_Enum.OutForDelivery,
  Order_Status_Enum.Delivered,
  Order_Status_Enum.Cancelled
];

export const manufacturerStatus: Manufacturer_Status_Enum[] = [
  Manufacturer_Status_Enum.Manufactured,
  Manufacturer_Status_Enum.Shipped,
  Manufacturer_Status_Enum.Delayed
];

export const PriceSettingSkuInitialData: PriceSettingsSkuFields = {
    new_spherical: 0,
    new_toric: 0,
    amend_spherical: 0,
    amend_toric: 0,
    remake_spherical: 0,
    remake_toric: 0,
};


export const FormControlNameDesigner = {
  name: '',
  registration_number: '',
  lens_brand_name: '',
  address: ''
};

export const FormControlNameDistributor = {
  name: '',
  registration_number: '',
  lens_brand_name: '',
  address: '',
  device_licence_no: '',
  distributor_licence_number: ''
};

export const FormControlNameManufacturer = {
  register_number: '',
  companyName: '',
  address: '',
  license_number: ''
};

export const FormControlNameRegularClinic = {
  name: '',
  register_number: '',
  address: ''
};

export const FormControlNameFranchiseClinic = {
  clinicName: '',
  register_number: '',
  address: ''
};

export const FormControlNameDoctor = {
  first_name: '',
  last_name: '',
  license_number: ''
};

export const FormControlNamePatient = {
  first_name: '',
  last_name: '',
  reference_number: '',
  nationality: '',
  unitNumber: '',
  streetAddress: '',
  city: '',
  country: '',
  postalCode: '',
  name: '',
  relationship: '',
  language: ''
}

export const patientModuleActiveItemList: FooterDropdownItems[] = [
  {value: "Patients", key: "patients", isSelected: true},
  {value: "Old Patients", key:"oldPatients", isSelected: false}
];


