<div class="modal-body">
  <div class="container-fluid">
      <div class="row">
        <div class="select-role-head mb-4">
          <h3 class="kl-text1-type-1">Partner selection</h3>
      </div>
    <div class="select-role-form-container">
      <form
        class="role-selection-form mt-4"
        [formGroup]="clinicSelectionForm"
        (ngSubmit)="onSubmit()"
        >
          <div class="role-selection-container" id="select-role-div">
              <div
                  class="select-role-dropdown"
              >
                  <select
                      class="form-select kl-input-text line-height"
                      formControlName="clinic"
                  >
                     
                      <option value="" disabled selected hidden >Select a partner</option>
                      <option 
                        *ngFor="let clinic of availableClinics$ | async" 
                        [value]="clinic?.clinicId"
                    >
                        {{ clinic.clinicName }}
                    </option>
                      
                  </select>
              </div>
              <div class="error-message-container-common">
                <p
                    class="error-text"
                    *ngIf="(isDirty('clinic') || 
                        isTouched('clinic')) && 
                        isRequired('clinic')"
                >
                    Please select a partner
                </p>
            </div>
          </div>
          <div
              class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center"
              id="sign-in-btn-div"
          >
              <button
                  type="submit"
                  class="kl-btn-primary"
                  id="sign-in-action-btn"
                  [disabled]="!clinicSelectionForm.valid"
              >
                  Continue
              </button>
          </div>
     </form>
    </div>
  </div>
</div>

