import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlePopupService {
  otpSendSuccess$ = new BehaviorSubject({type: '', isOpen: false});
  changePasswordPopupClose$ = new Subject<boolean>();

  constructor() { }

  /**
   * Adds a value to observable stream
   * @param value The boolean value to be passed to stream
   * @returns void
   */
  setOtpStatus = (type: string, isOpen: boolean): void => {
    this.otpSendSuccess$.next({type, isOpen});
  };

  /**
   * Returns an observable which on subscription tells whether to open or close popup
   * @param Nil
   * @returns Observable of boolean value
   */
  getOtpStatus = (): Observable<{type: string, isOpen: boolean}> => {
    return this.otpSendSuccess$.asObservable();
  };

  /**
   * Adds a value to observable stream
   * @param value The boolean value to be passed to stream
   * @returns void
   */
  closeChangePasswordPopup = (value: boolean): void => {
    this.changePasswordPopupClose$.next(value);
  };

  /**
   * Returns an observable which on subscription tells whether to open or close popup
   * @param Nil
   * @returns Observable of boolean value
   */
  changePasswordCloseStatus = (): Observable<boolean> => {
    return this.changePasswordPopupClose$.asObservable();
  };
}
