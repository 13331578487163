import { createAction } from '@ngrx/store';

import { GetDoctorInfoQuery, GetDoctorInfoQueryVariables, GetDoctorsListBasedOnRoleQueryVariables, GetTotalTableItemCountForDoctorsQueryVariables, GetUserListBasedOnRoleQuery, GetUserListBasedOnRoleQueryVariables, OnboardDoctorMutationVariables, SaveClinicDetailsOfDoctorsMutation, SaveClinicDetailsOfDoctorsMutationVariables } from '@app/generated/graphql';
import { ActionType, DoctorListData } from '../models/doctors.model';
import { User } from '@app/shared/models/shared.model';

// Doctor onboarding, editing related actions
export const getFranchiseClinicList = createAction(
    ActionType.getFranchiseClinicList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getFranchiseClinicListSuccess = createAction(
    ActionType.getFranchiseClinicListSuccess,
    (franchiseClinicList: User[] | null) => ({ franchiseClinicList })
);
export const getRegularClinicList = createAction(
    ActionType.getRegularClinicList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getRegularClinicListSuccess = createAction(
    ActionType.getRegularClinicListSuccess,
    (regularClinicList: User[] | null) => ({ regularClinicList })
);
export const onboardDoctor = createAction(
    ActionType.onboardDoctor,
    (doctorDetails: OnboardDoctorMutationVariables) => ({
        doctorDetails,
    })
);
export const getDoctorFormData = createAction(
    ActionType.getDoctorFormData,
    (userId: GetDoctorInfoQueryVariables) => ({ userId })
);

export const getDoctorFormDataSuccess = createAction(
    ActionType.getDoctorFormDataSuccess,
    (doctorDetails: GetDoctorInfoQuery) => ({ doctorDetails })
);

// Update clinic details of doctors.
export const saveClinicDetailsOfDoctors = createAction(
    ActionType.saveClinicDetailsOfDoctors,
    (clinicDetailsParams: SaveClinicDetailsOfDoctorsMutationVariables) => ({ clinicDetailsParams })
)
export const saveClinicDetailsOfDoctorsSuccess = createAction(
    ActionType.saveClinicDetailsOfDoctorsSuccess,
    (clinicDetails: SaveClinicDetailsOfDoctorsMutation) => ({ clinicDetails })
)
//   Doctor listing, total table items count related actions
export const getDoctorTableList = createAction(
    ActionType.getDoctorTableList,
    (doctorListParams: GetDoctorsListBasedOnRoleQueryVariables) => ({ doctorListParams })
);
export const getDoctorTableListSuccess = createAction(
    ActionType.getDoctorTableListSuccess,
    (doctorList: DoctorListData[]) => ({ doctorList })
);
export const getDoctorTableListFailure = createAction(
    ActionType.getDoctorTableListFailure,
    (doctorList) => ({ doctorList })
);

export const getTotalTableItemsCount = createAction(
    ActionType.getTotalTableItemsCount,
    (countListParams: GetTotalTableItemCountForDoctorsQueryVariables) => ({ countListParams })
);
export const getTotalTableItemsCountSuccess = createAction(
    ActionType.getTotalTableItemsCountSuccess,
    (tableItemCount: number) => ({ tableItemCount })
);
