import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';
import { Router } from '@angular/router';

import { OnSendOtpBothServiceMutationVariables } from '@app/generated/graphql';
import { sendOtpToBothServices } from '@app/auth/actions/auth.action';
import { Url } from '@app/core/util/common';
import { getUserIdFromInitialLogin } from '@app/auth/selectors/auth.selector';

@Component({
    selector: 'app-otp-service-selection',
    templateUrl: './otp-service-selection.component.html',
    styleUrls: ['./otp-service-selection.component.scss'],
})
export class OtpServiceSelectionComponent implements OnInit, OnDestroy {
    userDetails$ = new Observable<{}>();
    isAlive = true;
    userId!: string;
    userData$ = new Observable<string>();
    subscription$ = new Subscription();

    constructor(
        private _store: Store, 
        private _router: Router    
    ) {
        this.userData$ = this._store.select(getUserIdFromInitialLogin);
        this.subscription$ = this.userData$.pipe(
            takeWhile(() => this.isAlive))
            .subscribe((userId: string) => {
                this.userId = userId;
            });
    }

    ngOnInit(): void {}

    /**
     * Send otp to phone number of user
     * @param Nil
     * @returns void
     */
    onSendToPhone = (): void => {
        const data: OnSendOtpBothServiceMutationVariables = { payload: { method: 'sms', userId: this.userId } };
        this._store.dispatch(sendOtpToBothServices(data));
    };

    /**
     * Send otp to email of user
     * @param Nil
     * @returns void
     */
    onSendToEmail = (): void => {
        const data: OnSendOtpBothServiceMutationVariables = { payload: { method: 'email', userId: this.userId } };
        this._store.dispatch(sendOtpToBothServices(data));
    };

    /**
     * Go back to login page
     * @param Nil
     * @returns void
     */
    goBackToPreviousPage = (): void => {
        this._router.navigateByUrl(Url.LOGIN);
    };

    ngOnDestroy(): void {
        this.isAlive = false;
        this.subscription$.unsubscribe();
    }
}
