import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AuthService } from '@app/core/services/auth.service';
import { EncryptService } from '@app/core/services/encrypt.service';
import { OnLoginMutationVariables } from '@app/generated/graphql';
import { getUserIdFromInitialLogin } from '@app/auth/selectors/auth.selector';
import { fetchAllRolesOfUser, login } from '@app/auth/actions/auth.action';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm!: FormGroup;
    error: string | undefined;
    isAlive = true;
    isLoading = false;
    showPassword = false;
    showRoleSelectionUI = false;
    isRoleSelctionActive = false;
    userId = '';
    userIdData$ = new Observable<string>();
    subscription$ = new Subscription();
    routeParamSubscription$ = new Subscription();

    constructor(
        private _fb: FormBuilder,
        private _router: Router,
        private _store: Store,
        private _routeParams: ActivatedRoute,
        private _encryptService: EncryptService,
        private _authService: AuthService
    ) {
        this.createForm();
        this.userIdData$ = this._store.select(getUserIdFromInitialLogin);
        this.subscription$ = 
            this.userIdData$
            .subscribe((userId: string) => {
                this.userId = userId;
            });
    }

    ngOnInit(): void {

        // if role selection ui is to be shown, fetch all roles for that user
        // if on login page clear state and remove key from storage
        this.routeParamSubscription$ = this._routeParams.data.subscribe((roleSelection: any) => {
            this.isRoleSelctionActive = roleSelection?.isRoleSelection;
            if (this.isRoleSelctionActive) {
                this._store.dispatch(fetchAllRolesOfUser(this.userId));
            }
            else {
                this._authService.clearAllUserDetailsAndLogOut();
            }
        });
    }

    /**
     * Create login form and controls
     * @param
     * @returns void
     */
    createForm = (): void => {
        this.loginForm = this._fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.maxLength(128)]],
            remember: [true],
        });
    };

    /**
     * Checks if the control is valid
     * @param name of the form control
     * @returns boolean
     */
    isValid = (name: string): boolean => {
        return this.loginForm.controls[name].valid;
    };

    /**
     * Checks if the control is touched
     * @param name of the form control
     * @returns boolean
     */
    isTouched = (name: string): boolean => {
        return this.loginForm.controls[name].touched;
    };

    /**
     * Checks if the control is dirty
     * @param name of the form control
     * @returns boolean
     */
    isDirty = (name: string): boolean => {
        return this.loginForm.controls[name]?.dirty;
    };

    /**
     * Checks if the control is required
     * @param name of the form control
     * @returns boolean
     */
    isRequired = (name: string): boolean => {
        return this.loginForm.controls[name]?.errors?.['required'];
    };

    /**
     * Checks whether there is max length error
     * @param name of the form control
     * @returns boolean
     */
    isMaxLengthError = (name: string): boolean => {
        return this.loginForm.controls[name]?.errors?.['maxlength'];
    };

    /**
     * Hide/show password
     * @param
     * @returns void
     */
    togglePassword = (): void => {
        this.showPassword = !this.showPassword;
    };

    /**
     * Getter to fetch form control value for email
     * @param
     * @returns string
     */
    get emailValue(): string {
        return this.loginForm.controls['email'].value;
    }

    /**
     * Getter to fetch form control value for password
     * @param
     * @returns string
     */
    get passwordValue(): string {
        return this.loginForm.controls['password'].value;
    }

    /**
     * On login click, if form valid login action dispatched
     * @param
     * @returns void
     */
    onSubmit = (): void => {
        if (this.loginForm.valid) {
            const loginParams: OnLoginMutationVariables = {
                payload: {
                    email: this.emailValue.toLocaleLowerCase(),
                    password: this._encryptService.encrypt(this.passwordValue),
                },
            };
            this._store.dispatch(login(loginParams));
        }
    };

    /**
     * Goes to forgot password page
     * @param
     * @returns void
     */
    onNavigateToForgotPwd = (): void => {
        this._router.navigateByUrl('auth/forgot-password');
    };

    ngOnDestroy() {
        this.isAlive = false;
        this.subscription$.unsubscribe();
        this.routeParamSubscription$.unsubscribe();
    }
}
