import { createAction } from '@ngrx/store';

import {
    ActionType,
    CareOfPatient,
    ContractManufacturerListForLabSelection,
    DeletePatientAndLoadListQueryVariables,
    DoctorsForListDropdown,
    Lens,
    ListActiveItems,
    ManufacturerHistoryPerPatient,
    OldPatient,
    OrderSequenceForBothEye,
    PatientTransferDetails,
    PatientTransferRequestList,
    TreatmentBasicDetailCollection,
} from '../models/patients.model';
import {
    AcceptPatientTransferRequestMutationVariables,
    CreateOrderForSpareLensMutationVariables,
    DeleteRelatedEntriesOfDraftByIdsMutationVariables,
    GetControlPanelValuesByDesignerIdQuery,
    GetControlPanelValuesByDesignerIdQueryVariables,
    GetCurrentTreatmentDataByPatientQuery,
    GetDesignerIdByDoctorIdQuery,
    GetDesignerIdByDoctorIdQueryVariables,
    GetDistributorTypeForLoggedInDoctorQueryVariables,
    GetDoctorsListQueryVariables,
    GetFranchiseClinicDoctorsListQueryVariables,
    GetLatestCreatedOrderForPatientQuery,
    GetLatestFacdDataForFurtherTreatmentQuery,
    GetLatestFacdDataForFurtherTreatmentQueryVariables,
    GetLatestSatDataForFurtherTreatmentQuery,
    GetLatestSatDataForFurtherTreatmentQueryVariables,
    GetLensDetailsQueryVariables,
    GetListOfOrderForPatientQuery,
    GetListOfOrderForPatientQueryVariables,
    GetManufacturerHistoryPerPatientQueryVariables,
    GetMatDataQueryVariables,
    GetOldPatientListQueryVariables,
    GetOrderSequencesQueryVariables,
    GetPatientClinicTypeDetailsQueryVariables,
    GetPatientListBasedOnRolesQuery,
    GetPatientListBasedOnRolesQueryVariables,
    GetPatientListTotalCountByRolesQueryVariables,
    GetPatientTransferRequestListCountQueryVariables,
    GetPatientTransferRequestListQueryVariables,
    GetSatDataQueryVariables,
    GetUserListBasedOnRoleQueryVariables,
    InUseLensSelectionUpdateMutationVariables,
    OnboardPatientMutationVariables,
    RejectPatientTransferRequestMutationVariables,
    SaveA2DataOfPatientMutationVariables,
    SaveA3DataOfPatientMutationVariables,
    SaveFacdDataOfPatientMutationVariables,
    SendLoginCredToPatientMutationVariables,
    UpdateUserRoleDetailsMutationVariables,
} from '@app/generated/graphql';
import { PatientInfoForDoctor } from '@app/shared/models/patient-details.model';
import { AData, D1Data, DoctorSlipDetails, LabFormDetails } from '@app/core/treatment-engine/interfaces';
import { User } from '@app/shared/models/shared.model';

export const getRegularClinicList = createAction(
    ActionType.getRegularClinicList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getRegularClinicListSuccess = createAction(
    ActionType.getRegularClinicListSuccess,
    (regularClinicList: User[] | null) => ({ regularClinicList })
);
export const getDoctorList = createAction(ActionType.getDoctorList, (clinicId: GetDoctorsListQueryVariables) => ({
    clinicId,
}));
export const getDoctorListSuccess = createAction(
    ActionType.getDoctorListSuccess,
    (doctorsList: DoctorsForListDropdown[] | null) => ({ doctorsList })
);
export const onboardPatient = createAction(
    ActionType.onboardPatient,
    (patientDetails: OnboardPatientMutationVariables) => ({
        patientDetails,
    })
);

// Facd related actions
export const onSaveFacdQuestionsData = createAction(ActionType.saveFacdFormData, (facdFormData: D1Data) => ({
    facdFormData,
}));
export const onSaveFacdQuestionsDataAndSubmit = createAction(
    ActionType.saveFacdFormDataAndSubmit,
    (facdFormData: SaveFacdDataOfPatientMutationVariables) => ({ facdFormData })
);
export const getSavedFacdData = createAction(ActionType.getFacdDataById, (facdId: string) => ({ facdId }));
export const getSavedFacdDataSucess = createAction(ActionType.getFacdDataSuccess, (facdData: D1Data) => ({ facdData }));

export const getSatData = createAction(
  ActionType.getSatData,
  (getSatDataParams: GetSatDataQueryVariables) => ({ getSatDataParams })
);
export const getSatDataSuccess = createAction(
  ActionType.getMatDataSuccess,
  (satData: AData) => ({ satData })
);
export const getMatData = createAction(
  ActionType.getMatData,
  (getMatDataParams: GetMatDataQueryVariables) => ({ getMatDataParams })
);
export const getMatDataSuccess = createAction(
  ActionType.getMatDataSuccess,
  (matData: AData) => ({ matData })
)

export const saveFacdQuestionsToState = createAction(ActionType.storeFacdDataToState, (facdData: D1Data) => ({
    facdData,
}));
export const getDistributorIdForRegularClinic = createAction(
    ActionType.getDistributorIdForRegularClinic,
    (clinicId: string) => ({ clinicId })
);
export const getDistributorIdForFranchiseClinic = createAction(
    ActionType.getDistributorIdForFranchiseClinic,
    (clinicId: string) => ({ clinicId })
);
export const distributorIdFetchSuccess = createAction(
    ActionType.distributorIdFetchSuccess,
    (distributorId: string) => ({ distributorId })
);
export const addFacdDataToOrderCollection = createAction(
    ActionType.addFacdDataToOrderCollection,
    (
        facdIdRight: string,
        facdIdLeft: string,
        patientId: string,
        contractManufacturerId: string,
        isRightEye: boolean,
        isLeftEye: boolean
    ) => ({
        facdIdRight,
        facdIdLeft,
        patientId,
        contractManufacturerId,
        isRightEye,
        isLeftEye,
    })
);
export const addDoctorAndLabFormDataToStore = createAction(
    ActionType.addDoctorAndLabFormDataToStore,
    (doctorSlipData: DoctorSlipDetails, labFormDetails: LabFormDetails) => ({ doctorSlipData, labFormDetails })
);
export const clearFacdStateFromStore = createAction(ActionType.clearFacdStateFromStore);
export const clearFacdDataSuccess = createAction(ActionType.clearFacdStateFromStoreSuccess);
export const clearPatientDetailsFromStore = createAction(ActionType.clearPatientDetailsFromStore);
export const clearPatientDataSuccess = createAction(ActionType.clearPatientDetailsFromStoreSuccess);

// fetching patient details
export const getPatientInfoForTreatment = createAction(ActionType.getPatientInfoForTreatment, (patientId?: string) => ({
    patientId,
}));
export const getPatientInfoForTreatmentSuccess = createAction(
    ActionType.getPatientInfoForTreatmentSuccess,
    (patientInfo: PatientInfoForDoctor) => ({ patientInfo })
);

export const storeCurrentPatientId = createAction(ActionType.storeCurrentPatientId, (patientId: string) => ({
    patientId,
}));

// Patient listing related actions
export const getPatientList = createAction(
    ActionType.getPatientList,
    (patientListParams: GetPatientListBasedOnRolesQueryVariables) => ({ patientListParams })
);

export const getPatientListSuccess = createAction(
    ActionType.getPatientListSuccess,
    (patientList: GetPatientListBasedOnRolesQuery | null) => ({ patientList })
);

export const resetPatientList = createAction(
  ActionType.resetPatientList
);

export const resetTotalTableItemsCount = createAction(
  ActionType.resetTotalTableItemsCount
);

export const getPatientListFailure = createAction(ActionType.getPatientListFailure);

export const getPatientListTotalCount = createAction(
    ActionType.getPatientListTotalCount,
    (countListParams: GetPatientListTotalCountByRolesQueryVariables) => ({ countListParams })
);

export const totalCountFetchSuccess = createAction(
    ActionType.getTotalTableItemsCountSuccess,
    (tableItemCount: number | null) => ({ tableItemCount })
);

export const deletePatient = createAction(
    ActionType.onDeletePatient,
    (deletePatientAndLoadListQueryVariables: DeletePatientAndLoadListQueryVariables) => ({
        deletePatientAndLoadListQueryVariables,
    })
);

// order - patient details page related
export const getLatestOrderDetailOfPatient = createAction(
    ActionType.getLatestOrderDetailOfPatient,
    (patientId: string) => ({ patientId })
);
export const getLatestOrderDetailOfPatientSuccess = createAction(
    ActionType.getLatestOrderDetailOfPatientSuccess,
    (orderDetails: GetLatestCreatedOrderForPatientQuery) => ({ orderDetails })
);
export const getLatestOrderDetailOfPatientFailure = createAction(
    ActionType.getLatestOrderDetailOfPatientFailure,
    (orderDetails: GetLatestCreatedOrderForPatientQuery) => ({ orderDetails })
);
export const getPreviousLatestOrderDetails = createAction(
    ActionType.getPreviousLatestOrderDetails,
    (patientId: string) => ({ patientId })
);
export const getPreviousLatestOrderDetailsSuccess = createAction(
    ActionType.getPreviousLatestOrderDetailsSuccess,
    (treatmentHistory: GetCurrentTreatmentDataByPatientQuery) => ({ treatmentHistory })
);
export const getPreviousLatestOrderDetailsFailure = createAction(ActionType.getPreviousLatestOrderDetailsFailure);
export const storeTreatmentHistoryByDoctor = createAction(
    ActionType.storeTreatmentHistoryByDoctor,
    (treatmentTarget: TreatmentBasicDetailCollection) => ({ treatmentTarget })
);
export const initializeCurrentTreatmentSelection = createAction(ActionType.initializeCurrentTreatmentSelection);

// amend treatment related
export const saveAmendQuestionsToState = createAction(ActionType.saveAmendQuestionsToState, (amendData: AData) => ({
    amendData,
}));
export const clearAmendQuestionsStored = createAction(ActionType.clearAmendQuestionsStored);
export const getPreviousFacdForAmendTreatment = createAction(
    ActionType.getPreviousFacdForAmendTreatment,
    (prevFacdPayload: GetLatestFacdDataForFurtherTreatmentQueryVariables) => ({ prevFacdPayload })
);
export const getPreviousFacdForAmendTreatmentSuccess = createAction(
    ActionType.getPreviousFacdForAmendTreatmentSuccess,
    (prevFacdData: GetLatestFacdDataForFurtherTreatmentQuery) => ({ prevFacdData })
);

export const getPreviousSatForAmendTreatment = createAction(
    ActionType.getPreviousSatForAmendTreatment,
    (prevSatPayload: GetLatestSatDataForFurtherTreatmentQueryVariables) => ({ prevSatPayload })
);
export const getPreviousSatForAmendTreatmentSuccess = createAction(
    ActionType.getPreviousSatForAmendTreatmentSuccess,
    (prevSatData: GetLatestSatDataForFurtherTreatmentQuery) => ({ prevSatData })
);

// save sat related actions
export const onSaveAmendQuestionsDataAndSubmit = createAction(
    ActionType.onSaveAmendQuestionsDataAndSubmit,
    (a2FormData: SaveA2DataOfPatientMutationVariables) => ({ a2FormData })
);
export const addA2DataToOrderCollection = createAction(
    ActionType.addA2DataToOrderCollection,
    (patientId: string, satId: string, isRightEye: boolean, isLeftEye: boolean) => ({
        patientId,
        satId,
        isRightEye,
        isLeftEye,
    })
);

// save mat related actions
export const onSaveSatQuestionsDataAndSubmit = createAction(
    ActionType.onSaveSatQuestionsDataAndSubmit,
    (a3FormData: SaveA3DataOfPatientMutationVariables) => ({ a3FormData })
);
export const addA3DataToOrderCollection = createAction(
    ActionType.addA3DataToOrderCollection,
    (matId: string | null, patientId: string, isRightEye: boolean, isLeftEye: boolean) => ({
        matId,
        patientId,
        isRightEye,
        isLeftEye,
    })
);

// Treatment logic handling actions
export const updatePrefillFormDataStatus = createAction(
    ActionType.updatePrefillFormDataStatus,
    (prefillStatus: boolean) => ({ prefillStatus })
);

// List of order to be shown in patient details page
export const getListOfOrdersOfPatient = createAction(
    ActionType.getListOfOrdersOfPatient,
    (ordersPayload: GetListOfOrderForPatientQueryVariables) => ({ ordersPayload })
);
export const getListOfOrdersOfPatientSuccess = createAction(
    ActionType.getListOfOrdersOfPatientSuccess,
    (ordersList: GetListOfOrderForPatientQuery) => ({ ordersList })
);
// Get order sequence list.
export const getOrderSequences = createAction(
  ActionType.getOrderSequences,
  (orderSequencesParam: GetOrderSequencesQueryVariables) => ({ orderSequencesParam })
);
export const getOrderSequencesSuccess = createAction(
  ActionType.getOrderSequencesSuccess,
  (orderSequences: OrderSequenceForBothEye) => ({ orderSequences })
)
// action for spare lens
export const createOrderForSpareLens = createAction(
    ActionType.createOrderForSpareLens,
    (
        orderPayload: CreateOrderForSpareLensMutationVariables,
        orderListPayload: GetListOfOrderForPatientQueryVariables
    ) => ({ orderPayload, orderListPayload })
);

// Patient transfer request actions

export const loadPatientTransferRequestList = createAction(
    ActionType.loadPatientTransferRequestList,
    (payload: GetPatientTransferRequestListQueryVariables) => ({ payload })
);

export const loadPatientTransferRequestListSuccess = createAction(
    ActionType.loadPatientTransferRequestListSuccess,
    (patientTransferRequestList: PatientTransferRequestList[]) => ({ patientTransferRequestList })
);

export const loadPatientTransferRequestListFailure = createAction(
    ActionType.loadPatientTransferRequestListFailure,
    (error: any) => ({ error })
);

export const loadPatientTransferRequestListCount = createAction(
    ActionType.loadPatientTransferRequestListCount,
    (patientTransferRequestListTotalCount: GetPatientTransferRequestListCountQueryVariables) => ({
        patientTransferRequestListTotalCount,
    })
);

export const loadPatientTransferRequestListCountSuccess = createAction(
    ActionType.loadPatientTransferRequestListCountSuccess,
    (totalPatientTransferRequestListCount: number) => ({ totalPatientTransferRequestListCount })
);

export const loadPatientTransferRequestListCountFailure = createAction(
    ActionType.loadPatientTransferRequestListCountFailure,
    (error: any) => ({ error })
);

export const storeLastPayloadForPatientTransferRequestListAndCount = createAction(
    ActionType.storeLastPayloadForPatientTransferRequestListAndCount,
    (lastPayloadForPatientTransferRequestListAndCount: {
        list: GetPatientTransferRequestListQueryVariables | null;
        count: GetPatientTransferRequestListCountQueryVariables | null;
    }) => ({ lastPayloadForPatientTransferRequestListAndCount })
);

export const acceptPatientTransferRequest = createAction(
    ActionType.acceptPatientTransferRequest,
    (acceptPatientTransferRequestMutationVariables: AcceptPatientTransferRequestMutationVariables, updateRoleDetailsParams: UpdateUserRoleDetailsMutationVariables) => ({
        acceptPatientTransferRequestMutationVariables, updateRoleDetailsParams
    })
);

export const rejectPatientTransferRequest = createAction(
    ActionType.rejectPatientTransferRequest,
    (rejectPatientTransferRequestMutationVariables: RejectPatientTransferRequestMutationVariables) => ({
        rejectPatientTransferRequestMutationVariables,
    })
);

export const addPatientFormAsDraft = createAction(ActionType.addPatientFormAsDraftRequest, (isDraft: boolean) => ({
    isDraft,
}));

export const getDesignerIdByDoctorId = createAction(
    ActionType.getDesignerIdByDoctorId,
    (doctorId: GetDesignerIdByDoctorIdQueryVariables) => ({ doctorId })
);
export const getDesignerIdByDoctorIdSuccess = createAction(
    ActionType.getDesignerIdByDoctorIdSuccess,
    (designerId: GetDesignerIdByDoctorIdQuery) => ({ designerId })
);

export const getControlPanelValuesByDesignerId = createAction(
    ActionType.getControlPanelValuesByDesignerId,
    (designerId: GetControlPanelValuesByDesignerIdQueryVariables) =>
    ({designerId})
);

export const getControlPanelValuesByDesignerIdSuccess = createAction(
    ActionType.getControlPanelValuesByDesignerIdSuccess,
    (getControlPanelValuesByDesignerId: GetControlPanelValuesByDesignerIdQuery | null) => ({
        getControlPanelValuesByDesignerId,
    })
);

export const getContractManufacturerList = createAction(
  ActionType.getContractManufacturerList
);

export const getContractManufacturerListSuccess = createAction(
  ActionType.getContractManufacturerListSuccess,
  (contractManufacturerList: ContractManufacturerListForLabSelection[]) => ({ contractManufacturerList })
);

export const getLensList = createAction(
  ActionType.getLensList,
  (getLensListParams: GetLensDetailsQueryVariables) => ({ getLensListParams})
);

export const getLensListSuccess = createAction(
  ActionType.getLensListSuccess,
  (lensList: Lens[]) => ({ lensList })
);

export const clearLensList = createAction(
  ActionType.clearLensList
)

export const getFranchiseClinicDoctorList = createAction(
  ActionType.getFranchiseClinicDoctorsList,
  (clinicId: GetFranchiseClinicDoctorsListQueryVariables) => ({
      clinicId,
  })
);
export const getFranchiseClinicDoctorsListSuccess = createAction(
  ActionType.getFranchiseClinicDoctorsListSuccess,
  (franchiseClinicDoctorsList: DoctorsForListDropdown[] | null) => ({ franchiseClinicDoctorsList })
);

// Patient onboarding, editing related actions
export const getFranchiseClinicList = createAction(
  ActionType.getFranchiseClinicList,
  (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getFranchiseClinicListSuccess = createAction(
  ActionType.getFranchiseClinicListSuccess,
  (franchiseClinicList: User[] | null) => ({ franchiseClinicList })
);

export const inUseLensSelection = createAction(
    ActionType.inUseLensSelection,
    (inUseLensPayload: InUseLensSelectionUpdateMutationVariables) => ({ inUseLensPayload })
);

export const getDistributorTypeBasedOnDoctorId = createAction(
    ActionType.getDistributorTypeBasedOnDoctorId,
    (doctorId: GetDistributorTypeForLoggedInDoctorQueryVariables) => ({ doctorId })
);
export const getDistributorTypeBasedOnDoctorIdSuccess = createAction(
    ActionType.getDistributorTypeBasedOnDoctorIdSuccess,
    (distributorType: string | null) => ({ distributorType })
);

export const updateLoopToMatchCorrectness = createAction(
    ActionType.updateLoopToMatchCorrectness,
    (loopToMatchCorrectness: boolean) => ({ loopToMatchCorrectness })
);

export const updateLabSelected = createAction(
  ActionType.updateLabSelected,
  (contractManufacturerId: string) => ({contractManufacturerId})
);

export const getManufacturerHistoryPerPatient = createAction(
  ActionType.getManufacturerHistoryPerPatient,
  (params: GetManufacturerHistoryPerPatientQueryVariables) => ({ params })
);

export const getManufacturerHistoryPerPatientSuccess = createAction(
  ActionType.getManufacturerHistoryPerPatientSuccess,
  (manufacturerHistoryPerPatient: ManufacturerHistoryPerPatient[]) => ({ manufacturerHistoryPerPatient })
);

export const updatePatientListActiveItems = createAction(
  ActionType.updateListActiveItems,
  (listActiveItems: ListActiveItems[]) => ({ listActiveItems })
);

export const getOldPatientList = createAction(
  ActionType.getOldPatientList,
  (params: GetOldPatientListQueryVariables) => ({ params })
);

export const getOldPatientListSuccess = createAction(
  ActionType.getOldPatientListSuccess,
  (oldPatientList: OldPatient[] | null) => ({ oldPatientList })
);

export const updateOldPatientTotalCount = createAction(
  ActionType.updateOldPatientTotalCount,
  (totalOldPatientCount) => ({ totalOldPatientCount })
);

export const updateCareOfPatient = createAction(
  ActionType.updateCareOfPatient,
  (careOfPatient: CareOfPatient | null) => ({ careOfPatient })
);

export const sendLoginCredToPatient = createAction(
  ActionType.sendLoginCredToPatient,
  (params: SendLoginCredToPatientMutationVariables) => ({ params })
);

export const getTransferredPatientDetails = createAction(
  ActionType.getTransferredPatientDetails,
  (params: GetPatientClinicTypeDetailsQueryVariables) => ({ params })
);

export const getTransferredPatientDetailsSuccess = createAction(
  ActionType.getTransferredPatientDetailsSuccess,
  (patientTransferDetails: PatientTransferDetails | null) => ({ patientTransferDetails })
);

export const discardDraft = createAction(
  ActionType.discardDraft,
  (params: DeleteRelatedEntriesOfDraftByIdsMutationVariables, getOrderListParams: GetListOfOrderForPatientQueryVariables) => ({ params, getOrderListParams })
);
