import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from './reducers';
import { getErrorMessage, getLoading, selectOpaqueLoading } from './ui/selectors/ui.selector';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    // Select loading state from ngrx store.
    showLoading$ = this._store.select(getLoading);
    // Select loading opaque loader state from ngrx store.
    showOpaqueLoading$ = this._store.select(selectOpaqueLoading);
    // Select error message state from ngrx store.
    errorMessage$ = this._store.select(getErrorMessage);

    constructor(private _store: Store<State>) {}
}
