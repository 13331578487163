<div class="bg-white kl-table-container">
    <div class="kl-table custom-scrollbar overflow-auto" [ngClass]="collectionSize === 0 ? '' : 'table-height'">
        <table class="table table-condensed table-hover my-0">
            <thead class="position-sticky top-0">
                <tr>
                    <th *ngFor="let col of tableColumns" scope="col">
                        <div class="header-container d-flex align-items-center">
                            <span class="kl-text5-type-1">{{ col.header }}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr class="data-row" *ngFor="let data of tableData; let i = index">
                    <td *ngFor="let col of tableColumns">
                        <span
                            class="kl-text5-type4 span-width span-width"
                            placement="bottom"
                            [ngbTooltip]="data[col.columnDef]?.length > 20 ? data[col.columnDef] : ''"
                            *ngIf="col?.columnDef != 'clinic_type'"
                        >
                            <span>
                                <span 
                                    *ngIf="col?.columnDef === 'name' && allowLogoForTableDataList" 
                                    class="contact-image kl-profile-image"
                                >
                                    {{ data?.user_image }}
                                </span>
                            </span>
                            {{
                                data?.[col.columnDef]?.length > 20
                                    ? (data?.[col.columnDef] | slice : 0 : 20) + '...'
                                    : data?.[col.columnDef]
                            }}
                        </span>
                        <span
                        *ngIf="col?.columnDef === 'clinic_type'"
                            [ngClass]="data?.[col.columnDef] === 'Partner' ? 'regular-clinic' : 'franchise-clinic'">
                            {{ data?.[col.columnDef] }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="collectionSize === 0" class="no-data-found-label d-flex justify-content-center">
            <span class="kl-table-empty-text">No Data Found</span>
        </div>
    </div>
</div>
