import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Observable, Subject } from 'rxjs';
import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import {
    CreateOrderForRemakeGQL,
    CreateOrderForRemakeMutation,
    CreateOrderForRemakeMutationVariables,
    GetDetailsOfOrderByIdGQL,
    GetDetailsOfOrderByIdQuery,
    GetDetailsOfOrderByIdQueryVariables,
    GetFormDataFilledByDoctorForDesignViewGQL,
    GetFormDataFilledByDoctorForDesignViewQuery,
    GetFormDataFilledByDoctorForDesignViewQueryVariables,
    GetListOfOrderCountGQL,
    GetListOfOrderCountQuery,
    GetListOfOrderCountQueryVariables,
    GetListOfOrdersBasedOnRoleGQL,
    GetListOfOrdersBasedOnRoleQuery,
    GetListOfOrdersBasedOnRoleQueryVariables,
    GetUserDetailsByIdGQL,
    GetUserDetailsByIdQuery,
    GetUserDetailsByIdQueryVariables,
    UpdateOrderStatusGQL,
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables,
} from '@app/generated/graphql';
import { Roles } from '../util/common';
import { CredentialsService } from './credentials.service';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    orderStatusUpdated$ = new Subject<boolean>();

    constructor(
        private _getListOfOrderCount: GetListOfOrderCountGQL,
        private _getListOfOrdersBasedOnRoleGQL: GetListOfOrdersBasedOnRoleGQL,
        private _getUserDetailsByIdGQL: GetUserDetailsByIdGQL,
        private _getDetailsOfOrderByIdGQL: GetDetailsOfOrderByIdGQL,
        private _getFormDataFilledByDoctorForDesignViewGQL: GetFormDataFilledByDoctorForDesignViewGQL,
        private _updateOrderDetailsGQL: UpdateOrderStatusGQL,
        private _createOrderForRemake: CreateOrderForRemakeGQL,
        private _credentialService: CredentialsService,
        private _datePipe: DatePipe
    ) { }

    /**
     * Fetch total count of orders
     */
    getTotalCountOfOrderList = (
        listParams: GetListOfOrderCountQueryVariables
    ): Observable<ApolloQueryResult<GetListOfOrderCountQuery>> => {
        return this._getListOfOrderCount.watch(listParams).valueChanges;
    };

    /**
     * Fetch orders list
     */
    getListOfOrderBasedOnRole = (
        listParams: GetListOfOrdersBasedOnRoleQueryVariables
    ): Observable<ApolloQueryResult<GetListOfOrdersBasedOnRoleQuery>> => {
        return this._getListOfOrdersBasedOnRoleGQL.watch(listParams).valueChanges;
    };

    /**
     * Fetch user details
     */
    getUserDetailsByUserId = (
        userIdParam: GetUserDetailsByIdQueryVariables
    ): Observable<ApolloQueryResult<GetUserDetailsByIdQuery>> => {
        return this._getUserDetailsByIdGQL.watch(userIdParam).valueChanges;
    };

    /**
     * Fetch order details for showing in order details page
     */
    getOrderDetailsByOrderId = (
        orderIdParam: GetDetailsOfOrderByIdQueryVariables
    ): Observable<ApolloQueryResult<GetDetailsOfOrderByIdQuery>> => {
        return this._getDetailsOfOrderByIdGQL.watch(orderIdParam).valueChanges;
    };

    /**
     * Get form data filled by doctor
     */
    getFormDataFilledByDoctorForDesignView = (
        dataPayload: GetFormDataFilledByDoctorForDesignViewQueryVariables
    ): Observable<ApolloQueryResult<GetFormDataFilledByDoctorForDesignViewQuery>> => {
        return this._getFormDataFilledByDoctorForDesignViewGQL.watch(dataPayload).valueChanges;
    };

    /**
     * Update order status
     */
    updateOrderStatus = (
        orderDetails: UpdateOrderStatusMutationVariables
    ): Observable<MutationResult<UpdateOrderStatusMutation>> => {
        return this._updateOrderDetailsGQL.mutate(orderDetails);
    };

    /**
     * Check for warranty period expiration, compare dates to find expiry
     * @param orderCreatedDate Date in which the order is created
     * @param warrantyPeriodInDays Warranty period set for manufacturer at time of onboarding
     * @returns boolean, true if warranty expired
     */
    isWarrantyActive(orderCreatedDate: string, warrantyPeriodInDays: number): boolean {
        const orderDate = new Date(orderCreatedDate);
        const warrantyExpirationDate = new Date(orderDate);
        warrantyExpirationDate.setDate(orderDate.getDate() + warrantyPeriodInDays);

        const currentDate = new Date();

        // Compare the current date with the warranty expiration date
        if (currentDate <= warrantyExpirationDate) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Remake order.
     * @param createOrderForRemakeParams order remake details.
     * @returns remake status.
     */
    createOrderForRemake = (
        createOrderForRemakeParams: CreateOrderForRemakeMutationVariables
    ): Observable<MutationResult<CreateOrderForRemakeMutation>> => {
        return this._createOrderForRemake.mutate(createOrderForRemakeParams);
    };

    /**
     *
     * @returns order status update event as observable.
     */
    onOrderStatusUpdated = (): Observable<boolean> => {
      return this.orderStatusUpdated$.asObservable();
    }

    /**
     * Emit order update event status.
     */
    emitOrderStatusUpdatedEvent = (status: boolean): void => {
      this.orderStatusUpdated$.next(status);
    }

    getOrdersListBasedOnUserRole = (ordersList: any) => {
        const userRole = this._credentialService?.credentials?.role
        const tableData: any = []
        switch (userRole) {
            case Roles.SUPER_ADMIN:
                ordersList?.forEach((listItem: any) => {
                    const name = listItem?.userByUserId?.user_role_mappings?.[0]?.name.split(" ");
                    const orderList = {
                        id: listItem?.id,
                        order_id: listItem?.order_id || '--',
                        created_at: listItem?.created_at
                            ? this._datePipe.transform(listItem?.created_at, 'dd/MM/yyy', 'UTC')
                            : '--',
                        patientId: listItem?.user_id,
                        first_name: name?.[0],
                        last_name: name?.[1]  || '--',
                        reference_number: listItem?.userByUserId?.user_role_mappings?.[0]?.reference_number,
                        rightOrLeft: listItem?.side,
                        order_sequence: listItem?.order_sequence || '--',
                        order_type: listItem?.order_type,
                        design_type: listItem?.design_type,
                        designer: listItem?.userByDistributorId?.designerDistributorMappingsByDistributorId?.[0]?.userByDesignerId?.user_role_mappings?.[0]?.name,
                        distributor: listItem?.userByDistributorId?.designerDistributorMappingsByDistributorId?.[0]?.userByDistributorId?.user_role_mappings?.[0]?.name,
                        partner: listItem?.userByClinicId?.user_role_mappings?.[0]?.name,
                        practitioner: listItem?.userByDoctorId?.user_role_mappings?.[0]?.name,
                        order_status: listItem?.order_status,
                        action: '--',
                        previous_status: listItem?.previous_status,
                        requireTextStyling: true,
                    };
                    tableData.push(orderList);
                });
                return tableData;
            break;
            case Roles.DESIGNER:
                ordersList?.forEach((listItem: any) => {
                    const name = listItem?.userByUserId?.user_role_mappings?.[0]?.name.split(" ");
                    const orderList = {
                        id: listItem?.id,
                        order_id: listItem?.order_id || '--',
                        created_at: listItem?.created_at
                            ? this._datePipe.transform(listItem?.created_at, 'dd/MM/yyy', 'UTC')
                            : '--',
                        patientId: listItem?.user_id,
                        first_name: name?.[0],
                        last_name: name?.[1]  || '--',
                        reference_number: listItem?.userByUserId?.user_role_mappings?.[0]?.reference_number,
                        rightOrLeft: listItem?.side,
                        order_sequence: listItem?.order_sequence || '--',
                        order_type: listItem?.order_type,
                        design_type: listItem?.design_type,
                        distributor: listItem?.userByDistributorId?.user_role_mappings?.[0]?.user?.designerDistributorMappingsByDistributorId?.[0]?.userByDistributorId?.user_role_mappings?.[0]?.name,
                        partner: listItem?.userByClinicId?.user_role_mappings?.[0]?.name,
                        practitioner: listItem?.userByDoctorId?.user_role_mappings?.[0]?.name,
                        order_status: listItem?.order_status,
                        action: '--',
                        previous_status: listItem?.previous_status,
                        requireTextStyling: true
                    };
                    tableData.push(orderList);
                });
                return tableData;
            break;
            case Roles.EXCLUSIVE_DISTRIBUTOR:
            case Roles.SELECTIVE_DISTRIBUTOR:
                ordersList?.forEach((listItem: any) => {
                    const name = listItem?.userByUserId?.user_role_mappings?.[0]?.name?.split(" ");
                    const orderList = {
                        id: listItem?.id,
                        order_id: listItem?.order_id || '--',
                        created_at: listItem?.created_at
                            ? this._datePipe.transform(listItem?.created_at, 'dd/MM/yyy', 'UTC')
                            : '--',
                        patientId: listItem?.user_id,
                        first_name: name?.[0],
                        last_name: name?.[1]  || '--',
                        reference_number: listItem?.userByUserId?.user_role_mappings?.[0]?.reference_number,
                        rightOrLeft: listItem?.side,
                        order_sequence: listItem?.order_sequence || '--',
                        order_type: listItem?.order_type,
                        design_type: listItem?.design_type,
                        partner: listItem?.userByClinicId?.user_role_mappings?.[0]?.name,
                        practitioner: listItem?.userByDoctorId?.user_role_mappings?.[0]?.name,
                        order_status: listItem?.order_status,
                        manufacturer_status: listItem?.manufacturer_status,
                        action: '--',
                        previous_status: listItem?.previous_status,
                        requireTextStyling: true,
                        isSelectable: true,
                    };
                    tableData.push(orderList);
                });
                return tableData;
            break;
            case Roles.REGULAR_CLINIC:
            case Roles.FRANCHISE_CLINIC:
                ordersList?.forEach((listItem: any) => {
                    const name = listItem?.userByUserId?.user_role_mappings?.[0]?.name.split(" ");
                    const orderList = {
                        id: listItem?.id,
                        order_id: listItem?.order_id || '--',
                        created_at: listItem?.created_at
                            ? this._datePipe.transform(listItem?.created_at, 'dd/MM/yyy', 'UTC')
                            : '--',
                        patientId: listItem?.user_id,
                        first_name: name?.[0],
                        last_name: name?.[1]  || '--',
                        reference_number: listItem?.userByUserId?.user_role_mappings?.[0]?.reference_number,
                        rightOrLeft: listItem?.side,
                        order_sequence: listItem?.order_sequence || '--',
                        order_type: listItem?.order_type,
                        design_type: listItem?.design_type,
                        practitioner: listItem?.userByDoctorId?.user_role_mappings?.[0]?.name,
                        order_status: listItem?.order_status,
                        action: '--',
                        previous_status: listItem?.previous_status,
                        requireTextStyling: true
                    };
                    tableData.push(orderList);
                });
                return tableData;
            break;
            case Roles.DOCTOR:
                ordersList?.forEach((listItem: any) => {
                    const name = listItem?.userByUserId?.user_role_mappings?.[0]?.name.split(" ");
                    const orderList = {
                        id: listItem?.id,
                        order_id: listItem?.order_id || '--',
                        created_at: listItem?.created_at
                            ? this._datePipe.transform(listItem?.created_at, 'dd/MM/yyy', 'UTC')
                            : '--',
                        patientId: listItem?.user_id,
                        first_name: name?.[0],
                        last_name: name?.[1]  || '--',
                        reference_number: listItem?.userByUserId?.user_role_mappings?.[0]?.reference_number,
                        rightOrLeft: listItem?.side,
                        order_sequence: listItem?.order_sequence || '--',
                        order_type: listItem?.order_type,
                        design_type: listItem?.design_type,
                        order_status: listItem?.order_status,
                        action: '--',
                        previous_status: listItem?.previous_status,
                        requireTextStyling: true
                    };
                    tableData.push(orderList);
                });
                return tableData;
            }
    }
}
