import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

import { OrderStatusNameInUi } from '@app/core/util/common';
import { OrderStatusColor } from '@app/core/util/initial-values';

@Directive({
  selector: '[appStatusColor]'
})
export class StatusColorDirective implements AfterViewInit{


  constructor(private _el: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const content: string = this._el.nativeElement.textContent.trim() ;
    this.getColor(content);
  }

  getColor = (content: string): void => {
    switch (content) {
      case OrderStatusNameInUi.Cancelled:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Cancelled);
        break;
      case OrderStatusNameInUi.Delivered:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Delivered);
        break;
      case OrderStatusNameInUi.DraftSaved:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.DraftSaved);
        break;
      case OrderStatusNameInUi.Ordered:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Ordered);
        break;
      case OrderStatusNameInUi.OutForDelivery:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.OutForDelivery);
        break;
      case OrderStatusNameInUi.Shipped:
      case OrderStatusNameInUi.ShippedToCountry:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Shipped);
        break;
      case OrderStatusNameInUi.Submitted:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Submitted);
        break;
      case OrderStatusNameInUi.Received:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Received);
      break;
      case OrderStatusNameInUi.Manufactured:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Manufactured);
      break;
      case OrderStatusNameInUi.Delayed:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Delayed);
      break;
      default:
        this._renderer.setStyle(this._el.nativeElement, 'color', OrderStatusColor.Default);
        break;
    }
  }

}
