import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserPriceSettingsData } from '@app/core/util/price-setting-data';
import { UserPriceSettingsStructure, UserSavedPriceSettings } from '@app/shared/models/user-price-settings.model';

@Component({
    selector: 'app-price-settings',
    templateUrl: './price-settings.component.html',
    styleUrls: ['./price-settings.component.scss'],
})
export class PriceSettingsComponent implements OnChanges, OnInit {
    priceSettingsForm: FormGroup = new FormGroup({});
    userPriceData: UserPriceSettingsStructure[] = [];
    priceSettingsCompleteData: UserPriceSettingsStructure[] = UserPriceSettingsData;

    @Input() fetchedUserPriceSettings: UserSavedPriceSettings = {};
    @Input() mode = '';

    ngOnChanges(): void {
      this.appendValueToForm();
    }

    ngOnInit(): void {
        this.userPriceData = this.priceSettingsCompleteData;
        this.initializeForm();
    }

    initializeForm = (): void => {
        this.userPriceData.forEach((item) => {
            this.priceSettingsForm.addControl(item.unique_field_name, new FormControl('', [Validators.required]));
        });
    };

    isRequired = (control: string): boolean => {
        return this.priceSettingsForm.controls[control]?.errors?.['required'];
    };

    isDirty = (control: string): boolean => {
        return this.priceSettingsForm.controls[control]?.dirty;
    };

    isTouched = (control: string): boolean => {
        return this.priceSettingsForm.controls[control]?.touched;
    };

    appendValueToForm = (): void => {
      this.priceSettingsForm.patchValue(this.fetchedUserPriceSettings)
    }
}
