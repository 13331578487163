import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, throwError, withLatestFrom } from 'rxjs';

import { CommonService } from '@app/core/services/common.service';
import {
    GetOnboardUserFormDataQuery,
    GetOnboardUserFormDataQueryVariables,
    GetMoreDetailsOfUserQuery,
    GetMoreDetailsOfUserQueryVariables,
    GetRoleDetailsQuery,
    GetRoleDetailsQueryVariables,
    GetUserEmailBasedOnIdQuery,
    GetUserEmailBasedOnIdQueryVariables,
    UpdateUserDetailsMutationVariables,
    UpdateUserDetailsMutation,
    GetPermissionsBasedOnRoleQueryVariables,
    GetGrantedRoleListPerUserQueryVariables,
    GetGrantedRoleListPerUserQuery,
    GetCountryCodeDetailsQueryVariables,
    GetCountryCodeDetailsQuery,
} from '@app/generated/graphql';
import {
    ActionType,
    getLoggedInUserEmailSuccess,
    getOnboardUserFormDataSuccess,
    setLoadingSpinner,
    getMoreDetailsOfUserSuccess,
    getRoleDetailsSuccess,
    updateUserDetailsSuccess,
    storeUserPermissionsSuccess,
    getGrantedRoleListPerUserSuccess,
    getCountryCodeDetailsSuccess,
} from '@app/ui/actions/ui.actions';
import { Roles, Url } from '@app/core/util/common';
import { UserPermissionsData } from '../models/ui.model';
import { getRoleIdOfLoggedInUser } from '@app/shared/selectors/shared.selector';
import { CredentialsService } from '@app/core/services/credentials.service';
import { sendLoginCredToPatient } from '@app/patients/actions/patients.action';
import { EVENT_MSG, EventExtra } from '@app/shared/models/shared.model';

@Injectable()
export class UiEffects {
    constructor(
        private _action$: Actions,
        private _commonsService: CommonService,
        private _store: Store,
        private _toast: ToastrService,
        private _router: Router,
        private _credentialsService: CredentialsService,
    ) {}

    /**
     * Api request for getting  user role details.
     * @param data shares the data required to get designer list from api.
     * @returns result with success or failure.
     */
    getRoleDetails$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.getRoleDetails),
            mergeMap((data: { data: GetRoleDetailsQueryVariables }) => {
                return this._commonsService.getRoleDetails(data?.data).pipe(
                    map((result: ApolloQueryResult<GetRoleDetailsQuery>) => {
                        return getRoleDetailsSuccess(result?.data);
                    }),
                    catchError((error) => {
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

  /**
   * Api request for getting logged in user email from userid
   * @param data shares the data required to get email, user id
   * @returns result with success or failure.
   */
    getLoggedInUserEmail$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.getLoggedInUserEmail),
            mergeMap((params: { data: GetUserEmailBasedOnIdQueryVariables }) => {
                return this._commonsService.getLoggedInUserEmail(params?.data).pipe(
                    map((result: ApolloQueryResult<GetUserEmailBasedOnIdQuery>) => {
                        const email = result?.data?.user?.[0]?.email ?? result?.data?.user?.[0]?.userByCareOf?.email;
                        return getLoggedInUserEmailSuccess(email || '');
                    }),
                    catchError((error) => {
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Store to state permissions of logged in user
     * @param Nil
     * @returns result with success or failure
     */
    storeLoggedInUserPermissions$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.storeUserPermissions),
            withLatestFrom(this._store.select(getRoleIdOfLoggedInUser)),
            switchMap(([action, data]) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                const roleId = this._credentialsService.credentials?.roleId;
                const roleDetails: GetPermissionsBasedOnRoleQueryVariables = {
                    role_id: roleId
                };
                return this._commonsService.getRolePermissionsOfUser(roleDetails).pipe(
                    map((response) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        const userRolePermissions: UserPermissionsData = {
                            module_details: response?.data?.role_permissions?.[0]?.module_details,
                            permissions: response?.data?.role_permissions?.[0]?.permissions,
                            role: response?.data?.role_permissions?.[0]?.role,
                        };
                        return storeUserPermissionsSuccess(userRolePermissions);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Trigger side effects to get user details.
     * @param userIdObj shares user id.
     * returns user details observable.
     */
    getMoreDetailsOfUser$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.getMoreDetailsOfUser),
            mergeMap((userIdObj: GetMoreDetailsOfUserQueryVariables) => {
                return this._commonsService.getMoreDetailsOfUser(userIdObj).pipe(
                    map((result: ApolloQueryResult<GetMoreDetailsOfUserQuery>) => {
                        return getMoreDetailsOfUserSuccess(result?.data);
                    }),
                    catchError((error) => {
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Get data of onboard users to populate onboard form - used for editing user onboard form.
     */
    getOnboardUserFormData$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.getOnboardUserFormData),
            mergeMap((params: { userObj: GetOnboardUserFormDataQueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonsService.getOnboardUserFormDataGQL(params?.userObj).pipe(
                    map((result: ApolloQueryResult<GetOnboardUserFormDataQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getOnboardUserFormDataSuccess(result.data);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    /**
     * Update user info from onboarding form.
     */
    updateUserDetails$ = createEffect(() =>
        this._action$.pipe(
            ofType(ActionType.updateUserDetails),
            mergeMap((params: { role: string; userDetails: UpdateUserDetailsMutationVariables, eventExtra?: EventExtra}) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._commonsService.updateUserDetailsGQL(params?.userDetails).pipe(
                    map((result: MutationResult<UpdateUserDetailsMutation>) => {
                        const role = this._credentialsService.credentials?.role || '';
                        // redirect user to list page based on which user role updated
                        if (params.role === Roles.DESIGNER) {
                            this._router.navigateByUrl(Url.DESIGNER_LISTING);
                        } else if (params.role === Roles.DISTRIBUTOR) {
                            this._router.navigateByUrl(Url.EXCLUSIVE_DIST_LISTING);
                        } else if (params.role === Roles.PATIENT && role !== Roles.PATIENT) {
                            if(params?.eventExtra?.['message'] === EVENT_MSG.UNLINK_FAMILY_MEMBER){
                                this._store.dispatch(sendLoginCredToPatient({
                                    patientId: params?.userDetails?.id || '',
                                    email: params?.userDetails?.userDetails?.email || '',
                                }));
                            }
                            this._router.navigateByUrl(Url.PATIENTS_LISTING);
                        } else if (params.role === Roles.DOCTOR) {
                            this._router.navigateByUrl(Url.DOCTORS_LISTING);
                        } else if (params.role === Roles.FRANCHISE_CLINIC) {
                            this._router.navigateByUrl(Url.FRANCHISE_CLINIC_LISTING);
                        } else if (params.role === Roles.REGULAR_CLINIC) {
                            this._router.navigateByUrl(Url.REGULAR_CLINIC_LISTING);
                        } else if (params.role === Roles.SELECTIVE_DISTRIBUTOR) {
                            this._router.navigateByUrl(Url.SELECTIVE_DIST_LISTING);
                        }
                        this._toast.success('User updated successfully');
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return updateUserDetailsSuccess(result.data as UpdateUserDetailsMutation);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        this._toast.error('User updation failed');
                        return throwError(() => {
                            return new Error('');
                        });
                    })
                );
            })
        )
    );

    getGrantedRoleListPerUser$ = createEffect(() =>
      this._action$.pipe(
        ofType(ActionType.getGrantedRoleListPerUser),
        mergeMap((params: { getGrantedRoleListPerUserQueryParam: GetGrantedRoleListPerUserQueryVariables }) => {
          return this._commonsService.getGrantedRoleListPerUser(params?.getGrantedRoleListPerUserQueryParam).pipe(
              map((result: ApolloQueryResult<GetGrantedRoleListPerUserQuery>) => {
                const userRoles = this._commonsService.transformGrantedRoles(result?.data);
                return getGrantedRoleListPerUserSuccess(userRoles);
              }),
              catchError((error) => {
                  this._store.dispatch(setLoadingSpinner({ status: false }));
                  return throwError(() => {
                      return new Error('');
                  });
              })
          );
      })
      )
    );

    getCountryCodeDetails$ = createEffect(() =>
      this._action$.pipe(
        ofType(ActionType.getCountryCodeDetails),
        mergeMap(() => {
          return this._commonsService.getCountryCodeDetails()
            .pipe(
              map((result: ApolloQueryResult<GetCountryCodeDetailsQuery>) => {
                const countyCodeDetails = this._commonsService.transformCountryCodeDetails(result?.data);
                return getCountryCodeDetailsSuccess(countyCodeDetails);
              }),
              catchError((error) => {
                // Handle the error
                return throwError(() => new Error('Failed to get country code details'));
              })
            );
        })
        )
    );
}
