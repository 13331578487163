import { AmendFormInputControl } from "@app/patients/model/patient";

export const amendFormData: AmendFormInputControl[] = [
    {
        key: 'eye_selection',
        value: '',
        name: 'eye_selection',
        label: 'Please confirm eye(s) to be amended',
        type: 'dropdown',
        inputElementData: [
            {
                value: 'RIGHT AND LEFT',
                label: 'RIGHT AND LEFT'
            },
            {
                value: 'RIGHT ONLY',
                label: 'RIGHT ONLY'
            },
            {
                value: 'LEFT ONLY',
                label: 'LEFT ONLY'
            }
        ],
        placeholder: 'Select your answer',
        validators: [{
            required: true,
        }],
        unit: '',
        canSkip: false,
        isSingleQuestion: true,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: null,
        amendLeftEye: null,
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Please select the eye(s) to be designed appropriately</span>
                <ul class="sub-item">
                    <li>RIGHT AND LEFT</li>
                    <li>RIGHT ONLY</li>
                    <li>LEFT ONLY</li>
                </ul>
            </li>
            <li class="main-item">
                <span>Incorrect selection will result in the wrong lens(es) made.</span>
            </li>
        </ul>`,
    },
    {
        key: 'tangential_treatment_zone',
        value: '',
        name: 'tangential_treatment_zone',
        label: 'Mapping the entire tangential treatment zone, does it look round?',
        type: 'dropdown',
        inputElementData: [
            {
                value: 1,
                label: 'Yes'
            },
            {
                value: 2,
                label: 'No'
            }
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: '1',
                formControlName: 'tangential_treatment_zone_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: '1',
                formControlName: 'tangential_treatment_zone_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>None</span>
            </li>
        </ul>`,
    },
    {
        key: 'treatment_zone',
        value: '',
        name: 'treatment_zone',
        label: 'In tangential map, where is the treatment zone in relation to the geometric centre of the cornea?',
        type: 'dropdown',
        inputElementData: [
            {
                value: 1,
                label: 'Centered'
            },
            {
                value: 2,
                label: 'Inferior'
            },
            {
                value: 3,
                label: 'Superior'
            },
            {
                value: 4,
                label: 'Nasal'
            },
            {
                value: 5,
                label: 'Temporal'
            },
            {
                value: 6,
                label: 'Inferior nasal'
            },
            {
                value: 7,
                label: 'Inferior temporal'
            },
            {
                value: 8,
                label: 'Superior nasal'
            },
            {
                value: 9,
                label: 'Superior temporal'
            }
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: '1',
                formControlName: 'treatment_zone_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: '1',
                formControlName: 'treatment_zone_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Selection of decentration is valid if it is outside a 4-ring (0.4mm) radius.</span>
            </li>
            <li class="main-item">
                <span>Is it consistent over 2 review visits?</span>
            </li>
            <li class="main-item">
                <span>Has the patient's compliance and I&R technique been validated?</span>
            </li>
        </ul>`,
    },
    {
        key: 'axial_power_diff_map',
        value: '',
        name: 'axial_power_diff_map',
        label: 'In Axial power DIFFERENCE map (normalised scale ONLY), what do you observe?',
        type: 'dropdown',
        inputElementData: [
            {
                value: 1,
                label: 'Uniform treatment zone'
            },
            {
                value: 2,
                label: 'Small treatment zone'
            },
            {
                value: 3,
                label: 'Pseudo central-island'
            },
            {
                value: 4,
                label: 'Uneven treatment zone'
            },
            {
                value: 5,
                label: 'Real central-island'
            },
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: '1',
                formControlName: 'axial_power_diff_map_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: '1',
                formControlName: 'axial_power_diff_map_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Draw a 2mm circle and place it in the middle of the axial treatment zone even if it is decentered.</span>
            </li>
        </ul>`,
    },
    {
        key: 'cornea_condition',
        value: '',
        name: 'cornea_condition',
        label: `What is the cornea's response and ocular health?`,
        type: 'dropdown',
        inputElementData: [
            {
                value: true,
                label: 'Yes'
            },
            {
                value: false,
                label: 'No'
            },
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: 'false',
                formControlName: 'ces_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Central epithelial SPK',
            },
            {
                value: 'false',
                formControlName: 'csa_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Central stromal absorbtion',
            },
            {
                value: 'false',
                formControlName: 'mpspk_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Mid-peripheral SPK',
            },
            {
                value: 'false',
                formControlName: 'rza_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Reverse zone adhesion',
            },
            {
                value: 'false',
                formControlName: 'ela_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Edge lift adhesion',
            },
            {
                value: 'false',
                formControlName: 'ls_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Limbal staining',
            },
        ],
        amendLeftEye: [
            {
                value: 'false',
                formControlName: 'ces_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Central epithelial SPK',
            },
            {
                value: 'false',
                formControlName: 'csa_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Central stromal absorbtion',
            },
            {
                value: 'false',
                formControlName: 'mpspk_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Mid-peripheral SPK',
            },
            {
                value: 'false',
                formControlName: 'rza_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Reverse zone adhesion',
            },
            {
                value: 'false',
                formControlName: 'ela_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Edge lift adhesion',
            },
            {
                value: 'false',
                formControlName: 'ls_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select answer',
                label: 'Limbal staining',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Click [YES] if you observe the clinical sign.</span>
            </li>
        </ul>`,
    },
    {
        key: 'blurry_vision',
        value: '',
        name: 'blurry_vision_mid_day_regression',
        label: `Is the patient experiencing blurry distance vision and/or mid-day regression?`,
        type: 'multi',
        inputElementData: [
            {
                value: 'Oblique',
                label: 'Oblique'
            },
            {
                value: 'ATR',
                label: 'ATR'
            },
            {
                value: 'WTR',
                label: 'WTR'
            },
        ],
        validators: [{
            required: true
        }],
        placeholder: '',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: '0',
                formControlName: 'blurry_vision_sphere_right',
                valueUnit: 'Diopters',
                type: 'number',
                validators: [{
                    required: true,
                    number: true,
                    maxLength: true,
                    minValue: -20,
                    maxValue: 5,
                }],
                placeholder: 'Type here',
                label: 'Sphere',
            },
            {
                value: '0',
                formControlName: 'blurry_vision_cylinder_right',
                valueUnit: 'Diopters',
                type: 'number',
                validators: [{
                    required: true,
                    number: true,
                    maxLength: true,
                    minValue: -10,
                    maxValue: 0
                }],
                placeholder: 'Type here',
                label: 'Cylinder',
            },
            {
                value: 'WTR',
                formControlName: 'blurry_vision_axis_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select axis',
                label: 'Axis',
            },
        ],
        amendLeftEye: [
            {
                value: '0',
                formControlName: 'blurry_vision_sphere_left',
                valueUnit: 'Diopters',
                type: 'number',
                validators: [{
                    required: true,
                    number: true,
                    maxLength: true,
                    minValue: -20,
                    maxValue: 5,
                }],
                placeholder: 'Type here',
                label: 'Sphere',
            },
            {
                value: '0',
                formControlName: 'blurry_vision_cylinder_left',
                valueUnit: 'Diopters',
                type: 'number',
                validators: [{
                    required: true,
                    number: true,
                    maxLength: true,
                    minValue: -10,
                    maxValue: 0
                }],
                placeholder: 'Type here',
                label: 'Cylinder',
            },
            {
                value: 'WTR',
                formControlName: 'blurry_vision_axis_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select axis',
                label: 'Axis',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>If [YES], what is the under OR over-correction from subj RX?</span>
            </li>
        </ul>`,
    },
    {
        key: 'symptomatic_hoa',
        value: '',
        name: 'symptomatic_hoa',
        label: 'Is the patient experiencing symptomatic HOA',
        type: 'dropdown',
        inputElementData: [
            {
                value: true,
                label: 'Yes'
            },
            {
                value: false,
                label: 'No'
            }
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: 'false',
                formControlName: 'symptomatic_hoa_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: 'false',
                formControlName: 'symptomatic_hoa_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Glare, flare, foggy vision, halos etc Leave blank if Qn 2. returned value >1</span>
            </li>
        </ul>`,
    },
    {
        key: 'near_work_difficulty',
        value: '',
        name: 'near_work_difficulty',
        label: 'Is the patient having difficulties with near work?',
        type: 'dropdown',
        inputElementData: [
            {
                value: true,
                label: 'Yes'
            },
            {
                value: false,
                label: 'No'
            }
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: 'false',
                formControlName: 'near_work_difficulty_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: 'false',
                formControlName: 'near_work_difficulty_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>Leave blank if Qn 5. returned a value</span>
            </li>
        </ul>`,
    },
    {
        key: 'myopia_control',
        value: '',
        name: 'myopia_control',
        label: `How is the patient's myopia control?`,
        type: 'dropdown',
        inputElementData: [
            {
                value: 1,
                label: 'No progression'
            },
            {
                value: 2,
                label: 'Evidence of progression'
            }
        ],
        validators: [{
            required: true,
        }],
        placeholder: 'Select your answer',
        unit: '',
        canSkip: false,
        isSingleQuestion: false,
        isRightEyeDataBeShown: true,
        isLeftEyeDataBeShown: true,
        amendRightEye: [
            {
                value: '1',
                formControlName: 'myopia_control_right',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        amendLeftEye: [
            {
                value: '1',
                formControlName: 'myopia_control_left',
                valueUnit: '',
                type: 'dropdown',
                validators: [{
                    required: true,
                }],
                placeholder: 'Select your answer',
            },
        ],
        suggestionData: `
        <ul>
            <li class="main-item">
                <span>None</span>
            </li>
        </ul>`,
    },
    {
      key: 'lab_of_choice',
      value: '1',
      name: 'lab_of_choice',
      label: 'Please choose your lab',
      type: 'dropdown',
      inputElementData: [{value: '', label: ''}],
      validators: [{
          required: true,
      }],
      placeholder: 'Choose your answer',
      unit: '',
      canSkip: false,
      isSingleQuestion: false,
      isRightEyeDataBeShown: true,
      isLeftEyeDataBeShown: true,
      amendRightEye: null,
      amendLeftEye: null,
      suggestionData: `
      <ul>
          <li class="main-item">
              <span>Select the lab you want your lens(es) to be manufactured.
              Valley – USA (For orders between Fri to Mon, shipped Sat)
              Corneal lens – New Zealand (Between Tues to Thurs,
              shipped next Tues)</span>
          </li>
      </ul>
      `,
      prefillValue: true
  },
  {
      key: 'lens_material',
      value: '',
      name: 'lens_material',
      label: 'Choose lens material and colour',
      type: 'dropdown',
      inputElementData: [],
      validators: [{
          required: true,
      }],
      placeholder: 'Choose your answer',
      unit: '',
      canSkip: false,
      isSingleQuestion: false,
      isRightEyeDataBeShown: true,
      isLeftEyeDataBeShown: true,
      amendRightEye: [
          {
              value: '',
              formControlName: 'lens_material_right',
              valueUnit: '',
              type: 'dropdown',
              validators: [{
                  required: true
              }],
              placeholder: 'Choose your answer',
          },
      ],
      amendLeftEye: [
          {
              value: '',
              formControlName: 'lens_material_left',
              valueUnit: '',
              type: 'dropdown',
              validators: [{
                  required: true
              }],
              placeholder: 'Choose your answer',
          },
      ],
      suggestionData: `
      <ul>
          <li class="main-item">
              <span>Choose the lens type and color.</span>
              <ul class="sub-item">
                  <li>- Valley: Boston XO (Violet, Green, Blue) & Optimum Extra (Grey,
                      Green, Blue)</li>
                  <li>- Corneal lens: Boston XO (Red, Violet, Green, Blue) & Optimum
                      Extra (Grey, Green, Blue)</li>
              </ul>
          </li>
      </ul>
      `,
      prefillValue: true
  },
];
