<div class="container-fluid forgot-pwd-main d-flex flex-column">
    <div class="">
        <div class="card border-0">
            <div class="card-body px-0">
                <div class="mb-4">
                    <h5 class="forgot-password-heading kl-h6-type-1">Forgot Password?</h5>
                </div>
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPwdForm()">
                    <div class="mb-1" class="forgot-form-container input-div">
                        <label for="email" class="kl-text2-type-1 mb-2">Enter your email address</label>
                        <input
                            class="kl-input-text input-text-type-1"
                            type="email"
                            name="email"
                            placeholder="Email"
                            required="true"
                            formControlName="email"
                        />
                        <div class="error-message-container-common">
                            <p 
                                class="error-text"
                                *ngIf="isRequired('email') && (isDirty('email') || isTouched('email'))"
                            >
                                Email is required
                            </p>
                            <p
                                class="error-text"
                                *ngIf="
                                    !isValid('email') &&
                                    (isDirty('email') || isTouched('email')) &&
                                    !isRequired('email')
                                "
                            >
                                Please enter a valid email
                            </p>
                        </div>
                    </div>
                    <div class="button-wrapper w-100 text-center">
                        <button type="submit" class="kl-btn-primary" [disabled]="!forgotPasswordForm.valid">
                            <span>Continue</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
