import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
    catchError,
    EMPTY,
    finalize,
    map,
    mergeMap,
    Observable,
    of,
    switchMap,
    tap,
    throwError,
    withLatestFrom,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import { AuthService } from '@app/core/services/auth.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import {
    ClinicListForSelection,
    InitialTokenDetails,
    LoginResponseData,
    OtpSelectionDetails,
    OtpTarget,
    SelectedRoleDetails,
} from '@app/auth/models/auth.model';
import {
    resetPasswordFailure,
    resetPasswordSuccess,
    userRoleFetchFailure,
    userRoleFetchSuccess,
    getUserDetailsSuccess,
    forgotPwdEmailValidationSuccess,
    storeInitialLoginResponse,
    resetInitialLoginDetails,
    otpServiceSelectionDetails,
    clearAuthRelatedInitialDataSuccess,
    clinicListFetchForSelectionSuccess,
    saveLoginDataForLaterClinicSelection,
    onClinicSelectForDoctorSuccess,
    selectClinicAfterLogin,
    getUserProfileDetailsSuccess,
    validateDoctorEmailSuccess,
    doctorEmailValidationFailed,
    getClinicsByDesignerIdSuccess,
    getSiblingListSuccess,
    roleSelectionInitiate,
    getSiblingList,
    checkIfPatientIsUnderFranchiseClinic,
    saveParentPatientId,
} from '../actions/auth.action';
import { AuthModuleStates } from '../models/auth.model';
import {
    getMoreDetailsOfUser,
    setLoadingSpinner,
    setOpaqueLoadingSpinner,
    storeUserPermissions,
} from '@app/ui/actions/ui.actions';
import { ToasterService } from '@app/core/services/toaster.service';
import { Roles, Url } from '@app/core/util/common';
import { HandlePopupService } from '@app/core/services/handle-popup.service';
import {
    ClinicSelectAndUpdateMutation,
    ClinicSelectAndUpdateMutationVariables,
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables,
    GetClinicsByDesignerIdQuery,
    GetClinicsByDesignerIdQueryVariables,
    GetFamilyMembersUnderFranchiseClinicQuery,
    GetFamilyMembersUnderFranchiseClinicQueryVariables,
    GetListOfClinicsForDoctorQuery,
    GetListOfClinicsForDoctorQueryVariables,
    GetRolesBasedOnUserIdQuery,
    GetRolesBasedOnUserIdQueryVariables,
    GetSiblingListQuery,
    GetSiblingListQueryVariables,
    GetUserAndUserRoleMappingByEmailQuery,
    GetUserAndUserRoleMappingByEmailQueryVariables,
    GetUserDetailsQuery,
    GetUserDetailsQueryVariables,
    GetUserProfileQuery,
    GetUserProfileQueryVariables,
    OnLoginMutation,
    OnLoginMutationVariables,
    OnSendOtpBothServiceMutation,
    OnSendOtpBothServiceMutationVariables,
    ResetPasswordWithNewMutation,
    ResetPasswordWithNewMutationVariables,
    RoleSelectAndUpdateMutation,
    RoleSelectAndUpdateMutationVariables,
    UpdateNewUserDetailsMutation,
    UpdateNewUserDetailsMutationVariables,
    VerifyOtpMutation,
    VerifyOtpMutationVariables,
} from '@app/generated/graphql';
import {
  availableUserRoles,
    getAuthenticationType,
    getUserIdFromInitialLogin,
    getUserIdOfDoctorForClinicList,
    getloginDataForClinicSelection,
    selectDoctorDetailsByEmailForAuth,
} from '@app/auth/selectors/auth.selector';
import { UtilService } from '@app/core/services/util.service';
import { CommonService } from '@app/core/services/common.service';
import { getUserIdOfLoggedInUser } from '@app/shared/selectors/shared.selector';
import { getDistributorByDoctorAndClinicId, roleSelectionFailure, roleSelectionSuccess } from '@app/shared/actions/shared.action';

@Injectable()
export class AuthEffects {
    otpExtras$ = new Observable<{}>();
    constructor(
        private _router: Router,
        private _store: Store,
        private _action$: Actions,
        private _authService: AuthService,
        private _credentialService: CredentialsService,
        private _toasterService: ToasterService,
        private _popupService: HandlePopupService,
        private _commonService: CommonService,
        private _utilService: UtilService
    ) {}

    /**
     * on login call, validate logins and redirect user to otp selection page
     * @param params Login details like email/username & password
     * @returns action denoting login success/failure
     */
    onLogin$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.Login),
            mergeMap((params: { params: OnLoginMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._authService.login(params.params).pipe(
                    map((result: MutationResult<OnLoginMutation>) => {
                        if (!result?.data?.login?.isActive) {
                            this._toasterService.error('User not found.');
                            throw new Error('Unknown user.');
                        }

                        // If initial login success, take user to otp service selection ui
                        // store details in state
                        const initialSuccessLoginRes: InitialTokenDetails = {
                            userId: result?.data?.login?.id as string,
                            token: result?.data?.login?.token as string,
                            email: params?.params?.payload?.email,
                        };
                        this._credentialService.setCredentials({
                            userId: result?.data?.login?.id || '',
                            token: result?.data?.login?.token,
                            roleMappingId: '',
                            role: '',
                        });
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        if (result?.data?.login?.isFirstLogin) {
                            this._router.navigate([Url.TERMS_AND_CONDITIONS]);
                        } else {
                            this._router.navigate([Url.OTP_SERVICE]);
                        }
                        return storeInitialLoginResponse(initialSuccessLoginRes);
                    }),
                    catchError((error) => {
                        if (error?.graphQLErrors) {
                            error?.graphQLErrors.forEach((e: any) => {
                                const message = e?.extensions?.internal?.response?.body?.error
                                    ? e?.extensions?.internal?.response?.body?.error
                                    : 'Something went wrong!';
                                this._toasterService.error(message);
                            });
                        }
                        if (error?.networkError) {
                            this._toasterService.error('Something went wrong');
                        }
                        const initialFailureLoginRes: InitialTokenDetails = {
                            userId: '',
                            token: '',
                            email: '',
                        };
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return of(resetInitialLoginDetails(initialFailureLoginRes));
                    }),
                    finalize(() => {
                      this._authService.onLoginEvent();
                    })
                );
            })
        )
    );

    updateNewUserDetails$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(AuthModuleStates.updateNewUserDetails),
                mergeMap((params: { updateNewUserDetailsMutationVariables: UpdateNewUserDetailsMutationVariables }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._authService.onUpdateNewUserDetails(params?.updateNewUserDetailsMutationVariables).pipe(
                        map((result: MutationResult<UpdateNewUserDetailsMutation>) => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            this._router.navigate([Url.OTP_SERVICE]);
                        }),
                        catchError((error) => {
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return throwError(() => {
                                return new Error('');
                            });
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Send otp to mobile / email based on user selection
     * @param params Contains user id and otp service type whtether email or sms
     * @returns action with success or failure response
     */
    SendOtpToBothServices$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.sendOtpToBothServices),
            withLatestFrom(this._store.select(getAuthenticationType)),
            switchMap(([action, authType]: [{ otpMethodAndUserId: OnSendOtpBothServiceMutationVariables }, string]) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._authService.sendOtpToBothServices(action?.otpMethodAndUserId).pipe(
                    map((result: MutationResult<OnSendOtpBothServiceMutation>) => {
                        // decide on what text to show in alert popup, based on login/forgot password
                        const otpType: string = authType;
                        const isOpen = true;
                        this._popupService.setOtpStatus(otpType, isOpen);

                        // navigate to otp entering page, update state
                        const preferredDetails: OtpSelectionDetails = {
                            method: action?.otpMethodAndUserId?.payload?.method,
                            userId: action?.otpMethodAndUserId?.payload?.userId,
                        };
                        this._router.navigate([Url.OTP]);
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return otpServiceSelectionDetails(preferredDetails);
                    }),
                    catchError((error) => {
                        if (error?.graphQLErrors) {
                            error?.graphQLErrors.forEach((e: any) => {
                                const message = e?.extensions?.internal?.response?.body?.error
                                    ? e?.extensions?.internal?.response?.body?.error
                                    : 'Something went wrong!';
                                this._toasterService.error(message);
                            });
                        }
                        if (error?.networkError) {
                            this._toasterService.error('Something went wrong');
                        }
                        const preferredDetails: OtpSelectionDetails = {
                            method: '',
                            userId: '',
                        };
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return of(otpServiceSelectionDetails(preferredDetails));
                    })
                );
            })
        )
    );

    /**
     * Resend otp to user email/number
     * @param params Contains user id and otp service type whtether email or sms
     * @returns action with success or failure response.
     */
    resendOtp$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.resendOtpToUser),
            mergeMap((params: { resendOtpParams: OnSendOtpBothServiceMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._authService.sendOtpToBothServices(params?.resendOtpParams).pipe(
                    map((result: MutationResult<OnSendOtpBothServiceMutation>) => {
                        this._toasterService.success('OTP sent successfully');
                        const preferredDetails: OtpSelectionDetails = {
                            method: params?.resendOtpParams?.payload?.method,
                            userId: params?.resendOtpParams?.payload?.userId,
                        };
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return otpServiceSelectionDetails(preferredDetails);
                    }),
                    catchError((error) => {
                        if (error?.graphQLErrors) {
                            error?.graphQLErrors.forEach((e: any) => {
                                const message = e?.extensions?.internal?.response?.body?.error
                                    ? e?.extensions?.internal?.response?.body?.error
                                    : 'Something went wrong!';
                                this._toasterService.error(message);
                            });
                        }
                        if (error?.networkError) {
                            this._toasterService.error('Something went wrong');
                        }
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return of(error);
                    })
                );
            })
        )
    );

    /**
     * Otp Verification, this effect will call api responsible to handle the case
     * For eg - verify otp from server
     * If otp is valid user is redirected to role selection page
     * @param params shares user input otp and user id
     * @returns action with success or failure response
     */
    verifyOtp$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(AuthModuleStates.VerifyOtp),
                withLatestFrom(
                  this._store.select(getAuthenticationType),
                  this._store.select(availableUserRoles)
                  ),
                switchMap(([action, authType, availableRoles]: [{ payload: VerifyOtpMutationVariables }, string, GetRolesBasedOnUserIdQuery]) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._authService.verifyOtp(action.payload).pipe(
                        map((result: MutationResult<VerifyOtpMutation>) => {
                            // Based on authentication type, take user to appropriate page
                            if (authType === 'login') {
                                const isCareTaker = availableRoles.user_role_mapping?.some((role) => role.role?.name === Roles.CARE_TAKER);
                                const isPatient = availableRoles.user_role_mapping?.some((role) => role?.role?.name === Roles.PATIENT);
                                const userId = this._credentialService.credentials?.userId || '';
                                if(isCareTaker){
                                  this._store.dispatch(saveParentPatientId(userId));
                                  localStorage.setItem('parentPatientId', userId);
                                  this._store.dispatch(getSiblingList({parentId: userId}))
                                  this._router.navigateByUrl(Url.SIBLING_SELECTOR);
                                }else if(isPatient && !isCareTaker){
                                  const patient = availableRoles.user_role_mapping?.find((role) => role?.role?.name === Roles.PATIENT);
                                  const loggedInPatient: { selectedRole: string, role: SelectedRoleDetails} = {
                                    selectedRole: patient?.id,
                                    role: { id: patient?.role?.id, name: patient?.role?.name || ''}
                                  }
                                  this._store.dispatch(roleSelectionInitiate(loggedInPatient, false));
                                }
                                else{
                                  this._router.navigateByUrl(Url.ROLE_SELECTION);
                                }
                            }
                            if (authType === 'forgot-password') {
                                this._router.navigateByUrl(Url.RESET_PASSWORD);
                            }
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return result;
                        }),
                        catchError((error) => {
                            this._toasterService.error('Wrong OTP! Please enter valid OTP');
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return throwError(() => {
                                throw new Error('Wrong OTP! Please enter valid OTP');
                            });
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * on reset password calls api and resets the password.
     * Used for resetting password.
     * @param params shares new password entered by user.
     * @returns action denoting password reset success/failure
     */
    onResetPassword$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.ResetPassword),
            mergeMap((params: { forgotPwdParams: ForgotPasswordMutationVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._authService.resetPassword(params.forgotPwdParams).pipe(
                    map((result: MutationResult<ForgotPasswordMutation>) => {
                        this._toasterService.success('Successfully Updated Password');
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return resetPasswordSuccess(true);
                    }),
                    tap(() => this._router.navigateByUrl(Url.LOGIN)),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        this._toasterService.error('Soemthing went wrong, please try again');
                        return of(resetPasswordFailure(false));
                    })
                );
            })
        )
    );

    /**
     * On login success, fetch user roles that are mapped to the email, password
     * @param params whether login is success - true
     * @returns action denoting password role fetch success/failure with data
     */
    onLoginSuccessFetchRoles$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.UserRolesFetch),
            mergeMap((params: { userId: string }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                const userId = params?.userId;
                const fetchRolesParams: GetRolesBasedOnUserIdQueryVariables = { user_id: userId };
                return this._authService.onUserRolesFetch(fetchRolesParams).pipe(
                    map((data: ApolloQueryResult<GetRolesBasedOnUserIdQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return userRoleFetchSuccess(data?.data);
                    }),
                    catchError((error) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return of(userRoleFetchFailure([]));
                    })
                );
            })
        )
    );

    /**
     * On User Role Selection, call api to update details based on selected role
     * On successful api call user will get token which is final
     * @param params role-id of selected role
     * @returns actions for role updation success/failure with data
     */
    onUserRoleSelection$ = createEffect(
      () =>
          this._action$.pipe(
              ofType(AuthModuleStates.roleSelectionInitiate),
              withLatestFrom(
                  this._store.select(getUserIdFromInitialLogin),
                  this._store.select(getUserIdOfLoggedInUser)
              ),
              switchMap(
                  ([action, userIdFromState, userIdOfLoggedUser]: [
                      {
                          selectedRoleDetails: {
                              selectedRole: string;
                              role: SelectedRoleDetails;
                          };
                          isAfterLogin: boolean;
                      },
                      string,
                      string
                  ]) => {
                      userIdFromState =
                          userIdFromState && userIdFromState != '' ? userIdFromState : userIdOfLoggedUser;
                      this._store.dispatch(setOpaqueLoadingSpinner({ status: true }));
                      const userRoleSelectionParams: RoleSelectAndUpdateMutationVariables = {
                          role_mapping_id: action?.selectedRoleDetails?.selectedRole,
                          user_id: userIdFromState,
                      };
                      return this._authService.onUserRoleSelectionUpdate(userRoleSelectionParams).pipe(
                          map((data: MutationResult<RoleSelectAndUpdateMutation>) => {
                              let loginData: LoginResponseData;
                              loginData = {
                                  token: data.data?.roleSelect?.token as string,
                                  roleMappingId: action.selectedRoleDetails?.selectedRole,
                                  userId: userIdFromState,
                                  role: action?.selectedRoleDetails?.role?.name,
                                  roleId: action?.selectedRoleDetails?.role?.id,
                              };
                              if (action.selectedRoleDetails.role.name === Roles.DOCTOR) {
                                  this._store.dispatch(saveLoginDataForLaterClinicSelection(loginData));
                                  loginData = {
                                      token: '',
                                      roleMappingId: '',
                                      userId: loginData.userId,
                                      role: '',
                                      roleId: loginData.roleId,
                                  };
                                  if (action.isAfterLogin) {
                                      this._store.dispatch(selectClinicAfterLogin(true));
                                  } else {
                                      this._router.navigateByUrl(Url.CLINIC_SELECTION);
                                  }
                              } else {
                                  this._credentialService.setCredentials(loginData, true);
                                  this._store.dispatch(roleSelectionSuccess(loginData));
                                  this._utilService.navigateToRoutesBasedOnRole();
                              }
                              if (
                                  userIdOfLoggedUser &&
                                  userIdOfLoggedUser != '' &&
                                  action.selectedRoleDetails.role.name != Roles.DOCTOR
                              ) {
                                  this._store.dispatch(
                                      getMoreDetailsOfUser({ userId: loginData.userId, roleId: loginData.roleId })
                                  );
                                  this._store.dispatch(storeUserPermissions());
                              }
                          }),
                          finalize(() => {
                              if (action.selectedRoleDetails.role.name != Roles.DOCTOR) {
                                  this._commonService.onSwitchRoles();
                              }
                              this._store.dispatch(setOpaqueLoadingSpinner({ status: false }));
                          }),
                          catchError((error) => {
                              this._credentialService.removeRoleBasedToken();
                              const loginData = this.fetchEmptyLoginDataResponse();
                              this._toasterService.error('Something went wrong, please try again');
                              this._store.dispatch(setOpaqueLoadingSpinner({ status: false }));
                              return of(roleSelectionFailure(loginData));
                          })
                      );
                  }
              )
          ),
      { dispatch: false }
  );

    /**
     * Fetch list of clinics for doctor to select while logging in
    */
    onClinicListFetchForLoginAsDoctor$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.ClinicListFetchForSelection),
            withLatestFrom(this._store.select(getUserIdOfDoctorForClinicList)),
            switchMap(([action, userIdFromState]) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                const doctorIdPayload: GetListOfClinicsForDoctorQueryVariables = {
                    doctor_id: userIdFromState ? userIdFromState : this._credentialService.credentials?.userId,
                };
                return this._authService.getListOfClinicsAndNameOfDoctor(doctorIdPayload).pipe(
                    map((data: ApolloQueryResult<GetListOfClinicsForDoctorQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        const clinicList = this.modifyClinicListResponse(data?.data);
                        return clinicListFetchForSelectionSuccess(clinicList);
                    }),
                    catchError((error) => {
                        this._credentialService.removeRoleBasedToken();
                        const loginData: LoginResponseData = {
                            token: '',
                            roleMappingId: '',
                            userId: '',
                            role: '',
                            roleId: '',
                        };
                        this._toasterService.error('Something went wrong, please try again');
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return of(roleSelectionFailure(loginData));
                    })
                );
            })
        )
    );

    /**
     * When doctor selects a clinic update the selection
     * Clear temporary state variable 'loginDataForLaterClinicSelection'
     * Update localStorage with auth data
     * Fetch role permission and store in state
     * Fetch distributor Id of doctor and add it to role based token data
     */
    onClinicSelectAndUpdate$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.OnClinicSelectForDoctor),
            withLatestFrom(this._store.select(getloginDataForClinicSelection)),
            switchMap(
                ([action, loginDetails]: [
                    { clinicData: ClinicSelectAndUpdateMutationVariables, clinicExtra: {clinicRoleId: string} },
                    LoginResponseData
                ]) => {
                    return this._authService.onClinicSelectAndUpdate(action.clinicData).pipe(
                        map((result: MutationResult<ClinicSelectAndUpdateMutation>) => {
                          this._store.dispatch(setLoadingSpinner({ status: false }));
                            loginDetails = {
                                ...loginDetails,
                                clinicId: action?.clinicData?.clinic_id,
                                clinicType: action?.clinicData?.clinic_type,
                                clinicRoleId: action?.clinicExtra?.clinicRoleId
                            };
                            this._credentialService.setCredentials(loginDetails, true);
                            this._store.dispatch(
                                getMoreDetailsOfUser({ userId: loginDetails.userId, roleId: loginDetails.roleId })
                            );
                            this._store.dispatch(roleSelectionSuccess(loginDetails));
                            this._store.dispatch(storeUserPermissions());
                            this._store.dispatch(getDistributorByDoctorAndClinicId({ doctorId: loginDetails.userId, clinicId: loginDetails.clinicId }));
                            return onClinicSelectForDoctorSuccess(loginDetails);
                        }),
                        finalize(() => {
                            this._commonService.onSwitchRoles();
                            this._store.dispatch(setOpaqueLoadingSpinner({ status: false }));
                        }),
                        tap(() => {
                          this._authService.getRoleHierarchyForLoggedInUser();
                          this._router.navigateByUrl(Url.DASHBOARD)
                        }),
                        catchError((error) => {
                            this._store.dispatch(setOpaqueLoadingSpinner({ status: false }));
                            this._toasterService.error('Something went wrong, please try again');
                            return throwError(() => {
                                throw new Error(error);
                            });
                        })
                    );
                }
            )
        )
    );

    /**
     * On change password, calls action and change the password
     * Used for changing password after login
     * @param params shares new password, emailid of user
     * @returns action denoting password change success/failure
     */
    changePassword$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(AuthModuleStates.ChangePassword),
                mergeMap((response: { changePasswordData: ResetPasswordWithNewMutationVariables }) => {
                    this._store.dispatch(setLoadingSpinner({ status: true }));
                    return this._authService.changePassword(response.changePasswordData).pipe(
                        map((result: MutationResult<ResetPasswordWithNewMutation>) => {
                            this._toasterService.success('Password changed Successfully');
                            this._popupService.closeChangePasswordPopup(true);
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return response;
                        }),
                        catchError((error) => {
                            this._toasterService.error('Failed to change password, please try again');
                            this._store.dispatch(setLoadingSpinner({ status: false }));
                            return throwError(() => new Error());
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Get id of user based on email
     * @param params email id of user
     * @returns action denoting password change success/failure
     */
    getUserDetails$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.GetUserDetails),
            mergeMap((params: { data: GetUserDetailsQueryVariables }) => {
                return this._authService.getUserDetails(params.data).pipe(
                    map((result: ApolloQueryResult<GetUserDetailsQuery>) => {
                        this._popupService.closeChangePasswordPopup(true);
                        return getUserDetailsSuccess(result?.data);
                    }),
                    catchError((error) => {
                        return throwError(() => new Error());
                    })
                );
            })
        )
    );

    /**
     * Checks if entered email is valid for forgot password action
     * If such an email does not exist show a message, if valid and not regular patient,
     * redirect to otp service page
     * @param params param from action, contains email and source data
     * @returns Success/Failure
     */
    checkIfEmailValidForForgotPwd$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.ForgotPasswordEmailValidation),
            mergeMap((params: { param: OtpTarget }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                const payload: GetUserDetailsQueryVariables = { email: params.param.email };
                return this._authService.getUserDetails(payload).pipe(
                    map((result: ApolloQueryResult<GetUserDetailsQuery>) => {
                        const patientClinicDistributorType =
                            result.data?.doctor_patient_mapping?.[0]?.userByClinicId
                                ?.clinic_distributors_by_clinic_id?.[0]?.distributor?.user_role_mappings?.[0]?.role
                                ?.name;
                        let forgotPasswordParams: InitialTokenDetails = {
                            userId: '',
                            email: '',
                            token: '',
                        };
                        if (result?.data?.user?.length > 0 && patientClinicDistributorType !== Roles.SELECTIVE_DISTRIBUTOR) {
                            const userId: string = result?.data?.user?.[0]?.id;
                            forgotPasswordParams = {
                                userId,
                                email: params?.param?.email,
                                token: '',
                            };
                            this._router.navigateByUrl(Url.OTP_SERVICE);
                        } else if(patientClinicDistributorType === Roles.SELECTIVE_DISTRIBUTOR){
                            this._store.dispatch(checkIfPatientIsUnderFranchiseClinic({patientId: result?.data?.user?.[0]?.id}));
                        } else {
                            this._toasterService.error('Please enter a valid email');
                        }
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return forgotPwdEmailValidationSuccess(forgotPasswordParams);
                    }),
                    catchError((error) => {
                        this._toasterService.error('Something went wrong, please try again');
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return throwError(() => new Error());
                    })
                );
            })
        )
    );

    /**
     * Clear all login details saved while in the process of login/forgot password
     * As this is not required after login
     * @param Nil
     * @returns Success action
     */
    clearAuthRelatedData$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.ClearAuthRelatedInitialData),
            map(() => {
                const storageItem = localStorage.getItem('authFlowDetails');
                if (storageItem) {
                    localStorage.removeItem('authFlowDetails');
                }
                return clearAuthRelatedInitialDataSuccess();
            })
        )
    );

    /**
     * Clear data in state key 'loginDataForLaterClinicSelection'
     * As this is not required after clinic selection
     */
    clearLoginDataAfterClinicSelect$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(AuthModuleStates.OnClinicSelectForDoctorSuccess),
                map(() => {
                    const storageItem = localStorage.getItem('loginDataForLaterClinicSelection');
                    if (storageItem) {
                        localStorage.removeItem('loginDataForLaterClinicSelection');
                    }
                })
            ),
        { dispatch: false }
    );

    /**
     * Returns an empty response of data for initialization purpose
     * @returns LoginResponseData
     */
    fetchEmptyLoginDataResponse = (): LoginResponseData => {
        const loginData: LoginResponseData = {
            token: '',
            roleMappingId: '',
            userId: '',
            role: '',
            roleId: '',
        };
        return loginData;
    };

    /**
     * Push item to array for use in clinic selection dropdown
     */
    modifyClinicListResponse = (clinicData: GetListOfClinicsForDoctorQuery): ClinicListForSelection[] => {
        let clinicList: ClinicListForSelection[] = [];
        clinicData?.franchise_clinic?.forEach((clinic) => {
            const clinicObj: ClinicListForSelection = {
                clinicId: clinic?.clinic_id,
                clinicName: clinic?.user?.user_role_mappings?.[0]?.name || '',
                clinicType: 'franchise_clinic',
                clinicRoleId: clinic?.user?.user_role_mappings?.[0]?.role_id ?? ''
            };
            clinicList.push(clinicObj);
        });
        clinicData?.regular_clinic?.forEach((clinic) => {
            const clinicObj: ClinicListForSelection = {
                clinicId: clinic?.clinic_id,
                clinicName: clinic?.user?.user_role_mappings?.[0]?.name || '',
                clinicType: 'regular_clinic',
                clinicRoleId: clinic?.user?.user_role_mappings?.[0]?.role_id ?? ''
            };
            clinicList.push(clinicObj);
        });
        return clinicList;
    };

    /**
     * Validate the email entered as doctors email
     */
    validateDoctorEmail$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.ValidateDoctorEmail),
            mergeMap((params: { emailPayload: GetUserAndUserRoleMappingByEmailQueryVariables }) =>
                this._authService.validateDoctorEmail(params.emailPayload).pipe(
                    mergeMap((result: ApolloQueryResult<GetUserAndUserRoleMappingByEmailQuery>) => {
                        let userId = '';
                        let role = '';
                        let userName = '';
                        const isDesignerBelongsToTheDoctor =
                            result?.data?.designer?.[0]?.user?.clinicDoctorMappingsByDoctorId.some(
                                (data) =>
                                    data?.user?.clinic_distributors_by_clinic_id?.[0]?.distributor
                                        ?.designerDistributorMappingsByDistributorId?.[0]?.designer_id ===
                                    params?.emailPayload?.designerId
                            );
                        if (params?.emailPayload?.isSuperAdmin) {
                            userId = result?.data?.superAdmin?.[0]?.id;
                            role = result?.data?.superAdmin?.[0]?.role?.name || '';
                        }
                        if (params?.emailPayload?.isDesigner) {
                            userId = result?.data?.designer?.[0]?.id;
                            role = result?.data?.designer?.[0]?.role?.name || '';
                            userName = result?.data?.designer?.[0]?.name || '';
                        }

                        if (
                            params?.emailPayload?.isDesigner &&
                            !params?.emailPayload?.isSuperAdmin &&
                            userName &&
                            !isDesignerBelongsToTheDoctor
                        ) {
                            return throwError(() => "Sorry. The user doesn't work under you.");
                        }
                        if ((role && role !== Roles.DOCTOR && !params?.emailPayload?.isDesigner) || (!role && userId)) {
                            return throwError(() => 'Email provided does not belongs to any specific practitioner.');
                        }

                        if (!userId) {
                            return throwError(() => 'Sorry no match found');
                        }
                        return of(validateDoctorEmailSuccess(result?.data));
                    }),
                    catchError((error): Observable<any> => {
                        return of(doctorEmailValidationFailed(error));
                    })
                )
            )
        )
    );

    /**
     * Login super admin as the requested doctor.
     */
    loginSuperAdminToTheRequestedDoctorProfile$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(AuthModuleStates.LoginSuperAdminToTheRequestedDoctorProfile),
                withLatestFrom(this._store.pipe(select(selectDoctorDetailsByEmailForAuth))),
                mergeMap(
                    ([action, doctorDetails]: [
                        { loggingRoleName: string },
                        GetUserAndUserRoleMappingByEmailQuery | null
                    ]) => {
                        if (doctorDetails) {
                            let roleMappingId = '';
                            let userId = '';
                            let role: { __typename?: 'role' | undefined; id: any; name: string } | null | undefined =
                                null;
                            if (action?.loggingRoleName && action?.loggingRoleName === Roles.SUPER_ADMIN) {
                                roleMappingId = doctorDetails?.superAdmin?.[0]?.id;
                                userId = doctorDetails?.superAdmin?.[0]?.user?.id;
                                role = doctorDetails?.superAdmin?.[0]?.role;
                            }

                            if (action?.loggingRoleName && action?.loggingRoleName === Roles.DESIGNER) {
                                roleMappingId = doctorDetails?.designer?.[0]?.id;
                                userId = doctorDetails?.designer?.[0]?.user_id;
                                role = doctorDetails?.designer?.[0]?.role;
                            }

                            const userRoleSelectionParams: RoleSelectAndUpdateMutationVariables = {
                                role_mapping_id: roleMappingId,
                                user_id: userId,
                            };

                            return this._authService.onUserRoleSelectionUpdate(userRoleSelectionParams).pipe(
                                map((data: MutationResult<RoleSelectAndUpdateMutation>) => {
                                    let loginData: LoginResponseData;
                                    loginData = {
                                        token: data.data?.roleSelect?.token as string,
                                        roleMappingId: roleMappingId,
                                        userId: userId,
                                        role: role?.name as string,
                                        roleId: role?.id,
                                    };

                                    this._store.dispatch(saveLoginDataForLaterClinicSelection(loginData));
                                    this._router.navigateByUrl(Url.CLINIC_SELECTION);
                                    return of();
                                })
                            );
                        } else {
                            // Handle the case when doctorDetails is null
                            return EMPTY;
                        }
                    }
                )
            ),
        { dispatch: false }
    );

    /**
     * Get id of user based on email
     * @param params email id of user
     * @returns action denoting password change success/failure
     */
    getUserProfileDetails$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.getUserProfileDetails),
            mergeMap((userProfileParams: { userProfileParams: GetUserProfileQueryVariables }) => {
                this._store.dispatch(setLoadingSpinner({ status: true }));
                return this._authService.getUserProfileDetails(userProfileParams.userProfileParams).pipe(
                    map((result: ApolloQueryResult<GetUserProfileQuery>) => {
                        this._store.dispatch(setLoadingSpinner({ status: false }));
                        return getUserProfileDetailsSuccess(result?.data);
                    }),
                    catchError((error) => {
                        return throwError(() => new Error());
                    })
                );
            })
        )
    );

    getClinicsByDesignerId$ = createEffect(() =>
        this._action$.pipe(
            ofType(AuthModuleStates.getClinicsByDesignerId),
            mergeMap((params: { params: GetClinicsByDesignerIdQueryVariables }) => {
                return this._authService.getClinicsByDesignerId(params.params).pipe(
                    map((result: ApolloQueryResult<GetClinicsByDesignerIdQuery>) => {
                        const clinicList = this._authService.transformClinicsByDesigners(result?.data);
                        return getClinicsByDesignerIdSuccess(clinicList);
                    }),
                    catchError((error) => {
                        return throwError(() => new Error());
                    })
                );
            })
        )
    );

    getSiblingList$ = createEffect(() =>
      this._action$.pipe(
        ofType(AuthModuleStates.getSiblingList),
        mergeMap((params: { params: GetSiblingListQueryVariables}) => {
          return this._authService.getSiblingListGQL(params.params).pipe(
            map((result: ApolloQueryResult<GetSiblingListQuery>) => {
              const siblingList = this._authService.transformSiblingList(result.data);
              return getSiblingListSuccess(siblingList);
            }),
            catchError((error) => {
              return throwError(() => new Error());
            })
          )
        })
      )
    );

    checkIfPatientIsUnderFranchiseClinic$ = createEffect(() =>
      this._action$.pipe(
          ofType(AuthModuleStates.checkIfPatientIsUnderFranchiseClinic),
          switchMap((params: { params: GetFamilyMembersUnderFranchiseClinicQueryVariables}) => {
              return this._authService.getFamilyMembersUnderFranchiseClinic(params.params).pipe(
                  map((result: ApolloQueryResult<GetFamilyMembersUnderFranchiseClinicQuery>) => {
                      if(result?.data?.user && result?.data?.user.length === 0){
                          this._toasterService.error('Sorry! Operation not allowed.');
                          throw new Error('Unauthorized');
                      }
                      const anyPatientHasFranchiseClinicRole = result.data.user.some(user =>
                          user?.doctorPatientMappingsByPatientId.some(mapping =>
                              mapping?.userByClinicId?.user_role_mappings.some(roleMapping =>{
                                return roleMapping?.role?.name === Roles.FRANCHISE_CLINIC
                              }
                            )
                          )
                      );
                      if(anyPatientHasFranchiseClinicRole){
                          this._router.navigate([Url.OTP_SERVICE]);
                      }else{
                        this._toasterService.error('Sorry! Operation not allowed.');
                      }
                  }),
                  catchError((error) => {
                      return throwError(() => new Error('An error occurred'));
                  })
              );
          })
      ), {dispatch: false}
  );
}
