import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function sendOtpValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return {inValid: true};
    }
    const isNumber = /^\d+$/.test(value);
    return isNumber ? null : {notNumber: true};
  }
}
