import { D1 } from "./../inputForms/D1";
import { A } from "../inputForms/A";
import { TroubleshootControlPanel } from "./controlPanels";
import { axis, eye } from "../interfaces";

export class Troubleshoot {
  constructor(d1: D1, controlPanel: TroubleshootControlPanel, a?: A) {
    this._A =
      a ||
      new A(
        {
          clinicName: '',
          patientName: '',
          patientReference: '',
          practitionerName: '',
          eyes: eye.both,
          question1: {
            right: 0,
            left: 0,
          },
          question2: {
            right: 0,
            left: 0,
          },
          question3: {
            right: 0,
            left: 0,
          },
          question4: {
            right: {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
            },
            left: {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
            },
          },
          question5: {
            right: {
              1: 0,
              2: 0,
              3: axis.WTR,
            },
            left: {
              1: 0,
              2: 0,
              3: axis.WTR,
            },
          },
          question6: {
            right: false,
            left: false,
          },
          question7: {
            right: false,
            left: false,
          },
          question8: {
            right: 0,
            left: 0,
          },
          question9: '',
          question10: {
            right: '',
            left: ''
          },
          lensColor: {
            right: '',
            left: ''
        }
        },
        d1
      );
    this.F5 = this._A.F7;
    this.F11 = this._A.F9;
    this.F24 = this._A.F18;
    this.F33 = this._A.F30;
    this.F34 = this._A.F31;
    this.F35 = this._A.F32;
    this.F36 = this._A.F33;
    this.F37 = this._A.F34;
    this.F38 = this._A.F35;
    this.F44 = this._A.F36;
    this.F45 = this._A.F37;
    this.F46 = this._A.F38;
    this.F52 = this._A.F39;
    this.F55 = this._A.F41;
    this.F60 = this._A.F43;
    this.F80 = this._A.I7;
    this.F86 = this._A.I9;
    this.F99 = this._A.I18;
    this.F108 = this._A.I30;
    this.F109 = this._A.I31;
    this.F110 = this._A.I32;
    this.F111 = this._A.I33;
    this.F112 = this._A.I34;
    this.F113 = this._A.I35;
    this.F119 = this._A.I36;
    this.F120 = this._A.I37;
    this.F121 = this._A.I38;
    this.F127 = this._A.I39;
    this.F130 = this._A.I41;
    this.F135 = this._A.I43;
    this.K45 = this.F45;
    this.K120 = this.F120;

    this.T5 = controlPanel.oneEValue;
    this.T6 = controlPanel.twoEValueLower;
    this.T7 = controlPanel.twoEValueMiddle;
    this.T8 = controlPanel.twoEValueUpper;
    this.T9 = controlPanel.threeEValueLower;
    this.T10 = controlPanel.threeEValueUpper;
    this.T11 = controlPanel.fourEValue;
    this.T12 = controlPanel.twoHvidLower;
    this.T13 = controlPanel.twoHvidUpper;
    this.T14 = controlPanel.fiveSphere;
    this.T15 = controlPanel.sevenSphere;
    this.T16 = controlPanel.eightSphere;
    this.T17 = controlPanel.twoAc1Tlt;
    this.T18 = controlPanel.threeAc1TltLower;
    this.T19 = controlPanel.threeAc1TltMiddle;
    this.T20 = controlPanel.threeAc1TltUpper;
    this.T21 = controlPanel.fourAc1Tlt;
    this.T22 = controlPanel.twoAc2TltLower;
    this.T23 = controlPanel.twoAc2TltMiddle;
    this.T24 = controlPanel.twoAc2TltUpper;
    this.T25 = controlPanel.threeAc2TltLower;
    this.T26 = controlPanel.threeAc2TltMiddle;
    this.T27 = controlPanel.threeAc2TltUpper;
    this.T28 = controlPanel.fourAc2Tlt;
    this.T29 = controlPanel.fourRc2Tlt;
    this.T30 = controlPanel.fourElTltLower;
    this.T31 = controlPanel.fourElTltUpper;
    this.T32 = controlPanel.sixAcWidth;
    this.T33 = controlPanel.eightAcWidth;
    this.T34 = controlPanel.eightBaseEcc;
    this.T35 = controlPanel.fourApicalTlt;
  }
  private _A: A;

  T5: number;
  T6: number;
  T7: number;
  T8: number;
  T9: number;
  T10: number;
  T11: number;
  T12: number;
  T13: number;
  T14: number;
  T15: number;
  T16: number;
  T17: number;
  T18: number;
  T19: number;
  T20: number;
  T21: number;
  T22: number;
  T23: number;
  T24: number;
  T25: number;
  T26: number;
  T27: number;
  T28: number;
  T29: number;
  T30: number;
  T31: number;
  T32: number;
  T33: number;
  T34: number;
  T35: number;
  E5 = true;
  F5: number;
  H5() {
    if (this.F5 > 1) {
      return -this.T5;
    } else {
      return 0;
    }
  }

  E6 = false;

  F11: number;
  H11() {
    if (this.F11 < 1.1) {
      return this.T8;
    } else if (this.F11 === 2) {
      return this.T8;
    } else if (this.F11 === 3) {
      return -this.T6;
    } else if (this.F11 === 4) {
      return this.T8;
    } else if (this.F11 === 5) {
      return this.T8;
    } else if (this.F11 === 6) {
      return -this.T7;
    } else if (this.F11 === 7) {
      return -this.T7;
    } else if (this.F11 === 8) {
      return -this.T7;
    } else if (this.F11 === 9) {
      return -this.T7;
    } else {
      return 0;
    }
  }
  I11() {
    if (this.F11 < 1.1) {
      return 0;
    } else if (this.F11 === 2 || this.F11 === 3) {
      return 0;
    } else if (this.F11 === 4 || this.F11 === 5) {
      return this.T13;
    } else if (
      this.F11 === 6 ||
      this.F11 === 7 ||
      this.F11 === 8 ||
      this.F11 === 9
    ) {
      return this.T12;
    } else {
      return 0; // for solving type error
    }
  }
  L11() {
    if (this.F11 === 2) {
      return this.T17;
    } else {
      return 0;
    }
  }
  M11() {
    if (this.F11 < 1.1) {
      return this.T22;
    } else if (this.F11 === 2) {
      return this.T23;
    } else if (this.F11 === 3) {
      return this.T22;
    } else if (
      this.F11 === 4 ||
      this.F11 === 5 ||
      this.F11 === 6 ||
      this.F11 === 7 ||
      this.F11 === 8 ||
      this.F11 === 9
    ) {
      return this.T24;
    } else {
      return 0;
    }
  }

  F24: number;
  H24() {
    if (this.F24 < 1.1 || this.F24 === 2) {
      return this.T10;
    } else if (this.F24 === 3 || this.F24 === 4) {
      return -this.T9;
    } else if (this.F24 === 5) {
      return this.T10;
    } else {
      return 0;
    }
  }
  L24() {
    if (this.F24 < 1.1) {
      return this.T18;
    } else if (this.F24 === 2) {
      return this.T19;
    } else if (this.F24 === 3 || this.F24 === 4) {
      return this.T18;
    } else if (this.F24 === 5) {
      return this.T20;
    } else {
      return 0;
    }
  }
  M24() {
    if (this.F24 < 1.1) {
      return this.T25;
    } else if (this.F24 === 2) {
      return this.T26;
    } else if (this.F24 === 3 || this.F24 === 4) {
      return this.T25;
    } else if (this.F24 === 5) {
      return this.T27;
    } else {
      return 0;
    }
  }
  F33: boolean;
  H33() {
    if (this.F33) {
      return -this.T11;
    } else {
      return 0;
    }
  }
  F34: boolean;
  H34() {
    if (this.F34) {
      return -this.T11;
    } else {
      return 0;
    }
  }
  R34() {
    if (this.F34) {
      return this.T35;
    } else {
      return 0;
    }
  }
  F35: boolean;
  L35() {
    if (this.F35) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M35() {
    if (this.F35) {
      return this.T28;
    } else {
      return 0;
    }
  }
  N35() {
    if (this.F35) {
      return this.T29;
    } else {
      return 0;
    }
  }
  F36: boolean;
  L36() {
    if (this.F36) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M36() {
    if (this.F36) {
      return this.T28;
    } else {
      return 0;
    }
  }
  N36() {
    if (this.F36) {
      return this.T29;
    } else {
      return 0;
    }
  }
  F37: boolean;
  L37() {
    if (this.F37) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M37() {
    if (this.F37) {
      return this.T28;
    } else {
      return 0;
    }
  }
  O37() {
    if (this.F37) {
      return this.T30;
    } else {
      return 0;
    }
  }
  F38: boolean;
  O38() {
    if (this.F38) {
      return this.T31;
    } else {
      return 0;
    }
  }
  F44: number;
  J44() {
    if (this.F44 === 0) {
      return 0;
    } else if (this.F44 < 0) {
      return -(this.F44 - this.T14);
    } else {
      return -this.F44;
    }
  }
  F45: number;
  K45: number;
  F46: axis;
  K46(): number {
    if (this.F46 === "WTR") {
      return -this.K45;
    } else {
      return this.K45;
    }
  }
  F52: boolean;
  P52() {
    if (this.F52) {
      return -this.T32;
    } else {
      return 0;
    }
  }
  F55: boolean;
  J55() {
    if (this.F55) {
      return -this.T15;
    } else {
      return this.J44();
    }
  }
  F60: number;
  J61() {
    if (this.F60 === 2) {
      return this.J55() + this.T16;
    } else {
      return this.J55();
    }
  }
  P61() {
    if (this.F60 === 2) {
      return this.T33;
    } else {
      return 0;
    }
  }
  Q61() {
    if (this.F60 === 2) {
      return this.T34;
    } else {
      return 0;
    }
  }
  I64() {
    const values = [this.H5(), this.H11(), this.H24(), this.H33(), this.H34()];
    return values.length === 0 ? 0 : Math.min(...values);
  }
  H61 = 0;
  I65 = this.I11;
  I66 = this.J61;
  I67 = this.K46;
  L5 = 0;
  L61 = 0;
  I68() {
    const rangeOfValues = [this.L11(), this.L24(), this.L35(), this.L36(), this.L37()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  M5 = 0;
  M61 = 0;
  I69() {
    const rangeOfValues = [this.M11(), this.M24(), this.M35(), this.M36(), this.M37()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  N5 = 0;
  N61 = 0;
  I70() {
    const rangeOfValues = [this.N35(), this.N36()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  O5 = 0;
  O61 = 0;
  I71() {
    const rangeOfValues = [this.O37(), this.O38()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  I72() {
    return this.P52() + this.P61();
  }
  I73 = this.Q61.bind(this);

  I74() {
    return this.R34();
  }

  E80 = true;
  F80: number;
  H80() {
    if (this.F80 > 1) {
      return -this.T5;
    } else {
      return 0;
    }
  }
  E81 = false;
  F86: number;
  H86() {
    const f86 = this.F86;
    const t8 = this.T8;
    const t6 = this.T6;
    const t7 = this.T7;
    if (f86 < 1.1) {
      return t8;
    } else if (f86 === 2) {
      return t8;
    } else if (f86 === 3) {
      return -t6;
    } else if (f86 === 4) {
      return t8;
    } else if (f86 === 5) {
      return t8;
    } else if (f86 === 6) {
      return -t7;
    } else if (f86 === 7) {
      return -t7;
    } else if (f86 === 8) {
      return -t7;
    } else if (f86 === 9) {
      return -t7;
    } else {
      return 0;
    }
  }
  I86() {
    if (this.F86 < 1.1) {
      return 0;
    } else if (this.F86 === 2) {
      return 0;
    } else if (this.F86 === 3) {
      return 0;
    } else if (this.F86 === 4) {
      return this.T13;
    } else if (this.F86 === 5) {
      return this.T13;
    } else if (this.F86 === 6) {
      return this.T12;
    } else if (this.F86 === 7) {
      return this.T12;
    } else if (this.F86 === 8) {
      return this.T12;
    } else if (this.F86 === 9) {
      return this.T12;
    }
    return 0;
  }
  I86_2(){
    if (this.F86 < 1.1 || this.F86 === 2 || this.F86 === 3) {
      return 0;
    }
    if (this.F86 >= 4 && this.F86 <= 9) {
      return this.T12;
    }
    return 0;
  }
  L86() {
    const f86 = this.F86;
    const t17 = this.T17;
    if (f86 === 2) {
      return t17;
    } else {
      return 0;
    }
  }
  M86() {
    const f86 = this.F86;
    if (f86 < 1.1) {
      return this.T22;
    } else if (f86 === 2) {
      return this.T23;
    } else if (f86 === 3) {
      return this.T22;
    } else if (f86 === 4) {
      return this.T24;
    } else if (f86 === 5) {
      return this.T24;
    } else if (f86 === 6) {
      return this.T24;
    } else if (f86 === 7) {
      return this.T24;
    } else if (f86 === 8) {
      return this.T24;
    } else if (f86 === 9) {
      return this.T24;
    } else {
      return 0;
    }
  }
  F99: number;
  H99() {
    if (this.F99 < 1.1) {
      return this.T10;
    } else if (this.F99 === 2) {
      return this.T10;
    } else if (this.F99 === 3) {
      return -this.T9;
    } else if (this.F99 === 4) {
      return -this.T9;
    } else if (this.F99 === 5) {
      return this.T10;
    } else {
      return 0;
    }
  }
  L99() {
    if (this.F99 < 1.1) {
      return this.T18;
    } else if (this.F99 === 2) {
      return this.T19;
    } else if (this.F99 === 3 || this.F99 === 4) {
      return this.T18;
    } else if (this.F99 === 5) {
      return this.T20;
    } else {
      return 0;
    }
  }
  M99() {
    if (this.F99 < 1.1) {
      return this.T25;
    } else if (this.F99 === 2) {
      return this.T26;
    } else if (this.F99 === 3) {
      return this.T25;
    } else if (this.F99 === 4) {
      return this.T25;
    } else if (this.F99 === 5) {
      return this.T27;
    } else {
      return 0;
    }
  }
  F108: boolean;
  H108() {
    if (this.F108) {
      return -this.T11;
    } else {
      return 0;
    }
  }
  F109: boolean;
  H109() {
    if (this.F109) {
      return -this.T11;
    } else {
      return 0;
    }
  }
  R109() {
    if (this.F109) {
      return this.T35;
    } else {
      return 0;
    }
  }
  F110: boolean;
  L110() {
    if (this.F110) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M110() {
    if (this.F110) {
      return this.T28;
    } else {
      return 0;
    }
  }
  N110() {
    if (this.F110) {
      return this.T29;
    } else {
      return 0;
    }
  }
  F111: boolean;
  L111() {
    if (this.F111) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M111() {
    if (this.F111) {
      return this.T28;
    } else {
      return 0;
    }
  }
  N111() {
    if (this.F111) {
      return this.T29;
    } else {
      return 0;
    }
  }
  F112: boolean;
  L112() {
    if (this.F112) {
      return this.T21;
    } else {
      return 0;
    }
  }
  M112() {
    if (this.F112) {
      return this.T28;
    } else {
      return 0;
    }
  }
  O112() {
    if (this.F112) {
      return this.T30;
    } else {
      return 0;
    }
  }
  F113: boolean;
  O113() {
    if (this.F113) {
      return this.T31;
    } else {
      return 0;
    }
  }
  F119: number;
  J119() {
    if (this.F119 === 0) {
      return 0;
    } else if (this.F119 < 0) {
      return -(this.F119 - this.T14);
    } else if (this.F119 > 0) {
      return -this.F119;
    } else {
      return 0;
    }
  }
  F120: number;
  K120: number;
  F121: axis;
  K121() {
    if (this.F121 === "WTR") {
      return -this.K120;
    } else {
      return this.K120;
    }
  }
  F127: boolean;
  P127() {
    if (this.F127) {
      return -this.T32;
    } else {
      return 0;
    }
  }
  F130: boolean;
  J130() {
    if (this.F130) {
      return -this.T15;
    } else {
      return this.J119();
    }
  }
  F135: number;
  J136() {
    if (this.F135 === 2) {
      return this.J130() + this.T16;
    } else {
      return this.J130();
    }
  }
  P136() {
    if (this.F135 === 2) {
      return this.T33;
    } else {
      return 0;
    }
  }
  Q136() {
    if (this.F135 === 2) {
      return this.T34;
    } else {
      return 0;
    }
  }
  I139() {
    return Math.min(
      this.H80(),
      this.H86(),
      this.H99(),
      this.H108(),
      this.H109()
    );
  }
  I140 = this.I86;
  I140_2 = this.I86_2;
  I141 = this.J136;
  I142 = this.K121;
  L80 = 0;
  L136 = 0;
  I143() {
    const rangeOfValues = [this.L86(), this.L99(), this.L110(), this.L111(), this.L112()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  M80 = 0;
  M136 = 0;
  I144() {
    const rangeOfValues = [this.M86(), this.M99(), this.M110(), this.M111(), this.M112()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  N80 = 0;
  N136 = 0;
  I145() {
    const rangeOfValues = [this.N110(), this.N111()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  O80 = 0;
  O136 = 0;
  I146() {
    const rangeOfValues = [this.O112(), this.O113()];
    const maxValue = Math.max(...rangeOfValues);
    return maxValue;
  }
  I147() {
    return this.P127() + this.P136();
  }
  I148 = this.Q136;
  I149 = this.R109;
}
