<div class="container-fluid p-0 not-found-container">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
            <div class="center-viewport">
                <div class="not-found-text-section">
                    <h1 class="not-found-code">404</h1>
                    <h1 class="not-found-text">Page not found !</h1>
                    <div class="center center-content">
                        <p>The page you are looking for is not found, click below !</p>
                        <button class="kl-btn-primary px-4" (click)="onNavigateToHome()">Return to Home Page</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
