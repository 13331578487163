import { createAction } from "@ngrx/store";

import { GetUserFormData1Query, GetUserFormData1QueryVariables, GetUserListBasedOnRoleQuery, GetUserListBasedOnRoleQueryVariables, OnboardRegularClinicMutationVariables, GetListOfRegularClinicsCountQueryVariables, GetListOfRegularClinicsQuery, GetListOfRegularClinicsQueryVariables } from "@app/generated/graphql";
import { ActionType } from "@app/regular-clinics/models/regular-clinic.model";
import { User } from "@app/shared/models/shared.model";

export const getRegularClinicListCount = createAction(
    ActionType.getRegularClinicListCount,
    (countListParams: GetListOfRegularClinicsCountQueryVariables) => ({ countListParams })
);
export const getRegularClinicListCountSuccess = createAction(
    ActionType.getRegularClinicListCountSuccess,
    (regularClinicListCount: number) => ({ regularClinicListCount })
);
export const getRegularClinicsListBasedOnRole = createAction(
    ActionType.getRegularClinicsListBasedOnRole,
    (countListParams: GetListOfRegularClinicsQueryVariables) => ({ countListParams })
);
export const getRegularClinicsListBasedOnRoleSuccess = createAction(
    ActionType.getRegularClinicsListBasedOnRoleSuccess,
    (regularClinicListData: GetListOfRegularClinicsQuery) => ({ regularClinicListData })
);

export const getExclusiveDistributorList = createAction(
    ActionType.getExclusiveDistributorList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getExclusiveDistributorListSuccess = createAction(
    ActionType.getExclusiveDistributorListSuccess,
    (getExclusiveDistributorList: User[] | null) => ({ getExclusiveDistributorList })
);
export const getSelectiveDistributorList = createAction(
    ActionType.getSelectiveDistributorList,
    (roleObj: GetUserListBasedOnRoleQueryVariables) => ({ roleObj })
);
export const getSelectiveDistributorListSuccess = createAction(
    ActionType.getSelectiveDistributorListSuccess,
    (getSelectiveDistributorList: User[] | null) => ({ getSelectiveDistributorList })
);
export const onboardRegularClinic = createAction(
    ActionType.onboardRegularClinic,
    (regularClinicDetails: OnboardRegularClinicMutationVariables) => ({
        regularClinicDetails,
    })
);
export const getRegularClinicFormData = createAction(
    ActionType.getRegularClinicFormData,
    (userId: GetUserFormData1QueryVariables) => ({ userId }),
);

export const getRegularClinicFormDataSuccess = createAction(
    ActionType.getRegularClinicFormDataSuccess,
    (regularClinicDetails: GetUserFormData1Query) => ({ regularClinicDetails }),
);
