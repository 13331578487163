import { Injectable } from '@angular/core';

import { LoginResponseData, ROLE_NAMES } from '@app/auth/models/auth.model';
import { Roles } from '../util/common';

const credentialsKey = 'initial-access-token';
const roleBasedKey = 'role-based-token';

@Injectable({
    providedIn: 'root',
})
export class CredentialsService {
    private _credentials: LoginResponseData | null = null;

    constructor() {
        const savedCredentials = sessionStorage.getItem(roleBasedKey) || localStorage.getItem(roleBasedKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    /**
     * Checks is the user is authenticated.
     * @return True if the user is authenticated.
     */
    isAuthenticated(): boolean {
        if (localStorage.getItem(roleBasedKey)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Gets the user credentials.
     * @return The user credentials or null if the user is not authenticated.
     */
    get credentials(): LoginResponseData | null {
        return this._credentials;
    }

    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param credentials The user credentials.
     * @param remember True to remember credentials across sessions
     * @returns void
     */
    setCredentials(credentials?: LoginResponseData, remember?: boolean): void {
        this._credentials = credentials || null;

        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            if (storage.getItem(credentialsKey) || storage.getItem(roleBasedKey)) {
                storage.removeItem(credentialsKey);
                storage.removeItem(roleBasedKey);
            }
            storage.setItem(roleBasedKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(credentialsKey);
            sessionStorage.removeItem(roleBasedKey);
            localStorage.removeItem(credentialsKey);
            localStorage.removeItem(roleBasedKey);
        }
    }

    /**
     * Removes the role based token if role selection failed
     * @param empty
     * @returns void
     */
    removeRoleBasedToken(): void {
        if (localStorage.getItem(roleBasedKey)) {
            localStorage.removeItem(roleBasedKey);
        }
    }

    /**
     * Returns role names for showing in dropdown
     * @param roleValue string that is obtained from table
     * @return string to display in front end
     */
    getActualRoleNameForDisplay(roleValue: string | undefined): string | void {
        if (roleValue === Roles.SUPER_ADMIN) {
            return ROLE_NAMES.super_admin;
        }
        else if (roleValue === Roles.DESIGNER) {
            return ROLE_NAMES.designer;
        }
        else if (roleValue === Roles.EXCLUSIVE_DISTRIBUTOR) {
            return ROLE_NAMES.distributor;
        }
        else if (roleValue === Roles.SELECTIVE_DISTRIBUTOR) {
            return ROLE_NAMES.selective_distributor;
        }
        else if (roleValue === Roles.REGULAR_CLINIC) {
            return ROLE_NAMES.regular_clinic;
        }
        else if (roleValue === Roles.DOCTOR) {
            return ROLE_NAMES.doctor;
        }
        else if (roleValue === Roles.FRANCHISE_CLINIC) {
            return ROLE_NAMES.franchise_clinic;
        }
        else if (roleValue === Roles.CONTRACT_MANUFACTURER) {
            return ROLE_NAMES.contract_manufacturer;
        }
        else if (roleValue === Roles.PATIENT) {
            return ROLE_NAMES.patient;
        }else if (roleValue === Roles.CARE_TAKER) {
          return ROLE_NAMES.family_member
        }
        return;
    }
}
