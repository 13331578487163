import { GetListOfRegularClinicsQuery, GetUserFormData1Query, GetUserListBasedOnRoleQuery } from "@app/generated/graphql";
import { User } from "@app/shared/models/shared.model";

export enum ActionType {
    getExclusiveDistributorList = '[Regular Clinic] get exclusive distributor list',
    getExclusiveDistributorListSuccess = '[Regular Clinic] get exclusive distributor list success',
    getSelectiveDistributorList = '[Regular Clinic] get selective distributor list',
    getSelectiveDistributorListSuccess = '[Regular Clinic] get selective distributor list success',
    onboardRegularClinic = '[Regular Clinic] Onboard regular clinic',
    onboardRegularClinicSuccess = '[Regular Clinic] Onboard regular clinic success',
    getRegularClinicsListBasedOnRole = '[Regular clinic] Get regular clinic list',
    getRegularClinicsListBasedOnRoleSuccess = '[Regular clinic] Get regular clinic list success',
    getRegularClinicListCount= '[Regular clinic] Get total count of Regular clinics',
    getRegularClinicListCountSuccess= '[Regular clinic] Update Regular clinic list total count to state',
    getRegularClinicFormData = '[Regular clinic] get regular clinic form data',
    getRegularClinicFormDataSuccess = '[Regular clinic] get regular clinic form data success',
}

export const initialState: State = {
  exclusiveDistributorList: null,
  selectiveDistributorList: null,
  regularClinicTotalCount: 0,
  regularClinicListData: {
    superadmin: [],
    distributor: [],
    designer: []
  },
  deletedRegularClinic:true,
  regularClinicDetails: { user_role_mapping: [], user: [], contract_manufacturer_charges: [] },
}

export interface State {
  exclusiveDistributorList: User[] | null;
  selectiveDistributorList: User[] | null;
  regularClinicTotalCount: number;
  regularClinicListData: GetListOfRegularClinicsQuery;
  regularClinicDetails: GetUserFormData1Query;
  deletedRegularClinic: boolean
}

export interface RegularClinicListData {
    id: string;
    name: string;
    contact_number: string;
    registration_number?: string;
    count: string;
    order?:string;
    distributor_name?: string;
}

export interface OnboardingUpdateRegularClinicUserRoleDetails {
  name: string,
  role_details: OnboardingRegularClinicRoleDetails
}

export interface OnboardingRegularClinicRoleDetails {
  address: string,
  registration_number: string,
  distributor?: string,
  distributor_type?: string,
}

export enum Distributor {
  SELECTIVE_DISTRIBUTOR = 'selective',
  EXCLUSIVE_DISTRIBUTOR = 'exclusive',
}
