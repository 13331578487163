import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * This is to check strength of password
 * @param control form control of target field
 * @returns null | Object with key value, exact validation message
 */
export const PasswordStrengthValidator = (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value || '';
    if (!value) {
        return null;
    }
    const numberAndSpecial = new RegExp('^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$');
    const passwordWithTenChar = new RegExp('^.{10,}$');
    const upperCaseCharacters: RegExp = /[A-Z]+/g;
    const lowerCaseCharacters: RegExp = /[a-z]+/g;
    const numberCharacters: RegExp = /[0-9]+/g;
    const specialCharacters: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (
        upperCaseCharacters.test(value) === false ||
        lowerCaseCharacters.test(value) === false ||
        numberCharacters.test(value) === false ||
        specialCharacters.test(value) === false
    ) {
        return {
            passwordStrength: 'Password should include uppercase, lowercase, special characters and numerical digits.',
        };
    }
    if (!passwordWithTenChar.test(value)) {
        return {
            passwordStrength: ' Password must contain 10 characters.',
        };
    }
    if (!numberAndSpecial.test(value)) {
        return {
            passwordStrength: ' Must include atleast one number or special character.',
        };
    }
    return null;
};
