<ng-template #content let-modal>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="alert-title-heading mb-2">
                    <ng-content select=".popup-main-title"></ng-content>
                </div>
                <div class="d-flex justify-content-center alert-container text-center">
                    <ng-content select=".popup-body-content"></ng-content>
                </div>
                <div class="d-flex justify-content-center alert-container text-center">
                  <ng-content select=".popup-body-content-2"></ng-content>
              </div>
                <div class="popup-btn-container d-flex justify-content-center mb-3 mt-5">
                    <button
                        type="submit"
                        class="kl-btn-cancel-action kl-btn-outline me-3"
                        (click)="onCancelModal()"
                    >
                        <span>Cancel</span>
                    </button>
                    <button
                        type="submit"
                        class="kl-btn-add-save-user"
                        (click)="onConfirmClose()"
                    >
                        <span>Confirm</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
