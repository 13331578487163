import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";

import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  constructor() { }

  encrypt(data: string): string {
    // encryption
    const b64 = CryptoJS.AES.encrypt(
      data,
      environment.CRYPTO_SECRET_KEY
    ).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const encryptedString = e64.toString(CryptoJS.enc.Hex);
    return encryptedString;
  }

  decrypt(data: string): string {
    // decryption
    const reb64 = CryptoJS.enc.Hex.parse(data);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, environment.CRYPTO_SECRET_KEY);
    return decrypt.toString(CryptoJS.enc.Utf8);
  }
}
