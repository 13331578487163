<router-outlet></router-outlet>

<!-- Loader component is in sync with ngrx loader state -->
<ng-container *ngIf="showLoading$ | async">
  <app-loader [loaderType]="'simple'"></app-loader>
</ng-container>

<ng-container *ngIf="showOpaqueLoading$ | async">
  <app-loader [loaderType]="'opaque'"></app-loader>
</ng-container>
