import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';

import { updateNewUserDetails } from '@app/auth/actions/auth.action';
import { getUserIdFromInitialLogin } from '@app/auth/selectors/auth.selector';
import { UpdateNewUserDetailsMutationVariables } from '@app/generated/graphql';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  isEnabled: boolean = false;
  isFirstLogin: boolean = false;
  isAlive = true;
  userId!: string;
  userData$ = new Observable<string>();
  subscription$ = new Subscription();

  constructor(
    private _store: Store
  ) {
    this.userData$ = this._store.select(getUserIdFromInitialLogin);
    this.subscription$ = this.userData$.pipe(
        takeWhile(() => this.isAlive))
        .subscribe((userId: string) => {
            this.userId = userId;
    });
  }

  ngOnInit(): void  {}


  onSignIn = (): void => {
    const updateNewUserDetailsMutationVariables: UpdateNewUserDetailsMutationVariables= {
        is_first_login: this.isFirstLogin,
        id : this.userId
    }
    this._store.dispatch(updateNewUserDetails(updateNewUserDetailsMutationVariables));
  }

  onChangeCheckbox = (): void => {
    this.isEnabled = !this.isEnabled;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.subscription$.unsubscribe();
  }

}
