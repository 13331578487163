import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
    modalRef: NgbModalRef | null = null;
    writtenConfirmation = '';
    @Output() confirmationStatus = new EventEmitter<boolean>();
    @Output() cancelConfirmationStatus = new EventEmitter<boolean>();
    @Output() writtenConfirmationStatus = new EventEmitter<string>();
    @ViewChild('content') private content!: NgbModal;

    constructor(private _modalService: NgbModal) {}

    ngOnInit(): void {}

    /**
     * Opens a modal, called from parent component
     * @param Nil
     * @returns void
     */
    openConfirmDialog = (): void => {
      this.openVerticallyCenteredPopup(this.content);
    }

    /**
     * Opens a modal from ng bootstrap
     * Pass in the config, actual content of the modal to the service to open the modal
     * @param content Reference to the actual modal template
     * @returns void
     */
    openVerticallyCenteredPopup = (content: NgbModal): void => {
        this.modalRef = this._modalService.open(content, {
            centered: true,
            windowClass: 'common-confirmation-popup',
            backdrop: 'static',
            keyboard: false,
        });
    }

    /**
     * Closes all open modals and stays in same page
     * @param Nil
     * @returns void
     */
    onCancelModal = () => {
      this.cancelConfirmationStatus.emit(true);
      this.modalRef?.close();
    }

    /**
     * Closes only confirmation modal
     */
    onCancelConfirmDialogOnly = (): void => {
        this.modalRef?.close();
    }

    /**
     * Closes the popup modal, emits an event for parent to do required actions
     * @param Nil
     * @returns void
     */
    onConfirmClose = (): void => {
        this.confirmationStatus.emit(true);
        this.writtenConfirmationStatus.emit(this.writtenConfirmation);
    };
}
