import { GetRolesBasedOnUserIdQuery, GetUserDetailsQuery, GetUserProfileQuery } from '@app/generated/graphql';
import { Action, createReducer, on } from '@ngrx/store';

import {
    resetPasswordSuccess,
    userRoleFetchSuccess,
    userRoleFetchFailure,
    resetPasswordFailure,
    getUserDetailsSuccess,
    storeInitialLoginResponse,
    resetInitialLoginDetails,
    otpServiceSelectionDetails,
    verifyOtpStatus,
    clearAuthRelatedInitialDataSuccess,
    forgotPwdEmailValidationSuccess,
    saveLoginDataForLaterClinicSelection,
    clinicListFetchForSelectionSuccess,
    onClinicSelectForDoctorSuccess,
    selectClinicAfterLogin,
    validateDoctorEmail,
    validateDoctorEmailSuccess,
    doctorEmailValidationFailed,
    getUserProfileDetailsSuccess,
    getClinicsByDesignerIdSuccess,
    getSiblingListSuccess,
    saveUserIdOfLoggedInUser,
    saveParentPatientId
} from '../actions/auth.action';
import { ClinicListForSelection, initialState, InitialTokenDetails, LoginResponseData, OtpSelectionDetails, State, verifyOtpDetails } from '../models/auth.model';
import { roleSelectionSuccess } from '@app/shared/actions/shared.action';
import { resetAppState } from '@app/actions';

const authModuleReducer = createReducer(
    initialState,

    // rehydrate based changes start
    on(
        storeInitialLoginResponse,
        resetInitialLoginDetails,
        (state, payload: { initialLoginDetails: InitialTokenDetails}) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                initialAuthBasicDetails: payload.initialLoginDetails
            }
        }
    }),
    on(
        storeInitialLoginResponse,
        (state) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                authenticationType: 'login'
            }
        }
    }),
    on(otpServiceSelectionDetails,
        (state, payload: { preferredOtpMethod: OtpSelectionDetails}) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                otpServiceSelectionDetails: payload.preferredOtpMethod
            }
        }
    }),
    on(verifyOtpStatus,
        (state, payload: { verifyOtpResponse: verifyOtpDetails}) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                otpVerificationDetails: payload.verifyOtpResponse
            }
        }
    }),
    on(clearAuthRelatedInitialDataSuccess,
        resetPasswordSuccess,
        roleSelectionSuccess,
        (state) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                initialAuthBasicDetails: {
                    userId: '',
                    token: '',
                    email: ''
                },
                otpServiceSelectionDetails: {
                    method: '',
                    userId: ''
                },
                otpVerificationDetails: {
                    otp: null,
                    userId: ''
                },
                authenticationType: ''
            }
        }
    }),
    on(forgotPwdEmailValidationSuccess,
        (state, payload: {forgotPasswordParams: InitialTokenDetails}) => {
        return {
            ...state,
            authFlowDetails: {
                ...state.authFlowDetails,
                initialAuthBasicDetails: payload.forgotPasswordParams,
                authenticationType: 'forgot-password'
            }
        }
    }),
    // rehydrate changes end

    on(resetPasswordFailure, (state, payload: { isPasswordReset: boolean }) => ({
        ...state,
        isPasswordReset: payload.isPasswordReset,
    })),
    on(userRoleFetchSuccess, (state, payload: { userRoles: GetRolesBasedOnUserIdQuery }) => ({
        ...state,
        availableUserRoles: payload.userRoles
    })),
    on(userRoleFetchFailure, (state, payload: { userRoles: string[] }) => ({
        ...state,
        availableUserRoles: { user_role_mapping: [] },
    })),
    on(getUserDetailsSuccess, (state, payload: { data: GetUserDetailsQuery }) => ({
        ...state,
        userDetails: payload.data,
    })),
    on(saveLoginDataForLaterClinicSelection, (state, payload: { loginData: LoginResponseData }) => ({
        ...state,
        loginDataForLaterClinicSelection: payload.loginData,
    })),
    on(clinicListFetchForSelectionSuccess, (state, payload: { clinicList: ClinicListForSelection[] }) => ({
        ...state,
        clinicList: payload.clinicList,
    })),
    on(onClinicSelectForDoctorSuccess, (state, payload: { loginData: LoginResponseData }) => ({
        ...state,
        loginDataForLaterClinicSelection: {
            token: '',
            roleMappingId: '',
            userId: '',
            role: '',
            roleId: '',
        }
    })),
    on(selectClinicAfterLogin, (state, payload: { showClinicSelection: boolean }) => ({
        ...state,
        showClinicSelection: payload.showClinicSelection
    })),
        // Handle the validateDoctorEmail action
        on(validateDoctorEmail, (state) => ({
          ...state,
          isValidatingDoctorEmail: true,
          doctorEmailFailedMsg: null
      })),

      // Handle the validateDoctorEmailSuccess action
      on(validateDoctorEmailSuccess, (state, { doctorDetails }) => ({
          ...state,
          doctorDetails: doctorDetails,
          isValidatingDoctorEmail: false,
          doctorEmailFailedMsg: null
      })),

      // Handle the doctorEmailValidationFailed action
      on(doctorEmailValidationFailed, (state, { doctorEmailFailedMsg }) => ({
          ...state,
          isValidatingDoctorEmail: false,
          doctorEmailFailedMsg,
          doctorDetails: null
      })),

    on(getUserProfileDetailsSuccess, (state, payload:{userProfileDetails: GetUserProfileQuery}) => ({
        ...state,
        userProfileDetails: payload.userProfileDetails
    })),
    on(getClinicsByDesignerIdSuccess, (state, {clinicsByDesigner}) => ({
      ...state,
      clinicsByDesigner
    })),
    on(getSiblingListSuccess, (state, { siblingList }) => ({
      ...state,
      siblingList
    })),
    on(saveUserIdOfLoggedInUser, (state, { userId }) => ({
      ...state,
      authFlowDetails: {
        ...state.authFlowDetails,
        initialAuthBasicDetails: {
          ...state.authFlowDetails.initialAuthBasicDetails,
          userId
        }
    }})),
    on(saveParentPatientId, (state, {parentPatientId}) => ({
      ...state,
      parentPatientId
    })),
    on(resetAppState, () => initialState)
);
export function authReducer(state: State | undefined, action: Action) {
    return authModuleReducer(state, action);
}
