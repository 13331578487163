<ng-container [formGroup]="patientBasicInfo">
    <div *ngIf="careOf && mode !== 'create'" class="info-text mb-4">This user is registered as a family member under patient {{ careOf.name }} ({{careOf.email}})</div>
    <div class="row">
        <div class="col-12">
          <p class="kl-text3-type-6 patient-info">Patient Info</p>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="first-name" class="kl-text5-type2 mb-10">First name <sup class="field-required">*</sup> </label>
            <input
                type="text"
                formControlName="first_name"
                class="kl-input-text input-text-type-2"
                id="first-name"
                placeholder="First name"
            />
            <div class="error-common-container">
                <span class="error-text" *ngIf="isValid('first_name')">First name required.</span>
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="last-name" class="kl-text5-type2 mb-10">Last name <sup class="field-required">*</sup> </label>
            <input
                type="text"
                formControlName="last_name"
                class="kl-input-text input-text-type-2"
                id="last-name"
                placeholder="Last name"
            />
            <div class="error-common-container">
                <span class="error-text" *ngIf="isValid('last_name')">Last name required.</span>
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="patient_reference_number" class="kl-text5-type2 mb-10"
                >Patient reference number <sup class="field-required">*</sup>
            </label>
            <input
                type="text"
                formControlName="reference_number"
                class="kl-input-text input-text-type-2"
                id="patient-reference-number"
                placeholder="Reference number"
            />
            <div class="error-common-container">
                <span class="error-text" *ngIf="isValid('reference_number')"
                    >Patient reference number required.</span
                >
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="nationality" class="kl-text5-type2 mb-10"
                >Ethnicity<sup class="field-required">*</sup>
            </label>
            <input
                type="text"
                formControlName="nationality"
                class="kl-input-text input-text-type-2"
                id="nationality"
                placeholder="Ethnicity"
            />
            <div class="error-common-container">
                <span *ngIf="isValid('nationality')" class="error-text"> Nationality is required. </span>
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
          <label for="age" class="kl-text5-type2 mb-10">Age<sup class="field-required">*</sup> </label>
          <input
              type="number"
              formControlName="age"
              class="kl-input-text input-text-type-2"
              id="age"
              placeholder="Age"
              [disabled]="true"
          />
          <div class="error-common-container">
              <span *ngIf="isValid('age')" class="error-text"> Age is required. </span>
          </div>
      </div>
        <div class="col-md-6 mb-3">
            <div class="form-group">
                <label for="dob" class="kl-text5-type-11 mb-10">Date of birth</label>
                <div class="position-relative w-100">
                    <input
                        class="form-control pr-5 w-100"
                        id="dob"
                        (dateSelect)="onDobSelect()"
                        formControlName="dob"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        placeholder="yyyy-mm-dd"
                        name="dob"
                        ngbDatepicker
                        #dob="ngbDatepicker"
                    />
                    <img
                        class="position-absolute top-50 end-0 translate-middle-y me-2"
                        (click)="dob.toggle()"
                        src="/assets/icons/common/calendar-black.svg"
                    />
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
          <p class="kl-text5-type2 mb-2">Please select gender.</p>
          <div class="d-flex mt-3">
              <label for="male" class="kl-check-default kl-radio-container me-2 mb-4 padding-radio-button"
                  >Male
                  <input
                      type="radio"
                      [value]="'male'"
                      id="male"
                      formControlName="gender"
                      name="gender"
                      [checked]="true"
                  />
                  <span></span>
              </label>
              <label for="female" class="kl-check-default kl-radio-container me-2"
                  >Female
                  <input type="radio" id="female" [value]="'female'" formControlName="gender" name="gender" />
                  <span></span>
              </label>
              <label for="other" class="kl-check-default kl-radio-container me-2"
                  >Other
                  <input type="radio" id="other" [value]="'other'" formControlName="gender" name="gender" />
                  <span></span>
              </label>
          </div>
      </div>
    </div>
    <!-- Contact Details-->
    <div class="row">
        <div class="col-12">
          <p class="kl-text3-type-6 patient-info">Contact Details</p>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="contact-number" class="kl-text5-type2 mb-10"
                >Contact number <sup class="field-required">*</sup>
            </label>
            <div
                class="d-flex justify-content-start align-items-center select-phone-number-wrap"
                [ngClass]="{ errorBorderColor: submitted && isValid('contact_number'),'input-disabled': mode === 'view' || role === 'patient' }"
                #phoneInput
            >
                <input formControlName="country_code" hidden />
                <app-country-code-selector
                    [country_code]="country_code"
                    (selectedCode)="onSelectCode($event)"
                ></app-country-code-selector>
                <input
                    (focus)="onFocusPhoneInput('focus')"
                    (blur)="onFocusPhoneInput('blur')"
                    type="tel"
                    formControlName="contact_number"
                    class="kl-input-text input-text-type-2"
                    id="contact-number"
                    placeholder="Contact number"
                    value="+65"
                />
            </div>
            <div class="error-common-container">
                <span class="error-text" *ngIf="isValid('contact_number')"
                    >Please add a valid phone number.</span
                >
            </div>
        </div>
        <div class="col-md-6 col-12 onboard-input-wrap">
            <label for="email" class="kl-text5-type2 mb-10"
                >Email ID <sup class="field-required">*</sup>
            </label>
            <input
                type="email"
                formControlName="email"
                class="kl-input-text input-text-type-2"
                id="email"
                placeholder="Email ID"
            />
            <div class="error-common-container">
                <span
                    class="error-text"
                    *ngIf="isValid('email') && !isEmailTaken"
                    >Please add a valid email ID.</span
                >
                <span *ngIf="isEmailTaken" class="error-text">
                    This email is already registered.
                </span>
                <div *ngIf="showSiblingsUI && mode !== 'edit'" class="kl-text5-type-1">
                    <div class="d-flex align-items-center">
                      <p class="mb-sm-0 mb-3">Do you want to add as family member"?</p>
                      <button class="kl-btn-primary-plain" (click)="onAddAsSiblings()" type="button">YES</button>
                      <button class="kl-btn-primary-plain" (click)="onCancelSiblings()" type="button">NO</button>
                    </div>
                </div>
                <div *ngIf="isDependable && mode !== 'edit'" class="kl-text5-type-1 d-flex align-items-center mt-2">
                  Added as family member <button class="kl-btn-primary-plain" (click)="onCancelSiblings()" type="button">UNDO</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Eye Power -->
    <div class="row" #eyePowerElements>
      <div class="col-12">
          <p class="kl-text3-type-6 patient-info">Eye Power</p>
      </div>
      <div *ngFor="let eye of eyeSides" class="col-md-6 col-12 onboard-input-wrap">
          <label class="kl-text5-type2 mb-10"> {{ eye.label }}</label>
          <div class="row" [formGroupName]="eye.side">
              <!-- Sphere Input -->
              <div class="col-12">
                    <label class="kl-text5-type2 mb-1" [for]="eye.side + 'sphere'">Sphere</label>
                    <input
                        type="number"
                        [id]="eye.side + 'sphere'"
                        formControlName="sphere"
                        class="kl-input-text input-text-type-2 col"
                        placeholder="Sphere"
                    />
                    </div>
              <!-- Cyl Input -->
              <div class="col-12">
                    <label class="kl-text5-type2 mb-1" [for]="eye.side + 'cyl'">Cyl</label>
                    <input
                        type="number"
                        [id]="eye.side + 'cyl'"
                        formControlName="cyl"
                        class="kl-input-text input-text-type-2 col"
                        placeholder="Cyl"
                    />
              </div>
              <!-- Axis Input -->
              <div class="col-12 mb-4">
                  <label class="kl-text5-type2 mb-1" [for]="eye.side + 'axis'">Axis</label>
                  <input
                      type="number"
                      [id]="eye.side + 'axis'"
                      formControlName="axis"
                      class="kl-input-text input-text-type-2"
                      placeholder="Axis"
                      min="0"
                  />
                  <div class="error-common-container">
                    <span
                        class="error-text"
                        *ngIf="getAxisControl(eye.side)"
                        >Should be between 0 to 180.</span
                    >
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- Axial Length -->
    <div class="col-12">
        <p class="kl-text3-type-6 patient-info">Axial Length</p>
    </div>
    <div class="row" #axialLengthElements>
        <div *ngFor="let axialLength of axialLengths" class="col-md-6 col-12 onboard-input-wrap">
            <label [for]="axialLength.label" class="kl-text5-type2 mb-10"
                >{{ axialLength.label }}
            </label>
            <input
                type="number"
                [formControlName]="axialLength.formControlName"
                class="kl-input-text input-text-type-2"
                [id]="axialLength.label"
                placeholder="Axial length"
            />
        </div>
    </div>
</ng-container>
