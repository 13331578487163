import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';

import { filterValues } from '@app/core/util/initial-values';
import { getFilterByValues, saveAppliedFilters } from '@app/shared/actions/shared.action';
import { FilterParams, FilterTypesRef } from '@app/shared/models/shared.model';
import { selectAppliedFilters, selectFilterBy } from '@app/shared/selectors/shared.selector';


@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
    filterTypes: FilterTypesRef[] = [];
    filterByValues$ = new Observable<string[] | null>();
    isAlive = true;
    filterForm: FormGroup = new FormGroup({});
    isSubmitted = false;

    constructor(public activeModal: NgbActiveModal, private _store: Store, private _fb: FormBuilder) {}

    ngOnInit(): void {
      this.initForm();
      this.subscribeToFilterByValues();
      this.selectAppliedFilters();
    }

    initForm = (): void => {
        this.filterForm = this._fb.group({
            filterType: [""],
            filterValue: [""],
            startDate: [""],
            endDate: [""],
        });

        // Subscribe to filter type changes.
        this.filterForm.get('filterType')?.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((filterType: keyof typeof filterValues) => {
          if(filterType){
            this._store.dispatch(getFilterByValues(filterType));
          }
        });
    };

    /**
     * Select any applied filter in store if available.
     */
    selectAppliedFilters = () => {
      this._store.select(selectAppliedFilters).pipe(takeWhile(() => this.isAlive)).subscribe((appliedFilters: FilterParams | null) => {
        if(appliedFilters){
          this.filterForm.patchValue(appliedFilters);
        }
      })
    }

    /**
     * Get default value for filter
     */
    subscribeToFilterByValues = (): void => {
      this.filterByValues$ = this._store.pipe(
          takeWhile(() => this.isAlive),
          select(selectFilterBy)
      );
    };

    applyFilter = (): void => {
      const startDateControl = this.filterForm.get('startDate');
      const endDateControl = this.filterForm.get('endDate');

      // Even if required validators are not added while defining the form for start and end date controls.
      // ng datepicker adds a required validator to date picker by default.
      // and the form will not be an invalid form if not applied reset.
      // It is required to reset the start date and end date fields if user not select any date range for filtering for smooth submit of form.
      if(!startDateControl?.value){
        this.filterForm.get('startDate')?.reset();
      }
      if(!endDateControl?.value){
        this.filterForm.get('endDate')?.reset();
      }

      if(this.filterForm.valid){
        this._store.dispatch(saveAppliedFilters(this.filterForm.value));
        this.activeModal.close(this.filterForm.value);
      }else{
        this.isSubmitted = true; // Make submit true to display error message.
      };
    };

   /**
   * Checks if the control is valid
   * @param name of the form control
   * @returns boolean
   */
    isValid = (name: string): boolean => {
      return this.filterForm.controls[name]?.valid;
    };

    ngOnDestroy(): void {
      this.isAlive = false;
    }
}
