import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';

import { resetUserDetailsPageExtraDetails } from '@app/shared/actions/shared.action';
import { CapitalizeOrderStatusPipe } from '@app/shared/pipes/capitalize-order-status.pipe';
import { FilterTypesEnum } from '@app/core/util/common';

@Component({
    selector: 'app-additional-info',
    templateUrl: './additional-info.component.html',
    styleUrls: ['./additional-info.component.scss'],
    providers: [ CapitalizeOrderStatusPipe ]
})
export class AdditionalInfoComponent implements OnInit, OnDestroy {
    @Input() data: any[] | null = [];
    @Input() tableHeadings?: String[] = [];
    @Input() heading: string = '';
    @Input() imageSrc: string = '';
    @Input() showInitials: boolean = false;
    @Input() isLoading: boolean | null = false;

    constructor(private _datePipe: DatePipe, private _store: Store, private _capitalizeOrderStatus: CapitalizeOrderStatusPipe) {}

    ngOnInit(): void {}

    get keys() {
        return this.data?.length ? Object.keys(this.data[0]) : [];
    }

    getInitials(item: any) {
        if (!item.name) {
            return '';
        }
        let names = item.name.split(' ');
        let initials = names[0].charAt(0);
        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0);
        }
        return initials.toUpperCase();
    }

    formatValue(item: any, key: string): string {
        let value = item[key];
        const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;

        if (datePattern.test(value)) {
            // value looks like a date, try to format it
            const date = new Date(value);
            if (!isNaN(date.getTime())) {
                return this._datePipe.transform(date, 'dd MMM yyyy') as string;
            }
        }
        if(key === 'clinicType'){
            // Formats clinic type and return respective values
            return value === 'Regular' ? 'Partner' : 'Gold Partner';
        }    
        if(key === 'status'){
            return this._capitalizeOrderStatus.transform(value, FilterTypesEnum.ORDER_STATUS_SNAKE_CASE);
        }                  
        // value is not a date, return it as is
        return value;
    }

    ngOnDestroy(): void {
        this._store.dispatch(resetUserDetailsPageExtraDetails());
    }
}
